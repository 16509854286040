export const shippingTypeId = {
  postOffice: '1',
  sevenEleven: '2',
  familyMart: '3',
  hiLife: '4',
};

export const shippingType = {
  1: '郵寄',
  2: '711',
  3: '全家',
  4: '萊爾富',
};

export default {
  shippingTypeId,
  shippingType,
};
