import React from 'react';
import Header from '../../Parts/Header';
import Title from '../../Parts/Title';

import {
  cancelMark,
} from '../../../../constants/Assets';

import style from './style.module.scss';

const CancelPage = () => (
  <div className={style.container}>
    <Header />
    <div className={style.content}>
      <img src={cancelMark} alt="identifySuccess" />
      <div className={style.textContent}>
        <Title>取消驗證？</Title>
        <div>這會影響您存取本次服務，確定要這要做？</div>
      </div>
    </div>
  </div>
);

export default CancelPage;
