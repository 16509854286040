import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('ORDER_CREATE'),
  ...createAsyncActionTypesArray('ORDER_LIST'),
  ...createAsyncActionTypesArray('ORDER_DETAIL'),
  ...createAsyncActionTypesArray('GET_DONATE_ORGANIZATION'),
  ...createAsyncActionTypesArray('GET_MY_RENTED_SOLD_ORDER_LIST'),
  ...createAsyncActionTypesArray('SEND_COMMENT'),
  ...createAsyncActionTypesArray('LINK_RESOURCE_TO_COMMENT'),
  ...createAsyncActionTypesArray('WITHDRAW_REQUEST'),
  ...createAsyncActionTypesArray('UPDATE_WITHDRAW_INFO'),
  ...createAsyncActionTypesArray('ORDER_UPDATE'),
  ...createAsyncActionTypesArray('GET_ORDER_NUMBERS'),
  ...createAsyncActionTypesArray('GET_ADDRESS'),

  // ----- FLOW -----
  ...createAsyncActionTypesArray('ORDER_CREATE_FLOW'),
  ...createAsyncActionTypesArray('ORDER_LIST_FLOW'),
  ...createAsyncActionTypesArray('ORDER_DETAIL_FLOW'),
  ...createAsyncActionTypesArray('GET_DONATE_ORGANIZATION_FLOW'),
  ...createAsyncActionTypesArray('GET_MY_RENTED_SOLD_ORDER_LIST_FLOW'),
  ...createAsyncActionTypesArray('SEND_COMMENT_FLOW'),
  ...createAsyncActionTypesArray('LINK_RESOURCE_TO_COMMENT_FLOW'),
  ...createAsyncActionTypesArray('WITHDRAW_REQUEST_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_WITHDRAW_INFO_FLOW'),
  ...createAsyncActionTypesArray('ORDER_UPDATE_FLOW'),
  ...createAsyncActionTypesArray('GET_ORDER_NUMBERS_FLOW'),
  ...createAsyncActionTypesArray('GET_ADDRESS_FLOW'),

  'RESET_ORDER_CREATE_STATUS',
  'RESET_DONATE_ORGANIZATION',

  { prefix: 'ORDER' },
);
