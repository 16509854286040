import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OrderActionCreators from '../../../../Order/ActionCreator';
import ActionCreators from '../../../ActionCreator';
import WithdrawMoneyComponent from './WithdrawMoneyComponent';

import {
  orderListDataSelector,
} from '../../../../Order/selector';

const WithdrawMoneyComponentContainer = ({
  isVisible,
  onModalClose,
  orderList,
  userId,
  actions: {
    withdrawRequestFlowRequest,
    getMyRentedSoldOrderListFlowRequest,
  },
}) => {
  const filleterNuSentWithdrawCondition = {
    isAbleSentWithdrawRequest: true,
    isRequestWithdraw: false,
  };

  useEffect(() => {
    if (isVisible) {
      getMyRentedSoldOrderListFlowRequest({
        userId,
        filleter: filleterNuSentWithdrawCondition,
      });
    }
  }, [isVisible]);

  const handelSendWithdrawRequest = (selectOrderList) => {
    withdrawRequestFlowRequest({ selectOrderList, userId });
    onModalClose();
  };

  const handelGetUnSendWithdrawRequestList = () => {
    getMyRentedSoldOrderListFlowRequest({
      userId,
      filleter: filleterNuSentWithdrawCondition,
    });
  };

  const handelGetSendedWithdrawRequestList = () => {
    getMyRentedSoldOrderListFlowRequest({
      userId,
      filleter: {
        isAbleSentWithdrawRequest: true,
        isRequestWithdraw: true,
      },
    });
  };

  return (
    <WithdrawMoneyComponent
      isVisible={isVisible}
      onModalClose={onModalClose}
      data={orderList}
      onClickSendWithdrawRequest={handelSendWithdrawRequest}
      onClickGetUnSendWithdrawRequestList={handelGetUnSendWithdrawRequestList}
      onClickGetSendedWithdrawRequestList={handelGetSendedWithdrawRequestList}
    />
  );
};
WithdrawMoneyComponentContainer.propTypes = {
  orderList: PropTypes.array,
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    withdrawRequestFlowRequest: PropTypes.func.isRequired,
    getMyRentedSoldOrderListFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
};

WithdrawMoneyComponentContainer.defaultProps = {
  orderList: [],
  isVisible: false,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...OrderActionCreators,
  }, dispatch),
});

const mapStateToProps = state => ({
  orderList: orderListDataSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithdrawMoneyComponentContainer);
