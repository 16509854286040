import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from '../../../../components/ModalComponent';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import ReduxStatus from '../../../../constants/ReduxStatus';

const ChangePasswordComponent = ({
  reduxStatus,
  reduxError,
  handleChangePassword,
  resetStatus,
  setIsShowPopoverContent,
  setShowChangePasswordModal,
  showChangePasswordModal,
}) => {
  const [oldPasswordInput, setOldPasswordInput] = useState('');
  const [newPasswordInput, setNewPasswordInput] = useState('');
  useEffect(() => {
    setIsShowPopoverContent(true);
    if (showChangePasswordModal) {
      resetStatus();
      setOldPasswordInput('');
      setNewPasswordInput('');
    }
  }, [showChangePasswordModal]);
  useEffect(() => {
    if (reduxStatus === ReduxStatus.STATUS_NORMAL) {
      setShowChangePasswordModal(false);
    }
  }, [reduxStatus]);
  const onChangePassword = () => {
    handleChangePassword({
      oldPassword: oldPasswordInput,
      newPassword: newPasswordInput,
    });
  };

  return (
    <div>
      <ModalComponent
        key="modal"
        isVisible={showChangePasswordModal}
        onModalClose={() => { setShowChangePasswordModal(false); }}
        onModalConfirmed={onChangePassword}
        title="變更密碼"
        content={(
          <ReduxStatusVisualizedComponent
            showModalOnError
            reduxStatus={reduxStatus}
            modalFailureContent={reduxError}
          >
            <div>
              <div>請輸入原密碼</div>
              <input
                type="password"
                value={oldPasswordInput}
                onChange={event => setOldPasswordInput(event.target.value)}
              />
              <div>請輸入新密碼</div>
              <input
                type="password"
                value={newPasswordInput}
                onChange={event => setNewPasswordInput(event.target.value)}
              />
              <div>(密碼必需至少7個字元，且包含至少1個小寫字母、1個大寫字母、及1個數字)</div>
            </div>
          </ReduxStatusVisualizedComponent>
        )}
      />
    </div>
  );
};

ChangePasswordComponent.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  handleChangePassword: PropTypes.func.isRequired,
  resetStatus: PropTypes.func.isRequired,
  setIsShowPopoverContent: PropTypes.func,
  setShowChangePasswordModal: PropTypes.func.isRequired,
  showChangePasswordModal: PropTypes.bool.isRequired,
};

ChangePasswordComponent.defaultProps = {
  reduxError: null,
  setIsShowPopoverContent: () => {},
};

export default ChangePasswordComponent;
