import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Assets from '../../../../constants/Assets';
import SlideComponent from '../../../../components/SlideComponent/SlideComponent';
import RentalSlide from './RentalSlide';
import Paths from '../../../../routes/Paths';
import Button from '../../../../components/Button';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import { FontSize } from '../../../../constants/FontSize';

const styles = {
  mainContainer: {
    textAlign: 'center',
    color: 'black',
  },
  topContainer: {
    position: 'relative',
    color: 'white',
  },
  titleContainer: {
    display: 'flex',
  },
  title: {
    fontSize: '60px',
    color: 'white',
  },
  textContent: {
    margin: '0 0 40px 0',
    width: 'auto',
    lineHeight: '30px',
    fontSize: '18px',
  },
  providePic: {
    width: '100vw',
    height: 'auto',
    marginLeft: 'calc(50% - 50vw)',
    position: 'relative',
    objectFit: 'cover',
  },
  provideTextContainer: {
    whiteSpace: 'normal',
    position: 'absolute',
    textAlign: 'left',
    marginLeft: '60%',
    bottom: '200px',
    right: '10%',
    fontSize: '18px',
    padding: '20px',
  },
  informationContent: {
    height: 'auto',
    maxWidth: '600px',
    minWidth: '300px',
    margin: '100px auto 90px auto',
    fontSize: '18px',
    lineHeight: '40px',
    letterSpacing: '1px',
  },
  rentalContainer: {
    margin: '116px auto 0 auto',
    fontSize: FontSize.title,
    fontWeight: 'bold',
  },
  rentalSlideContainer: {
    height: 'auto',
    margin: '0 auto',
    backgroundColor: 'white',
    display: 'flex',
    position: 'relative',
    padding: '0px 100px',
  },
  rentalSlideMobileContainer: {
    height: 'auto',
    margin: '0 auto',
    backgroundColor: 'white',
    display: 'flex',
    position: 'relative',
    padding: '0 10px',
  },
  learnMore: {
    textDecoration: 'underline',
    marginTop: '40px',
  },
  icon: {
    marginRight: '10px',
  },
  rentButtonContainer: {
    margin: '40px 0 100px 0',
  },
};

const ProvidePage = ({
  data,
}) => {
  const renderSlider = (
    <SlideComponent
      data={data}
      dot
      arrow
      slideDirection="horizontal"
      layout={data.map((item, index) => (
        <RentalSlide
          key={item.imgUrl}
          index={index}
          imgUrl={item.imgUrl}
        />
      ))}
    />
  );
  return (
    <div style={styles.mainContainer}>
      <div style={styles.topContainer}>
        <Link to={Paths.SUBMIT_BAG}>
          <Default>
            <img alt="" src={Assets.howItWorksBanner1} style={styles.providePic} />
          </Default>
          <Mobile>
            <img alt="" src={Assets.howItWorksBannerMobile1} style={styles.providePic} />
          </Mobile>
        </Link>
      </div>

      <h1 style={styles.rentalContainer}>
        Rental Method
      </h1>
      <Default>
        <div style={styles.rentalSlideContainer}>
          {renderSlider}
        </div>
      </Default>
      <Mobile>
        <div style={styles.rentalSlideMobileContainer}>
          {renderSlider}
        </div>
      </Mobile>

      <div style={styles.rentButtonContainer}>
        <Link to={Paths.SUBMIT_BAG}>
          <Button text="立即出租" />
        </Link>
      </div>
    </div>
  );
};

ProvidePage.propTypes = {
  data: PropTypes.array.isRequired,
};


export default ProvidePage;
