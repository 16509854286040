import React from 'react';
import Header from '../../Parts/Header';
import Title from '../../Parts/Title';
import {
  useVideo,
} from '../../../../constants/Assets';


import styles from './styles.module.scss';

const StartRecord = () => (
  <div className={styles.container}>
    <Header />
    <div className={styles.content}>
      <div className={styles.imgContainer}>
        <img src={useVideo} alt="holdPhone" className={useVideo.holdPhone} />
      </div>
      <div className={styles.contentText}>
        <Title>
          準備開始拍攝您的臉部辨識影片
        </Title>
        <div className={styles.text}>
          請置於光線充足之處，並遵循下一個畫面的指示
        </div>
      </div>
    </div>

  </div>
);


export default StartRecord;
