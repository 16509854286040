import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MemberCouponComponent from './MemberCouponComponent';
import ActionCreators from '../../ActionCreator';
import { couponCodeTypeId } from '../../../../constants/Type';


import {
  reduxStatusSelector,
  reduxErrorSelector,
  isLogInSelector,
  memberCouponListSelector,
} from '../../selector';


const MemberCouponComponentContainer = ({
  isLogin,
  isVisible,
  onModalClose,
  reduxStatus,
  reduxError,
  orderType,
  memberCouponList,
  isReadMode,
  onSelectedMemberCoupon,
  onMemberCouponCount,
  actions: {
    getMemberCouponListFlowRequest,
  },
  inputCouponText,
}) => {
  if (memberCouponList) {
    let count = 0;

    memberCouponList.forEach((coupon) => {
      const isHidden = R.pathOr(false, ['coupon', 'isHidden'], coupon);
      if (!isHidden) count += 1;
    });

    onMemberCouponCount(count);
  }

  useEffect(() => {
    if (isLogin) {
      getMemberCouponListFlowRequest();
    }
  }, []);

  useEffect(() => {
    if (isLogin && isVisible) {
      getMemberCouponListFlowRequest();
    }
  }, [isVisible]);

  useEffect(() => {
    const selectCoupon = memberCouponList.filter((coupon) => {
      const couponCode = R.path(['coupon', 'couponCode'], coupon);
      const couponTypeId = R.path(['coupon', 'couponTypeId'], coupon);
      const isOverDate = moment().isAfter(R.path(['endDate'], coupon));

      if (orderType === 'rent') {
        return couponCode === inputCouponText && (
          couponTypeId === couponCodeTypeId.rentPrice || couponTypeId === couponCodeTypeId.depositPrice)
          && !isOverDate;
      }
      return couponCode === inputCouponText && couponTypeId === couponCodeTypeId.sellPrice && !isOverDate;
    });

    if (selectCoupon) {
      onSelectedMemberCoupon(selectCoupon[0]);
    }
  }, [inputCouponText]);

  return (
    <MemberCouponComponent
      isReadMode={isReadMode}
      orderType={orderType}
      memberCouponList={memberCouponList}
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      isVisible={isVisible}
      onModalClose={onModalClose}
      onSelectedMemberCoupon={onSelectedMemberCoupon}
    />
  );
};

MemberCouponComponentContainer.propTypes = {
  reduxStatus: PropTypes.string,
  orderType: PropTypes.string,
  reduxError: PropTypes.string,
  isLogin: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
  isReadMode: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onSelectedMemberCoupon: PropTypes.func,
  onMemberCouponCount: PropTypes.func,
  memberCouponList: PropTypes.array,
  inputCouponText: PropTypes.string,
  actions: PropTypes.shape({
    getMemberCouponListFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
};

MemberCouponComponentContainer.defaultProps = {
  isVisible: false,
  isReadMode: false,
  orderType: 'rent',
  memberCouponList: [],
  onSelectedMemberCoupon: () => {},
  reduxStatus: null,
  reduxError: null,
  onMemberCouponCount: () => {},
  inputCouponText: '',
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  isLogin: isLogInSelector(state),
  memberCouponList: memberCouponListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberCouponComponentContainer);
