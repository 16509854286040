import React from 'react';

import styles from './styles.module.scss';

const Header = () => (
  <div
    className={styles.container}
  >
    By Ohphire
  </div>
);

export default Header;
