import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import * as R from 'ramda';
import MemberHeader from '../../../Member/components/MemberHeader';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import Mobile from '../../../../components/RWDComponent/Mobile';
import ProductTerminateComponent from '../ProductTerminateComponent';
import Paths from '../../../../routes/Paths';
import { Colors } from '../../../../constants/Color';
import PaginationComponent from '../../../../components/PaginationComponent';
import MyProductItemComponent from '../MyProductItemComponent';
import Default from '../../../../components/RWDComponent/Default';
import * as LocalStorageUtils from '../../../../utils/LocalStorageUtils';
import LocalStorageKey from '../../../../constants/LocalStorageKey';

const styles = {
  reduxStyle: {
    width: '100%',
  },
  mainContainer: {
    paddingTop: 200,
    margin: '0px auto',
    width: '100%',
  },
  mainMobileContainer: {
    paddingTop: 120,
    margin: '0px auto',
    width: '100%',
  },
  tableHeader: {
    margin: '10px 0px',
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingBottom: 10,
    borderBottom: '1px solid #BABABA',
    borderTop: '3px solid #BABABA',
  },
  width5: {
    fontSize: 10,
    width: '5%',
  },
  width17: {
    fontSize: 10,
    width: '17%',
  },
  width15: {
    fontSize: 10,
    width: '15%',
  },
  bagContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '50px',
  },
  bagItemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bagItemMobileContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '50px',
  },
  functionButton: {
    cursor: 'pointer',
    outline: 'none',
    border: '1px solid #AE4200',
    color: 'black',
    width: 90,
    height: 27,
    fontFamily: 'Microsoft JhengHei Regular',
    fontWeight: 200,
  },
  uploadButton: {
    width: '120px',
    height: '45px',
    padding: '10px 20px',
    outline: 'none',
    cursor: 'pointer',
    margin: '20px auto',
    textAlign: 'center',
    border: `2px ${Colors.introRightTitle} solid`,
  },
  loadMoreButtonContainer: {
    width: '100%',
    textAlign: 'center',
    margin: '20px 0px',
  },
  loadMoreButton: {
    margin: '5px auto',
  },
  myProductContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  productListContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 'auto',
  },
  invisibleMyProduct: {
    height: 1,
    opacity: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  paginationContainer: {
    zIndex: 10,
    margin: '20px 0px',
  },
  opTabContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '10px 0px',
  },
  opTab: {
    fontSize: 16,
    margin: '0px 120px',
    cursor: 'pointer',
    outline: 'none',
  },
  opTabSelected: {
    fontSize: 16,
    margin: '0px 120px',
    color: Colors.introRightTitle,
    cursor: 'pointer',
    outline: 'none',
  },
  opTabMobile: {
    fontSize: 16,
    margin: '0px 8vw',
    cursor: 'pointer',
    outline: 'none',
  },
  opTabMobileSelected: {
    fontSize: 16,
    margin: '0px 8vw',
    color: Colors.introRightTitle,
    cursor: 'pointer',
    outline: 'none',
  },
};

const OnlineBagPage = ({
  reduxStatus,
  reduxError,
  data,
  onClickOpMangerTag,
  selectOpManger,
}) => {
  const [selectedBagData, setSelectedBagData] = useState(null);
  const [isShowProductTerminateComponent, setIsShowProductTerminateComponent] = useState(false);
  const [productStartIndex, setProductStartIndex] = useState(0);
  const [productEndIndex, setProductEndIndex] = useState(0);
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    const endIndex = data.length < 12 ? data.length - 1 : 12;
    setProductEndIndex(endIndex);
    setIsInitial(false);
  }, [data, isInitial]);

  const onModalClose = () => {
    setIsShowProductTerminateComponent(false);
  };

  const onClickEditButton = (editData) => {
    LocalStorageUtils.writeData(LocalStorageKey.EDIT_COMMODITY, editData);
  };

  const renderProductList = () => {
    if (R.isNil(productStartIndex) || R.isNil(productEndIndex)) {
      return null;
    }

    const bagList = [];
    for (let i = productStartIndex; i <= productEndIndex; i += 1) {
      bagList.push(<MyProductItemComponent
        key={R.pathOr(null, [i, 'id'], data) ? R.pathOr(null, [i, 'id'], data) : R.pathOr(null, [i, 'uuid'], data)}
        inStockSwitch={() => {
          setSelectedBagData(data[i]);
          setIsShowProductTerminateComponent(true);
        }}
        bagData={data[i]}
        onClickEditButton={onClickEditButton}
      />);
    }

    return bagList;
  };

  const content = (
    <ReduxStatusVisualizedComponent
      containerStyle={styles.reduxStyle}
      reduxStatus={reduxStatus}
      showModalOnError
      modalFailureContent={reduxError}
    >
      <MemberHeader
        reduxStatus={reduxStatus}
        path={Paths.ONLINE_PRODUCTS}
        category="onlineBag"
      />

      <Mobile>
        <Link to={Paths.SUBMIT_BAG}>
          <div style={styles.uploadButton}>
            立即上傳
          </div>
        </Link>
      </Mobile>
      <div
        style={styles.myProductContainer}
      >

        <div style={styles.opTabContainer}>
          <Default>
            <div
              style={selectOpManger === 1 ? styles.opTabSelected : styles.opTab}
              onClick={() => {
                onClickOpMangerTag(1);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              onKeyPress={() => {
                onClickOpMangerTag(1);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              role="button"
              tabIndex={0}
            >
              OP管理
            </div>
            <div
              style={selectOpManger === 2 ? styles.opTabSelected : styles.opTab}
              onClick={() => {
                onClickOpMangerTag(2);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              onKeyPress={() => {
                onClickOpMangerTag(2);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              role="button"
              tabIndex={0}
            >
              自助管理
            </div>
            <div
              style={selectOpManger === 3 ? styles.opTabSelected : styles.opTab}
              onClick={() => {
                onClickOpMangerTag(3);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              onKeyPress={() => {
                onClickOpMangerTag(3);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              role="button"
              tabIndex={0}
            >
              草稿
            </div>
          </Default>
          <Mobile>
            <div
              style={selectOpManger === 1 ? styles.opTabMobileSelected : styles.opTabMobile}
              onClick={() => {
                onClickOpMangerTag(1);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              onKeyPress={() => {
                onClickOpMangerTag(1);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              role="button"
              tabIndex={0}
            >
              OP管理
            </div>
            <div
              style={selectOpManger === 2 ? styles.opTabMobileSelected : styles.opTabMobile}
              onClick={() => {
                onClickOpMangerTag(2);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              onKeyPress={() => {
                onClickOpMangerTag(2);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              role="button"
              tabIndex={0}
            >
              自助管理
            </div>
            <div
              style={selectOpManger === 3 ? styles.opTabMobileSelected : styles.opTabMobile}
              onClick={() => {
                onClickOpMangerTag(3);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              onKeyPress={() => {
                onClickOpMangerTag(3);
                setProductStartIndex(0);
                setIsInitial(true);
              }}
              role="button"
              tabIndex={0}
            >
              草稿
            </div>
          </Mobile>

        </div>
        <div style={styles.productListContainer}>
          {renderProductList()}
          <Default>
            <MyProductItemComponent style={styles.invisibleMyProduct} key="invisibleItem1" />
            <MyProductItemComponent style={styles.invisibleMyProduct} key="invisibleItem2" />
            <MyProductItemComponent style={styles.invisibleMyProduct} key="invisibleItem3" />
            <MyProductItemComponent style={styles.invisibleMyProduct} key="invisibleItem4" />
          </Default>
        </div>
        <div style={styles.paginationContainer}>
          <PaginationComponent
            total={data.length || 0}
            pageSize={12}
            onIndexChange={(start, end) => {
              setProductStartIndex(start);
              setProductEndIndex(end);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          />
        </div>
      </div>
    </ReduxStatusVisualizedComponent>
  );

  return (
    <div>
      <ProductTerminateComponent
        onModalClose={onModalClose}
        isVisible={isShowProductTerminateComponent}
        bagData={selectedBagData}
      />

      {/* <BagEditComponent
        reduxStatus
        isVisible={isShowEditBagComponent}
        onModalClose={onModalClose}
        bagId={selectedBagId}
        bagData={selectedBagData}
      /> */}

      <div style={styles.mainContainer} key="baseContainer">
        {content}
      </div>

    </div>
  );
};

OnlineBagPage.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  data: PropTypes.array,
  onClickOpMangerTag: PropTypes.func.isRequired,
  selectOpManger: PropTypes.bool,
};

OnlineBagPage.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: null,
  selectOpManger: false,
};

export default OnlineBagPage;
