import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import ContactPage from './ContactPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
} from '../../selector';


const ContactContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    resetStatus,
    sendContactFlowRequest,
  },
}) => {
  useEffect(() => {
    resetStatus();
    return resetStatus;
  }, []);
  const onSendContact = (data) => {
    sendContactFlowRequest(data);
  };
  return (
    <ContactPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      onSendContact={onSendContact}
    />
  );
};

ContactContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,

  actions: PropTypes.shape({
    resetStatus: PropTypes.func,
    sendContactFlowRequest: PropTypes.func,
  }).isRequired,
};

ContactContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactContainer);
