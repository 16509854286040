import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_NEGOTIATION_LIST'),
  ...createAsyncActionTypesArray('SUBMIT_NEGOTIATION'),
  ...createAsyncActionTypesArray('NEGOTIATION_WITH_OH_PHIRE'),
  ...createAsyncActionTypesArray('ACCEPT_AMOUNT'),
  ...createAsyncActionTypesArray('REPLY_TO_MESSAGE'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_NEGOTIATION_LIST_FLOW'),
  ...createAsyncActionTypesArray('SUBMIT_NEGOTIATION_FLOW'),
  ...createAsyncActionTypesArray('NEGOTIATION_WITH_OH_PHIRE_FLOW'),
  ...createAsyncActionTypesArray('ACCEPT_AMOUNT_FLOW'),
  ...createAsyncActionTypesArray('REPLY_TO_MESSAGE_FLOW'),

  // ----- STATUS -----
  'RESET_STATUS',

  { prefix: 'NEGOTIATION' },
);
