import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActionCreators from '../../ActionCreator';
import TransactionPage from './TransactionPage';
import OrderActionCreators from '../../../Order/ActionCreator';
import TapPayService from '../../../../lib/TapPayService';
import ReduxStatus from '../../../../constants/ReduxStatus';


import {
  orderDetailErrorSelector,
  orderDetailDataStatusSelector,
  orderDetailReduxStatusSelector,
} from '../../../Order/selector';

import {
  transactionReduxStatusSelector,
  transactionErrorSelector,
} from '../../selector';


const transactionContainer = ({
  orderId,
  orderData,
  actions: {
    orderDetailFlowRequest,
    transactionFlowRequest,
    resetTransactionStatus,
  },
  orderDetailError,
  transactionError,
  orderDetailReduxStatus,
  transactionReduxStatus,
}) => {
  const getPrimeResult = (result) => {
    if (result.status === 0) {
      transactionFlowRequest({
        prime: result.card.prime,
        orderId,
      });
    }
  };

  useEffect(() => {
    TapPayService.loadScript(getPrimeResult);
  }, []);

  useEffect(() => {
    orderDetailFlowRequest({ orderId });
  }, []);

  const handleOnModalConfirm = () => {
    if (transactionReduxStatus === ReduxStatus.STATUS_NORMAL) {
      resetTransactionStatus();
    }
  };

  return (
    <TransactionPage
      orderDetailError={orderDetailError}
      transactionError={transactionError}
      orderData={orderData}
      transactionReduxStatus={transactionReduxStatus}
      orderDetailReduxStatus={orderDetailReduxStatus}
      onModalConfirm={handleOnModalConfirm}
    />
  );
};
transactionContainer.propTypes = {
  actions: PropTypes.shape({
    orderDetailFlowRequest: PropTypes.func.isRequired,
    transactionFlowRequest: PropTypes.func.isRequired,
    resetTransactionStatus: PropTypes.func.isRequired,
  }).isRequired,
  orderData: PropTypes.object,
  orderId: PropTypes.string,
  orderDetailReduxStatus: PropTypes.string,
  transactionReduxStatus: PropTypes.string,
  orderDetailError: PropTypes.object,
  transactionError: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...OrderActionCreators,
  }, dispatch),
});

transactionContainer.defaultProps = {
  orderData: null,
  orderDetailReduxStatus: null,
  transactionReduxStatus: null,
  orderDetailError: null,
  transactionError: null,
  orderId: '0',
};

const mapStateToProps = state => ({
  orderData: orderDetailDataStatusSelector(state),
  orderDetailReduxStatus: orderDetailReduxStatusSelector(state),
  transactionReduxStatus: transactionReduxStatusSelector(state),
  transactionError: transactionErrorSelector(state),
  orderDetailError: orderDetailErrorSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(transactionContainer);
