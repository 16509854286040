import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FollowComponent from './FollowComponent';
import ActionCreators from '../../modules/Member/ActionCreator';
import {
  memberFollowingListSelector,
  memberIdSelector,
  isLogInSelector,
} from '../../modules/Member/selector';

const FollowComponentContainer = ({
  actions: {
    toggleFollowFlowRequest,
    getFollowingListFlowRequest,
  },
  targetId,
  userId,
  followingList,
  isLogin,
  style,
}) => {
  useEffect(() => {
    if (isLogin) {
      getFollowingListFlowRequest();
    }
  }, []);

  const handleOnToggleFollow = () => {
    toggleFollowFlowRequest({ targetId });
  };

  return (
    <FollowComponent
      isLogin={isLogin}
      isMe={Number(userId) === Number(targetId)}
      targetId={targetId}
      followingList={followingList}
      onToggleFollow={handleOnToggleFollow}
      style={style}
    />
  );
};

FollowComponentContainer.propTypes = {
  actions: PropTypes.shape({
    toggleFollowFlowRequest: PropTypes.func.isRequired,
    getFollowingListFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  targetId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  userId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  followingList: PropTypes.array,
  isLogin: PropTypes.bool,
  style: PropTypes.object,
};

FollowComponentContainer.defaultProps = {
  followingList: [],
  userId: 0,
  isLogin: false,
  style: {},
};

const mapStateToProps = state => ({
  followingList: memberFollowingListSelector(state),
  userId: memberIdSelector(state),
  isLogin: isLogInSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FollowComponentContainer);
