export const Colors = {
  footerText: '#FFFFFF',
  footerSubTitle: '#727171',
  horizontalLine: '#3E3A39',
  introRightTitle: '#AE4200',
  separateLine: 'rgba(0,0,0,0.12)',
  buyBlackColor: '#fdf5e6',
  orderBrown: '#927764',
};


export default {
  Colors,
};
