import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import HeaderMemberComponent from './HeaderMemberComponent';
import ActionCreators from '../../../Cart/ActionCreator';
import MemberActionCreator from '../../ActionCreator';

import {
  reduxStatusSelector,
  reduxErrorSelector,
  isLogInSelector,
  memberNameSelector,
  memberSocialLoginSelector,
  memberCommunityNameSelector,
  memberNotificationListSelector,
} from '../../selector';

import { cartListSelector } from '../../../Cart/selector';

const HeaderMemberContainer = ({
  reduxStatus,
  reduxError,
  isLogin,
  memberName,
  communityName,
  memberSocialLogin,
  style,
  cartList,
  notificationList,
  actions: {
    resetStatus,
    deleteCartFlowRequest,
    changePasswordFlowRequest,
    hadReadNotificationFlowRequest,
    allReadNotificationFlowRequest,
  },
  isScrolled,
  isShowCommodityList,
  headerScrolled,
}) => {
  const onRemoveItem = (type, itemId) => {
    if (type === 'cart') {
      deleteCartFlowRequest(itemId);
    }
  };

  const handleOnHadReadNotification = (notificationId) => {
    hadReadNotificationFlowRequest({ notificationId });
  };

  return (
    <HeaderMemberComponent
      isLogin={isLogin}
      memberName={memberName}
      communityName={communityName}
      memberSocialLogin={memberSocialLogin}
      style={style}
      cartList={cartList}
      notificationList={notificationList}
      onRemoveItem={onRemoveItem}
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      handleChangePassword={changePasswordFlowRequest}
      resetStatus={resetStatus}
      onHadReadNotification={handleOnHadReadNotification}
      isScrolled={isScrolled}
      isShowCommodityList={isShowCommodityList}
      headerScrolled={headerScrolled}
      onAllReadNotification={allReadNotificationFlowRequest}
    />
  );
};

HeaderMemberContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    deleteCartFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    changePasswordFlowRequest: PropTypes.func.isRequired,
    fbLogInFlowRequest: PropTypes.func.isRequired,
    hadReadNotificationFlowRequest: PropTypes.func.isRequired,
    allReadNotificationFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  isLogin: PropTypes.bool,
  memberSocialLogin: PropTypes.string,
  communityName: PropTypes.string,
  memberName: PropTypes.string,
  cartList: PropTypes.array,
  notificationList: PropTypes.array,
  style: PropTypes.shape({}),
  isShowCommodityList: PropTypes.array,
  isScrolled: PropTypes.bool,
  headerScrolled: PropTypes.bool,
};

HeaderMemberContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  memberName: '',
  isLogin: false,
  communityName: '',
  memberSocialLogin: null,
  style: {},
  cartList: [],
  notificationList: [],
  isShowCommodityList: [],
  isScrolled: false,
  headerScrolled: false,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  isLogin: isLogInSelector(state),
  memberName: memberNameSelector(state),
  communityName: memberCommunityNameSelector(state),
  memberSocialLogin: memberSocialLoginSelector(state),
  cartList: cartListSelector(state),
  notificationList: memberNotificationListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...MemberActionCreator,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderMemberContainer);
