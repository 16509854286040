import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ReduceDepositItemComponent from '../ReduceDepositItemComponent';
import InfoModalComponent from '../../../../../../components/InfoModalComponent';
import Default from '../../../../../../components/RWDComponent/Default';
import Mobile from '../../../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    width: '90%',
    padding: 50,
    backgroundColor: 'white',
    position: 'relative',
    zIndex: 9999,
  },
  mainMobileContainer: {
    width: '95vw',
    padding: 20,
    backgroundColor: 'white',
    position: 'relative',
    zIndex: 9999,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  add: {
    border: '1px solid #AE4200',
    color: '#939598',
    fontSize: 16,
    width: 162,
    height: 54,
    fontFamily: 'Microsoft JhengHei Regular',
    textAlign: 'center',
    padding: '10px 0px',
    outline: 'none',
  },
  title: {
    color: '#58595B',
    fontSize: 20,
    fontFamily: 'Microsoft JhengHei Regular',
  },
  table: {
    marginTop: 30,
    width: '100%',
  },
  selectHeader: {
    padding: '10px 0px',
    fontSize: 16,
    width: '10%',
    color: '#D1D3D4',
  },
  selectHeaderMobile: {
    padding: '10px 0px',
    fontSize: 16,
    width: '10%',
    color: '#D1D3D4',
  },
  accountHeader: {
    fontSize: 16,
    width: '10%',
    color: '#D1D3D4',
  },
  bagHeader: {
    fontSize: 16,
    width: '65%',
    color: '#D1D3D4',
  },
  optionHeader: {
    fontSize: 16,
    width: '15%',
    color: '#D1D3D4',
  },
  reduceDeposit: {
    color: '#58595B',
    fontSize: 20,
  },
  headerDivider: {
    borderTop: '2px solid #939598',
    borderBottom: '1px solid #D1D3D4',
  },
};

const ReduceDepositMainComponent = ({
  isVisible,
  onModalClose,
  data,
  onAddReduceDeposit,
  onDeleteReduceDepositBag,
  onDeleteReduceDepositUser,
  onEditReduceDepositBag,
}) => {
  // before : [{owner, user, bag},  {owner, user, bag}, ...]
  // after  : [{user:{}, bags:[]}, {user:{}, bags:[]} ]
  const arrangeData = () => {
    const arrangeReduceDepositList = [];
    data.forEach((item, dataIndex) => {
      if (R.isEmpty(arrangeReduceDepositList)) {
        arrangeReduceDepositList.push({ user: item.user, bags: [item.bag] });
      } else {
        let hasThisUser = false;
        let arrangeIndex = 0;
        arrangeReduceDepositList.forEach((arrangeItem, index) => {
          if (arrangeItem.user.id === item.userId) {
            hasThisUser = true;
            arrangeIndex = index;
            return true;
          }
          return false;
        });
        if (hasThisUser) {
          arrangeReduceDepositList[arrangeIndex].bags.push(data[dataIndex].bag);
        } else {
          arrangeReduceDepositList.push({ user: item.user, bags: [item.bag] });
        }
      }
    });
    return arrangeReduceDepositList;
  };
  const content = (
    <React.Fragment>
      <div style={styles.titleContainer}>
        <div style={styles.reduceDeposit}>清單 Deposit List</div>
        <button style={styles.add} type="button" onClick={onAddReduceDeposit}>新增</button>
      </div>

      <table style={styles.table}>
        <tbody>
          <tr style={styles.headerDivider}>
            <Default>
              <th style={styles.selectHeader}>選取</th>
            </Default>
            <Mobile>
              <th style={styles.selectHeaderMobile}>選取</th>
            </Mobile>
            <th style={styles.accountHeader}>帳戶</th>
            <th style={styles.bagHeader}>包款限制</th>
            <Default>
              <th style={styles.optionHeader}>選項</th>
            </Default>
          </tr>
          {arrangeData().map(item => (
            <ReduceDepositItemComponent
              onEditReduceDepositBag={() => onEditReduceDepositBag(item)}
              onDeleteReduceDepositUser={() => onDeleteReduceDepositUser({ userId: item.user.id })}
              onDeleteReduceDepositBag={onDeleteReduceDepositBag}
              key={item.user.id}
              data={item}
            />
          ))
        }
        </tbody>
      </table>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainContainer}>
              {content}
            </div>
          )}
          containerStyle={{ paddingTop: 30 }}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainMobileContainer}>
              {content}
            </div>
          )}
          containerStyle={{ paddingTop: 30 }}
        />
      </Mobile>
    </React.Fragment>

  );
};

ReduceDepositMainComponent.propTypes = {
  data: PropTypes.array,
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onAddReduceDeposit: PropTypes.func.isRequired,
  onDeleteReduceDepositBag: PropTypes.func.isRequired,
  onDeleteReduceDepositUser: PropTypes.func.isRequired,
  onEditReduceDepositBag: PropTypes.func.isRequired,
};

ReduceDepositMainComponent.defaultProps = {
  data: [],
  isVisible: false,
};

export default ReduceDepositMainComponent;
