import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Link } from '@reach/router';
import Button from '../../../components/Button';
import Assets from '../../../constants/Assets';
import { Colors } from '../../../constants/Color';
import Paths from '../../../routes/Paths';
import Default from '../../../components/RWDComponent/Default';
import Mobile from '../../../components/RWDComponent/Mobile';
import calculateOrderNo from '../../../utils/calculateOrderNo';
import { FontSize } from '../../../constants/FontSize';

const styles = {
  endModalContainer: {
    position: 'relative',
    height: '400px',
  },
  endModalMobileContainer: {
    position: 'relative',
    height: '450px',
    color: 'white',
    textAlign: 'center',
  },
  img: {
    position: 'absolute',
    width: 'calc(100% + 48px) ',
    height: 'calc(100% + 48px) ',
    left: '-24px',
    top: '-24px',
  },
  imgMobile: {
    position: 'absolute',
    width: 'calc(100% + 48px) ',
    height: 'calc(100% + 48px) ',
    left: '-24px',
    top: '-24px',
  },
  endTextContainer: {
    position: 'absolute',
    width: '100%',
    height: '100px',
    top: '10px',
    textAlign: 'center',
  },
  endTextContainerMobile: {
    position: 'absolute',
    width: '100%',
    height: 'auto',
    top: '30px',
  },
  endTitle: {
    fontSize: FontSize.body,
    color: 'black',
    letterSpacing: '3px',
    fontWeight: 'bold',
  },
  endSubTitle: {
    margin: '10px 0',
    fontSize: FontSize.subTitle,
    color: 'black',
  },
  endText: {
    marginTop: '50px',
    color: 'black',
  },
  orderNo: {
    textDecoration: 'underline',
    marginLeft: 5,
  },
  endButton: {
    marginTop: '40px',
    width: '120px',
    height: '40px',
    color: 'black',
    border: '2px grey solid',
    backgroundColor: 'transparent',
    marginRight: '15px',
    fontSize: '16px',
  },
  endMobileButton: {
    marginTop: '40px',
    width: '110px',
    height: '40px',
  },
  goBackOrderList: {
    backgroundColor: 'transparent',
    border: `2px ${Colors.introRightTitle} solid`,
    marginRight: '30px',
  },
  buttonContainer: {
    width: '100%',
    height: '100px',
    color: 'black',
    marginTop: '50px',
  },
  logo: {
    width: '100px',
    marginBottom: '40px',
  },
};

const EndModalComponent = ({
  orderList,
  onModalConfirmed,
  orderListIndex,
}) => {
  const textContent = mobileButtonStyle => (
    <React.Fragment>
      <img
        src={Assets.orderEndLogo}
        style={styles.logo}
        alt=""
      />
      <div style={styles.endSubTitle}>歡迎您加入永續時尚</div>
      <div style={styles.endSubTitle}>與OP 永續時尚共享平台一起讓地球更美好</div>
      <div style={styles.buttonContainer}>
        <div>
          訂單編號
          <span style={styles.orderNo}>
            {calculateOrderNo(R.pathOr('', [orderListIndex, 'id'], orderList))}
          </span>
        </div>
        <br />
        <div>
          在評論區留下租借評論獲得評論＄50折扣優惠碼
        </div>
        <div>
          <Button
            text="返回訂單頁"
            style={{ ...styles.endButton, ...mobileButtonStyle }}
            onClick={onModalConfirmed}
          />
          <Link to={Paths.HOME}>
            <Button text="繼續選購" style={{ ...styles.endButton, ...mobileButtonStyle }} />
          </Link>
        </div>
      </div>
    </React.Fragment>
  );

  const content = (
    <React.Fragment>
      <Default>
        <img alt="" src={Assets.orderEnd} style={styles.img} />
        <div style={styles.endTextContainer}>
          {textContent()}
        </div>
      </Default>
      <Mobile>
        <img alt="" src={Assets.orderEnd} style={styles.imgMobile} />
        <div style={styles.endTextContainerMobile}>
          {textContent(styles.endMobileButton)}
        </div>
      </Mobile>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Default>
        <div style={styles.endModalContainer}>
          {content}
        </div>
      </Default>
      <Mobile>
        <div style={styles.endModalMobileContainer}>
          {content}
        </div>
      </Mobile>
    </React.Fragment>
  );
};

EndModalComponent.propTypes = {
  orderList: PropTypes.array,
  onModalConfirmed: PropTypes.func.isRequired,
  orderListIndex: PropTypes.number,
};

EndModalComponent.defaultProps = {
  orderList: [],
  orderListIndex: null,
};

export default EndModalComponent;
