import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import ReduxStatus from '../../../../constants/ReduxStatus';
import Assets from '../../../../constants/Assets';
import InfoTitle from '../../../../components/InfoTitle';
import { Colors } from '../../../../constants/Color';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    margin: '160px 20px 0px 20px',
  },
  contactContainer: {
    position: 'relative',
    backgroundColor: 'white',
    padding: '220px 80px 60px 60px',
    height: '915px',
    width: '825px',
    top: '-440px',
    left: '100px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  contactMobileContainer: {
    backgroundColor: 'white',
    marginBottom: '50px',
    height: 'auto',
    width: '95vw',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  leftContainer: {
    display: 'inline-block',
    margin: '10px 0 0 0',
    fontSize: '16px',
  },
  contactForm: {
    margin: '0 0 44px 0',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  input: {
    marginBottom: '20px',
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid #DBDBDB',
    width: '670px',
    fontSize: '16px',
  },
  textArea: {
    marginTop: '20px',
    outline: 'none',
    border: 'none',
    width: '670px',
    borderBottom: '1px solid #DBDBDB',
    height: '150px',
  },
  message: {
    textAlign: 'center',
    border: '1px #DDDDDD solid',
    margin: '20px 0 -10px 0 ',
  },
  button: {
    margin: '36px 0 0 0 ',
    outline: 'none',
    cursor: 'pointer',
    padding: '0px 10px',
    borderRadius: 3,
    color: 'white',
    border: '1px solid grey',
    height: '60px',
    width: '200px',
    backgroundColor: Colors.introRightTitle,
  },
  table: {
    marginTop: '100px',
    lineHeight: '40px',
  },
  tr: {
    borderBottom: '1px solid #ddd',
  },
  titleTd: {
    width: '250px',
    fontWeight: 'bold',
  },
  contactTd: {
    width: '280px',
  },
  subtitle: {
    width: '100%',
    color: '#000000',
    fontSize: '20px',
    letterSpacing: '3.33px',
    lineHeight: '28px',
    textAlign: 'left',
    margin: '20px 0 10px 0',
  },
  pic: {
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    marginTop: '115px',
    maxHeight: '60vw',
    objectFit: 'cover',
  },
  container: {
    height: 'auto',
  },
  mobileInfoTitle: {
    width: '20px',
    fontWeight: 'bold',
  },
  mobileMainContainer: {
    margin: '50px 20px 0px 20px',
  },
};

const ContactPage = ({
  reduxStatus,
  reduxError,
  onSendContact,
}) => {
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [orderNoInput, setOrderNoInput] = useState('');
  const [contentInput, setContentInput] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    onSendContact({
      name: nameInput,
      email: emailInput,
      phone: phoneInput,
      orderNo: orderNoInput,
      content: contentInput,
    });

    setNameInput('');
    setEmailInput('');
    setPhoneInput('');
    setOrderNoInput('');
    setContentInput('');
  };

  const contactTable = (
    <table style={styles.table}>
      <thead>
        <tr style={styles.tr}>
          <td style={styles.titleTd}>Line客服</td>
          <td style={styles.contactTd}>@ohphire</td>
        </tr>
      </thead>
      <thead>
        <tr style={styles.tr}>
          <td style={styles.titleTd}>FB粉絲專頁</td>
          <td>OP 永續時尚共享平台</td>
        </tr>
      </thead>
      <thead>
        <tr>
          <td style={styles.titleTd}>客服信箱</td>
          <td>service@ohphire.com</td>
        </tr>
      </thead>
    </table>
  );
  const renderFormBlock = (
    <form onSubmit={onSubmit}>
      <div style={styles.leftContainer}>
        <div>
          <div style={styles.contactForm}>聯絡表單</div>
          <div>*姓名</div>
          <input
            value={nameInput}
            onChange={(event) => { setNameInput(event.target.value); }}
            style={styles.input}
            required
          />
        </div>
        <div>
          <div>*電子信箱</div>
          <input
            value={emailInput}
            onChange={(event) => { setEmailInput(event.target.value); }}
            style={styles.input}
            required
          />
        </div>
        <div>
          <div>訂單編號</div>
          <input
            value={orderNoInput}
            onChange={(event) => { setOrderNoInput(event.target.value); }}
            style={styles.input}
          />
        </div>
        <div>
          <div>連絡電話</div>
          <input
            value={phoneInput}
            onChange={(event) => { setPhoneInput(event.target.value); }}
            style={styles.input}
          />
        </div>
      </div>

      <div style={{ textAlign: 'left' }}>
        *詢問內容
        <textarea
          value={contentInput}
          onChange={(event) => { setContentInput(event.target.value); }}
          style={styles.textArea}
          required
        />
      </div>
      { reduxStatus === ReduxStatus.STATUS_NORMAL ? (
        <div style={styles.message}>
          已收到您的表單
        </div>
      ) : null }
      <button style={styles.button} type="submit">確定送出</button>
    </form>
  );

  const renderContent = (
    <React.Fragment>
      <ReduxStatusVisualizedComponent
        showModalOnError
        reduxStatus={reduxStatus}
        modalFailureContent={reduxError}
      />
      <InfoTitle
        leftTitle="CONTACT"
        leftSubtitle="聯絡我們"
        rightTitle="Contact info"
        content={contactTable}
      />
      <img
        src={Assets.contactPage}
        alt=""
        style={styles.pic}
      />
      <div style={styles.container}>
        <Default>
          <div style={styles.contactContainer}>
            {renderFormBlock}
          </div>
        </Default>
        <Mobile>
          <div style={styles.contactMobileContainer}>
            {renderFormBlock}
          </div>
        </Mobile>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer} key="baseContainer">
          {renderContent}
        </div>
      </Default>
      <Mobile>
        <div style={styles.mobileMainContainer} key="baseContainer">
          {renderContent}
        </div>
      </Mobile>
    </React.Fragment>
  );
};

ContactPage.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  onSendContact: PropTypes.func.isRequired,
};

ContactPage.defaultProps = {
  reduxStatus: '',
  reduxError: '',
};

export default ContactPage;
