import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import * as R from 'ramda';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
// SMS closed due to account name and phone number aren't linked
// import SmsLoginComponent from './SmsLoginComponent';
import OhPhireConstants from '../../../../constants/OhPhireConstants';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';


const LinkText = styled.div`
  cursor: pointer;
  &:hover{
    opacity: 0.5;
    text-decoration:underline;
  }
`;

const SignInLinkText = styled.div`
  text-align: center;
  width: 100%;
  color: black;
  cursor: pointer;
  border: 1px solid #000;
  margin: 10px 0px;
  font-size: 15px;
  height: 70px;
  line-height: 70px;
  &:hover{
    opacity: 0.5;
  }
`;
const styles = {
  mainContainer: {
    height: 'fit-content',
    width: '60vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainMobileContainer: {
    height: 'fit-content',
    width: '90vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 18,
  },
  form: {
    width: '70%',
  },
  reduxStyle: {
    width: '100%',
  },
  pic: {
    width: '50%',
    height: 850,
    objectFit: 'cover',
  },
  input: {
    fontSize: 16,
    width: '100%',
    margin: '20px 0px',
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid #BCBCBC',
    color: 'black',
  },
  button: {
    width: '100%',
    height: 70,
    margin: '10px 0px',
    fontSize: 15,
    color: 'white',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: '#A6A4A4',
    border: '1px solid #A6A4A4',
  },
  login: {
    fontFamily: 'Baskerville, serif',
    textAlign: 'center',
    fontSize: 50,
    color: 'black',
  },
  or: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 100,
    width: '100%',
  },
  textCenter: {
    textAlign: 'center',
    margin: 20,
    fontSize: 16,
  },
  fbContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: 520,
  },
  fbButton: {
    width: '100%',
    height: 70,
    margin: '10px 0px',
    fontSize: 15,
    color: 'white',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: '#4C5F9B',
    border: '1px solid #4C5F9B',
  },
  registerHint: {
    width: '100%',
    textAlign: 'center',
    height: 'fit-content',
    margin: '20px 0px 10px 0px',
    fontSize: 13,
    color: 'black',
  },
  copyright: {
    textAlign: 'center',
    fontWeight: 200,
    color: 'black',
    marginBottom: '50px',
  },
  rememberContainer: {
    width: 'fit-content',
  },
  rememberMarginRight: {
    marginRight: 5,
  },
  forgotPasswordContainer: {
    margin: '10px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  comingSoonLogin: {
    height: '100%',
    width: '100%',
  },
};

const LoginPage = ({
  reduxStatus,
  reduxError,
  handleLogin,
  responseFacebook,
  // SMS closed due to account name and phone number aren't linked
  // onSendSms,
  // onSmsLogin,
  rememberedEmail,
  setIsShowForgotPasswordPage,
  setIsShowRegisterPage,

}) => {
  const [emailInput, setEmailInput] = useState(rememberedEmail);
  const [passwordInput, setPasswordInput] = useState('');
  const [isRememberEmail, setIsRememberEmail] = useState(!R.isEmpty(rememberedEmail));

  const onSubmit = (event) => {
    event.preventDefault();
    handleLogin({
      isRememberEmail,
      email: emailInput,
      password: passwordInput,
    });
  };
  const content = (
    <ReduxStatusVisualizedComponent
      containerStyle={styles.reduxStyle}
      showModalOnError
      reduxStatus={reduxStatus}
      modalFailureContent={reduxError}
    >
      <div style={styles.rightContainer} key="baseContainer">
        <div style={styles.login}>Log in</div>
        {/* SMS closed due to account name and phone number aren't linked */}
        {/* <div style={styles.form}>
          <SmsLoginComponent
            onSendSms={onSendSms}
            onSmsLogin={onSmsLogin}
          />
        </div>
        <div style={styles.or}>or</div> */}
        <form style={styles.form} onSubmit={onSubmit}>
          <input
            placeholder="Email 會員帳號*"
            value={emailInput}
            onChange={(event) => { setEmailInput(event.target.value); }}
            style={styles.input}
            required
          />
          <input
            placeholder="Password 會員密碼*"
            type="password"
            style={styles.input}
            value={passwordInput}
            onChange={(event) => { setPasswordInput(event.target.value); }}
            required
          />
          <div style={styles.forgotPasswordContainer}>
            <div style={styles.rememberContainer}>
              <Checkbox
                defaultChecked={isRememberEmail}
                style={styles.rememberMarginRight}
                onChange={(event) => {
                  setIsRememberEmail(event.target.checked);
                }}
              />
              記住帳號
            </div>
            <LinkText
              onClick={() => { setIsShowForgotPasswordPage(true); }}
            >
              忘記密碼
            </LinkText>
          </div>
          <div style={styles.registerHint}>首次加入會員即贈$100優惠折扣碼</div>
          <SignInLinkText
            onClick={() => setIsShowRegisterPage(true)}
          >
            Register 立即加入會員
          </SignInLinkText>
          <button style={styles.button} type="submit">Sign in 登入</button>
        </form>

        <div style={styles.form}>
          <FacebookLogin
            appId={OhPhireConstants.appID}
            fields="name,email,picture"
            onClick={() => {}}
            callback={responseFacebook}
            isMobile={false}
            disableMobileRedirect
            render={renderProps => (
              <button
                style={styles.fbButton}
                type="button"
                onClick={renderProps.onClick}
              >
                Login with Facebook
              </button>
            )}
          />
        </div>

        <div style={styles.copyright}>
          &copy; 2023 OP Inc. All rights reserved.
        </div>
      </div>
    </ReduxStatusVisualizedComponent>
  );

  return (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          {content}
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          {content}
        </div>
      </Mobile>
    </React.Fragment>
  );
};

LoginPage.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  handleLogin: PropTypes.func.isRequired,
  responseFacebook: PropTypes.func.isRequired,
  // SMS closed due to account name and phone number aren't linked
  // onSendSms: PropTypes.func.isRequired,
  // onSmsLogin: PropTypes.func.isRequired,
  rememberedEmail: PropTypes.string,
  setIsShowForgotPasswordPage: PropTypes.func,
  setIsShowRegisterPage: PropTypes.func,
};

LoginPage.defaultProps = {
  reduxStatus: '',
  reduxError: '',
  rememberedEmail: '',
  setIsShowForgotPasswordPage: null,
  setIsShowRegisterPage: null,
};

export default LoginPage;
