import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import MyPageBagItem from '../../../../components/MyPageBagItem';
import OutfitItem from '../../../../components/OutfitItem';
import MemberProfileHeader from '../MemberProfileHeader';
import { typeId } from '../../../../constants/Type';
import UploadOutfitComponent from '../UploadOutfitComponent';
import OutfitDetailComponent from '../OutfitDetailComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';


const styles = {
  mainContainer: {
    paddingTop: 130,
    margin: '0px auto',
    width: '80%',
  },
  bagListContainer: {
    width: '100%',
    textAlign: 'center',
    height: 'auto',
    maxWidth: 1500,
    flexWrap: 'wrap',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  invisibleBagItemDummy: {
    height: 1,
    opacity: 0,
    width: '200px',
    verticalAlign: 'top',
    cursor: 'pointer',
    margin: '75px 30px',
    display: 'inline-block',
  },
  invisibleBagItemDummyMobile: {
    height: 1,
    opacity: 0,
    width: '100px',
    verticalAlign: 'top',
    cursor: 'pointer',
    margin: '75px 5px',
    display: 'inline-block',
  },
  invisibleOutfitDummy: {
    height: 1,
    opacity: 0,
    width: '270px',
    verticalAlign: 'top',
    cursor: 'pointer',
    margin: '75px 0px',
    display: 'inline-block',
  },
  invisibleOutfitDummyMobile: {
    height: 1,
    opacity: 0,
    width: '100px',
    verticalAlign: 'top',
    cursor: 'pointer',
    margin: '75px 0px',
  },
  tabContainer: {
    marginTop: 10,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '2px solid #eeeeee',
  },
  selectedTabContainer: {
    marginTop: -2,
    width: 180,
    height: 60,
    textAlign: 'center',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '2px solid #BCBEC0',
  },
  unSelectedTabContainer: {
    marginTop: -2,
    height: 60,
    width: 180,
    border: 'none',
    textAlign: 'center',
    borderTop: '2px solid #eeeeee',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedTab: {
    padding: '20px 0px',
    color: 'black',
    fontSize: 16,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },
  unSelectedTab: {
    padding: '20px 0px',
    color: '#939598',
    cursor: 'pointer',
    border: 'none',
    fontSize: 16,
    outline: 'none',
    backgroundColor: 'transparent',
  },
  selectBadge: {
    marginLeft: 3,
    height: 25,
    width: 25,
    fontSize: 10,
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '50%',
    display: 'inlineBlock',
    padding: '4px 0px',
    cursor: 'pointer',
  },
  unSelectBadge: {
    padding: '4px 0px',
    marginLeft: 3,
    height: 25,
    width: 25,
    color: 'white',
    fontSize: 10,
    backgroundColor: '#bbbbbb',
    borderRadius: '50%',
    display: 'inlineBlock',
    cursor: 'pointer',
  },
  reduxStyle: {
    width: '100%',
  },
};

const MemberProfilePage = ({
  isMe,
  memberId,
  id,
  reduxStatus,
  memberProfile,
  style,
}) => {
  const [type, setType] = useState('rent');
  const bags = R.pathOr([], ['bags'], memberProfile);
  const outfits = R.pathOr([], ['outfits'], memberProfile);
  const uri = R.pathOr([], ['uri'], memberProfile);
  const [selectOutfitId, setSelectOutfitId] = useState(0);
  const [isShowUploadOutfitComponent, setIsShowUploadOutfitComponent] = useState(false);
  const [isShowOutfitDetailComponent, setIsShowOutfitDetailComponent] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getType = (bag, types) => {
    switch (types || type) {
      case 'rent':
        return bag.typeId === typeId.rent || bag.typeId === typeId.rentBuy;
      case 'buy':
        return bag.typeId === typeId.buy || bag.typeId === typeId.rentBuy;

      default:
        return '';
    }
  };

  const onClickOutfit = (outfitId) => {
    setSelectOutfitId(outfitId);
    setIsShowOutfitDetailComponent(true);
  };

  const renderTabItem = (criteria = '', label = '', isShowNumberArea = false, numberCount = 0) => (
    <div style={type === criteria ? styles.selectedTabContainer : styles.unSelectedTabContainer}>
      <button
        style={type === criteria ? styles.selectedTab : styles.unSelectedTab}
        type="button"
        onClick={() => setType(criteria)}
      >
        {label}
      </button>
      {
        isShowNumberArea && (
          <div style={type === criteria ? styles.selectBadge : styles.unSelectBadge}>
            { numberCount }
          </div>
        )
      }
    </div>
  );

  return (
    <div>
      <UploadOutfitComponent
        reduxStatus={reduxStatus}
        isVisible={isShowUploadOutfitComponent}
        onModalClose={() => setIsShowUploadOutfitComponent(false)}
      />

      <OutfitDetailComponent
        isVisible={isShowOutfitDetailComponent}
        id={selectOutfitId}
        memberId={memberId}
        onModalClose={() => setIsShowOutfitDetailComponent(false)}
        isMe={isMe}
      />

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        containerStyle={styles.reduxStyle}
      >
        <div style={{ ...styles.mainContainer, ...style }} key="baseContainer">
          <MemberProfileHeader
            isMe={isMe}
            memberId={memberId}
            id={id}
            memberProfile={memberProfile}
            memberAvatarUri={uri}
            onShareOutfit={() => setIsShowUploadOutfitComponent(true)}
          />

          <div style={styles.tabContainer}>
            {renderTabItem('outfit', '貼文')}
            {renderTabItem('rent', '租借', true,
              R.filter(item => getType(item, 'rent'), bags).length)}
            {renderTabItem('buy', '購買', true,
              R.filter(item => getType(item, 'buy'), bags).length)}
          </div>

          {/*
           NOTE: We add four invisible items to the end of the list in order
           to keep the items in the last row being arranged from left.
           The maximum number of items of each row is 5 (since we've limited the
           maximum with of container to be 1500), thus we only need 4 dummy
           items in the last row.
           */}
          <div style={styles.bagListContainer}>
            {type === 'outfit' ? (
              <React.Fragment>
                {outfits.map(outfit => (
                  <OutfitItem
                    key={outfit.id}
                    onClickOutfit={onClickOutfit}
                    outfit={outfit}
                  />
                ))}
                <Default>
                  <div style={styles.invisibleOutfitDummy} />
                  <div style={styles.invisibleOutfitDummy} />
                  <div style={styles.invisibleOutfitDummy} />
                  <div style={styles.invisibleOutfitDummy} />
                </Default>
                <Mobile>
                  <div style={styles.invisibleOutfitDummyMobile} />
                  <div style={styles.invisibleOutfitDummyMobile} />
                  <div style={styles.invisibleOutfitDummyMobile} />
                  <div style={styles.invisibleOutfitDummyMobile} />
                </Mobile>

              </React.Fragment>
            ) : (
              <React.Fragment>
                {R.filter(bag => getType(bag), bags).map(MyPageBagItem)}
                <Default>
                  <div style={styles.invisibleBagItemDummy} />
                  <div style={styles.invisibleBagItemDummy} />
                  <div style={styles.invisibleBagItemDummy} />
                  <div style={styles.invisibleBagItemDummy} />
                </Default>
                <Mobile>
                  <div style={styles.invisibleBagItemDummyMobile} />
                  <div style={styles.invisibleBagItemDummyMobile} />
                  <div style={styles.invisibleBagItemDummyMobile} />
                  <div style={styles.invisibleBagItemDummyMobile} />
                </Mobile>
              </React.Fragment>
            )}
          </div>
        </div>
      </ReduxStatusVisualizedComponent>
    </div>
  );
};

MemberProfilePage.propTypes = {
  memberId: PropTypes.oneOfType(
    [PropTypes.number,
      PropTypes.string],
  ),
  id: PropTypes.oneOfType(
    [PropTypes.number,
      PropTypes.string],
  ),
  isMe: PropTypes.bool,
  reduxStatus: PropTypes.string.isRequired,
  memberProfile: PropTypes.shape({}),
  style: PropTypes.object,
};

MemberProfilePage.defaultProps = {
  memberId: null,
  id: null,
  isMe: null,
  memberProfile: null,
  style: {},
};

export default MemberProfilePage;
