import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
} from 'antd';


const styles = {
  mainContainer: {
    width: '100%',
    height: '100%',
    padding: 20,
  },
  title: {
    fontSize: 35,
    color: 'black',
  },
  subtitle: {
    marginTop: 20,
    fontSize: 15,
  },
};

const CancelOrderModalComponent = ({
  isVisible,
  onModalClose,
  onCancelOrder,
}) => {
  const content = (
    <div style={styles.mainContainer}>
      <div style={styles.title}>取消訂單</div>
      <div style={styles.subtitle}>一旦取消訂單將無法修改，確定要取消嗎?</div>
    </div>
  );


  return (
    <Modal
      isVisible={isVisible}
      visible={isVisible}
      onCancel={onModalClose}
      onOk={() => onCancelOrder()}
      okText="確定"
      cancelText="取消"
    >
      {content}
    </Modal>
  );
};

CancelOrderModalComponent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
};
export default CancelOrderModalComponent;
