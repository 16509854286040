import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  getThemeListRequest,
  getThemeListSuccess,
  getThemeListFailure,
  getThemeBagRequest,
  getThemeBagSuccess,
  getThemeBagFailure,
  resetStatus,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
  themeBags: [],
};

export default handleActions({
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: initialState.reduxStatus },
  }),

  [combineActions(
    getThemeListRequest,
    getThemeBagRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: null },
  }),

  [combineActions(
    getThemeListFailure,
    getThemeBagFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),

  [combineActions(
    getThemeListSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
    error: { $set: null },
  }),

  [combineActions(
    getThemeBagSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    themeBags: { $set: payload },
    error: { $set: null },
  }),

}, initialState);
