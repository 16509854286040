import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getNegotiationListRequest,
  getNegotiationListSuccess,
  getNegotiationListFailure,
  submitNegotiationRequest,
  submitNegotiationSuccess,
  submitNegotiationFailure,

  getNegotiationListFlowRequest,
  getNegotiationListFlowSuccess,
  getNegotiationListFlowFailure,
  submitNegotiationFlowRequest,
  submitNegotiationFlowSuccess,
  submitNegotiationFlowFailure,

  negotiationWithOhPhireFlowRequest,
  negotiationWithOhPhireFlowSuccess,
  negotiationWithOhPhireFlowFailure,
  negotiationWithOhPhireRequest,
  negotiationWithOhPhireSuccess,
  negotiationWithOhPhireFailure,

  acceptAmountFlowRequest,
  acceptAmountFlowSuccess,
  acceptAmountFlowFailure,
  acceptAmountRequest,
  acceptAmountSuccess,
  acceptAmountFailure,

  replyToMessageFlowRequest,
  replyToMessageFlowSuccess,
  replyToMessageFlowFailure,
  replyToMessageRequest,
  replyToMessageSuccess,
  replyToMessageFailure,
} = ActionCreator;

// ---------- TASKS ----------
export function* getNegotiationList(bagId) {
  yield put(getNegotiationListRequest());
  try {
    const result = (yield call(API.getNegotiationList, bagId));
    if (result.status === 200) {
      yield put(getNegotiationListSuccess({
        data: result.data,
      }));
    }
    return result;
  } catch (error) {
    yield put(getNegotiationListFailure(error));
    throw error;
  }
}

export function* submitNegotiation(data) {
  yield put(submitNegotiationRequest());
  try {
    yield call(API.submitNegotiation, data);
    yield put(submitNegotiationSuccess());
  } catch (error) {
    yield put(submitNegotiationFailure(error));
    throw error;
  }
}

export function* negotiationWithOhPhire(data) {
  yield put(negotiationWithOhPhireRequest());
  try {
    yield call(API.negotiationWithOhPhire, data);
    yield put(negotiationWithOhPhireSuccess());
  } catch (error) {
    yield put(negotiationWithOhPhireFailure(error));
    throw error;
  }
}

export function* acceptAmount(data) {
  yield put(acceptAmountRequest());
  try {
    const result = yield call(API.acceptAmount, data);
    yield put(acceptAmountSuccess());
    return result;
  } catch (error) {
    yield put(acceptAmountFailure(error));
    throw error;
  }
}

export function* replyToMessage(data) {
  yield put(replyToMessageRequest());
  try {
    const result = yield call(API.replyToMessage, data);
    yield put(replyToMessageSuccess());
    return result;
  } catch (error) {
    yield put(replyToMessageFailure(error));
    throw error;
  }
}


// ---------- FLOWS ----------

export function* getNegotiationListFlow({ payload }) {
  try {
    const result = yield call(getNegotiationList, payload);
    yield put(getNegotiationListFlowSuccess(result));
  } catch (error) {
    yield put(getNegotiationListFlowFailure(error));
  }
}

export function* submitNegotiationFlow({ payload }) {
  try {
    const result = yield call(submitNegotiation, payload);
    yield put(submitNegotiationFlowSuccess(result));
    yield put(getNegotiationListFlowRequest(payload.bagId));
  } catch (error) {
    yield put(submitNegotiationFlowFailure(error));
  }
}

export function* negotiationWithOhPhireFlow({ payload }) {
  try {
    const result = yield call(negotiationWithOhPhire, payload);
    yield put(negotiationWithOhPhireFlowSuccess(result));
    if (result.status === 200) {
      yield put(getNegotiationListFlowRequest(payload.bagId));
    }
  } catch (error) {
    yield put(negotiationWithOhPhireFlowFailure(error));
  }
}

export function* acceptAmountFlow({ payload }) {
  try {
    const result = yield call(acceptAmount, payload);
    yield put(acceptAmountFlowSuccess(result));
    if (result.status === 200 || result.status === 204) {
      yield put(getNegotiationListFlowRequest(payload.bagId));
    }
  } catch (error) {
    yield put(acceptAmountFlowFailure(error));
  }
}

export function* replyToMessageFlow({ payload }) {
  try {
    const result = yield call(replyToMessage, payload);
    yield put(replyToMessageFlowSuccess(result));
    if (result.status === 200 || result.status === 204) {
      yield put(getNegotiationListFlowRequest(payload.bagId));
    }
  } catch (error) {
    yield put(replyToMessageFlowFailure(error));
  }
}

export default [
  takeLatest(getNegotiationListFlowRequest, getNegotiationListFlow),
  takeLatest(submitNegotiationFlowRequest, submitNegotiationFlow),
  takeLatest(negotiationWithOhPhireFlowRequest, negotiationWithOhPhireFlow),
  takeLatest(acceptAmountFlowRequest, acceptAmountFlow),
  takeLatest(replyToMessageFlowRequest, replyToMessageFlow),
];
