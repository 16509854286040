import * as R from 'ramda';
import { createSelector } from 'reselect';
import { clothesStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  clothesStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  clothesStateSelector,
  result => R.path(['error', 'message'], result),
);


export const clothesListSelector = createSelector(
  clothesStateSelector,
  result => R.path(['data'], result),
);

export const getClothesIdByClothesNoSelector = createSelector(
  clothesStateSelector,
  result => R.path(['selectBagId'], result),
);

export const filterConditionSelector = createSelector(
  clothesStateSelector,
  result => R.path(['filterCondition'], result),
);
export const commentListSelector = createSelector(
  clothesStateSelector,
  result => R.path(['commentList'], result),

);

export const getReleaseDateSelector = createSelector(
  clothesStateSelector,
  result => R.path(['releaseDate'], result),
);
