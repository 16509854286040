import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import InfoModalComponent from '../../../../../components/InfoModalComponent';
import Default from '../../../../../components/RWDComponent/Default';
import Mobile from '../../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    padding: 30,
    width: 700,
    height: 550,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  mainMobileContainer: {
    padding: 30,
    width: '95vw',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  titleMainContainer: {
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  editTitle: {
    fontSize: 21,
    fontFamily: 'Microsoft JhengHei Regular',
    color: 'black',
    width: '60%',
    weight: 100,
  },
  editSubTitle: {
    paddingRight: 10,
    textAlign: 'right',
    marginLeft: 10,
    fontFamily: 'Microsoft JhengHei Regular',
    color: 'black',
    width: '20%',
    weight: 100,
  },
  editItemContainer: {
    height: 400,
    width: '100%',
    overflowY: 'scroll',
  },
  table: {
    marginTop: 20,
    width: '100%',
  },
  contentTable: {
    width: '100%',
  },
  tableScrollable: {
    width: '100%',
    height: 300,
    overflowY: 'scroll',
    borderBottom: '1px solid #D1D3D4',
  },
  tableHeader: {
    borderTop: '2px solid #939598',
    borderBottom: '1px solid #D1D3D4',
  },
  imgTitleHeader: {
    width: '30%',
    color: '#58595B',
  },
  bagNameHeader: {
    color: '#58595B',
    width: '55%',
  },
  selectHeader: {
    width: '15%',
    height: 30,
  },
  itemContainer: {
    width: '100%',
  },
  imgTitle: {
    width: '30%',
  },
  bagName: {
    color: '#58595B',
    width: '60%',
  },
  select: {
    width: '10%',
  },
  selectionContainer: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  memberContainer: {
    marginTop: 30,
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  memberName: {
    marginLeft: 15,
    fontSize: 18,
    fontFamily: 'Microsoft JhengHei Regular',
  },
  checkbox: {
    width: 20,
    height: 20,
  },
  bagContainer: {
    border: '1px solid #ECECEC',
    position: 'relative',
    margin: 10,
    width: 100,
    height: 100,
  },
  function: {
    width: 'fit-content',
    height: 50,
    marginTop: 30,
    fontSize: 20,
    padding: '10px 50px',
    border: '1px solid #AE4200',
    backgroundColor: 'white',
    color: '#939598',
    fontFamily: 'Microsoft JhengHei Regular',
    outline: 'none',
    cursor: 'pointer',
  },
  optionValue: {
    width: '20%',
    height: 30,
    backgroundColor: 'transparent',
    border: '1px solid #E6E7E8',
    color: 'black',
    outline: 'none',
    borderRadius: 0,
    padding: 10,
    WebkitAppearance: 'none',
    cursor: 'pointer',
  },
};

const ReduceDepositSelectBagComponent = ({
  myBagList,
  selectBagList,
  onModalClose,
  isVisible,
  onSelectBag,
}) => {
  const [currentSelectBagIdList, setCurrentSelectBagIdList] = useState([]);
  const [bagList, setBagList] = useState([]);
  const bagIdList = bagList.map(bag => bag.id);
  useEffect(() => {
    setBagList(selectBagList);
    setCurrentSelectBagIdList(selectBagList.map(bag => bag.id));
  }, [selectBagList]);

  const content = (
    <React.Fragment>
      <div style={styles.titleMainContainer}>
        <div style={styles.titleContainer}>
          <div style={styles.editTitle}>編輯免押金帳號包款選項</div>
          <div style={styles.editSubTitle}>
            sort By
          </div>
          <select style={styles.optionValue} />
        </div>
      </div>
      <table style={styles.table}>
        <tbody>
          <tr style={styles.tableHeader}>
            <th style={styles.imgTitleHeader}>商品圖片</th>
            <th style={styles.bagNameHeader}>商品名稱</th>
            <th style={styles.selectHeader}>選取</th>
          </tr>
        </tbody>
      </table>
      <div style={styles.tableScrollable}>
        <table style={styles.contentTable}>
          <tbody>
            {myBagList.map(bag => (
              <tr key={bag.id}>
                <td style={styles.imgTitle}>
                  <img alt="" src={R.pathOr('', ['frontPic', 'uri'], bag)} style={styles.bagContainer} />
                </td>
                <td style={styles.bagName}>{R.pathOr('', ['name'], bag)}</td>
                <td style={styles.select}>
                  <input
                    style={styles.checkbox}
                    type="checkbox"
                    value={bag.id}
                    key={bag.id}
                    defaultChecked={R.includes(bag.id, bagIdList)}
                    onChange={(event) => {
                      if (event.target.checked && !R.includes(Number(event.target.value), currentSelectBagIdList)) {
                        setCurrentSelectBagIdList(R.concat(currentSelectBagIdList, [Number(event.target.value)]));
                      } else {
                        setCurrentSelectBagIdList(R.filter(item => item !== Number(event.target.value), currentSelectBagIdList));
                      }
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        type="button"
        style={styles.function}
        onClick={() => {
          setCurrentSelectBagIdList([]);
          setBagList([]);
          onSelectBag(currentSelectBagIdList);
        }}
      >
        儲存
      </button>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainContainer}>
              {content}
            </div>
      )}
          containerStyle={{ paddingTop: 40 }}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainMobileContainer}>
              {content}
            </div>
      )}
          containerStyle={{ paddingTop: 40 }}
        />
      </Mobile>
    </React.Fragment>

  );
};

ReduceDepositSelectBagComponent.propTypes = {
  myBagList: PropTypes.array,
  selectBagList: PropTypes.array,
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onSelectBag: PropTypes.func.isRequired,
};

ReduceDepositSelectBagComponent.defaultProps = {
  myBagList: [],
  selectBagList: [],
  isVisible: false,
};

export default ReduceDepositSelectBagComponent;
