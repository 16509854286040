import React from 'react';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';
import { orderStatus } from '../../../constants/OrderStatus';
import Default from '../../../components/RWDComponent/Default';
import Mobile from '../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    width: 400,
    height: 100,
    padding: 10,
    position: 'relative',
  },
  mainMobileContainer: {
    width: '100%',
    height: '240px',
    padding: 10,
    position: 'relative',
  },
  cancelOrderContainer: {
    position: 'absolute',
    width: 'fit-content',
    height: 20,
    top: 66,
    left: 100,
  },
  cancelOrderMobileContainer: {
    position: 'absolute',
    width: 'fit-content',
    height: 20,
    top: 37,
    right: 10,
    display: 'table',
  },
  icon: {
    fontSize: 10,
    marginTop: 6,
    color: '#927764',
    margin: '6px 1px',
  },
  orderCancel: {
    fontSize: 5,
    marginTop: 2,
    color: '#927764',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
  },
  infoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'fit-content',
    height: 100,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  infoMobileContainer: {
    width: '100%',
    height: 100,
    padding: 10,
    display: '-webkit-flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: '1 0 auto',
  },
  withdrawContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 400,
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  withdrawMobileContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 240,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  disableCover: {
    position: 'absolute',
    top: 0,
    left: 150,
    width: 300,
    height: 100,
    zIndex: 1000,
    backgroundColor: 'rgba(255,255,255,0.7)',
  },
  stepBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 0px',
    height: 80,
    width: 20,
    backgroundColor: '#927764',
    marginRight: 3,
    borderRadius: 10,
  },
  stepBarUpMobileContainer: {
    display: '-webkit-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 2px',
    height: '20px',
    width: '85%',
    backgroundColor: '#927764',
    borderRadius: 10,
    flex: '1 0 auto',
    position: 'absolute',
    top: 55,
  },
  stepBarDownMobileContainer: {
    display: '-webkit-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 2px',
    height: '20px',
    width: '85%',
    backgroundColor: '#927764',
    borderRadius: 10,
    flex: '1 0 auto',
    position: 'absolute',
    bottom: 40,
  },
  stepDivider: {
    margin: '10px 3px 0px 3px',
    height: 1,
    width: 18,
    backgroundColor: '#927764',
  },
  enableStep: {
    width: 16,
    height: 16,
    borderRadius: 50,
    backgroundColor: 'white',
  },
  disableStep: {
    width: 16,
    height: 16,
    borderRadius: 50,
    backgroundColor: '#C0AEA3',
  },
  buttonEnable: {
    border: 'none',
    color: 'black',
    outline: 'none',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    cursor: 'pointer',
    height: '20px',
    display: 'table',
    width: 'fit-content',
    WebkitAppearance: 'default-button',
    paddingRight: '15px',
  },
  buttonDisable: {
    border: 'none',
    color: '#aaa',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'not-allowed',
    height: '20px',
    width: 'fit-content',
    display: 'table',
    WebkitAppearance: 'default-button',
    paddingRight: '15px',
  },
  sendButtonEnable: {
    border: 'none',
    color: 'black',
    outline: 'none',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    cursor: 'pointer',
    height: '20px',
    display: 'table',
    width: 'fit-content',
    WebkitAppearance: 'default-button',
    paddingRight: '15px',
    paddingTop: '2px',
  },
  sendButtonDisable: {
    border: 'none',
    color: '#aaa',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'not-allowed',
    height: '20px',
    width: 'fit-content',
    display: 'table',
    WebkitAppearance: 'default-button',
    paddingRight: '15px',
    paddingTop: '2px',
  },
  stepTextContainer: {
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  stepTextMobileContainer: {
    height: 80,
    width: '100%',
    display: '-webkit-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '10px',
    marginTop: '5px',
    flex: '1 0 auto',
  },
  sendMobileContainer: {
    height: 10,
    width: '100%',
    display: '-webkit-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 0 auto',
    marginBottom: '70px',
    marginTop: '3px',
  },
  withdraw: {
    fontSize: 10,
    textAlign: 'center',
    width: 100,
    padding: '5px 15px',
    color: ' white',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: '#927764',
  },
  mobileLine: {
    border: '1px grey solid',
    backgroundColor: 'grey',
    height: '40px',
    width: '0px',
    position: 'absolute',
    top: '78px',
    display: 'table',
  },
  mobileDownLine: {
    border: '1px grey solid',
    backgroundColor: 'grey',
    height: '40px',
    width: '0px',
    position: 'absolute',
    bottom: '64px',
    display: 'table',
  },
};

const LenderOrderStatusPanel = ({
  order,
  onConfirmOrder,
  onCancelOrder,
  onProductSend,
  onProductReturnConfirm,
  onProductIssueReport,
}) => {
  const status = R.path(['orderStatus', 'name'], order);
  const rentStartDate = R.path(['rentStartDate'], order);
  const rentEndDate = R.path(['rentEndDate'], order);
  const isOhPhireManage = R.path(['bag', 'isOhPhireManage'], order);
  const enableConfirmingOrder = status === orderStatus.confirming;
  const enableConfirmedOrder = status === orderStatus.confirmed
    || (status === orderStatus.confirming && status !== orderStatus.canceled)
    || status === orderStatus.lenderSent;
  const enableConfirmedOrderButton = status === orderStatus.confirming;
  const enableProductInRentPeriod = moment(new Date()).isAfter(rentStartDate)
    && moment(new Date()).isBefore(rentEndDate) && (status === orderStatus.lenderSent);
  const enableLenderConfirm = enableProductInRentPeriod || status === '租借方歸還'
    || status === orderStatus.lenderReceive || status === '租借方歸還';
  const enableLenderConfirmButton = (status === '租借方歸還'
    || (moment(new Date()).isAfter(rentStartDate) && moment(new Date()).isBefore(rentEndDate)))
   && status !== orderStatus.lenderReceive;
  const enableLenderConfirmStep = moment(new Date()).isAfter(rentEndDate)
    || status === orderStatus.lenderReceive
    || status === '租借方歸還';
  const enableIssueReportStep = status === orderStatus.issueReport;
  const enableIssueReport = enableProductInRentPeriod || enableIssueReportStep;
  const enableIssueReportButton = moment(new Date()).isAfter(rentStartDate)
    && moment(new Date()).isBefore(rentEndDate) && status !== orderStatus.issueReport;
  const enableCancelOrder = status === orderStatus.confirming || status === orderStatus.canceled;
  const enableCancelOrderButton = status === orderStatus.confirming;
  const enableCancelOrderHint = status === orderStatus.canceled;
  const enableLenderSentButton = status === orderStatus.confirmed;

  const getLenderSentStyle = () => {
    if (status === orderStatus.confirmed || status === '租借方歸還' || status === orderStatus.lenderSent) {
      return ({ ...styles.buttonEnable, marginTop: 28 });
    }
    return ({ ...styles.buttonDisable, marginTop: 28 });
  };

  const getLenderSentMobileStyle = () => {
    if (status === orderStatus.confirmed || status === '租借方歸還' || status === orderStatus.lenderSent) {
      return ({ ...styles.sendButtonEnable });
    }
    return ({ ...styles.sendButtonDisable });
  };

  return (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          {enableCancelOrderHint && (
          <div style={styles.cancelOrderContainer}>
            <Icon type="check-circle" theme="filled" style={styles.icon} />
            <button type="button" style={styles.orderCancel}>訂單已取消</button>
          </div>
          )}
          <div style={styles.infoContainer}>
            <div style={styles.stepBarContainer}>
              <div style={status === orderStatus.confirming ? styles.enableStep : styles.disableStep} />
              <div style={(status !== orderStatus.hadPaidDeposit
            && status !== orderStatus.create
            && status !== orderStatus.confirming
            && status !== orderStatus.canceled)
                ? styles.enableStep : styles.disableStep}
              />
              <div style={status === orderStatus.canceled ? styles.enableStep : styles.disableStep} />
            </div>
            <div style={styles.stepTextContainer}>
              <button
                type="button"
                disabled={!enableConfirmingOrder}
                style={enableConfirmingOrder ? styles.buttonEnable : styles.buttonDisable}
              >
                未審核訂單
              </button>
              <button
                type="button"
                disabled={!enableConfirmedOrderButton}
                style={enableConfirmedOrder ? styles.buttonEnable : styles.buttonDisable}
                onClick={() => onConfirmOrder()}
              >
                接受訂單
              </button>
              <button
                type="button"
                disabled={!enableCancelOrderButton}
                style={enableCancelOrder ? styles.buttonEnable : styles.buttonDisable}
                onClick={() => onCancelOrder()}
              >
                取消訂單
              </button>
            </div>
            <div style={{ ...styles.stepDivider, ...{ width: 35, marginLeft: -10, marginTop: 40 } }} />
            <button
              type="button"
              disabled={!enableLenderSentButton}
              style={getLenderSentStyle()}
              onClick={() => onProductSend(!isOhPhireManage)}
            >
              我要寄貨
            </button>
            <div style={{ ...styles.stepDivider, ...{ width: 35, marginTop: 40, marginRight: 5 } }} />
            <div style={styles.stepBarContainer}>
              <div style={enableProductInRentPeriod ? styles.enableStep : styles.disableStep} />
              <div style={enableLenderConfirmStep ? styles.enableStep : styles.disableStep} />
              <div style={enableIssueReportStep ? styles.enableStep : styles.disableStep} />
            </div>
            <div style={styles.stepTextContainer}>
              <button
                type="button"
                style={enableProductInRentPeriod ? styles.buttonEnable : styles.buttonDisable}
              >
                商品租借中
              </button>
              <button
                type="button"
                disabled={!enableLenderConfirmButton}
                style={enableLenderConfirm ? styles.buttonEnable : styles.buttonDisable}
                onClick={() => onProductReturnConfirm()}
              >
                確認歸還
              </button>
              <button
                type="button"
                disabled={!enableIssueReportButton}
                style={enableIssueReport ? styles.buttonEnable : styles.buttonDisable}
                onClick={() => onProductIssueReport()}
              >
                問題回報
              </button>
            </div>
          </div>
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          {enableCancelOrderHint && (
          <div style={styles.cancelOrderMobileContainer}>
            <Icon type="check-circle" theme="filled" style={styles.icon} />
            <button type="button" style={styles.orderCancel}>訂單已取消</button>
          </div>
          )}
          <div style={styles.infoMobileContainer}>
            <div style={styles.stepTextMobileContainer}>
              <button
                type="button"
                disabled={!enableConfirmingOrder}
                style={enableConfirmingOrder ? styles.buttonEnable : styles.buttonDisable}
              >
                未審核訂單
              </button>
              <button
                type="button"
                disabled={!enableConfirmedOrderButton}
                style={enableConfirmedOrder ? styles.buttonEnable : styles.buttonDisable}
                onClick={() => onConfirmOrder()}
              >
                接受訂單
              </button>
              <button
                type="button"
                disabled={!enableCancelOrderButton}
                style={enableCancelOrder ? styles.buttonEnable : styles.buttonDisable}
                onClick={() => onCancelOrder()}
              >
                取消訂單
              </button>
            </div>
            <div style={styles.stepBarUpMobileContainer}>
              <div style={status === orderStatus.confirming ? styles.enableStep : styles.disableStep} />
              <div style={(status !== orderStatus.hadPaidDeposit
              && status !== orderStatus.create
              && status !== orderStatus.confirming
              && status !== orderStatus.canceled)
                ? styles.enableStep : styles.disableStep}
              />
              <div style={status === orderStatus.canceled ? styles.enableStep : styles.disableStep} />
            </div>

            <div style={styles.mobileLine} />
            <div style={styles.sendMobileContainer}>
              <button
                type="button"
                disabled={!enableLenderSentButton}
                style={getLenderSentMobileStyle()}
                onClick={() => onProductSend(!isOhPhireManage)}
              >
                我要寄貨
              </button>
            </div>
            <div style={styles.mobileDownLine} />
            <div style={styles.stepBarDownMobileContainer}>
              <div style={enableProductInRentPeriod ? styles.enableStep : styles.disableStep} />
              <div style={enableLenderConfirmStep ? styles.enableStep : styles.disableStep} />
              <div style={enableIssueReportStep ? styles.enableStep : styles.disableStep} />
            </div>
            <div style={styles.stepTextMobileContainer}>
              <button
                type="button"
                style={enableProductInRentPeriod ? styles.buttonEnable : styles.buttonDisable}
              >
                商品租借中
              </button>
              <button
                type="button"
                disabled={!enableLenderConfirmButton}
                style={enableLenderConfirm ? styles.buttonEnable : styles.buttonDisable}
                onClick={() => onProductReturnConfirm()}
              >
                確認歸還
              </button>
              <button
                type="button"
                disabled={!enableIssueReportButton}
                style={enableIssueReport ? styles.buttonEnable : styles.buttonDisable}
                onClick={() => onProductIssueReport()}
              >
                問題回報
              </button>
            </div>
          </div>
        </div>
      </Mobile>
    </React.Fragment>
  );
};

LenderOrderStatusPanel.defaultProps = {
  order: {},
};

LenderOrderStatusPanel.propTypes = {
  order: PropTypes.object,
  onConfirmOrder: PropTypes.func.isRequired,
  onProductSend: PropTypes.func.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
  onProductIssueReport: PropTypes.func.isRequired,
  onProductReturnConfirm: PropTypes.func.isRequired,
};
export default LenderOrderStatusPanel;
