import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Link } from '@reach/router';
import {
  Icon, Popconfirm,
} from 'antd';
import amountPattern from '../../../utils/AmountPattern';
import BagStatus from '../../../constants/BagStatus';
import { rentAmountCalculate } from '../../../utils/rentAmountCalculate';
import bagOrClothesPath from '../../../utils/bagOrClothesPath';

const styles = {
  mainContainer: {
    marginBottom: 10,
    width: '100%',
    paddingBottom: 10,
    borderBottom: '1px solid #D1D3D4',
  },
  width5: {
    width: '5%',
  },
  width10: {
    width: '10%',
  },
  width17: {
    width: '17%',
  },
  width20: {
    width: '20%',
  },
  detailContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  img: {
    width: '100%',
    objectFit: 'contain',
    height: 160,
    backgroundColor: 'white',
  },
  functionButton: {
    cursor: 'pointer',
    outline: 'none',
    border: '1px solid #AE4200',
    color: 'black',
    maxWidth: 80,
    minWidth: 58,
    width: '60%',
    height: 27,
    fontWeight: 200,
  },
  tableTr: {
    borderTop: '1px solid #BABABA',
    borderBottom: '1px solid #BABABA',
  },
  icon: {
    marginLeft: '5px',
  },
};


const CartTableComponent = ({
  cart,
  onDeleteCart,
}) => {
  const commodityType = R.pathOr(1, ['bag', 'commodityTypeId'], cart);
  const washAmount = R.pathOr(0, ['bag', 'washAmount'], cart);

  const renderTypeString = (type) => {
    if (type === 'rent') {
      return '租借';
    } if (type === 'buy') {
      return '購買';
    }
    return '租借-購買';
  };

  const renderCreateOrderButton = (bagNo, bagStatus) => {
    if (bagStatus.id === BagStatus.bagStatusId.inStock) {
      return (
        <Link
          key="transaction"
          to={`${bagOrClothesPath(commodityType)}/${bagNo}`}
        >
          <button type="button" style={styles.functionButton}>下訂單</button>
        </Link>
      );
    }
    return (<span>{bagStatus.name}</span>);
  };

  return (
    <tr style={styles.tableTr} key={R.pathOr('0', ['id'], cart)}>
      <td style={styles.width20}>
        <Link to={`${bagOrClothesPath(commodityType)}/${R.pathOr('', ['bag', 'bagNo'], cart)}`}>
          <img style={styles.img} src={R.pathOr('', ['bag', 'frontPic', 'uri'], cart)} alt="" />
        </Link>
      </td>
      <td style={styles.width10}>
        <Link to={`${bagOrClothesPath(commodityType)}/${R.pathOr('', ['bag', 'bagNo'], cart)}`}>
          {R.pathOr('0', ['bag', 'bagNo'], cart)}
        </Link>
      </td>
      <td style={styles.width17}>
        <Link to={`${bagOrClothesPath(commodityType)}/${R.pathOr('', ['bag', 'bagNo'], cart)}`}>
          {R.pathOr('', ['bag', 'name'], cart)}
        </Link>
      </td>
      <td style={styles.width10}>
        {renderTypeString(R.pathOr('', ['bag', 'type', 'name'], cart))}
      </td>
      <td style={styles.width20}>
        {`NT$ ${Math.ceil(rentAmountCalculate(R.pathOr(0, ['bag', 'rentAmountPerDay'], cart), 4, false, washAmount) / 4)}
        / NT$ ${R.pathOr('', ['bag', 'deposit'], cart)} `}
      </td>
      <td style={styles.width10}>
        {`NT$ ${amountPattern(R.pathOr('0', ['bag', 'buyoutAmount'], cart))}`}
      </td>
      <td style={styles.width10}>
        {renderCreateOrderButton(R.pathOr('0', ['bag', 'bagNo'], cart), R.pathOr('', ['bag', 'bagStatus'], cart))}
      </td>
      <td style={{ ...styles.width5, ...styles.detailContainer }}>
        <Popconfirm
          title="是否從願望清單移除?"
          onConfirm={() => onDeleteCart(cart.id)}
          okText="Yes"
          cancelText="No"
        >
          <Icon
            style={styles.icon}
            type="close-circle"
          />
        </Popconfirm>
      </td>
    </tr>
  );
};


CartTableComponent.propTypes = {
  cart: PropTypes.object,
  onDeleteCart: PropTypes.func.isRequired,
};

CartTableComponent.defaultProps = {
  cart: null,
};

export default CartTableComponent;
