import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import * as R from 'ramda';
import { Avatar } from 'antd/lib/index';
import Modal from '../../../../components/CustomModal';
import Paths from '../../../../routes/Paths';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import amountPattern from '../../../../utils/AmountPattern';
import Assets from '../../../../constants/Assets';
import { passbaseStatusId } from '../../../../constants/PassbaseStatus';
import Identification from '../../../Identification';
import memberHeaderStyle from './MemberHeader.module.scss';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  upperContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  upperMobileContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  reduxStyle: {
    width: '100%',
  },
  memberNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  memberNameMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '0px',
  },
  hint: {
    color: 'black',
    fontSize: 16,
    fontWeight: 100,
  },
  name: {
    fontSize: 30,
    color: 'black',
    fontFamily: 'Baskerville-Old-Facef',
  },
  nameRightSideContainer: {
    marginLeft: 10,
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  nameContainer: {
    display: 'block',
    border: '2px red solid',
  },
  nameRightBottomSideContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subInfoUpperContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subInfoTitle: {
    marginRight: 5,
    color: '#AE4200',
    fontSize: 20,
  },
  subInfo: {
    fontSize: 16,
    fontWeight: 200,
  },
  avatar: {
    fontSize: 50,
  },
  verticalDivider: {
    height: 17,
    width: 1.5,
    backgroundColor: '#CDCDCD',
  },
  verticalDividerMobile: {
    height: 40,
  },
  divider: {
    width: '80%',
    height: 2,
    backgroundColor: '#eeeeee',
    marginTop: 50,
  },
  tabContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  selectTabColor: {
    color: '#AE4200',
  },
  selectedTab: {
    marginTop: -2,
    width: 300,
    padding: '20px 0px',
    height: 60,
    fontSize: 16,
    textAlign: 'center',
    cursor: 'pointer',
  },
  borderTop: {
    borderTop: '2px solid #BCBEC0',
  },
  unSelectedTab: {
    marginTop: -2,
    padding: '20px 0px',
    height: 60,
    width: 300,
    color: '#939598',
    fontSize: 16,
    textAlign: 'center',
    cursor: 'pointer',
  },
  subTab: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderBottom: '2px solid #BCBEC0',
  },
  headMobileRightContainer: {
    width: '65%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  headerInfoMobileContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  passbaseIcon: {
    width: '160px',
    height: 'auto',
    position: 'absolute',
    left: '-10px',
    top: '-10px',
  },
  passbaseIconMobile: {
    width: '100px',
    height: 'auto',
    position: 'absolute',
    left: '0px',
  },
  passbaseIconWithOutButtonMobile: {
    width: '100px',
    height: 'auto',
    position: 'relative',
    left: '0px',
  },
  passbaseButtonContainer: {
    width: '140px',
    height: '50px',
    opacity: 0,
  },
  passbaseButtonMobileContainer: {
    width: '100px',
    height: 'auto',
    opacity: 0,
  },
  passBaseMemberContainer: {
    position: 'relative',
  },
  passbaseNoteText: {
    position: 'absolute',
    top: 30,
    fontSize: 6,
    width: '160px',
  },
};

const MemberHeader = ({
  reduxStatus,
  reduxError,
  communityName,
  memberAvatarUri,
  path,
  category,
  bagNumber,
  dealtBags,
  earnAmount,
  memberProfile,
  orderNumbers,
  memberId,
}) => {
  const isVerified = R.pathOr(null, ['isVerified'], memberProfile);
  const processStatus = R.pathOr(null, ['passbaseStatus'], memberProfile);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowCancelPage, setIsShowCancelPage] = useState(false);

  const categoryArray = {
    transactionRecord: [
      { title: '出租/出售紀錄', path: Paths.MY_RENTED_SOLD_HISTORY },
      { title: '租借/購買紀錄', path: Paths.ORDER_LIST },
    ],
    onlineBag: [
      { title: '賣包', path: Paths.SELL },
      { title: '借包', path: Paths.LEND },
      { title: '借賣並行', path: Paths.LEND_SELL },
    ],
  };

  const renderTabItem = (tabName, isSelected, directPath) => (
    <div
      style={isSelected
        ? { ...styles.selectedTab, ...styles.borderTop }
        : styles.unSelectedTab}
    >
      {
        directPath ? (
          <Link
            to={directPath}
            style={isSelected ? styles.selectTabColor : null}
          >
            {tabName}
          </Link>
        ) : tabName
      }
    </div>
  );

  const renderSubTab = () => (
    <div style={styles.subTab}>
      {categoryArray[category].map(item => (
        <div key={item.title} style={item.path === path ? styles.selectedTab : styles.unSelectedTab}>
          <Link
            to={item.path}
            style={item.path === path ? styles.selectTabColor : null}
          >
            {item.title}
          </Link>
        </div>
      ))}
    </div>
  );

  const renderMemberAvatar = (
    <Link to={`${Paths.MEMBER}/${communityName}`}>
      <Avatar
        icon="user"
        size={98}
        style={styles.avatar}
        src={memberAvatarUri || Assets.AvatarIcon}
      />
    </Link>
  );

  const infoData = [
    {
      title: bagNumber,
      info: '件',
      hint: '商品',
    },
    {
      title: dealtBags,
      info: '筆',
      hint: '出售成交',
    },
    {
      title: orderNumbers,
      info: '筆',
      hint: '出租成交',
    },
    {
      title: amountPattern(earnAmount),
      info: '元',
      hint: '賺取金額',
    },
  ];

  const renderMemberHederInfo = (title, info, hint, dividerStyle) => (
    <React.Fragment key={hint}>
      <div style={styles.subInfoContainer}>
        <div style={styles.subInfoUpperContainer}>
          <div style={styles.subInfoTitle}>{title}</div>
          <div style={styles.subInfo}>{info}</div>
        </div>
        <div style={styles.hint}>{hint}</div>
      </div>
      <div style={{ ...styles.verticalDivider, ...dividerStyle }} />
    </React.Fragment>
  );

  const renderPassbaseButton = (isMobile) => {
    const renderIconImag = (imgPath, style) => (
      <img
        src={imgPath}
        style={isMobile ? { ...styles.passbaseIconMobile, ...style } : styles.passbaseIcon}
        alt=""
      />
    );

    const renderPassbaseIcon = () => {
      if (isVerified && processStatus === passbaseStatusId.accept) {
        return renderIconImag(
          isMobile ? Assets.passbaseVerifySuccessMobile : Assets.passbaseVerifySuccess,
          styles.passbaseIconWithOutButtonMobile,
        );
      }

      if (processStatus === passbaseStatusId.processing) {
        return renderIconImag(isMobile ? Assets.passbaseVerifyingMobile : Assets.passbaseVerifying,
          styles.passbaseIconWithOutButtonMobile);
      }

      if (processStatus === passbaseStatusId.reject) {
        return (
          <React.Fragment>
            <button type="button" onClick={() => { setIsShowModal(true); }}>
              再次驗證
            </button>
            <div style={styles.passbaseNoteText}>
              透過實名認證即可開始租借
            </div>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          <button type="button" onClick={() => { setIsShowModal(true); }}>
            立即驗證
          </button>
          <div style={styles.passbaseNoteText}>
            透過實名認證即可開始租借
          </div>
        </React.Fragment>
      );
    };


    return (
      <React.Fragment>
        <div style={styles.passBaseMemberContainer}>
          {renderPassbaseIcon()}
        </div>
      </React.Fragment>
    );
  };

  const renderMemberHeder = (
    <React.Fragment>
      <Default>
        <div style={styles.memberNameContainer}>
          {renderMemberAvatar}
          <div style={styles.nameRightSideContainer}>
            <div style={styles.name}>{communityName}</div>
            {renderPassbaseButton()}
          </div>
        </div>

        {infoData.map(item => (
          renderMemberHederInfo(item.title, item.info, item.hint)
        ))}
      </Default>
      <Mobile>
        <div style={styles.memberNameMobileContainer}>
          {renderMemberAvatar}
          {renderPassbaseButton(true)}
        </div>
        <div style={styles.headMobileRightContainer}>
          <div style={styles.nameRightSideContainer}>
            <div style={styles.name}>{communityName}</div>
          </div>

          <div style={styles.headerInfoMobileContainer}>
            {infoData.map(item => (
              renderMemberHederInfo(item.title, item.info, item.hint, styles.verticalDividerMobile)
            ))}
          </div>
        </div>
      </Mobile>
    </React.Fragment>
  );

  return (

    <ReduxStatusVisualizedComponent
      containerStyle={styles.reduxStyle}
      showModalOnError
      reduxStatus={reduxStatus}
      modalFailureContent={reduxError}
    >
      <Modal
        isOpen={isShowModal}
        onClose={() => { setIsShowCancelPage(true); }}
        contentClassName={memberHeaderStyle['custom-modal-content']}
      >
        <Identification
          memberId={memberId}
          onClose={() => {
            setIsShowModal(false);
            setIsShowCancelPage(false);
          }
          }
          isShowCancelPage={isShowCancelPage}
          onGiveUpCancel={() => {
            setIsShowCancelPage(false);
          }}
        />
      </Modal>
      <div style={styles.mainContainer} key="baseContainer">
        <Default>
          <div style={styles.upperContainer}>
            {renderMemberHeder}
          </div>
        </Default>
        <Mobile>
          <div style={styles.upperMobileContainer}>
            {renderMemberHeder}
          </div>
        </Mobile>


        <div style={styles.divider} />
        <div style={styles.tabContainer}>
          {renderTabItem('個人資料', category === 'personalInfo', Paths.MEMBER)}
          {renderTabItem('交易紀錄', category === 'transactionRecord', Paths.ORDER_LIST)}
          {renderTabItem('我的商品', category === 'onlineBag', Paths.ONLINE_PRODUCTS)}
          {renderTabItem('出貨資訊', category === 'shippingInfo', Paths.SHIPPING_INFO)}
        </div>
        <div style={styles.tabContainer}>
          {category === 'transactionRecord' ? renderSubTab() : null}
        </div>
      </div>
    </ReduxStatusVisualizedComponent>
  );
};

MemberHeader.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  memberAvatarUri: PropTypes.string,
  communityName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  path: PropTypes.string,
  category: PropTypes.string,
  bagNumber: PropTypes.number,
  dealtBags: PropTypes.number,
  earnAmount: PropTypes.number,
  memberProfile: PropTypes.object,
  orderNumbers: PropTypes.number,
  memberId: PropTypes.string.isRequired,
};

MemberHeader.defaultProps = {
  reduxStatus: '',
  reduxError: '',
  category: 'personalInfo',
  memberAvatarUri: null,
  path: null,
  communityName: null,
  bagNumber: 0,
  dealtBags: 0,
  earnAmount: 0,
  memberProfile: {},
  orderNumbers: 0,
};

export default MemberHeader;
