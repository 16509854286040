import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import * as R from 'ramda';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  getBagListRequest,
  getBagListSuccess,
  getBagListFailure,
  getBagReleaseDateRequest,
  getBagReleaseDateSuccess,
  getBagReleaseDateFailure,
  getMyBagListRequest,
  getMyBagListSuccess,
  getMyBagListFailure,
  notifyWhenArrivedRequest,
  notifyWhenArrivedSuccess,
  notifyWhenArrivedFailure,
  submitBagFlowRequest,
  submitBagFlowSuccess,
  submitBagFlowFailure,
  resetStatus,

  getCommentListFlowRequest,
  getCommentListSuccess,
  getCommentListFailure,

  searchBagListByKeywordFlowRequest,
  searchBagListByKeywordSuccess,
  searchBagListByKeywordFailure,

  getBagRentDateListFlowRequest,
  getBagRentDateListSuccess,
  getBagRentDateListFailure,

  getBagIdByBagNoFlowRequest,
  getBagIdByBagNoSuccess,
  getBagIdByBagNoFailure,

  getBagNumbersFlowRequest,
  getBagNumbersFlowSuccess,
  getBagNumbersFlowFailure,

  getOnlineWardrobeRequest,
  getOnlineWardrobeSuccess,
  getOnlineWardrobeFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  selectBagId: null,
  data: [],
  commentList: [],
  rentDateList: [],
  releaseDate: '',
  filterCondition: null,
  searchByKeyword: null,
  onlineWardrobe: [],
};

export default handleActions({
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: initialState.reduxStatus },
  }),

  [getCommentListFlowRequest]: state => update(state, {
    commentList: { $set: initialState.commentList },
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
  }),

  [combineActions(
    getBagListRequest,
    notifyWhenArrivedRequest,
    getMyBagListRequest,
    getOnlineWardrobeRequest,
    submitBagFlowRequest,
    searchBagListByKeywordFlowRequest,
    getBagRentDateListFlowRequest,
    getBagNumbersFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: null },
  }),

  [getBagIdByBagNoFlowRequest]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    selectBagId: { $set: null },
  }),

  [getBagReleaseDateRequest]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    releaseDate: { $set: '' },
  }),

  [combineActions(
    getBagListFailure,
    notifyWhenArrivedFailure,
    getMyBagListFailure,
    getOnlineWardrobeFailure,
    submitBagFlowFailure,
    getBagNumbersFlowFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),

  [getOnlineWardrobeSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    onlineWardrobe:
      R.pathOr(0, ['filterCondition', 'page'], payload) === 0
        ? { $set: payload.data }
        : { $push: payload.data },
    filterCondition: { $set: payload.filterCondition },
    error: { $set: null },
  }),

  [combineActions(
    getBagListSuccess,
    getMyBagListSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data:
      R.pathOr(0, ['filterCondition', 'page'], payload) === 0
        ? { $set: payload.data }
        : { $push: payload.data },
    filterCondition: { $set: payload.filterCondition },
    error: { $set: null },
  }),
  [getBagReleaseDateSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    releaseDate: { $set: payload },
  }),
  [getCommentListSuccess]: (state, { payload }) => update(state, {
    commentList: { $set: payload },
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
  }),

  [searchBagListByKeywordSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    searchByKeyword: { $set: payload },
    error: { $set: null },
  }),

  [getBagRentDateListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    rentDateList: { $set: payload },
    error: { $set: null },
  }),

  [getBagIdByBagNoSuccess]: (state, { payload }) => update(state, {
    selectBagId: { $set: payload },
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
  }),

  [getBagRentDateListFailure]: (state, { payload }) => update(state, {
    rentDateList: { $set: [] },
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),

  [getCommentListFailure]: state => update(state, {
    commentList: { $set: initialState.commentList },
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
  }),

  [getBagIdByBagNoFailure]: state => update(state, {
    selectBagId: { $set: null },
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
  }),

  [searchBagListByKeywordFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    searchByKeyword: { $set: null },
    error: { $set: null },
  }),

  [getBagReleaseDateFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    releaseDate: { $set: '' },
  }),

  [getBagNumbersFlowSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    bagNumbers: { $set: payload },
  }),

  [combineActions(
    notifyWhenArrivedSuccess,
    submitBagFlowSuccess,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
  }),

}, initialState);
