import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('FB_LOG_IN'),
  ...createAsyncActionTypesArray('LOG_IN'),
  ...createAsyncActionTypesArray('LOG_OUT'),
  ...createAsyncActionTypesArray('REGISTER'),
  ...createAsyncActionTypesArray('CHANGE_PASSWORD'),
  ...createAsyncActionTypesArray('FORGOT_PASSWORD'),
  ...createAsyncActionTypesArray('UPDATE_MEMBER_INFO'),
  ...createAsyncActionTypesArray('GET_MEMBER_PROFILE'),
  ...createAsyncActionTypesArray('SEND_SMS'),
  ...createAsyncActionTypesArray('SMS_LOGIN'),
  ...createAsyncActionTypesArray('TOGGLE_FOLLOW'),
  ...createAsyncActionTypesArray('GET_FOLLOWING_LIST'),
  ...createAsyncActionTypesArray('UPLOAD_OUTFIT'),
  ...createAsyncActionTypesArray('GET_MEMBER_OUTFIT_LIST'),
  ...createAsyncActionTypesArray('GET_OUTFIT_DETAIL'),
  ...createAsyncActionTypesArray('SEND_OUTFIT_COMMENT'),
  ...createAsyncActionTypesArray('GET_PROTECT_TREE_END_TIME'),
  ...createAsyncActionTypesArray('GET_REDUCE_DEPOSIT_LIST'),
  ...createAsyncActionTypesArray('DELETE_REDUCE_DEPOSIT_BAG'),
  ...createAsyncActionTypesArray('GET_REDUCE_DEPOSIT_USER_LIST'),
  ...createAsyncActionTypesArray('CREATE_REDUCE_DEPOSIT_USER'),
  ...createAsyncActionTypesArray('DELETE_REDUCE_DEPOSIT_USER'),
  ...createAsyncActionTypesArray('UPDATE_REDUCE_DEPOSIT_USER'),
  ...createAsyncActionTypesArray('GET_USER_ID_BY_COMMUNITY_NAME'),
  ...createAsyncActionTypesArray('HAD_READ_NOTIFICATION'),
  ...createAsyncActionTypesArray('All_READ_NOTIFICATION'),
  ...createAsyncActionTypesArray('GET_NOTIFICATION_LIST'),
  ...createAsyncActionTypesArray('GET_MEMBER_COUPON_LIST'),
  ...createAsyncActionTypesArray('DELETE_OUTFITS'),
  ...createAsyncActionTypesArray('GET_WITHDRAW_ORDER_NUMBERS'),
  ...createAsyncActionTypesArray('SEARCH_MEMBER_LIST_BY_KEYWORD'),
  ...createAsyncActionTypesArray('PASSBASE_NEW_AUTHENTICATION'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('FB_LOG_IN_FLOW'),
  ...createAsyncActionTypesArray('LOG_IN_FLOW'),
  ...createAsyncActionTypesArray('LOG_OUT_FLOW'),
  ...createAsyncActionTypesArray('REGISTER_FLOW'),
  ...createAsyncActionTypesArray('CHANGE_PASSWORD_FLOW'),
  ...createAsyncActionTypesArray('FORGOT_PASSWORD_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_MEMBER_INFO_FLOW'),
  ...createAsyncActionTypesArray('GET_MEMBER_PROFILE_FLOW'),
  ...createAsyncActionTypesArray('SEND_SMS_FLOW'),
  ...createAsyncActionTypesArray('SMS_LOGIN_FLOW'),
  ...createAsyncActionTypesArray('TOGGLE_FOLLOW_FLOW'),
  ...createAsyncActionTypesArray('GET_FOLLOWING_LIST_FLOW'),
  ...createAsyncActionTypesArray('UPLOAD_OUTFIT_FLOW'),
  ...createAsyncActionTypesArray('GET_MEMBER_OUTFIT_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_OUTFIT_DETAIL_FLOW'),
  ...createAsyncActionTypesArray('SEND_OUTFIT_COMMENT_FLOW'),
  ...createAsyncActionTypesArray('GET_PROTECT_TREE_END_TIME_FLOW'),
  ...createAsyncActionTypesArray('GET_REDUCE_DEPOSIT_LIST_FLOW'),
  ...createAsyncActionTypesArray('DELETE_REDUCE_DEPOSIT_BAG_FLOW'),
  ...createAsyncActionTypesArray('GET_REDUCE_DEPOSIT_USER_LIST_FLOW'),
  ...createAsyncActionTypesArray('CREATE_REDUCE_DEPOSIT_USER_FLOW'),
  ...createAsyncActionTypesArray('DELETE_REDUCE_DEPOSIT_USER_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_REDUCE_DEPOSIT_USER_FLOW'),
  ...createAsyncActionTypesArray('GET_USER_ID_BY_COMMUNITY_NAME_FLOW'),
  ...createAsyncActionTypesArray('HAD_READ_NOTIFICATION_FLOW'),
  ...createAsyncActionTypesArray('All_READ_NOTIFICATION_FLOW'),
  ...createAsyncActionTypesArray('GET_NOTIFICATION_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_MEMBER_COUPON_LIST_FLOW'),
  ...createAsyncActionTypesArray('DELETE_OUTFITS_FLOW'),
  ...createAsyncActionTypesArray('GET_WITHDRAW_ORDER_NUMBERS_FLOW'),
  ...createAsyncActionTypesArray('PASSBASE_NEW_AUTHENTICATION_FLOW'),
  ...createAsyncActionTypesArray('SEARCH_MEMBER_LIST_BY_KEYWORD_FLOW'),

  // ----- STATUS -----
  'RESET_STATUS',

  { prefix: 'MEMBER' },
);
