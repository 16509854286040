import React from 'react';
import Slider from 'react-slick';
import BagBannerSlide from './BagBannerSlide';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import Assets from '../../../../constants/Assets';
import Paths from '../../../../routes/Paths';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const styles = {
  mainContainer: {
    margin: 0,
  },
  themeItem: {
    width: 500,
    margin: '50px auto',
    height: 300,
    position: 'relative',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  text: {
    position: 'absolute',
    left: -20,
    top: -20,
    padding: 10,
    fontSize: '30px',
    color: 'white',
    backgroundColor: 'black',
  },
  slideContainer: {
    width: '100vw',
    height: 'auto',
    marginLeft: 'calc(50% - 50vw)',
    transform: 'translate(0, 0)',
  },
  dotStyle: {
    background: 'white',
    border: 'none',
    outline: 'none',
    padding: '6px',
  },
  mobileSlide: {
    height: '600px',
  },
  loanButtonContainer: {
    textAlign: 'center',
    marginTop: '30px',
  },
  loanButton: {
    display: 'inline-block',
    color: '#974107',
    border: '1px solid #974107',
    verticalAlign: 'middle',
    padding: '15px 45px',
  },
  mobileImgStyle: {
    height: '100%',
  },
  sellLendingContainer: {
    textAlign: 'center',
    position: 'relative',
    height: 'auto',
    width: '100vw',
  },
  homeRentingImg: {
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    height: 'auto',
    objectFit: 'cover',
  },
};

const webBannerData = [
  {
    imgUrl: Assets.bagBanner01,
    path: `${Paths.BAG_LIST}?search={"bagType":[1,3]}&page=1`,
  },
  {
    imgUrl: Assets.bagBanner02,
    path: null,
  },
  {
    imgUrl: Assets.bagBanner03,
    path: null,
  },
];


const mobileBannerData = [
  {
    imgUrl: Assets.bagBannerMobile01,
    path: `${Paths.ESSENCES_LIST}/?search={"bagType":[2]}&page=1`,
  },
  {
    imgUrl: Assets.bagBannerMobile02,
    path: null,
  },
  {
    imgUrl: Assets.bagBannerMobile03,
    path: null,
  },
];

const BagBannerSlideComponent = () => (
  <div style={styles.mainContainer}>
    <div style={styles.slideContainer}>
      <Default>
        <div style={styles.slideContainer}>
          <Slider
            arrows
            dots
            autoplay
            speed={1300}
            slidesToShow={1}
            slidesToScroll={1}
          >
            {(webBannerData || []).map(item => (
              <BagBannerSlide
                key={item.imgUrl}
                imgUrl={item.imgUrl}
                path={item.path}
              />
            ))}
          </Slider>
        </div>
      </Default>
      <Mobile>
        <Slider
          arrows
          dots
          autoplay
          speed={1300}
          slidesToShow={1}
          slidesToScroll={1}
        >
          {(mobileBannerData || []).map(item => (
            <BagBannerSlide
              key={item.imgUrl}
              imgUrl={item.imgUrl}
              style={styles.mobileSlide}
              path={item.path}
              mobileImgStyle={styles.mobileImgStyle}
            />
          ))}
        </Slider>
      </Mobile>
    </div>
  </div>
);

export default BagBannerSlideComponent;
