import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_CART_LIST'),
  ...createAsyncActionTypesArray('DELETE_CART'),
  ...createAsyncActionTypesArray('ADD_CART'),
  ...createAsyncActionTypesArray('RESET_CART'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_CART_LIST_FLOW'),
  ...createAsyncActionTypesArray('DELETE_CART_FLOW'),
  ...createAsyncActionTypesArray('ADD_CART_FLOW'),

  { prefix: 'CART' },
);
