import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPixel from 'react-facebook-pixel';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { Link } from '@reach/router';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import MemberCouponComponent from '../../../Member/components/MemberCouponComponent';
import ModalComponent from '../../../../components/ModalComponent';
import ReduxStatus from '../../../../constants/ReduxStatus';
import PlaceOrderTableComponent from '../PlaceOrderTableComponent';
import Button from '../../../../components/Button';
import amountPattern from '../../../../utils/AmountPattern';
import { Colors } from '../../../../constants/Color';
import { FontSize } from '../../../../constants/FontSize';
import { shippingCost } from '../../../../constants/ShippingAmount';
import Assets from '../../../../constants/Assets';
import { commodityTypeId } from '../../../../constants/Type';
import { BagLevelId } from '../../../../constants/BagLevels';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import bagOrClothesPath from '../../../../utils/bagOrClothesPath';
import ZeroCard from '../../../../constants/ZeroCard';

const styles = {
  mainContainer: {
    margin: '140px 20px 0 20px',
    position: 'relative',
    fontSize: FontSize.body,
  },
  tapPayIframe: {
    margin: 0,
    outline: 0,
    WebkitAppearance: 'none',
    tapHighlightColor: 'rgba(255,255,255,0)',
    lineHeight: '1.21428571em',
    padding: '.578571em 1em',
    fontSize: '1em',
    background: '#fff',
    border: '1px solid rgba(34,36,38,.15)',
    color: 'rgba(0,0,0,.87)',
    borderRadius: '.28571429rem',
    boxShadow: '0 0 0 0 transparent inset',
    WebkitTransition: 'color .1s ease,border-color .1s ease',
    transition: 'color .1s ease,border-color .1s ease',
    width: '100%',
  },
  tableContainer: {
    width: '70%',
    margin: '0 auto',
    marginBottom: '100px',
  },
  detailTable: {
    tableLayout: 'fixed',
  },
  tableHeader: {
    margin: '10px 0px',
    width: '100%',
    paddingBottom: 10,
    borderTop: '1px solid #BABABA',
    borderBottom: '1px solid #BABABA',
    tableLayout: 'fixed',
  },
  width20: {
    width: '20%',
    margin: '20px 0 10px 0',
    height: '60px',
  },
  width40: {
    width: '40%',
    margin: '20px 0 10px 0',
    paddingRight: '8%',
  },
  img: {
    width: '100%',
    minWidth: '90px',
    objectFit: 'contain',
    height: 160,
    backgroundColor: 'white',
    margin: '20px 0',
  },
  underTableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderTop: '1px solid #BABABA',
    padding: '60px 0',
    borderBottom: '1px solid #D1D3D4',
    position: 'relative',
    height: '1200px',
  },
  fieldContainer: {
    width: '65%',
    position: 'absolute',
    margin: '0 auto',
    left: 20,
  },
  fieldMobileContainer: {
    width: '100%',
    position: 'relative',
    margin: '0',
    borderTop: '1.5px solid #D1D3D4',
    padding: '20px 0',
  },
  infoTable: {
    fontSize: FontSize.body,
  },
  orderDetailContainer: {
    margin: '0 auto',
    width: '35%',
    position: 'absolute',
    height: '100%',
    minHeight: '180px',
    right: 0,
    top: 0,
  },
  orderDetailMobileContainer: {
    margin: '0 auto',
    width: '100%',
    position: 'relative',
    height: 'auto',
    minHeight: '120px',
    borderBottom: '1.5px solid #D1D3D4',
  },
  orderDetailText: {
    width: '100%',
    height: '130px',
    position: 'absolute',
    bottom: '0px',
    textAlign: 'right',
  },
  detailTableContainer: {
    position: 'absolute',
    height: 'auto',
    width: 'auto',
    right: '0px',
  },
  detailItem: {
    width: '80px',
  },
  detailDate: {
    color: Colors.introRightTitle,
    fontSize: '14px',
    fontFamily: 'Myriad-Variable-Concept',
  },
  detailAmount: {
    color: Colors.introRightTitle,
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'Myriad-Variable-Concept',
  },
  amountText: {
    position: 'absolute',
    right: '0px',
    bottom: '0px',
  },
  amountTextMobile: {
    top: '10px',
  },
  noteContainer: {
    width: '260px',
    height: 'auto',
    margin: '0 auto',
    lineHeight: '24px',
    paddingTop: '60px',
  },
  buttonContainer: {
    marginTop: '40px',
    textAlign: 'right',
  },
  orderContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '100px',
  },
  noteTitle: {
    fontWeight: 'bold',
  },
  noteBlank: {
    height: '24px',
    width: '100%',
  },
  noteCreditIconContainer: {
    display: 'flex',
  },
  noteCreditIcon: {
    width: '50px',
    height: '40px',
    marginBottom: '-20px',
    marginTop: '15px',
    objectFit: 'contain',
  },
  zeroCardIcon: {
    width: '30px',
    height: '20px',
    marginBottom: '-20px',
    marginTop: '15px',
    objectFit: 'contain',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    color: 'black',
    position: 'relative',
    marginBottom: '20px',
  },
  icon: {
    width: '40px',
    height: '40px',
    position: 'relative',
    top: '5px',
    left: '0px',
    objectFit: 'contain',
  },
  title: {
    fontSize: FontSize.title,
    marginLeft: '16px',
  },
  shippingTax: {
    marginTop: '20px',
    color: Colors.introRightTitle,
    fontSize: '8px',
  },
  bagName: {
    fontWeight: 'bold',
    marginTop: '12px',
    fontSize: '8px',
  },
  instalmentTextStyle: {
    width: '500px',
  },
  originAmountContainer: {
    position: 'absolute',
    right: 0,
    bottom: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 150,
    height: 30,
  },
  originAmountMobileContainer: {
    position: 'absolute',
    right: 0,
    bottom: 60,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 150,
    height: 30,
  },
  couponAmountContainer: {
    position: 'absolute',
    right: 0,
    bottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 150,
    height: 30,
  },
  couponAmountMobileContainer: {
    position: 'absolute',
    right: 0,
    bottom: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 150,
    height: 30,
  },
  couponContent: {
    fontSize: 14,
    color: 'black',
  },
  depositNote: {
    color: Colors.introRightTitle,
    fontSize: '12px',
    fontFamily: 'Myriad-Variable-Concept',
    textAlign: 'right',
    width: '100%',
  },
};

const OrderCreatePage = ({
  orderCreateReduxStatus,
  isShowDialog,
  dialogInfo,
  transactionReduxError,
  receiptType,
  eReceiptAddress,
  recipientName,
  recipientPhone,
  recipientZipCode,
  recipientAddress,
  rentDays,
  rentStartDate,
  rentEndDate,
  note,
  phoneBarCode,
  taxId,
  receiptHead,
  taxReceiptAddress,
  donateOrganizationList,
  bagData,
  amount,
  onSubmitOrderCreate,
  onDialogConfirm,
  onValueChanged,
  setEReceiptAddress,
  tableContainerStyle,
  isUpdateMemberInfo,
  isAutoInputRecipient,
  onUpdateMemberInfo,
  onAutoInputRecipientChanged,
  onInstalmentChanged,
  onZeroCardInstallmentChanged,
  isShowOrderCreateFailModal,
  onOrderCreateFailConfirm,
  orderType,
  shippingType,
  onSelectedMemberCoupon,
  selectedMemberCoupon,
  clearSelectedMemberCoupon,
  sevenElevenAddress,
  sevenElevenMarket,
  handleInputCouponText,
  inputCouponText,
  rentDateList,
  instalment,
  zeroCardInstallment,
  onPayWayTypeChanged,
}) => {
  const couponType = R.path(['coupon', 'couponType', 'name'], selectedMemberCoupon);
  const commodityType = R.pathOr(1, ['commodityTypeId'], bagData);
  const deposit = R.pathOr(0, ['deposit'], bagData);
  const newLevel = R.pathOr(0, ['newLevel'], bagData);

  const [isShowMemberCouponComponent, setIsShowMemberCouponComponent] = useState(false);
  const [isShowCouponDisableModal, setIsShowCouponDisableModal] = useState(false);

  const onClickMyCoupon = () => {
    if (amount <= 600) {
      setIsShowCouponDisableModal(true);
      return;
    }

    setIsShowMemberCouponComponent(true);
  };

  const getCouponAmount = () => {
    let couponAmount = 0;
    const originAmount = couponType === '押金折扣碼' ? deposit : amount;

    if (R.pathOr(0, ['coupon', 'percent'], selectedMemberCoupon) !== 0) {
      couponAmount = Math.ceil(originAmount * (1 - (R.pathOr(0, ['coupon', 'percent'], selectedMemberCoupon) / 100)));
    } else {
      couponAmount = R.pathOr(0, ['coupon', 'minus'], selectedMemberCoupon);
    }

    return `-${couponAmount}`;
  };

  const calculateTotalAmount = (inputAmount, type) => {
    let finalAmount = inputAmount;
    if (
      ((couponType === '售價折扣碼' || couponType === '租金折扣碼') && type === 'amount')
      || (couponType === '押金折扣碼' && type === 'deposit')
    ) {
      if (R.pathOr(0, ['coupon', 'percent'], selectedMemberCoupon) !== 0) {
        finalAmount = Math.ceil(inputAmount * (R.pathOr(0, ['coupon', 'percent'], selectedMemberCoupon) / 100));
      } else {
        finalAmount = inputAmount - R.pathOr(0, ['coupon', 'minus'], selectedMemberCoupon);
      }
    }

    if (commodityType === commodityTypeId.essences || newLevel === BagLevelId) {
      return finalAmount + shippingCost.blackCat;
    }
    return `NT$${amountPattern(finalAmount)}`;
  };

  const renderOrderInfo = (style) => {
    if (bagData !== null) {
      return (
        <React.Fragment>
          <div style={{ ...styles.amountText, ...style }}>
            <div style={styles.detailDate}>
              金額
              <span style={styles.detailAmount}>
                {Number(amount)}
              </span>
            </div>
            {
              commodityType === commodityTypeId.essences || newLevel === BagLevelId ? (
                <div style={styles.detailDate}>
                  運費
                  <span style={styles.detailAmount}>
                    {shippingCost.blackCat}
                  </span>
                </div>
              ) : null
            }
            {
            !R.isNil(selectedMemberCoupon) && (
            <div style={styles.detailDate}>
              {couponType}
              <span style={styles.detailAmount}>
                {getCouponAmount()}
              </span>
            </div>
            )
          }
            <div style={styles.detailDate}>
              總金額
              <span style={styles.detailAmount}>
                {calculateTotalAmount(amount, 'amount')}
              </span>
            </div>
            {instalment ? (
              <div style={styles.detailDate}>
                分期手續費
                <span style={styles.detailAmount}>
                  NT$
                  {amountPattern(Math.round(amount * (
                    instalment === 3 ? 0.01 : 0.015))) }
                </span>
              </div>
            ) : null}
            {zeroCardInstallment ? (
              <div style={styles.detailDate}>
                0卡分期手續費
                <span style={styles.detailAmount}>
                  NT$
                  {amountPattern(Math.round(amount * ZeroCard.getInstallmentRate(zeroCardInstallment)))}
                </span>
              </div>
            ) : null}
            {
              orderType !== 'buy' ? (
                <div style={styles.detailDate}>
                  押金
                  <span style={styles.detailAmount}>
                    {calculateTotalAmount(deposit, 'deposit')}
                  </span>
                </div>
              ) : null
            }
          </div>
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <ReduxStatusVisualizedComponent
      showModalOnError
      modalFailureContent={transactionReduxError || dialogInfo}
      reduxStatus={orderCreateReduxStatus}
      modalFailureTitle="交易失敗"
    >

      {/* GTag implant */}
      <Helmet>
        <script>
          {`
          function gtag_report_conversion(url) {
            var callback = function () {
              if (typeof(url) != 'undefined') {
                window.location = url;
              }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-627439533/1dGOCJG6mNMBEK3vl6sC',
                'transaction_id': '',
                'event_callback': callback
            });
            return false;
          }
          `}
        </script>
      </Helmet>

      <MemberCouponComponent
        orderType={orderType}
        onSelectedMemberCoupon={onSelectedMemberCoupon}
        isVisible={isShowMemberCouponComponent}
        onModalClose={() => setIsShowMemberCouponComponent(false)}
        inputCouponText={inputCouponText}
      />
      <ModalComponent
        isVisible={isShowCouponDisableModal}
        onModalClose={() => setIsShowCouponDisableModal(false)}
        onModalConfirmed={() => setIsShowCouponDisableModal(false)}
        content="使用折扣碼消費須滿 $600"
      />

      <ModalComponent
        maskClosable={false}
        isVisible={isShowDialog}
        onModalClose={onDialogConfirm}
        onModalConfirmed={onDialogConfirm}
        title={orderCreateReduxStatus === ReduxStatus.STATUS_NORMAL ? '下訂成功' : ''}
        content={dialogInfo}
        buttonText="前往"
      />
      <ModalComponent
        maskClosable={false}
        isVisible={isShowOrderCreateFailModal}
        onModalClose={onOrderCreateFailConfirm}
        onModalConfirmed={onOrderCreateFailConfirm}
        title="訂單錯誤"
        content="請返回上一頁重新選擇租借日期或是按下購買按鈕"
        buttonText="回上一頁"
      />
      <div style={styles.mainContainer}>
        <div style={styles.orderContainer}>
          <div style={{ ...styles.tableContainer, ...tableContainerStyle }}>
            <div style={styles.titleContainer}>
              <img style={styles.icon} src={Assets.cartTitleIcon} alt="" />
              <h1 style={styles.title}>
                Shopping Cart
              </h1>
            </div>
            <table style={styles.infoTable}>
              <thead style={styles.tableHeader}>
                <tr>
                  <td style={styles.width20}>商品資訊</td>
                  <td style={styles.width40}>描述</td>
                  <td style={styles.width20}>價格</td>
                  {/* was折扣價 now hide for future coupon */}
                  <Default>
                    {orderType === 'rent' ? (
                      <td style={styles.width20}>租借期間</td>
                    ) : null }
                  </Default>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ ...styles.width20, ...styles.whiteBackgroundColor }}>
                    <Link to={`${bagOrClothesPath(commodityType)}/${R.pathOr('0', ['bagNo'], bagData)}`}>
                      <img style={styles.img} src={R.pathOr('', ['frontPic', 'uri'], bagData)} alt="" />
                    </Link>
                  </td>
                  <td style={styles.width40}>
                    <Link to={`${bagOrClothesPath(commodityType)}/${R.pathOr('0', ['bagNo'], bagData)}`}>
                      <div>
                        {R.pathOr(null, ['bagNo'], bagData) || R.pathOr(null, ['clothesNo'], bagData)}
                      </div>
                      <div style={styles.bagName}>
                        <div>
                          {R.pathOr('0', ['tags', 0, 'name'], bagData)}
                        </div>
                        {`${R.pathOr('', ['purchaseYear'], bagData)}  ${R.pathOr('0', ['name'], bagData)}`}
                      </div>
                    </Link>
                  </td>
                  <td style={styles.width20}>
                    {`NT$ ${amountPattern(amount)}`}
                  </td>
                  <Default>
                    {orderType === 'rent' ? (
                      <td style={styles.width20}>
                        <div style={styles.detailDate}>
                          {`${rentDays}天`}
                          <br />
                          {`${moment(rentStartDate).format('YYYY/MM/DD')} - ${moment(rentEndDate).format('YYYY/MM/DD')}`}
                        </div>
                      </td>
                    ) : null }
                  </Default>
                  {/* TODO: was折扣價 now hide for future coupon */}
                  {/* <Default>
                    <td style={styles.width20}>
                      {`NT. ${amountPattern(amount)}`}
                    </td>
                  </Default> */}
                </tr>
              </tbody>
            </table>
            <Default>
              <div style={styles.underTableContainer}>
                <div style={styles.fieldContainer}>
                  <PlaceOrderTableComponent
                    orderType={orderType}
                    bagData={bagData}
                    amount={amount}
                    onValueChanged={onValueChanged}
                    recipientName={recipientName}
                    recipientPhone={recipientPhone}
                    recipientZipCode={recipientZipCode}
                    recipientAddress={recipientAddress}
                    rentStartDate={rentStartDate}
                    rentEndDate={rentEndDate}
                    note={note}
                    receiptType={receiptType}
                    phoneBarCode={phoneBarCode}
                    taxId={taxId}
                    receiptHead={receiptHead}
                    taxReceiptAddress={taxReceiptAddress}
                    donateOrganizationList={donateOrganizationList}
                    eReceiptAddress={eReceiptAddress}
                    setEReceiptAddress={setEReceiptAddress}
                    isUpdateMemberInfo={isUpdateMemberInfo}
                    onUpdateMemberInfo={onUpdateMemberInfo}
                    isAutoInputRecipient={isAutoInputRecipient}
                    onAutoInputRecipientChanged={onAutoInputRecipientChanged}
                    onInstalmentChanged={onInstalmentChanged}
                    onPayWayTypeChanged={onPayWayTypeChanged}
                    onZeroCardInstallmentChanged={onZeroCardInstallmentChanged}
                    onClickMyCoupon={onClickMyCoupon}
                    clearSelectedMemberCoupon={clearSelectedMemberCoupon}
                    instalmentTextStyle={styles.instalmentTextStyle}
                    shippingType={shippingType}
                    sevenElevenAddress={sevenElevenAddress}
                    sevenElevenMarket={sevenElevenMarket}
                    onInputCouponChange={handleInputCouponText}
                    inputCouponText={inputCouponText}
                    rentDateList={rentDateList}
                  />
                </div>

                <div style={styles.orderDetailContainer}>
                  <div style={styles.orderDetailText}>
                    {renderOrderInfo()}
                  </div>
                </div>
              </div>
            </Default>
            <Mobile>
              <div style={styles.fieldMobileContainer}>
                <PlaceOrderTableComponent
                  orderType={orderType}
                  bagData={bagData}
                  amount={amount}
                  onValueChanged={onValueChanged}
                  recipientName={recipientName}
                  recipientPhone={recipientPhone}
                  recipientZipCode={recipientZipCode}
                  recipientAddress={recipientAddress}
                  rentStartDate={rentStartDate}
                  rentEndDate={rentEndDate}
                  note={note}
                  receiptType={receiptType}
                  phoneBarCode={phoneBarCode}
                  taxId={taxId}
                  receiptHead={receiptHead}
                  taxReceiptAddress={taxReceiptAddress}
                  donateOrganizationList={donateOrganizationList}
                  eReceiptAddress={eReceiptAddress}
                  setEReceiptAddress={setEReceiptAddress}
                  isUpdateMemberInfo={isUpdateMemberInfo}
                  onUpdateMemberInfo={onUpdateMemberInfo}
                  isAutoInputRecipient={isAutoInputRecipient}
                  onAutoInputRecipientChanged={onAutoInputRecipientChanged}
                  onClickMyCoupon={onClickMyCoupon}
                  onInstalmentChanged={onInstalmentChanged}
                  onPayWayTypeChanged={onPayWayTypeChanged}
                  onZeroCardInstallmentChanged={onZeroCardInstallmentChanged}
                  clearSelectedMemberCoupon={clearSelectedMemberCoupon}
                  shippingType={shippingType}
                  sevenElevenAddress={sevenElevenAddress}
                  sevenElevenMarket={sevenElevenMarket}
                  onInputCouponChange={handleInputCouponText}
                  inputCouponText={inputCouponText}
                  rentDateList={rentDateList}
                />
              </div>
              <div style={styles.orderDetailMobileContainer}>
                <div style={styles.orderDetailText}>
                  <Mobile>
                    {renderOrderInfo(styles.amountTextMobile)}
                  </Mobile>
                </div>
              </div>
            </Mobile>
            <div style={styles.depositNote}>本平台為自助租借</div>
            <div style={styles.depositNote}>商品寄出由出租方，歸還由租借方支出運費</div>
            <div style={styles.depositNote}>歸還後出租方確認商品無誤</div>
            <div style={styles.depositNote}>平台將於五個工作天內刷退押金</div>
            <div style={styles.buttonContainer}>
              <Button
                text="送出訂單"
                onClick={() => {
                  ReactPixel.track('Purchase', {
                    content_type: 'product',
                    currency: 'TWD',
                    content_ids: [R.pathOr(null, ['id'], bagData)],
                    value: calculateTotalAmount(amount, 'amount'),
                    contents: [{
                      id: R.pathOr(null, ['id'], bagData),
                      quantity: '1',
                      commodityType: commodityType === 1 || commodityType === '1' ? 'bags' : 'clothes',
                      type: orderType,
                      name: R.path(['name'], bagData),
                    }],
                    num_items: '1',
                    content_name: R.pathOr(null, ['name'], bagData),
                  });
                  onSubmitOrderCreate();
                }}
              />
            </div>
          </div>

          <div style={{ ...styles.noteContainer, ...tableContainerStyle }}>
            <div style={styles.noteTitle}>
              下一步：
            </div>
            <div style={styles.noteBlank} />
            選擇您想要的付款方式
            <br />
            平台提供的付款方式如下：
            <br />
            <b>租借 </b>
            - 信用卡一次付清
            <br />
            <b>購買 </b>
            - 信用卡一次付清
            <br />
            - 信用卡分期付款(3期/6期需額外支付手續費)
            <br />
            - 中租0卡分期付款(3/6/9/12/18/24期需額外支付手續費)
            <br />
            <div style={styles.noteCreditIconContainer}>
              <img src={Assets.orderCredit01} style={styles.noteCreditIcon} alt="" />
              <img src={Assets.orderCredit02} style={styles.noteCreditIcon} alt="" />
              <img src={Assets.orderCredit03} style={styles.noteCreditIcon} alt="" />
              <img src={Assets.zeroCardIcon} style={styles.noteCreditIcon} alt="" />
            </div>
            <div style={styles.noteBlank} />
            <div style={styles.noteBlank} />
            <hr />
            <div style={styles.noteBlank} />
            <div style={styles.noteTitle}>
              退換貨須知：
            </div>
            <div style={styles.noteBlank} />
            凡於OP 永續時尚共享平台
            <br />
            購買精品包包及全新商品
            <br />
            皆享七天鑑賞期
            <br />
            於收到包裹當日算起
            <br />
            *其餘服務恕不適用
            <br />
            <div style={styles.noteBlank} />
            <hr />
            <div style={styles.noteBlank} />
          </div>
        </div>
      </div>
    </ReduxStatusVisualizedComponent>
  );
};

OrderCreatePage.propTypes = {
  orderCreateReduxStatus: PropTypes.string,
  transactionReduxError: PropTypes.string,
  isShowDialog: PropTypes.bool,
  dialogInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onDialogConfirm: PropTypes.func.isRequired,
  onSubmitOrderCreate: PropTypes.func.isRequired,
  recipientName: PropTypes.string,
  recipientPhone: PropTypes.string,
  recipientZipCode: PropTypes.string,
  recipientAddress: PropTypes.string,
  rentStartDate: PropTypes.string,
  rentEndDate: PropTypes.string,
  rentDays: PropTypes.number,
  note: PropTypes.string,
  receiptType: PropTypes.string,
  phoneBarCode: PropTypes.string,
  taxId: PropTypes.string,
  receiptHead: PropTypes.string,
  taxReceiptAddress: PropTypes.string,
  eReceiptAddress: PropTypes.string,
  onValueChanged: PropTypes.func.isRequired,
  donateOrganizationList: PropTypes.array,
  bagData: PropTypes.object,
  amount: PropTypes.number,
  setEReceiptAddress: PropTypes.func.isRequired,
  tableContainerStyle: PropTypes.object,
  isUpdateMemberInfo: PropTypes.bool,
  onUpdateMemberInfo: PropTypes.func.isRequired,
  isAutoInputRecipient: PropTypes.bool,
  onAutoInputRecipientChanged: PropTypes.func.isRequired,
  onOrderCreateFailConfirm: PropTypes.func.isRequired,
  onInstalmentChanged: PropTypes.func,
  onPayWayTypeChanged: PropTypes.func,
  isShowOrderCreateFailModal: PropTypes.bool,
  orderType: PropTypes.string,
  shippingType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onSelectedMemberCoupon: PropTypes.func.isRequired,
  clearSelectedMemberCoupon: PropTypes.func.isRequired,
  selectedMemberCoupon: PropTypes.object,
  sevenElevenMarket: PropTypes.string,
  sevenElevenAddress: PropTypes.string,
  handleInputCouponText: PropTypes.func.isRequired,
  onZeroCardInstallmentChanged: PropTypes.func,
  inputCouponText: PropTypes.string,
  rentDateList: PropTypes.array,
  instalment: PropTypes.number,
  zeroCardInstallment: PropTypes.number,
};

OrderCreatePage.defaultProps = {
  orderCreateReduxStatus: null,
  transactionReduxError: null,
  selectedMemberCoupon: null,
  isShowDialog: false,
  dialogInfo: '',
  rentStartDate: new Date(),
  rentEndDate: new Date(),
  recipientName: '',
  recipientPhone: '',
  recipientAddress: '',
  recipientZipCode: '',
  phoneBarCode: '',
  taxId: '',
  receiptHead: '',
  taxReceiptAddress: '',
  eReceiptAddress: '',
  rentDays: 1,
  note: '',
  receiptType: '1',
  shippingType: null,
  donateOrganizationList: [],
  bagData: null,
  amount: 0,
  tableContainerStyle: {},
  isUpdateMemberInfo: false,
  isShowOrderCreateFailModal: false,
  isAutoInputRecipient: false,
  orderType: 'rent',
  onInstalmentChanged: () => {},
  onPayWayTypeChanged: () => {},
  onZeroCardInstallmentChanged: () => {},
  sevenElevenMarket: '',
  sevenElevenAddress: '',
  inputCouponText: '',
  rentDateList: [],
  instalment: 0,
  zeroCardInstallment: 0,
};

export default OrderCreatePage;
