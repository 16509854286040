import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { rentAmountCalculate } from '../../utils/rentAmountCalculate';
import { Colors } from '../../constants/Color';
import Default from '../RWDComponent/Default';
import Mobile from '../RWDComponent/Mobile';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'fit-content',
    height: 'fit-content',
    margin: '10px 0',
  },
  mainMobileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 'fit-content',
    margin: '10px 0',
  },
  selectCircle: {
    margin: '0px 5px',
    outline: 'none',
    cursor: 'pointer',
    fontSize: 15,
    width: '5.2vw',
    height: '5.2vw',
    minWidth: 40,
    minHeight: 40,
    textAlign: 'center',
    borderRadius: 150,
    border: 'none',
    color: '#fff',
    backgroundColor: '#75331B',
  },
  selectMobileCircle: {
    outline: 'none',
    cursor: 'pointer',
    fontSize: 15,
    width: '21vw',
    height: '21vw',
    textAlign: 'center',
    borderRadius: 150,
    border: 'none',
    color: '#fff',
    backgroundColor: '#75331B',
  },
  unSelectCircle: {
    margin: '0px 5px',
    outline: 'none',
    cursor: 'pointer',
    fontSize: 15,
    width: '5.2vw',
    height: '5.2vw',
    minWidth: 40,
    minHeight: 40,
    textAlign: 'center',
    borderRadius: 150,
    color: '#75331B',
    border: '1px solid #75331B',
    backgroundColor: '#fff',
  },
  unSelectMobileCircle: {
    outline: 'none',
    cursor: 'pointer',
    fontSize: 15,
    width: '21vw',
    height: '21vw',
    textAlign: 'center',
    borderRadius: 150,
    color: '#75331B',
    border: '1px solid #75331B',
    backgroundColor: '#fff',
  },
  calculateContainer: {
    position: 'relative',
    height: 'auto',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px',
  },
  calculateMobileContainer: {
    position: 'relative',
    height: 'auto',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    padding: '0px 10px',
  },
  calculateUnit: {
    width: '22%',
    height: '7.3vw',
    minHeight: '100px',
    minWidth: '100px',
    position: 'relative',
    outline: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    backgroundColor: 'white',
  },
  calculateUnitMobile: {
    width: '22vw',
    height: '22vw',
    position: 'relative',
    outline: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    backgroundColor: 'white',
  },
  calculateUp: {
    height: '50%',
    width: '100%',
    borderRadius: '50rem 50rem 0px 0px',
    backgroundColor: '#a48b78',
    color: 'white',
    position: 'relative',
  },
  calculateUpSelect: {
    height: '50%',
    width: '100%',
    borderRadius: '50rem 50rem 0px 0px',
    backgroundColor: '#75331B',
    color: 'white',
    position: 'relative',
  },
  calculateDown: {
    height: '50%',
    width: '100%',
    borderRadius: '0px 0px 50rem 50rem',
    backgroundColor: '#a48b78',
    color: 'white',
    position: 'relative',
  },
  calculateDownSelect: {
    height: '50%',
    width: '100%',
    borderRadius: '0px 0px 50rem 50rem',
    backgroundColor: '#75331B',
    color: 'white',
    position: 'relative',
  },
  circleLine: {
    border: 0,
    borderTop: '0.1px white solid',
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
  },
  calculateDayText: {
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    fontSize: '14px',
    transform: 'translate(-50%, 0%)',
  },
  calculateDayTextMobile: {
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    fontSize: '12px',
    transform: 'translate(-50%, 0%)',
  },
  calculateAmountText: {
    position: 'absolute',
    top: '8px',
    left: '50%',
    fontSize: '14px',
    transform: 'translate(-50%, 0%)',
    whiteSpace: 'nowrap',
  },
  calculateAmountTextMobile: {
    position: 'absolute',
    top: '8px',
    left: '50%',
    fontSize: '12px',
    transform: 'translate(-50%, 0%)',
    whiteSpace: 'nowrap',
  },
  title: {
    fontSize: '14px',
    color: Colors.introRightTitle,
    margin: '0px 4px',
  },
  dateNoteContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginTop: '20px',
  },
  dateNoteMobileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '20px',
  },
  dateTitle: {
    fontSize: '24px',
    marginTop: 8,
  },
};

const BagRentAmountSelectorComponent = ({
  rentAmountPerDay,
  onSelectDays,
  isDisable,
  washAmount,
}) => {
  const [days, setDays] = useState(4);
  const dayArray = [4, 8, 12, 16];

  const renderCalculateCircle = (day, isEarn, isMobile) => (
    <button
      style={isMobile ? styles.calculateUnitMobile : styles.calculateUnit}
      key={day}
      disabled={isDisable}
      type="button"
      onClick={() => {
        setDays(day);
        onSelectDays(day, rentAmountCalculate(rentAmountPerDay, day, false, washAmount));
      }}
    >
      <div style={days === day ? styles.calculateUpSelect : styles.calculateUp}>
        <div style={isMobile ? styles.calculateDayTextMobile : styles.calculateDayText}>
          {`${day}天`}
        </div>
      </div>
      <div style={styles.circleLine} />
      <div style={days === day ? styles.calculateDownSelect : styles.calculateDown}>
        <div style={isMobile ? styles.calculateAmountTextMobile : styles.calculateAmountText}>
          $
          {rentAmountCalculate(rentAmountPerDay, day, isEarn, washAmount)}
        </div>
      </div>
    </button>
  );

  return (
    <React.Fragment>
      <Default>
        <div style={styles.dateNoteContainer}>
          <h1 style={styles.dateTitle}>
            Price
          </h1>
          <div style={styles.title}>
            租借價格
          </div>
        </div>
      </Default>
      <Mobile>
        <div style={styles.dateNoteMobileContainer}>
          <h1 style={styles.dateTitle}>
            Price
          </h1>
          <div style={styles.title}>
            租借價格
          </div>
        </div>
      </Mobile>
      <div style={styles.calculateContainer}>
        <Default>
          {dayArray.map(day => renderCalculateCircle(day, false, false))}
        </Default>
        <Mobile>
          {dayArray.map(day => renderCalculateCircle(day, false, true))}
        </Mobile>
      </div>
    </React.Fragment>
  );
};

BagRentAmountSelectorComponent.propTypes = {
  rentAmountPerDay: PropTypes.number.isRequired,
  onSelectDays: PropTypes.func.isRequired,
  isDisable: PropTypes.bool.isRequired,
  washAmount: PropTypes.number.isRequired,
};


export default BagRentAmountSelectorComponent;
