import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActionCreators from '../../ActionCreator';
import MemberActionCreators from '../../../Member/ActionCreator';
import BagActionCreators from '../../../Bag/ActionCreator';
import TagActionCreators from '../../../Tag/ActionCreator';
import HeaderComponent from './HeaderComponent';
import { commodityTypeId } from '../../../../constants/Type';

import {
  searchMemberListByKeywordSelector,
  isLogInSelector,
  memberNameSelector,
  memberSocialLoginSelector,
} from '../../../Member/selector';

import {
  searchBagListByKeywordSelector,
} from '../../../Bag/selector';

import {
  headerCommodityReduxErrorSelector,
  headerCommodityReduxStatusSelector,
  headerCommodityListSelector,
  maqueeListSelector,
} from '../../selector';

import {
  categorizedTagListSelector,
} from '../../../Tag/selector';

const HeaderContainer = ({
  userList,
  bagList,
  commodityList,
  actions: {
    searchBagListByKeywordFlowRequest,
    searchMemberListByKeywordFlowRequest,
    getHeaderCommodityListFlowRequest,
    getTagFlowRequest,
    getMaqueeListFlowRequest,
    resetStatus,
  },
  commodityListReduxStatus,
  commodityListError,
  memberName,
  isLogin,
  headerScrolled,
  tags,
  data,
}) => {
  const [isShowCommodityList, setIsShowCommodityList] = useState([false, commodityTypeId.bag]);

  useEffect(() => {
    getHeaderCommodityListFlowRequest({ commodityType: isShowCommodityList[1] });
  }, [isShowCommodityList[1]]);

  useEffect(() => {
    getTagFlowRequest();
  }, []);

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) getMaqueeListFlowRequest();
    else resetStatus();
    return resetStatus;
  }, []);

  return (
    <HeaderComponent
      userList={userList}
      bagList={bagList}
      commodityList={commodityList}
      onBagSearchByKeyword={searchBagListByKeywordFlowRequest}
      onMemberSearchByKeyword={searchMemberListByKeywordFlowRequest}
      commodityListReduxStatus={commodityListReduxStatus}
      commodityListError={commodityListError}
      isShowCommodityList={isShowCommodityList}
      setIsShowCommodityList={setIsShowCommodityList}
      memberName={memberName}
      isLogin={isLogin}
      headerScrolled={headerScrolled}
      tags={tags}
      maqueeText={decodeURIComponent(R.pathOr('', [0, 'text'], data))}
    />
  );
};
HeaderContainer.propTypes = {
  userList: PropTypes.array,
  bagList: PropTypes.array,
  commodityList: PropTypes.array,
  actions: PropTypes.shape({
    searchMemberListByKeywordFlowRequest: PropTypes.func.isRequired,
    searchBagListByKeywordFlowRequest: PropTypes.func.isRequired,
    getHeaderCommodityListFlowRequest: PropTypes.func.isRequired,
    getTagFlowRequest: PropTypes.func.isRequired,
    getMaqueeListFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  commodityListReduxStatus: PropTypes.string,
  commodityListError: PropTypes.string,
  memberName: PropTypes.string,
  isLogin: PropTypes.bool,
  headerScrolled: PropTypes.bool,
  tags: PropTypes.array,
  data: PropTypes.shape({}).isRequired,
};

HeaderContainer.defaultProps = {
  userList: [],
  bagList: [],
  commodityList: [],
  commodityListReduxStatus: '',
  commodityListError: '',
  memberName: '',
  isLogin: false,
  headerScrolled: false,
  tags: [],
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...MemberActionCreators,
    ...TagActionCreators,
    ...BagActionCreators,
  }, dispatch),
});


const mapStateToProps = state => ({
  userList: searchMemberListByKeywordSelector(state),
  bagList: searchBagListByKeywordSelector(state),
  commodityList: headerCommodityListSelector(state),
  commodityListReduxStatus: headerCommodityReduxStatusSelector(state),
  commodityListError: headerCommodityReduxErrorSelector(state),
  isLogin: isLogInSelector(state),
  memberName: memberNameSelector(state),
  memberSocialLogin: memberSocialLoginSelector(state),
  tags: categorizedTagListSelector(state),
  data: maqueeListSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderContainer);
