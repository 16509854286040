import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Button } from 'antd/lib/index';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import Assets from '../../../../constants/Assets';
import CommentStars from '../../../../components/CommentStars';
import Uploader from '../../../ResourceUploader/components/Uploader';

const styles = {
  mainContainer: {
    padding: 20,
  },
  mainMobileContainer: {
    padding: 20,
    width: '90vw',
  },
  textarea: {
    border: '1px solid #bbbbbb',
    width: '100%',
    height: '100px',
  },
  mobileInput: {
    marginRight: 5,
    border: '1px solid #bbbbbb',
  },
  sendComment: {
    backgroundColor: '#AE4200',
    width: '100px',
    color: '#ffffff',
    padding: 5,
    fontSize: 14,
    outline: 'none',
    border: 'none',
    marginTop: '20px',
  },
  textInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  starContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0px',
  },
  starMobileContainer: {
    margin: '20px 0px',
  },
  star: {
    width: 40,
    height: 40,
    cursor: 'pointer',
  },
  noteStar: {
    width: 20,
    height: 20,
  },
  starButton: {
    padding: '0px 10px',
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
  },
  noteText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '5px 0px',
  },
  noteTitle: {
    width: 60,
  },
  starNoteContainer: {
    marginLeft: 20,
  },
  uploaderListContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 10,
    height: 'fit-content',
    width: '100%',
    marginBottom: 10,
  },
  uploaderContainer: {
    width: 102,
    height: 102,
    position: 'relative',
    marginRight: 15,
    border: '1px solid #bbbbbb',
  },
  uploaderContainerMobile: {
    width: 72,
    height: 72,
    position: 'relative',
    marginRight: 5,
    border: '1px solid #bbbbbb',
  },
  upload: {
    width: 100,
    height: 100,
    position: 'absolute',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadMobile: {
    width: 70,
    height: 70,
    position: 'absolute',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  plus: {
    fontSize: 25,
    color: '#bbbbbb',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  uploadImage: {
    zIndex: 2,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  closeIcon: {
    top: -20,
    color: '#bbb',
    backgroundColor: 'white',
    borderColor: 'transparent',
    right: -15,
    position: 'absolute',
    cursor: 'pointer',
    border: 'none',
    outline: ' none',
    fontSize: 25,
    zIndex: 2,
  },
  uploadedImage: {
    width: 100,
    height: 100,
    marginRight: 15,
    border: '1px solid #bbbbbb',
    objectFit: 'contain',
  },
  uploadedImageMobile: {
    width: 70,
    height: 70,
    marginRight: 15,
    border: '1px solid #bbbbbb',
    objectFit: 'contain',
  },
};

const OrderBagCommentComponent = ({
  commentResources,
  comment,
  onStarChanged,
  orderData,
  sendCommentError,
  sendCommentReduxStatus,
  onSendComment,
  onCommentChanged,
  stars,
  onAddCommentResource,
  onDeleteCommentResource,
}) => {
  const hasComment = !R.isEmpty(R.pathOr('', ['commentId'], orderData));

  const renderStarArray = starts => ([...Array(starts).fill(true), ...Array(5 - starts).fill(false)]);

  const starNoteData = [
    { title: '非常棒', stars: 5 },
    { title: '棒', stars: 4 },
    { title: '不錯', stars: 3 },
    { title: '普通', stars: 2 },
    { title: '差', stars: 1 },
  ];

  const renderStarNote = (title, getStarts) => (
    <div key={title} style={styles.noteText}>
      <div style={styles.noteTitle}>{title}</div>
      <CommentStars stars={getStarts} />
    </div>
  );

  const renderSelectStar = (
    renderStarArray(stars).map((star, index) => {
      const key = String(star) + String(index);
      if (star) {
        return (
          <button
            key={key}
            onClick={() => { onStarChanged(index + 1); }}
            type="button"
            style={styles.starButton}
            disabled={hasComment}
          >
            <img
              src={Assets.commentStartActive}
              alt=""
              style={styles.star}
            />
          </button>
        );
      }
      return (
        <button
          key={key}
          onClick={() => { onStarChanged(index + 1); }}
          type="button"
          style={styles.starButton}
          disabled={hasComment}
        >
          <img
            src={Assets.commentStartDisActive}
            alt=""
            style={styles.star}
          />
        </button>
      );
    })
  );

  const renderCommentResource = item => (
    <div>
      <Default>
        <img
          key={item.id}
          style={styles.uploadedImage}
          src={item.uri}
          alt=""
        />
      </Default>
      <Mobile>
        <img
          key={item.id}
          style={styles.uploadedImageMobile}
          src={item.uri}
          alt=""
        />
      </Mobile>
    </div>
  );

  const renderUploader = index => (
    <React.Fragment>
      <Default>
        <Uploader
          disabled={R.pathOr(null, [`${index}`, 'id'], commentResources) !== null}
          onFileUploaded={result => onAddCommentResource(result)}
          childComponent={(
            <div style={styles.uploaderContainer}>
              <div style={styles.upload}>
                {R.pathOr(null, [`${index}`, 'id'], commentResources) !== null
                  ? (
                    <img
                      key={commentResources[index].id}
                      style={styles.uploadImage}
                      src={commentResources[index].uri}
                      alt=""
                    />
                  )
                  : (
                    <div style={styles.plus}>+</div>
                  )}
                {R.pathOr(null, [`${index}`, 'id'], commentResources) !== null
                && (
                  <Button
                    style={styles.closeIcon}
                    type="link"
                    shape="circle"
                    icon="close-circle"
                    onClick={() => onDeleteCommentResource(index)}
                  />
                )}
              </div>
            </div>
          )}
        />
      </Default>
      <Mobile>
        <Uploader
          disabled={R.pathOr(null, [`${index}`, 'id'], commentResources) !== null}
          onFileUploaded={result => onAddCommentResource(result)}
          childComponent={(
            <div style={styles.uploaderContainerMobile}>
              <div style={styles.uploadMobile}>
                {R.pathOr(null, [`${index}`, 'id'], commentResources) !== null
                  ? (
                    <img
                      key={commentResources[index].id}
                      style={styles.uploadImage}
                      src={commentResources[index].uri}
                      alt=""
                    />
                  )
                  : (
                    <div style={styles.plus}>+</div>
                  )}
                {R.pathOr(null, [`${index}`, 'id'], commentResources) !== null
                && (
                  <Button
                    style={styles.closeIcon}
                    type="link"
                    shape="circle"
                    icon="close-circle"
                    onClick={() => onDeleteCommentResource(index)}
                  />
                )}
              </div>
            </div>
          )}
        />
      </Mobile>
    </React.Fragment>
  );

  const content = (
    <React.Fragment>
      <Default>
        <div style={styles.starContainer}>
          <div style={styles.selectStartContainer}>
            {renderSelectStar}
          </div>
          <div style={styles.starNoteContainer}>
            {starNoteData.map(item => (renderStarNote(item.title, item.stars)))}
          </div>
        </div>
      </Default>
      <Mobile>
        <div style={styles.starMobileContainer}>
          <div style={styles.selectStartContainer}>
            {renderSelectStar}
          </div>
          <div style={styles.starNoteContainer}>
            {starNoteData.map(item => (renderStarNote(item.title, item.stars)))}
          </div>
        </div>
      </Mobile>
      {hasComment && <div style={styles.uploaderListContainer}>{commentResources.map(item => renderCommentResource(item))}</div>}
      <div style={styles.textInputContainer}>
        {hasComment
          ? (
            <div>
              {comment}
            </div>
          )
          : (
            <React.Fragment>
              <div style={styles.uploaderListContainer}>
                {commentResources.length >= 0 && renderUploader(0)}
                {commentResources.length >= 1 && renderUploader(1)}
                {commentResources.length >= 2 && renderUploader(2)}
              </div>
              <textarea
                value={comment}
                disabled={hasComment}
                style={styles.textarea}
                type="textarea"
                placeholder="評論即可獲得$50優惠折扣碼(20字以上) &#13;&#10;評論附上圖片即可獲得$100折扣碼&#13;&#10;撰寫評論"
                onChange={onCommentChanged}
              />
            </React.Fragment>
          )}
        {hasComment ? null
          : (
            <button
              style={styles.sendComment}
              type="button"
              onClick={onSendComment}
            >
              確定送出
            </button>
          )
        }
      </div>
    </React.Fragment>
  );
  return (
    <ReduxStatusVisualizedComponent
      showModalOnError
      modalFailureContent={sendCommentError}
      reduxStatus={sendCommentReduxStatus}
    >
      <Default>
        <div style={styles.mainContainer}>
          {content}
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          {content}
        </div>
      </Mobile>
    </ReduxStatusVisualizedComponent>
  );
};

OrderBagCommentComponent.propTypes = {
  orderData: PropTypes.object,
  onSendComment: PropTypes.func.isRequired,
  comment: PropTypes.string,
  onCommentChanged: PropTypes.func.isRequired,
  sendCommentReduxStatus: PropTypes.string,
  sendCommentError: PropTypes.string,
  onStarChanged: PropTypes.func.isRequired,
  onAddCommentResource: PropTypes.func.isRequired,
  onDeleteCommentResource: PropTypes.func.isRequired,
  stars: PropTypes.number,
  commentResources: PropTypes.array,
};

OrderBagCommentComponent.defaultProps = {
  sendCommentReduxStatus: null,
  orderData: null,
  sendCommentError: '',
  comment: '',
  stars: 0,
  commentResources: [],
};

export default OrderBagCommentComponent;
