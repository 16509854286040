import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActionCreators from '../../ActionCreator';
import TransactionPage from './ZeroCardTransactionPage';
import OrderActionCreators from '../../../Order/ActionCreator';
import ReduxStatus from '../../../../constants/ReduxStatus';


import {
  orderDetailErrorSelector,
  orderDetailDataStatusSelector,
  orderDetailReduxStatusSelector,
} from '../../../Order/selector';

import {
  transactionReduxStatusSelector,
  zeroCardTransactionErrorSelector,
  zeroCardTransactionDataSelector,
} from '../../selector';


const transactionContainer = ({
  orderId,
  orderData,
  actions: {
    orderDetailFlowRequest,
    zeroCardTransactionFlowRequest,
    resetTransactionStatus,
  },
  orderDetailError,
  zeroCardTransactionError,
  orderDetailReduxStatus,
  transactionReduxStatus,
  zerCardTransactionData,
}) => {
  useEffect(() => {
    orderDetailFlowRequest({ orderId });
  }, []);

  useEffect(() => {
    const infoUrl = R.pathOr(null, ['info_reserve', 'payment_url_web'], zerCardTransactionData);
    const infoUrlMobile = R.pathOr(null, ['info_reserve', 'payment_url_app'], zerCardTransactionData);
    if (infoUrl || infoUrlMobile) {
      const deviceSize = window.innerWidth;
      if (deviceSize > 900) {
        window.open(infoUrl);
      } else window.open(infoUrlMobile);
    }
  }, [zerCardTransactionData]);

  const handleOnModalConfirm = () => {
    if (transactionReduxStatus === ReduxStatus.STATUS_NORMAL) {
      resetTransactionStatus();
    }
  };

  const handleOnConfirmPay = () => {
    const zeroCardInstallment = R.pathOr(0, ['zeroCardInstallment'], orderData);
    const amount = R.pathOr(0, ['amount'], orderData);
    zeroCardTransactionFlowRequest({
      orderId,
      installment: zeroCardInstallment,
      amount,
    });
  };

  return (

    <TransactionPage
      orderDetailError={orderDetailError}
      transactionError={zeroCardTransactionError}
      orderData={orderData}
      transactionReduxStatus={transactionReduxStatus}
      orderDetailReduxStatus={orderDetailReduxStatus}
      onModalConfirm={handleOnModalConfirm}
      onSendConfirm={handleOnConfirmPay}
    />
  );
};
transactionContainer.propTypes = {
  actions: PropTypes.shape({
    orderDetailFlowRequest: PropTypes.func.isRequired,
    transactionFlowRequest: PropTypes.func.isRequired,
    resetTransactionStatus: PropTypes.func.isRequired,
  }).isRequired,
  orderData: PropTypes.object,
  orderId: PropTypes.string,
  orderDetailReduxStatus: PropTypes.string,
  transactionReduxStatus: PropTypes.string,
  orderDetailError: PropTypes.object,
  transactionError: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...OrderActionCreators,
  }, dispatch),
});

transactionContainer.defaultProps = {
  orderData: null,
  orderDetailReduxStatus: null,
  transactionReduxStatus: null,
  orderDetailError: null,
  transactionError: null,
  orderId: '0',
};

const mapStateToProps = state => ({
  orderData: orderDetailDataStatusSelector(state),
  orderDetailReduxStatus: orderDetailReduxStatusSelector(state),
  transactionReduxStatus: transactionReduxStatusSelector(state),
  zeroCardTransactionError: zeroCardTransactionErrorSelector(state),
  orderDetailError: orderDetailErrorSelector(state),
  zerCardTransactionData: zeroCardTransactionDataSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(transactionContainer);
