import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Link, Location } from '@reach/router';
import { useWindowSize } from '@react-hook/window-size/throttled';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import BagItem from '../../../../components/BagItem';
import BagItemHomePage from '../../../../components/BagItemHomePage';
import BagSearchPanelComponent from '../../../../components/BagSearchPanelComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import PageButton from '../../../../components/PageButton';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import LoginPageContainer from '../../../Member/components/LoginPage';
import { FontSize } from '../../../../constants/FontSize';
import Assets from '../../../../constants/Assets';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Paths from '../../../../routes/Paths';
import * as SearchBagHelper from '../../../../utils/SearchBagHelper';
import { commodityTypeId } from '../../../../constants/Type';

const styles = {
  mainContainer: {
    marginBottom: 30,
    maxWidth: '100%',
    overflowX: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainHomePageContainer: {
    paddingBottom: 30,
    maxWidth: '100%',
    overflowX: 'hidden',
    position: 'relative',
    backgroundColor: 'white',
  },
  image: {
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    height: 'calc(100vh - 120px)',
    objectFit: 'cover',
  },
  bagListContainer: {
    width: '95vw',
    textAlign: 'center',
    margin: '30px auto',
    padding: '0px 10px',
    position: 'relative',
  },
  bagListMobileContainer: {
    width: '100vw',
    textAlign: 'center',
    margin: '0px auto',
    padding: '0px 5px',
    position: 'relative',
  },
  bagListHomeContainer: {
    width: 'calc(100% + 60px)',
    textAlign: 'center',
    margin: '30px auto',
    marginLeft: '-30px',
    padding: '0px 10px',
    position: 'relative',
  },
  invisibleBagItem: {
    height: 1,
    opacity: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  loadMoreButton: {
    width: 'fit-content',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: FontSize.body,
    textDecoration: 'underline',
  },
  sellLendingContainer: {
    textAlign: 'center',
    position: 'relative',
    height: 'auto',
    width: '100vw',
  },
  sellLendingMobileContainer: {
    textAlign: 'center',
    position: 'relative',
    marginTop: '60px',
    height: 'auto',
    width: 'calc(100% + 220px)',
    left: '-110px',
  },
  img: {
    objectFit: 'cover',
    height: 'auto',
    width: '100vw',
    right: '0',
    top: '0',
    marginLeft: 'calc(50% - 50vw)',
  },
  mobileImg: {
    objectFit: 'contain',
    height: 'auto',
    width: '100vw',
    right: '0',
    top: '0',
    marginTop: '20px',
    marginLeft: 'calc(50% - 50vw)',
  },
  slideContainer: {
    width: '88vw',
    height: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'relative',
  },
  slideMobileContainer: {
    width: '70vw',
    height: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'relative',
  },
  loadMoreContainer: {
    position: 'absolute',
    right: '8%',
    top: '90px',
    width: 'fit-content',
  },
  loadMoreMobileContainer: {
    position: 'absolute',
    right: '10%',
    bottom: '90px',
    width: '200px',
    zIndex: 2,
    color: 'black',
  },
  pageButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  bagSearchContainer: {
    width: '100vw',
  },
  bagSearchContainerFixed: {
    width: '100vw',
    position: 'fixed',
    top: '83px',
    height: '0px',
    zIndex: 3,
    backgroundColor: 'white',
  },
  bagSearchContainerMobileFixed: {
    width: '100vw',
    position: 'fixed',
    top: '80px',
    zIndex: 1,
    backgroundColor: 'white',
  },
  bagSearchContainerDummy: {
    width: '100vw',
    height: '150px',
  },
  bagSearchContainerDummyMobile: {
    width: '100vw',
    height: '220px',
  },
};

const BagListPage = ({
  reduxStatus,
  reduxError,
  cartReduxStatus,
  cartReduxError,
  data,
  isHomepage,
  isClothes,
  bagNumber,
  location,
  isBuyArea,
  commodityType,
  allNew,
}) => {
  const urlPageCondition = SearchBagHelper.getPath(location);
  const { page: urlPage } = urlPageCondition;
  const [currentPage, setCurrentPage] = useState(0);
  const [width] = useWindowSize({ fps: 60 });
  const [showPage, setShowPage] = useState(urlPage - 1 || 0);
  const [pageData, setShowPageData] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [isNextTenPage, setIsNextTenPage] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);
  const [scrollHight, setScrollHight] = useState(0);
  const [isFixSearchBar, setIsFixSearchBar] = useState(false);
  const [isFixMobileSearchBar, setIsFixMobileSearchBar] = useState(false);
  const bagOrClothesPath = isClothes ? Paths.CLOTHES_LIST : Paths.BAG_LIST;
  const path = `${bagOrClothesPath}?search={"bagType":[1,3]}&page=1`;

  useEffect(() => {
    setShowPage(urlPageCondition.page - 1);
  }, [urlPageCondition]);

  useEffect(() => {
    if (isHomepage && currentPage < 2) {
      const timer = setTimeout(() => {
        setCurrentPage(currentPage + 1);
      }, 500);
      return () => clearTimeout(timer);
    }
    // If bags number > 20 don't do anything
    return undefined;
  }, []);

  useEffect(() => {
    const onScroll = (event) => {
      setScrollHight(event.target.documentElement.scrollTop);
    };

    window.addEventListener('scroll', onScroll);

    if (scrollHight > 180) {
      setIsFixSearchBar(true);
    } else {
      setIsFixSearchBar(false);
    }

    if (scrollHight > 240) {
      setIsFixMobileSearchBar(true);
    } else {
      setIsFixMobileSearchBar(false);
    }

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollHight]);

  const calculateBagNumber = () => {
    if (width < 400) {
      return 2;
    }
    if (width < 450) {
      return 2;
    }
    if (width < 800) {
      return 2;
    }
    if (width < 1000) {
      return 3;
    }
    if (width > 1001 && width < 1300) {
      return 4;
    }
    if (width > 1301 && width < 1500) {
      return 5;
    }
    if (width > 1501 && width < 1700) {
      return 5;
    }

    return 8;
  };

  const renderBanner = () => {
    if (isBuyArea && allNew) return Assets.allNewBuyPageBanner01;

    if (isBuyArea) return Assets.buyPageBanner01;

    if (commodityType === commodityTypeId.clothes) return Assets.clotheBanner;

    if (commodityType === commodityTypeId.accessories) return Assets.accessoriesPageBanner01;

    if (commodityType === commodityTypeId.essences) return Assets.fragranceBanner;

    return Assets.bagPageBanner01;
  };

  const renderMobileBanner = () => {
    if (isBuyArea && allNew) return Assets.allNewBuyPageBannerMobile;

    if (isBuyArea) return Assets.buyPageBannerMobile;

    if (commodityType === commodityTypeId.clothes) return Assets.clothesListBannerMobile;

    if (commodityType === commodityTypeId.accessories) return Assets.accessoriesListBannerMobile;

    if (commodityType === commodityTypeId.essences) return Assets.fragranceMobileBanner;

    return Assets.bagListBannerMobile;
  };

  const renderHomeLoadMoreButton = () => (
    <React.Fragment>
      <Default>
        <div style={styles.loadMoreContainer}>
          <Link to={path}>
            <button
              style={styles.loadMoreButton}
              type="button"
            >
              {isClothes ? '瀏覽更多服裝' : '瀏覽更多配件'}
            </button>
          </Link>
        </div>
      </Default>
      <Mobile>
        <div style={styles.loadMoreMobileContainer}>
          <Link to={path}>
            <button
              style={styles.loadMoreButton}
              type="button"
            >
              {isClothes ? '瀏覽更多服裝' : '瀏覽更多配件'}
            </button>
          </Link>
        </div>
      </Mobile>
    </React.Fragment>
  );

  const renderMobileData = (inputData) => {
    const newData = [];

    inputData.forEach((item, index) => {
      if (index % 2 === 0) {
        newData.push([item]);
      }
      if (index % 2 === 1) {
        newData[newData.length - 1].push(item);
      }
    });

    return newData;
  };

  const renderDummy = (
    <React.Fragment>
      {/*
           NOTE: We add four invisible items to the end of the list in order
           to keep the items in the last row being arranged from left.
           The maximum number of items of each row is 5 (since we've limited the
           maximum with of container to be 1500), thus we only need 4 dummy
           items in the last row.
         */}
      <BagItem style={styles.invisibleBagItem} key="invisibleBagItem1" />
      <BagItem style={styles.invisibleBagItem} key="invisibleBagItem2" />
      <BagItem style={styles.invisibleBagItem} key="invisibleBagItem3" />
      <BagItem style={styles.invisibleBagItem} key="invisibleBagItem4" />
    </React.Fragment>
  );

  return [
    isHomepage ? null : (
      <div key="bagPageBanner">
        <Default>
          <img
            alt=""
            src={renderBanner()}
            style={styles.img}
          />
        </Default>
        <Mobile>
          <img
            alt=""
            src={renderMobileBanner()}
            style={styles.mobileImg}
          />
        </Mobile>
      </div>
    ),
    <div style={isHomepage ? styles.mainHomePageContainer : styles.mainContainer} key="baseContainer">

      <InfoModalComponent
        isVisible={isShowLoginModal}
        onModalClose={() => setIsShowLoginModal(false)}
        content={(
          <LoginPageContainer
            setIsShowLoginModal={setIsShowLoginModal}
          />
          )}
      />

      <Default>
        <div style={isFixSearchBar && !isHomepage ? styles.bagSearchContainerFixed : styles.bagSearchContainer}>
          <BagSearchPanelComponent
            page={currentPage}
            isHomepage={isHomepage}
            isClothes={isClothes}
            onSearch={
          () => {
            setIsSearch(true);
            setIsInitial(true);
            setCurrentPage(0);
          }
        }
            isNextTenPage={isNextTenPage}
            isInitial={isInitial}
            isSearch={isSearch}
            isBuyArea={isBuyArea}
            commodityType={commodityType}
          />
        </div>
        <div style={isFixSearchBar && !isHomepage ? styles.bagSearchContainerDummy : null} />
      </Default>
      <Mobile>
        <div style={isFixMobileSearchBar && !isHomepage ? styles.bagSearchContainerMobileFixed : styles.bagSearchContainer}>
          <BagSearchPanelComponent
            page={currentPage}
            isHomepage={isHomepage}
            isClothes={isClothes}
            onSearch={
          () => {
            setIsSearch(true);
            setIsInitial(true);
            setCurrentPage(0);
          }
        }
            isNextTenPage={isNextTenPage}
            isInitial={isInitial}
            isSearch={isSearch}
            isBuyArea={isBuyArea}
            commodityType={commodityType}
          />
        </div>
        <div style={isFixMobileSearchBar && !isHomepage ? styles.bagSearchContainerDummyMobile : null} />
      </Mobile>
      {isHomepage ? renderHomeLoadMoreButton() : null}

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus || cartReduxStatus}
        showModalOnError
        modalFailureContent={reduxError || cartReduxError}
      >
        {
          isHomepage ? (
            <div style={styles.bagListHomeContainer}>
              <Default>
                <div style={styles.slideContainer}>
                  <Slider
                    arrows
                    speed={300}
                    autoplay
                    slidesToShow={calculateBagNumber()}
                    slidesToScroll={1}
                  >
                    {(data || []).map(item => (
                      <BagItemHomePage
                        isClothes={isClothes}
                        {...item}
                      />
                    ))}
                  </Slider>
                </div>
              </Default>
              <Mobile>
                <div style={styles.slideMobileContainer}>
                  <Slider
                    arrows
                    autoplay
                    speed={300}
                    slidesToShow={calculateBagNumber()}
                    slidesToScroll={1}
                  >
                    {(renderMobileData(data) || []).map(
                      item => (
                        <React.Fragment key={item[0].id}>
                          <BagItemHomePage
                            isClothes={isClothes}
                            {...item[0]}
                          />
                          <BagItemHomePage
                            isClothes={isClothes}
                            {...item[1]}
                          />
                        </React.Fragment>
                      ),
                    )}
                  </Slider>
                </div>
              </Mobile>
              {renderDummy}
            </div>
          ) : (
            <React.Fragment>
              <Default>
                <div style={styles.bagListContainer}>
                  { (pageData || []).map(
                    item => (
                      <React.Fragment key={item.id}>
                        <BagItem
                          isBuyArea={isBuyArea}
                          setIsShowLoginModal={setIsShowLoginModal}
                          {...item}
                        />
                      </React.Fragment>
                    ),
                  )}
                </div>
              </Default>
              <Mobile>
                <div style={styles.bagListMobileContainer}>
                  { (pageData || []).map(
                    item => (
                      <React.Fragment key={item.id}>
                        <BagItem
                          isBuyArea={isBuyArea}
                          setIsShowLoginModal={setIsShowLoginModal}
                          {...item}
                        />
                      </React.Fragment>
                    ),
                  )}
                </div>
              </Mobile>
            </React.Fragment>
          )
        }
        {
          isHomepage ? null : (
            <PageButton
              currentPage={currentPage}
              data={data}
              showPage={showPage}
              setShowPageData={setShowPageData}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              setShowPage={setShowPage}
              setCurrentPage={setCurrentPage}
              setIsNextTenPage={setIsNextTenPage}
              setIsInitial={setIsInitial}
              bagNumber={bagNumber}
              isBuyArea={isBuyArea}
              commodityType={commodityType}
            />
          )
        }
      </ReduxStatusVisualizedComponent>
    </div>,
  ];
};

BagListPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array.isRequired,
  allNew: PropTypes.bool,
};

BagListPage.defaultProps = {
  reduxError: null,
  allNew: false,
};

export default (props => (
  <Location>
    {({ location }) => (<BagListPage location={location} {...props} />)}
  </Location>
));
