import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';
import BagStatus from '../../constants/BagStatus';
import BagSortOrder from '../../constants/BagSortOrder';
import { commodityTypeId } from '../../constants/Type';

const {
  getHeaderCommodityListFlowRequest,
  getHeaderCommodityListFlowSuccess,
  getHeaderCommodityListFlowFailure,
  getHeaderCommodityListRequest,
  getHeaderCommodityListSuccess,
  getHeaderCommodityListFailure,

  getMaqueeListRequest,
  getMaqueeListSuccess,
  getMaqueeListFailure,
  getMaqueeListFlowRequest,
  getMaqueeListFlowSuccess,
  getMaqueeListFlowFailure,
} = ActionCreator;

export const buildFilterClause = (filterCondition = {}) => {
  try {
    const {
      tags,
      page = 0,
      bagStatus,
      sortOrder = 0,
      commodityType,
    } = filterCondition;

    const pageLimit = 4;
    const buyAreaId = 99;
    const buyAreaIdAllNew = 98;
    const where = filterCondition.where || {};

    if (tags) {
      Object.assign(where, {
        tags,
      });
    }

    if (bagStatus) {
      Object.assign(where, {
        bagStatusId: bagStatus,
      });
    } else {
      Object.assign(where, {
        bagStatusId: {
          inq: [
            BagStatus.bagStatusId.inStock,
            BagStatus.bagStatusId.rented,
          ],
        },
      });
    }

    Object.assign(where, {
      typeId: {
        inq: [1, 3],
      },
    });

    if (commodityType && commodityType !== commodityTypeId.bag && commodityType !== buyAreaId && commodityType !== buyAreaIdAllNew) {
      Object.assign(where, {
        commodityTypeId: commodityType,
      });
    } else if (commodityType === 0) {
      Object.assign(where, {
        and: [
          {
            or: [
              { commodityTypeId: null },
              { commodityTypeId: 1 },
              { commodityTypeId: 2 },
              { commodityTypeId: 3 },
            ],
          },
        ],
      });
    } else if (commodityType === buyAreaId || commodityType === buyAreaIdAllNew) {
      const commodityTypeArray = [
        { commodityTypeId: null },
        { commodityTypeId: 1 },
        { commodityTypeId: 2 },
        { commodityTypeId: 3 },
      ];

      let newLevel = {
        newLevel: { nin: [10] },
      };

      if (commodityType === buyAreaIdAllNew) {
        newLevel = { newLevel: 10 };
      }
      Object.assign(where, {
        and: [
          {
            bagStatusId: {
              inq: [
                BagStatus.bagStatusId.inStock,
                BagStatus.bagStatusId.rented,
              ],
            },
          },
          newLevel,
          {
            or: commodityTypeArray,
          },
        ],
      });
      Object.assign(where, {
        typeId: {
          inq: [2, 3],
        },
      });
    } else {
      Object.assign(where, {
        and: [
          {
            or: [
              { commodityTypeId: null },
              { commodityTypeId: 1 },
            ],
          },
        ],
      });
    }

    const order = R.pathOr(
      R.pathOr('', [0, 'order'], BagSortOrder),
      [sortOrder, 'order'],
      BagSortOrder,
    );

    const assignedFilterCondition = {
      limit: pageLimit,
      skip: pageLimit * page,
      order,
      include: [
        {
          relation: 'bagStatus',
          scope: { fields: 'name' },
        },
        {
          relation: 'frontPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'reduceDepositUsers',
          scope: { fields: 'id' },
        },
        {
          relation: 'owner',
          scope: {
            fields: ['name', 'avatarId', 'communityName'],
            include: [
              {
                relation: 'avatar',
                scope: { fields: 'uri' },
              },
              {
                relation: 'follow',
              },
            ],
          },
        },
        'type',
        'tags',
        'commodityType',
      ],
      ...(R.isEmpty(where) ? {} : { where }),
    };

    return assignedFilterCondition;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {};
  }
};

// ---------- TASKS ----------
export function* getHeaderCommodityList(filterCondition = {}) {
  const assignedFilterCondition = buildFilterClause(filterCondition);
  yield put(getHeaderCommodityListRequest(assignedFilterCondition));
  try {
    const result = (yield call(API.getBagList, assignedFilterCondition));
    yield put(getHeaderCommodityListSuccess({
      data: result.data,
      filterCondition,
    }));
  } catch (error) {
    yield put(getHeaderCommodityListFailure(error));
  }
}

function* getMaqueeList() {
  yield put(getMaqueeListRequest());
  try {
    const result = yield call(API.getMaqueeList);
    yield put(getMaqueeListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getMaqueeListFailure(error));
    throw (error);
  }
}

// Flow
export function* getHeaderCommodityListFlow({ payload }) {
  try {
    const result = yield call(getHeaderCommodityList, payload);
    yield put(getHeaderCommodityListFlowSuccess(result));
  } catch (error) {
    yield put(getHeaderCommodityListFlowFailure(error));
  }
}

export function* getMaqueeListFlow({ payload }) {
  try {
    const maquee = yield call(getMaqueeList, payload);
    yield put(getMaqueeListFlowSuccess(
      R.pathOr([], ['data'], maquee),
    ));
  } catch (error) {
    yield put(getMaqueeListFlowFailure(error));
  }
}

export default [
  takeLatest(getHeaderCommodityListFlowRequest, getHeaderCommodityListFlow),
  takeLatest(getMaqueeListFlowRequest, getMaqueeListFlow),
];
