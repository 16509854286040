import {
  call,
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  retrieveBagFlowRequest,
  retrieveBagFlowSuccess,
  retrieveBagFlowFailure,
  retrieveBagRequest,
  retrieveBagSuccess,
  retrieveBagFailure,

  extendContractBagFlowRequest,
  extendContractBagFlowSuccess,
  extendContractBagFlowFailure,
  extendContractBagRequest,
  extendContractBagSuccess,
  extendContractBagFailure,

  bagEditFlowRequest,
  bagEditFlowSuccess,
  bagEditFlowFailure,
  bagEditRequest,
  bagEditSuccess,
  bagEditFailure,

  updateBagInfoRequest,
  updateBagInfoSuccess,
  updateBagInfoFailure,
  updateBagInfoFlowRequest,
  updateBagInfoFlowSuccess,
  updateBagInfoFlowFailure,

} = ActionCreator;

export function* retrieveBag(payload) {
  yield put(retrieveBagRequest());
  try {
    const result = yield call(API.retrieveBag, payload);
    yield put(retrieveBagSuccess(result.data));
    return result;
  } catch (error) {
    yield put(retrieveBagFailure(error));
    throw error;
  }
}

export function* extendContractBag(payload) {
  yield put(extendContractBagRequest());
  try {
    const result = yield call(API.extendContractBag, payload);
    yield put(extendContractBagSuccess(result.data));
    return result;
  } catch (error) {
    yield put(extendContractBagFailure(error));
    throw error;
  }
}

export function* bagEdit(payload) {
  yield put(bagEditRequest());
  try {
    const result = yield call(API.bagEdit, payload);
    yield put(bagEditSuccess(result.data));
    return result;
  } catch (error) {
    yield put(bagEditFailure(error));
    throw error;
  }
}

function* updateBagInfo(data) {
  yield put(updateBagInfoRequest());
  try {
    const {
      tags,
      id: bagId,
      frontPic,
      backPic,
      sidePic,
      bottomPic,
      zipperPic,
      owner,
      negotiator,
      ...restOfData
    } = (data || {});

    // NOTE: Updating tags (link or unlink tags)
    // eslint-disable-next-line no-console
    if (tags) {
      yield all(Object.keys(tags).map((tagId) => {
        const {
          action,
        } = tags[tagId];
        if (action === 'add') return call(API.linkBagTag, { tagId, bagId });
        if (action === 'remove') return call(API.unlinkBagTag, { tagId, bagId });
        return null;
      }).filter(d => d));
    }

    // NOTE: Updating pics
    if (frontPic) Object.assign(restOfData, { frontPicId: frontPic.id });
    if (backPic) Object.assign(restOfData, { backPicId: backPic.id });
    if (sidePic) Object.assign(restOfData, { sidePicId: sidePic.id });
    if (bottomPic) Object.assign(restOfData, { bottomPicId: bottomPic.id });
    if (zipperPic) Object.assign(restOfData, { zipperPicId: zipperPic.id });

    // NOTE: Updating owner
    if (owner) Object.assign(restOfData, { ownerId: owner.id });

    if (negotiator) Object.assign(restOfData, { negotiatorId: negotiator.id });

    // NOTE: Updating bag info
    if (!R.isEmpty(restOfData)) {
      const result = yield call(API.updateBagInfo, {
        ...restOfData,
        id: bagId,
      });
      if (result.status === 200) {
        yield put(updateBagInfoSuccess());
        return;
      }
    }
  } catch (error) {
    yield put(updateBagInfoFailure(error));
    throw (error);
  }
}

export function* retrieveBagFlow({ payload }) {
  try {
    const result = yield call(retrieveBag, payload);
    if (result.status === 200) {
      yield put(retrieveBagFlowSuccess());
      return;
    }

    yield put(retrieveBagFlowFailure());
  } catch (error) {
    yield put(retrieveBagFlowFailure());
  }
}

export function* extendContractBagFlow({ payload }) {
  try {
    const result = yield call(extendContractBag, payload);
    if (result.status === 200) {
      yield put(extendContractBagFlowSuccess());
      return;
    }

    yield put(extendContractBagFlowFailure());
  } catch (error) {
    yield put(extendContractBagFlowFailure());
  }
}

export function* bagEditFlow({ payload }) {
  try {
    const result = yield call(bagEdit, payload);
    if (result.status === 200) {
      yield put(bagEditFlowSuccess());
      return;
    }

    yield put(bagEditFlowFailure());
  } catch (error) {
    yield put(bagEditFlowFailure());
  }
}

export function* updateBagInfoFlow({ payload }) {
  try {
    const result = yield call(updateBagInfo, payload);
    yield put(updateBagInfoFlowSuccess(result.data));
  } catch (error) {
    yield put(updateBagInfoFlowFailure(error));
  }
}


export default [
  takeLatest(retrieveBagFlowRequest, retrieveBagFlow),
  takeLatest(extendContractBagFlowRequest, extendContractBagFlow),
  takeLatest(bagEditFlowRequest, bagEditFlow),
  takeLatest(updateBagInfoFlowRequest, updateBagInfoFlow),
];
