import React from 'react';
import HowItWorksPage from './HowItWorksPage';
import Assets from '../../../../constants/Assets';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';


const primasData = [
  { imgUrl: Assets.howItWorksStep1 },
  { imgUrl: Assets.howItWorksStep2 },
  { imgUrl: Assets.howItWorksStep3 },
];

const bannerData = [
  { imgUrl: Assets.howItWorksBanner1 },
  { imgUrl: Assets.howItWorksBanner2 },
  // { imgUrl: Assets.howItWorksBanner3 },
];

const bannerMobileData = [
  { imgUrl: Assets.howItWorksBannerMobile1 },
  { imgUrl: Assets.howItWorksBannerMobile2 },
  // { imgUrl: Assets.howItWorksBannerMobile3 },
];

const HowItWorksContainer = () => (
  <React.Fragment>
    <Default>
      <HowItWorksPage
        primasData={primasData}
        bannerData={bannerData}
        bannerSlideDirection="vertical"
      />
    </Default>
    <Mobile>
      <HowItWorksPage
        primasData={primasData}
        bannerData={bannerMobileData}
      />
    </Mobile>
  </React.Fragment>

);


export default HowItWorksContainer;
