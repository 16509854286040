import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CouponItemComponent from './CouponItemComponent';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';


const styles = {
  mainContainer: {
    width: 750,
    height: 700,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '20px 80px',
    position: 'relative',
    zIndex: 9999,
  },
  mainMobileContainer: {
    width: 330,
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '20px 10px',
    position: 'relative',
    zIndex: 9999,
  },
  title: {
    height: 70,
    paddingTop: 20,
    width: '100%',
    fontSize: 28,
    borderBottom: '1px solid #000',
    marginBottom: 10,
    color: 'black',
  },
  mobileTitle: {
    height: 50,
    paddingTop: 10,
    width: '100%',
    fontSize: 17,
    borderBottom: '1px solid #000',
    marginBottom: 10,
    color: 'black',
  },
  couponListContainer: {
    height: 500,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflowY: 'scroll',
  },
  confirmButton: {
    marginTop: 25,
    color: '#AE4200',
    fontSize: 16,
    border: '1px solid #AE4200',
    textAlign: 'center',
    height: 50,
    width: 150,
    outline: 'none',
    backgroundColor: ' white',
    cursor: 'pointer',
  },
  confirmMobileButton: {
    width: 100,
  },
  mobileFontSize: {
    fontSize: 14,
  },
};

const MemberCouponComponent = ({
  isVisible,
  isReadMode,
  onModalClose,
  memberCouponList,
  orderType,
  onSelectedMemberCoupon,
}) => {
  const [memberCoupons, setMemberCoupons] = useState([]);

  useEffect(() => {
    if (memberCouponList) {
      setMemberCoupons(memberCouponList);
    }
  }, [memberCouponList, isVisible]);

  const renderContent = (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          <div style={styles.title}>我的COUPON券</div>
          <div style={styles.couponListContainer}>
            {memberCoupons.map(memberCoupon => (
              <CouponItemComponent
                isReadMode={isReadMode}
                key={memberCoupon.id}
                orderType={orderType}
                memberCoupon={memberCoupon}
                onSelectedMemberCoupon={onSelectedMemberCoupon}
              />
            ))}
          </div>
          <button
            type="button"
            style={styles.confirmButton}
            onClick={() => onModalClose()}
          >
            確認
          </button>
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          <div style={styles.mobileTitle}>我的COUPON券</div>
          <div style={styles.couponListContainer}>
            {memberCoupons.map(memberCoupon => (
              <CouponItemComponent
                key={memberCoupon.id}
                isReadMode={isReadMode}
                orderType={orderType}
                memberCoupon={memberCoupon}
                onSelectedMemberCoupon={onSelectedMemberCoupon}
              />
            ))}
          </div>
          <button
            type="button"
            style={{ ...styles.confirmButton, ...styles.confirmMobileButton }}
            onClick={() => onModalClose()}
          >
            確認
          </button>
        </div>
      </Mobile>
    </React.Fragment>
  );

  return (
    <InfoModalComponent
      isVisible={isVisible}
      onModalClose={onModalClose}
      content={renderContent}
      containerStyle={{ paddingTop: 30 }}
    />
  );
};

MemberCouponComponent.propTypes = {
  isVisible: PropTypes.bool,
  orderType: PropTypes.string,
  memberCouponList: PropTypes.array,
  onModalClose: PropTypes.func.isRequired,
  onSelectedMemberCoupon: PropTypes.func,
  isReadMode: PropTypes.bool,
};

MemberCouponComponent.defaultProps = {
  isVisible: false,
  isReadMode: false,
  memberCouponList: [],
  orderType: 'rent',
  onSelectedMemberCoupon: () => {},
};

export default MemberCouponComponent;
