import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActionCreators from '../../ActionCreator';
import CartListPage from './CartListPage';

import {
  cartListSelector,
  cartReduxStatusSelector,
  cartReduxErrorSelector,
} from '../../selector';

const CartListContainer = ({
  actions: {
    getCartListFlowRequest,
    deleteCartFlowRequest,
  },
  cartList,
  cartListReduxStatus,
  cartListError,
}) => {
  useEffect(() => {
    getCartListFlowRequest();
  }, []);

  return (
    <CartListPage
      cartList={cartList}
      cartListError={cartListError}
      cartListReduxStatus={cartListReduxStatus}
      onDeleteCart={deleteCartFlowRequest}
    />
  );
};
CartListContainer.propTypes = {
  actions: PropTypes.shape({
    getCartListFlowRequest: PropTypes.func.isRequired,
    deleteCartFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  cartList: PropTypes.array,
  cartListReduxStatus: PropTypes.string,
  cartListError: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),

});

CartListContainer.defaultProps = {
  cartList: [],
  cartListReduxStatus: null,
  cartListError: '',
};

const mapStateToProps = state => ({
  cartList: cartListSelector(state),
  cartListReduxStatus: cartReduxStatusSelector(state),
  cartListError: cartReduxErrorSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartListContainer);
