import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const styles = {
  follow: {
    width: 130,
    height: 33,
    backgroundColor: 'white',
    color: '#BABABA',
    textAlign: 'center',
    padding: '5px 0px',
    borderRadius: 20,
    outline: 'none',
    border: '1px solid #AE4200',
    cursor: 'pointer',
  },
  following: {
    width: 130,
    height: 33,
    marginLeft: 15,
    backgroundColor: '#AE4200',
    color: 'white',
    textAlign: 'center',
    padding: '5px 0px',
    borderRadius: 20,
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
  },
};

const FollowComponent = ({
  isLogin,
  isMe,
  followingList,
  onToggleFollow,
  targetId,
  style,
}) => {
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    setIsFollowing(
      R.filter(following => Number(following.followId) === Number(targetId), followingList).length > 0,
    );
  }, [followingList]);

  return (isMe || !isLogin
    ? null : (
      <button
        type="button"
        style={isFollowing
          ? { ...styles.following, ...style }
          : { ...styles.follow, ...style }}
        onClick={() => onToggleFollow()}
      >
        {isFollowing ? '追蹤中' : '追蹤'}
      </button>
    )
  );
};

FollowComponent.propTypes = {
  onToggleFollow: PropTypes.func.isRequired,
  targetId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  followingList: PropTypes.array,
  isMe: PropTypes.bool,
  isLogin: PropTypes.bool,
  style: PropTypes.object,
};

FollowComponent.defaultProps = {
  followingList: [],
  isMe: false,
  isLogin: false,
  style: {},
};

export default FollowComponent;
