import React, { useState, useEffect } from 'react';
import { Switch } from 'antd';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import BagSortOrder from '../../constants/BagSortOrder';
import SizeType from '../../constants/SizeType';
import Default from '../RWDComponent/Default';
import Mobile from '../RWDComponent/Mobile';
import { FontSize } from '../../constants/FontSize';
import { tagTypeId, commodityTypeName, commodityTypeId } from '../../constants/Type';


const styles = {
  mainContainer: {
    position: 'relative',
    marginTop: 30,
    marginBottom: 25,
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    color: 'black',
    padding: '0 8vw',
    alignItems: 'flex-end',
    backgroundColor: 'white',
  },
  title: {
    color: '#070707',
    fontSize: FontSize.title,
    display: 'inline-block',
    margin: 0,
  },
  subTitle: {
    marginLeft: 30,
    color: '#070707',
    fontSize: FontSize.body,
    display: 'inline-block',
  },
  optionGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  optionTitle: {
    fontSize: FontSize.body,
    marginRight: 10,
  },
  optionValue: {
    width: '100%',
    backgroundColor: 'transparent',
    border: '1px solid #808080',
    color: 'black',
    outline: 'none',
    borderRadius: 0,
    padding: 10,
    WebkitAppearance: 'none',
    cursor: 'pointer',
    fontSize: FontSize.body,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  optionTitleContainer: {
    width: '15%',
    position: 'relative',
    height: '65px',
  },
  optionContainer: {
    position: 'relative',
    height: '100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  newInContainer: {
    minWidth: '90px',
    width: '100%',
    position: 'relative',
  },
  newInHomeContainer: {
    minWidth: '90px',
    width: '100%',
    position: 'relative',
    textAlign: 'left',
  },
  newInContainerMobile: {
    width: '30%',
    minWidth: '200px',
    margin: '0 auto',
  },
  optionUpContainerMobile: {
    position: 'relative',
    height: '100px',
    width: '90%',
    margin: '0 auto',
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionTitleContainerMobile: {
    textAlign: 'left',
    width: '19%',
    position: 'relative',
    height: '65px',
  },
  mainContainerMobile: {
    marginTop: 10,
  },
  titleMobile: {
    color: '#070707',
    fontSize: '30px',
    textAlign: 'center',
    marginTop: '50px',
  },
  subTitleMobile: {
    color: '#070707',
    fontSize: FontSize.body,
    textAlign: 'center',
  },
  hrLine: {
    width: '100%',
  },
  opOption: {
    position: 'absolute',
    left: 0,
    bottom: '0px',
    width: '110px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  opOptionMobile: {
    position: 'absolute',
    right: 0,
    bottom: '30px',
    width: '110px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

const BagSearchPanel = ({
  handleSearchConditionChange,
  tags,
  condition,
  isClothes,
  isBuyArea,
  isHomepage,
  commodityType,
  onCommodityTypeChange,
  selectedCommodityType,
}) => {
  const selectedSortOrder = R.pathOr(0, ['sortOrder'], condition);
  const [isDefaultType, setIsDefaultType] = useState(true);
  const [searchTag, setSearchTag] = useState({});
  const [searchTagArray, setSearchTagArray] = useState([0]);
  const [isInitial, setIsInitial] = useState(true);

  const [isDefaultOPManager, setIsDefaultOPManager] = useState(false);

  useEffect(() => {
    const defaultType = R.pathOr(0, ['tags', 0, 0], condition);
    const urlTag = R.pathOr(0, ['tags'], condition);
    const check = (urlTag.toString()) === (searchTagArray.toString());
    if ((isDefaultType || !check) && defaultType) {
      setSearchTag(
        {
          ...{
            type: defaultType,
          },
        },
      );
      setIsInitial(true);
      setIsDefaultType(false);
    } else if (!urlTag) {
      setSearchTag({});
      setIsInitial(true);
    }
    setIsDefaultOPManager(R.pathOr(false, ['isOhPhireManage'], condition));
  }, [condition]);


  const handleAnythingChange = (newCondition) => {
    const finalCondition = {
      ...condition,
      tags: searchTagArray,
      sortOrder: selectedSortOrder,
      ...newCondition,
    };

    if (finalCondition.tags.length === 0 || finalCondition.tags[0] === 0) delete finalCondition.tags;
    if (finalCondition.sortOrder === 0) delete finalCondition.sortOrder;
    if (finalCondition.size === '0') delete finalCondition.size;
    if (finalCondition.commodityType === 0) delete finalCondition.commodityType;
    setIsInitial(false);
    handleSearchConditionChange(finalCondition);
  };

  useEffect(() => {
    if (Object.entries(searchTag).length !== 0) {
      setIsInitial(false);
      const searchTagCondition = Object.values(searchTag).map(item => [item]);
      const finalSearchTag = searchTagCondition.filter(item => item[0] !== 0);

      handleAnythingChange({
        tags: finalSearchTag,
      });

      setSearchTagArray(finalSearchTag);
    }
  }, [searchTag]);


  const handleSortOrderChange = (event) => {
    const newSelectedSortOrder = parseInt(event.target.value, 10);
    handleAnythingChange({
      sortOrder: newSelectedSortOrder,
    });
  };

  const handleSizeChange = (event) => {
    const newSelectedSize = event.target.value;
    handleAnythingChange({
      size: newSelectedSize,
    });
  };

  const handleOPManagerChange = (event) => {
    handleAnythingChange({
      isOhPhireManage: event,
    });
  };

  // const handleCommodityTypeChange = (event) => {
  //   const newSelectedCommodityType = Number(event.target.value);
  //   setSelectCommodityType(newSelectedCommodityType);
  //   handleAnythingChange({
  //     commodityType: newSelectedCommodityType,
  //   });
  // };

  const handleTagChange = (event, key) => {
    const newSelectedTag = parseInt(event.target.value, 10);
    switch (key) {
      case 'brand':
        setSearchTag(
          {
            ...searchTag,
            ...{
              brand: newSelectedTag,
            },
          },
        );
        break;
      case 'color':
        setSearchTag(
          {
            ...searchTag,
            ...{
              color: newSelectedTag,
            },
          },
        );
        break;
      case 'type':
        setSearchTag(
          {
            ...searchTag,
            ...{
              type: newSelectedTag,
            },
          },
        );
        break;
      case 'style':
        setSearchTag(
          {
            ...searchTag,
            ...{
              type: newSelectedTag,
            },
          },
        );
        break;
      case 'price':
        setSearchTag(
          {
            ...searchTag,
            ...{
              price: newSelectedTag,
            },
          },
        );
        break;

      default:
        break;
    }
  };

  const renderHomePageTitle = () => (isClothes ? '最新租借單品' : '最新購買商品');

  const renderSelection = (key, text, selectTagsId) => (
    <select
      style={styles.optionValue}
      onChange={event => handleTagChange(event, key)}
      value={isInitial ? 0 : R.pathOr(0, [key], searchTag)}
    >
      <option key="all" value="0">{text}</option>
      {
          tags.map(({
            id: tagCategoryId,
            tags: tagItems,
          }) => {
            if (tagCategoryId !== selectTagsId) return null;
            return tagItems.map(({
              name: tagName,
              id: tagId,
            }) => <option key={tagId} value={tagId}>{tagName}</option>);
          })
        }
    </select>
  );

  const renderOPManageSwitch = isMobile => (
    <div style={isMobile ? styles.opOptionMobile : styles.opOption}>
      <Switch
        onChange={(event) => { handleOPManagerChange(event); }}
        size="small"
        checked={isDefaultOPManager}
      />
      OP代管項目
    </div>
  );

  const renderOptionGroup = () => {
    const renderSellType = () => {
      if (selectedCommodityType === commodityTypeId.clothes) return renderSelection('style', '種類', tagTypeId.clotheStyle);

      if (selectedCommodityType === commodityTypeId.accessories) return renderSelection('style', '種類', tagTypeId.accessoriesType);

      return renderSelection('type', '種類', tagTypeId.bagStyle);
    };

    if (commodityType === commodityTypeId.clothes) {
      return (
        <React.Fragment>
          <Default>
            <div style={styles.optionTitleContainer}>
              <span style={styles.optionTitle}>篩選</span>
              {renderSelection('style', '種類', tagTypeId.clotheStyle)}
            </div>
            <div style={styles.optionTitleContainer}>
              {renderSelection('brand', '品牌', tagTypeId.clothesBrand)}
            </div>
            <div style={styles.optionTitleContainer}>
              {renderSelection('price', '價格', tagTypeId.price)}
            </div>
            <div style={styles.optionTitleContainer}>
              <select
                style={styles.optionValue}
                onChange={handleSizeChange}
              >
                {
                  SizeType.map(({
                    name,
                    value,
                  }) => (
                    <option key={`${name}`} value={value}>{name}</option>
                  ))
              }
              </select>
            </div>
            <div style={styles.optionTitleContainer}>
              {renderSelection('color', '顏色', tagTypeId.color)}
            </div>
          </Default>
          <Mobile>
            <div style={styles.optionTitleContainerMobile}>
              <span style={styles.optionTitle}>篩選</span>
              {renderSelection('style', '種類', tagTypeId.clotheStyle)}
            </div>
            <div style={styles.optionTitleContainerMobile}>
              {renderSelection('brand', '品牌', tagTypeId.clothesBrand)}
            </div>
            <div style={styles.optionTitleContainerMobile}>
              {renderSelection('price', '價格', tagTypeId.price)}
            </div>
            <div style={styles.optionTitleContainerMobile}>
              <select
                style={styles.optionValue}
                onChange={handleSizeChange}
              >
                {
                  SizeType.map(({
                    name,
                    value,
                  }) => (
                    <option key={`${name}`} value={value}>{name}</option>
                  ))
              }
              </select>
            </div>
            <div style={styles.optionTitleContainerMobile}>
              {renderSelection('color', '顏色', tagTypeId.color)}
            </div>
          </Mobile>
        </React.Fragment>
      );
    }

    if (isBuyArea) {
      return (
        <React.Fragment>
          <Default>
            <div style={styles.optionTitleContainer}>
              <span style={styles.optionTitle}>篩選</span>
              <select
                style={styles.optionValue}
                onChange={onCommodityTypeChange}
              >
                <option key="分類" value={0}>分類</option>
                <option key={commodityTypeName.bag} value={commodityTypeId.bag}>{commodityTypeName.bag}</option>
                <option key={commodityTypeName.clothes} value={commodityTypeId.clothes}>{commodityTypeName.clothes}</option>
                <option key={commodityTypeName.accessories} value={commodityTypeId.accessories}>{commodityTypeName.accessories}</option>
              </select>
            </div>

            <div style={styles.optionTitleContainer}>
              {selectedCommodityType === commodityTypeId.clothes
                ? renderSelection('brand', '品牌', tagTypeId.clothesBrand) : renderSelection('brand', '品牌', tagTypeId.brand)}
            </div>
            <div style={styles.optionTitleContainer}>
              {renderSellType()}
            </div>
            <div style={styles.optionTitleContainer}>
              {renderSelection('price', '價格', tagTypeId.price)}
            </div>
            <div style={styles.optionTitleContainer}>
              {renderSelection('color', '顏色', tagTypeId.color)}
            </div>
          </Default>
          <Mobile>
            <div style={styles.optionTitleContainerMobile}>
              <span style={styles.optionTitle}>篩選</span>
              <select
                style={styles.optionValue}
                onChange={onCommodityTypeChange}
              >
                <option key="分類" value={0}>分類</option>
                <option key={commodityTypeName.bag} value={commodityTypeId.bag}>{commodityTypeName.bag}</option>
                <option key={commodityTypeName.clothes} value={commodityTypeId.clothes}>{commodityTypeName.clothes}</option>
                <option key={commodityTypeName.accessories} value={commodityTypeId.accessories}>{commodityTypeName.accessories}</option>
              </select>
            </div>
            <div style={styles.optionTitleContainerMobile}>
              {selectedCommodityType === commodityTypeId.clothes
                ? renderSelection('brand', '品牌', tagTypeId.clothesBrand) : renderSelection('brand', '品牌', tagTypeId.brand)}
            </div>
            <div style={styles.optionTitleContainerMobile}>
              {selectedCommodityType === commodityTypeId.clothes
                ? renderSelection('style', '種類', tagTypeId.clotheStyle) : renderSelection('style', '種類', tagTypeId.bagStyle)}
            </div>
            <div style={styles.optionTitleContainerMobile}>
              {renderSelection('price', '價格', tagTypeId.price)}
            </div>
            <div style={styles.optionTitleContainerMobile}>
              {renderSelection('color', '顏色', tagTypeId.color)}
            </div>
          </Mobile>
        </React.Fragment>
      );
    }

    if (commodityType === commodityTypeId.accessories) {
      return (
        <React.Fragment>
          <Default>
            <div style={styles.optionTitleContainer}>
              <span style={styles.optionTitle}>篩選</span>
              {renderSelection('type', '分類', tagTypeId.accessoriesType)}
            </div>
            <div style={styles.optionTitleContainer}>
              {renderSelection('brand', '品牌', tagTypeId.brand)}
            </div>
            <div style={styles.optionTitleContainer}>
              {renderSelection('price', '價格', tagTypeId.price)}
            </div>
            <div style={styles.optionTitleContainer}>
              {renderSelection('color', '顏色', tagTypeId.color)}
            </div>
          </Default>
          <Mobile>
            <div style={styles.optionTitleContainerMobile}>
              <span style={styles.optionTitle}>篩選</span>
              {renderSelection('type', '分類', tagTypeId.accessoriesType)}
            </div>
            <div style={styles.optionTitleContainerMobile}>
              {renderSelection('brand', '品牌', tagTypeId.brand)}
            </div>
            <div style={styles.optionTitleContainerMobile}>
              {renderSelection('price', '價格', tagTypeId.price)}
            </div>
            <div style={styles.optionTitleContainerMobile}>
              {renderSelection('color', '顏色', tagTypeId.color)}
            </div>
          </Mobile>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Default>
          <div style={styles.optionTitleContainer}>
            <span style={styles.optionTitle}>篩選</span>
            {renderSelection('type', '分類', tagTypeId.bagStyle)}
          </div>
          <div style={styles.optionTitleContainer}>
            {renderSelection('brand', '品牌', tagTypeId.brand)}
          </div>
          <div style={styles.optionTitleContainer}>
            {renderSelection('price', '價格', tagTypeId.price)}
          </div>
          <div style={styles.optionTitleContainer}>
            {renderSelection('color', '顏色', tagTypeId.color)}
          </div>
        </Default>
        <Mobile>
          <div style={styles.optionTitleContainerMobile}>
            <span style={styles.optionTitle}>篩選</span>
            {renderSelection('type', '分類', tagTypeId.bagStyle)}
          </div>
          <div style={styles.optionTitleContainerMobile}>
            {renderSelection('brand', '品牌', tagTypeId.brand)}
          </div>
          <div style={styles.optionTitleContainerMobile}>
            {renderSelection('price', '價格', tagTypeId.price)}
          </div>
          <div style={styles.optionTitleContainerMobile}>
            {renderSelection('color', '顏色', tagTypeId.color)}
          </div>
        </Mobile>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Default
        width={901}
      >
        <div style={styles.mainContainer}>
          {isHomepage ? (
            <div style={styles.newInHomeContainer}>
              <h1 style={styles.title}>
                New in
              </h1>
              <span style={styles.subTitle}>
                {renderHomePageTitle()}
              </span>
            </div>
          ) : null}
          {isHomepage ? null : (
            <div style={styles.optionContainer}>
              { renderOPManageSwitch()}
              <div style={styles.optionGroup}>
                {renderOptionGroup()}
                <div style={styles.optionTitleContainer}>
                  <span style={styles.optionTitle}>排序</span>
                  <select
                    style={styles.optionValue}
                    onChange={handleSortOrderChange}
                    value={selectedSortOrder}
                  >
                    {
                     BagSortOrder.map(({
                       name,
                       order,
                     }, index) => (
                       <option key={`${name}_${order}`} value={index}>{name}</option>
                     ))
                   }
                  </select>
                </div>
              </div>
            </div>
          )}
          <hr style={styles.hrLine} />
        </div>
      </Default>
      <Mobile
        width={900}
      >
        <div style={styles.mainContainerMobile}>
          {
            isHomepage ? (
              <div style={styles.newInContainerMobile}>
                <h1 style={styles.titleMobile}>
                  New in
                </h1>
                <div style={styles.subTitleMobile}>
                  {renderHomePageTitle()}
                </div>
              </div>
            ) : null
          }
          {isHomepage ? null : (
            <React.Fragment>
              <div style={styles.optionUpContainerMobile}>
                {renderOptionGroup()}
              </div>
              <div style={styles.optionUpContainerMobile}>
                <div style={styles.optionTitleContainerMobile}>
                  <span style={styles.optionTitle}>排序</span>
                  <select
                    style={styles.optionValue}
                    onChange={handleSortOrderChange}
                    value={selectedSortOrder}
                  >
                    {
                      BagSortOrder.map(({
                        name,
                        order,
                      }, index) => (
                        <option key={`${name}_${order}`} value={index}>{name}</option>
                      ))
                    }
                  </select>
                </div>
                {renderOPManageSwitch(true)}
              </div>
            </React.Fragment>
          )}
        </div>
      </Mobile>
    </React.Fragment>
  );
};

BagSearchPanel.propTypes = {
  handleSearchConditionChange: PropTypes.func.isRequired,
  tags: PropTypes.array,
  condition: PropTypes.shape({}),
  isClothes: PropTypes.bool,
  isBuyArea: PropTypes.bool,
  isHomepage: PropTypes.bool,
  commodityType: PropTypes.number,
  onCommodityTypeChange: PropTypes.func.isRequired,
  selectedCommodityType: PropTypes.number,
};

BagSearchPanel.defaultProps = {
  tags: [],
  condition: {},
  isClothes: false,
  isBuyArea: false,
  isHomepage: false,
  commodityType: 0,
  selectedCommodityType: 0,
};

export default BagSearchPanel;
