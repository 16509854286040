import api from '../../lib/configAxios';

export default {
  retrieveBag: async ({ bagId, ...retrieveData }) => api.post(
    `Bags/${bagId}/retrieve`, retrieveData,
  ),
  extendContractBag: async ({ bagId, ...data }) => api.post(
    `Bags/${bagId}/extendContract`, data,
  ),
  bagEdit: async ({ bagId, ...data }) => api.post(
    `Bags/${bagId}/bagEdit`, data,
  ),

  updateBagInfo: async data => api.patch(
    `Bags/${data.id}`, data,
  ),

  linkBagTag: async ({ bagId, tagId }) => api.put(
    `Bags/${bagId}/tags/rel/${tagId}`,
  ),
};
