import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import styled from 'styled-components';
import Paths from '../../../../routes/Paths';
import BadgeComponent from '../../../../components/BadgeComponent';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import LoginPageContainer from '../LoginPage';
import ChangePasswordComponent from '../MemberPage/ChangePasswordComponent';
import Assets from '../../../../constants/Assets';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import { FontSize } from '../../../../constants/FontSize';

const LoginSignUpLink = styled.div`
  cursor: pointer;
  color: black;
  &:hover{
    opacity: 0.5;
    text-decoration:underline;
  }
`;

const styles = {
  memberContainer: {
    width: 150,
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  text: {
    color: 'grey',
    fontSize: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 150,
    fontFamily: 'Baskerville-Old-Facef',
  },
  textScrolled: {
    color: 'white',
    fontSize: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 150,
    fontFamily: 'Baskerville-Old-Facef',
  },
  loanButton: {
    display: 'inline-block',
    color: '#974107',
    border: '1px solid #974107',
    verticalAlign: 'middle',
    padding: '5px 40px',
    fontSize: FontSize.body,
    borderRadius: '20px',
  },
  loanButtonScrolled: {
    display: 'inline-block',
    color: '#974107',
    border: '1px solid #974107',
    verticalAlign: 'middle',
    padding: '5px 40px',
    fontSize: FontSize.body,
    borderRadius: '20px',
  },
  title: {
    color: '#974107',
    fontSize: 15,
    fontWeight: 0,
    margin: 0,
    width: 250,
  },
  logInLink: {
    cursor: 'pointer',
  },
  memberName: {
    letterSpacing: 1,
    textDecoration: 'underline',
  },
  memberNameScrolled: {
    letterSpacing: 1,
    textDecoration: 'underline',
    color: 'black',
  },
  memberContainerMobile: {
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'middle',
    position: 'absolute',
    right: '30px',
    top: '0px',
  },
  fbButton: {
    display: 'none',
  },
};

const HeadMemberComponent = ({
  reduxStatus,
  reduxError,
  isLogin,
  memberName,
  communityName,
  memberSocialLogin,
  style,
  cartList,
  notificationList,
  onRemoveItem,
  handleChangePassword,
  resetStatus,
  onHadReadNotification,
  isScrolled,
  isShowCommodityList,
  headerScrolled,
  onAllReadNotification,
}) => {
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  return (
    <div style={style}>
      <Default>
        <InfoModalComponent
          containerStyle={{ zIndex: 1 }}
          isVisible={isShowLoginModal}
          onModalClose={() => setIsShowLoginModal(false)}
          content={(
            <LoginPageContainer
              setIsShowLoginModal={setIsShowLoginModal}
            />
            )}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          containerStyle={{ zIndex: 1, paddingTop: 100 }}
          isVisible={isShowLoginModal}
          onModalClose={() => setIsShowLoginModal(false)}
          content={(
            <LoginPageContainer
              setIsShowLoginModal={setIsShowLoginModal}
            />
            )}
        />
      </Mobile>

      <ChangePasswordComponent
        reduxStatus={reduxStatus}
        reduxError={reduxError}
        style={styles.dropdownItem}
        handleChangePassword={handleChangePassword}
        resetStatus={resetStatus}
        showChangePasswordModal={showChangePasswordModal}
        setShowChangePasswordModal={setShowChangePasswordModal}
      />

      <Default>
        <div style={styles.memberContainer}>
          <BadgeComponent
            iconSrc={isShowCommodityList[0] || isScrolled || !headerScrolled ? Assets.headerBadgeIcon01 : Assets.headerIcon01}
            popOverTriggerType="click"
            targetRoute={isLogin ? Paths.MEMBER : Paths.LOGIN}
            type="user"
            isLogin={isLogin}
            communityName={communityName}
            memberSocialLogin={memberSocialLogin}
            setIsShowLoginModal={setIsShowLoginModal}
            reduxStatus={reduxStatus}
            reduxError={reduxError}
            handleChangePassword={handleChangePassword}
            resetStatus={resetStatus}
            setShowChangePasswordModal={setShowChangePasswordModal}
          />
          <BadgeComponent
            iconSrc={isShowCommodityList[0] || isScrolled || !headerScrolled ? Assets.headerBadgeIcon03 : Assets.headerIcon03}
            popOverTriggerType="click"
            data={notificationList}
            type="notification"
            isLogin={isLogin}
            communityName={communityName}
            onHadReadNotification={onHadReadNotification}
            onAllReadNotification={onAllReadNotification}
          />
          <BadgeComponent
            iconSrc={isShowCommodityList[0] || isScrolled || !headerScrolled ? Assets.headerBadgeIcon02 : Assets.headerIcon02}
            popOverTriggerType="click"
            targetRoute={Paths.CART}
            data={cartList}
            type="cart"
            isLogin={isLogin}
            onRemoveItem={onRemoveItem}
          />

          <div style={isShowCommodityList[0] || isScrolled || !headerScrolled ? styles.text : styles.textScrolled}>
            {isLogin
              ? (
                <Link
                  to={Paths.MEMBER}
                  style={isShowCommodityList[0] || isScrolled || !headerScrolled ? styles.memberName : styles.memberNameScrolled}
                >
                  {`Hi, ${memberName || ''}`}
                </Link>
              )
              : (
                <LoginSignUpLink
                  onClick={() => setIsShowLoginModal(true)}
                >
                  會員登入 / 註冊
                </LoginSignUpLink>
              )
        }
          </div>
        </div>
        <Link
          to={Paths.SUBMIT_BAG}
          style={isShowCommodityList[0] || isScrolled || !headerScrolled ? styles.loanButton : styles.loanButtonScrolled}
        >
          寄賣/寄租
        </Link>
      </Default>

      <Mobile>
        <div style={styles.memberContainerMobile}>
          <BadgeComponent
            iconSrc={Assets.headerBadgeIcon01}
            popOverTriggerType="click"
            targetRoute={isLogin ? Paths.MEMBER : Paths.LOGIN}
            type="user"
            isLogin={isLogin}
            communityName={communityName}
            memberSocialLogin={memberSocialLogin}
            setIsShowLoginModal={setIsShowLoginModal}
            reduxStatus={reduxStatus}
            reduxError={reduxError}
            handleChangePassword={handleChangePassword}
            resetStatus={resetStatus}
            setShowChangePasswordModal={setShowChangePasswordModal}
          />
          <BadgeComponent
            iconSrc={Assets.headerBadgeIcon03}
            popOverTriggerType="click"
            data={notificationList}
            type="notification"
            isLogin={isLogin}
            communityName={communityName}
            onHadReadNotification={onHadReadNotification}
            onAllReadNotification={onAllReadNotification}
          />
          <BadgeComponent
            iconSrc={Assets.headerBadgeIcon02}
            popOverTriggerType="click"
            targetRoute={Paths.CART}
            data={cartList}
            type="cart"
            isLogin={isLogin}
            onRemoveItem={onRemoveItem}
          />
        </div>
      </Mobile>
    </div>
  );
};

HeadMemberComponent.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  isLogin: PropTypes.bool,
  memberName: PropTypes.string,
  memberSocialLogin: PropTypes.string,
  communityName: PropTypes.string,
  cartList: PropTypes.array,
  notificationList: PropTypes.array,
  onRemoveItem: PropTypes.func,
  style: PropTypes.shape({}),
  handleChangePassword: PropTypes.func.isRequired,
  resetStatus: PropTypes.func.isRequired,
  onHadReadNotification: PropTypes.func,
  isShowCommodityList: PropTypes.array,
  isScrolled: PropTypes.bool,
  headerScrolled: PropTypes.bool,
  onAllReadNotification: PropTypes.func,
};

HeadMemberComponent.defaultProps = {
  reduxError: null,
  memberName: '',
  memberSocialLogin: null,
  isLogin: false,
  communityName: '',
  cartList: [],
  notificationList: [],
  style: {},
  onRemoveItem: () => {},
  onHadReadNotification: () => {},
  isShowCommodityList: [],
  isScrolled: false,
  headerScrolled: false,
  onAllReadNotification: () => {},
};

export default HeadMemberComponent;
