import React from 'react';

import Header from '../../Parts/Header';
import Title from '../../Parts/Title';

import style from './style.module.scss';

const StartCard = () => (
  <div className={style.container}>
    <Header />
    <div className={style.content}>
      <Title> 您將掃描哪種證件？ </Title>
      <div className={style.text}>請於選擇證照類型</div>
      <div className={style.selectContent}>
        <div className={style.select}>身分證 ID Card</div>
      </div>
    </div>

  </div>
);

export default StartCard;
