import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_CLOTHES_LIST'),
  ...createAsyncActionTypesArray('GET_MY_CLOTHES_LIST'),
  ...createAsyncActionTypesArray('NOTIFY_WHEN_ARRIVED'),
  ...createAsyncActionTypesArray('GET_COMMENT_LIST'),
  ...createAsyncActionTypesArray('GET_CLOTHES_RELEASE_DATE'),
  ...createAsyncActionTypesArray('GET_CLOTHES_ID_BY_CLOTHES_NO'),
  ...createAsyncActionTypesArray('GET_CLOTHES_RENT_DATE_LIST'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_CLOTHES_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_MY_CLOTHES_LIST_FLOW'),
  ...createAsyncActionTypesArray('NOTIFY_WHEN_ARRIVED_FLOW'),
  ...createAsyncActionTypesArray('GET_COMMENT_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_CLOTHES_RELEASE_DATE_FLOW'),
  ...createAsyncActionTypesArray('GET_CLOTHES_ID_BY_CLOTHES_NO_FLOW'),
  ...createAsyncActionTypesArray('GET_CLOTHES_RENT_DATE_LIST_FLOW'),

  // ----- STATUS -----
  'RESET_STATUS',

  { prefix: 'CLOTHES' },
);
