import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import Assets from '../../../../constants/Assets';
import Paths from '../../../../routes/Paths';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    width: 885,
    height: 670,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  mainMobileContainer: {
    width: '90vw',
    height: 'auto',
  },
  leftContainer: {
    width: '45%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rightContainer: {
    width: '55%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: '#FF5809',
    padding: '50px',
    position: 'relative',
    textAlign: 'center',
  },
  mobileImgContainer: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  title: {
    width: '100%',
    fontFamily: 'Baskerville',
    fontSize: 24,
    padding: '30px 0px',
    color: 'black',
    marginTop: '50px',
  },
  publishComplete: {
    marginTop: 10,
    fontFamily: 'Microsoft JhengHei Bold',
    fontSize: 22,
    color: 'black',
    marginBottom: 40,
  },
  subTitle: {
    marginTop: 4,
    fontSize: 16,
    fontWeight: 200,
    color: 'black',
    lineHeight: 3,
  },
  continue: {
    outline: 'none',
    width: '35%',
    height: 50,
    marginTop: 80,
    textAlign: 'center',
    padding: '10px 0px',
    fontSize: 18,
    color: 'black',
    border: '2px black solid',
    backgroundColor: 'transparent',
  },
  textContainerStyle: {
    width: '100%',
    textAlign: 'center',
  },
  buttonContainer: {
    width: '100%',
  },
};

const CompletePublishComponent = ({
  isVisible,
  onModalClose,
}) => {
  const renderChild = textContainerStyle => (
    <React.Fragment>
      <Mobile>
        <img style={styles.mobileImgContainer} src={Assets.protectTreeDialog} alt="" />
      </Mobile>
      <Default>
        <img style={styles.leftContainer} src={Assets.protectTreeDialog} alt="" />
      </Default>
      <div style={{ ...styles.rightContainer, ...textContainerStyle }}>
        <div style={styles.title}>
          Publish Completed!
        </div>

        <div style={{ ...styles.publishComplete, ...textContainerStyle }}>
          <Default>
            單品上傳完成·ＯＰ正在審核中
          </Default>
          <Mobile>
            單品上傳完成
            <br />
            ＯＰ正在審核中
          </Mobile>
        </div>
        <div style={{ ...styles.subTitle, ...textContainerStyle }}>
          歡迎加入永續時尚的行列
          <br />
          友善環境 · 輕盈荷包 · 精簡衣櫥
          <br />
          開啟你的線上衣櫥之旅
        </div>
        <Link to={Paths.ONLINE_PRODUCTS} style={styles.buttonContainer}>
          <button style={styles.continue} type="button" onClick={onModalClose}>繼續逛逛</button>
        </Link>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainContainer}>
              {renderChild()}
            </div>
        )}
          containerStyle={{ paddingTop: 110 }}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainMobileContainer}>
              {renderChild(styles.textContainerStyle)}
            </div>
      )}
          containerStyle={{ paddingTop: 110 }}
        />
      </Mobile>
    </React.Fragment>

  );
};

CompletePublishComponent.propTypes = {
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
};

CompletePublishComponent.defaultProps = {
  isVisible: false,
};

export default CompletePublishComponent;
