import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import ActionCreator from './ActionCreator';
import API from './API';

const {
  transactionFlowRequest,
  transactionFlowSuccess,
  transactionFlowFailure,
  transactionRequest,
  transactionSuccess,
  transactionFailure,

  zeroCardTransactionFlowRequest,
  zeroCardTransactionFlowSuccess,
  zeroCardTransactionFlowFailure,
  zeroCardTransactionRequest,
  zeroCardTransactionSuccess,
  zeroCardTransactionFailure,
} = ActionCreator;


export function* transaction(payload) {
  yield put(transactionRequest());
  try {
    const result = yield call(API.orderTransaction, payload);
    yield put(transactionSuccess(result.data));
    return result;
  } catch (error) {
    yield put(transactionFailure(error));
    throw error;
  }
}

export function* transactionFlow({ payload }) {
  try {
    const result = yield call(transaction, payload);
    if (result.status === 200) {
      yield put(transactionFlowSuccess());
      return;
    }

    yield put(transactionFlowFailure());
  } catch (error) {
    yield put(transactionFlowFailure());
  }
}

export function* zeroCardTransaction(payload) {
  yield put(zeroCardTransactionRequest());
  try {
    const result = yield call(API.ZerCardReserveEc, payload);
    yield put(zeroCardTransactionSuccess(result.data));
    return result;
  } catch (error) {
    yield put(zeroCardTransactionFailure(error));
    throw error;
  }
}

export function* zeroCardTransactionFlow({ payload }) {
  try {
    const result = yield call(zeroCardTransaction, payload);
    if (result.status === 200) {
      yield put(zeroCardTransactionFlowSuccess());
      return;
    }

    yield put(zeroCardTransactionFlowFailure());
  } catch (error) {
    yield put(zeroCardTransactionFlowFailure());
  }
}


export default [
  takeLatest(transactionFlowRequest, transactionFlow),
  takeLatest(zeroCardTransactionFlowRequest, zeroCardTransactionFlow),
];
