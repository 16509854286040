import api from '../../lib/configAxios';

export default {
  getSpecialSelectList: async () => api.get(
    `Users?filter=${encodeURIComponent(JSON.stringify({
      where: {
        isMaster: true,
      },
      fields: ['name', 'id', 'avatarId', 'communityName'],
      include: [
        {
          relation: 'avatar',
          scope: { fields: 'uri' },
        },
      ],
    }))}`,
  ),
};
