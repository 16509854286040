/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  circle,
} from '../../../../constants/Assets';

import Header from '../../Parts/Header';
import Button from '../../Parts/Button';

import styles from './style.module.scss';

const VideoRecorder = ({
  onClickStop,
}) => {
  const [recording, setRecording] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const videoRef = useRef();
  const replayVideoRef = useRef();
  const mediaRecorderRef = useRef();
  const [videoChunks, setVideoChunks] = useState([]);

  const handleStartRecording = () => {
    setRecording(true);
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.start();

        mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
          videoChunks.push(event.data);
          setVideoChunks(videoChunks);
        });

        mediaRecorderRef.current.addEventListener('stop', async () => {
          const videoBlob = new Blob(videoChunks, { type: 'video/mp4' });
          const formData = new FormData();
          formData.append('file', videoBlob, 'recorded-video.mp4');
          const url = URL.createObjectURL(videoBlob);
          setVideoURL(url);
          replayVideoRef.current.src = url;

          onClickStop({
            video: formData,
          });
        });
      });
  };

  const handleStopRecording = async () => {
    setRecording(false);
    mediaRecorderRef.current.stop();
    videoRef.current.srcObject.getTracks().forEach(track => track.stop());
  };


  useEffect(() => {
    handleStartRecording();
  }, []);


  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <div className={styles.cameraWrapperOuter}>
          <img src={circle} alt="" />
          <div className={styles.cameraWrapper}>
            <div className={styles.videoWrapper}>
              <video className={styles.record} ref={videoRef} autoPlay muted playsInline src={videoURL} />
              {
              videoURL && !recording && (
                <video
                  ref={replayVideoRef}
                  autoPlay
                  muted
                  playsInline
                  src={videoURL}
                  loop
                />
              )
            }
            </div>
          </div>
        </div>
        {recording ? (
          <Button type="button" onClick={handleStopRecording}>完成錄影</Button>
        ) : (
          null
        )}
      </div>
    </div>
  );
};

export default VideoRecorder;

VideoRecorder.propTypes = {
  onClickStop: PropTypes.func.isRequired,
};
