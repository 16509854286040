import React, { useState } from 'react';
import { Icon, Modal } from 'antd';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import * as R from 'ramda';
import Button from '../../../../components/Button';
import Uploader from '../../../ResourceUploader/components/Uploader';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import Assets from '../../../../constants/Assets';
import { BagLevelId, BagLevelText } from '../../../../constants/BagLevels';
import {
  typeId, commodityTypeId, tagTypeId, transactionType,
} from '../../../../constants/Type';
import { rentAmountCalculate, baseDayRentAmountCalculate } from '../../../../utils/rentAmountCalculate';
import { shippingTypeId } from '../../../../constants/ShippingType';
import { Colors } from '../../../../constants/Color';


const styles = {
  mainContainer: {
    width: '100%',
    height: 'fit-content',
    padding: '40px 0px 150px 0px',
    borderBottom: '1px solid #DFDFDF',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  uploadItemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0px 0px 20px 0px',
    borderBottom: '1px solid #DFDFDF',
  },
  title: {
    fontFamily: 'Myriad Semibold',
    fontSize: 24,
    color: 'black',
  },
  button: {
    fontSize: 18,
    color: 'black',
    border: 'none',
    outline: 'none',
  },
  memberInputContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  memberInputMobileContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  selectionItem: {
    marginTop: 10,
    marginRight: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '60%',
  },
  selectionItemAttachment: {
    marginTop: 10,
    marginRight: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '90%',
  },
  selectionMobileItem: {
    marginTop: 10,
    marginRight: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  priceItem: {
    margin: '0px 20px 20px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  priceBlockItem: {
    margin: '0px 20px 20px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  priceMobileItem: {
    margin: '0px 20px 20px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  priceBlockMobileContainer: {
    margin: '0px 20px 20px 0px',
    width: '80%',
    border: `2px solid ${Colors.orderBrown}`,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectionTitle: {
    color: 'black',
    fontSize: 12,
    width: '140px',
  },
  priceTitle: {
    color: 'black',
    fontSize: 12,
    width: '140px',
  },
  selection: {
    height: 35,
    width: '100%',
    backgroundColor: 'transparent',
    border: '1px solid #E6E7E8',
    color: 'black',
    outline: 'none',
    padding: 5,
    cursor: 'pointer',
  },
  rentTypeSelection: {
    height: 35,
    width: '75%',
    backgroundColor: 'transparent',
    border: '1px solid #E6E7E8',
    color: 'black',
    outline: 'none',
    padding: 5,
    cursor: 'pointer',
  },
  input: {
    height: 35,
    width: '100%',
    backgroundColor: 'transparent',
    border: '1px solid #E6E7E8',
    color: 'black',
    outline: 'none',
    borderRadius: 0,
    padding: 5,
    WebkitAppearance: 'none',
  },
  priceInputContainer: {
    width: '100%',
    position: 'relative',
  },
  priceInput: {
    height: 35,
    width: '100%',
    backgroundColor: 'transparent',
    border: '1px solid #a48b78',
    color: 'black',
    outline: 'none',
    padding: '5px 5px 5px 42px',
    WebkitAppearance: 'none',
    borderRadius: '5px',
    fontSize: '12px',
  },
  priceBlockInput: {
    height: 35,
    width: '60%',
    backgroundColor: 'transparent',
    color: 'black',
    outline: 'none',
    padding: '5px 5px 5px 5px',
    WebkitAppearance: 'none',
    borderRadius: '5px',
    fontSize: '20px',
    border: 'none',
  },
  priceMobileInput: {
    height: 35,
    width: '100%',
    backgroundColor: 'transparent',
    border: '1px solid #a48b78',
    color: 'black',
    outline: 'none',
    padding: '5px 5px 5px 42px',
    WebkitAppearance: 'none',
    borderRadius: '5px',
    fontSize: '12px',
  },
  priceHeader: {
    position: 'absolute',
    backgroundColor: '#a48b78',
    top: '0px',
    height: '34px',
    color: 'white',
    width: '40px',
    padding: '7px 6px',
    borderRadius: '5px',
    fontSize: '10px',
  },
  incomeInput: {
    width: 120,
  },
  uploaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  uploaderItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '25%',
    margin: 0,
  },
  uploaderMobileItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '20%',
    margin: 0,
  },
  uploaderItemFrontPic: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    width: '100%',
    position: 'relative',
  },
  uploaderItemMobileSamplePic: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    width: '100%',
    position: 'relative',
  },
  uploaderItemMobile: {
    height: 'auto',
    padding: '10px 0px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
  },
  upload: {
    backgroundColor: '#DCDCDC',
    height: '120px',
    width: '97%',
    position: 'relative',
  },
  uploadMobile: {
    backgroundColor: '#DCDCDC',
    height: '90px',
    width: '94%',
    position: 'relative',
  },
  frontPicUpload: {
    backgroundColor: '#DCDCDC',
    height: '600px',
    width: '100%',
    margin: '2px',
    position: 'relative',
  },
  uploadedImg: {
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  descriptionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  description: {
    width: '100%',
    height: 246,
    padding: 20,
    border: '1px solid #999999',
    fontSize: '12px',
    outline: 'none',
  },
  memberInfoInputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  memberInputBox: {
    margin: '5px 0px',
    border: 'none',
    borderBottom: '1px black solid',
    outline: 'none',
    width: '55%',
  },
  memberInputMobileBox: {
    margin: '5px 0px',
    border: 'none',
    borderBottom: '1px black solid',
    outline: 'none',
    width: '90%',
  },
  addressInput: {
    width: '100%',
  },
  stepContainer: {
    width: '30%',
    paddingRight: '50px',
    paddingLeft: '50px',
  },
  stepMobileContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  picContainer: {
    width: '40%',
    position: 'relative',
    padding: '0px 60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '560px',
  },
  picMobileContainer: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '40px 0px',
  },
  picContentContainer: {
    width: '100%',
    maxWidth: '500px',
  },
  infoContainer: {
    width: '40%',
    padding: '0px 30px',
    maxWidth: '760px',
  },
  divideLine: {
    backgroundColor: '#6a3906',
    border: '0px',
    height: '1px',
    marginBottom: '30px',
  },
  divideLineDisable: {
    backgroundColor: '#a48b78',
    border: '0px',
    height: '1px',
    marginBottom: '30px',
  },
  divideLineMobile: {
    backgroundColor: '#6a3906',
    border: '0px',
    height: '1px',
    width: '100%',
  },
  divideLineMobileDisable: {
    backgroundColor: '#a48b78',
    border: '0px',
    height: '1px',
    width: '100%',
  },
  stepTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    color: '#6a3906',
    cursor: 'pointer',
    marginBottom: '16px',
    outline: 'none',
  },
  infoTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    color: '#6a3906',
    cursor: 'pointer',
    marginBottom: '6px',
    outline: 'none',
  },
  infoTextMobileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    color: '#6a3906',
    cursor: 'pointer',
    marginBottom: '6px',
    outline: 'none',
    width: '100%',
  },
  stepTextDisableContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    color: '#a48b78',
    cursor: 'pointer',
    marginBottom: '16px',
    outline: 'none',
  },
  stepTextMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#6a3906',
    cursor: 'pointer',
    marginBottom: '16px',
    outline: 'none',
    width: '26%',
  },
  stepTextMobileDisableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#a48b78',
    cursor: 'pointer',
    marginBottom: '16px',
    outline: 'none',
    width: '26%',
  },
  stepTitle: {
    margin: 0,
    color: '#6a3906',
    fontSize: '21px',
  },
  stepTitleMobile: {
    margin: 0,
    color: '#6a3906',
    fontSize: '16px',
  },
  stepTitleDisable: {
    margin: 0,
    color: '#a48b78',
    fontSize: '21px',
  },
  stepTitleDisableMobile: {
    margin: 0,
    color: '#a48b78',
    fontSize: '16px',
  },
  stepText: {
    margin: '0px 0px 2px 10px',
    color: '#6a3906',
  },
  stepNoteText: {
    margin: '0px 0px 2px 2px',
  },
  uploadPicContainer: {
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
  },
  uploadPicMobileContainer: {
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
  },
  uploadBox: {
    width: '80px',
    height: '80px',
    cursor: 'pointer',
  },
  uploadContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  uploadIcon: {
    fontSize: '60px',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  notePicContainer: {
    height: '200px',
    width: '100px',
  },
  sliderContainer: {
    height: 'auto',
    position: 'relative',
    marginTop: '40px',
  },
  previewContainer: {
    height: 'auto',
    position: 'relative',
    marginTop: '40px',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    backgroundColor: '#DCDCDC',
    marginBottom: '10px',
  },
  preArrow: {
    position: 'absolute',
    top: '55px',
    left: '-30px',
    backgroundImage: `url(${Assets.uploadLeftArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '50px',
    height: '50px',
    cursor: 'pointer',
    zIndex: 1,
    outline: 'none',
  },
  nextArrow: {
    position: 'absolute',
    top: '55px',
    right: '-30px',
    backgroundImage: `url(${Assets.uploadRightArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '50px',
    height: '50px',
    cursor: 'pointer',
    outline: 'none',
  },
  previewPreArrow: {
    position: 'absolute',
    top: '180px',
    left: '10px',
    backgroundImage: `url(${Assets.uploadLeftArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '50px',
    height: '50px',
    cursor: 'pointer',
    zIndex: 1,
    outline: 'none',
  },
  previewNextArrow: {
    position: 'absolute',
    top: '180px',
    right: '10px',
    backgroundImage: `url(${Assets.uploadRightArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '50px',
    height: '50px',
    cursor: 'pointer',
    outline: 'none',
  },
  uploadNotePic: {
    width: '100%',
    height: '100%',
    maxHeight: '160px',
    objectFit: 'contain',
  },
  preViewPic: {
    width: '100%',
    height: '400px',
    objectFit: 'contain',
  },
  productInfoDetailContainer: {
    margin: '100px 0px 60px 0px',
  },
  calculateContainer: {
    position: 'relative',
    height: 'auto',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px',
  },
  calculateMobileContainer: {
    position: 'relative',
    height: 'auto',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    padding: '0px 10px',
  },
  calculateUnit: {
    width: '22%',
    height: '7.3vw',
    minHeight: '100px',
    minWidth: '100px',
    position: 'relative',
  },
  calculateUnitMobile: {
    width: '22vw',
    height: '22vw',
    position: 'relative',
  },
  calculateUp: {
    height: '50%',
    width: '100%',
    borderRadius: '50rem 50rem 0px 0px',
    backgroundColor: '#a48b78',
    color: 'white',
    position: 'relative',
  },
  calculateDown: {
    height: '50%',
    width: '100%',
    borderRadius: '0px 0px 50rem 50rem',
    backgroundColor: '#a48b78',
    color: 'white',
    position: 'relative',
  },
  circleLine: {
    border: 0,
    borderTop: '0.1px white solid',
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
  },
  calculateDayText: {
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    fontSize: '14px',
    transform: 'translate(-50%, 0%)',
  },
  calculateDayTextMobile: {
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    fontSize: '12px',
    transform: 'translate(-50%, 0%)',
  },
  calculateAmountText: {
    position: 'absolute',
    top: '8px',
    left: '50%',
    fontSize: '14px',
    transform: 'translate(-50%, 0%)',
    whiteSpace: 'nowrap',
  },
  calculateAmountTextMobile: {
    position: 'absolute',
    top: '8px',
    left: '50%',
    fontSize: '12px',
    transform: 'translate(-50%, 0%)',
    whiteSpace: 'nowrap',
  },
  trialBalanceContainer: {
    margin: '20px 0px',
  },
  needDepositSelect: {
    display: 'flex',
  },
  needDepositText: {
    marginLeft: '10px',
    marginBottom: '20px',
    fontSize: '12px',
  },
  shippingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  shippingMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  shippingBlock: {
    border: '1px solid grey',
    width: '100%',
    height: '320px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    margin: '10px 0px',
  },
  shippingBlockMobile: {
    border: '1px solid grey',
    width: '100%',
    height: '400px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    margin: '20px 0px',
  },
  shippingIcon: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    marginBottom: '10px',
    marginTop: '10px',
  },
  opMangerIcon: {
    width: '50px',
    height: '50px',
    objectFit: 'contain',
    marginBottom: '10px',
    marginTop: '10px',
  },
  shippingIconContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  opManagementIconContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '320px',
  },
  opManagementIconMobileContainer: {
    width: '100%',
    heigh: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  shippingIconBoth: {
    width: '50px',
    height: '40px',
    objectFit: 'contain',
  },
  marginBlock: {
    height: '100px',
  },
  nextStepButton: {
    backgroundColor: '#a48b78',
    outline: 'none',
    border: 'none',
    borderRadius: '5px',
    width: '100px',
    height: '30px',
    color: 'white',
    margin: '100px 10px',
    cursor: 'pointer',
    fontSize: '12px',
  },
  nextStepMobileButton: {
    backgroundColor: '#a48b78',
    outline: 'none',
    border: 'none',
    borderRadius: '5px',
    width: '100%',
    height: '50px',
    color: 'white',
    margin: '10px 0px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  saveButton: {
    backgroundColor: 'white',
    outline: 'none',
    border: '1px #a48b78 solid',
    borderRadius: '5px',
    width: '100px',
    height: '30px',
    color: '#a48b78',
    margin: '0px 10px',
    cursor: 'pointer',
    fontSize: '12px',
  },
  saveMobileButton: {
    backgroundColor: 'white',
    outline: 'none',
    border: '1px #a48b78 solid',
    borderRadius: '5px',
    width: '100%',
    height: '50px',
    color: '#a48b78',
    margin: '10px 0px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  buttonContainer: {
    position: 'absolute',
    right: '30px',
    bottom: '30px',
  },
  buttonMobileContainer: {
    width: '100%',
  },
  washText: {
    textDecoration: 'underline',
    color: 'black',
    fontSize: '12px',
  },
  washTextPrice: {
    color: '#a48b78',
    fontSize: '12px',
  },
  ableToBuyText: {
    color: '#a48b78',
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  priceBlank: {
    height: '10px',
  },
  memberDeliveryContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0px 10px',
  },
  memberDeliveryTitle: {
    width: '35%',
    textAlign: 'left',
  },
  memberDeliveryMobileTitle: {
    width: '70px',
    textAlign: 'left',
  },
  deliverClick: {
    margin: '10px 0px',
  },
  addressTitle: {
    marginBottom: '10px',
    textAlign: 'left',
    color: '#a48b78',
    width: '100%',
  },
  autoInputRecipientCheckboxText: {
    fontSize: '12px',
  },
  shippingMethodText: {
    fontSize: '10px',
    color: 'black',
    marginBottom: '-25px',
  },
  shippingLenderInfo: {
    fontSize: '12px',
    textAlign: 'left',
    marginLeft: '-20px',
    marginTop: '20px',
  },
  shippingLenderMobileInfo: {
    fontSize: '12px',
    textAlign: 'left',
    marginLeft: '-20px',
    width: '100%',
  },
  priceTitleCircle: {
    fontSize: '14px',
    color: 'black',
  },
  infoTextContainerDeposit: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    color: '#6a3906',
    cursor: 'pointer',
    marginTop: '-100px',
    marginBottom: '6px',
    outline: 'none',
  },
  infoTextContainerDelivery: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    color: '#6a3906',
    cursor: 'pointer',
    marginTop: '-80px',
    marginBottom: '6px',
    outline: 'none',
  },
  deliveryNote: {
    color: '#6a3906',
    marginBottom: '30px',
  },
  deliveryNoteMobile: {
    color: '#6a3906',
    marginBottom: '30px',
    textAlign: 'center',
  },
  questionIcon: {
    margin: '0px 10px',
  },
  washPriceNote: {
    color: 'white',
    backgroundColor: '#6a3906',
    padding: '5px 10px',
  },
  amountNote: {
    color: 'white',
    backgroundColor: '#6a3906',
    padding: '5px 10px',
    position: 'absolute',
    width: '16vw',
    textAlign: 'left',
  },
  amountNoteMobile: {
    color: 'white',
    backgroundColor: '#6a3906',
    padding: '5px 10px',
    position: 'absolute',
    width: '46vw',
    zIndex: 1,
    textAlign: 'left',
    fontSize: 12,
  },
  mapIcon: {
    width: '16px',
    height: 'auto',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  isBuyRadio: {
    marginLeft: '4px',
  },
  attachmentContainer: {
    height: 35,
    width: '100%',
    backgroundColor: 'transparent',
    color: 'black',
    outline: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  attachmentList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  attachmentText: {
    marginLeft: '5px',
  },
  priceBlockContainer: {
    border: `2px solid ${Colors.orderBrown}`,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48%',
    position: 'relative',
  },
  priceBlockMobileItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  priceBlockTitle: {
    backgroundColor: Colors.orderBrown,
    borderRadius: '3px 3px 0px 0px',
    padding: 5,
    color: 'white',
    textAlign: 'center',
    width: '100%',
  },
  priceBlockInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0px',
    width: '80%',
  },
  canEarnText: {
    position: 'absolute',
    color: '#AD1A01',
    bottom: -22,
  },
  canEarnTextMobile: {
    position: 'absolute',
    color: '#AD1A01',
    bottom: -5,
  },
  isOhPhireManageTextContainer: {
    width: '100%',
    height: '132px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F6F0E8',
    padding: '10px 0px',
    position: 'absolute',
    bottom: 0,
  },
  isOhPhireManageTextMobileContainer: {
    width: '50%',
    height: '396px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F6F0E8',
    padding: '10px 0px',
    position: 'relative',
  },
  isOhPhireTriangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '1.5vw 1.2vw 0 1.2vw',
    borderColor: 'white transparent transparent transparent',
    position: 'absolute',
    left: 10,
    top: 0,
  },
  attachmentIcon: {
    fontSize: 18,
    color: '#DB5535',
    marginRight: 5,
  },
  attachmentItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'black',
    width: '200px',
    margin: '5px 0px',
  },
  attachmentSortItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'black',
    width: '100px',
    margin: '5px 0px',
  },
  attachmentMidItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'black',
    width: '110px',
    margin: '5px 0px',
  },
  attachmentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  managementIconMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
  },
  managementIconContainer: {
    position: 'absolute',
    top: '60px',
  },
  mangerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const EmptyUploadCommodityComponent = {
  uuid: null,
  name: null,
  purchaseYear: null,
  deposit: null,
  washAmount: null,
  buyoutAmount: null,
  typeId: null,
  contractDeadLine: null,
  newLevel: null,
  hotLevel: null,
  description: null,
  rentMonths: null,
  tags: null, // update to bagTag model
  designerTagId: null,
  styleTagId: null,
  originalPrice: null,
  rentAmountPerDay: null,

  frontPicId: null,
  backPicId: null,
  sidePicId: null,
  bottomPicId: null,
  zipperPicId: null,

  frontPic: null,
  backPic: null,
  sidePic: null,
  bottomPic: null,
  zipperPic: null,
};

const PrevArrow = (props) => {
  const {
    onClick,
    isPreview,
  } = props;
  return (
    <div
      style={isPreview ? styles.previewPreArrow : styles.preArrow}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    />
  );
};

const NextArrow = (props) => {
  const {
    onClick,
    isPreview,
  } = props;
  return (
    <div
      style={isPreview ? styles.previewNextArrow : styles.nextArrow}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    />
  );
};

const UploadCommodityComponent = ({
  tags,
  uploadBag,
  onSubmit,
  onModifiedData,
  itemMobileContainerStyle,
  onSaveDraft,
  isUpdateMemberInfo,
  onIsUpdateMemberInfoChanged,
  recipientAddress,
  recipientName,
  recipientPhone,
  selectStore,
  onMemberValueChanged,
  sevenElevenMarket,
  isPostReceive,
  isSevenElevenReceive,
  updateIsPostReceive,
  updateIsSevenElevenReceive,
  isDraft,
  isEdit,
  onChangeCommodity,
  mobileContainerStyle,
  mobileMainContainerStyle,
  isMobile,
  onChangeToBuy,
  rentType,
}) => {
  const [step, setStep] = useState(1);
  const [showDepositInput, setShowDepositInput] = useState(false);
  const [commodityType, setCommodityType] = useState(commodityTypeId.bag);
  const [washAmount, setWashAmount] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(isEdit ? uploadBag.originalPrice : null);
  const [buyoutPrice, setBuyoutPrice] = useState(null);
  const [rentPerDayPrice, setRentPerDayPrice] = useState(null);
  const [selectAccessoriesType, setSelectAccessoriesType] = useState('');
  const accessoriesType = R.pathOr([], [5, 'tags'], tags);
  const [isShowWashPriceNote1, setIsShowWashPriceNote1] = useState(false);
  const [isShowWashPriceNote2, setIsShowWashPriceNote2] = useState(false);
  const [isShowAmountNote, setIsShowAmountNote] = useState(false);
  const [isShowRentDayHint, setIsShowRentDayHint] = useState(false);
  const [selectTransactionType, setSelectTransactionType] = useState(typeId.rent);
  // Note: Cancel auto advise
  // const [isDefaultRentDayPrice, setIsDefaultRentDayPrice] = useState(false);
  // const [isAutoRentDayPrice, setIsAutoRentDayPrice] = useState(false);

  const stepsData = [
    {
      step: 1,
      title: 'DETAILS',
      text: '商品細節',
    },
    {
      step: 2,
      title: 'PRICING',
      text: '價格',
    },
    {
      step: 3,
      title: 'DELIVERY',
      text: '收款資訊',
    },
    {
      step: 4,
      title: 'MANAGE',
      text: '商品管理',
    },
  ];

  const getShoesTagId = () => accessoriesType.map((item) => {
    if (item.name === '鞋履') {
      return item.id;
    }
    return null;
  }).filter(item => item !== null)[0];

  const onFileUploaded = (key, result) => {
    onModifiedData(uploadBag.uuid, [
      { key, value: result.id },
      { key: key.replace('Id', ''), value: result }]);
  };

  const uploadPicData = [
    {
      picTypeId: 'frontPicId',
      type: 'frontPic',
    },
    {
      picTypeId: 'backPicId',
      type: 'backPic',
    },
    {
      picTypeId: 'bottomPicId',
      type: 'bottomPic',
    },
    {
      picTypeId: 'sidePicId',
      type: 'sidePic',
    },
    {
      picTypeId: 'zipperPicId',
      type: 'zipperPic',
    },
  ];

  const renderInputBlock = (title, value, valueKey) => (
    <div style={styles.memberDeliveryContainer}>
      <div style={isMobile ? styles.memberDeliveryMobileTitle : styles.memberDeliveryTitle}>
        {title}
      </div>
      <input
        style={isMobile ? styles.memberInputMobileBox : styles.memberInputBox}
        placeholder={title}
        value={value}
        onChange={(event) => { onMemberValueChanged(valueKey, event); }}
      />
    </div>
  );

  const renderUploadPicButton = (
    picTypeId,
    type,
  ) => {
    const uploadContentBlock = (
      <React.Fragment>
        <div style={styles.uploadContainer}>
          <Uploader
            childComponent={(
              <div style={styles.uploadBox}>
                <Icon
                  style={styles.uploadIcon}
                  type="plus-circle"
                />
              </div>
            )}
            onFileUploaded={result => onFileUploaded(picTypeId, result)}
          />
        </div>
        <img alt="" src={R.pathOr('', [type, 'uri'], uploadBag)} style={styles.uploadedImg} />
      </React.Fragment>
    );

    const content = (
      <div style={picTypeId === 'frontPicId' ? styles.frontPicUpload : styles.upload}>
        {uploadContentBlock}
      </div>
    );


    const contentMobile = (
      <div style={styles.uploadMobile}>
        {uploadContentBlock}
      </div>
    );

    return (
      <React.Fragment key={picTypeId}>
        <Default>
          <div style={picTypeId === 'frontPicId' ? styles.uploaderItemFrontPic : styles.uploaderItem}>
            {content}
          </div>
        </Default>
        <Mobile>
          <div style={styles.uploaderMobileItem}>
            {contentMobile}
          </div>
        </Mobile>
      </React.Fragment>
    );
  };

  const renderBagProductInfo = () => (
    <React.Fragment>
      <div style={isMobile ? styles.selectionMobileItem : styles.selectionItem}>
        <div style={styles.selectionTitle}>品牌</div>
        <select
          defaultValue={uploadBag.designerTagId === null ? '0' : uploadBag.designerTagId}
          style={styles.selection}
          onChange={event => onModifiedData(
            uploadBag.uuid, [{ key: 'designerTagId', value: event.target.value }],
          )}
        >
          <option
            key="0"
            value="0"
          />
          {
            tags.map(({
              id: tagCategoryId,
              tags: tagItems,
            }) => {
              if (tagCategoryId !== 1) return null;
              return tagItems.map(({
                name: tagName,
                id: tagId,
              }) => <option key={tagId} value={tagId}>{tagName}</option>);
            })
          }
        </select>
      </div>

      <div style={isMobile ? styles.selectionMobileItem : styles.selectionItem}>
        <div style={styles.selectionTitle}>包包類別</div>
        <select
          defaultValue={uploadBag.styleTagId === null ? '0' : uploadBag.styleTagId}
          onChange={(event) => {
            onModifiedData(uploadBag.uuid, [{ key: 'styleTagId', value: event.target.value }]);
          }}
          style={styles.selection}
        >
          <option
            key="0"
            value="0"
          />
          {
            tags.map(({
              id: tagCategoryId,
              tags: tagItems,
            }) => {
              if (tagCategoryId !== tagTypeId.bagStyle) return null;
              return tagItems.map(({
                name: tagName,
                id: tagId,
              }) => <option key={tagId} value={tagId}>{tagName}</option>);
            })
          }
        </select>
      </div>
    </React.Fragment>
  );

  const renderClothesProductInfo = selectionItemMobileStyle => (
    <React.Fragment>
      <div style={{ ...styles.selectionItem, ...selectionItemMobileStyle }}>
        <div style={styles.selectionTitle}>品牌</div>
        <select
          defaultValue={uploadBag.designerTagId === null ? '4' : uploadBag.designerTagId}
          style={styles.selection}
          onChange={event => onModifiedData(
            uploadBag.uuid, [{ key: 'designerTagId', value: event.target.value }],
          )}
        >
          <option
            key="0"
            value="0"
          />
          {
            tags.map(({
              id: tagCategoryId,
              tags: tagItems,
            }) => {
              if (tagCategoryId !== 4) return null;
              return tagItems.map(({
                name: tagName,
                id: tagId,
              }) => <option key={tagId} value={tagId}>{tagName}</option>);
            })
          }
        </select>
      </div>

      <div style={{ ...styles.selectionItem, ...selectionItemMobileStyle }}>
        <div style={styles.selectionTitle}>類別</div>
        <select
          defaultValue={uploadBag.styleTagId === null ? '5' : uploadBag.styleTagId}
          onChange={event => onModifiedData(uploadBag.uuid, [{ key: 'styleTagId', value: event.target.value }])}
          style={styles.selection}
        >
          <option
            key="0"
            value="0"
          />
          {
      tags.map(({
        id: tagCategoryId,
        tags: tagItems,
      }) => {
        if (tagCategoryId !== tagTypeId.clotheStyle) return null;
        return tagItems.map(({
          name: tagName,
          id: tagId,
        }) => <option key={tagId} value={tagId}>{tagName}</option>);
      })
    }
        </select>
      </div>
    </React.Fragment>
  );

  const renderAccessoriesProductInfo = selectionItemMobileStyle => (
    <React.Fragment>
      <div style={{ ...styles.selectionItem, ...selectionItemMobileStyle }}>
        <div style={styles.selectionTitle}>品牌</div>
        <select
          defaultValue={uploadBag.designerTagId === null ? '4' : uploadBag.designerTagId}
          style={styles.selection}
          onChange={event => onModifiedData(
            uploadBag.uuid, [{ key: 'designerTagId', value: event.target.value }],
          )}
        >
          <option
            key="0"
            value="0"
          />
          {
            tags.map(({
              id: tagCategoryId,
              tags: tagItems,
            }) => {
              if (tagCategoryId !== 4) return null;
              return tagItems.map(({
                name: tagName,
                id: tagId,
              }) => <option key={tagId} value={tagId}>{tagName}</option>);
            })
          }
        </select>
      </div>

      <div style={{ ...styles.selectionItem, ...selectionItemMobileStyle }}>
        <div style={styles.selectionTitle}>類別</div>
        <select
          defaultValue={uploadBag.styleTagId === null ? tagTypeId.accessoriesType : uploadBag.styleTagId}
          onChange={(event) => {
            onModifiedData(uploadBag.uuid, [{ key: 'styleTagId', value: event.target.value }]);
            setSelectAccessoriesType(event.target.value);
          }}
          style={styles.selection}
        >
          <option
            key="0"
            value="0"
          />
          {
          tags.map(({
            id: tagCategoryId,
            tags: tagItems,
          }) => {
            if (tagCategoryId !== tagTypeId.accessoriesType) return null;
            return tagItems.map(({
              name: tagName,
              id: tagId,
            }) => <option key={tagId} value={tagId}>{tagName}</option>);
          })
          }
        </select>
      </div>
    </React.Fragment>
  );

  const renderProductArea = () => {
    if (commodityType === commodityTypeId.clothes) return renderClothesProductInfo();

    if (commodityType === commodityTypeId.accessories) return renderAccessoriesProductInfo();

    return renderBagProductInfo();
  };

  const renderProductInfoContent = () => (
    <React.Fragment>
      <Default>
        <div style={styles.infoTextContainer}>
          <h1 style={styles.stepTitle}>
            Product
          </h1>
          <span style={styles.stepText}>
            產品
          </span>
        </div>
      </Default>
      <Mobile>
        <div style={styles.infoTextMobileContainer}>
          <h1 style={styles.stepTitle}>
            Product
          </h1>
          <div style={styles.stepText}>
            產品
          </div>
        </div>
      </Mobile>

      <hr style={styles.divideLine} />

      <div style={isMobile ? styles.selectionMobileItem : styles.selectionItem}>
        <div style={styles.selectionTitle}>產品類別</div>
        <select
          defaultValue={uploadBag.commodityTypeId === null ? commodityTypeId.bag : uploadBag.commodityTypeId}
          style={styles.selection}
          onChange={(event) => {
            onModifiedData(
              uploadBag.uuid, [{ key: 'commodityTypeId', value: event.target.value }],
            );
            setCommodityType(Number(event.target.value));
          }}
        >
          <option value="1">包包</option>
          <option value="2">衣服</option>
          <option value="3">配飾</option>
        </select>
      </div>
      {renderProductArea()}

      <div style={isMobile ? styles.selectionMobileItem : styles.selectionItem}>
        <div style={styles.selectionTitle}>名稱</div>
        <input
          style={styles.input}
          value={uploadBag.name === null ? '' : uploadBag.name}
          onChange={event => onModifiedData(uploadBag.uuid, [{ key: 'name', value: event.target.value }])}
        />
      </div>

    </React.Fragment>
  );
  const renderAccessoriesSelect = () => {
    if (selectAccessoriesType === String(getShoesTagId())) {
      return (
        <React.Fragment>
          <option value="EU35">EU35</option>
          <option value="EU35">EU35</option>
          <option value="EU37">EU37</option>
          <option value="EU38">EU38</option>
          <option value="EU39">EU39</option>
          <option value="EU40">EU40</option>
          <option value="EU41">EU41</option>
          <option value="EU42">EU42</option>
        </React.Fragment>
      );
    }

    return (
      <option value="OS">OS</option>
    );
  };

  const renderProductInfoDetailContent = () => (
    <React.Fragment>
      <div style={styles.infoTextContainer}>
        <h1 style={styles.stepTitle}>
          Product info
        </h1>
        <span style={styles.stepText}>
          產品詳情
        </span>
      </div>
      <hr style={styles.divideLine} />

      <div style={isMobile ? styles.selectionMobileItem : styles.selectionItem}>
        <div style={styles.selectionTitle}>顏色</div>
        <select
          defaultValue={uploadBag.colorTagId === null ? '0' : uploadBag.colorTagId}
          onChange={event => onModifiedData(uploadBag.uuid, [{ key: 'colorTagId', value: event.target.value }])}
          style={styles.selection}
        >
          <option
            key="0"
            value="0"
          />
          {
         tags.map(({
           id: tagCategoryId,
           tags: tagItems,
         }) => {
           if (tagCategoryId !== 3) return null;
           return tagItems.map(({
             name: tagName,
             id: tagId,
           }) => <option key={tagId} value={tagId}>{tagName}</option>);
         })
         }
        </select>
      </div>

      <div style={isMobile ? styles.selectionMobileItem : styles.selectionItem}>
        <div style={styles.selectionTitle}>尺寸標準</div>
        <select
          defaultValue={uploadBag.sizeStandard === null ? '0' : uploadBag.sizeStandard}
          onChange={event => onModifiedData(uploadBag.uuid, [{ key: 'sizeStandard', value: event.target.value }])}
          style={styles.selection}
        >
          {
            commodityType === commodityTypeId.accessories ? (
              renderAccessoriesSelect()
            ) : (
              <React.Fragment>
                <option
                  key="0"
                  value="0"
                />
                <option value="XXS">XXS</option>
                <option value="XS">XS</option>
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="L">L</option>
              </React.Fragment>
            )
          }
        </select>
      </div>

      <div style={isMobile ? styles.selectionMobileItem : styles.selectionItem}>
        <div style={styles.selectionTitle}>幾成新</div>
        <select
          defaultValue={uploadBag.newLevel === null ? '0' : uploadBag.newLevel}
          onChange={event => onModifiedData(uploadBag.uuid, [{ key: 'newLevel', value: event.target.value }])}
          style={styles.selection}
        >
          <option
            key="0"
            value="0"
          />
          {
            Object.keys(BagLevelId)
              .map(level => (
                <option value={BagLevelId[level]}>
                  {BagLevelText[level]}
                </option>
              ))
            }
        </select>
      </div>

      <div style={isMobile ? styles.selectionMobileItem : styles.selectionItemAttachment}>
        <div style={styles.selectionTitle}>原廠配件</div>
        <div style={styles.attachmentContainer}>
          <div style={styles.attachmentList}>
            <input
              type="checkbox"
              defaultChecked={uploadBag.isHasWarrantyCard ? false : uploadBag.isHasWarrantyCard}
              style={styles.deliverClick}
              onChange={event => onModifiedData(
                uploadBag.uuid, [{ key: 'isHasWarrantyCard', value: event.target.value }],
              )}
            />
            <div style={styles.attachmentText}>
              保卡
            </div>
          </div>
          <div style={styles.attachmentList}>
            <input
              type="checkbox"
              defaultChecked={uploadBag.isHasDustCover ? false : uploadBag.isHasDustCover}
              style={styles.deliverClick}
              onChange={event => onModifiedData(
                uploadBag.uuid, [{ key: 'isHasDustCover', value: event.target.value }],
              )}
            />
            <div style={styles.attachmentText}>
              防塵套
            </div>
          </div>
          <div style={styles.attachmentList}>
            <input
              type="checkbox"
              defaultChecked={uploadBag.isHasOriginBox ? false : uploadBag.isHasOriginBox}
              style={styles.deliverClick}
              onChange={event => onModifiedData(
                uploadBag.uuid, [{ key: 'isHasOriginBox', value: event.target.value }],
              )}
            />
            <div style={styles.attachmentText}>
              原廠盒
            </div>
          </div>
          <div style={styles.attachmentList}>
            <input
              type="checkbox"
              defaultChecked={uploadBag.isHasProve ? false : uploadBag.isHasProve}
              style={styles.deliverClick}
              onChange={event => onModifiedData(
                uploadBag.uuid, [{ key: 'isHasProve', value: event.target.value }],
              )}
            />
            <div style={styles.attachmentText}>
              購買證明
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  // Note: Cancel auto advise
  // const renderRentAmount = () => {
  //   if (isDefaultRentDayPrice) {
  //     return uploadBag.rentAmountPerDay;
  //   }
  //   if (isAutoRentDayPrice) {
  //     return rentPerDayPrice;
  //   }
  //   return uploadBag.rentAmountPerDay || '';
  // };

  const renderDescriptionBlock = () => (
    <React.Fragment>
      <div style={styles.infoTextContainer}>
        <h1 style={styles.stepTitle}>
          Item Description
        </h1>
        <span style={styles.stepText}>
          產品敘述
        </span>
      </div>
      <hr style={styles.divideLine} />

      <div style={{ ...styles.uploadItemContainer, ...itemMobileContainerStyle }}>
        <div style={styles.descriptionContainer}>
          <textarea
            style={styles.description}
            value={uploadBag.description === null ? '' : uploadBag.description}
            placeholder="描述您的商品，增加群眾對您提供商品的興趣。有關的尺碼偏大小，材質，等..."
            onChange={(event) => {
              onModifiedData(uploadBag.uuid, [{ key: 'description', value: event.target.value }]);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );

  const dayArray = [4, 8, 12, 16];
  const renderCalculateCircle = (day, isEarn) => (
    <div style={isMobile ? styles.calculateUnitMobile : styles.calculateUnit} key={day}>
      <div style={styles.calculateUp}>
        <div style={isMobile ? styles.calculateDayTextMobile : styles.calculateDayText}>
          {`${day}天`}
        </div>
      </div>
      <div style={styles.circleLine} />
      <div style={styles.calculateDown}>
        <div style={isMobile ? styles.calculateAmountTextMobile : styles.calculateAmountText}>
          NT$
          {rentAmountCalculate(rentPerDayPrice, day, isEarn, washAmount)}
        </div>
      </div>
    </div>
  );

  const renderPriceContent = () => (
    <React.Fragment>
      <div style={styles.infoTextContainer}>
        <h1 style={styles.stepTitle}>
          Price
        </h1>
        <span style={styles.stepText}>
          價格
        </span>
      </div>
      <hr style={styles.divideLine} />

      <div style={isMobile ? styles.priceMobileItem : styles.priceItem}>
        <div style={styles.priceTitle}>交易型態</div>
        <div style={styles.priceInputContainer}>
          <select
            defaultValue={rentType}
            style={styles.rentTypeSelection}
            onChange={(event) => {
              onChangeToBuy(event.target.value);
              setSelectTransactionType(Number(event.target.value));
            }}
          >
            {
            Object.keys(typeId)
              .map(item => (
                <option value={typeId[item]}>
                  {transactionType[item]}
                </option>
              ))
            }
          </select>
        </div>
      </div>

      <div style={isMobile ? styles.priceBlockMobileItem : styles.priceBlockItem}>
        {
          selectTransactionType === typeId.rent || selectTransactionType === typeId.rentBuy ? (
            <div style={isMobile ? styles.priceBlockMobileContainer : styles.priceBlockContainer}>
              <div style={styles.priceBlockTitle}>
                單日出租價格
                <Default>
                  <Icon
                    type="question-circle"
                    style={styles.questionIcon}
                    onMouseOver={() => { setIsShowAmountNote(true); }}
                    onFocus={() => { setIsShowAmountNote(true); }}
                    onMouseOut={() => { setIsShowAmountNote(false); }}
                    onBlur={() => { setIsShowAmountNote(false); }}
                  />
                  {
                isShowAmountNote ? (
                  <span style={styles.amountNote}>
                    此為系統建議價格
                    <br />
                    建議自行輸入租金
                    <br />
                    <br />
                    ＊小提醒:
                    <br />
                    合理租金出租率將大幅提高
                  </span>
                ) : null
              }
                </Default>
                <Mobile>
                  <Icon
                    type="question-circle"
                    style={styles.questionIcon}
                    onMouseOver={() => { setIsShowAmountNote(true); }}
                    onFocus={() => { setIsShowAmountNote(true); }}
                    onMouseOut={() => { setIsShowAmountNote(false); }}
                    onBlur={() => { setIsShowAmountNote(false); }}
                  />
                  {
                isShowAmountNote ? (
                  <span style={styles.amountNoteMobile}>
                    此為系統建議價格
                    <br />
                    建議自行輸入租金
                    <br />
                    <br />
                    ＊小提醒:
                    <br />
                    合理租金出租率將大幅提高
                  </span>
                ) : null
              }
                </Mobile>
              </div>

              <div style={styles.priceBlockInputContainer}>
                NT$
                <input
                  type="number"
                  style={styles.priceBlockInput}
            // Note: Cancel auto advise
            // value={renderRentAmount()}
                  onChange={(event) => {
                    if (event.target.value > 1000) {
                      setIsShowRentDayHint(true);
                    }
                    onModifiedData(uploadBag.uuid, [{ key: 'rentAmountPerDay', value: event.target.value }]);
                    setRentPerDayPrice(Math.ceil(Number(event.target.value)));
                    // Note: Cancel auto advise
                    // setIsAutoRentDayPrice(false);
                    // setIsDefaultRentDayPrice(true);
                  }
            }
                />
                元
              </div>
            </div>
          ) : null
        }
        {
          selectTransactionType === typeId.buy || selectTransactionType === typeId.rentBuy ? (
            <div style={isMobile ? styles.priceBlockMobileContainer : styles.priceBlockContainer}>
              <div style={styles.priceBlockTitle}>
                買斷價格
              </div>

              <div style={styles.priceBlockInputContainer}>
                NT$
                <input
                  type="number"
                  style={styles.priceBlockInput}
                  value={uploadBag.buyoutAmount === null ? '' : uploadBag.buyoutAmount}
                  onChange={(event) => {
                    onModifiedData(uploadBag.uuid, [{ key: 'buyoutAmount', value: event.target.value }]);
                    setBuyoutPrice(Number(event.target.value));
                  }}
                />
                元
              </div>
              <div style={isMobile ? styles.canEarnTextMobile : styles.canEarnText}>{`實收價格:$ ${Math.ceil(buyoutPrice * 0.8)} 元`}</div>
            </div>
          ) : null}
      </div>
      <div style={styles.priceBlank} />

      <div style={styles.trialBalanceContainer}>
        <div style={styles.priceTitleCircle}>
          顯示金額
          <Icon
            type="question-circle"
            style={styles.questionIcon}
            onMouseOver={() => { setIsShowWashPriceNote1(true); }}
            onFocus={() => { setIsShowWashPriceNote1(true); }}
            onMouseOut={() => { setIsShowWashPriceNote1(false); }}
            onBlur={() => { setIsShowWashPriceNote1(false); }}
          />
          {
            isShowWashPriceNote1 ? (
              <span style={styles.washPriceNote}>
                - 顯示於平台上的租借金額
              </span>
            ) : null
          }
        </div>

        <div style={isMobile ? styles.calculateMobileContainer : styles.calculateContainer}>
          {dayArray.map(day => renderCalculateCircle(day))}
        </div>

        <div style={styles.priceTitleCircle}>
          每日可賺
          <Icon
            type="question-circle"
            style={styles.questionIcon}
            onMouseOver={() => { setIsShowWashPriceNote2(true); }}
            onFocus={() => { setIsShowWashPriceNote2(true); }}
            onMouseOut={() => { setIsShowWashPriceNote2(false); }}
            onBlur={() => { setIsShowWashPriceNote2(false); }}
          />
          {
            isShowWashPriceNote2 ? (
              <span style={styles.washPriceNote}>
                - 實際賺取金額
              </span>
            ) : null
          }
        </div>

        <div style={isMobile ? styles.calculateMobileContainer : styles.calculateContainer}>
          {dayArray.map(day => renderCalculateCircle(day, true))}
        </div>

        <div style={isMobile ? styles.priceMobileItem : styles.priceItem}>
          <div style={styles.priceTitle}>商品市價</div>
          <div style={styles.priceInputContainer}>
            <input
              type="number"
              style={isMobile ? styles.priceMobileInput : styles.priceInput}
              placeholder="僅接受$18,000以上(精品專櫃/設計師品牌)的商品喔！"
              value={uploadBag.originalPrice === null ? '' : uploadBag.originalPrice}
              onChange={(event) => {
                onModifiedData(uploadBag.uuid, [
                  { key: 'originalPrice', value: event.target.value },
                  { key: 'rentAmountPerDay', value: baseDayRentAmountCalculate(Number(event.target.value)) },
                ]);
                setOriginalPrice(Math.ceil(Number(event.target.value)));
              // Note: Cancel auto advise
              // setIsAutoRentDayPrice(true);
              // setIsDefaultRentDayPrice(false);
              }
            }
            />
            <div style={styles.priceHeader}>
              NT$
            </div>
          </div>
        </div>

        <div style={isMobile ? styles.priceMobileItem : styles.priceItem}>
          <div style={styles.priceTitle}>清洗價格</div>
          <div style={styles.washTextPrice}>
            請設定你需要的送洗金額。
            <img
              src={Assets.uploadMapIcon}
              alt=""
              style={styles.mapIcon}
            />
            <a
              href="https://www.google.com.tw/maps/search/%E6%B4%97%E8%A1%A3%E5%BA%97"
              target="_blank"
              rel="noreferrer"
              style={styles.washText}
            >
              尋找洗衣店
            </a>
          </div>
          <div style={styles.priceInputContainer}>
            <input
              type="number"
              style={isMobile ? styles.priceMobileInput : styles.priceInput}
              value={uploadBag.washAmount === null ? '' : uploadBag.washAmount}
              onChange={(event) => {
                if (event.target.value > 200) {
                  // eslint-disable-next-line no-alert
                  alert('清洗價格不可大於200');
                  return;
                }
                onModifiedData(uploadBag.uuid, [{ key: 'washAmount', value: event.target.value }]);
                setWashAmount(Number(uploadBag.washAmount));
              }}
            />
            <div style={styles.priceHeader}>
              NT$
            </div>
          </div>
        </div>
        <hr style={styles.divideLine} />
      </div>

    </React.Fragment>
  );

  const renderDepositBlock = () => (
    <React.Fragment>
      <div style={styles.infoTextContainerDeposit}>
        <h1 style={styles.stepTitle}>
          Deposit
        </h1>
        <span style={styles.stepText}>
          押金
        </span>
      </div>
      <hr style={styles.divideLine} />

      <div style={styles.needDepositSelect}>
        <input
          type="radio"
          name="isNeedDeposit"
          onChange={() => {
            onModifiedData(uploadBag.uuid, [{ key: 'deposit', value: 0 }]);
            setShowDepositInput(false);
          }}
        />
        <div style={styles.needDepositText}>
          無需支付押金
        </div>
      </div>

      <div style={styles.needDepositSelect}>
        <input
          type="radio"
          name="isNeedDeposit"
          onChange={() => {
            onModifiedData(uploadBag.uuid, [{ key: 'deposit', value: Math.ceil(originalPrice * 0.1) }]);
            setShowDepositInput(false);
          }}
        />
        <div style={styles.needDepositText}>
          收取押金(平台建議押金為商品市價的10%)
        </div>
      </div>

      <div style={styles.needDepositSelect}>
        <input
          type="radio"
          name="isNeedDeposit"
          onChange={() => setShowDepositInput(true)}
        />
        <div style={styles.needDepositText}>自訂押金</div>
        {
          showDepositInput ? (
            <div style={styles.priceInputContainer}>
              <input
                type="number"
                style={styles.priceInput}
                value={uploadBag.deposit === null ? '' : uploadBag.deposit}
                onChange={event => onModifiedData(uploadBag.uuid, [{ key: 'deposit', value: event.target.value }])}
              />
              <div style={styles.priceHeader}>
                $
              </div>
            </div>
          ) : null
        }
      </div>

    </React.Fragment>
  );

  const renderShippingBlock = () => (
    <React.Fragment>
      <div style={isMobile ? styles.infoTextMobileContainer : styles.infoTextContainer}>
        <h1 style={styles.stepTitle}>
          Delivery Method
        </h1>
        <span style={styles.stepText}>
          出貨和收貨方式
        </span>
        <span style={styles.stepNoteText}>
          可複選＊
        </span>
      </div>
      <hr style={styles.divideLine} />
      <Default>
        <div style={styles.deliveryNote}>
          商品寄出/歸還時
          <br />
          以您選擇的方式寄出和回收商品
          <br />
          系統將會提供您在此輸入的收件資料給租借會員。
        </div>
      </Default>
      <Mobile>
        <div style={styles.deliveryNoteMobile}>
          商品寄出/歸還時，
          以您選擇的方式寄出和回收商品，
          系統將會提供您在此輸入的收件資料給租借會員。
        </div>
      </Mobile>

      <div style={styles.memberInfoInputContainer}>
        <div style={isMobile ? styles.memberInputMobileContainer : styles.memberInputContainer}>
          <input
            type="checkbox"
            style={styles.autoInputRecipientCheckbox}
            defaultChecked={isUpdateMemberInfo}
            onChange={event => onIsUpdateMemberInfoChanged(event.target.checked)}
          />
          <div style={styles.stepText}>收件資料更新至會員資料</div>
        </div>
      </div>

      <div style={isMobile ? styles.shippingMobileContainer : styles.shippingContainer}>
        <div style={isMobile ? styles.shippingBlockMobile : styles.shippingBlock}>
          <div style={styles.shippingIconContainer}>
            <div>
              <img
                src={Assets.memberShippingIcon}
                alt=""
                style={styles.shippingIcon}
              />
              <div style={styles.shippingMethodText}>
                寄送到府 收件/歸還
              </div>
              <br />
              <input
                type="checkbox"
                defaultChecked={isPostReceive}
                style={styles.deliverClick}
                onChange={event => updateIsPostReceive(event.target.checked)}
              />
            </div>
            <div style={isMobile ? styles.shippingLenderMobileInfo : styles.shippingLenderInfo}>
              {renderInputBlock('姓名', recipientName, 'recipientName')}
              {renderInputBlock('手機', recipientPhone, 'recipientPhone')}
              {renderInputBlock('收件地址', recipientAddress, 'recipientAddress')}
            </div>
          </div>
        </div>

        <div style={isMobile ? styles.shippingBlockMobile : styles.shippingBlock}>
          <div style={styles.shippingIconContainer}>
            <div>
              <img
                src={Assets.memberShippingStoreIcon}
                alt=""
                style={styles.shippingIcon}
              />
              <div style={styles.shippingMethodText}>
                超商店到店
              </div>
              <br />
              <input
                type="checkbox"
                defaultChecked={isSevenElevenReceive}
                style={styles.deliverClick}
                onChange={event => updateIsSevenElevenReceive(event.target.checked)}
              />
            </div>
            <div style={isMobile ? styles.shippingLenderMobileInfo : styles.shippingLenderInfo}>
              {renderInputBlock('姓名', recipientName, 'recipientName')}
              {renderInputBlock('手機', recipientPhone, 'recipientPhone')}
              <div style={styles.memberDeliveryContainer}>
                <div style={isMobile ? styles.memberDeliveryMobileTitle : styles.memberDeliveryTitle}>選擇超商</div>
                <select
                  value={selectStore}
                  onChange={(event) => { onMemberValueChanged('selectStore', event); }}
                  style={isMobile ? styles.memberInputMobileBox : styles.memberInputBox}
                >
                  <option
                    key="0"
                    value="0"
                  />
                  <option
                    key="seven-Eleven"
                    value={shippingTypeId.sevenEleven}
                  >
                    7-11
                  </option>
                </select>
              </div>
              {renderInputBlock('門市名稱', sevenElevenMarket, 'sevenElevenMarket')}
            </div>
          </div>
        </div>
      </div>

      <div style={styles.marginBlock} />
      <div style={styles.infoTextContainerDelivery}>
        <h1 style={styles.stepTitle}>
          Delivery
        </h1>
        <span style={styles.stepText}>
          運送費用
        </span>
      </div>
      <hr style={styles.divideLine} />

      <div style={styles.needDepositSelect}>
        <input
          type="radio"
          name="isNeedDeposit"
          defaultChecked
          value={false}
          onChange={event => onModifiedData(uploadBag.uuid, [{ key: 'isNeedDeliveryFree', value: event.target.value }])}
        />
        <div style={styles.needDepositText}>
          會員自由挑選運送方式（出租者和租借者各自負擔單次運費）
        </div>
      </div>
    </React.Fragment>
  );

  const renderManagementBlock = () => (
    <React.Fragment>
      <div style={isMobile ? styles.infoTextMobileContainer : styles.infoTextContainer}>
        <h1 style={styles.stepTitle}>
          Product Management
        </h1>
        <span style={styles.stepText}>
          商品管理
        </span>
      </div>
      <hr style={styles.divideLine} />

      <div style={isMobile ? styles.shippingMobileContainer : styles.shippingContainer}>
        <div style={isMobile ? styles.shippingBlockMobile : styles.shippingBlock}>
          <div style={isMobile ? styles.opManagementIconMobileContainer : styles.opManagementIconContainer}>
            <div style={isMobile ? styles.managementIconMobileContainer : styles.managementIconContainer}>
              <img
                src={Assets.OPMangerIcon}
                alt=""
                style={styles.opMangerIcon}
              />
              <div style={styles.shippingMethodText}>
                OP管理
              </div>
              <br />
              <input
                type="radio"
                defaultChecked={uploadBag.isOhPhireManage}
                style={styles.deliverClick}
                onChange={() => onModifiedData(uploadBag.uuid, [{ key: 'isOhPhireManage', value: true }])}
                name="isOhPhireManage"
              />
            </div>

            <div style={isMobile ? styles.isOhPhireManageTextMobileContainer : styles.isOhPhireManageTextContainer}>
              <div style={styles.isOhPhireTriangle} />
              <div style={styles.mangerTextContainer}>
                <div style={styles.attachmentSortItem}>
                  <Icon
                    style={styles.attachmentIcon}
                    type="check-circle"
                    theme="filled"
                  />
                  <div>
                    閃電出貨
                  </div>
                </div>
                <div style={styles.attachmentMidItem}>
                  <Icon
                    style={styles.attachmentIcon}
                    type="check-circle"
                    theme="filled"
                  />
                  <div>
                    基礎清潔
                  </div>
                </div>
              </div>
              <div style={styles.mangerTextContainer}>
                <div style={styles.attachmentSortItem}>
                  <Icon
                    style={styles.attachmentIcon}
                    type="check-circle"
                    theme="filled"
                  />
                  <div>
                    出貨管理
                  </div>
                </div>
                <div style={styles.attachmentMidItem}>
                  <Icon
                    style={styles.attachmentIcon}
                    type="check-circle"
                    theme="filled"
                  />
                  <div>
                    專業鑑定
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={isMobile ? styles.shippingBlockMobile : styles.shippingBlock}>
          <div style={isMobile ? styles.opManagementIconMobileContainer : styles.opManagementIconContainer}>
            <div style={isMobile ? styles.managementIconMobileContainer : styles.managementIconContainer}>
              <img
                src={Assets.selfManagerIcon}
                alt=""
                style={styles.opMangerIcon}
              />
              <div style={styles.shippingMethodText}>
                自行管理
              </div>
              <br />
              <input
                type="radio"
                defaultChecked={!uploadBag.isOhPhireManage}
                style={styles.deliverClick}
                onChange={() => onModifiedData(uploadBag.uuid, [{ key: 'isOhPhireManage', value: false }])}
                name="isOhPhireManage"
              />
            </div>
            <div style={isMobile ? styles.isOhPhireManageTextMobileContainer : styles.isOhPhireManageTextContainer}>
              <div style={styles.isOhPhireTriangle} />
              <div style={styles.attachmentItem}>
                <Icon
                  style={styles.attachmentIcon}
                  type="check-circle"
                  theme="filled"
                />
                <div>
                  需提前一週下單
                </div>
              </div>
              <div style={styles.attachmentItem}>
                <Icon
                  style={styles.attachmentIcon}
                  type="check-circle"
                  theme="filled"
                />
                <div>
                  由收益扣除額外運費
                </div>
              </div>
              <div style={styles.attachmentItem}>
                <Icon
                  style={styles.attachmentIcon}
                  type="check-circle"
                  theme="filled"
                />
                <div>
                  精品需送平台檢驗代為出貨
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );


  const renderSubmitOrEditButton = () => {
    if (!isEdit) {
      return true;
    }
    if (isDraft) {
      return true;
    }
    return false;
  };

  const renderStepsList = data => (
    <React.Fragment key={data.text}>
      <div
        onClick={() => { setStep(data.step); }}
        style={step === data.step ? styles.stepTextContainer : styles.stepTextDisableContainer}
        onKeyPress={() => { setStep(data.step); }}
        role="button"
        tabIndex="0"
      >
        <h1 style={step === data.step ? styles.stepTitle : styles.stepTitleDisable}>
          {data.title}
        </h1>
        <span style={styles.stepText}>
          {data.text}
        </span>
      </div>
      <hr style={step === data.step ? styles.divideLine : styles.divideLineDisable} />
    </React.Fragment>
  );

  const renderStepsListMobile = data => (
    <div
      onClick={() => { setStep(data.step); }}
      style={step === data.step ? styles.stepTextMobileContainer : styles.stepTextMobileDisableContainer}
      onKeyPress={() => { setStep(data.step); }}
      role="button"
      tabIndex="0"
      key={data.text}
    >
      <h1 style={step === data.step ? styles.stepTitleMobile : styles.stepTitleDisableMobile}>
        {data.title}
      </h1>
      <hr style={step === data.step ? styles.divideLineMobile : styles.divideLineMobileDisable} />
      <div style={styles.stepText}>
        {data.text}
      </div>
    </div>
  );

  const uploadPicNoteSlider = () => (
    <div style={styles.sliderContainer}>
      <Slider
        speed={1000}
        slidesToShow={1}
        slidesToScroll={1}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
      >
        <div>
          <img
            alt=""
            src={Assets.uploadPicNote01}
            style={styles.uploadNotePic}
          />
        </div>
        <div>
          <img
            alt=""
            src={Assets.uploadPicNote02}
            style={styles.uploadNotePic}
          />
        </div>
        <div>
          <img
            alt=""
            src={Assets.uploadPicNote03}
            style={styles.uploadNotePic}
          />
        </div>
        <div>
          <img
            alt=""
            src={Assets.uploadPicNote04}
            style={styles.uploadNotePic}
          />
        </div>
      </Slider>
    </div>
  );

  const mobilePreviewSlider = () => (
    <div style={styles.previewContainer}>
      <Slider
        speed={1000}
        slidesToShow={1}
        slidesToScroll={1}
        nextArrow={(
          <NextArrow
            isPreview
          />
        )}
        prevArrow={(
          <PrevArrow
            isPreview
          />
        )}
      >
        {uploadPicData.map(item => (
          <div key={item.type}>
            <img
              alt=""
              src={R.pathOr('', [item.type, 'uri'], uploadBag)}
              style={styles.preViewPic}
            />
          </div>
        ))}
      </Slider>
    </div>
  );

  const renderUploadPicBlock = () => (
    <div style={isMobile ? styles.picMobileContainer : styles.picContainer}>
      <div style={styles.picContentContainer}>
        <div style={styles.stepTextContainer}>
          <h1 style={styles.stepTitle}>
            Photos
          </h1>
          <span style={styles.stepText}>
            上傳照片
          </span>
        </div>

        <Mobile>
          {step === 1 ? uploadPicNoteSlider() : null}
          {mobilePreviewSlider()}
        </Mobile>

        <div style={isMobile ? styles.uploadPicMobileContainer : styles.uploadPicContainer}>
          {uploadPicData.map(item => (
            renderUploadPicButton(
              item.picTypeId,
              item.type,
            )
          ))}
        </div>

        <Default>
          {uploadPicNoteSlider()}
        </Default>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Modal
        visible={isShowRentDayHint}
        closable
        onCancel={() => { setIsShowRentDayHint(false); }}
        footer={(
          <div>
            <Button
              type="button"
              onClick={() => { setIsShowRentDayHint(false); }}
              text="我瞭解了"
              style={{
                width: '100px',
                height: '40px',
              }}
            />
          </div>
        )}
      >
        小提醒！出租金額已超出平台平均出租價格，建議調降以提升出租率！
      </Modal>
      <div style={{ ...styles.mainContainer, ...mobileMainContainerStyle }}>
        <Default>
          <div style={styles.stepContainer}>
            {stepsData.map(data => renderStepsList(data))}
          </div>
        </Default>
        <Mobile>
          <div style={styles.stepMobileContainer}>
            {stepsData.map(data => renderStepsListMobile(data))}
          </div>
        </Mobile>

        <Default>
          {renderUploadPicBlock()}
        </Default>

        <div style={{ ...styles.infoContainer, ...mobileContainerStyle }}>
          { step === 1 ? (
            <React.Fragment>
              {renderProductInfoContent()}
              <div style={styles.productInfoDetailContainer}>
                {renderProductInfoDetailContent()}
              </div>
              {renderDescriptionBlock()}
            </React.Fragment>
          ) : null}

          { step === 2 ? (
            <React.Fragment>
              {renderPriceContent()}
              <div style={styles.marginBlock} />
              {renderDepositBlock()}
            </React.Fragment>
          ) : null}

          { step === 3 ? (
            <React.Fragment>
              {renderShippingBlock()}
            </React.Fragment>
          ) : null}

          { step === 4 ? (
            <React.Fragment>
              {renderManagementBlock()}
            </React.Fragment>
          ) : null}

          <Mobile>
            {renderUploadPicBlock()}
          </Mobile>
        </div>
      </div>
      <div style={isMobile ? styles.buttonMobileContainer : styles.buttonContainer}>
        {step === 4 ? (
          <button
            style={isMobile ? styles.nextStepMobileButton : styles.nextStepButton}
            onClick={() => (renderSubmitOrEditButton() ? onSubmit() : onChangeCommodity())}
            type="button"
          >
            {renderSubmitOrEditButton() ? '審核發布' : '送出編輯'}
          </button>
        ) : (
          <button
            style={isMobile ? styles.nextStepMobileButton : styles.nextStepButton}
            onClick={() => { setStep(step + 1); }}
            type="button"
          >
            下一步
          </button>
        )}
        {
          renderSubmitOrEditButton() ? (
            <button
              style={isMobile ? styles.saveMobileButton : styles.saveButton}
              onClick={() => { onSaveDraft(); }}
              type="button"
            >
              儲存草稿
            </button>
          ) : null
        }

      </div>
    </React.Fragment>
  );
};

UploadCommodityComponent.propTypes = {
  tags: PropTypes.array,
  uploadBag: PropTypes.object,
  onModifiedData: PropTypes.func.isRequired,
  itemMobileContainerStyle: PropTypes.object,
  onSubmit: PropTypes.func,
  onSaveDraft: PropTypes.func,
  onMemberValueChanged: PropTypes.func,
  recipientName: PropTypes.string,
  recipientPhone: PropTypes.string,
  recipientAddress: PropTypes.string,
  isUpdateMemberInfo: PropTypes.bool,
  onIsUpdateMemberInfoChanged: PropTypes.func,
  sevenElevenMarket: PropTypes.string,
  isPostReceive: PropTypes.bool,
  isSevenElevenReceive: PropTypes.bool,
  updateIsPostReceive: PropTypes.func,
  updateIsSevenElevenReceive: PropTypes.func,
  onChangeCommodity: PropTypes.func,
  isDraft: PropTypes.bool,
  isEdit: PropTypes.bool,
  selectStore: PropTypes.string,
  mobileContainerStyle: PropTypes.object,
  mobileMainContainerStyle: PropTypes.object,
  isMobile: PropTypes.bool,
  onChangeToBuy: PropTypes.func.isRequired,
  rentType: PropTypes.number,
};

UploadCommodityComponent.defaultProps = {
  tags: [],
  uploadBag: {},
  itemMobileContainerStyle: {},
  onSubmit: () => {},
  onSaveDraft: () => {},
  recipientName: '',
  recipientPhone: '',
  recipientAddress: '',
  isUpdateMemberInfo: true,
  onMemberValueChanged: () => {},
  onIsUpdateMemberInfoChanged: () => {},
  sevenElevenMarket: '',
  selectStore: '',
  isPostReceive: false,
  isSevenElevenReceive: false,
  updateIsPostReceive: () => {},
  updateIsSevenElevenReceive: () => {},
  onChangeCommodity: () => {},
  isDraft: false,
  isEdit: false,
  mobileContainerStyle: {},
  mobileMainContainerStyle: {},
  isMobile: false,
  rentType: 1,
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  isPreview: PropTypes.bool,
};

PrevArrow.defaultProps = {
  onClick: () => {},
  isPreview: false,
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
  isPreview: PropTypes.bool,
};

NextArrow.defaultProps = {
  onClick: () => {},
  isPreview: false,
};

export {
  UploadCommodityComponent,
  EmptyUploadCommodityComponent,
};
