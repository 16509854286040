import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd/lib/index';
import { Link } from '@reach/router';
import update from 'immutability-helper/index';
import * as R from 'ramda';
import FollowComponent from '../FollowComponent';
import AvatarComponent from '../AvatarComponent';
import Default from '../RWDComponent/Default';
import Mobile from '../RWDComponent/Mobile';
import Type from '../../constants/Type';
import Paths from '../../routes/Paths';

const styles = {
  mainContainer: {
    width: '100%',
    height: 'fit-content',
    backgroundColor: 'white',
    padding: 52,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  leftContainer: {
    width: '20%',
    minWidth: '120px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'row',
    height: 'fit-content',
  },
  middleContainer: {
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rightContainer: {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 50,
  },
  follow: {
    width: 155,
    height: 33,
    backgroundColor: 'white',
    color: '#BABABA',
    textAlign: 'center',
    padding: '5px 0px',
    borderRadius: 20,
    outline: 'none',
    border: '1px solid #BABABA',
    cursor: 'pointer',
    marginTop: 5,
  },
  following: {
    width: 155,
    height: 33,
    backgroundColor: '#AE4200',
    color: 'white',
    textAlign: 'center',
    padding: '5px 0px',
    borderRadius: 20,
    outline: 'none',
    cursor: 'pointer',
    marginTop: 5,
  },
  bagInfoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
    borderBottom: '1px solid #F1F2F2',
    marginBottom: 30,
  },
  designer: {
    textAlign: 'left',
    fontSize: 16,
  },
  bagStyle: {
    marginRight: 10,
    fontSize: 25,
  },
  bagStyleMobile: {
    marginLeft: 0,
    fontSize: 20,
    fontFamily: 'Baskerville-Old-Facef',
    color: 'black',
  },
  infoItemContainer: {
    height: 'fit-content',
    padding: '3px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  infoItemTitle: {
    width: 150,
    textAlign: 'left',
    fontWeight: 500,
    letterSpacing: 0.5,
  },
  infoItemContent: {
    width: 'fit-content',
    textAlign: 'left',
    fontWeight: 200,
    color: 'black',
    marginLeft: '60px',
  },
  infoItemMobileContent: {
    width: 'fit-content',
    textAlign: 'left',
    fontWeight: 200,
    color: 'black',
  },
  itemContainer: {
    padding: '15px 0px 0px 0px',
    height: 'auto',
    width: '100%',
  },
  icon: {
    fontSize: 20,
    width: 20,
    height: 20,
  },
  subTitle: {
    textAlign: 'start',
    margin: '10px 0px',
    height: 'fit-content',
  },
  subTitleMobile: {
    textAlign: 'start',
    margin: '10px 0px',
    height: 'fit-content',
  },
  subTitleHidden: {
    display: 'none',
  },
  divider: {
    height: 2,
    width: '100%',
    backgroundColor: '#E1E1E1',
    margin: '30px 0px',
  },
  itemUpperContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 15,
  },
  title: {
    width: '100%',
    textAlign: 'start',
    fontSize: 15,
    color: 'black',
  },
  titleMobile: {
    marginLeft: 0,
    width: '100%',
    textAlign: 'start',
    fontSize: 15,
    color: 'black',
  },
  anchor: {
    width: 20,
    height: 20,
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    cursor: 'pointer',
    marginRight: '30px',
  },
  backgroundColor: {
    backgroundColor: '#F8F8F8',
  },
  ownerName: {
    marginTop: 15,
    fontFamily: 'Baskerville, serif',
    textAlign: 'center',
    fontSize: 30,
    color: 'black',
  },
  ownerLevel: {
    textAlign: 'center',
    fontSize: 15,
    color: '#808285',
    marginBottom: 10,
  },
  infoItemMobileContainer: {
    width: '100%',
    margin: '5px 0px',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'fit-content',
    marginBottom: '50px',
  },
};

const BagOwnerPanelComponent = ({
  data,
  mainStyle,
  isVerified,
}) => {
  const getName = tagCategoryId => R.pipe(
    R.pathOr([], ['tags']),
    R.filter(tag => tag.tagCategoryId === tagCategoryId),
    R.pathOr('', ['0', 'name']),
  )(data);

  const communityName = R.pathOr(null, ['owner', 'communityName'], data);
  const changedAvatarIcon = R.pathOr(null, ['owner', 'avatar', 'uri'], data);
  const commodityType = R.pathOr(1, ['commodityType', 'id'], data);

  const collapseVisible = {
    1: false,
    2: false,
  };
  // TODO: content need to be supplied by Oh!Phire client
  const collapseData = [
    {
      title: '付款方式',
      subTitle: (
        <div>
          <b>租借 </b>
          - 信用卡一次付清
          <br />
          <b>購買 </b>
          - 信用卡一次付清
          <br />
          信用卡分期付款(3期/6期）
        </div>
      ),
      id: '1',
    },
    {
      title: '退換貨須知',
      subTitle:
        (
          <div>
            【購買精品包包】
            <br />
            - 皆享七天鑑賞期
            <br />
            【租借服務】
            <br />
            【會員二手服飾】
            <br />
            - 恕不適用
          </div>
        ),
      id: '2',
    },
    {
      title: '如何租借',
      subTitle:
        (
          <div>
            1. 點選天數，選擇收貨首日
            <br />
            2. 預刷押金及支付租借兩階段付款
            <br />
            3. 點選【我要歸還】自由選擇寄送方式
            <br />
            4. 歸還成功，押金刷退
          </div>
        ),
      id: '3',
    },
  ];

  const [selectedItem, setSelectedItem] = useState(collapseVisible);
  const onClickAnchor = (id) => {
    setSelectedItem(update(selectedItem, { $toggle: [id] }));
  };
  const renderItem = item => (
    <div key={item.id} style={styles.itemContainer}>
      <div style={styles.itemUpperContainer}>
        <Default>
          <div style={styles.title}>
            {item.title}
          </div>
        </Default>
        <Mobile>
          <div style={styles.titleMobile}>
            {item.title}
          </div>
        </Mobile>
        <button
          onClick={() => onClickAnchor(item.id)}
          type="button"
          style={styles.anchor}
        >
          {selectedItem[item.id]
            ? <Icon type="up" />
            : <Icon type="down" />
          }
        </button>
      </div>
      {
        selectedItem[item.id]
          ? (
            <React.Fragment>
              <Default>
                <div style={styles.subTitle}>{item.subTitle}</div>
              </Default>
              <Mobile>
                <div style={styles.subTitleMobile}>{item.subTitle}</div>
              </Mobile>
            </React.Fragment>

          )
          : null
      }
      <div style={styles.divider} />
    </div>
  );

  const renderInfoItem = (title, content) => (
    <React.Fragment>
      <Default>
        <div style={styles.infoItemContainer}>
          <div style={styles.infoItemTitle}>{title}</div>
          <div style={styles.infoItemContent}>{content}</div>
        </div>
      </Default>
      <Mobile>
        <div style={styles.infoItemMobileContainer}>
          <div style={styles.infoItemTitle}>{title}</div>
          <div style={styles.infoItemMobileContent}>{content}</div>
        </div>
      </Mobile>
    </React.Fragment>
  );

  const renderNewLevel = () => {
    const newLevel = R.pathOr(null, ['newLevel'], data);
    if (newLevel > 9) {
      return '全新';
    }
    return newLevel ? `${newLevel}成新` : null;
  };
  const renderBagDetail = (
    <React.Fragment>
      {renderInfoItem('COLOR 商品顏色', getName(3))}
      {commodityType === Type.commodityTypeId.clothes ? null : renderInfoItem('CONDITION 狀況評估 ', renderNewLevel())}
      {renderInfoItem('CATEGORY 分類', getName(2))}
      {commodityType !== Type.commodityTypeId.clothes && (getName(6) === '' || getName(6) === '包包')
        ? (renderInfoItem('SIZE 尺寸',
          `長${R.pathOr('0', ['height'], data)}cm/寬${R.pathOr('0', ['width'], data)}cm/高${R.pathOr('0', ['depth'], data)}cm`))
        : (renderInfoItem('SIZE 尺寸',
          `${R.pathOr('', ['sizeStandard'], data)}${R.pathOr('', ['sizeNumber'], data)}`))
          }
    </React.Fragment>
  );
  return (
    <div style={{ ...styles.mainContainer, ...mainStyle }}>
      <Default>
        <div style={styles.leftContainer}>
          <Link to={`${Paths.MEMBER}/${communityName}`}>
            <div style={styles.profileContainer}>
              <AvatarComponent
                ownerChangedAvatar={changedAvatarIcon}
                isVerified={isVerified}
              />
              <div style={styles.ownerName}>{R.pathOr('', ['owner', 'name'], data)}</div>
              {/* ToDo: Future Development */}
              {/* <div style={styles.ownerLevel}>頂尖收藏家</div> */}
              <FollowComponent
                targetId={R.pathOr('', ['owner', 'id'], data)}
              />
            </div>
          </Link>
        </div>
        <div style={styles.middleContainer}>
          <div style={styles.bagInfoContainer}>
            <div style={styles.bagStyle}>{getName(1)}</div>
            <div style={styles.designer}>{R.pathOr('', ['name'], data)}</div>
          </div>
          {renderBagDetail}
        </div>
        <div style={styles.rightContainer}>
          {collapseData.map(item => renderItem(item))}
        </div>
      </Default>
      <Mobile>
        <div>
          <div style={styles.bagStyleMobile}>{getName(1)}</div>
          <div style={styles.designer}>{R.pathOr('', ['name'], data)}</div>
        </div>
        <div style={styles.divider} />
        {renderBagDetail}
        <div style={styles.divider} />
        {collapseData.map(item => renderItem(item))}
      </Mobile>
    </div>
  );
};


BagOwnerPanelComponent.propTypes = {
  data: PropTypes.object,
  mainStyle: PropTypes.object,
  isVerified: PropTypes.bool,
};

BagOwnerPanelComponent.defaultProps = {
  data: {},
  mainStyle: {},
  isVerified: false,
};


export default BagOwnerPanelComponent;
