import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd/lib/index';
import { Colors } from '../../../../constants/Color';
import Button from '../../../../components/Button';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
/* This is unreleased function will use it but now just commented them */
// import Assets from '../../../../constants/Assets';
import envConfig from '../../../../lib/configEnv';

const styles = {
  mainContainer: {
    width: '70%',
  },
  mainMobileContainer: {
    width: '90%',
  },
  featureContainer: {
    width: '100%',
    height: 'auto',
    minHeight: '200px',
    background: 'white',
    boxShadow: '4px 4px 12px -2px rgba(20%,20%,40%,0.5)',
    position: 'relative',
    display: 'flex',
    margin: '40px auto',
  },
  titleContainer: {
    width: '50%',
    height: 'auto',
    position: 'relative',
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '20%',
    transform: 'translate(0%, -50%)',
    fontSize: '14px',
    color: 'black',
    paddingRight: '10px',
    minWidth: '60px',
    wordBreak: 'break-all',
  },
  collectionContainer: {
    width: '80%',
    height: 'auto',
    margin: '50px 0',
  },
  memberGrade: {
    width: '35px',
    height: '35px',
  },
  collector: {
    fontSize: '21px',
    color: Colors.introRightTitle,
    marginRight: '5px',
    display: 'flex',
    marginBottom: '32px',
  },
  collectorTitle: {
    margin: '9px 0 0 5px',
  },
  LevelContainer: {
    height: '10px',
    position: 'relative',
    marginBottom: '20px',
  },
  processLine: {
    width: '40%',
    border: `2px solid ${Colors.introRightTitle}`,
    position: 'absolute',
    top: '0px',
  },
  totalLine: {
    width: '80%',
    border: `2px solid ${Colors.separateLine}`,
    position: 'absolute',
    top: '0px',
  },
  startContainer: {
    width: 'auto',
    position: 'absolute',
    top: '-10px',
    right: '8%',
  },
  memberStar: {
    width: '20px',
    height: '20px',
    margin: '0 2px',
  },
  note: {
    margin: '27px 0',
    color: '#BEBEBE',
  },
  hintMember: {
    textDecoration: 'underline',
  },
  hintIcon: {
    marginRight: '10px',
  },
  numberText: {
    color: Colors.introRightTitle,
    fontWeight: 'semi-old',
  },
  content: {
    width: '45%',
    height: 'auto',
    margin: 'auto 0',
    fontSize: '14px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    position: 'absolute',
    right: '30px',
    top: '40%',
    color: 'grey',
    background: 'white',
    border: `1.5px solid ${Colors.introRightTitle}`,
    maxWidth: '160px',
    minWidth: '80px',
    width: '30%',
    height: '50px',
    fontSize: '12px',
  },
  memberPoint: {
    width: '32px',
    height: '32px',
    marginRight: '5px',
  },
  point: {
    color: Colors.introRightTitle,
    fontWeight: 'bold',
    fontSize: '14px',
    paddingTop: '8px',
  },
  pointDate: {
    fontSize: '14px',
    color: '#BEBEBE',
    marginLeft: '10px',
    paddingTop: '8px',
  },
  mobileTitle: {
    fontsize: '10px',
    position: 'absolute',
    top: '50%',
    left: '20%',
    transform: 'translate(0%, -50%)',
    color: '#58595B',
    paddingRight: '80px',
    minWidth: '60px',
    wordBreak: 'break-all',
  },
};

const MemberOtherFeatureComponent = ({
  ReduceDepositUserNumber,
  onClickReduceDeposit,
  onShowMemberCouponComponent,
  memberCouponCount,
  onClickWithDrawMoney,
  withdrawNumber,
}) => {
  const content = (
    <React.Fragment>
      {/* This block is TODO just comment it in current.  */}
      {/* <div style={styles.featureContainer}>
        <div style={styles.titleContainer}>
          <div style={styles.title}>會員級別 </div>
        </div>
        <div style={styles.collectionContainer}>
          <div style={styles.collector}>
            <img src={Assets.memberGrade} style={styles.memberGrade} alt="" />
            <div style={styles.collectorTitle}>頂尖收藏家</div>
          </div>
          <div style={styles.LevelContainer}>
            <div style={styles.processLine} />
            <div style={styles.totalLine} />
            <div style={styles.startContainer}>
              <img src={Assets.memberStar} style={styles.memberStar} alt="" />
              <img src={Assets.memberStar} style={styles.memberStar} alt="" />
              <img src={Assets.memberStar} style={styles.memberStar} alt="" />
            </div>
          </div>
          <div style={styles.collectorTextContainer}>
            <div>
                截至2020年1月31日止，您於平台上架的包款總價已達 NT
              <span style={styles.numberText}>20</span>
                個，目前會員級別為品味收藏家
              <br />
                若您在2021年1月31日前，於平台上架的包款總價再多 NT
              <span style={styles.numberText}>NT$12,345</span>
                ，即可升等為頂尖收藏家
            </div>

            <div style={styles.note}>
              <div>*金額計算依包款實際上架期間為準 </div>
            </div>

            <div style={styles.hintMember}>
              <Icon style={styles.hintIcon} type="question-circle" theme="filled" />
            了解會員級別制度
            </div>
          </div>
        </div>
      </div> */}

      <div style={styles.featureContainer}>
        <div style={styles.titleContainer}>
          <div style={styles.title}>我的COUPON券</div>
        </div>
        <div style={styles.content}>
          <span style={styles.numberText}>{memberCouponCount}</span>
          張
          <Button
            style={styles.button}
            text="查看"
            onClick={() => onShowMemberCouponComponent()}
          />
        </div>
      </div>

      {/* TODO: comment the feature in prod */}
      { envConfig.env === 'prod' ? null : (
        <div style={styles.featureContainer}>
          <div style={styles.titleContainer}>
            <Default>
              <div style={styles.title}>
                免押金清單
                <Icon style={styles.hintIcon} type="question-circle" theme="filled" />
              </div>
            </Default>
            <Mobile>
              <div style={styles.mobileTitle}>
                免押金清單
                <Icon style={styles.hintIcon} type="question-circle" theme="filled" />
              </div>
            </Mobile>
          </div>
          <div style={styles.content}>
            <span style={styles.numberText}>{ReduceDepositUserNumber}</span>
            位
            <Button
              style={styles.button}
              text="編輯"
              onClick={() => { onClickReduceDeposit(true); }}
            />
          </div>
        </div>
      )}
      {/* This block is TODO just comment it in current.  */}
      {/* <div style={styles.featureContainer}>
        <div style={styles.titleContainer}>
          <div style={styles.title}>
            我的奢華金
            <Icon style={styles.hintIcon} type="question-circle" theme="filled" />
          </div>
        </div>
        <div style={styles.content}>
          <img src={Assets.memberPoint} style={styles.memberPoint} alt="" />
          <div style={styles.numberText}>2,234 </div>
          <div style={styles.point}>
              POINTS
          </div>
          <div style={styles.pointDate}>
              點數到期日 2020/11/31
          </div>
        </div>
        <Button
          style={styles.button}
          text="查看奢華金"
        />
      </div> */}

      {/* TODO: This block is TODO just comment it in current.  */}
      <div style={styles.featureContainer}>
        <div style={styles.titleContainer}>
          <div style={styles.title}>
            提領款項
          </div>
        </div>
        <div style={styles.content}>
          <span style={styles.numberText}>{withdrawNumber}</span>
          筆
          <Button
            style={styles.button}
            text="查看"
            onClick={onClickWithDrawMoney}
          />
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          {content}
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          {content}
        </div>
      </Mobile>
    </React.Fragment>

  );
};

MemberOtherFeatureComponent.propTypes = {
  memberInfo: PropTypes.shape({}),
  ReduceDepositUserNumber: PropTypes.number,
  onClickReduceDeposit: PropTypes.func.isRequired,
  memberCouponCount: PropTypes.number,
  onShowMemberCouponComponent: PropTypes.func.isRequired,
  onClickWithDrawMoney: PropTypes.func.isRequired,
  withdrawNumber: PropTypes.number,
};

MemberOtherFeatureComponent.defaultProps = {
  memberInfo: {},
  ReduceDepositUserNumber: 0,
  memberCouponCount: 0,
  withdrawNumber: 0,
};

export default MemberOtherFeatureComponent;
