import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShippingInfoPage from './ShippingInfoPage';
import MemberActionCreators from '../../../Member/ActionCreator';


import {
  reduxStatusSelector,
  reduxErrorSelector,
  memberDataSelector,
} from '../../selector';

const ShippingInfoPageContainer = ({
  reduxError,
  reduxStatus,
  actions: {
    updateMemberInfoFlowRequest,
  },
  memberData,
}) => {
  const originRecipientName = R.pathOr('', ['user', 'recipientName'], memberData);
  const originRecipientPhone = R.pathOr('', ['user', 'recipientPhone'], memberData);
  const originRecipientZipCode = R.pathOr('', ['user', 'recipientZipCode'], memberData);
  const originRecipientAddress = R.pathOr('', ['user', 'recipientAddress'], memberData);
  const originSelectStore = R.pathOr('0', ['user', 'selectStore'], memberData);
  const originSevenElevenMarket = R.pathOr('', ['user', 'sevenElevenMarket'], memberData);
  const [recipientName, setRecipientName] = useState(originRecipientName);
  const [recipientPhone, setRecipientPhone] = useState(originRecipientPhone);
  const [recipientZipCode, setRecipientZipCode] = useState(originRecipientZipCode);
  const [recipientAddress, setRecipientAddress] = useState(originRecipientAddress);
  const [selectStore, setSelectStore] = useState(originSelectStore);
  const [sevenElevenMarket, setSevenElevenMarket] = useState(originSevenElevenMarket);
  const [changeInfo, setChangeInfo] = useState({});


  const handelMemberInfoChange = () => {
    updateMemberInfoFlowRequest(changeInfo);
  };

  const handelInputValueChange = (event, key) => {
    switch (key) {
      case 'recipientName':
        setRecipientName(event.target.value);
        setChangeInfo({
          ...changeInfo,
          ...{ recipientName: event.target.value },
        });
        break;
      case 'recipientPhone':
        setRecipientPhone(event.target.value);
        setChangeInfo({
          ...changeInfo,
          ...{ recipientPhone: event.target.value },
        });
        break;
      case 'recipientZipCode':
        setRecipientZipCode(event.target.value);
        setChangeInfo({
          ...changeInfo,
          ...{ recipientZipCode: event.target.value },
        });
        break;
      case 'recipientAddress':
        setRecipientAddress(event.target.value);
        setChangeInfo({
          ...changeInfo,
          ...{ recipientAddress: event.target.value },
        });
        break;
      case 'selectStore':
        setSelectStore(event.target.value);
        setChangeInfo({
          ...changeInfo,
          ...{ selectStore: event.target.value },
        });
        break;
      case 'sevenElevenMarket':
        setSevenElevenMarket(event.target.value);
        setChangeInfo({
          ...changeInfo,
          ...{ sevenElevenMarket: event.target.value },
        });
        break;
      case 'isPostReceive':
        setChangeInfo({
          ...changeInfo,
          ...{ isPostReceive: event.target.checked },
        });
        break;
      case 'isSevenElevenReceive':
        setChangeInfo({
          ...changeInfo,
          ...{ isSevenElevenReceive: event.target.checked },
        });
        break;
      default:
        break;
    }
  };

  return (
    <ShippingInfoPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      onInputValueChange={handelInputValueChange}
      onSubmitMemberInfoChange={handelMemberInfoChange}
      memberData={memberData}
      recipientName={recipientName}
      recipientPhone={recipientPhone}
      recipientZipCode={recipientZipCode}
      recipientAddress={recipientAddress}
      selectStore={selectStore}
      sevenElevenMarket={sevenElevenMarket}
    />
  );
};

ShippingInfoPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    updateMemberInfoFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  memberData: PropTypes.object.isRequired,
};

ShippingInfoPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  memberData: memberDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...MemberActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingInfoPageContainer);
