import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import RegisterPage from './RegisterPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
} from '../../selector';

const RegisterPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    resetStatus,
    registerFlowRequest,
    logInFlowRequest,
  },
  setIsShowRegisterPage,
}) => {
  useEffect(() => {
    resetStatus();
    return resetStatus;
  }, []);


  const handleRegister = (data) => {
    registerFlowRequest(data);
  };

  return (
    <RegisterPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      handleRegister={handleRegister}
      setIsShowRegisterPage={setIsShowRegisterPage}
      onLogin={logInFlowRequest}
    />
  );
};

RegisterPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    registerFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    logInFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  setIsShowRegisterPage: PropTypes.func,
};

RegisterPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  setIsShowRegisterPage: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterPageContainer);
