import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Link } from '@reach/router';
import Paths from '../../../../routes/Paths';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import AvatarComponent from '../../../../components/AvatarComponent';
import { FontSize } from '../../../../constants/FontSize';


const styles = {
  mainContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  upperContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  upperLeftContainer: {
    width: '30%',
    height: '300',
  },
  upperLeftMobileContainer: {
    width: '30%',
    height: 'auto',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  upperRightContainer: {
    width: '70%',
    margin: '10px 20px',
  },
  upperRightMobileContainer: {
    width: 'auto',
    margin: '10px 20px',
  },
  firstRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  secondRow: {
    width: '100%',
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  avatar: {
    fontSize: 225,
  },
  avatarMobile: {
    fontSize: 100,
    marginLeft: 0,
  },
  name: {
    fontSize: 34,
    color: '#070707',
    fontFamily: 'Baskerville, serif',
  },
  editPersonalProfile: {
    padding: '13px 0px',
    fontSize: FontSize.body,
    marginLeft: 45,
    color: 'black',
    width: 175,
    fontWeight: 100,
    height: 50,
    textAlign: 'center',
    border: '1px solid #F1F2F2',
    cursor: 'pointer',
    outline: 'none',
  },
  editFollowButtonMobileStyle: {
    width: 100,
    height: 40,
    marginLeft: 0,
    padding: '5px',
    margin: '0px 5px',
  },
  icon: {
    marginLeft: 5,
  },
  proLevel: {
    marginLeft: 5,
    color: '#808285',
    fontSize: FontSize.body,
    fontWeight: 100,
  },
  secondRowItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '33%',
  },
  secondRowItemMobile: {
    display: 'block',
    width: 'auto',
    textAlign: 'center',
  },
  count: {
    fontWeight: 600,
    fontSize: FontSize.body,
    color: '#070707',
  },
  unit: {
    fontWeight: 100,
    fontSize: FontSize.body,
    color: '#070707',
  },
  marginLeft5: {
    marginLeft: 5,
  },
  shareOutfit: {
    width: 175,
    height: 50,
    color: 'white',
    backgroundColor: '#AE4200',
    textAlign: 'center',
    padding: '13px',
    fontSize: FontSize.body,
    fontWeight: 100,
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
  },
  shareOutfitMobile: {
    width: 100,
    height: 40,
    color: 'white',
    backgroundColor: '#AE4200',
    textAlign: 'center',
    padding: '5px',
    fontSize: FontSize.body,
    fontWeight: 100,
    cursor: 'pointer',
    outline: 'none',
    margin: '0px 5px',
    border: 'none',
  },
  follow: {
    marginLeft: 50,
    width: 155,
    height: 33,
    backgroundColor: 'white',
    color: '#BABABA',
    textAlign: 'center',
    padding: '5px 0px',
    borderRadius: 20,
    outline: 'none',
    border: '1px solid #BABABA',
    cursor: 'pointer',
  },
  following: {
    marginLeft: 50,
    width: 155,
    height: 33,
    backgroundColor: '#AE4200',
    color: 'white',
    textAlign: 'center',
    padding: '5px 0px',
    borderRadius: 20,
    outline: 'none',
    cursor: 'pointer',
  },
  mobileButtonContainer: {
    display: 'flex',
  },
  passbaseIconStyle: {
    right: 20,
    bottom: 20,
    width: '57px',
    height: '57px',
  },
  passbaseIconMobileStyle: {
    right: 0,
    bottom: 5,
  },
  avatarContainerStyle: {
    width: 250,
    height: 250,
  },
  avatarContainerMobileStyle: {
    width: 100,
    height: 100,
  },
};

const MemberProfileHeader = ({
  isLogin,
  onShareOutfit,
  isMe,
  memberId,
  onToggleFollow,
  memberProfile,
}) => {
  const communityName = R.pathOr('', ['communityName'], memberProfile);
  const uri = R.pathOr('', ['uri'], memberProfile);
  const bags = R.pathOr([], ['bags'], memberProfile);
  const following = R.pathOr([], ['following'], memberProfile);
  const followers = R.pathOr([], ['followers'], memberProfile);
  const isVerified = R.pathOr(false, ['isVerified'], memberProfile);

  const renderFollowButton = () => {
    if (isLogin) {
      const isFollowing = R.filter(follower => Number(follower.userId) === Number(memberId), followers).length > 0;
      return (
        <button type="button" style={isFollowing ? styles.following : styles.follow} onClick={() => onToggleFollow()}>
          {isFollowing ? '追蹤中' : '追蹤'}
        </button>
      );
    }
    return null;
  };
  const headerInfoData = [
    {
      data: bags.length,
      text: '件商品',
    },
    {
      data: followers.length,
      text: '位追蹤者',
    },
    {
      data: following.length,
      text: '位追蹤中',
    },
  ];
  const renderHeaderInfo = (data, text, style) => (
    <div style={{ ...styles.secondRowItem, ...style }} key={text}>
      <div style={styles.count}>{data}</div>
      <div style={{ ...styles.unit, ...styles.marginLeft5 }}>{text}</div>
    </div>
  );
  const renderEditorFollowButton = (style) => {
    if (isMe) {
      return (
        <button type="button" style={{ ...styles.editPersonalProfile, ...style }}>
          <Link to={`${Paths.MEMBER}/${memberId}${Paths.EDIT}`}>
            編輯個人檔案
          </Link>
        </button>
      );
    }

    return renderFollowButton();
  };
  const renderHeaderBlocK = (
    <React.Fragment>
      <div style={styles.firstRow}>
        <div style={styles.name}>{communityName}</div>
        {/* TODO in feature */}
        {/* <Icon type="user" style={styles.icon} />
        <div style={styles.proLevel}>頂尖收藏家</div> */}
        <Default>
          {renderEditorFollowButton()}
        </Default>
      </div>
      <div style={styles.secondRow}>
        <Default>
          {headerInfoData.map(item => (renderHeaderInfo(item.data, item.text)))}
        </Default>
        <Mobile>
          {headerInfoData.map(item => (renderHeaderInfo(item.data, item.text, styles.secondRowItemMobile)))}
        </Mobile>
      </div>
      <div style={styles.unit}>{R.pathOr('', ['introduction'], memberProfile)}</div>
    </React.Fragment>
  );
  return (
    <div style={styles.mainContainer} key="baseContainer">
      <div style={styles.upperContainer}>
        <Default>
          <div style={styles.upperLeftContainer}>
            <Link to={`${Paths.MEMBER}/${communityName}`}>
              <AvatarComponent
                ownerChangedAvatar={uri}
                isVerified={isVerified}
                size={225}
                avatarStyle={styles.avatar}
                passbaseIconStyle={styles.passbaseIconStyle}
                avatarContainerStyle={styles.avatarContainerStyle}
              />
            </Link>
          </div>
          <div style={styles.upperRightContainer}>
            {renderHeaderBlocK}
          </div>
        </Default>
        <Mobile>
          <div style={styles.upperLeftMobileContainer}>
            <Link to={`${Paths.MEMBER}/${communityName}`}>
              <AvatarComponent
                ownerChangedAvatar={uri}
                isVerified={isVerified}
                size={100}
                avatarStyle={styles.avatarMobile}
                passbaseIconStyle={styles.passbaseIconMobileStyle}
                avatarContainerStyle={styles.avatarContainerMobileStyle}
              />
            </Link>
          </div>
          <div style={styles.upperRightMobileContainer}>
            {renderHeaderBlocK}
          </div>
        </Mobile>

      </div>
      <Mobile>
        <div style={styles.mobileButtonContainer}>
          {renderEditorFollowButton(isMe, styles.editFollowButtonMobileStyle)}
          {isMe && <button type="button" style={styles.shareOutfitMobile} onClick={() => onShareOutfit()}>分享穿搭</button>}
        </div>
      </Mobile>
      <Default>
        {isMe && <button type="button" style={styles.shareOutfit} onClick={() => onShareOutfit()}>分享穿搭</button>}
      </Default>
    </div>
  );
};

MemberProfileHeader.propTypes = {
  memberProfile: PropTypes.shape({}),
  memberId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onToggleFollow: PropTypes.func.isRequired,
  onShareOutfit: PropTypes.func.isRequired,
  isMe: PropTypes.bool,
  isLogin: PropTypes.bool,
};

MemberProfileHeader.defaultProps = {
  memberProfile: null,
  memberId: null,
  isMe: false,
  isLogin: false,
};

export default MemberProfileHeader;
