import React, { useState } from 'react';
import { Button, Icon, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as R from 'ramda';
import Uploader from '../../../ResourceUploader/components/Uploader';
import { typeId } from '../../../../constants/Type';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import { EarnPercent } from '../../../../constants/EarnPercent';
import { getRentBagOwnerEarnAmount } from '../../../../constants/RentBagAmountPerDayReference';

const styles = {
  mainContainer: {
    width: '100%',
    height: 'fit-content',
    padding: '40px 50px',
    borderBottom: '1px solid #DFDFDF',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  leftContainer: {
    width: '15%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    position: 'relative',
  },
  mobileTopContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    position: 'relative',
  },
  rightContainer: {
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  deleteIcon: {
    marginTop: 25,
    color: '#AE4200',
    fontSize: 30,
    position: 'absolute',
    right: '60px',
  },
  deleteIconMobile: {
    color: '#AE4200',
    fontSize: 30,
    position: 'absolute',
    right: '0px',
    top: '35%',
  },
  bag: {
    fontSize: 20,
    color: '#C4BEBE',
  },
  index: {
    marginLeft: 5,
    fontSize: 50,
    color: '#C4BEBE',
  },
  expandIcon: {
    outline: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    width: 20,
    height: 20,
    color: '#AE4200',
    position: 'absolute',
    fontSize: 30,
    left: 80,
    top: 15,
  },
  uploadItemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '30px 0px 20px 0px',
    borderBottom: '1px solid #DFDFDF',
  },
  itemTitleContainer: {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginRight: 50,
  },
  title: {
    fontFamily: 'Myriad Semibold',
    fontSize: 24,
    color: 'black',
  },
  titleHint: {
    fontFamily: 'Myriad Pro Semibold',
    fontSize: 15,
    marginLeft: 5,
    color: 'black',
  },
  subTitle: {
    fontFamily: 'Myriad Pro Semibold',
    fontSize: 15,
    color: 'black',
    fontWeight: '100',
  },
  button: {
    fontSize: 18,
    color: 'black',
    border: 'none',
    outline: 'none',
  },
  optionContainer: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  optionItemContainer: {
    width: '15%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  memberInputContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  itemOption: {
    color: '#070707',
    fontSize: 18,
    fontFamily: 'Microsoft JhengHei Bold',
  },
  itemOptionHint: {
    marginLeft: 15,
    color: '#070707',
    fontSize: 14,
    fontFamily: 'Microsoft JhengHei Bold',
  },
  selectionContainer: {
    height: 200,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'space-between',
    width: '70%',
    flexWrap: 'wrap',
  },
  selectionMobileContainer: {
    height: 'auto',
    alignItems: 'space-between',
    width: '100%',
  },
  incomeContainer: {
    width: '70%',
    height: 250,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  incomeMobileContainer: {
    width: '100%',
    height: 'auto',
    minHeight: '200px',
    alignItems: 'flex-start',
  },
  selectionItem: {
    marginTop: 10,
    marginRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '30%',
  },
  selectionItemMobile: {
    marginTop: 10,
    marginRight: 20,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  selectionTitle: {
    color: 'black',
    fontSize: 15,
  },
  selection: {
    height: 35,
    width: '100%',
    backgroundColor: 'transparent',
    border: '1px solid #E6E7E8',
    color: 'black',
    outline: 'none',
    padding: 5,
    cursor: 'pointer',
  },
  input: {
    height: 35,
    width: '100%',
    backgroundColor: 'transparent',
    border: '1px solid #E6E7E8',
    color: 'black',
    outline: 'none',
    borderRadius: 0,
    padding: 5,
    WebkitAppearance: 'none',
  },
  incomeInput: {
    width: 120,
  },
  uploaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  uploaderItem: {
    height: 150,
    padding: '10px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  uploaderItemMobile: {
    height: 'auto',
    padding: '10px 0px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  upload: {
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'black',
    height: 58,
    fontSize: 18,
    minWidth: 208,
    padding: '15px 0px',
    cursor: 'pointer',
  },
  uploadTitleContainer: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginRight: 60,
  },
  uploadTitle: {
    width: 100,
    fontFamily: 'Microsoft JhengHei Bold',
    fontSize: 16,
    color: 'black',
  },
  uploadSubTitle: {
    marginLeft: 10,
    fontSize: 14,
    color: 'black',
  },
  uploadImgThumbnail: {
    width: 150,
    height: 150,
    margin: '0px 20px',
    objectFit: 'contain',
  },
  descriptionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  descriptionTitle: {
    fontFamily: 'Microsoft JhengHei Bold',
    fontSize: 18,
    color: '#4E4C4C',
  },
  description: {
    marginTop: 10,
    width: '100%',
    height: 246,
    padding: 5,
    border: '1px solid #999999',
  },
  income: {
    height: '100%',
    width: '10%',
    textAlign: 'start',
    color: 'black',
  },
  incomeMobile: {
    height: 'auto',
    width: '100%',
    textAlign: 'start',
    color: 'black',
    marginTop: '20px',
  },
  amountPanel: {
    height: '100%',
    backgroundColor: 'white',
    width: '90%',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  amountPanelMobile: {
    height: 'auto',
    backgroundColor: 'white',
    width: '100%',
    display: 'block',
    padding: 0,
    alignItems: 'flex-start',
  },
  amountItemContainer: {
    width: '100%',
    height: 50,
    padding: '10px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  amountItemMobileContainer: {
    width: '100%',
    height: 'auto',
    display: 'block',
    padding: '10px 0px',
    alignItems: 'center',
  },
  ownerPriceHint: {
    width: '40%',
    textAlign: 'start',
    fontFamily: 'Microsoft JhengHei Bold',
    color: 'black',
    fontSize: 16,
  },
  nt: {
    marginLeft: 10,
    width: 60,
    textAlign: 'start',
    fontFamily: 'Microsoft JhengHei Bold',
    color: 'black',
  },
  ntMobile: {
    marginLeft: 0,
  },
  actualNt: {
    color: '#D2D0D0',
  },
  actualWidth: {
    width: 120,
  },
  earn: {
    color: '#AE4200',
  },
  memberInfoInputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  memberInputBox: {
    margin: '20px 20px 20px 0px',
    border: 'none',
    borderBottom: '1px black solid',
    outline: 'none',
  },
  addressInput: {
    width: '100%',
  },
};

const EmptyUploadBagComponent = {
  uuid: null,
  name: null,
  purchaseYear: null,
  depositOrigin: null,
  buyoutAmount: null,
  typeId: null,
  contractDeadLine: null,
  newLevel: null,
  hotLevel: null,
  description: null,
  rentMonths: null,
  tags: null, // update to bagTag model
  designerTagId: null,
  styleTagId: null,
  originalPrice: null,

  frontPicId: null,
  backPicId: null,
  sidePicId: null,
  bottomPicId: null,
  zipperPicId: null,

  frontPic: null,
  backPic: null,
  sidePic: null,
  bottomPic: null,
  zipperPic: null,
};

const UploadBagComponent = ({
  tags,
  index,
  uploadBag,
  onModifiedData,
  onRemoveUploadBag,
  isMonoUploadBag,
  containerStyle,
  optionItemMobileContainerStyle,
  itemMobileContainerStyle,
  itemTitleMobileContainerStyle,
  onMemberValueChanged,
  recipientName,
  recipientPhone,
  recipientAddress,
  isUpdateMemberInfo,
  onIsUpdateMemberInfoChanged,
}) => {
  const [isExpand, setIsExpand] = useState(index === 0);
  const [deposit, setDeposit] = useState(0);

  const onFileUploaded = (key, result) => {
    onModifiedData(uploadBag.uuid, [
      { key, value: result.id },
      { key: key.replace('Id', ''), value: result }]);
  };

  const bagHotLevel = [
    { id: 1, name: '爆紅包款' },
    { id: 2, name: '熱門包款' },
    { id: 3, name: '普通包款' },
  ];

  const bagLevel = [
    { id: 10, name: '全新(10/10)' },
    { id: 9, name: 'A(9/10)' },
    { id: 8, name: 'B(8/10)' },
    { id: 7, name: 'C(6-7/10)' },
    { id: 5, name: 'D(5/10)' },
  ];

  const renderPredictAmount = (uploadTypeId) => {
    if (uploadTypeId === typeId.buy) {
      return '顯示售價';
    }
    if (uploadTypeId === typeId.rent) {
      return '預收押金';
    }
    return '顯示售價';
  };

  const renderActualAmount = (uploadTypeId) => {
    if (uploadTypeId === null) return null;
    if (uploadTypeId === typeId.buy || uploadTypeId === typeId.rentBuy) {
      return '實際收款金額';
    }
    return '實收押金';
  };

  const renderEarnAmount = (amountItemMobileContainer, ntMobileStyle) => {
    const uploadTypeId = uploadBag.typeId;
    if (uploadTypeId === null) return null;
    if (uploadTypeId !== typeId.buy) {
      return (
        <div style={{ ...styles.amountItemContainer, ...amountItemMobileContainer }}>
          <div style={styles.ownerPriceHint}>每次可賺/4天</div>
          <div style={{ ...styles.nt, ...styles.earn, ...ntMobileStyle }}>
            NT$
          </div>
          <div style={styles.actualWidth}>
            {getRentBagOwnerEarnAmount(deposit)}
          </div>
          <div />
        </div>
      );
    }
    return null;
  };

  const renderNt = (uploadTypeId) => {
    if (uploadTypeId !== typeId.rentBuy) {
      return 'NT$';
    }
    return '售 NT$';
  };

  const getDefaultValue = (uploadTypeId) => {
    if (uploadTypeId === typeId.rent) {
      return R.pathOr(0, ['depositOrigin'], uploadBag);
    }
    return R.pathOr(0, ['buyoutAmount'], uploadBag);
  };

  const renderAmountPanel = (amountPanelMobileStyle, amountItemMobileContainer, ntMobileStyle) => {
    const uploadTypeId = uploadBag.typeId;
    if (uploadTypeId === null) return null;

    return (
      <div style={{ ...styles.amountPanel, ...amountPanelMobileStyle }}>
        <div style={{ ...styles.amountItemContainer, ...amountItemMobileContainer }}>
          <div style={styles.ownerPriceHint}>{renderPredictAmount(uploadTypeId)}</div>
          <div style={{ ...styles.nt, ...ntMobileStyle }}>{renderNt(uploadTypeId)}</div>
          <input
            value={getDefaultValue(uploadTypeId)}
            type="text"
            pattern="[0-9]*"
            min={1000}
            style={{ ...styles.input, ...styles.incomeInput }}
            onChange={(event) => {
              if (uploadTypeId === typeId.rent) {
                onModifiedData(uploadBag.uuid, [{ key: 'depositOrigin', value: Number(event.target.value) }]);
                setDeposit(Number(event.target.value));
              } else if (uploadTypeId === typeId.rentBuy) {
                onModifiedData(uploadBag.uuid, [{ key: 'buyoutAmount', value: Number(event.target.value) }]);
                onModifiedData(uploadBag.uuid, [{ key: 'depositOrigin', value: Number(event.target.value) }]);
                setDeposit(Number(event.target.value));
              } else {
                onModifiedData(uploadBag.uuid, [{ key: 'buyoutAmount', value: Number(event.target.value) }]);
                setDeposit(Number(event.target.value));
              }
            }}
          />
        </div>
        <div style={{ ...styles.amountItemContainer, ...amountItemMobileContainer }}>
          <div style={styles.ownerPriceHint}>{renderActualAmount(uploadTypeId)}</div>
          <div style={{ ...styles.nt, ...styles.actualNt, ...ntMobileStyle }}>{renderNt(uploadTypeId)}</div>
          <div style={styles.actualWidth}>
            {uploadBag.typeId === typeId.rent
              ? Math.ceil(R.pathOr(0, ['depositOrigin'], uploadBag) * EarnPercent.sellOwnerEarnPercent)
              : Math.ceil(R.pathOr(0, ['buyoutAmount'], uploadBag) * EarnPercent.sellOwnerEarnPercent)
            }
          </div>
        </div>
        {renderEarnAmount(amountItemMobileContainer, ntMobileStyle)}
      </div>
    );
  };

  const renderPriceDescription = () => {
    const uploadTypeId = uploadBag.typeId;
    switch (uploadTypeId) {
      case typeId.buy:
        return (
          <div style={styles.subTitle}>
            - 顯示售價：
            <br />
            於平台上顯示的價格
            <br />
            <br />
            - 實際收款金額：
            <br />
            商品售出後，扣除平台服務費，實際收取的金額
          </div>
        );
      case typeId.rent:
        return (
          <div style={styles.subTitle}>
            - 預收押金：
            <br />
            押金設定建議為等同此商品價值之金額
            <br />
            <br />
            - 實際收款金額：
            <br />
            當商品遺失或毀損，等同於此商品售出，商品主人實際收到的金額
            <br />
            <br />
            - 每次可賺租金：
            <br />
            租金依商品價值自動換算
          </div>
        );
      case typeId.rentBuy:
        return (
          <div style={styles.subTitle}>
            - 顯示售價：
            <br />
            於平台上顯示的價格
            <br />
            <br />
            - 實際收款金額：
            <br />
            商品售出後，扣除平台服務費，實際收取的金額
            <br />
            <br />
            - 每次可賺租金：
            <br />
            租金依商品價值自動換算
          </div>
        );
      default:
        return null;
    }
  };

  const uploadPicData = [
    {
      picTypeId: 'frontPicId',
      type: 'frontPic',
    },
    {
      picTypeId: 'backPicId',
      type: 'backPic',
    },
    {
      picTypeId: 'bottomPicId',
      type: 'bottomPic',
    },
    {
      picTypeId: 'sidePicId',
      type: 'sidePic',
    },
    {
      picTypeId: 'zipperPicId',
      type: 'zipperPic',
    },
  ];

  const renderUploadPicButton = (
    picTypeId,
    type,
  ) => {
    const content = (
      <React.Fragment>
        <div style={styles.uploadTitleContainer} />
        <Uploader
          childComponent={<div style={styles.upload}>上傳照片</div>}
          onFileUploaded={result => onFileUploaded(picTypeId, result)}
        />
        <img alt="" src={R.pathOr('', [type, 'uri'], uploadBag)} style={styles.uploadImgThumbnail} />
      </React.Fragment>
    );

    return (
      <React.Fragment key={picTypeId}>
        <Default>
          <div style={styles.uploaderItem}>
            {content}
          </div>
        </Default>
        <Mobile>
          <div style={styles.uploaderItemMobile}>
            {content}
          </div>
        </Mobile>
      </React.Fragment>

    );
  };
  const renderInputBlock = (title, value, valueKey, inputStyle) => (
    <input
      style={{ ...styles.memberInputBox, ...inputStyle }}
      placeholder={title}
      value={value}
      onChange={(event) => { onMemberValueChanged(valueKey, event); }}
    />
  );
  const renderProductInfoContent = selectionItemMobileStyle => (
    <React.Fragment>
      <div style={{ ...styles.selectionItem, ...selectionItemMobileStyle }}>
        <div style={styles.selectionTitle}>品牌</div>
        <select
          defaultValue={uploadBag.designerTagId === null ? '0' : uploadBag.designerTagId}
          style={styles.selection}
          onChange={event => onModifiedData(
            uploadBag.uuid, [{ key: 'designerTagId', value: event.target.value }],
          )}
        >
          <option
            key="0"
            value="0"
          />
          {
        tags.map(({
          id: tagCategoryId,
          tags: tagItems,
        }) => {
          if (tagCategoryId !== 1) return null;
          return tagItems.map(({
            name: tagName,
            id: tagId,
          }) => <option key={tagId} value={tagId}>{tagName}</option>);
        })
      }
        </select>
      </div>

      <div style={{ ...styles.selectionItem, ...selectionItemMobileStyle }}>
        <div style={styles.selectionTitle}>包款樣式</div>
        <select
          defaultValue={uploadBag.styleTagId === null ? '0' : uploadBag.styleTagId}
          onChange={event => onModifiedData(uploadBag.uuid, [{ key: 'styleTagId', value: event.target.value }])}
          style={styles.selection}
        >
          <option
            key="0"
            value="0"
          />
          {
        tags.map(({
          id: tagCategoryId,
          tags: tagItems,
        }) => {
          if (tagCategoryId !== 2) return null;
          return tagItems.map(({
            name: tagName,
            id: tagId,
          }) => <option key={tagId} value={tagId}>{tagName}</option>);
        })
      }
        </select>
      </div>

      <div style={{ ...styles.selectionItem, ...selectionItemMobileStyle }}>
        <div style={styles.selectionTitle}>包況</div>
        <select
          defaultValue={uploadBag.newLevel === null ? '0' : uploadBag.newLevel}
          onChange={(event) => {
            onModifiedData(uploadBag.uuid, [{ key: 'newLevel', value: event.target.value }]);
          }}
          style={styles.selection}
        >
          <option
            value="0"
            key="0"
          />
          {bagLevel.map(
            item => <option value={item.id} key={item.id}>{item.name}</option>,
          )}
        </select>
      </div>


      <div style={{ ...styles.selectionItem, ...selectionItemMobileStyle }}>
        <div style={styles.selectionTitle}>名稱</div>
        <input
          style={styles.input}
          value={uploadBag.name === null ? '' : uploadBag.name}
          onChange={event => onModifiedData(uploadBag.uuid, [{ key: 'name', value: event.target.value }])}
        />
      </div>


      <div style={{ ...styles.selectionItem, ...selectionItemMobileStyle }}>
        <div style={styles.selectionTitle}>產品熱門度</div>
        <select
          defaultValue={uploadBag.hotLevel === null ? '0' : uploadBag.hotLevel}
          onChange={(event) => {
            onModifiedData(uploadBag.uuid, [{ key: 'hotLevel', value: event.target.value }]);
          }}
          style={styles.selection}
        >
          <option
            value="0"
            key="0"
          />
          {bagHotLevel.map(
            item => <option value={item.id} key={item.id}>{item.name}</option>,
          )}
        </select>
      </div>


      <div style={{ ...styles.selectionItem, ...selectionItemMobileStyle }}>
        <div style={styles.selectionTitle}>商品原價</div>
        <input
          type="number"
          style={styles.input}
          value={uploadBag.originalPrice === null ? '' : uploadBag.originalPrice}
          onChange={event => onModifiedData(uploadBag.uuid, [{ key: 'originalPrice', value: event.target.value }])}
        />
        {/* TODO:temporarily comment
        <select
          defaultValue={uploadBag.colorTagId === null ? '0' : uploadBag.colorTagId}
          onChange={event => onModifiedData(uploadBag.uuid, [{ key: 'colorTagId', value: event.target.value }])}
          style={styles.selection}
        >
          <option
            key="0"
            value="0"
          />
          {
         tags.map(({
           id: tagCategoryId,
           tags: tagItems,
         }) => {
           if (tagCategoryId !== 3) return null;
           return tagItems.map(({
             name: tagName,
             id: tagId,
           }) => <option key={tagId} value={tagId}>{tagName}</option>);
         })
         }
        </select>
        */}
      </div>
    </React.Fragment>
  );
  return (
    <div style={{ ...styles.mainContainer, ...containerStyle }}>
      <Default>
        <div style={styles.leftContainer}>
          <div style={styles.bag}>bag</div>
          <div style={styles.index}>{index + 1}</div>
          <Button
            type="link"
            onClick={() => setIsExpand(!isExpand)}
            style={styles.expandIcon}
            icon={isExpand ? 'down' : 'up'}
          />
        </div>
      </Default>
      <Mobile>
        <div style={styles.mobileTopContainer}>
          <div style={styles.bag}>bag</div>
          <div style={styles.index}>{index + 1}</div>
          <Button
            type="link"
            onClick={() => setIsExpand(!isExpand)}
            style={styles.expandIcon}
            icon={isExpand ? 'down' : 'up'}
          />
          {!isMonoUploadBag
      && (
      <Popconfirm
        title="確認刪除?"
        onConfirm={() => onRemoveUploadBag(uploadBag.uuid)}
        okText="Yes"
        cancelText="No"
      >
        <Icon type="delete" style={styles.deleteIconMobile} />
      </Popconfirm>
      )}
        </div>
      </Mobile>

      {isExpand
      && (
      <div style={styles.rightContainer}>
        <div style={{ ...styles.uploadItemContainer, ...itemMobileContainerStyle }}>
          <div style={{ ...styles.itemTitleContainer, ...itemTitleMobileContainerStyle }}>
            <div style={styles.optionContainer}>
              <div style={styles.title}>METHOD</div>
              <div style={styles.titleHint}>提供方式</div>
            </div>
          </div>
          <div style={{ ...styles.optionItemContainer, ...optionItemMobileContainerStyle }}>


            <Button
              icon={uploadBag.typeId === typeId.rent ? 'check-square' : 'border'}
              type="link"
              style={styles.button}
              onClick={() => {
                onModifiedData(uploadBag.uuid,
                  [{ key: 'typeId', value: typeId.rent },
                    { key: 'depositOrigin', value: null },
                    { key: 'buyoutAmount', value: null },
                  ]);
                setDeposit(0);
              }}
            />
            <Icon type="User" />
            <div style={styles.itemOption}>出借</div>
            <div style={styles.itemOptionHint}>Lend</div>
          </div>


          <div style={{ ...styles.optionItemContainer, ...optionItemMobileContainerStyle }}>
            <Button
              icon={uploadBag.typeId === typeId.buy ? 'check-square' : 'border'}
              type="link"
              style={styles.button}
              onClick={() => onModifiedData(uploadBag.uuid,
                [{ key: 'typeId', value: typeId.buy },
                  { key: 'depositOrigin', value: null },
                  { key: 'buyoutAmount', value: null },
                ])}
            />
            <Icon type="User" />
            <div style={styles.itemOption}>出售</div>
            <div style={styles.itemOptionHint}>Sell</div>
          </div>


          <div style={{ ...styles.optionItemContainer, ...optionItemMobileContainerStyle }}>
            <Button
              icon={uploadBag.typeId === typeId.rentBuy ? 'check-square' : 'border'}
              type="link"
              style={styles.button}
              onClick={() => {
                onModifiedData(uploadBag.uuid,
                  [{ key: 'typeId', value: typeId.rentBuy },
                    { key: 'depositOrigin', value: null },
                    { key: 'buyoutAmount', value: null },
                  ]);
                setDeposit(0);
              }}
            />
            <Icon type="User" />
            <div style={styles.itemOption}>出售-出借</div>
            <div style={styles.itemOptionHint}>Both</div>
          </div>
        </div>


        <div style={{ ...styles.uploadItemContainer, ...itemMobileContainerStyle }}>
          <div style={{ ...styles.itemTitleContainer, ...itemTitleMobileContainerStyle }}>
            <div style={styles.optionContainer}>
              <div style={styles.title}>DEADLINE</div>
              <div style={styles.titleHint}>租賣時間</div>
            </div>
          </div>

          <div style={{ ...styles.optionItemContainer, ...optionItemMobileContainerStyle }}>
            <Button
              type="link"
              style={styles.button}
              icon={uploadBag.rentMonths === 3 ? 'check-square' : 'border'}
              onClick={() => onModifiedData(uploadBag.uuid, [
                {
                  key: 'contractDeadLine',
                  value: moment(new Date()).add(3, 'months'),
                },
                {
                  key: 'rentMonths',
                  value: 3,
                },
              ])}
            />
            <div style={styles.itemOption}>3個月</div>
          </div>

          <div style={{ ...styles.optionItemContainer, ...optionItemMobileContainerStyle }}>
            <Button
              type="link"
              style={styles.button}
              icon={uploadBag.rentMonths === 6 ? 'check-square' : 'border'}
              onClick={() => onModifiedData(uploadBag.uuid, [
                {
                  key: 'contractDeadLine',
                  value: moment(new Date()).add(6, 'months'),
                },
                {
                  key: 'rentMonths',
                  value: 6,
                },
              ])}
            />
            <div style={styles.itemOption}>6個月</div>
          </div>


          <div style={{ ...styles.optionItemContainer, ...optionItemMobileContainerStyle }}>
            <Button
              type="link"
              style={styles.button}
              icon={uploadBag.rentMonths === 12 ? 'check-square' : 'border'}
              onClick={() => onModifiedData(uploadBag.uuid, [
                {
                  key: 'contractDeadLine',
                  value: moment(new Date()).add(12, 'months'),
                },
                {
                  key: 'rentMonths',
                  value: 12,
                },
              ])}
            />
            <div style={styles.itemOption}>12個月</div>
          </div>
        </div>

        <div style={{ ...styles.uploadItemContainer, ...itemMobileContainerStyle }}>
          <div style={{ ...styles.itemTitleContainer, ...itemTitleMobileContainerStyle }}>
            <div style={styles.optionContainer}>
              <div style={styles.title}>PHOTOS</div>
              <div style={styles.titleHint}>上傳相片</div>
            </div>
            <div style={styles.subTitle}>
              上傳最少一張，最多五張照片
              <br />
              拍照第一張建議以乾淨淺色背景為主。
              <br />
              也可拍攝使用痕跡特寫照，以加快審核速度！
            </div>
          </div>
          <div style={styles.uploaderContainer}>
            {uploadPicData.map(item => (
              renderUploadPicButton(
                item.picTypeId,
                item.type,
              )
            ))}
          </div>
        </div>


        <div style={{ ...styles.uploadItemContainer, ...itemMobileContainerStyle }}>
          <div style={{ ...styles.itemTitleContainer, ...itemTitleMobileContainerStyle }}>
            <div style={styles.optionContainer}>
              <div style={styles.title}>PRODUCT INFO</div>
              <div style={styles.titleHint}>產品詳情</div>
            </div>
            <div style={styles.subTitle}>
              關於商品的品牌、樣式等
            </div>
          </div>

          <Default>
            <div style={styles.selectionContainer}>
              {renderProductInfoContent()}
            </div>
          </Default>
          <Mobile>
            <div style={styles.selectionMobileContainer}>
              {renderProductInfoContent(styles.selectionItemMobile)}
            </div>
          </Mobile>
        </div>


        <div style={{ ...styles.uploadItemContainer, ...itemMobileContainerStyle }}>
          <div style={{ ...styles.itemTitleContainer, ...itemTitleMobileContainerStyle }}>
            <div style={styles.optionContainer}>
              <div style={styles.title}>PRICE</div>
              <div style={styles.titleHint}>價格</div>
            </div>
            {renderPriceDescription()}
          </div>
          <Default>
            <div style={styles.incomeContainer}>
              <div style={styles.income}>收入明細</div>
              {renderAmountPanel()}
            </div>
          </Default>
          <Mobile>
            <div style={styles.incomeMobileContainer}>
              <div style={styles.incomeMobile}>收入明細</div>
              {renderAmountPanel(
                styles.amountPanelMobile,
                styles.amountItemMobileContainer,
                styles.ntMobile,
              )}
            </div>
          </Mobile>
        </div>
        <div style={{ ...styles.uploadItemContainer, ...itemMobileContainerStyle }}>
          <div style={{ ...styles.itemTitleContainer, ...itemTitleMobileContainerStyle }}>
            <div style={styles.optionContainer}>
              <div style={styles.title}>ITEM DESCRIPTION</div>
            </div>
            <div style={styles.subTitle}>
              獨特的商品詳細信息和屬性
            </div>
          </div>
          <div style={styles.descriptionContainer}>
            <div style={styles.descriptionTitle}>
              描述您的商品，增加群眾對您提供商品的興趣
              <br />
              有關的使用情況或特徵。例如：復古、鉚釘、皮革、特殊色...
            </div>
            <textarea
              style={styles.description}
              onChange={(event) => {
                onModifiedData(uploadBag.uuid, [{ key: 'description', value: event.target.value }]);
              }}
            />
          </div>
        </div>
        <div style={{ ...styles.uploadItemContainer, ...itemMobileContainerStyle }}>
          <div style={{ ...styles.itemTitleContainer, ...itemTitleMobileContainerStyle }}>
            <div style={styles.optionContainer}>
              <div style={styles.title}>ADDRESS</div>
            </div>
            <div style={styles.subTitle}>
              收件地址
            </div>
          </div>
          <div style={styles.memberInfoInputContainer}>
            <div style={{ ...styles.memberInputContainer, ...optionItemMobileContainerStyle }}>
              <input
                type="checkbox"
                style={styles.autoInputRecipientCheckbox}
                defaultChecked={isUpdateMemberInfo}
                onChange={event => onIsUpdateMemberInfoChanged(event.target.checked)}
              />
              <div style={styles.autoInputRecipientCheckboxText}>收件資料更新至會員資料</div>
            </div>
            <div style={{ ...styles.memberInputContainer, ...itemMobileContainerStyle }}>
              {renderInputBlock('地址(Address)*', recipientAddress, 'recipientAddress', styles.addressInput)}
              {renderInputBlock('姓名(Name)*', recipientName, 'recipientName')}
              {renderInputBlock('手機(Phone)*', recipientPhone, 'recipientPhone')}
            </div>
          </div>
        </div>
      </div>
      )}
      {!isMonoUploadBag
      && (
      <Default>
        <Popconfirm
          title="確認刪除?"
          onConfirm={() => onRemoveUploadBag(uploadBag.uuid)}
          okText="Yes"
          cancelText="No"
        >
          <Icon type="delete" style={styles.deleteIcon} />
        </Popconfirm>
      </Default>
      )
      }
    </div>
  );
};

UploadBagComponent.propTypes = {
  tags: PropTypes.array,
  uploadBag: PropTypes.object,
  index: PropTypes.number.isRequired,
  onRemoveUploadBag: PropTypes.func.isRequired,
  onModifiedData: PropTypes.func.isRequired,
  isMonoUploadBag: PropTypes.bool,
  containerStyle: PropTypes.object,
  optionItemMobileContainerStyle: PropTypes.object,
  itemMobileContainerStyle: PropTypes.object,
  itemTitleMobileContainerStyle: PropTypes.object,
  onMemberValueChanged: PropTypes.func,
  recipientName: PropTypes.string,
  recipientPhone: PropTypes.string,
  recipientAddress: PropTypes.string,
  isUpdateMemberInfo: PropTypes.bool,
  onIsUpdateMemberInfoChanged: PropTypes.func,
};

UploadBagComponent.defaultProps = {
  tags: [],
  uploadBag: {},
  isMonoUploadBag: false,
  containerStyle: {},
  optionItemMobileContainerStyle: {},
  itemMobileContainerStyle: {},
  itemTitleMobileContainerStyle: {},
  recipientName: '',
  recipientPhone: '',
  recipientAddress: '',
  isUpdateMemberInfo: true,
  onMemberValueChanged: () => {},
  onIsUpdateMemberInfoChanged: () => {},
};

export {
  UploadBagComponent,
  EmptyUploadBagComponent,
};
