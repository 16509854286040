import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';
import { Checkbox } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import renderTableFormField from '../../../../components/FormTable/renderTableFormField';
import Button from '../../../../components/Button';
import { Colors } from '../../../../constants/Color';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import { shippingTypeId, shippingType } from '../../../../constants/ShippingType';

const styles = {
  mainContainer: {
    width: '70%',
    margin: '50px auto',
  },
  tableStyle: {
    margin: '10px auto',
    tableLayout: 'fixed',
    width: '100%',
    wordWrap: 'break-word',
  },
  editTableStyle: {
    margin: '0px auto',
    minWidth: '200px',
    tableLayout: 'fixed',
    width: '50%',
    wordWrap: 'break-word',
  },
  editTableMobileStyle: {
    tableLayout: 'fixed',
    width: '100%',
    wordWrap: 'break-word',
  },
  reduxStyle: {
    width: '100%',
  },
  inputBox: {
    borderRadius: 0,
    minWidth: '140px',
    width: '70%',
    padding: 3,
  },
  tableTitle: {
    width: '16%',
    padding: '7px 0',
    textAlign: 'right',
    color: 'black',
  },
  tableContent: {
    width: '35%',
    paddingLeft: '10px',
  },
  fieldTitle: {
    minWidth: '0px',
    width: '100%',
  },
  editHeaderContainer: {
    borderBottom: `1px solid ${Colors.separateLine}`,
    position: 'relative',
    paddingBottom: '5px',
  },
  informationTitle: {
    height: 'auto',
    fontSize: '18px',
    width: '50%',
    minWidth: '80px',
    color: 'black',
  },
  editButton: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    color: 'grey',
    background: 'white',
    border: `2px solid ${Colors.introRightTitle}`,
    maxWidth: '160px',
    minWidth: '60px',
    width: '30%',
    height: '50px',
  },
  checkbox: {
    width: '0px',
    minWidth: '0px',
    textAlign: 'right',
    padding: '1px',
  },
  editTableContainer: {
    display: 'flex',
    position: 'relative',
    height: 'auto',
    flexWrap: 'wrap',
    margin: '5px 0px',
  },
  saveButton: {
    width: '160px',
    height: '50px',
    padding: '0px 10px',
    outline: 'none',
    cursor: 'pointer',
    margin: '10px',
    color: 'grey',
    backgroundColor: 'white',
    border: `2px solid ${Colors.introRightTitle}`,
  },
  changePasswordButton: {
    width: '90px',
    height: '30px',
    minWidth: '80px',
    padding: '0px 10px',
    outline: 'none',
    cursor: 'pointer',
    margin: '10px',
    color: 'grey',
    backgroundColor: 'white',
    border: `2px solid ${Colors.introRightTitle}`,
  },
  disableChangePasswordButton: {
    width: '90px',
    height: '30px',
    minWidth: '80px',
    padding: '0px 10px',
    outline: 'none',
    cursor: 'pointer',
    margin: '10px',
    color: 'grey',
    backgroundColor: 'white',
    border: `2px solid ${Colors.separateLine}`,
  },
  cancelButton: {
    width: '160px',
    height: '50px',
    padding: '0px 10px',
    outline: 'none',
    cursor: 'pointer',
    margin: '10px',
    color: 'grey',
    backgroundColor: 'white',
    border: `2px solid ${Colors.separateLine}`,
  },
  buttonContainer: {
    display: 'flex',
    margin: '0 auto',
    height: 'auto',
    width: '50%',
    minWidth: '200px',
  },
  tableContainer: {
    position: 'relative',
    height: 'calc(100% + 60px)',
    marginBottom: '160px',
    marginTop: '50px',
  },
  subscribeContainer: {
    display: 'flex',
    width: '50%',
    minWidth: '200px',
    margin: '0 auto',
  },
  inputBoxText: {
    borderRadius: 0,
    minWidth: '50px',
    width: '70%',
    padding: 3,
  },
  unEditButton: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    color: 'grey',
    background: 'white',
    border: `2px solid ${Colors.separateLine}`,
    maxWidth: '160px',
    minWidth: '60px',
    width: '30%',
    height: '50px',
  },
  changePasswordNote: {
    fontSize: '10px',
    padding: '9px 0',
    margin: '4px',
    color: 'grey',
  },
  subscribeMobileContainer: {
    width: '100%',
  },
};

const MemberInfoComponent = ({
  reduxStatus,
  resetStatus,
  memberInfo,
  handleUpdateMemberInfo,
  setShowChangePasswordModal,
}) => {
  useEffect(() => {
    resetStatus();
    return resetStatus;
  }, []);

  const [modifiedLoginInformationData, setModifiedLoginInformationData] = useState({});
  const [modifiedDeliverData, setModifiedDeliverData] = useState({});
  const [modifiedBillData, setModifiedBillData] = useState({});
  const [editLoginInformationData, setEditLoginInformationData] = useState(false);
  const [editDeliverData, setEditDeliverData] = useState(false);
  const [editBillData, setEditBillData] = useState(false);
  const selectStore = shippingType[Number(R.pathOr('', ['selectStore'], memberInfo))] || '';

  const handleSaveLoginInformationData = (event) => {
    event.preventDefault();
    handleUpdateMemberInfo(modifiedLoginInformationData);
    setEditLoginInformationData(false);
    setModifiedLoginInformationData({});
  };

  const handleSaveDeliverData = (event) => {
    event.preventDefault();
    handleUpdateMemberInfo(modifiedDeliverData);
    setEditDeliverData(false);
    setModifiedDeliverData({});
  };

  const handleSaveBillData = (event) => {
    event.preventDefault();
    handleUpdateMemberInfo(modifiedBillData);
    setEditBillData(false);
    setModifiedBillData({});
  };

  const renderModifiedLoginInformationField = parameters => renderTableFormField({
    data: memberInfo,
    modifiedData: modifiedLoginInformationData,
    setModifiedData: setModifiedLoginInformationData,
    ...parameters,
  });

  const renderModifiedDeliverDataField = parameters => renderTableFormField({
    data: memberInfo,
    modifiedData: modifiedDeliverData,
    setModifiedData: setModifiedDeliverData,
    ...parameters,
  });

  const renderModifiedBillDataField = parameters => renderTableFormField({
    data: memberInfo,
    modifiedData: modifiedBillData,
    setModifiedData: setModifiedBillData,
    ...parameters,
  });

  const renderDateTextFiled = (data = {}) => (
    <tr>
      <td style={styles.tableTitle}>
        {data.name}
      </td>
      <td style={styles.tableContent}>
        {moment(R.pathOr('', [data.key], memberInfo)).format('YYYY/MM/DD')}
      </td>
    </tr>
  );
  const renderGender = (data) => {
    switch (data) {
      case 'm':
        return '男生';
      case 'f':
        return '女生';
      default:
        return '其他/不願透露';
    }
  };

  const renderGenderTextFiled = (data = {}) => (
    <tr>
      <td style={styles.tableTitle}>
        {data.name}
      </td>
      <td style={styles.tableContent}>
        {renderGender(R.pathOr('', [data.key], memberInfo))}
      </td>
    </tr>
  );

  const renderSelectStoreTextFiled = (data = {}) => (
    <tr>
      <td style={styles.tableTitle}>
        {data.name}
      </td>
      <td style={styles.tableContent}>
        {shippingType[Number(R.pathOr('', [data.key], memberInfo))]}
      </td>
    </tr>
  );

  const loginInfoContent = [
    {
      key: 'isActive',
      default: true,
      content: (R.pathOr(false, ['isActive'], memberInfo) && (
      <tr key="isActive">
        <td />
        <td>已認證</td>
      </tr>
      )),
    },
    {
      key: 'blank',
      default: true,
      content: null,
    },
    {
      key: 'name',
      name: '姓名',
      type: editLoginInformationData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    }, {
      key: 'gender',
      name: '性別',
      type: 'select',
      options: [{ id: 'm', name: '男' }, { id: 'f', name: '女' }, { id: '', name: '其他/不願透露' }],
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
      renderText: (
        renderGenderTextFiled({
          key: 'gender',
          name: '性別',
        })
      ),
    },
    {
      key: 'birthday',
      name: '生日',
      type: 'date',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
      renderText: (
        renderDateTextFiled({
          key: 'birthday',
          name: '生日',
        })
      ),
    }, {
      key: 'email',
      name: '電子信箱',
      type: editLoginInformationData ? null : 'text',
      disabled: true,
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    }, {
      key: 'phone',
      name: '手機號碼',
      type: editLoginInformationData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
    {
      key: 'recipientAddress',
      name: '地址',
      type: editLoginInformationData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
  ];

  const renderSubscribeAndChangePasswordBlock = style => (
    <div style={styles.editTableContainer}>
      <div style={{ ...styles.subscribeContainer, ...style }}>
        {editLoginInformationData ? (
          <table>
            <tbody>
              {renderModifiedLoginInformationField({
                key: 'isSubscribe',
                type: 'checkbox',
                fieldTitleStyle: styles.checkbox,
              })}
            </tbody>
          </table>
        ) : (
          <Checkbox
            checked={R.pathOr(false, ['isSubscribe'], memberInfo)}
            disabled
          />
        )}
        我願意收到最新優惠情報
      </div>
      <div style={{ ...styles.subscribeContainer, ...style }} />
      <div style={{ ...styles.subscribeContainer, ...style }}>
        {
      editLoginInformationData ? (
        <React.Fragment>
          {R.isNil(R.path(['socialLogin'], memberInfo))
            ? (
              <Button
                text="變更密碼"
                style={styles.changePasswordButton}
                onClick={() => { setShowChangePasswordModal(true); }}
              />
            ) : (
              <React.Fragment>
                <Button
                  text="變更密碼"
                  style={styles.disableChangePasswordButton}
                />
                <div style={styles.changePasswordNote}>您並非使用 Email 及密碼註冊故無法更改密碼</div>
              </React.Fragment>
            )}
        </React.Fragment>
      ) : null}
      </div>
      <div style={{ ...styles.subscribeContainer, ...style }} />
    </div>
  );

  const deliveryContent = [
    {
      key: 'recipientName',
      name: '姓名',
      type: editDeliverData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
    {
      key: 'recipientPhone',
      name: '手機號碼',
      type: editDeliverData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
    {
      key: 'recipientZipCode',
      name: '郵遞區號',
      type: editDeliverData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
    {
      key: 'recipientAddress',
      name: '收件地址',
      type: editDeliverData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
    {
      key: 'sevenElevenMarket',
      name: `${selectStore}門市名稱`,
      type: editDeliverData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
    {
      key: 'selectStore',
      name: '選擇超商',
      type: 'select',
      options: [
        { id: null, name: '' },
        { id: shippingTypeId.sevenEleven, name: shippingType[shippingTypeId.sevenEleven] },
      ],
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
      renderText: (
        renderSelectStoreTextFiled({
          key: 'selectStore',
          name: '選擇超商',
        })
      ),
    },
    {
      key: 'sevenElevenAddress',
      name: `${selectStore}門市地址`,
      type: editDeliverData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
  ];

  const billAddressContent = [
    {
      key: 'payeeName',
      name: '姓名',
      type: editBillData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
    {
      key: 'payeePhone',
      name: '手機號碼',
      type: editBillData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
    {
      key: 'payeeBankCode',
      name: '銀行代碼',
      type: editBillData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
    {
      key: 'payeeBankAccount',
      name: '銀行帳號',
      type: editBillData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
    {
      key: 'payeeBankBranch',
      name: '銀行分行',
      type: editBillData ? null : 'text',
      fieldTitleStyle: styles.tableTitle,
      fieldContentStyle: styles.tableContent,
      inputBoxStyle: styles.inputBox,
    },
  ];

  return (
    <ReduxStatusVisualizedComponent
      reduxStatus={reduxStatus}
      containerStyle={styles.reduxStyle}
    >
      <div style={styles.mainContainer}>
        <div>
          <div style={styles.editHeaderContainer}>
            <div style={styles.informationTitle}>會員登入資料</div>
            <Button
              text="編輯"
              onClick={() => setEditLoginInformationData(true)}
              style={editLoginInformationData ? styles.unEditButton : styles.editButton}
            />
          </div>

          <div style={styles.tableContainer}>
            <form onSubmit={handleSaveLoginInformationData}>
              <div style={styles.editTableContainer}>
                <Default>
                  <table style={styles.editTableStyle}>
                    <tbody>
                      {loginInfoContent.map((item, index) => {
                        if (index % 2 === 0) {
                          if (item.default) {
                            return (
                              <React.Fragment key={item.key}>
                                {item.content}
                              </React.Fragment>
                            );
                          }

                          return (
                            <React.Fragment key={item.key}>
                              { !editLoginInformationData && item.renderText
                                ? item.renderText : (
                                  renderModifiedLoginInformationField({
                                    key: item.key,
                                    name: item.name,
                                    type: item.type,
                                    options: item.options,
                                    disabled: item.disabled,
                                    fieldTitleStyle: item.fieldTitleStyle,
                                    fieldContentStyle: item.fieldContentStyle,
                                    inputBoxStyle: item.inputBoxStyle,
                                  }))}
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}
                    </tbody>
                  </table>

                  <table style={styles.editTableStyle}>
                    <tbody>
                      {loginInfoContent.map((item, index) => {
                        if (index % 2 === 1) {
                          if (item.default) {
                            return (
                              <React.Fragment key={item.key}>
                                {item.content}
                              </React.Fragment>
                            );
                          }

                          return (
                            <React.Fragment key={item.key}>
                              { !editLoginInformationData && item.renderText
                                ? item.renderText : (
                                  renderModifiedLoginInformationField({
                                    key: item.key,
                                    name: item.name,
                                    type: item.type,
                                    options: item.options,
                                    disabled: item.disabled,
                                    fieldTitleStyle: item.fieldTitleStyle,
                                    fieldContentStyle: item.fieldContentStyle,
                                    inputBoxStyle: item.inputBoxStyle,
                                  }))}
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}
                    </tbody>
                  </table>
                  {renderSubscribeAndChangePasswordBlock()}
                </Default>
                <Mobile>
                  <table style={styles.editTableMobileStyle}>
                    <tbody>
                      {loginInfoContent.map((item) => {
                        if (item.default) {
                          return (
                            <React.Fragment key={item.key}>
                              {item.content}
                            </React.Fragment>
                          );
                        }

                        return (
                          <React.Fragment key={item.key}>
                            { !editLoginInformationData && item.renderText
                              ? item.renderText : (
                                renderModifiedLoginInformationField({
                                  key: item.key,
                                  name: item.name,
                                  type: item.type,
                                  options: item.options,
                                  disabled: item.disabled,
                                  fieldTitleStyle: item.fieldTitleStyle,
                                  fieldContentStyle: item.fieldContentStyle,
                                  inputBoxStyle: item.inputBoxStyle,
                                }))}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                  {renderSubscribeAndChangePasswordBlock(styles.subscribeMobileContainer)}
                </Mobile>
              </div>

              {
                editLoginInformationData ? (
                  <div style={styles.buttonContainer}>
                    <button
                      type="button"
                      onClick={() => setEditLoginInformationData(false)}
                      style={styles.cancelButton}
                    >
                      取消
                    </button>
                    <button
                      type="submit"
                      style={styles.saveButton}
                    >
                      儲存
                    </button>
                  </div>
                ) : null}
            </form>
          </div>
        </div>

        <div>
          <div style={styles.editHeaderContainer}>
            <div style={styles.informationTitle}>收件人資料</div>
            <Button
              text="編輯"
              onClick={() => setEditDeliverData(true)}
              style={editDeliverData ? styles.unEditButton : styles.editButton}
            />
          </div>
          <div style={styles.tableContainer}>
            <form onSubmit={handleSaveDeliverData}>
              <div style={styles.editTableContainer}>
                <Default>
                  <table style={styles.editTableStyle}>
                    <tbody>
                      {deliveryContent.map((item, index) => {
                        if (index % 2 === 0) {
                          if (item.default) {
                            return (
                              <React.Fragment key={item.key}>
                                {item.content}
                              </React.Fragment>
                            );
                          }

                          return (
                            <React.Fragment key={item.key}>
                              { !editDeliverData && item.renderText
                                ? item.renderText : (
                                  renderModifiedDeliverDataField({
                                    key: item.key,
                                    name: item.name,
                                    type: item.type,
                                    options: item.options,
                                    disabled: item.disabled,
                                    fieldTitleStyle: item.fieldTitleStyle,
                                    fieldContentStyle: item.fieldContentStyle,
                                    inputBoxStyle: item.inputBoxStyle,
                                  }))}
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}
                    </tbody>
                  </table>

                  <table style={styles.editTableStyle}>
                    <tbody>
                      {deliveryContent.map((item, index) => {
                        if (index % 2 === 1) {
                          if (item.default) {
                            return (
                              <React.Fragment key={item.key}>
                                {item.content}
                              </React.Fragment>
                            );
                          }

                          return (
                            <React.Fragment key={item.key}>
                              { !editDeliverData && item.renderText
                                ? item.renderText : (
                                  renderModifiedDeliverDataField({
                                    key: item.key,
                                    name: item.name,
                                    type: item.type,
                                    options: item.options,
                                    disabled: item.disabled,
                                    fieldTitleStyle: item.fieldTitleStyle,
                                    fieldContentStyle: item.fieldContentStyle,
                                    inputBoxStyle: item.inputBoxStyle,
                                  }))}
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}
                    </tbody>
                  </table>
                </Default>
                <Mobile>
                  <table style={styles.editTableMobileStyle}>
                    <tbody>
                      {deliveryContent.map((item) => {
                        if (item.default) {
                          return (
                            <React.Fragment key={item.key}>
                              {item.content}
                            </React.Fragment>
                          );
                        }

                        return (
                          <React.Fragment key={item.key}>
                            { !editDeliverData && item.renderText
                              ? item.renderText : (
                                renderModifiedDeliverDataField({
                                  key: item.key,
                                  name: item.name,
                                  type: item.type,
                                  options: item.options,
                                  disabled: item.disabled,
                                  fieldTitleStyle: item.fieldTitleStyle,
                                  fieldContentStyle: item.fieldContentStyle,
                                  inputBoxStyle: item.inputBoxStyle,
                                }))}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </Mobile>
              </div>
              {
                editDeliverData ? (
                  <div style={styles.buttonContainer}>
                    <button
                      type="button"
                      onClick={() => setEditDeliverData(false)}
                      style={styles.cancelButton}
                    >
                      取消
                    </button>
                    <button
                      type="submit"
                      style={styles.saveButton}
                    >
                      儲存
                    </button>
                  </div>
                ) : null
              }
            </form>
          </div>
        </div>

        <div>
          <div style={styles.editHeaderContainer}>
            <div style={styles.informationTitle}>收款資料</div>
            <Button
              text="編輯"
              onClick={() => setEditBillData(true)}
              style={editBillData ? styles.unEditButton : styles.editButton}
            />
          </div>
          <div style={styles.tableContainer}>
            <form onSubmit={handleSaveBillData}>
              <div style={styles.editTableContainer}>
                <Default>
                  <table style={styles.editTableStyle}>
                    <tbody>
                      {billAddressContent.map((item, index) => {
                        if (index % 2 === 0) {
                          return (
                            <React.Fragment key={item.key}>
                              { renderModifiedBillDataField({
                                key: item.key,
                                name: item.name,
                                type: item.type,
                                fieldTitleStyle: item.fieldTitleStyle,
                                fieldContentStyle: item.fieldContentStyle,
                                inputBoxStyle: item.inputBoxStyle,
                              })}
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}
                    </tbody>
                  </table>

                  <table style={styles.editTableStyle}>
                    <tbody>
                      {billAddressContent.map((item, index) => {
                        if (index % 2 === 1) {
                          return (
                            <React.Fragment key={item.key}>
                              { renderModifiedBillDataField({
                                key: item.key,
                                name: item.name,
                                type: item.type,
                                fieldTitleStyle: item.fieldTitleStyle,
                                fieldContentStyle: item.fieldContentStyle,
                                inputBoxStyle: item.inputBoxStyle,
                              })}
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}
                    </tbody>
                  </table>
                </Default>
                <Mobile>
                  <table style={styles.editTableMobileStyle}>
                    <tbody>
                      {billAddressContent.map(item => (
                        <React.Fragment key={item.key}>
                          { renderModifiedBillDataField({
                            key: item.key,
                            name: item.name,
                            type: item.type,
                            fieldTitleStyle: item.fieldTitleStyle,
                            fieldContentStyle: item.fieldContentStyle,
                            inputBoxStyle: item.inputBoxStyle,
                          })}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </Mobile>
              </div>
              {editBillData ? (
                <div style={styles.buttonContainer}>
                  <button
                    type="button"
                    onClick={() => setEditBillData(false)}
                    style={styles.cancelButton}
                  >
                    取消
                  </button>
                  <button
                    type="submit"
                    style={styles.saveButton}
                  >
                    儲存
                  </button>
                </div>
              ) : null
              }
            </form>
          </div>
        </div>
      </div>
    </ReduxStatusVisualizedComponent>
  );
};

MemberInfoComponent.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  resetStatus: PropTypes.func.isRequired,
  setShowChangePasswordModal: PropTypes.func.isRequired,
  handleUpdateMemberInfo: PropTypes.func.isRequired,
  memberInfo: PropTypes.shape({}),
};

MemberInfoComponent.defaultProps = {
  memberInfo: {},
};

export default MemberInfoComponent;
