import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import { FontSize } from '../../../../constants/FontSize';

const styles = {
  mainContainer: {
    height: '100%',
    width: '100%',
    display: 'inline-block',
    whiteSpace: 'normal',
    verticalAlign: 'top',
    overflow: 'hidden',
  },
  imgContainer: {
    position: 'relative',
    height: '300px',
    width: '300px',
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '0px auto 0 auto',
  },
  imgMobileContainer: {
    position: 'relative',
    height: '150px',
    width: '100%',
    display: 'inline-block',
    verticalAlign: 'top',
    marginTop: '40px',
  },
  instructionContainer: {
    position: 'relative',
    height: 'auto',
    width: 'auto',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  img: {
    position: 'absolute',
    objectFit: 'cover',
    height: '110px',
    width: '102px',
    bottom: '0',
    left: '10%',
  },
  imgMobile: {
    objectFit: 'cover',
    height: '110px',
    width: '102px',
  },
  textContainer: {
    maxWidth: '550px',
    minWidth: '230px',
    width: '30vw',
    lineHeight: '20px',
    textAlign: 'left',
    marginTop: '150px',
    fontSize: FontSize.body,
    letterSpacing: '2px',
    display: 'inline-block',
    verticalAlign: 'top',
    marginBottom: '100px',
    padding: '20px',
  },
  textMobileContainer: {
    width: '75vw',
    lineHeight: '20px',
    textAlign: 'left',
    marginTop: '50px',
    fontSize: FontSize.body,
    letterSpacing: '2px',
    verticalAlign: 'top',
    marginBottom: '100px',
    padding: '20px',
  },
  title: {
    fontWeight: 'bold',
  },
  text: {
    lineHeight: '30px',
    marginTop: '36px',
  },
  checkContent: {
    marginTop: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
  },
  check: {
    display: 'inline-block',
  },
  checkIcon: {
    marginRight: '5px',
  },
};


const InstructionItem = ({
  title,
  instructions,
  checkList,
  containerStyle,
}) => (
  <div style={{ ...styles.textContainer, ...containerStyle }}>
    <div style={styles.title}>{title}</div>
    <div style={styles.text}>
      {instructions.split('\n').map(item => (
        <div key={item}>{item}</div>
      ))}
    </div>
    <div style={styles.checkContent}>
      {
        checkList.map(checkItem => (
          <div
            key={checkItem}
            style={styles.check}
          >
            <Icon style={styles.checkIcon} type="check" />
            {checkItem}
          </div>
        ))
      }
    </div>
  </div>
);

const RentalSlide = ({
  imgUrl,
  index,
}) => {
  const renderSlideContent = (containerStyle) => {
    switch (index) {
      case 0:
        return (
          <InstructionItem
            title="開始出借"
            instructions="輕鬆創造 屬於自己的時尚品味"
            checkList={[
              '權威鑑定-平台包款皆經由專業鑑定，確保商品品質',
              '完整押金制度，商品歸還後即退還款項',
              '優質物流-物流人員到府收送件，省時又方便',
            ]}
            containerStyle={containerStyle}
          />
        );
      case 1:
        return (
          <InstructionItem
            title="開始出售"
            instructions="共享時尚品味 讓單品裝載更多回憶"
            checkList={[
              '專業上架服務-由平台人員拍攝專業商品照與上架服務',
              '優質物流-黑貓物流專人到府收送件，省時又方便',
              '輕鬆提款-收到通知即可申請提款，簡單進帳',
            ]}
            containerStyle={containerStyle}
          />
        );
      case 2:
        return (
          <InstructionItem
            title="同時出借與出售"
            instructions="在單品等待下一個主人同時，也提供短期租借的選項，創造雙重收益，達成環保與時尚兼具的愛地球行動"
            containerStyle={containerStyle}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div style={styles.mainContainer}>
      <Default>
        <div style={styles.imgContainer}>
          <img alt="" src={imgUrl} style={styles.img} />
        </div>
        <div style={styles.instructionContainer}>
          {renderSlideContent()}
        </div>
      </Default>
      <Mobile>
        <div style={styles.imgMobileContainer}>
          <img alt="" src={imgUrl} style={styles.imgMobile} />
        </div>
        <div style={styles.instructionContainer}>
          {renderSlideContent(styles.textMobileContainer)}
        </div>
      </Mobile>
    </div>
  );
};


InstructionItem.propTypes = {
  title: PropTypes.string,
  instructions: PropTypes.string,
  checkList: PropTypes.array,
  containerStyle: PropTypes.object,
};

InstructionItem.defaultProps = {
  title: null,
  instructions: null,
  checkList: [],
  containerStyle: {},
};


RentalSlide.propTypes = {
  imgUrl: PropTypes.string,
  index: PropTypes.number,
};

RentalSlide.defaultProps = {
  imgUrl: '',
  index: null,
};

const MemoRentalSlide = memo(RentalSlide);

export default MemoRentalSlide;
