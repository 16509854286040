/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Spin, Icon } from 'antd';
import * as R from 'ramda';
import moment from 'moment';

import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import { orderStatus, orderStatusId } from '../../../../constants/OrderStatus';
import Paths from '../../../../routes/Paths';
import MemberHeader from '../../../Member/components/MemberHeader';
import OrderDetailTableComponentV2 from '../OrderDetailTableComponentV2';
import ModalComponent from '../../../../components/ModalComponent';
import TransactionPage from '../../../Transaction/components/TransactionPage';
import ZeroCardTransactionPage from '../../../Transaction/components/ZeroCardTransactionPage';
import ApplePayTransactionPage from '../../../Transaction/components/ApplePayTransactionPage';
import Button from '../../../../components/Button';
import EndModalComponent from '../EndModalComponent';
import { Colors } from '../../../../constants/Color';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import ReduxStatus from '../../../../constants/ReduxStatus';

const styles = {
  reduxStyle: {
    width: '100%',
  },
  mainContainer: {
    paddingTop: 200,
    margin: '0px auto',
    width: '100%',
  },
  mainMobileContainer: {
    paddingTop: 120,
    margin: '0px auto',
    width: '100%',
  },
  table: {
    width: 1000,
    border: '1px solid black',
  },
  orderItemContainer: {
    margin: '0 auto 200px auto',
    width: '90%',
    overflowY: 'auto',
  },
  mainTable: {
    tableLayout: 'fixed',
    borderTop: '1px solid #BABABA',
    borderBottom: '1px solid #BABABA',
    width: '100%',
    minWidth: '900px',
  },
  header: {
    borderBottom: '1px solid #BABABA',
  },
  thead: {
    borderBottom: '1px solid #BABABA',
  },
  buyButton: {
    width: '95px',
    height: '40px',
    fontSize: '12px',
  },
  narrowColumn: {
    width: '5%',
    textAlign: 'center',
  },
  buyColumn: {
    width: '10%',
    textAlign: 'center',
  },
  nanoColumn: {
    width: '1%',
    textAlign: 'center',
  },
  normalColumn: {
    padding: 10,
    width: '15%',
  },
  orderStatusColumn: {
    width: '17%',
    textAlign: 'center',
    alignItems: 'center',
  },
  createBuyOrderButton: {
    width: '100px',
    height: '40px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '5px',
  },
  crossIcon: {
    color: 'white',
    fontSize: '30px',
  },
  goBackButtonContainer: {
    position: 'absolute',
    left: '50%',
    bottom: '-130px',
    transform: 'translate(-50%)',
  },
  goBackButton: {
    backgroundColor: 'white',
    color: '#888888',
    border: `2px ${Colors.introRightTitle} solid`,
  },
  icon: {
    fontSize: 20,
    color: '#927764',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '100px 0px',
  },
};

const OrderListPage = ({
  orderList,
  orderListError,
  orderListReduxStatus,
  onSubmitCreateBuyOrder,
  orderCreateError,
  orderCreateReduxStatus,
  setTransactionModalId,
  transactionModalId,
  isShowTransactionConfirmModal,
  onModalConfirmed,
  transactionConfirmModalContent,
  setIsEndTransaction,
  isShowEndConfirmModal,
  orderUpdateReduxStatus,
}) => {
  const [payText, setPayText] = useState('');
  const [orderListIndex, setOrderListIndex] = useState(null);
  const [isPayByZeroCard, setIsPayByZeroCard] = useState(false);
  const [payWay, setPayWay] = useState(0);

  const renderOperate = (
    id,
    status,
    typeName,
    zeroCardInstallment,
    zeroCardStatus,
    payWayType,
    index,
    buttonStyle,
  ) => {
    if (orderStatus.create === status && !zeroCardStatus) {
      const buttonText = () => {
        if (typeName === 'buy' && zeroCardInstallment) return '零卡支付購買價格';

        if (typeName === 'buy') return '支付購買價格';

        return '預刷押金';
      };


      if (typeName === 'buy') {
        setIsEndTransaction(true);
      } else {
        setIsEndTransaction(false);
      }

      const checkIsUsingZeroCart = () => {
        if (zeroCardInstallment) {
          return setIsPayByZeroCard(true);
        }
        return setIsPayByZeroCard(false);
      };

      return (
        <div>
          <Button
            text={buttonText()}
            style={{ ...styles.buyButton, ...buttonStyle }}
            onClick={() => {
              setPayWay(payWayType);
              setTransactionModalId(id);
              setPayText(buttonText());
              setOrderListIndex(index);
              checkIsUsingZeroCart();
            }}
          />
        </div>
      );
    } if (orderStatus.hadPaidDeposit === status) {
      const buttonText = '支付租金';
      setIsEndTransaction(true);
      return (
        <Button
          text={buttonText}
          style={styles.buyButton}
          onClick={() => {
            setPayWay(payWayType);
            setTransactionModalId(id);
            setPayText(buttonText);
            setOrderListIndex(index);
            // GTag implant
            window.gtag_report_conversion();
          }}
        />
      );
    }

    if (
      status === orderStatus.create && (zeroCardStatus === '003' || zeroCardStatus === '004')) {
      return ('零卡審核中');
    }
    if (
      status === orderStatus.confirming
        || status === orderStatus.confirmed
        || status === orderStatus.lenderSent
        || status === orderStatus.renterSent
        || status === orderStatus.lenderReceive
        || status === orderStatus.shipSuccess_returnDeposit) {
      return (<Icon type="check-circle" theme="filled" style={styles.icon} />);
    }

    return (<div>{status}</div>);
  };


  const renderRentToBuyButton = (sourceOrder) => {
    // orderType === 'rent' &&
    // OrderStatus is complete &&
    // BagType === 'rentBuy' &&
    // isCreateBuyOrder === false &&
    // is in rented period

    if (sourceOrder !== null) {
      const {
        userCouponId,
        coupon,
        ...order
      } = sourceOrder;
      if (R.pathOr('', ['bag', 'type', 'name'], order) === 'rentBuy'
        && R.pathOr('', ['type', 'name'], order) === 'rent'
        && R.pathOr(0, ['orderStatusId'], order) >= orderStatusId.confirmed
        && moment().isBefore(moment(order.rentEndDate))
        && (!R.pathOr(true, ['isCreateBuyOrder'], order))) {
        return (
          <React.Fragment>
            <br />
            <Button
              type="submit"
              text="點我購買"
              style={styles.createBuyOrderButton}
              onClick={() => { onSubmitCreateBuyOrder(order, true); }}
            />
            <br />
          </React.Fragment>
        );
      }
    }
    return null;
  };

  const goBack = () => {
    window.history.back();
  };


  const renderPayContent = () => {
    if (isPayByZeroCard) {
      return (
        <ZeroCardTransactionPage
          orderId={String(transactionModalId)}
        />
      );
    }

    if (payWay === 2) {
      return (
        <ApplePayTransactionPage
          orderId={String(transactionModalId)}
        />
      );
    }

    return (
      <TransactionPage
        orderId={String(transactionModalId)}
      />
    );
  };
  const content = (
    <ReduxStatusVisualizedComponent
      containerStyle={styles.reduxStyle}
      showModalOnError
      modalFailureContent={orderListError || orderCreateError}
      reduxStatus={[orderListReduxStatus, orderCreateReduxStatus]}
    >
      {
        transactionModalId > 0 && (
        <ModalComponent
          key="modal"
          isVisible
          onModalClose={() => { setTransactionModalId(0); }}
          onModalConfirmed={() => { setTransactionModalId(0); }}
          title={payText}
          isShowFooter={false}
          content={renderPayContent()}
        />
        )}

      <ModalComponent
        onModalConfirmed={onModalConfirmed}
        content={transactionConfirmModalContent}
        isVisible={isShowTransactionConfirmModal}
        onModalClose={onModalConfirmed}
        maskClosable={false}
      />

      <ModalComponent
        onModalConfirmed={onModalConfirmed}
        content={(
          <EndModalComponent
            orderList={orderList}
            onModalConfirmed={onModalConfirmed}
            orderListIndex={orderListIndex}
          />
        )}
        isVisible={isShowEndConfirmModal}
        onModalClose={onModalConfirmed}
        closeIcon={(
          <Icon
            type="close"
            style={styles.crossIcon}
          />
        )}
        width={400}
        isShowFooter={false}
        maskClosable={false}
      />

      <MemberHeader
        reduxStatus
        path={Paths.ORDER_LIST}
        category="transactionRecord"
      />

      {
   orderUpdateReduxStatus === ReduxStatus.STATUS_LOADING ? (
     <div style={styles.loadingContainer}>
       <Spin tip="資料傳送中，請稍候..." spinning />
     </div>
   ) : (
     <div style={styles.orderItemContainer}>
       <Default>
         <table style={styles.mainTable}>
           <thead style={styles.thead}>
             <tr style={styles.header}>
               <td style={styles.narrowColumn}>商品圖片</td>
               <td style={styles.narrowColumn}>付款狀態</td>
               <td style={styles.narrowColumn}>交易類別</td>
               <td style={styles.orderStatusColumn}>訂單狀態</td>
               <td style={styles.narrowColumn}>評論</td>
               <td style={styles.buyColumn}>購買</td>
               <td style={styles.nanoColumn} />
             </tr>
           </thead>
         </table>
         { orderList !== null ? orderList.map((order, index) => (
           <OrderDetailTableComponentV2
             key={order.id}
             index={index}
             order={order}
             renderOperate={renderOperate}
             renderRentToBuyButton={renderRentToBuyButton}
           />
         )) : null}
       </Default>
       <Mobile>
         { orderList !== null ? orderList.map((order, index) => (
           <OrderDetailTableComponentV2
             key={order.id}
             index={index}
             order={order}
             renderOperate={renderOperate}
             renderRentToBuyButton={renderRentToBuyButton}
           />
         )) : null}
       </Mobile>

       <div style={styles.goBackButtonContainer}>
         <Button
           text="回上一頁"
           style={styles.goBackButton}
           onClick={goBack}
         />
       </div>
     </div>
   )
}

    </ReduxStatusVisualizedComponent>
  );
  return (
    <React.Fragment>
      {/* GTag implant */}
      <Helmet>
        <script>
          {'gtag(\'event\', \'conversion\', {\'send_to\': \'AW-627439533/YJlKCK6vo9IBEK3vl6sC\'})'}
        </script>
        <script>
          {`
            function gtag_report_conversion(url) {
              var callback = function () {
                if (typeof(url) != 'undefined') {
                  window.location = url;
                }
              };
              gtag('event', 'conversion', {
                  'send_to': 'AW-627439533/uWAICIq8mNMBEK3vl6sC',
                  'transaction_id': '',
                  'event_callback': callback
              });
              return false;
            }          
      `}
        </script>
      </Helmet>
      <Default>
        <div style={styles.mainContainer} key="baseContainer">
          {content}
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer} key="baseContainer">
          {content}
        </div>
      </Mobile>
    </React.Fragment>
  );
};

OrderListPage.propTypes = {
  orderList: PropTypes.array,
  orderListReduxStatus: PropTypes.string,
  orderListError: PropTypes.string,
  onSubmitCreateBuyOrder: PropTypes.func.isRequired,
  orderCreateError: PropTypes.string,
  orderCreateReduxStatus: PropTypes.string,
  setTransactionModalId: PropTypes.func.isRequired,
  transactionModalId: PropTypes.number,
  isShowTransactionConfirmModal: PropTypes.bool.isRequired,
  isShowEndConfirmModal: PropTypes.bool.isRequired,
  onModalConfirmed: PropTypes.func.isRequired,
  setIsEndTransaction: PropTypes.func.isRequired,
  transactionConfirmModalContent: PropTypes.string.isRequired,
  orderUpdateReduxStatus: PropTypes.string,
};

OrderListPage.defaultProps = {
  orderList: [],
  orderListReduxStatus: null,
  orderListError: '',
  orderCreateError: null,
  orderCreateReduxStatus: null,
  transactionModalId: 0,
  orderUpdateReduxStatus: '',
};

export default OrderListPage;
