import * as R from 'ramda';
import { createSelector } from 'reselect';
import { bagStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  bagStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  bagStateSelector,
  result => R.path(['error', 'message'], result),
);


export const bagListSelector = createSelector(
  bagStateSelector,
  result => R.path(['data'], result),
);

export const onlineWardrobeSelector = createSelector(
  bagStateSelector,
  result => R.path(['onlineWardrobe'], result),
);

export const getBagIdByBagNoSelector = createSelector(
  bagStateSelector,
  result => R.path(['selectBagId'], result),
);

export const filterConditionSelector = createSelector(
  bagStateSelector,
  result => R.path(['filterCondition'], result),
);
export const commentListSelector = createSelector(
  bagStateSelector,
  result => R.path(['commentList'], result),

);

export const searchBagListByKeywordSelector = createSelector(
  bagStateSelector,
  result => R.pathOr([], ['searchByKeyword'], result),
);


export const getRentDateListSelector = createSelector(
  bagStateSelector,
  result => R.path(['rentDateList'], result),
);

export const getReleaseDateSelector = createSelector(
  bagStateSelector,
  result => R.path(['releaseDate'], result),
);

export const bagNumberSelector = createSelector(
  bagStateSelector,
  result => R.path(['bagNumbers'], result),
);
