import React, { useState } from 'react';
import update from 'immutability-helper/index';
import {
  Icon,
} from 'antd/lib/index';
import Assets from '../../constants/Assets';

const styles = {
  mainContainer: {
    width: '100%',
    height: 'auto',
  },
  itemContainer: {
    padding: '15px 0px 0px 0px',
    height: 'auto',
    width: '100%',
  },
  icon: {
    width: 20,
    height: 'auto',
    marginLeft: '15px',
    objectFit: 'cover',
  },
  subTitle: {
    textAlign: 'start',
    margin: '10px 0px 10px 40px',
    height: 'fit-content',
    color: 'black',
  },
  subTitleHidden: {
    display: 'none',
  },
  divider: {
    height: 2,
    width: '100%',
    backgroundColor: '#E1E1E1',
    margin: '0px 0px',
  },
  itemUpperContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 15,
  },
  title: {
    marginLeft: 20,
    width: '100%',
    textAlign: 'start',
    fontSize: 15,
    color: 'black',
    fontWeight: 'bold',
  },
  anchor: {
    width: 20,
    height: 20,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    marginRight: '15px',
  },
  backgroundColor: {
    backgroundColor: '#F8F8F8',
  },
};

const GuaranteeCollapseComponent = () => {
  const collapseVisible = {
    1: false,
    2: false,
    3: false,
    4: false,
  };

  const [selectedItem, setSelectedItem] = useState(collapseVisible);

  const data = [
    {
      icon: Assets.bagDetailIcon01,
      title: 'Sustainable Fashion 永續時尚',
      subTitle: (
        <React.Fragment>
          環保意識覺醒 · 讓我們跟地球一起漂亮
          <span role="img" aria-label="starts"> ✨</span>
          <br />
          G
          <span role="img" aria-label="planet"> 🌍</span>
          <span role="img" aria-label="planet"> 🌍 </span>
          D FOR YOU AND THE PLANET
        </React.Fragment>
      ),
      id: '1',
    },
    {
      icon: Assets.bagDetailIcon02,
      title: 'Sharing Better 共享經濟',
      subTitle: (
        <React.Fragment>
          閒置單品新價值 · 擁抱綠色時尚
          <span role="img" aria-label="leafs"> 🍃</span>
          <br />
          共享創造利潤 · 同時減少能源消耗
        </React.Fragment>
      ),
      id: '2',
    },
    {
      icon: Assets.bagDetailIcon03,
      title: 'ID Verification 實名認證',
      subTitle: (
        <React.Fragment>
          嚴格審核會員資料 · 創造安全的共享環境
          <span role="img" aria-label="safe"> 🔐 </span>
        </React.Fragment>
      ),
      id: '3',
    },
    {
      icon: Assets.bagDetailIcon04,
      title: 'Online Rental 自助租借平台',
      subTitle: (
        <React.Fragment>
          精美的線上衣櫥 · 一手擁有
          <span role="img" aria-label="heart"> 💖 </span>
          <br />
          無限的時尚風采 · 等妳探索
          <span role="img" aria-label="stars"> ✨ </span>
        </React.Fragment>
      ),
      id: '4',
    },
  ];

  const onClickAnchor = (id) => {
    setSelectedItem(update(selectedItem, { $toggle: [id] }));
  };

  const renderItem = item => (
    <div key={item.id} style={styles.itemContainer}>
      <div style={styles.itemUpperContainer}>
        <img src={item.icon} style={styles.icon} alt="" />
        <div style={styles.title}>
          {item.title}
        </div>
        <button
          onClick={() => onClickAnchor(item.id)}
          type="button"
          style={styles.anchor}
        >
          {selectedItem[item.id]
            ? <Icon type="up" />
            : <Icon type="down" />
          }
        </button>
      </div>
      {
        selectedItem[item.id]
          ? <div style={styles.subTitle}>{item.subTitle}</div>
          : null
      }
      <div style={styles.divider} />
    </div>
  );

  return (
    <div style={styles.mainContainer}>
      <div style={styles.divider} />
      {data.map(item => renderItem(item))}
    </div>
  );
};

export default GuaranteeCollapseComponent;
