import api from '../../lib/configAxios';

export default {
  getNegotiationList: async bagId => api.get(
    `Negotiations/getNegotiations?bagId=${bagId}`,
  ),

  submitNegotiation: async data => api.post(
    'Negotiations/submitNegotiation', data,
  ),

  negotiationWithOhPhire: async data => api.post(
    'Negotiations/withOhPhire', data,
  ),

  acceptAmount: async data => api.post(
    'Negotiations/acceptAmount', data,
  ),

  replyToMessage: async data => api.post(
    'Negotiations/reply', data,
  ),

};
