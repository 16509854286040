import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';
import ActionCreators from '../../ActionCreator';
import CartActionCreators from '../../../Cart/ActionCreator';
import MemberActionCreators from '../../../Member/ActionCreator';
import BagDetailPage from '../../../../components/CommodityDetailPageComponet/BagDetailPage';
import * as LocalStorageUtils from '../../../../utils/LocalStorageUtils';
import LocalStorageKey from '../../../../constants/LocalStorageKey';

import {
  reduxStatusSelector,
  reduxErrorSelector,
  bagListSelector,
  commentListSelector,
  getReleaseDateSelector,
  getBagIdByBagNoSelector,
  getRentDateListSelector,
  onlineWardrobeSelector,
} from '../../selector';

import {
  cartListSelector,
  cartReduxStatusSelector,
  cartReduxErrorSelector,
} from '../../../Cart/selector';

import {
  isLogInSelector,
  memberIdSelector,
  memberPassbaseVerifySelector,
  memberEmailSelector,
  memberProfileSelector,
} from '../../../Member/selector';

const BagDetailPageContainer = ({
  reduxStatus,
  reduxError,
  cartReduxStatus,
  cartReduxError,
  data,
  commentList,
  selectBagId,
  bagNo,
  memberId,
  isLogin,
  bagReleaseDate,
  rentDateList,
  actions: {
    resetStatus,
    getBagListFlowRequest,
    notifyWhenArrivedFlowRequest,
    addCartFlowRequest,
    deleteCartFlowRequest,
    getCommentListFlowRequest,
    getBagReleaseDateFlowRequest,
    getBagIdByBagNoFlowRequest,
    getBagRentDateListFlowRequest,
    getOnlineWardrobeFlowRequest,
    passbaseNewAuthenticationFlowRequest,
    getMemberProfileFlowRequest,
  },
  cartList,
  isVerify,
  commodityType,
  onlineWardrobe,
  memberEmail,
  memberProfile,
}) => {
  const [cartId, setCartId] = useState('');
  const [bagId, setBagId] = useState(null);

  useEffect(() => {
    if (cartList != null && bagId !== null) {
      setCartId(R.pathOr('', ['id'], R.filter(cart => R.pathOr('', ['bagId'], cart) === Number(bagId), cartList)[0]));
    }
  }, [cartList]);

  useEffect(() => {
    getBagIdByBagNoFlowRequest(bagNo);
  }, [bagNo]);

  useEffect(() => {
    setBagId(selectBagId);
  }, [selectBagId]);

  useEffect(() => {
    getMemberProfileFlowRequest(memberId);
  }, []);

  const isInCart = !!cartId;

  const [bagData, setBagData] = useState(null);

  const findBag = () => (data || []).find(bag => `${bag.id}` === `${bagId}`);

  useEffect(() => {
    if ((!bagData || bagData.id !== bagId)) {
      setBagData(findBag());
    }
  }, [data, bagId]);


  useEffect(() => {
    if (bagData) {
      const ownerId = R.pathOr(0, ['ownerId'], bagData);
      getOnlineWardrobeFlowRequest({ ownerId });
    }
  }, [bagData]);

  useEffect(() => {
    if (!findBag() && bagId !== null) {
      getBagListFlowRequest({
        where: {
          id: bagId,
        },
        commodityType,
      });
    }
    return resetStatus;
  }, [bagId]);


  useEffect(() => {
    if (bagId !== null) {
      getCommentListFlowRequest(bagId);
      getBagRentDateListFlowRequest(bagId);
    }
  }, [bagId]);

  const handleNotifyMeWhenArrived = () => {
    notifyWhenArrivedFlowRequest({
      bagId,
    });
  };

  const handleOnAddToCart = () => {
    addCartFlowRequest(bagId);
  };


  const handleOnRemoveFromCart = () => {
    deleteCartFlowRequest(cartId);
  };

  const getReleaseDate = () => {
    getBagReleaseDateFlowRequest(bagId);
  };

  const rentAmountPerDay = R.pathOr(0, ['rentAmountPerDay'], bagData);

  const handleOnSaveOrderCreateInfo = (payload) => {
    LocalStorageUtils.writeData(LocalStorageKey.ORDER_CREATE_INFO, JSON.stringify(payload));
  };

  const handleOnPassbaseAuthentication = (authKey) => {
    passbaseNewAuthenticationFlowRequest({ authenticationKey: authKey });
  };

  return (
    <BagDetailPage
      memberId={memberId}
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      cartReduxStatus={cartReduxStatus}
      cartReduxError={cartReduxError}
      resetStatus={resetStatus}
      data={bagData}
      bagId={bagId}
      isLogin={isLogin}
      handleNotifyMeWhenArrived={handleNotifyMeWhenArrived}
      isInCart={isInCart}
      addToCart={handleOnAddToCart}
      removeFromCart={handleOnRemoveFromCart}
      commentList={commentList}
      getReleaseDate={getReleaseDate}
      bagReleaseDate={bagReleaseDate}
      rentAmountPerDay={rentAmountPerDay}
      rentDateList={rentDateList}
      onSaveOrderCreateInfo={handleOnSaveOrderCreateInfo}
      isVerify={isVerify}
      onlineWardrobe={onlineWardrobe}
      memberEmail={memberEmail}
      onPassbaseAuthentication={handleOnPassbaseAuthentication}
      memberProfile={memberProfile}
    />
  );
};

BagDetailPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  cartReduxStatus: PropTypes.string,
  cartReduxError: PropTypes.string,
  cartList: PropTypes.array,
  isLogin: PropTypes.bool,
  selectBagId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  bagNo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  memberId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  data: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    getBagListFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    notifyWhenArrivedFlowRequest: PropTypes.func.isRequired,
    deleteCartFlowRequest: PropTypes.func.isRequired,
    addCartFlowRequest: PropTypes.func.isRequired,
    getCommentListFlowRequest: PropTypes.func.isRequired,
    getBagReleaseDateFlowRequest: PropTypes.func.isRequired,
    getBagIdByBagNoFlowRequest: PropTypes.func.isRequired,
    getBagRentDateListFlowRequest: PropTypes.func.isRequired,
    getOnlineWardrobeFlowRequest: PropTypes.func.isRequired,
    passbaseNewAuthenticationFlowRequest: PropTypes.func.isRequired,
    getMemberProfileFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  commentList: PropTypes.array,
  rentDateList: PropTypes.array,
  bagReleaseDate: PropTypes.string,
  isVerify: PropTypes.bool,
  commodityType: PropTypes.number.isRequired,
  onlineWardrobe: PropTypes.array,
  memberEmail: PropTypes.string,
  memberProfile: PropTypes.object.isRequired,
};

BagDetailPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  cartReduxStatus: null,
  cartReduxError: null,
  isLogin: false,
  cartList: [],
  commentList: [],
  rentDateList: [],
  bagReleaseDate: '',
  memberId: 0,
  selectBagId: 0,
  isVerify: false,
  onlineWardrobe: [],
  memberEmail: '',
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  cartReduxStatus: cartReduxStatusSelector(state),
  cartReduxError: cartReduxErrorSelector(state),
  data: bagListSelector(state),
  isLogin: isLogInSelector(state),
  cartList: cartListSelector(state),
  commentList: commentListSelector(state),
  bagReleaseDate: getReleaseDateSelector(state),
  memberId: memberIdSelector(state),
  selectBagId: getBagIdByBagNoSelector(state),
  rentDateList: getRentDateListSelector(state),
  isVerify: memberPassbaseVerifySelector(state),
  onlineWardrobe: onlineWardrobeSelector(state),
  memberEmail: memberEmailSelector(state),
  memberProfile: memberProfileSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...CartActionCreators,
    ...MemberActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BagDetailPageContainer);
