import api from '../../lib/configAxios';

export default {
  uploadFile: async data => api.post(
    'Resources/upload',
    data,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  ),
};
