import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import { Colors } from '../../../../constants/Color';

const styles = {
  mainContainer: {
    width: 370,
    height: 320,
    padding: 20,
    position: 'relative',
    zIndex: 999,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 35,
    color: 'black',
    marginBottom: 30,
    marginTop: 30,
  },
  subtitle: {
    fontSize: 15,
  },
  confirmWithdraw: {
    width: 'fit-content',
    height: 'fit-content',
    padding: '3px 20px',
    color: 'black',
    border: '1px solid #CABCB3',
    fontWeight: 150,
    backgroundColor: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    marginTop: 30,
    marginBottom: 20,
  },
  confirmButton: {
    width: 'fit-content',
    height: 'fit-content',
    padding: '3px 20px',
    color: 'white',
    border: `1px solid ${Colors.introRightTitle}`,
    fontWeight: 150,
    backgroundColor: Colors.introRightTitle,
    outline: 'none',
    cursor: 'pointer',
    marginTop: 30,
    marginBottom: 20,
    margin: '30px 10px 20px 10px',
  },
  hr: {
    color: 'grey',
    width: '100%',
  },
  waringContainer: {
    border: '1px black solid',
    backgroundColor: '#D0D0D0',
    width: '80%',
    margin: '40px 0px',
    fontWeight: 600,
    color: 'black',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  waringTitle: {
    margin: '20px 0px',
  },
};

const ProductReturnConfirmModalComponent = ({
  isVisible,
  onModalClose,
  onLenderReceive,
}) => {
  const [isShowFinalConfirm, setIsShowFinalConfirm] = useState(false);

  const content = (
    <div style={styles.mainContainer}>
      {
        isShowFinalConfirm ? (
          <React.Fragment>
            <div style={styles.waringContainer}>
              <div style={styles.waringTitle}>提醒</div>
              <div style={styles.waringText}>按下確認後將無法更改</div>
              <div style={styles.waringText}>務必確認單品狀況完整喔</div>
              <div style={styles.buttonContainer}>
                <button
                  onClick={() => onLenderReceive()}
                  type="button"
                  style={styles.confirmButton}
                >
                  確認
                </button>
                <button
                  onClick={() => onModalClose()}
                  type="button"
                  style={styles.confirmButton}
                >
                  取消
                </button>
              </div>

            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div style={styles.title}>確認歸還</div>
            <div style={styles.subtitle}>按下即確認商品完整歸還</div>
            <div style={styles.subtitle}>將刷退租借方押金</div>
            <div style={styles.subtitle}>刷退押金後即可提領本次租金收益</div>
            <button
              onClick={() => setIsShowFinalConfirm(true)}
              type="button"
              style={styles.confirmWithdraw}
            >
              已確認
            </button>
          </React.Fragment>

        )
      }
      <hr style={styles.hr} />
    </div>
  );


  return (
    <InfoModalComponent
      isVisible={isVisible}
      content={content}
      onModalClose={onModalClose}
      containerStyle={{ paddingTop: 100 }}
    />
  );
};

ProductReturnConfirmModalComponent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onLenderReceive: PropTypes.func.isRequired,
};
export default ProductReturnConfirmModalComponent;
