import api from '../../lib/configAxios';

export default {
  getCartList: async filter => api.get(
    `Carts?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  deleteCart: async cartId => api.delete(
    `Carts/${cartId}`,
  ),

  addToCart: async data => api.post(
    'Carts', data,
  ),

};
