import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActionCreators from '../../ActionCreator';
import TransactionPage from './TransactionPage';
import OrderActionCreators from '../../../Order/ActionCreator';
import AppleTapPayService from '../../../../lib/AppleTapPayService';
import ReduxStatus from '../../../../constants/ReduxStatus';
import { orderStatus } from '../../../../constants/OrderStatus';

import {
  orderDetailErrorSelector,
  orderDetailDataStatusSelector,
  orderDetailReduxStatusSelector,
} from '../../../Order/selector';

import {
  transactionReduxStatusSelector,
  transactionErrorSelector,
} from '../../selector';


const ApplePayTransactionPageContainer = ({
  orderId,
  orderData,
  actions: {
    orderDetailFlowRequest,
    transactionFlowRequest,
    resetTransactionStatus,
  },
  orderDetailError,
  transactionError,
  orderDetailReduxStatus,
  transactionReduxStatus,
}) => {
  const getPrimeResult = (result) => {
    if (result.status === 0) {
      transactionFlowRequest({
        prime: result.prime,
        orderId,
      });
    }
  };

  useEffect(() => {
    orderDetailFlowRequest({ orderId });
  }, []);

  useEffect(async () => {
    if (orderData) {
      const {
        amount, deposit, typeId,
      } = orderData;
      const status = orderData.orderStatus;

      const renderAmount = () => {
        if (typeId === 2) {
          return amount;
        }

        if (status.name === orderStatus.create) { return deposit; }

        return amount;
      };
      await AppleTapPayService.setOrderAmount(renderAmount());
      await AppleTapPayService.loadScript(getPrimeResult);
    }
  }, [orderData]);


  const handleOnModalConfirm = () => {
    if (transactionReduxStatus === ReduxStatus.STATUS_NORMAL) {
      resetTransactionStatus();
    }
  };

  return (
    <TransactionPage
      orderDetailError={orderDetailError}
      transactionError={transactionError}
      orderData={orderData}
      transactionReduxStatus={transactionReduxStatus}
      orderDetailReduxStatus={orderDetailReduxStatus}
      onModalConfirm={handleOnModalConfirm}
    />
  );
};
ApplePayTransactionPageContainer.propTypes = {
  actions: PropTypes.shape({
    orderDetailFlowRequest: PropTypes.func.isRequired,
    transactionFlowRequest: PropTypes.func.isRequired,
    resetTransactionStatus: PropTypes.func.isRequired,
  }).isRequired,
  orderData: PropTypes.object,
  orderId: PropTypes.string,
  orderDetailReduxStatus: PropTypes.string,
  transactionReduxStatus: PropTypes.string,
  orderDetailError: PropTypes.object,
  transactionError: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...OrderActionCreators,
  }, dispatch),
});

ApplePayTransactionPageContainer.defaultProps = {
  orderData: null,
  orderDetailReduxStatus: null,
  transactionReduxStatus: null,
  orderDetailError: null,
  transactionError: null,
  orderId: '0',
};

const mapStateToProps = state => ({
  orderData: orderDetailDataStatusSelector(state),
  orderDetailReduxStatus: orderDetailReduxStatusSelector(state),
  transactionReduxStatus: transactionReduxStatusSelector(state),
  transactionError: transactionErrorSelector(state),
  orderDetailError: orderDetailErrorSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplePayTransactionPageContainer);
