import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getTagRequest,
  getTagSuccess,
  getTagFailure,
  getTagCategoryRequest,
  getTagCategorySuccess,
  getTagCategoryFailure,

  getTagFlowRequest,
  getTagFlowSuccess,
  getTagFlowFailure,
} = ActionCreator;

// ---------- TASKS ----------
export function* getTag(data) {
  yield put(getTagRequest(data));
  try {
    const result = (yield call(API.getTagList));
    yield put(getTagSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getTagFailure(error));
    throw error;
  }
}

export function* getTagCategory() {
  yield put(getTagCategoryRequest());
  try {
    const result = (yield call(API.getTagCategoryList));
    yield put(getTagCategorySuccess(result.data));
    return result;
  } catch (error) {
    yield put(getTagCategoryFailure(error));
    throw error;
  }
}

// ---------- FLOWS ----------
export function* getTagFlow({ payload }) {
  try {
    const tags = yield call(getTag, payload);
    const tagCategories = yield call(getTagCategory, payload);

    // NOTE: Build the dictionary
    const categorizedTagsDictionary = {};
    tagCategories.data.forEach((tagCategory) => {
      const {
        name,
        order,
        id,
      } = tagCategory;
      categorizedTagsDictionary[id] = {
        id,
        name,
        order,
        tags: [],
      };
    });
    tags.data.forEach((tag) => {
      const {
        name,
        tagCategoryId,
        order,
        id,
      } = tag;
      (R.pathOr([], [tagCategoryId, 'tags'], categorizedTagsDictionary)).push({
        name,
        order,
        id,
      });
    });

    // NOTE: Sorting...
    const sortingMethod = (a, b) => ((R.pathOr(0, ['order'], a)) - (R.pathOr(0, ['order'], b)));
    const categorizedTags = Object.keys(categorizedTagsDictionary).map((tagCategoryId) => {
      const tagCategory = categorizedTagsDictionary[tagCategoryId];
      tagCategory.tags = tagCategory.tags.sort(sortingMethod);
      return tagCategory;
    }).sort(sortingMethod);

    yield put(getTagFlowSuccess({
      categorizedTags,
      tags,
    }));
  } catch (error) {
    yield put(getTagFlowFailure(error));
  }
}


export default [
  takeLatest(getTagFlowRequest, getTagFlow),
];
