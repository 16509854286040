const assetsRoot = 'https://assets.ohphire.com/';

const getAssetsFullPath = path => `${assetsRoot}${path}`;

module.exports = {
  assetsRoot,
  logo: getAssetsFullPath('logo.png'),
  FAQBottom: getAssetsFullPath('FAQBottom.png'),
  FAQTopPic: getAssetsFullPath('FAQTopPic.png'),
  FAQTopPicMobile: getAssetsFullPath('FAQTopPicMobile.png'),
  privacyBottom: getAssetsFullPath('privacyBottom.png'),
  memberTerms: getAssetsFullPath('memberTerms.png'),
  iconPrimas: getAssetsFullPath('iconPrimas.png'),
  contactPage: getAssetsFullPath('contactPage.png'),
  bagListPage: getAssetsFullPath('bagListPage.png'),
  partnerTop: getAssetsFullPath('partnerTop.png'),
  logoBlackCat: getAssetsFullPath('logoBlackCat.png'),
  logoEntrupy: getAssetsFullPath('logoEntrupy.png'),
  logoPrimas: getAssetsFullPath('logoPrimas.png'),
  partnerBlackCat: getAssetsFullPath('partnerBlackCat.png'),
  partnerEntrupy: getAssetsFullPath('partnerEntrupy.png'),
  partnerPrimas: getAssetsFullPath('partnerPrimas.png'),
  aboutUsBackground: getAssetsFullPath('aboutUsBackground.png'),
  aboutUsFrontPic: getAssetsFullPath('aboutUsFrontPic.png'),
  howItWorksTop: getAssetsFullPath('howItWorksTop.png'),
  howItWorksClean: getAssetsFullPath('howItWorksClean.jpg'),
  howItWorksStep1: getAssetsFullPath('howItWorksStep1.png'),
  howItWorksStep2: getAssetsFullPath('howItWorksStep2.png'),
  howItWorksStep3: getAssetsFullPath('howItWorksStep3.png'),
  howItWorksLeftArrow: getAssetsFullPath('howItWorksLeftArrow.svg'),
  howItWorksRightArrow: getAssetsFullPath('howItWorksRightArrow.svg'),
  howItWorksLending: getAssetsFullPath('howItWorksLending.png'),
  howItWorksSell: getAssetsFullPath('howItWorksSell.png'),
  howItWorksLendingSell: getAssetsFullPath('howItWorksLendingSell.png'),
  howItWorksGoToProvide: getAssetsFullPath('howItWorksGoToProvide.jpg'),
  howItWorksGoToBuy: getAssetsFullPath('howItWorksGoToBuy.png'),
  howItWorksGoToRent: getAssetsFullPath('howItWorksGoToRent.png'),
  howItWorksEntrupy: getAssetsFullPath('howItWorksEntrupy.png'),
  howItWorksEntrupyNote: getAssetsFullPath('howItWorksEntrupyNote.jpg'),
  howItWorksBanner1: getAssetsFullPath('howItWorksBanner1.jpg'),
  howItWorksBanner2: getAssetsFullPath('howItWorksBanner2.jpg'),
  howItWorksBanner3: getAssetsFullPath('howItWorksBanner3.jpg'),
  howItWorksBannerMobile1: getAssetsFullPath('howItWorksBannerMobile1.jpg'),
  howItWorksBannerMobile2: getAssetsFullPath('howItWorksBannerMobile2.jpg'),
  howItWorksBannerMobile3: getAssetsFullPath('howItWorksBannerMobile3.jpg'),
  howItWorksTopPic: getAssetsFullPath('howItWorksTopPic.jpg'),
  howItWorksMobileTopPic: getAssetsFullPath('howItWorksMobileTopPic.jpg'),
  howItWorksBottomPic: getAssetsFullPath('howItWorksBottomPic.jpg'),
  howItWorksCleanForFree: getAssetsFullPath('howItWorksCleanForFree.png'),
  howItWorksCleanForFreeMobile: getAssetsFullPath('howItWorksCleanForFreeMobile.png'),
  howItWorksCleanMobile: getAssetsFullPath('howItWorksCleanMobile.jpg'),
  howItWorksStepPic: getAssetsFullPath('howItWorksStepPic.png'),
  howItWorksMemberNotePic: getAssetsFullPath('howItWorksMemberNotePic.png'),
  howItWorksCleanPic: getAssetsFullPath('howItWorksCleanPic.png'),
  howItWorksStepPicMobile: getAssetsFullPath('howItWorksStepPicMobile.png'),
  howItWorksStepMobile: getAssetsFullPath('howItWorksStepMobile.jpg'),
  howItWorksMemberNotePicMobile: getAssetsFullPath('howItWorksMemberNotePicMobile.png'),
  howItWorksCleanPicMobile: getAssetsFullPath('howItWorksCleanPicMobile.png'),
  provideUpload: getAssetsFullPath('provideUpload.png'),
  provideLending: getAssetsFullPath('provideLending.png'),
  provideLendingSell: getAssetsFullPath('provideLendingSell.png'),
  provideSell: getAssetsFullPath('provideSell.png'),
  provideClean: getAssetsFullPath('provideClean.png'),
  registerPic: getAssetsFullPath('registerPic.png'),
  registerMobilePic: getAssetsFullPath('registerMobilePic.png'),
  cartTitleIcon: getAssetsFullPath('cartTitleIcon.png'),
  orderCredit01: getAssetsFullPath('orderCredit01.png'),
  orderCredit02: getAssetsFullPath('orderCredit02.png'),
  orderCredit03: getAssetsFullPath('orderCredit03.png'),
  orderCredit04: getAssetsFullPath('orderCredit04.png'),
  homeMembership: getAssetsFullPath('homeMembership.png'),
  indexMember01: getAssetsFullPath('indexMember01.png'),
  indexMember02: getAssetsFullPath('indexMember02.png'),
  indexMember03: getAssetsFullPath('indexMember03.png'),
  indexMember04: getAssetsFullPath('indexMember04.png'),
  orderEnd: getAssetsFullPath('orderEnd.png'),
  protectTreeDialog: getAssetsFullPath('protectTreeDialog.png'),
  memberGrade: getAssetsFullPath('memberGrade.png'),
  memberStar: getAssetsFullPath('memberStar.png'),
  memberPoint: getAssetsFullPath('memberPoint.png'),
  bagBanner01: getAssetsFullPath('bagBanner01.gif'),
  bagBanner02: getAssetsFullPath('bagBanner02.jpg'),
  bagBanner03: getAssetsFullPath('bagBanner03.gif'),
  bagBannerMobile01: getAssetsFullPath('bagBannerMobile01.gif'),
  bagBannerMobile02: getAssetsFullPath('bagBannerMobile02.jpg'),
  bagBannerMobile03: getAssetsFullPath('bagBannerMobile03.gif'),
  ohPhireLogo: getAssetsFullPath('ohPhireLogo.png'),
  aboutUsIcon01: getAssetsFullPath('aboutUsIcon01.png'),
  aboutUsIcon02: getAssetsFullPath('aboutUsIcon02.png'),
  aboutUsIcon03: getAssetsFullPath('aboutUsIcon03.png'),
  aboutUs01: getAssetsFullPath('aboutUs01.jpg'),
  aboutUs02: getAssetsFullPath('aboutUs02.jpg'),
  aboutUs03: getAssetsFullPath('aboutUs03.png'),
  aboutUs04: getAssetsFullPath('aboutUs04.jpg'),
  aboutUs05: getAssetsFullPath('aboutUs05.png'),
  aboutUsMobile01: getAssetsFullPath('aboutUsMobile01.png'),
  aboutUsMobile02: getAssetsFullPath('aboutUsMobile02.png'),
  aboutUsMobile03: getAssetsFullPath('aboutUsMobile03.png'),
  aboutUsMobile04: getAssetsFullPath('aboutUsMobile04.png'),
  aboutUsMobile05: getAssetsFullPath('aboutUsMobile05.png'),
  aboutUsMobile06: getAssetsFullPath('aboutUsMobile06.png'),
  comingSoon: getAssetsFullPath('comingSoon.gif'),
  comingSoonMobile: getAssetsFullPath('comingSoonMobile.gif'),
  comingSoonLoginMobile: getAssetsFullPath('comingSoonLoginMobile.jpg'),
  comingSoonLogin: getAssetsFullPath('comingSoonLogin.jpg'),
  footIconLine: getAssetsFullPath('footIconLine.png'),
  bagDetailIcon01: getAssetsFullPath('bagDetailIcon01.png'),
  bagDetailIcon02: getAssetsFullPath('bagDetailIcon02.png'),
  bagDetailIcon03: getAssetsFullPath('bagDetailIcon03.png'),
  bagDetailIcon04: getAssetsFullPath('bagDetailIcon04.png'),
  badDetailKnowMoreBuying: getAssetsFullPath('badDetailKnowMoreBuying.png'),
  badDetailKnowMoreBuyingMobile: getAssetsFullPath('badDetailKnowMoreBuyingMobile.png'),
  homePageIgImage01: getAssetsFullPath('homePageIgImage01.png'),
  homePageIgImage02: getAssetsFullPath('homePageIgImage02.png'),
  homePageIgImage03: getAssetsFullPath('homePageIgImage03.png'),
  homePageIgImage04: getAssetsFullPath('homePageIgImage04.png'),
  homePageIgImage05: getAssetsFullPath('homePageIgImage05.png'),
  bagPageBanner01: getAssetsFullPath('bagPageBanner01.jpg'),
  bagPageBannerMobile01: getAssetsFullPath('bagPageBannerMobile01.jpg'),
  accessoriesPageBanner01: getAssetsFullPath('accessoriesPageBanner01.jpg'),
  accessoriesListBannerMobile: getAssetsFullPath('accessoriesListBannerMobile.jpg'),
  bagDetailButtonIcon: getAssetsFullPath('bagDetailButtonIcon.png'),
  providePic01: getAssetsFullPath('providePic01.png'),
  providePic02: getAssetsFullPath('providePic02.png'),
  providePic03: getAssetsFullPath('providePic03.png'),
  cart: getAssetsFullPath('cart.png'),
  commentStartDisActive: getAssetsFullPath('commentStartDisActive.svg'),
  commentStartActive: getAssetsFullPath('commentStartActive.svg'),
  bagSlideRightArrow: getAssetsFullPath('bagSlideRightArrow.svg'),
  bagSlideLeftArrow: getAssetsFullPath('bagSlideLeftArrow.svg.svg'),
  AvatarIcon: getAssetsFullPath('AvatarIcon.svg'),
  headerBadgeIcon01: getAssetsFullPath('headerBadgeIcon01.svg'),
  headerBadgeIcon02: getAssetsFullPath('headerBadgeIcon02.svg'),
  headerBadgeIcon03: getAssetsFullPath('headerBadgeIcon03.svg'),
  couponItemEnable: getAssetsFullPath('couponItemEnable.png'),
  couponItemDisable: getAssetsFullPath('couponItemDisable.png'),
  withdrawSuccessIcon: getAssetsFullPath('withdrawSuccessIcon.svg'),
  homePageHowItWorksImg01: getAssetsFullPath('homePageHowItWorksImg01.png'),
  homePageHowItWorksImg02: getAssetsFullPath('homePageHowItWorksImg02.png'),
  homePageHowItWorksImg03: getAssetsFullPath('homePageHowItWorksImg03.png'),
  homePageHowItWorksImgMobile01: getAssetsFullPath('homePageHowItWorksImgMobile01.png'),
  homePageHowItWorksImgMobile02: getAssetsFullPath('homePageHowItWorksImgMobile02.png'),
  homePageHowItWorksImgMobile03: getAssetsFullPath('homePageHowItWorksImgMobile03.png'),
  homePageHowItWorksIcon: getAssetsFullPath('homePageHowItWorksIcon.svg'),
  headerIcon01: getAssetsFullPath('headerIcon01.svg'),
  headerIcon02: getAssetsFullPath('headerIcon02.svg'),
  headerIcon03: getAssetsFullPath('headerIcon03.svg'),
  headerAbout01: getAssetsFullPath('headerAbout01.png'),
  headerAbout02: getAssetsFullPath('headerAbout02.png'),
  headerAbout03: getAssetsFullPath('headerAbout03.png'),
  whiteLogo: getAssetsFullPath('whiteLogo.svg'),
  uploadNotePic1: getAssetsFullPath('uploadNotePic1.jpg'),
  uploadNotePic2: getAssetsFullPath('uploadNotePic2.jpg'),
  uploadNotePic3: getAssetsFullPath('uploadNotePic3.jpg'),
  uploadLeftArrow: getAssetsFullPath('uploadLeftArrow.svg'),
  uploadRightArrow: getAssetsFullPath('uploadRightArrow.svg'),
  upLoad711: getAssetsFullPath('upLoad711.png'),
  upLoadBlackCat: getAssetsFullPath('upLoadBlackCat.png'),
  aboutOh01V2: getAssetsFullPath('aboutOh01_v2.png'),
  aboutOh02V2: getAssetsFullPath('aboutOh02_v2.png'),
  aboutOh03V2: getAssetsFullPath('aboutOh03_v2.png'),
  aboutOhMobile01V2: getAssetsFullPath('aboutOhMobile01_v2.png'),
  aboutOhMobile02V2: getAssetsFullPath('aboutOhMobile02_v2.png'),
  aboutOhMobile03V2: getAssetsFullPath('aboutOhMobile03_v2.png'),
  ohPhireLineQRCode: getAssetsFullPath('OhPhireLineQRCode.png'),
  homeRenting: getAssetsFullPath('homeRenting.png'),
  homePageAboutOh01: getAssetsFullPath('homePageAboutOh01.png'),
  homePageAboutOh02: getAssetsFullPath('homePageAboutOh02.png'),
  homePageAboutOh03: getAssetsFullPath('homePageAboutOh03.png'),
  homeRentingMobile: getAssetsFullPath('homeRentingMobile.png'),
  uploadPicNote01: getAssetsFullPath('uploadPicNote01.png'),
  uploadPicNote02: getAssetsFullPath('uploadPicNote02.png'),
  uploadPicNote03: getAssetsFullPath('uploadPicNote03.png'),
  uploadPicNote04: getAssetsFullPath('uploadPicNote04.png'),
  uploadMapIcon: getAssetsFullPath('uploadMapIcon.svg'),
  clotheBanner: getAssetsFullPath('clotheBanner.jpg'),
  bagListBannerMobile: getAssetsFullPath('bagListBannerMobile.jpg'),
  clothesListBannerMobile: getAssetsFullPath('clothesListBannerMobile.jpg'),
  howItWorksRentContent: getAssetsFullPath('howItWorksRentContent.png'),
  howItWorksLendContent: getAssetsFullPath('howItWorksLendContent.png'),
  howItWorksRentContentMobile: getAssetsFullPath('howItWorksRentContentMobile.png'),
  howItWorksLendContentMobile: getAssetsFullPath('howItWorksLendContentMobile.png'),
  sizeGuideMobile: getAssetsFullPath('sizeGuideMobile.png'),
  sizeGuide: getAssetsFullPath('sizeGuide.png'),
  passbaseVerifyNow: getAssetsFullPath('passbaseVerifyNow.png'),
  passbaseReVerify: getAssetsFullPath('passbaseReVerify.png'),
  passbaseVerifying: getAssetsFullPath('passbaseVerifying.png'),
  passbaseVerifySuccess: getAssetsFullPath('passbaseVerifySuccess.png'),
  passbaseVerifyNowMobile: getAssetsFullPath('passbaseVerifyNowMobile.png'),
  passbaseReVerifyMobile: getAssetsFullPath('passbaseReVerifyMobile.png'),
  passbaseVerifyingMobile: getAssetsFullPath('passbaseVerifyingMobile.png'),
  passbaseVerifySuccessMobile: getAssetsFullPath('passbaseVerifySuccessMobile.png'),
  registerImg: getAssetsFullPath('registerImg.png'),
  orderEndLogo: getAssetsFullPath('blackkkLogo-01.png'),
  memberShippingIcon: getAssetsFullPath('memberShippingIcon.png'),
  memberShippingStoreIcon: getAssetsFullPath('memberShippingStoreIcon.png'),
  opMangerIcon: getAssetsFullPath('opMangerIcon.png'),
  passsbaseVerifyModal01: getAssetsFullPath('passsbaseVerifyModal01.jpg'),
  passsbaseVerifyModal02: getAssetsFullPath('passsbaseVerifyModal02.jpg'),
  passsbaseLogo: getAssetsFullPath('passsbaseLogo.jpg'),
  passbaseVerifiedIcon01: getAssetsFullPath('passbaseVerifiedIcon01.png'),
  passbaseVerifiedIcon02: getAssetsFullPath('passbaseVerifiedIcon02.png'),
  passsbaseModalLogo: getAssetsFullPath('passsbaseModalLogo.png'),
  passsbaseVerifyModalMobile: getAssetsFullPath('passsbaseVerifyModalMobile.jpg'),
  OPManageMobile: getAssetsFullPath('OPManageMobile.png'),
  buyPageBanner01: getAssetsFullPath('buyPageBanner01.jpg'),
  buyPageBannerMobile: getAssetsFullPath('buyPageBannerMobile.jpg'),
  saveTry: getAssetsFullPath('saveTry.png'),
  homeBlock01: getAssetsFullPath('homeBlock01.jpg'),
  homeBlock02: getAssetsFullPath('homeBlock02.jpg'),
  homeBlock03: getAssetsFullPath('homeBlock03.jpg'),
  homeBlock04: getAssetsFullPath('homeBlock04.jpg'),
  homeBlock05: getAssetsFullPath('homeBlock05.jpg'),
  homeMobileBlock01: getAssetsFullPath('homeMobileBlock01.png'),
  homeMobileBlock02: getAssetsFullPath('homeMobileBlock02.png'),
  homeMobileBlock03: getAssetsFullPath('homeMobileBlock03.png'),
  homeMobileBlock04: getAssetsFullPath('homeMobileBlock04.png'),
  homeMobileBlock05: getAssetsFullPath('homeMobileBlock05.png'),
  homeBlockNoWords01: getAssetsFullPath('homeBlockNoWords01.jpg'),
  homeBlockNoWords02: getAssetsFullPath('homeBlockNoWords02.jpg'),
  homeBlockNoWords03: getAssetsFullPath('homeBlockNoWords03.jpg'),
  homeBlockNoWords04: getAssetsFullPath('homeBlockNoWords04.jpg'),
  homeBlockNoWords05: getAssetsFullPath('homeBlockNoWords05.jpg'),
  cloudsetNote: getAssetsFullPath('cloudsetNote.jpg'),
  hearFull: getAssetsFullPath('hearFull.png'),
  heartNotFull: getAssetsFullPath('heartNotFull.png'),
  allNewCircle: getAssetsFullPath('allNewCircle.png'),
  allNew: getAssetsFullPath('allNew.png'),
  zeroCardIcon: getAssetsFullPath('zeroCardIcon.png'),
  homePageHeaderPic: getAssetsFullPath('homePageHeaderPic.jpg'),
  homePageHeaderPicMobile: getAssetsFullPath('homePageHeaderPicMobile.jpg'),
  howHowItWorksButton01: getAssetsFullPath('howHowItWorksButton01.png'),
  howHowItWorksButton02: getAssetsFullPath('howHowItWorksButton02.png'),
  howHowItWorks01: getAssetsFullPath('howHowItWorks01.png'),
  howHowItWorks02: getAssetsFullPath('howHowItWorks02.png'),
  howHowItWorks03: getAssetsFullPath('howHowItWorks03.png'),
  howHowItWorks04: getAssetsFullPath('howHowItWorks04.png'),
  homeBlockVer201: getAssetsFullPath('homeBlockVer201.png'),
  homeBlockVer202: getAssetsFullPath('homeBlockVer202.png'),
  homeBlockVer203: getAssetsFullPath('homeBlockVer203.png'),
  homeBlockVer204: getAssetsFullPath('homeBlockVer204.png'),
  homeBlockVer205: getAssetsFullPath('homeBlockVer205.png'),
  homeBlockVer206: getAssetsFullPath('homeBlockVer206.png'),
  homeBlockVer207: getAssetsFullPath('homeBlockVer207.png'),
  homeBlockVer208: getAssetsFullPath('homeBlockVer208.png'),
  homeBlockMobileVer201: getAssetsFullPath('homeBlockMobileVer201.png'),
  homeBlockMobileVer202: getAssetsFullPath('homeBlockMobileVer202.png'),
  homeBlockMobileVer203: getAssetsFullPath('homeBlockMobileVer203.png'),
  homeBlockMobileVer204: getAssetsFullPath('homeBlockMobileVer204.png'),
  homeBlockMobileVer205: getAssetsFullPath('homeBlockMobileVer205.png'),
  homeMobileBlock06: getAssetsFullPath('homeMobileBlock06.png'),
  homeMobileBlock07: getAssetsFullPath('homeMobileBlock07.png'),
  homePageIcon01: getAssetsFullPath('homePageIcon01.png'),
  homePageIcon02: getAssetsFullPath('homePageIcon02.png'),
  homePageIcon03: getAssetsFullPath('homePageIcon03.png'),
  homePageIcon04: getAssetsFullPath('homePageIcon04.png'),
  homePageIcon05: getAssetsFullPath('homePageIcon05.png'),
  homePageIcon06: getAssetsFullPath('homePageIcon06.png'),
  OPMangerIcon: getAssetsFullPath('OPMangerIcon.png'),
  selfManagerIcon: getAssetsFullPath('selfManagerIcon.png'),
  homePageIcon08: getAssetsFullPath('homePageIcon08.png'),
  bagItemSliderLeftArrow: getAssetsFullPath('BagItemSliderLeftArrow2.png'),
  bagItemSliderRightArrow: getAssetsFullPath('BagItemSliderRightArrow2.png'),
  essencesHeader01: getAssetsFullPath('essencesHeader01.jpg'),
  essencesHeader02: getAssetsFullPath('essencesHeader02.jpg'),
  essencesHeader03: getAssetsFullPath('essencesHeader03.jpg'),
  fragranceBanner: getAssetsFullPath('fragranceBanner.png'),
  fragranceMobileBanner: getAssetsFullPath('fragranceMobileBanner.png'),
  allNewBuyPageBanner01: getAssetsFullPath('allNewBuyPageBanner01.jpg'),
  allNewBuyPageBannerMobile: getAssetsFullPath('allNewBuyPageBannerMobile.jpeg'),
  closeIcon: getAssetsFullPath('closeIcon.svg'),
  holdPhone: getAssetsFullPath('holdPhone.svg'),
  useVideo: getAssetsFullPath('useVideo.svg'),
  circle: getAssetsFullPath('circle.svg'),
  identifySuccess: getAssetsFullPath('identifySuccess.svg'),
  cancelMark: getAssetsFullPath('cancleMark.svg'),
  applePayIcon: getAssetsFullPath('applepay.jpg'),
};
