import * as R from 'ramda';
import { createSelector } from 'reselect';
import { headerCommodityStateSelector } from '../../lib/rootSelector';

export const headerCommodityReduxStatusSelector = createSelector(
  headerCommodityStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const headerCommodityReduxErrorSelector = createSelector(
  headerCommodityStateSelector,
  result => R.path(['error', 'message'], result),
);

export const headerCommodityListSelector = createSelector(
  headerCommodityStateSelector,
  result => R.path(['data'], result),
);

export const maqueeListSelector = createSelector(
  headerCommodityStateSelector,
  result => R.pathOr([], ['mequee'], result),
);
