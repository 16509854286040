import React, { useEffect } from 'react';
import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProductSendModalComponent from './ProductSendModalComponent';
import ActionCreators from '../../ActionCreator';
import { getOrderAddressSelector } from '../../selector';


const ProductSendModalComponentContainer = ({
  onModalClose,
  isVisible,
  order,
  userId,
  actions: {
    orderUpdateFlowRequest,
    getAddressFlowRequest,
  },
  orderAddress,
}) => {
  useEffect(() => {
    getAddressFlowRequest();
  }, []);

  const addressData = R.pathOr([], ['data'], orderAddress);


  const handleOnProductSend = (payload) => {
    const {
      isOwner, address, packageNo, shippingCompany,
    } = payload;
    if (isOwner) {
      orderUpdateFlowRequest({
        isFromOrderList: false,
        orderStatusId: 21,
        orderId: order.id,
        lenderSentAddress: address,
        lenderSentPackageNo: packageNo,
        lenderShippingCompany: shippingCompany,
      });
    } else {
      orderUpdateFlowRequest({
        isFromOrderList: true,
        orderStatusId: 22,
        orderId: order.id,
        renterSentAddress: address,
        renterSentPackageNo: packageNo,
        renterShippingCompany: shippingCompany,
      });
    }

    onModalClose();
  };

  return (
    <ProductSendModalComponent
      userId={userId}
      order={order}
      isVisible={isVisible}
      onModalClose={onModalClose}
      onProductSend={handleOnProductSend}
      addressData={addressData}
    />
  );
};


ProductSendModalComponentContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  order: PropTypes.object,
  userId: PropTypes.number,
  actions: PropTypes.shape({
    orderUpdateFlowRequest: PropTypes.func.isRequired,
    getAddressFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  orderAddress: PropTypes.array.isRequired,
};

ProductSendModalComponentContainer.defaultProps = {
  order: {},
  userId: null,
};

const mapStateToProps = state => ({
  orderAddress: getOrderAddressSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductSendModalComponentContainer);
