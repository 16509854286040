import api from '../../lib/configAxios';

export default {
  getClothesList: async (filter = { limit: 10, order: 'releaseDate DESC' }) => api.get(
    `Bags?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  notifyWhenArrived: async data => api.put(
    `Users/me/bagArrived/rel/${data.bagId}`,
  ),

  addClothesTag: async (data) => {
    const { bagId, tagId } = data;
    return api.put(
      `Bags/${bagId}/tags/rel/${tagId}`,
    );
  },

  getCommentList: async ({ bagId, filter }) => api.get(
    `Bags/${bagId}/comments?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  getClothesReleaseDate: async bagId => api.get(
    `Bags/${bagId}/releaseDate`,
  ),

  getClothesIdByClothesNo: async data => api.post(
    'Bags/getBagIdByBagNo', data,
  ),
};
