import React from 'react';

import Header from '../../Parts/Header';
import Title from '../../Parts/Title';

import {
  holdPhone,
} from '../../../../constants/Assets';

import style from './styles.module.scss';

const LandingPage = () => (
  <div className={style.container}>
    <Header />
    <div className={style.content}>
      <div className={style.titleContainer}>
        <img src={holdPhone} alt="holdPhone" className={style.holdPhone} />
      </div>
      <div className={style.titleContainer}>
        <Title>開始你的驗證 </Title>
      </div>
      <div className={style.titleContainer}>
        我們將引導您通過一個簡單的程序來驗證您的身份
      </div>
    </div>
  </div>
);

export default LandingPage;
