import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import MemberHeader from '../../../Member/components/MemberHeader';
import OrderDetailTableComponentV2 from '../OrderDetailTableComponentV2';
import ModalComponent from '../../../../components/ModalComponent';
import Button from '../../../../components/Button';
import Paths from '../../../../routes/Paths';
import { Colors } from '../../../../constants/Color';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import ReduxStatus from '../../../../constants/ReduxStatus';

const styles = {
  table: {
    width: 1000,
    border: '1px solid black',
  },
  mainContainer: {
    paddingTop: 200,
    margin: '0px auto',
    width: '100%',
  },
  mainMobileContainer: {
    paddingTop: 120,
    margin: '0px auto',
    width: '100%',
  },
  orderItemContainer: {
    margin: '0 auto 200px auto',
    width: '90%',
    overflowY: 'auto',
  },
  mainTable: {
    tableLayout: 'fixed',
    borderTop: '1px solid #BABABA',
    borderBottom: '1px solid #BABABA',
    width: '100%',
    minWidth: '900px',
  },
  orderStatusColumn: {
    width: '17%',
    textAlign: 'center',
  },
  thead: {
    borderBottom: '1px solid #BABABA',
  },
  buyButton: {
    width: '95px',
    height: '40px',
    fontSize: '12px',
  },
  narrowColumn: {
    width: '5%',
    textAlign: 'center',
  },
  nanoColumn: {
    width: '3%',
    textAlign: 'center',
  },
  normalColumn: {
    padding: 10,
    width: '15%',
  },
  goBackButtonContainer: {
    position: 'absolute',
    left: '50%',
    bottom: '-130px',
    transform: 'translate(-50%)',
  },
  goBackButton: {
    backgroundColor: 'white',
    color: '#888888',
    border: `2px ${Colors.introRightTitle} solid`,
  },
  header: {
    borderBottom: '1px solid #BABABA',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '100px 0px',
  },
};

const MyRentedSoldOrderListPage = ({
  userId,
  orderList,
  orderListError,
  orderListReduxStatus,
  withdrawRequestError,
  withdrawRequestReduxStatus,
  orderUpdateReduxStatus,
  onSubmitWithdrawRequest,
  isShowWithdrawRequestSuccess,
  onModalConfirmed,
  onConfirmOrder,
  isVerify,
}) => {
  const goBack = () => {
    window.history.back();
  };

  const onClickConfirmOrder = (order) => {
    if (isVerify) {
      onConfirmOrder({ orderId: order.id });
    }
  };

  const content = (
    <ReduxStatusVisualizedComponent
      showModalOnError
      modalFailureContent={orderListError || withdrawRequestError}
      reduxStatus={orderListReduxStatus || orderUpdateReduxStatus || withdrawRequestReduxStatus}
    >

      <ModalComponent
        onModalConfirmed={onModalConfirmed}
        content="已送出要求"
        isVisible={isShowWithdrawRequestSuccess}
        onModalClose={onModalConfirmed}
        maskClosable={false}
      />

      <MemberHeader
        reduxStatus
        path={Paths.MY_RENTED_SOLD_HISTORY}
        category="transactionRecord"
      />

      {
        orderUpdateReduxStatus === ReduxStatus.STATUS_LOADING ? (
          <div style={styles.loadingContainer}>
            <Spin tip="資料傳送中，請稍候..." spinning />
          </div>
        ) : (
          <div style={styles.orderItemContainer}>
            <Default>
              <table style={styles.mainTable}>
                <tbody style={styles.thead}>
                  <tr style={styles.header}>
                    <td style={styles.narrowColumn}>訂單編號</td>
                    <td style={styles.narrowColumn}>商品圖片</td>
                    <td style={styles.narrowColumn}>付款狀態</td>
                    <td style={styles.narrowColumn}>交易類別</td>
                    <td style={styles.orderStatusColumn}>訂單狀態</td>
                    <td style={styles.narrowColumn}>出貨日期</td>
                    <td style={styles.nanoColumn} />
                  </tr>
                </tbody>
              </table>
              { orderList !== null ? orderList.map((order, index) => (
                <OrderDetailTableComponentV2
                  userId={userId}
                  key={order.id}
                  index={index}
                  order={order}
                  isRentedSoldHistory
                  onSubmitWithdrawRequest={onSubmitWithdrawRequest}
                  onConfirmOrder={() => onClickConfirmOrder(order)}
                  isVerify={isVerify}
                />
              )) : null}
            </Default>
            <Mobile>
              { orderList !== null ? orderList.map((order, index) => (
                <OrderDetailTableComponentV2
                  userId={userId}
                  key={order.id}
                  index={index}
                  order={order}
                  isRentedSoldHistory
                  onSubmitWithdrawRequest={onSubmitWithdrawRequest}
                  onConfirmOrder={() => onClickConfirmOrder(order)}
                  isVerify={isVerify}
                />
              )) : null}
            </Mobile>
            <div style={styles.goBackButtonContainer}>
              <Button
                text="回上一頁"
                style={styles.goBackButton}
                onClick={goBack}
              />
            </div>
          </div>
        )
      }

    </ReduxStatusVisualizedComponent>
  );

  return (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer} key="baseContainer">
          {content}
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer} key="baseContainer">
          {content}
        </div>
      </Mobile>
    </React.Fragment>
  );
};

MyRentedSoldOrderListPage.propTypes = {
  orderList: PropTypes.array,
  orderListReduxStatus: PropTypes.string,
  orderListError: PropTypes.string,
  withdrawRequestReduxStatus: PropTypes.string,
  withdrawRequestError: PropTypes.string,
  onSubmitWithdrawRequest: PropTypes.func.isRequired,
  isShowWithdrawRequestSuccess: PropTypes.bool,
  onModalConfirmed: PropTypes.func.isRequired,
  onConfirmOrder: PropTypes.func,
  userId: PropTypes.number,
  isVerify: PropTypes.bool,
  orderUpdateReduxStatus: PropTypes.string,
};

MyRentedSoldOrderListPage.defaultProps = {
  orderList: [],
  orderListReduxStatus: null,
  orderListError: '',
  withdrawRequestReduxStatus: null,
  withdrawRequestError: '',
  isShowWithdrawRequestSuccess: false,
  userId: null,
  onConfirmOrder: () => {},
  isVerify: false,
  orderUpdateReduxStatus: '',
};

export default MyRentedSoldOrderListPage;
