import React, { useState } from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {
  Icon,
  Button,
} from 'antd/lib/index';
import * as R from 'ramda';
import DayOfWeekComponent from './DayOfWeekComponent';
import DateComponent from './DateComponent';
import Default from '../../RWDComponent/Default';
import Mobile from '../../RWDComponent/Mobile';

const styles = {
  mainMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90vw',
    border: '1px solid #D2D5D5',
    height: 'fit-content',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 424,
    border: '1px solid #D2D5D5',
    height: 'fit-content',
  },
  dateMobileHeaderContainer: {
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    height: 40,
    border: 'none',
    outline: 'none',
  },
  dateHeaderContainer: {
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 422,
    height: 40,
    border: 'none',
    outline: 'none',
  },
  dateMobileRangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    height: 40,
  },
  dateRangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 212,
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    height: 40,
  },
  selectMobileDateRangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
    cursor: 'pointer',
    backgroundColor: '#D2D5D5',
    border: 'none',
    outline: 'none',
    height: 40,
  },
  selectDateRangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 212,
    cursor: 'pointer',
    backgroundColor: '#D2D5D5',
    border: 'none',
    outline: 'none',
    height: 40,
  },
  calendarMobile: {
    fontSize: 18,
    margin: '2px 10px 0px 10px',
  },
  calendar: {
    fontSize: 25,
    margin: '2px 10px 0px 10px',
  },
  dateMobile: {
    width: '65%',
    color: 'black',
    fontSize: 12,
    height: 40,
    padding: '12px 0',
    textAlign: 'right',
  },
  date: {
    width: '65%',
    color: 'black',
    fontSize: 13,
    height: 40,
    padding: '12px 0',
    textAlign: 'right',
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  footer: {
    borderTop: '1px solid #D2D5D5',
    margin: '15px 5px 0px 5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: 50,
    padding: 5,
  },
  availableDot: {
    margin: 10,
    width: 20,
    height: 20,
    borderRadius: 50,
    backgroundColor: '#75331B',
  },
  availableMobileDot: {
    margin: 10,
    width: 15,
    height: 15,
    borderRadius: 50,
    backgroundColor: '#75331B',
  },
  disableDot: {
    margin: 10,
    width: 20,
    height: 20,
    borderRadius: 50,
    backgroundColor: '#D2D5D5',
  },
  disableMobileDot: {
    margin: 10,
    width: 15,
    height: 15,
    borderRadius: 50,
    backgroundColor: '#D2D5D5',
  },
  availableDateText: {
    width: 'fit-content',
    fontSize: 12,
    color: '#75331B',
  },
  disableDateText: {
    width: 'fit-content',
    fontSize: 12,
    color: '#D2D5D5',
  },
  monthSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 40,
    borderTop: '1px solid #D2D5D5',
  },
  preAndNextMonthButton: {
    fontSize: 20,
    color: 'black',
    cursor: 'pointer',
    border: 'none',
    outline: ' none',
    backgroundColor: 'transparent',
  },
  currentSelectedMonth: {
    width: '100%',
    height: 40,
    padding: '10px 0px',
    textAlign: 'center',
  },
  emptyDate: {
    width: 60,
    height: 40,
  },
  emptyMobileDate: {
    width: '14.2%',
    height: 40,
  },
  clearAll: {
    backgroundColor: 'transparent',
    color: '#75331B',
    fontSize: 15,
    marginLeft: 70,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  clearAllMobile: {
    backgroundColor: 'transparent',
    color: '#75331B',
    fontSize: 15,
    marginLeft: 15,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  confirm: {
    backgroundColor: 'transparent',
    color: '#75331B',
    fontSize: 15,
    marginLeft: 10,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
};

const DateRangePickerComponent = ({
  isDisable,
  onSelectedDateRange,
  previewDays,
  rentDateList,
  isOhPhireManage,
  isOverdueReturn,
  commodityType,
}) => {
  const [selectMonth, setSelectMonth] = useState(moment(new Date()).get('months'));
  const [fromDate, setFromDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isExpand, setIsExpand] = useState(false);

  const renderMonthCalendar = (month) => {
    let emptyDateCounting = moment().month((month)).startOf('month').weekday();
    let iterationDate = moment().month((month)).startOf('month').format('YYYY-MM-DD');
    const dateList = [];
    const lastDay = moment().month(month).endOf('month').format('YYYY-MM-DD');

    while (!moment(lastDay).isBefore(iterationDate)) {
      if (emptyDateCounting > 0) {
        dateList.push({ id: String(moment(month).get('months')) + String(emptyDateCounting) });
        emptyDateCounting -= 1;
      } else {
        dateList.push(iterationDate);
        iterationDate = moment(iterationDate).add(1, 'days').format('YYYY-MM-DD');
      }
    }

    return (
      <div style={styles.calendarContainer}>
        {dateList.map((date) => {
          if (!R.isNil(date.id)) {
            return (
              <React.Fragment key={date.id}>
                <Default>
                  <div key={`default_${date.id}`} style={styles.emptyDate} />
                </Default>
                <Mobile>
                  <div key={`mobile_${date.id}`} style={styles.emptyMobileDate} />
                </Mobile>
              </React.Fragment>
            );
          }
          return (
            <DateComponent
              isDisable={isOverdueReturn || isDisable}
              rentDateList={rentDateList}
              previewDays={previewDays}
              fromDate={fromDate}
              endDate={endDate}
              key={R.isNil(date.id) ? date : date.id}
              date={date}
              onSelected={(selectedDate) => {
                setFromDate(selectedDate);
                const selectedEndDate = moment(selectedDate).add(previewDays - 1, 'days').format('YYYY-MM-DD');
                setEndDate(selectedEndDate);
                onSelectedDateRange(selectedDate, selectedEndDate);
              }}
              isOhPhireManage={isOhPhireManage}
              commodityType={commodityType}
            />
          );
        })}
      </div>
    );
  };

  const renderFromToHeader = fromOrTo => (
    <React.Fragment>
      <Default>
        <div
          style={fromOrTo === 'from' ? styles.selectDateRangeContainer : styles.dateRangeContainer}
        >
          <Icon type="calendar" style={styles.calendar} />
          {fromOrTo === 'from' ? '從' : '至'}
          <div style={styles.date}>
            {R.isEmpty(fromOrTo === 'from' ? fromDate : endDate)
              ? '年 - 月 - 日 '
              : moment(fromOrTo === 'from' ? fromDate : endDate).format('YYYY-MM-DD')}
          </div>
        </div>
      </Default>
      <Mobile>
        <div
          style={fromOrTo === 'to' ? styles.selectMobileDateRangeContainer : styles.dateMobileRangeContainer}
        >
          <Icon type="calendar" style={styles.calendarMobile} />
          {fromOrTo === 'from' ? '從' : '至'}
          <div style={styles.dateMobile}>
            {R.isEmpty(fromOrTo === 'from' ? fromDate : endDate)
              ? '年 - 月 - 日 '
              : moment(fromOrTo === 'from' ? fromDate : endDate).format('YYYY-MM-DD')}
          </div>
        </div>
      </Mobile>
    </React.Fragment>
  );

  const renderPreNextButton = preOrNext => (
    <Button
      style={styles.preAndNextMonthButton}
      theme="filled"
      icon={preOrNext === 'pre' ? 'caret-left' : 'caret-right'}
      onClick={() => {
        setSelectMonth(preOrNext === 'pre' ? selectMonth - 1 : selectMonth + 1);
      }}
    />
  );

  return (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          <button
            type="button"
            style={styles.dateHeaderContainer}
            onClick={() => setIsExpand(!isExpand)}
          >
            {renderFromToHeader('from')}
            {renderFromToHeader('to')}
          </button>

          {isExpand && (
            <React.Fragment>
              <div style={styles.monthSelectorContainer}>
                {renderPreNextButton('pre')}
                <div style={styles.currentSelectedMonth}>{moment(new Date()).month(selectMonth).format('MMM YYYY')}</div>
                {renderPreNextButton('next')}
              </div>
              <DayOfWeekComponent />
              {renderMonthCalendar(selectMonth)}
              <div style={styles.footer}>
                <div style={styles.availableDot} />
                <div style={styles.availableDateText}>出貨日/歸還寄送日</div>
                <div style={styles.disableDot} />
                <div style={styles.disableDateText}>不可選日期</div>
                <button
                  type="button"
                  style={styles.clearAll}
                  onClick={() => {
                    setFromDate('');
                    setEndDate('');
                    setSelectMonth(moment(new Date()).get('months'));
                    onSelectedDateRange('', '');
                  }}
                >
                  清除
                </button>
                <button
                  type="button"
                  style={styles.confirm}
                  onClick={() => setIsExpand(false)}
                >
                  確定
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          <button
            type="button"
            style={styles.dateMobileHeaderContainer}
            onClick={() => setIsExpand(!isExpand)}
          >
            {renderFromToHeader('from')}
            {renderFromToHeader('to')}
          </button>

          {isExpand && (
            <React.Fragment>
              <div style={styles.monthSelectorContainer}>
                {renderPreNextButton('pre')}
                <div style={styles.currentSelectedMonth}>{moment(new Date()).month(selectMonth).format('MMM YYYY')}</div>
                {renderPreNextButton('next')}
              </div>
              <DayOfWeekComponent />
              {renderMonthCalendar(selectMonth)}
              <div style={styles.footer}>
                <div style={styles.availableMobileDot} />
                <div style={styles.availableDateText}>出貨/歸還日</div>
                <div style={styles.disableMobileDot} />
                <div style={styles.disableDateText}>不可選日期</div>
                <button
                  type="button"
                  style={styles.clearAllMobile}
                  onClick={() => {
                    setFromDate('');
                    setEndDate('');
                    setSelectMonth(moment(new Date()).get('months'));
                    onSelectedDateRange('', '');
                  }}
                >
                  清除
                </button>
                <button
                  type="button"
                  style={styles.confirm}
                  onClick={() => setIsExpand(false)}
                >
                  確定
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
      </Mobile>
    </React.Fragment>
  );
};

DateRangePickerComponent.propTypes = {
  onSelectedDateRange: PropTypes.func.isRequired,
  previewDays: PropTypes.number.isRequired,
  rentDateList: PropTypes.array,
  isDisable: PropTypes.bool,
  isOhPhireManage: PropTypes.bool,
  commodityType: PropTypes.number,
  isOverdueReturn: PropTypes.bool,
};

DateRangePickerComponent.defaultProps = {
  rentDateList: [],
  isDisable: false,
  isOhPhireManage: false,
  commodityType: 1,
  isOverdueReturn: false,
};

export default DateRangePickerComponent;
