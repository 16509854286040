import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import UploadOutfitComponent from './UploadOutfitComponent';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  memberAvatarUriSelector,
} from '../../selector';

const UploadOutfitComponentContainer = ({
  reduxStatus,
  onModalClose,
  isVisible,
  avatarUri,
  actions: {
    resetStatus,
    uploadOutfitFlowRequest,
  },
}) => {
  const handleOnUploadOutfit = (data) => {
    uploadOutfitFlowRequest(data);
  };
  useEffect(() => {
    if (isVisible) {
      resetStatus();
    }
  }, [isVisible]);

  return (
    <UploadOutfitComponent
      onUploadOutfit={handleOnUploadOutfit}
      avatarUri={avatarUri}
      isVisible={isVisible}
      onModalClose={onModalClose}
      reduxStatus={reduxStatus}
    />
  );
};

UploadOutfitComponentContainer.propTypes = {
  reduxStatus: PropTypes.string,
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  avatarUri: PropTypes.string,
  actions: PropTypes.shape({
    resetStatus: PropTypes.func.isRequired,
    uploadOutfitFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
};

UploadOutfitComponentContainer.defaultProps = {
  reduxStatus: null,
  isVisible: false,
  avatarUri: '',
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  avatarUri: memberAvatarUriSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadOutfitComponentContainer);
