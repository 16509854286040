import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../../../ActionCreator';
import ReduceDepositMainComponent from './ReduceDepositMainComponent';

const ReduceDepositMainComponentContainer = ({
  isVisible,
  onModalClose,
  data,
  onAddReduceDeposit,
  onEditReduceDepositBag,
  actions: {
    deleteReduceDepositBagFlowRequest,
    deleteReduceDepositUserFlowRequest,
  },
}) => (
  <ReduceDepositMainComponent
    isVisible={isVisible}
    onModalClose={onModalClose}
    data={data}
    onDeleteReduceDepositUser={deleteReduceDepositUserFlowRequest}
    onDeleteReduceDepositBag={deleteReduceDepositBagFlowRequest}
    onAddReduceDeposit={onAddReduceDeposit}
    onEditReduceDepositBag={onEditReduceDepositBag}
  />
);
ReduceDepositMainComponentContainer.propTypes = {
  data: PropTypes.array,
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onAddReduceDeposit: PropTypes.func.isRequired,
  onEditReduceDepositBag: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    deleteReduceDepositBagFlowRequest: PropTypes.func.isRequired,
    deleteReduceDepositUserFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
};

ReduceDepositMainComponentContainer.defaultProps = {
  data: [],
  isVisible: false,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(ReduceDepositMainComponentContainer);
