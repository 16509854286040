import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'antd';
import { FontSize } from '../../../../constants/FontSize';
import Assets from '../../../../constants/Assets';
import DefaultButton from '../../../../components/Button';
import { Colors } from '../../../../constants/Color';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    margin: '0px 20px',
    textAlign: 'center',
    paddingBottom: '80px',
  },
  questionContainer: {
    margin: '0px auto',
    width: '60%',
    textAlign: 'left',
    fontSize: '15px',
    letterSpacing: '2.4px',
    lineHeight: '25px',
    cursor: 'pointer',
    color: 'black',
    whiteSpace: 'normal',
  },
  questionMobileContainer: {
    margin: '0px auto',
    width: '80%',
    textAlign: 'left',
    fontSize: '15px',
    letterSpacing: '2.4px',
    lineHeight: '25px',
    cursor: 'pointer',
    color: 'black',
    whiteSpace: 'normal',
  },
  ans: {
    margin: '20px 0 70px 0',
    color: 'grey',
  },
  pic: {
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    marginTop: '80px',
    maxHeight: '30vw',
    objectFit: 'cover',
  },
  FAQhr: {
    marginTop: '10px',
    border: 0,
    borderTop: '1px solid rgba(0,0,0,0.2)',
    borderBottom: '1px solid rgba(255,255,255,0.4)',
    height: '1px',
  },
  img: {
    objectFit: 'cover',
    height: 'auto',
    width: '100vw',
    right: '0',
    top: '0',
    marginLeft: 'calc(50% - 50vw)',
  },
  FAQTitle: {
    fontSize: FontSize.title,
    marginTop: '30px',
  },
  ansContainer: {
    margin: '10px 0px',
    whiteSpace: 'pre-line',
    textAlign: 'left',
    fontSize: FontSize.body,
  },
  expand: {
    color: 'black',
  },
  questionTitleContainer: {
    height: 'auto',
    width: '100%',
    fontSize: FontSize.body,
    fontWeight: '600',
    display: 'flex',
    margin: '0 auto',
    marginTop: '5px',
    justifyContent: 'space-between',
    color: 'black',
  },
  questionTitle: {
    paddingTop: '3px',
    fontSize: FontSize.body,
  },
  typeContainer: {
    width: '60%',
    height: 'auto',
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonActive: {
    backgroundColor: 'white',
    color: 'black',
    border: `2px solid ${Colors.introRightTitle}`,
    width: '16%',
    minWidth: '100px',
  },
  buttonActiveMobile: {
    backgroundColor: 'white',
    color: 'black',
    border: `2px solid ${Colors.introRightTitle}`,
    width: '40%',
    minWidth: '150px',
  },
  button: {
    backgroundColor: 'white',
    color: 'grey',
    border: `2px solid ${Colors.separateLine}`,
    width: '16%',
    minWidth: '100px',
  },
  title: {
    textAlign: 'left',
    width: '60%',
    fontSize: FontSize.subTitle,
    fontWeight: 700,
    margin: '80px auto 30px auto',
  },
  titleMobile: {
    textAlign: 'left',
    width: '80%',
    fontSize: FontSize.subTitle,
    fontWeight: 700,
    margin: '30px auto',
  },
  typeMobileContainer: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrow: {
    fontSize: '50px',
    width: 40,
    height: 50,
    textAlign: 'center',
    lineHeight: '40px',
    color: Colors.introRightTitle,
  },
};


const RenderQuestionBlock = ({
  questions,
  ans,
  typeId,
  selectType,
}) => {
  const [isExpand, setIsExpand] = useState(false);

  return (
    typeId === selectType.id ? (
      <div>
        <div style={styles.questionTitleContainer}>
          <h1 style={styles.questionTitle}>
            {questions}
          </h1>
          <Button
            icon={isExpand ? 'up' : 'down'}
            onClick={() => setIsExpand(!isExpand)}
            type="link"
            style={styles.expand}
          />
        </div>
        {isExpand ? (
          <div style={styles.deepExpand}>
            <div style={styles.ansContainer}>
              {ans}
            </div>
          </div>
        ) : null}
        <hr style={styles.FAQhr} />
      </div>
    ) : null
  );
};

const FAQpage = ({
  data,
}) => {
  const [selectType, setSelectType] = useState({ id: 0, title: '常見問與答' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const type = [
    { title: '常見問與答' },
    { title: '關於租借' },
    { title: '關於出租' },
    { title: '關於出售/購買' },
    { title: '寄送/歸還/運費' },
  ];

  const handleSelectLeftArrow = () => {
    setSelectType({
      id: selectType.id === 0 ? 3 : selectType.id - 1,
      title: selectType.id === 0 ? type[3].title : type[selectType.id - 1].title,
    });
  };

  const handleSelectRightArrow = () => {
    setSelectType({
      id: selectType.id === 3 ? 0 : selectType.id + 1,
      title: selectType.id === 3 ? type[0].title : type[selectType.id + 1].title,
    });
  };

  return (
    <div style={styles.mainContainer} key="baseContainer">
      <Default>
        <img
          src={Assets.FAQTopPic}
          style={styles.img}
          alt=""
        />
      </Default>
      <Mobile>
        <img
          src={Assets.FAQTopPicMobile}
          style={styles.img}
          alt=""
        />
      </Mobile>
      <h1 style={styles.FAQTitle}>
        CUSTOMER FAQs
      </h1>
      <Default>
        <div style={styles.typeContainer}>
          {type.map((item, index) => (
            <DefaultButton
              key={item.title}
              text={item.title}
              style={index === selectType.id ? styles.buttonActive : styles.button}
              onClick={() => { setSelectType({ id: index, title: item.title }); }}
            />
          ))}
        </div>
        <div style={styles.title}>{selectType.title}</div>
        <div style={styles.questionContainer}>
          {data.map(item => (
            <RenderQuestionBlock
              key={item.questions + item.typeId}
              selectType={selectType}
              questions={item.questions}
              ans={item.ans}
              typeId={item.typeId}
            />
          ))}
        </div>
      </Default>
      <Mobile>
        <div style={styles.typeMobileContainer}>
          <Icon
            type="left"
            style={styles.arrow}
            onClick={handleSelectLeftArrow}
          />
          <DefaultButton
            text={selectType.title}
            style={styles.buttonActiveMobile}
          />
          <Icon
            type="right"
            style={styles.arrow}
            onClick={handleSelectRightArrow}
          />
        </div>
        <div style={styles.titleMobile}>{selectType.title}</div>
        <div style={styles.questionMobileContainer}>
          {data.map(item => (
            <RenderQuestionBlock
              key={item.questions + item.typeId}
              selectType={selectType}
              questions={item.questions}
              ans={item.ans}
              typeId={item.typeId}
            />
          ))}
        </div>
      </Mobile>
    </div>
  );
};


FAQpage.propTypes = {
  data: PropTypes.array.isRequired,
};

RenderQuestionBlock.propTypes = {
  questions: PropTypes.string.isRequired,
  ans: PropTypes.object.isRequired,
  selectType: PropTypes.object.isRequired,
  typeId: PropTypes.number.isRequired,
};


export default FAQpage;
