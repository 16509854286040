import React from 'react';
import PropTypes from 'prop-types';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import CartTableComponent from '../CartTableComponent';
import Assets from '../../../../constants/Assets';
import { FontSize } from '../../../../constants/FontSize';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import CartTableMobileComponent from '../CartTableMobileComponent';

const styles = {
  mainContainer: {
    margin: '100px 20px',
    position: 'relative',
  },
  cartContainer: {
    width: '90%',
    margin: '0 auto',
  },
  tableContainer: {
    width: '100%',
    fontSize: FontSize.body,
  },
  table: {
    width: '100%',
  },
  tableHeader: {
    margin: '10px 0px',
    width: '100%',
    paddingBottom: 10,
    borderTop: '1px solid #BABABA',
    borderBottom: '1px solid #BABABA',
    tableLayout: 'fixed',
  },
  width5: {
    width: '5%',
    margin: '20px 0 10px 0',
  },
  width10: {
    width: '10%',
    margin: '20px 0 10px 0',
  },
  width17: {
    width: '17%',
    margin: '20px 0 10px 0',
  },
  width20: {
    width: '20%',
    height: 60,
  },
  width23: {
    width: '23%',
    margin: '20px 0 10px 0',
  },
  titleContainer: {
    display: 'flex',
    color: 'black',
    position: 'relative',
    marginBottom: '15px',
  },
  icon: {
    width: '40px',
    height: '40px',
    position: 'relative',
    top: '5px',
  },
  title: {
    fontSize: FontSize.title,
    marginLeft: '15px',
  },
};

const CartListPage = ({
  cartList,
  cartListError,
  cartListReduxStatus,
  onDeleteCart,
}) => (
  <div style={styles.mainContainer}>
    <ReduxStatusVisualizedComponent
      showModalOnError
      modalFailureContent={cartListError}
      reduxStatus={cartListReduxStatus}
    >
      <div style={styles.cartContainer}>
        <div style={styles.titleContainer}>
          <img style={styles.icon} src={Assets.cartTitleIcon} alt="" />
          <h1 style={styles.title}>
            Shopping Cart
          </h1>
        </div>

        <div style={styles.tableContainer}>
          <div style={styles.table}>
            <Default>
              <table style={styles.tableHeader} key="table">
                <thead>
                  <tr key="tableHeader">
                    <td style={styles.width20}>
                      商品相片
                    </td>
                    <td style={styles.width10}>
                      商品編號
                    </td>
                    <td style={styles.width17}>
                      商品名稱
                    </td>
                    <td style={styles.width10}>
                      交易型態
                    </td>
                    <td style={styles.width20}>
                      租金/押金
                    </td>
                    <td style={styles.width10}>
                      購買價格
                    </td>
                    <td style={styles.width10}>
                      操作
                    </td>
                    <td style={styles.width5} />
                  </tr>
                </thead>
                <tbody>
                  {cartList !== null ? cartList.map(cart => (
                    <CartTableComponent
                      key={cart.id}
                      cart={cart}
                      onDeleteCart={onDeleteCart}
                    />
                  )) : null}
                </tbody>
              </table>
            </Default>
            <Mobile>
              {cartList !== null ? cartList.map(cart => (
                <CartTableMobileComponent
                  key={cart.id}
                  cart={cart}
                  onDeleteCart={onDeleteCart}
                />
              )) : null}
            </Mobile>
          </div>
        </div>
        <div>*為方便後續押金退款作業，每筆訂單僅限結帳一項商品。</div>
      </div>
    </ReduxStatusVisualizedComponent>
  </div>

);

CartListPage.propTypes = {
  cartList: PropTypes.array,
  cartListReduxStatus: PropTypes.string,
  cartListError: PropTypes.string,
  onDeleteCart: PropTypes.func.isRequired,
};

CartListPage.defaultProps = {
  cartList: [],
  cartListReduxStatus: null,
  cartListError: '',
};

export default CartListPage;
