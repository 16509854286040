import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPixel from 'react-facebook-pixel';
import * as R from 'ramda';
import { Link } from '@reach/router';
import {
  Icon,
  Popover,
  Badge,
  Divider,
  Popconfirm,
} from 'antd';
import { notificationTypeRedirectPath } from '../constants/NotificationType';
import Paths from '../routes/Paths';
import Default from './RWDComponent/Default';
import Mobile from './RWDComponent/Mobile';
import bagOrClothesPath from '../utils/bagOrClothesPath';

const styles = {
  popoverContainer: {
    width: 340,
  },
  title: {
    color: '#974107',
    fontSize: 15,
    fontWeight: 0,
    margin: 0,
    width: 250,
  },
  titleButton: {
    color: '#974107',
    fontSize: 15,
    fontWeight: 0,
    margin: 0,
    backgroundColor: 'none',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
  },
  allReadButton: {
    color: '#974107',
    fontSize: 15,
    margin: 0,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
  },
  dropdownBorderBottom: {
    marginTop: 10,
    borderBottom: '1px solid #e8e8e8',
  },
  dropdownItem: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    color: 'grey',
  },
  icon: {
    fontSize: '25px',
    margin: '0px 5px',
    width: '25px',
    height: '45px',
    objectFit: 'contain',
  },
  iconMobile: {
    fontSize: '25px',
    margin: '10px 1px',
    width: '30px',
    height: '30px',
  },
  badge: {
    position: 'absolute',
    fontSize: 6,
  },
  img: {
    width: 100,
    height: 100,
    objectFit: 'contain',
  },
  popoverItemContainer: {
    padding: 10,
    width: 300,
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  notificationContainer: {
    height: 'fit-content',
    maxHeight: 500,
    overflowY: 'auto',
  },
  notificationButtonContainer: {
    padding: 15,
    width: 280,
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'white',
    border: 'none',
    outline: 'none',
    textAlign: 'left',
    cursor: 'pointer',
  },
  notificationContentContainer: {
    width: 240,
    height: 'fit-content',
  },
  unReadHint: {
    borderRadius: 15,
    width: 7,
    height: 7,
    marginRight: 10,
    backgroundColor: 'red',
  },
  unReadInvisibleHint: {
    width: 8,
    height: 8,
    marginRight: 10,
    backgroundColor: 'transparent',
  },
  popoverItemRightContainer: {
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  checkoutButton: {
    outline: 'none',
    position: 'absolute',
    marginLeft: -16,
    padding: '12px 0px',
    width: '100%',
    height: 50,
    color: 'white',
    textAlign: 'center',
    backgroundColor: 'black',
  },
  divider: {
    margin: 0,
  },
};

const BadgeComponent = ({
  isLogin,
  memberSocialLogin,
  popOverTriggerType,
  data,
  targetRoute,
  iconSrc,
  type,
  onRemoveItem,
  setIsShowLoginModal,
  setShowChangePasswordModal,
  communityName,
  onHadReadNotification,
  onAllReadNotification,
}) => {
  const [isShowPopoverContent, setIsShowPopoverContent] = useState(false);
  const renderItem = (item) => {
    const commodityType = R.pathOr('', ['bag', 'commodityTypeId'], item);
    return (
      <div key={R.pathOr('', ['id'], item)}>
        <a href={
          `${bagOrClothesPath(commodityType)}/${R.pathOr('', ['bag', 'bagNo'], item)}`}
        >
          <div style={styles.popoverItemContainer}>
            <img style={styles.img} src={R.pathOr('', ['bag', 'frontPic', 'uri'], item)} alt="" />
            <div style={styles.popoverItemRightContainer}>
              <div>{R.pathOr('', ['bag', 'name'], item)}</div>
              <div>{R.pathOr('', ['bag', 'bagNo'], item)}</div>
            </div>
            <Popconfirm
              title="是否從購物車移除?"
              onConfirm={() => onRemoveItem(type, R.pathOr('', ['id'], item))}
              okText="Yes"
              cancelText="No"
            >
              <Icon
                type="close-circle"
              />
            </Popconfirm>
          </div>
        </a>
        <Divider style={styles.divider} />
      </div>
    );
  };

  const getRedirectPath = (item) => {
    const { notificationTypeId } = item;
    let path = notificationTypeRedirectPath[notificationTypeId];

    // if ((notificationTypeId === 101) || (notificationTypeId === 106)) {
    //   path = `${path}/${item.bag.bagNo}`;
    // }

    if (notificationTypeId === 100) {
      const followerCommunityName = item.content.split(' ')[0];
      path = `${path}/${followerCommunityName}`;
    }

    if (notificationTypeId === 107) {
      path = `${path}?bagId=${item.bagId}`;
    }

    if (notificationTypeId === 500 || notificationTypeId === 501) {
      path = `${path}?show="memberCoupon"`;
    }

    return path;
  };

  const renderNotificationItem = item => (
    <Link
      key={item.id}
      to={getRedirectPath(item)}
    >
      <button
        type="button"
        style={styles.notificationButtonContainer}
        onClick={() => {
          onHadReadNotification(item.id);
          setIsShowPopoverContent(false);
        }}
      >
        {item.isRead ? <div style={styles.unReadInvisibleHint} /> : <div style={styles.unReadHint} /> }
        <div style={styles.notificationContentContainer}>
          {item.content}
        </div>
      </button>
      <Divider style={styles.divider} />
    </Link>
  );

  const hasUnReadNotifications = R.filter(item => !R.path(['isRead'], item), data).length > 0;

  const renderPopoverContent = () => {
    if (type === 'notification') {
      return (
        <div style={styles.notificationContainer}>
          {hasUnReadNotifications && (
          <React.Fragment>
            <button
              type="button"
              style={styles.allReadButton}
              onClick={() => onAllReadNotification()}
            >
              全部已讀
            </button>
            <div style={styles.dropdownBorderBottom} />
          </React.Fragment>
          )}
          {data.map(item => renderNotificationItem(item))}
        </div>
      );
    }

    if (type === 'cart') {
      return (
        <div>
          {data.map(item => renderItem(item))}
          <Link to={targetRoute}>
            <button
              type="button"
              style={styles.checkoutButton}
              onClick={() => {
                ReactPixel.track('InitiateCheckout', {
                  content_type: 'product',
                  product_catalog_id: '880132272464897',
                  content_ids: [data.map(item => item.id)],
                  currency: 'TWD',
                  num_items: data.length || 0,
                  contents: data.map((item) => {
                    const commodityType = R.pathOr('', ['bag', 'commodityTypeId'], item);
                    return {
                      id: R.pathOr('', ['id'], item),
                      quantity: '1',
                      commodityType: commodityType === 1 || commodityType === '1' ? 'bags' : 'clothes',
                      type: R.path(['bag', 'type', 'name'], item),
                      name: R.path(['bag', 'name'], item),
                    };
                  }),
                });
                setIsShowPopoverContent(false);
              }}
            >
              CHECK OUT
            </button>
          </Link>
        </div>
      );
    }
    if (type === 'user' && isLogin) {
      return (
        <div>
          <div style={styles.titleButton}>
            個人檔案
          </div>
          <p />
          <div style={styles.dropdownBorderBottom} />
          <p />
          <Link to={Paths.MEMBER}>
            <div />
            <button
              type="button"
              onClick={() => setIsShowPopoverContent(false)}
              style={styles.dropdownItem}
            >
              個人資料
            </button>
          </Link>
          <p />
          <Link to={`${Paths.MEMBER}/${communityName}`}>
            <button
              type="button"
              onClick={() => setIsShowPopoverContent(false)}
              style={styles.dropdownItem}
            >
              我的頁面
            </button>
          </Link>
          <p />
          <Link to={Paths.ONLINE_PRODUCTS}>
            <button
              type="button"
              onClick={() => setIsShowPopoverContent(false)}
              style={styles.dropdownItem}
            >
              我的商品
            </button>
          </Link>
          <p />
          <Link to={Paths.MY_RENTED_SOLD_HISTORY}>
            <button
              type="button"
              onClick={() => setIsShowPopoverContent(false)}
              style={styles.dropdownItem}
            >
              交易紀錄
            </button>
          </Link>
          <p />
          <Link to={Paths.SHIPPING_INFO}>
            <button
              type="button"
              onClick={() => setIsShowPopoverContent(false)}
              style={styles.dropdownItem}
            >
              出貨資訊
            </button>
          </Link>
          <p />
          {memberSocialLogin ? null : (
            <button
              type="button"
              onClick={() => {
                setIsShowPopoverContent(false);
                setShowChangePasswordModal(true);
              }}
              style={styles.dropdownItem}
            >
              變更密碼
            </button>
          )}

          <p />
          <div style={styles.dropdownBorderBottom} />
          <p />
          <Link to={Paths.CONTACT}>
            <button
              type="button"
              onClick={() => setIsShowPopoverContent(false)}
              style={styles.dropdownItem}
            >
              聯絡我們
            </button>
          </Link>
          <p />
          <div style={styles.dropdownBorderBottom} />
          <p />
          <Link to={Paths.LOGOUT}>
            <button
              type="button"
              onClick={() => {
                setIsShowPopoverContent(false);
                setIsShowLoginModal(false);
              }}
              style={styles.dropdownItem}
            >
              Logout
            </button>
          </Link>
        </div>
      );
    }
    return (
      <div style={styles.mainContainer}>
        <div style={styles.title}>會員中心</div>
        <div style={styles.dropdownBorderBottom} />
        <p />
        <button
          type="button"
          onClick={() => {
            setIsShowPopoverContent(false);
            setIsShowLoginModal(true);
          }}
          style={styles.dropdownItem}
        >
          Login
        </button>
      </div>
    );
  };

  const getBadgeCount = (type === 'cart'
    ? data.length
    : R.pipe(
      R.filter(item => item.isRead === false),
      R.length(),
    )(data));

  return (
    (isLogin && data.length > 0) || type === 'user'
      ? (
        <Popover
          visible={isShowPopoverContent}
          content={renderPopoverContent()}
          overlayClassName=".ant-popover-title"
          overlayStyle={styles.popoverContainer}
          trigger={popOverTriggerType}
          placement="bottom"
          onVisibleChange={setIsShowPopoverContent}
        >
          <Default>
            <img
              src={iconSrc}
              style={styles.icon}
              role="presentation"
              alt=""
            />
          </Default>
          <Mobile>
            <img
              src={iconSrc}
              style={styles.iconMobile}
              role="presentation"
              alt=""
            />
          </Mobile>
          <Badge
            style={styles.badge}
            count={getBadgeCount}
          />
        </Popover>
      )
      : (
        <React.Fragment>
          <Default>
            <img
              src={iconSrc}
              style={styles.icon}
              alt=""
            />
          </Default>
          <Mobile>
            <img
              src={iconSrc}
              style={styles.iconMobile}
              alt=""
            />
          </Mobile>
        </React.Fragment>
      )
  );
};

BadgeComponent.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  isLogin: PropTypes.bool,
  popOverTriggerType: PropTypes.string,
  data: PropTypes.array,
  targetRoute: PropTypes.string,
  onRemoveItem: PropTypes.func,
  type: PropTypes.string.isRequired,
  setIsShowLoginModal: PropTypes.func,
  communityName: PropTypes.string,
  setShowChangePasswordModal: PropTypes.func,
  memberSocialLogin: PropTypes.string,
  onHadReadNotification: PropTypes.func,
  onAllReadNotification: PropTypes.func,
};

BadgeComponent.defaultProps = {
  isLogin: false,
  memberSocialLogin: null,
  communityName: '',
  data: [],
  popOverTriggerType: 'click',
  targetRoute: null,
  setIsShowLoginModal: () => {},
  onRemoveItem: () => {},
  setShowChangePasswordModal: () => {},
  onHadReadNotification: () => {},
  onAllReadNotification: () => {},
};

export default BadgeComponent;
