import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_BAG_LIST'),
  ...createAsyncActionTypesArray('GET_MY_BAG_LIST'),
  ...createAsyncActionTypesArray('NOTIFY_WHEN_ARRIVED'),
  ...createAsyncActionTypesArray('SUBMIT_BAG'),
  ...createAsyncActionTypesArray('GET_COMMENT_LIST'),
  ...createAsyncActionTypesArray('GET_BAG_RELEASE_DATE'),
  ...createAsyncActionTypesArray('GET_BAG_ID_BY_BAG_NO'),
  ...createAsyncActionTypesArray('SEARCH_BAG_LIST_BY_KEYWORD'),
  ...createAsyncActionTypesArray('GET_BAG_RENT_DATE_LIST'),
  ...createAsyncActionTypesArray('GET_BAG_NUMBERS'),
  ...createAsyncActionTypesArray('GET_ONLINE_WARDROBE'),


  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_BAG_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_MY_BAG_LIST_FLOW'),
  ...createAsyncActionTypesArray('NOTIFY_WHEN_ARRIVED_FLOW'),
  ...createAsyncActionTypesArray('SUBMIT_BAG_FLOW'),
  ...createAsyncActionTypesArray('GET_COMMENT_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_BAG_RELEASE_DATE_FLOW'),
  ...createAsyncActionTypesArray('GET_BAG_ID_BY_BAG_NO_FLOW'),
  ...createAsyncActionTypesArray('SEARCH_BAG_LIST_BY_KEYWORD_FLOW'),
  ...createAsyncActionTypesArray('GET_BAG_RENT_DATE_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_BAG_NUMBERS_FLOW'),
  ...createAsyncActionTypesArray('GET_ONLINE_WARDROBE_FLOW'),

  // ----- STATUS -----
  'RESET_STATUS',

  { prefix: 'BAG' },
);
