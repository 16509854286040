import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import * as R from 'ramda';
import Paths from '../../../../routes/Paths';
import MemberInfoComponent from './MemberInfoComponent';
import MemberHeader from '../MemberHeader';
import ProtectTreeComponent from '../../../../components/ProtectTreeComponent';
import MemberOtherFeatureComponent from './MemberOtherFeatureComponent';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import LoginPageContainer from '../LoginPage';
import ReduceDepositMainComponent from './ReduceDepositComponent/ReduceDepositMainComponent';
import ReduceDepositAccountModalComponent from './ReduceDepositComponent/ReduceDepositAccountModalComponent';
import ReduceDepositSelectBagComponent from './ReduceDepositComponent/ReduceDepositSelectBagComponent';
import WithdrawMoneyComponent from './WithdrawMoneyComponent';
import ReduxStatus from '../../../../constants/ReduxStatus';
import ChangePasswordComponent from './ChangePasswordComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import MemberCouponComponent from '../MemberCouponComponent';

// ToDo: Hidden protect tree
// import ProtectTreePanelComponent from './ProtectTreePanelComponent';

const styles = {
  mainContainer: {
    paddingTop: 200,
    margin: '0px auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  mainMobileContainer: {
    paddingTop: 120,
    margin: '0px auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: 10,
  },
  marginTop: {
    marginTop: 10,
  },
  login: {
    margin: '40px auto',
    width: '160px',
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'black',
  },
};

const MemberPage = ({
  myBagList,
  reduceDepositList,
  isLogin,
  reduxStatus,
  reduxError,
  memberInfo,
  handleChangePassword,
  handleUpdateMemberInfo,
  isDefaultShowMemberCoupon,
  resetStatus,
  withdrawNumber,
}) => {
  const [mode, setMode] = useState('add');
  const [isShowLoginModal, setIsShowLoginModal] = useState(true);
  const [isShowProtectTreeComponent, setIsShowProtectTreeComponent] = useState(false);
  const [isShowAddReduceDepositModal, setIsShowAddReduceDepositModal] = useState(false);
  const [isShowSelectBagReduceDepositComponent, setIsShowSelectBagReduceDepositComponent] = useState(false);
  const [selectBagList, setSelectBagList] = useState([]);
  const [selectUser, setSelectUser] = useState({});
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [isShowReduceDepositModal, setIsShowReduceDepositModal] = useState(false);
  const [isShowMemberComponentModal, setIsShowMemberComponentModal] = useState(isDefaultShowMemberCoupon === 'memberCoupon');
  const [memberCouponCount, setMemberCouponCount] = useState(0);
  const [isShowWithDrawMoneyModal, setIsShowWithDrawMoneyModal] = useState(false);
  const memberId = R.pathOr('', ['id'], memberInfo);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (reduxStatus === ReduxStatus.STATUS_NORMAL && mode === 'add') {
      setSelectBagList([]);
    }
  }, [reduxStatus]);

  const getReduceDepositUser = () => {
    const reduceDepositUser = reduceDepositList.map(item => R.pathOr('', ['user', 'id'], item));
    const ReduceDepositUserNumber = reduceDepositUser.filter((el, i, arr) => arr.indexOf(el) === i).length;

    return ReduceDepositUserNumber;
  };

  const onEditReduceDepositBag = (item) => {
    setMode('edit');
    setSelectUser(item.user);
    setSelectBagList(item.bags);
    setIsShowAddReduceDepositModal(true);
    setIsShowReduceDepositModal(false);
  };

  const onSelectBag = (bagIdList) => {
    const updateBagList = [];
    bagIdList.forEach((bagId) => {
      updateBagList.push(...R.filter(myBag => myBag.id === bagId, myBagList));
    });
    setSelectBagList(updateBagList);
    setIsShowSelectBagReduceDepositComponent(false);
  };

  if (!isLogin) {
    return (
      <React.Fragment>
        <Redirect to={Paths.MEMBER} noThrow />
        <Default>
          <InfoModalComponent
            containerStyle={{ zIndex: 1 }}
            isVisible={isShowLoginModal}
            onModalClose={() => setIsShowLoginModal(false)}
            content={(
              <LoginPageContainer
                setIsShowLoginModal={setIsShowLoginModal}
                isLogin={isLogin}
              />
          )}
          />
        </Default>
        <Mobile>
          <InfoModalComponent
            containerStyle={{ zIndex: 1, paddingTop: 100 }}
            isVisible={isShowLoginModal}
            onModalClose={() => setIsShowLoginModal(false)}
            content={(
              <LoginPageContainer
                setIsShowLoginModal={setIsShowLoginModal}
                isLogin={isLogin}
              />
        )}
          />
        </Mobile>

        <div style={styles.login}> 請先登入 </div>
      </React.Fragment>
    );
  }
  const content = (
    <React.Fragment>

      <ProtectTreeComponent
        isVisible={isShowProtectTreeComponent}
        onModalClose={() => setIsShowProtectTreeComponent(false)}
      />
      <MemberHeader
        reduxStatus={reduxStatus}
        path={Paths.MEMBER}
        category="personalInfo"
      />

      <ReduceDepositAccountModalComponent
        selectBagList={selectBagList}
        selectUser={selectUser}
        onSelectReduceDepositBag={() => setIsShowSelectBagReduceDepositComponent(true)}
        mode={mode}
        isVisible={isShowAddReduceDepositModal}
        onModalClose={() => setIsShowAddReduceDepositModal(false)}
      />

      <MemberCouponComponent
        isLogin={isLogin}
        isReadMode
        isVisible={isShowMemberComponentModal}
        onModalClose={() => setIsShowMemberComponentModal(false)}
        onMemberCouponCount={count => setMemberCouponCount(count)}
      />

      <ReduceDepositMainComponent
        isVisible={isShowReduceDepositModal}
        data={reduceDepositList}
        onEditReduceDepositBag={onEditReduceDepositBag}
        onModalClose={() => setIsShowReduceDepositModal(false)}
        onAddReduceDeposit={() => {
          setMode('add');
          setSelectBagList([]);
          setSelectUser({});
          setIsShowReduceDepositModal(false);
          setIsShowAddReduceDepositModal(true);
        }}
      />

      <ReduceDepositSelectBagComponent
        onSelectBag={onSelectBag}
        myBagList={myBagList}
        selectBagList={selectBagList}
        isVisible={isShowSelectBagReduceDepositComponent}
        onModalClose={() => setIsShowSelectBagReduceDepositComponent(false)}
      />

      <WithdrawMoneyComponent
        isVisible={isShowWithDrawMoneyModal}
        onModalClose={() => setIsShowWithDrawMoneyModal(false)}
        userId={memberId}
      />
      {/* //ToDo: Hidden protect tree */}
      {/* <ProtectTreePanelComponent
        onClickProtectTree={() => setIsShowProtectTreeComponent(true)}
      /> */}

      <MemberOtherFeatureComponent
        memberCouponCount={memberCouponCount}
        ReduceDepositUserNumber={getReduceDepositUser()}
        onClickReduceDeposit={() => setIsShowReduceDepositModal(true)}
        onShowMemberCouponComponent={() => setIsShowMemberComponentModal(true)}
        onClickWithDrawMoney={() => setIsShowWithDrawMoneyModal(true)}
        withdrawNumber={withdrawNumber}
      />

      <MemberInfoComponent
        reduxStatus={reduxStatus}
        resetStatus={resetStatus}
        reduxError={reduxError}
        memberInfo={memberInfo}
        handleChangePassword={handleChangePassword}
        handleUpdateMemberInfo={handleUpdateMemberInfo}
        setShowChangePasswordModal={setShowChangePasswordModal}
      />

      <ChangePasswordComponent
        reduxStatus={reduxStatus}
        reduxError={reduxError}
        handleChangePassword={handleChangePassword}
        resetStatus={resetStatus}
        showChangePasswordModal={showChangePasswordModal}
        setShowChangePasswordModal={setShowChangePasswordModal}
      />
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer} key="baseContainer">
          {content}
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer} key="baseContainer">
          {content}
        </div>
      </Mobile>
    </React.Fragment>
  );
};

MemberPage.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  memberInfo: PropTypes.shape({}),
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  resetStatus: PropTypes.func.isRequired,
  handleChangePassword: PropTypes.func.isRequired,
  handleUpdateMemberInfo: PropTypes.func.isRequired,
  reduceDepositList: PropTypes.array,
  myBagList: PropTypes.array,
  isDefaultShowMemberCoupon: PropTypes.string,
  withdrawNumber: PropTypes.number,
};

MemberPage.defaultProps = {
  reduxError: null,
  memberInfo: {},
  reduceDepositList: [],
  myBagList: [],
  isDefaultShowMemberCoupon: '',
  withdrawNumber: 0,
};

export default MemberPage;
