import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../ActionCreator';
import Subscribe from './Subscribe';
import {
  reduxStatusSelector,
  reduxErrorSelector,
} from '../selector';


const SubscribeContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    resetStatus,
    sendSubscribeFlowRequest,
  },
}) => {
  useEffect(() => {
    resetStatus();
    return resetStatus;
  }, []);
  const onSendSubscribe = (data) => {
    sendSubscribeFlowRequest(data);
  };
  return (
    <Subscribe
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      onSendSubscribe={onSendSubscribe}
    />
  );
};

SubscribeContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,

  actions: PropTypes.shape({
    resetStatus: PropTypes.func,
    sendSubscribeFlowRequest: PropTypes.func,
  }).isRequired,
};

SubscribeContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscribeContainer);
