import * as R from 'ramda';
import { createSelector } from 'reselect';
import { specialSelectStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  specialSelectStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  specialSelectStateSelector,
  result => R.path(['error', 'message'], result),
);

export const specialSelectListSelector = createSelector(
  specialSelectStateSelector,
  result => R.path(['data'], result),
);
