import React from 'react';
import { Link } from '@reach/router';
import Assets from '../../../constants/Assets';
import Default from '../../../components/RWDComponent/Default';
import Mobile from '../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    margin: '50px 0px',
    backgroundColor: 'white',
    height: 'auto',
    paddingTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainMobileContainer: {
    margin: 0,
    backgroundColor: 'white',
    height: 'auto',
    paddingTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  blockFrontContainer: {
    width: '33.5%',
  },
  blockMidContainer: {
    width: '29.5%',
  },
  blockBlackContainer: {
    width: '35%',
  },
  img: {
    width: '100%',
    height: '100%',
  },
  imgContainer: {
    position: 'relative',
  },
  textContainer: {
    position: 'absolute',
    bottom: '30px',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '40px',
  },
  buyTextContainer: {
    position: 'absolute',
    bottom: '5px',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '30px',
  },
  title: {
    color: 'white',
    margin: '4px 0px',
  },
  text: {
    color: 'black',
    marginBottom: 10,
    fontSize: '12px',
  },
  titleWhite: {
    fontSize: '30px',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '-120px',
    width: '200px',
    color: 'white',
    fontFamily: 'Baskerville-Old-Facef',
  },
  textWhite: {
    color: 'white',
    marginTop: -40,
    fontSize: '12px',
  },
  picContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  picButtonContainer: {
    width: '30%',
    height: 'auto',
    position: 'relative',
    margin: '20px 0px',
  },
  linkButton: {
    width: '100%',
    color: 'white',
  },
  bottomContainer: {
    padding: '20px 0px',
    color: 'white',
  },
  subTitle: {
    marginBottom: '8px',
  },
  mobileImg: {
    width: '90vw',
    margin: '5px 0px',
  },
  definePicContainer: {
    width: '80%',
    marginTop: -20,
    position: 'relative',
  },
  definePic: {
    width: '100%',
  },
  defineTitle: {
    fontSize: 50,
    position: 'absolute',
    color: 'white',
    fontWeight: 500,
    letterSpacing: 100,
    top: '20%',
    left: '40%',
  },
  defineSubTitle: {
    fontSize: 15,
    position: 'absolute',
    color: 'white',
    fontWeight: 500,
    letterSpacing: 20,
    top: '60%',
    left: '36%',
  },
};

const RenderImgBlock = item => (
  <div style={styles.picButtonContainer}>
    <Link
      to={item.link}
      style={styles.linkButton}
    >
      <img
        src={item.path}
        alt=""
        style={styles.img}
      />
      <div style={{ ...styles.bottomContainer, ...item.backgroundColor }}>
        <h1 style={styles.title}>
          {item.title}
        </h1>
        <div style={item.subTitle ? styles.subTitle : null}>
          {item.subTitle}
        </div>
        {item.text}
      </div>
    </Link>
  </div>
);

const RenderImgMobileBlock = item => (
  <Link to={item.link}>
    <img
      src={item.mobilePath}
      style={styles.mobileImg}
      alt=""
    />
  </Link>
);

const HomeBlockComponents = () => {
  const imgData = [
    // {
    //   path: Assets.homeBlockVer204,
    //   mobilePath: Assets.homeMobileBlock04,
    //   link: `${Paths.BUY_AREA_ALL_NEW}?search={"allCommodity":true,"bagType":[1,3],"allNew":true}&page=1`,
    //   title: '新品選購',
    //   subTitle: '為造型激起時尚浪花的迷人新作',
    //   text: '挑選新品 >',
    //   backgroundColor: {
    //     backgroundColor: '#927765',
    //   },
    // },
    // {
    //   path: Assets.homeBlockVer206,
    //   mobilePath: Assets.homeMobileBlock06,
    //   link: `${Paths.BUY_AREA}?search={"allCommodity":true,"bagType":[1,3]}&page=1`,
    //   title: '探索二手',
    //   subTitle: '多樣化高端二手精品提供選購',
    //   text: '安心選購 >',
    //   backgroundColor: {
    //     backgroundColor: '#927765',
    //   },
    // },
    // {
    //   path: Assets.homeBlockVer203,
    //   mobilePath: Assets.homeMobileBlock03,
    //   link: `${Paths.ACCESSORIES_LIST}?search={"bagType":[1,3]}&page=1`,
    //   title: '飾品租借',
    //   subTitle: '大牌配件 創造無限可能',
    //   text: '前往配件 >',
    //   backgroundColor: {
    //     backgroundColor: '#927765',
    //   },
    // },
    // {
    //   path: Assets.homeBlockVer201,
    //   mobilePath: Assets.homeMobileBlock01,
    //   link: `${Paths.BAG_LIST}?search={"bagType":[1,3]}&page=1`,
    //   title: '包款租借',
    //   subTitle: '人手一包也能是代言人',
    //   text: '前往租借 >',
    //   backgroundColor: {
    //     backgroundColor: '#927765',
    //   },
    // },
    // {
    //   path: Assets.homeBlockVer202,
    //   mobilePath: Assets.homeMobileBlock02,
    //   link: `${Paths.CLOTHES_LIST}?search={"bagType":[1,3]}&page=1`,
    //   title: '服裝租借',
    //   subTitle: '快速租借 即刻擁也百變穿搭',
    //   text: '前往服飾 >',
    //   backgroundColor: {
    //     backgroundColor: '#927765',
    //   },
    // },
    // {
    //   path: Assets.homeBlockVer207,
    //   mobilePath: Assets.homeMobileBlock07,
    //   link: `${Paths.HOW_IT_WORKS}`,
    //   title: '如何運作',
    //   subTitle: '提升時尚美學 讓妳的影響力發揮',
    //   text: '了解更多 >',
    //   backgroundColor: {
    //     backgroundColor: '#927765',
    //   },
    // },
  ];

  return (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          <div style={styles.picContainer}>
            {imgData.map(RenderImgBlock)}
          </div>
          <div style={styles.definePicContainer}>
            <img
              src={Assets.homeBlockVer208}
              style={styles.definePic}
              alt=""
            />
            <div style={styles.defineTitle}>專業鑑定</div>
            <div style={styles.defineSubTitle}>嚴格品質把關 精品手袋皆經平台出貨</div>
          </div>
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          {imgData.map(RenderImgMobileBlock)}
        </div>
      </Mobile>
    </React.Fragment>

  );
};

export default HomeBlockComponents;
