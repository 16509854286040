import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BagListPage from './BagListPage';

import {
  reduxStatusSelector,
  reduxErrorSelector,
  bagListSelector,
  bagNumberSelector,
} from '../../selector';

import {
  cartReduxStatusSelector,
  cartReduxErrorSelector,
} from '../../../Cart/selector';

import {
  clothesListSelector,
} from '../../../Clothes/selector';

const BagListPageContainer = ({
  reduxStatus,
  reduxError,
  cartReduxStatus,
  cartReduxError,
  bags,
  clothes,
  isHomepage,
  isClothes,
  bagNumber,
  isBuyArea,
  commodityType,
  allNew,
}) => {
  const data = isClothes ? clothes : bags;

  return (
    <BagListPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      cartReduxStatus={cartReduxStatus}
      cartReduxError={cartReduxError}
      data={data}
      isHomepage={isHomepage}
      isClothes={isClothes}
      bagNumber={bagNumber}
      isBuyArea={isBuyArea}
      commodityType={commodityType}
      allNew={allNew}
    />
  );
};

BagListPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  cartReduxError: PropTypes.string,
  cartReduxStatus: PropTypes.string,
  bags: PropTypes.array,
  clothes: PropTypes.array,
  isHomepage: PropTypes.bool,
  isClothes: PropTypes.bool,
  bagNumber: PropTypes.number,
  isBuyArea: PropTypes.bool,
  commodityType: PropTypes.number,
  allNew: PropTypes.bool,
};

BagListPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  cartReduxError: null,
  cartReduxStatus: null,
  isHomepage: false,
  bags: [],
  clothes: [],
  isClothes: false,
  bagNumber: 0,
  isBuyArea: false,
  commodityType: 0,
  allNew: false,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  bags: bagListSelector(state),
  clothes: clothesListSelector(state),
  bagNumber: bagNumberSelector(state),
  cartReduxStatus: cartReduxStatusSelector(state),
  cartReduxError: cartReduxErrorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BagListPageContainer);
