/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import CameraRecorder from './Pages/CameraRecorder';
import UploadLicense from './Pages/UploadLicense';
import StepsPage from './Pages/StepsPage';
import LandingPage from './Pages/LandingPage';
import StartRecord from './Pages/StartRecorder';
import StartCard from './Pages/StartCard';
import SendInfoConfirm from './Pages/SendInfoConfirm';
import SendSuccess from './Pages/SendSuccess';
import CancelPage from './Pages/CancelPage';

import Paths from '../../routes/Paths';

import Button from './Parts/Button';

import envConfig from '../../lib/configEnv';

import identificationAPI from './utils/api';

import style from './style.module.scss';

export const IdentifySteps = {
  initial: 0,
  stepsPage: 1,
  startRecord: 2,
  record: 3,
  startCard: 4,
  uploadFront: 5,
  uploadBack: 6,
  sendInfoConfirm: 7,
  sendSuccess: 8,
  cancel: 9,
};


const Identification = ({
  memberId,
  onClose,
  isShowCancelPage,
  onGiveUpCancel,
}) => {
  const [steps, setSteps] = React.useState(IdentifySteps.initial);
  const [isAgree, setIsAgree] = React.useState(false);
  const [uploadFiles, setUploadFiles] = React.useState({
    video: null,
    frontPic: null,
    backPic: null,
  });
  const [sentData, setSentDate] = React.useState(
    {
      start: false,
      video: false,
      googleResult: false,
      created: false,
    },
  );
  const [isSending, setIsSending] = React.useState(
    false,
  );
  const isNextDisabled = () => {
    if (steps === IdentifySteps.uploadBack) {
      return !uploadFiles.backPic;
    }

    if (steps === IdentifySteps.stepsPage) {
      return !isAgree;
    }
    if (steps === IdentifySteps.uploadFront) {
      return !uploadFiles.frontPic;
    }

    if (steps === IdentifySteps.uploadBack) {
      return !uploadFiles.backPic;
    }

    return false;
  };


  const submitVideo = async formData => identificationAPI('/Resources/uploadVerifyVideo', formData, 'Error uploading video:');

  const submitPic = async formData => identificationAPI('/Resources/uploadVerifyLicense', formData, 'Error uploading photo:');

  const uploadGoogle = async formData => identificationAPI('/Resources/googleVision', formData, 'Error uploading photo:');

  const createIdentifyData = async (data) => {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', '2KrAZWDRBGDDXgDdzB6wadPi3ZC5ZYkuNOH2f9ECn4VbsPJzqYgGA7Y3CyKVmFyP');
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify(data);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const result = fetch(`${envConfig.API_BASE_URL}/Identifications/createIdentification`, requestOptions)
      .then(response => response.text())
      // eslint-disable-next-line no-console
      .then(() => ({
        success: true,
      }))
      // eslint-disable-next-line no-console
      .catch(() => ({
        success: false,
      }));

    return result;
  };

  const handleClickNext = (identifyFile) => {
    const newFile = { ...uploadFiles, ...identifyFile };
    setUploadFiles(newFile);
  };

  const handleSentVerify = async () => {
    setIsSending(true);
    setSentDate({
      start: true,
    });
    const videoResult = await submitVideo(uploadFiles.video);
    setSentDate({
      start: true,
      video: true,
    });
    const frontPicResult = await submitPic(uploadFiles.frontPic);
    const backPicResult = await submitPic(uploadFiles.backPic);
    setSentDate({
      start: true,
      video: true,
      IDCard: true,
    });
    const googleResult = await uploadGoogle(uploadFiles.frontPic);
    setSentDate({
      start: true,
      googleResult: true,
      video: true,
      IDCard: true,
    });

    const data = {
      userId: memberId,
      cardFrontId: frontPicResult.id,
      cardBackId: backPicResult.id,
      videoId: videoResult.id,
      personalId: googleResult.idNumber,
      realName: googleResult.name,
      gender: googleResult.gender,
      birthday: googleResult.birthDate,
    };

    await createIdentifyData(data);

    setSentDate({
      start: true,
      googleResult: true,
      video: true,
      IDCard: true,
      created: true,
    });

    setTimeout(() => {
      setSteps(IdentifySteps.sendSuccess);
    }, 500);
  };

  const StepsButton = () => {
    const nextButton = () => {
      if (steps === IdentifySteps.sendInfoConfirm
         && uploadFiles.video && uploadFiles.frontPic
          && uploadFiles.backPic) {
        return (
          <Button
            type="button"
            onClick={() => { handleSentVerify(); }}
            disabled={isSending}
          >
            {isSending ? '送出中..' : '是，確定' }
          </Button>
        );
      }

      return (
        <Button
          onClick={() => { setSteps(steps + 1); }}
          disabled={isNextDisabled()}
        >
          下一步
        </Button>
      );
    };


    if (steps === IdentifySteps.sendSuccess) {
      return (
        <div className={style.buttonContainer}>

          <Button
            onClick={() => { }}
          >
            <Link to={Paths.HOME}>去逛逛</Link>
          </Button>
        </div>
      );
    }

    if (isShowCancelPage) {
      return (
        <div className={style.cancelButtonContainer}>
          <Button
            onClick={onGiveUpCancel}
          >
            不，返回上一頁
          </Button>
          <Button
            onClick={onClose}
          >
            是，確定
          </Button>
        </div>
      );
    }

    return (
      <div className={style.buttonContainer}>
        <Button
          type="button"
          onClick={() => {
            if (steps > 0) {
              setSteps(steps - 1);
            }
          }}
        >
          上一步
        </Button>
        {nextButton()}
      </div>
    );
  };


  const renderContent = () => {
    if (isShowCancelPage) {
      return (
        <CancelPage />
      );
    }

    if (steps === IdentifySteps.initial) {
      return (
        <LandingPage />
      );
    }

    if (steps === IdentifySteps.stepsPage) {
      return (
        <StepsPage
          onClickAgree={(event) => { setIsAgree(event.target.checked); }}
        />
      );
    }


    if (steps === IdentifySteps.record) {
      return (
        <>
          <CameraRecorder onClickStop={handleClickNext} />
        </>
      );
    }

    if (steps === IdentifySteps.startRecord) {
      return (
        <>
          <StartRecord onClickStop={handleClickNext} />
        </>
      );
    }

    if (steps === IdentifySteps.startCard) {
      return (
        <>
          <StartCard onClickStop={handleClickNext} />
        </>
      );
    }

    if (steps === IdentifySteps.uploadFront) {
      return (
        <>
          <UploadLicense
            title="請將證件正面置入框內"
            onClickStop={handleClickNext}
            fileKey="frontPic"
          />
        </>
      );
    }

    if (steps === IdentifySteps.uploadBack) {
      return (
        <UploadLicense
          title="請將證件背面面置入框內"
          onClickStop={handleClickNext}
          fileKey="backPic"
        />
      );
    }

    if (steps === IdentifySteps.sendInfoConfirm) {
      return (
        <SendInfoConfirm sentData={sentData} />
      );
    }

    if (steps === IdentifySteps.sendSuccess) {
      return (
        <SendSuccess />
      );
    }
    return null;
  };


  return (
    <div className={style.container}>
      {renderContent()}
      <StepsButton />
    </div>
  );
};

export default Identification;

Identification.propTypes = {
  memberId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onGiveUpCancel: PropTypes.func.isRequired,
  isShowCancelPage: PropTypes.bool.isRequired,
};
