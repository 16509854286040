const passbaseStatus = {
  initial: '初始註冊',
  maybeLate: '稍後驗證',
  processing: '驗證中',
  accept: '驗證成功',
  reject: '驗證失敗',
};

const passbaseStatusId = {
  initial: 1,
  maybeLate: 2,
  processing: 3,
  accept: 4,
  reject: 5,
};

module.exports = {
  passbaseStatus,
  passbaseStatusId,
};
