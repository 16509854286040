import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import HomePage from './HomePage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
} from '../../selector';
import {
  memberNameSelector,
  isLogInSelector,
} from '../../../Member/selector';

const HomePageContainer = ({
  reduxStatus,
  reduxError,
  memberName,
  isLogin,
}) => (
  <HomePage
    reduxStatus={reduxStatus}
    reduxError={reduxError}
    memberName={memberName}
    isLogin={isLogin}
  />
);

HomePageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  memberName: PropTypes.string,
  isLogin: PropTypes.bool.isRequired,
  actions: PropTypes.shape({

  }).isRequired,
};

HomePageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  memberName: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  memberName: memberNameSelector(state),
  isLogin: isLogInSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePageContainer);
