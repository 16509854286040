import * as R from 'ramda';
import { createSelector } from 'reselect';
import { negotiationStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  negotiationStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  negotiationStateSelector,
  result => R.path(['error', 'message'], result),
);

export const negotiationListSelector = createSelector(
  negotiationStateSelector,
  result => R.path(['data'], result),
);
