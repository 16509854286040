import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const styles = {
  mainContainer: {
    width: 'fit-content',
    height: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  preNextButton: {
    width: 30,
    height: 30,
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    color: 'black',
  },
  pageInactive: {
    fontSize: 14,
    textAlign: 'center',
    color: 'black',
    margin: 3,
    height: 30,
    width: 30,
    backgroundColor: 'transparent',
    cursor: ' pointer',
    border: 'none',
    outline: 'none',
    fontStyle: 'bold',
  },
  pageActive: {
    cursor: ' pointer',
    border: 'none',
    outline: 'none',
    fontSize: 14,
    textAlign: 'center',
    borderRadius: 50,
    backgroundColor: '#eee',
    color: 'black',
    margin: 3,
    height: 30,
    width: 30,
    fontStyle: 'bold',
  },
};

const PaginationComponent = ({
  total,
  pageSize,
  onIndexChange,
}) => {
  if (total <= 0) {
    onIndexChange(null, null);
    return null;
  }

  const [current, setCurrent] = useState(1);
  const pageCount = Math.ceil(total / pageSize);

  useEffect(() => {
    if (current === 1 && pageCount === 1) {
      onIndexChange(0, total - 1);
      return;
    }
    if (current === pageCount) {
      onIndexChange((current - 1) * pageSize, total - 1);
      return;
    }
    onIndexChange(pageSize * (current - 1), pageSize * current - 1);
  }, [current]);

  const renderPage = () => {
    const pages = [];
    for (let i = 1; i <= pageCount; i += 1) {
      pages.push(
        <button
          key={i}
          type="button"
          onClick={() => { setCurrent(i); }}
          style={i === current ? styles.pageActive : styles.pageInactive}
        >
          {i}
        </button>,
      );
    }
    return pages;
  };

  return (
    <div style={styles.mainContainer}>
      <Button
        type="link"
        icon="left"
        style={styles.preNextButton}
        onClick={() => { setCurrent(current - 1 < 1 ? 1 : current - 1); }}
      />
      {renderPage()}
      <Button
        type="link"
        icon="right"
        style={styles.preNextButton}
        onClick={() => { setCurrent(current + 1 > pageCount ? pageCount : current + 1); }}
      />
    </div>
  );
};

PaginationComponent.propTypes = {
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  onIndexChange: PropTypes.func,
};

PaginationComponent.defaultProps = {
  pageSize: 10,
  onIndexChange: () => {},
};

export default PaginationComponent;
