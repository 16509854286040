import React from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
} from 'antd';
import HeaderContainer from '../../modules/Header/components/HeaderComponent';
import Footer from './Footer';
import Default from '../RWDComponent/Default';
import Mobile from '../RWDComponent/Mobile';

const {
  Content,
} = Layout;

const styles = {
  mainContainer: {
    backgroundColor: 'white',
    minHeight: '100vh',
    overflow: 'hidden',
  },
  contentContainer: {
    maxWidth: 1500,
    width: '100%',
    margin: '0 auto',
    paddingTop: '120px',
    backgroundColor: 'white',
  },
  contentScrolledContainer: {
    maxWidth: 1500,
    width: '100%',
    margin: '0 auto',
    paddingTop: '120px',
    backgroundColor: 'white',
  },
  contentMobileContainer: {
    maxWidth: 1500,
    width: '100%',
    margin: '0 auto',
    paddingTop: '60px',
    backgroundColor: 'white',
  },
  fullContentContainer: {
    width: '100%',
    margin: '0 auto',
    backgroundColor: 'white',
    paddingTop: '50px',
  },
};

const GeneralLayout = (props) => {
  const {
    Child,
    showHeader,
    showFooter,
    fullWidthContent,
    headerScrolled,
    ...otherProps
  } = props;

  return (
    <Layout style={styles.mainContainer}>
      {showHeader && <HeaderContainer headerScrolled={headerScrolled} />}
      <Default>
        {
          headerScrolled ? (
            <Content
              style={
            fullWidthContent
              ? styles.fullContentContainer
              : styles.contentScrolledContainer}
            />
          ) : (
            <Content
              style={
            fullWidthContent
              ? styles.fullContentContainer
              : styles.contentContainer}
            />
          )
        }

      </Default>
      <Mobile>
        <Content style={
          fullWidthContent
            ? styles.fullContentContainer
            : styles.contentMobileContainer}
        />
      </Mobile>
      <Child {...otherProps} />
      {showFooter && <Footer />}
    </Layout>
  );
};

GeneralLayout.propTypes = {
  Child: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  fullWidthContent: PropTypes.bool,
  headerScrolled: PropTypes.bool,
};

GeneralLayout.defaultProps = {
  showHeader: true,
  showFooter: true,
  fullWidthContent: false,
  headerScrolled: false,
};

export default GeneralLayout;
