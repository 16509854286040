import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

const Default = ({
  children,
  width,
}) => {
  const minWidth = width || 1101;
  const isNotMobile = useMediaQuery({ minWidth });
  return isNotMobile ? children : null;
};

Default.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
};

Default.defaultProps = {
  width: null,
};

export default Default;
