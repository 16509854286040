import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button } from 'antd';
import moment from 'moment';
import { Link } from '@reach/router';
import * as R from 'ramda';
import { orderStatus } from '../../../constants/OrderStatus';
import amountPattern from '../../../utils/AmountPattern';
import bagOrClothesPath from '../../../utils/bagOrClothesPath';
import RenderOrderStatusPanel from './RenterOrderStatusPanel';
import LenderOrderStatusPanel from './LenderOrderStatusPanel';
import calculateOrderNo from '../../../utils/calculateOrderNo';
import { shippingCost } from '../../../constants/ShippingAmount';

const styles = {
  mainContainer: {
    width: '100%',
  },
  upTableContainer: {
    padding: '10px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
  },
  upTableOrderListContainer: {
    padding: '10px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  img: {
    width: 90,
    objectFit: 'contain',
    height: 90,
    backgroundColor: 'white',
    minWidth: '90px',
  },
  OrderListImg: {
    width: '100%',
    objectFit: 'contain',
    height: 'auto',
    backgroundColor: 'white',
    minWidth: '90px',
  },
  mainColor: {
    color: '#AE4200',
    fontSize: 16,
  },
  subColor: {
    color: 'black',
    fontSize: 16,
  },
  withdrawButton: {
    width: '80px',
    height: '30px',
  },
  withdrawButtonDisable: {
    width: '80px',
    height: '30px',
  },
  collapseContainer: {
    height: 'auto',
    padding: 10,
    borderBottom: '1px grey solid',
    borderTop: '1px grey solid',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  expand: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    color: 'black',
  },
  upTable: {
    tableLayout: 'fixed',
    width: '60%',
  },
  upTableRow: {
    height: '10px',
    width: '100%',
  },
  upTableTitle: {
    width: '40%',
  },
  upTableContent: {
    paddingLeft: '6px',
    width: '60%',
  },
  payButtonStyle: {
    width: '100px',
    height: '30px',
  },
  collapseTable: {
    tableLayout: 'fixed',
  },
  collapseTableTitle: {
    height: '30px',
  },
  collapseTableContent: {
    padding: '2px 15px',
  },
  userCouponContainer: {
    margin: '5px 0px',
    height: 'fit-content',
    padding: 10,
    width: '100%',
    backgroundColor: '#eee',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  couponInfo: {
    width: '100%',
    textAlign: 'right',
  },
  shippingStatusButton: {
    width: '95%',
    textAlign: 'left',
    margin: '0px auto',
  },
  tableBottomLine: {
    width: '100%',
    borderBottom: '1px grey solid',
  },
  nameTitle: {
    textAlign: 'center',
  },
  mainTable: {
    tableLayout: 'fixed',
    borderBottom: '1px solid #BABABA',
    width: '100%',
    margin: '20px 0',
  },
  innerTable: {
    marginTop: '20px',
    borderTop: '1px solid #BABABA',
  },
  innerNormalColumn: {
    padding: 10,
    width: '15%',
    borderRight: '1px solid #BABABA',
    borderLeft: '1px solid #BABABA',
  },
  innerWidthColumn: {
    padding: 10,
    width: '30%',
    borderRight: '1px solid #BABABA',
    borderLeft: '1px solid #BABABA',
  },
  couponInfoContainer: {
    border: '1px solid #BABABA',
    width: '100%',
    height: '40px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  couponTitle: {
    width: '50%',
    textAlign: 'left',
    paddingLeft: '10px',
  },
  couponText: {
    width: '50%',
    textAlign: 'right',
    paddingRight: '10px',
  },
  tableBottomBorder: {
    borderBottom: '1px solid #BABABA',
  },
  link: {
    width: '50%',
    marginBottom: '20px',
  },
  orderListStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '5px 0px',
  },
  orderListTitle: {
    width: '70px',
  },
  orderListTitleContainer: {
    marginBottom: 30,
    width: '100%',
  },
};

const OrderDetailMobileComponent = ({
  order,
  renderOperate,
  isRentedSoldHistory,
  index,
  setIsShowProductSendModal,
  setIsShowCancelOrderModal,
  onConfirmOrder,
  setIsShowProductReturnConfirmModal,
  setIsShowProductIssueReportModal,
  shippingInfo,
  renderRentTimeOrBuyTableContent,
  couponCode,
  couponAmount,
  renderDelayInfo,
  renderReply,
  renderCommentButton,
  renderRenterToBuyContent,
  renderShippingDate,
}) => {
  const [isShippingStatusExpand, setIsShippingStatusExpandExpand] = useState(false);
  const [isShippingInfoExpand, setIsShippingInfoExpandExpand] = useState(false);
  const [isOrderDetailExpand, setIsOrderDetailExpand] = useState(false);
  const status = R.pathOr('', ['orderStatus', 'name'], order);
  const commodityType = R.pathOr(1, ['commodityTypeId'], order);

  const renderDepositWithCoupon = () => {
    const couponTypeName = R.path(['userCoupon', 'coupon', 'couponType', 'name'], order);
    if (couponTypeName === '押金折扣碼') {
      return `NT $${amountPattern(R.pathOr(0, ['deposit'], order) - R.pathOr(0, ['couponAmount'], order))}`;
    }

    return `NT $${amountPattern(R.pathOr(0, ['deposit'], order))}`;
  };

  const renderRentTimeTitle = () => {
    if (isRentedSoldHistory && R.pathOr('rent', ['type', 'name'], order) === 'buy') return '買主';

    if (!isRentedSoldHistory) return '商品名稱';
    return '租借時間';
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.upTableOrderListContainer}>
        <Link
          to={`${bagOrClothesPath(commodityType)}/${R.pathOr(null, ['bag', 'bagNo'], order)}`}
          style={styles.link}
        >
          <img alt="" style={styles.OrderListImg} src={R.pathOr(null, ['bag', 'frontPic', 'uri'], order)} />
        </Link>
        {renderRenterToBuyContent(true)}
        <div style={styles.orderListTitleContainer}>
          <div style={styles.orderListStatus}>
            <div style={styles.orderListTitle}>
              訂單狀態
            </div>
            {
              isRentedSoldHistory ? (
                <div style={
                  status !== orderStatus.complete
                    ? styles.mainColor
                    : styles.subColor}
                >
                  {status}
                </div>
              ) : (
                <React.Fragment>
                  {renderOperate(
                    R.pathOr('0', ['id'], order),
                    R.pathOr('', ['orderStatus', 'name'], order),
                    R.pathOr('rent', ['type', 'name'], order),
                    R.pathOr(0, ['zeroCardInstallment'], order),
                    R.pathOr(null, ['zeroCardStatus'], order),
                    R.pathOr(null, ['payWayType'], order),
                    index,
                    styles.payButtonStyle,
                  )}
                </React.Fragment>
              )
              }
          </div>
          <div style={styles.orderListStatus}>
            <div style={styles.orderListTitle}>
              訂單編號
            </div>
            {calculateOrderNo(R.pathOr('', ['id'], order))}
          </div>
          <div style={styles.orderListStatus}>
            {
              isRentedSoldHistory ? (
                <React.Fragment>
                  <div style={styles.orderListTitle}>
                    出貨日期
                  </div>
                  { renderShippingDate()}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div style={styles.orderListTitle}>
                    租借日期
                  </div>
                  { renderRentTimeOrBuyTableContent(true)}
                </React.Fragment>
              )
            }
          </div>
        </div>
        {renderCommentButton()}
      </div>

      <div style={styles.shippingStatusButton}>
        訂單細項
        <Button
          icon={isOrderDetailExpand ? 'up' : 'down'}
          onClick={() => setIsOrderDetailExpand(!isOrderDetailExpand)}
          type="link"
          style={styles.expand}
        />
      </div>

      {isOrderDetailExpand ? (
        <React.Fragment>
          <table style={{ ...styles.mainTable, ...styles.innerTable }}>
            <thead>
              <tr style={styles.tableBottomBorder}>
                <th style={styles.innerNormalColumn}>訂單日期</th>
                <th style={styles.innerNormalColumn}>商品會員</th>
                <th style={styles.innerNormalColumn}>
                  {renderRentTimeTitle()}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={styles.innerNormalColumn}>
                  {moment(R.pathOr('', ['createDate'], order)).format('YYYY/MM/DD')}
                </td>
                <td style={styles.innerNormalColumn}>
                  <div style={styles.orderRole}>
                    <Avatar
                      style={styles.avatarMarginRight}
                      size="default"
                      icon="user"
                      src={`${R.pathOr('', ['user', 'avatar', 'uri'], order)}`}
                    />
                    {`${R.pathOr('', ['user', 'communityName'], order)}`}
                  </div>
                </td>
                <td style={styles.innerNormalColumn}>
                  {isRentedSoldHistory ? (
                    renderRentTimeOrBuyTableContent()
                  ) : R.pathOr(null, ['bag', 'name'], order)}
                </td>
              </tr>
            </tbody>
          </table>

          <table style={{ ...styles.mainTable, ...styles.innerTable }}>
            <thead>
              <tr style={styles.tableBottomBorder}>
                {
                  isRentedSoldHistory ? null : (<th style={styles.innerNormalColumn}>押金</th>)
                }
                <th style={isRentedSoldHistory ? styles.innerNormalColumn : styles.innerWidthColumn}>
                  { R.pathOr('rent', ['type', 'name'], order) === 'buy' ? '金額' : '租金'}
                </th>
                { isRentedSoldHistory
                  ? (
                    <React.Fragment>
                      <th style={styles.innerNormalColumn}>折扣代碼</th>
                      <th style={styles.innerNormalColumn}>實際賺取</th>
                    </React.Fragment>

                  )
                  : null}
              </tr>
            </thead>
            <tbody>
              <tr>
                {
                  isRentedSoldHistory ? null : (
                    <td style={styles.innerNormalColumn}>
                      {renderDepositWithCoupon()}
                    </td>
                  )
                }
                {
                  isRentedSoldHistory ? (
                    <td style={styles.innerNormalColumn}>
                      { R.pathOr('rent', ['type', 'name'], order) === 'buy' ? (
                        <React.Fragment>
                          {`金額  NT$ ${R.pathOr('', ['amount'], order) - shippingCost.blackCat}`}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div>
                            押金
                          </div>
                          {renderDepositWithCoupon()}
                          <br />
                          <div>
                            租金
                          </div>
                          {`NT$ ${R.pathOr('', ['amount'], order)}`}
                        </React.Fragment>
                      )}
                    </td>
                  ) : (
                    <td style={styles.innerWidthColumn}>
                      {`NT$ ${R.pathOr('', ['amount'], order)}`}
                    </td>
                  )
                }

                { isRentedSoldHistory ? (
                  <React.Fragment>
                    <td style={styles.innerNormalColumn}>
                      { couponCode ? `${couponCode} (NT$${couponAmount})` : null}
                    </td>
                    <td style={styles.innerNormalColumn}>
                      {`NT$ ${R.pathOr('-', ['ownerEarnedAmount'], order)}`}
                    </td>
                  </React.Fragment>
                ) : null}
              </tr>
            </tbody>
          </table>

          {
            isRentedSoldHistory ? null : (
              <div style={styles.couponInfoContainer}>
                <div style={styles.couponTitle}>
                  折扣代碼
                </div>
                <div style={styles.couponText}>
                  { couponCode ? `${couponCode} (NT$${couponAmount})` : null}
                </div>
              </div>
            )
          }

          {renderDelayInfo}
          {renderReply}
        </React.Fragment>
      ) : null}

      <div style={styles.shippingStatusButton}>
        出貨狀態
        <Button
          icon={isShippingStatusExpand ? 'up' : 'down'}
          onClick={() => setIsShippingStatusExpandExpand(!isShippingStatusExpand)}
          type="link"
          style={styles.expand}
        />
      </div>
      {isShippingStatusExpand ? (
        <React.Fragment>
          <div style={styles.collapseContainer}>
            {isRentedSoldHistory ? (
              <LenderOrderStatusPanel
                order={order}
                onConfirmOrder={() => onConfirmOrder()}
                onCancelOrder={() => setIsShowCancelOrderModal(true)}
                onProductSend={() => setIsShowProductSendModal(true)}
                onProductReturnConfirm={() => setIsShowProductReturnConfirmModal(true)}
                onProductIssueReport={() => setIsShowProductIssueReportModal(true)}
              />
            ) : (
              <RenderOrderStatusPanel
                order={order}
                onCancelOrder={() => setIsShowCancelOrderModal(true)}
                onProductSend={() => setIsShowProductSendModal(true)}
              />
            )}
          </div>
        </React.Fragment>
      ) : null}

      <div style={styles.shippingStatusButton}>
        物流資訊
        <Button
          icon={isShippingInfoExpand ? 'up' : 'down'}
          onClick={() => setIsShippingInfoExpandExpand(!isShippingInfoExpand)}
          type="link"
          style={styles.expand}
        />
      </div>

      {isShippingInfoExpand ? (
        <React.Fragment>
          <div style={styles.collapseContainer}>
            {shippingInfo()}
          </div>
        </React.Fragment>
      ) : null}

      <div style={styles.tableBottomLine} />
    </div>
  );
};

OrderDetailMobileComponent.propTypes = {
  order: PropTypes.object,
  renderOperate: PropTypes.func,
  isRentedSoldHistory: PropTypes.bool,
  index: PropTypes.number.isRequired,
  setIsShowProductSendModal: PropTypes.func.isRequired,
  setIsShowCancelOrderModal: PropTypes.func.isRequired,
  onConfirmOrder: PropTypes.func.isRequired,
  setIsShowProductReturnConfirmModal: PropTypes.func.isRequired,
  setIsShowProductIssueReportModal: PropTypes.func.isRequired,
  shippingInfo: PropTypes.func.isRequired,
  renderRentTimeOrBuyTableContent: PropTypes.func,
  couponCode: PropTypes.string,
  couponAmount: PropTypes.number,
  renderDelayInfo: PropTypes.object,
  renderReply: PropTypes.object,
  renderRenterToBuyContent: PropTypes.func.isRequired,
  renderCommentButton: PropTypes.func.isRequired,
  renderShippingDate: PropTypes.func.isRequired,
};

OrderDetailMobileComponent.defaultProps = {
  order: null,
  renderOperate: () => {},
  renderRentTimeOrBuyTableContent: () => {},
  isRentedSoldHistory: false,
  couponCode: '',
  couponAmount: null,
  renderDelayInfo: {},
  renderReply: {},
};

export default OrderDetailMobileComponent;
