import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { Link } from '@reach/router';
import Subscribe from '../../modules/Subscribe/components';
import { Colors } from '../../constants/Color';
import Paths from '../../routes/Paths';
import Assets from '../../constants/Assets';
import { FontSize } from '../../constants/FontSize';

const styles = {
  footerContainer: {
    width: '100%',
    backgroundColor: '#FBF2F1',
    textAlign: 'center',
    borderTop: '3px solid white',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative',
    height: '450px',
    padding: '50px 10px 0px 10px',
  },
  footerMobileContainer: {
    width: '100%',
    backgroundColor: '#FBF2F1',
    textAlign: 'center',
    borderTop: '3px solid white',
    color: 'black',
  },
  leftContainer: {
    border: '2px white solid',
    height: '600px',
    position: 'relative',
    width: '50%',
  },
  rightContainer: {
    border: '2px white solid',
    width: '50%',
  },
  contentContainer: {
    width: '90%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'left',
    marginTop: '20px',
    marginBottom: '70px',
    maxWidth: 1500,
  },
  bottomContentContainer: {
    width: '90%',
    margin: 'auto',
    marginTop: '10px',
    textAlign: 'right',
  },
  linkContent: {
    fontSize: 20,
    letterSpacing: '2px',
    color: 'black',
  },
  igIconContainer: {
    position: 'absolute',
    bottom: '100px',
  },
  linkTitle: {
    margin: '0 0 20px 0',
    fontSize: 20,
    color: 'black',
  },
  linkText: {
    color: 'black',
    fontSize: '10px',
  },
  introContainer: {
    height: '130px',
    width: '240px',
    fontWeight: '400',
    verticalAlign: 'top',
    textAlign: 'left',
  },
  icon: {
    color: 'black',
    fontSize: '20px',
    margin: '0 10px 0 5px',
  },
  contactTitle: {
    marginBottom: '20px',
    color: 'black',
    fontSize: '18px',
  },
  contactContainer: {
    height: '130px',
    width: '380px',
    textAlign: 'left',
  },
  socialIcon: {
    color: 'black',
    fontSize: '30px',
    margin: '0px 10px 0 10px',
  },
  lineIcon: {
    width: '30px',
    marginTop: '-12px',
    marginLeft: '8px',
  },
  horizontalLine: {
    height: '2px',
    border: 'none',
    backgroundColor: Colors.horizontalLine,
    margin: '0px',
  },
  copyright: {
    marginTop: '27px',
    fontSize: FontSize.overline,
  },
  topContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  bottomContainer: {
    border: '2px red solid',
    height: '250px',
    position: 'relative',
  },
};

const data = [
  {
    title: 'COMPANY',
    link: [
      {
        title: '關於我們',
        path: Paths.ABOUT,
      },
      {
        title: '了解更多',
        path: Paths.HOW_IT_WORKS,
      }],
  },
  {
    title: 'HELP',
    link: [
      {
        title: 'FAQs',
        path: Paths.FAQ,
      },
      {
        title: '隱私權保護',
        path: Paths.PRIVACY,
      },
      {
        title: '會員條款',
        path: Paths.MEMBER_TERMS,
      },
      {
        title: '與我們聯絡',
        path: Paths.CONTACT,
      },
    ],
  },
];

const RenderTitleBlock = ({
  title,
  link,
}) => (
  <div style={styles.introContainer} key={title}>
    <h1 style={styles.linkTitle}>
      {title}
    </h1>
    {
      link.map(item => (
        <div style={styles.linkContent} key={item.title}>
          <Link to={item.path} style={styles.linkText}>{item.title}</Link>
        </div>
      ))
    }
  </div>
);

const linkContent = (
  <React.Fragment>
    <a href="https://www.facebook.com/ohphire.tw/"><Icon type="facebook" style={styles.socialIcon} /></a>
    <a href="https://www.instagram.com/op.taiwan/"><Icon type="instagram" style={styles.socialIcon} /></a>
    <a href="https://lin.ee/BZ5XIWT">
      <img
        alt=""
        src={Assets.footIconLine}
        style={styles.lineIcon}
      />
    </a>
  </React.Fragment>
);

const Footer = () => (
  <React.Fragment>
    <div style={styles.footerContainer}>
      <div style={styles.topContainer}>
        {data.map(RenderTitleBlock)}
        <div style={styles.igIconContainer}>
          {linkContent}
        </div>
      </div>
      <div style={styles.contactContainer}>
        <Link to={Paths.CONTACT}>
          <h1 style={styles.contactTitle}>
            CONTACT US
          </h1>
        </Link>
        <Subscribe />
        <div style={styles.bottomContentContainer}>
          <div style={styles.copyright}>
            OP © 2023 Inc. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

RenderTitleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.array.isRequired,
};


export default Footer;
