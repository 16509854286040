import React from 'react';
import PropTypes from 'prop-types';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    width: 723,
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 50,
  },
  mainMobileContainer: {
    width: '90vw',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 50,
  },
  notify: {
    fontSize: 24,
    color: 'black',
    fontFamily: 'Microsoft JhengHei Bold',
  },
  subTitle: {
    fontSize: 18,
    marginTop: 15,
    fontWeight: 100,
    color: 'black',
    fontFamily: 'Microsoft JhengHei Bold',
  },
  button: {
    border: '1px solid #AE4200',
    color: '#939598',
    fontSize: 16,
    marginTop: 20,
    textAlign: 'center',
    padding: '8px 0px',
    width: 162,
    height: 54,
    cursor: 'pointer',
    outline: 'none',
  },
};

const NotifyDraftBagsSavedComponent = ({
  isVisible,
  onModalClose,
}) => {
  const content = (
    <React.Fragment>
      <div style={styles.notify}>提醒您</div>
      <div style={styles.subTitle}>已儲商品資訊，但尚未發布成功唷！</div>
      <button type="button" style={styles.button} onClick={onModalClose}>我知道了</button>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainContainer}>
              {content}
            </div>
          )}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainMobileContainer}>
              {content}
            </div>
          )}
        />
      </Mobile>
    </React.Fragment>

  );
};

NotifyDraftBagsSavedComponent.propTypes = {
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
};

NotifyDraftBagsSavedComponent.defaultProps = {
  isVisible: false,
};

export default NotifyDraftBagsSavedComponent;
