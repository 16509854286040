import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';
import * as R from 'ramda';
import moment from 'moment';
import ReactPixel from 'react-facebook-pixel';
import { Icon, Avatar, Spin } from 'antd';
import { useWindowSize } from '@react-hook/window-size/throttled';
import Type from '../../constants/Type';
import amountPattern from '../../utils/AmountPattern';
import Paths from '../../routes/Paths';
import Assets from '../../constants/Assets';
import BagStatus from '../../constants/BagStatus';
import NotifyMeWhenArrivedComponent from './NotifyMeWhenArrivedComponent';
import ReduxStatusVisualizedComponent from '../ReduxStatusVisualizedComponent';
import GuaranteeCollapseComponent from './GuaranteeCollapseComponent';
import BagItemHomePage from '../BagItemHomePage';
import SizeGuideComponent from './SizeGuideComponent';
import SizeBLockComponent from '../SizeBLockComponent';
import BagOwnerPanelComponent from './BagOwnerPanelComponent';
import FollowComponent from '../FollowComponent';
import GalleryComponent from './GalleryComponent';
import AvatarComponent from '../AvatarComponent';
import Default from '../RWDComponent/Default';
import Mobile from '../RWDComponent/Mobile';
import BagRentAmountSelectorComponent from './BagRentAmountSelectorComponent';
import DateRangePickerComponent from './DateSelectComponet/DateRangePickerComponent';
import CommentStars from '../CommentStars';
import KnowMoreBuyBagFlowComponent from './KnowMoreBuyBagFlowComponent';
import NegotiationComponent from '../../modules/Negotiation/components/NegotiationComponent';
import EstimateBagComponent from './EstimateBagComponent';
import bagOrClothesPath from '../../utils/bagOrClothesPath';
import { rentAmountCalculate } from '../../utils/rentAmountCalculate';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Colors } from '../../constants/Color';
import { passbaseStatusId } from '../../constants/PassbaseStatus';

const styles = {
  upperContainer: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  mainContainer: {
    marginTop: '55px',
    padding: '0px 0px 20px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  leftContainer: {
    width: '70%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '20px',
  },
  rightContainer: {
    width: '40%',
    height: 'fit-content',
    padding: '30px 40px',
  },
  frontImg: {
    width: '100%',
    objectFit: 'contain',
    height: 600,
  },
  transaction: {
    width: 'fix-content',
    height: 'fix-content',
    padding: 5,
    backgroundColor: '#eeeeee',
  },
  rowImgContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rowImg: {
    width: '100%',
    height: '95%',
    objectFit: 'contain',
    border: '#F1F1F2 1px solid',
  },
  frontMainImgMobile: {
    width: '95vw',
    height: 'auto',
    objectFit: 'contain',
    border: '#F1F1F2 1px solid',
  },
  imgRightBorder: {
    borderRight: '#F1F1F2 1px solid',
  },
  designer: {
    color: 'black',
    textAlign: 'start',
    fontSize: '23px',
    fontFamily: 'Baskerville-Old-Facef',
  },
  bagAlias: {
    color: 'black',
    textAlign: 'start',
    fontSize: 14,
  },
  type: {
    textAlign: 'start',
    backgroundColor: '#E6E7E8',
    color: '#939292',
    width: 'fit-content',
    padding: '3px 10px',
    fontSize: 14,
    borderRadius: 20,
  },
  newLevelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 20,
  },
  newLevel: {
    marginRight: 10,
    fontSize: 12,
  },
  estimateBag: {
    textDecoration: 'underline',
    cursor: 'help',
    border: 'none',
    outline: 'none',
    backgroundColor: '#F8F8F8',
  },
  bagNo: {
    marginTop: 5,
    fontSize: 12,
    textAlign: 'start',
  },
  rentBagAmount: {
    textAlign: 'start',
    fontSize: 12,
    color: '#994207',
    marginTop: 20,
  },
  deposit: {
    textAlign: 'start',
    fontSize: 12,
    marginTop: 8,
    color: 'black',
  },
  originalPrice: {
    textAlign: 'start',
    textDecoration: 'line-through',
    fontSize: 12,
    marginTop: 8,
    color: 'grey',
  },
  divider: {
    marginTop: 30,
    marginBottom: 20,
    height: 1,
    backgroundColor: '#DCDDDD',
    width: '100%',
  },
  avatar: {
    width: 100,
    height: 100,
    marginLeft: '25px',
  },
  avatarMobile: {
    width: 50,
    height: 50,
    marginLeft: '25px',
  },
  commentItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 'fit-content',
    padding: '30px 110px ',
    width: '100%',
    backgroundColor: '#fff',
  },
  commentItemMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 'fit-content',
    padding: 10,
    width: '100%',
    backgroundColor: '#fff',
    textAlign: 'left',
  },
  commentUserContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  commentNameMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  ownerInfo: {
    marginLeft: 2,
    fontSize: 14,
    color: '#070707',
  },
  commentDivider: {
    backgroundColor: '#E6E7E8',
    height: 1,
    marginTop: 25,
    width: '100%',
  },
  commentDividerMobile: {
    backgroundColor: '#E6E7E8',
    height: 1,
    marginTop: 0,
    width: '100%',
  },
  commentInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 'fit-content',
    width: '100%',
    marginLeft: 20,
  },
  commentNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'fit-content',
    width: '100%',
  },
  commentTime: {
    fontSize: 15,
    textAlign: 'start',
  },
  commentName: {
    color: 'black',
    fontSize: 20,
    marginRight: 30,
  },
  commentImgContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 'fit-content',
    flexWrap: 'wrap',
    width: '100%',
  },
  comment: {
    fontSize: 18,
    fontWeight: 300,
    color: 'black',
    margin: '20px 0px',
    textAlign: 'left',
  },
  commentMobile: {
    fontSize: 14,
    fontWeight: 200,
    color: 'black',
    margin: '20px 30px',
  },
  commentImg: {
    width: 220,
    height: 220,
    objectFit: 'contain',
    margin: 5,
  },
  commentTitleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    padding: '0px 110px',
  },
  height100: {
    height: 100,
  },
  commentTitle: {
    fontFamily: 'Baskerville, serif',
    textAlign: 'end',
    fontSize: 58,
    color: 'black',
    height: '100%',
    borderBottom: '#BDBEC0 3px solid',
    fontWeight: 400,
  },
  commentTitleMobile: {
    fontFamily: 'Baskerville, serif',
    textAlign: 'left',
    fontSize: 58,
    color: 'black',
    paddingLeft: '20px',
    height: '100%',
    fontWeight: 400,
  },
  commentSubTitle: {
    padding: '40px 20px',
    fontSize: 18,
    width: '100%',
    height: '100%',
    borderBottom: '#E6E7E8 3px solid',
  },
  commentSubTitleMobile: {
    padding: '10px 20px',
    fontSize: 18,
    width: '100%',
    height: '100%',
    borderBottom: '#E6E7E8 3px solid',
  },
  rightInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iWantRentRentOrBuy: {
    cursor: 'pointer',
    outline: 'none',
    marginTop: 10,
    textAlign: 'center',
    color: 'white',
    padding: '20px 0px',
    width: '70%',
    height: 70,
    fontSize: 16,
    backgroundColor: 'black',
    border: 'none',
    borderRadius: 5,
  },
  fullWidthButton: {
    cursor: 'pointer',
    outline: 'none',
    marginTop: 10,
    textAlign: 'center',
    color: 'white',
    padding: '20px 0px',
    width: '100%',
    height: 70,
    fontSize: 16,
    backgroundColor: 'black',
    border: 'none',
    borderRadius: 5,
  },
  infoContainer: {
    margin: '15px 0px 20px 0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoMobileContainer: {
    margin: '15px 0px 20px 0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 10,
  },
  infoFitContent: {
    width: 'fit-Content',
  },
  infoIcon: {
    marginTop: 5,
    fontSize: 22,
  },
  infoTitle: {
    paddingTop: -10,
    marginLeft: 5,
    textDecoration: 'underline',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: '#F8F8F8',
    outline: 'none',
  },
  operationContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '#F1F1F2 1px solid',
    bottom: -60,
    left: '55%',
  },
  bagDetailButton: {
    cursor: 'pointer',
    margin: '15px 0px',
    width: '80px',
    height: 'auto',
  },
  bagDetailButtonMobile: {
    cursor: 'pointer',
    margin: '15px 0px',
    width: '60px',
    height: 'auto',
  },
  bagDetailIcon: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  descriptionTitle: {
    marginTop: 10,
    color: 'grey',
    fontSize: 16,
    textAlign: 'start',
    fontFamily: 'Microsoft JhengHei Bold',
  },
  description: {
    margin: '10px 0px',
    textAlign: 'start',
    color: 'black',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Microsoft JhengHei semi-bold',
    whiteSpace: 'pre',
  },
  roughInfoContainer: {
    flexWrap: 'wrap',
    marginBottom: 30,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  user: {
    fontFamily: 'Baskerville, serif',
    textDecoration: 'underline',
  },
  transactionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mobileContainer: {
    padding: '0px 0px 20px 0px',
    display: 'block',
    width: '100vw',
  },
  upContainerMobile: {
    width: '100%',
    backgroundColor: 'white',
  },
  frontImgMobile: {
    width: '100%',
    objectFit: 'contain',
    height: 300,
  },
  zoomMobile: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    margin: '6px 0px',
    color: 'black',
  },
  rowImgMobile: {
    width: '25%',
    height: 100,
    objectFit: 'contain',
    borderBottom: '#F1F1F2 1px solid',
  },
  midMobileContainer: {
    width: '100%',
    height: 'fit-content',
    padding: '20px',
  },
  ownerInfoMobile: {
    marginLeft: 15,
    fontSize: 14,
    color: '#070707',
    display: 'flex',
  },
  ownerLinkMobile: {
    marginLeft: 5,
    fontSize: 14,
    color: '#070707',
  },
  userMobile: {
    marginRight: 10,
    fontFamily: 'Baskerville, serif',
    textDecoration: 'underline',
  },
  roughInfoMobileContainer: {
    flexWrap: 'wrap',
    marginBottom: 30,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  commentTitleMobileContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    padding: '0px 20px',
  },
  bagOwnerPanelStyle: {
    padding: 20,
  },
  NotifyMeWhenArrivedStyle: {
    width: '85vw',
    padding: '0 25px',
  },
  followContainer: {
    marginTop: '5px',
  },
  addToCartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 70,
    width: '100%',
    color: 'black',
    backgroundColor: 'white',
    border: '1px black solid',
    margin: '20px 0px',
  },
  cart: {
    width: 15,
    height: 15,
    object: 'contain',
    color: 'black',
    marginRight: '5px',
  },
  commentStar: {
    marginRight: '10px',
  },
  loadingContainer: {
    margin: '0px auto 200px auto',
  },
  commodityNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '5px',
  },
  clothesSizeStandard: {
    margin: '0px 0px 0px 6px',
    fontSize: 16,
  },
  clothesSizeNumber: {
    border: '1px grey solid',
    margin: '0px 2px',
    padding: '0px 4px',
  },
  noteContainer: {
    margin: '10px 0px',
    textAlign: 'left',
  },
  passbaseVerify: {
    cursor: 'pointer',
    outline: 'none',
    marginTop: 10,
    textAlign: 'center',
    color: 'white',
    padding: '10px 0px',
    width: '100%',
    height: 40,
    fontSize: 16,
    backgroundColor: 'blue',
    border: 'none',
  },
  iWantRentRentOrBuyDisable: {
    outline: 'none',
    marginTop: 10,
    textAlign: 'center',
    color: 'white',
    padding: '20px 0px',
    width: '70%',
    height: 70,
    fontSize: 16,
    backgroundColor: 'black',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 5,
    position: 'relative',
  },
  mobileImg: {
    width: '100%',
    height: '100%',
  },
  passbaseVerifiedIconContainer: {
    width: '50px',
    height: '50px',
    position: 'absolute',
    bottom: -10,
    right: -10,
  },
  passbaseVerifiedIcon: {
    width: '100%',
    height: '100%',
  },
  avatarContainer: {
    position: 'relative',
  },
  saveTry: {
    width: '100%',
    marginBottom: '10px',
  },
  slideContainer: {
    width: '88vw',
    height: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'relative',
    marginTop: '40px',
  },
  onlineWardrobeTitle: {
    fontSize: '50px',
    color: 'black',
    margin: '0px 14px',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    backgroundColor: 'white',
    padding: '0px 10px',
  },
  onlineWardrobeMobileTitle: {
    fontSize: '20px',
    color: 'black',
    margin: '0px 14px',
    position: 'absolute',
    left: '50%',
    top: '30px',
    transform: 'translate(-50%, 0%)',
    backgroundColor: 'white',
    padding: '0px 10px',
    width: 'fit-content',
    textAlign: 'center',
    display: 'table',
  },
  onlineWardrobeTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'bottom',
    position: 'relative',
  },
  onlineWardrobeTitleMobileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'bottom',
    position: 'relative',
    marginBottom: '40px',
  },
  onlineWardrobeBottomLine: {
    borderBottom: '1px grey solid',
    width: '98%',
    height: 55,
  },
  onlineWardrobeBottomMobileLine: {
    borderBottom: '1px grey solid',
    width: '98vw',
    height: 55,
  },
  mainPicContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '760px',
    padding: '0px 20px',
  },
  mainMobilePicContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    padding: '0px 20px',
  },
  subPicContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '20%',
  },
  subImg: {
    width: '100%',
    height: '135px',
    objectFit: 'contain',
    marginBottom: '10px',
    border: '#F1F1F2 1px solid',
  },
  rightUpContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  ownerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  followButton: {
    width: '80px',
    borderRadius: 10,
    height: '30px',
  },
  ownerInfoTextContainer: {
    margin: '0px 10px',
    display: 'flex',
    flexDirection: 'column',
  },
  commentContainer: {
    width: '100%',
  },
  picBlockContainer: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '50px',
    position: 'relative',
  },
  dateNoteContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
  },
  dateNoteMobileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
  },
  dateTitle: {
    fontSize: '24px',
    marginTop: 8,
  },
  dataSubTitle: {
    fontSize: '14px',
    color: Colors.introRightTitle,
    margin: '0px 4px',
  },
  transactionBlockContainer: {
    width: '47%',
    height: '180px',
    textAlign: 'center',
    backgroundColor: Colors.buyBlackColor,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  transactionBlockFullContainer: {
    width: '100%',
    height: '180px',
    textAlign: 'center',
    backgroundColor: Colors.buyBlackColor,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  amountText: {
    margin: '20px 0px',
    color: 'black',
    fontWeight: 'bold',
  },
  iWantNegotiation: {
    pointer: 'pointer',
    textDecoration: 'underline',
    color: 'black',
    position: 'absolute',
    bottom: '14px',
  },
  descriptionContainer: {
    textAlign: 'left',
    margin: '30px 0px',
    padding: '20px 0px',
    borderTop: '1px grey solid',
    borderBottom: '1px grey solid',
  },
  sizeGuideButton: {
    cursor: 'pointer',
    textAlign: 'left',
    marginBottom: '10px',
    color: 'black',
    whiteSpace: 'pre',
  },
  sizeGuideTitle: {
    fontSize: '20px',
    color: 'black',
    marginRight: '5px',
    textAlign: 'left',
  },
  sizeSubTitle: {
    color: Colors.introRightTitle,
    fontSize: '14px',
    marginLeft: '4px',
  },
  teachContainer: {
    position: 'relative',
    textAlign: 'left',
    color: Colors.introRightTitle,
    marginBottom: '20px',
  },
  teachTitle: {
    color: 'black',
    fontSize: '16px',
    position: 'absolute',
    top: -30,
  },
  sizeTitle: {
    color: 'black',
    fontSize: '16px',
    textAlign: 'left',
  },
  opMangerIcon: {
    width: '100px',
    objectFit: 'contain',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  slideMobileContainer: {
    width: '75vw',
    height: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'relative',
    marginBottom: '30px',
  },
  onlineWardrobeStyle: {
    width: '50px',
    border: '2px red solid',
  },
  allNewIconContainer: {
    width: '95px',
    height: '28px',
    margin: '0px 5px',
    paddingTop: '0px',
  },
  allNewIcon: {
    width: '100%',
    heigh: '100%',
    objectFit: 'contain',
  },
  avatarStyle: {
    marginLeft: 0,
  },
  bagNoText: {
    color: 'black',
    textAlign: 'start',
    fontSize: 14,
    margin: '10px 0px',
  },
  invisibleBagItem: {
    height: 1,
    opacity: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  priceBlockContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  attachmentIcon: {
    fontSize: 18,
    color: '#DB5535',
    marginRight: 5,
    margin: '8px 10px',
  },
  attachmentItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'black',
    width: '120px',
  },
  attachmentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  passbaseButtonContainer: {
    top: '10px',
    position: 'absolute',
    opacity: 0,
  },
  sizeText: {
    textAlign: 'left',
    color: 'black',
  },
  soldOutContainer: {
    backgroundColor: '#D0D0D0',
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    color: 'black',
    letterSpacing: 5,
    marginTop: 20,
  },
  commentResourceListContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 10,
    height: 110,
    width: 500,
    marginBottom: 10,
  },
  commentResourceListContainerMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 5,
    height: 'fit-content',
    width: 'fit-content',
    flexWrap: 'wrap',
    marginLeft: 30,
    marginBottom: 10,
  },
  commentImage: {
    width: 120,
    height: 120,
    marginRight: 15,
    border: '1px solid #bbbbbb',
    objectFit: 'contain',
  },
  commentImageMobile: {
    width: 60,
    height: 60,
    marginRight: 5,
    border: '1px solid #bbbbbb',
    objectFit: 'contain',
  },
  slideContainerBagItem: {
    width: '40vw',
    left: '20%',
    transform: 'translateX(-20%)',
    position: 'relative',
    height: '760px',
    padding: '0px 20px',
  },
};

const BagDetailPage = ({
  reduxStatus,
  reduxError,
  cartReduxStatus,
  cartReduxError,
  data,
  memberId,
  bagId,
  isLogin,
  isInCart,
  handleNotifyMeWhenArrived,
  addToCart,
  removeFromCart,
  commentList,
  getReleaseDate,
  bagReleaseDate,
  rentDateList,
  rentAmountPerDay,
  onSaveOrderCreateInfo,
  isVerify,
  onlineWardrobe,
  memberProfile,
}) => {
  const [isShowEstimateBagModal, setIsShowEstimateBagModal] = useState(false);
  const [isShowNotifyModal, setIsShowNotifyModal] = useState(false);
  const [isShowNegotiationComponent, setIsShowNegotiationComponent] = useState(false);
  const [isShowGalleryModal, setIsShowGalleryModal] = useState(false);
  const [isShowKnowMoreBuyBagFlowModal, setIsShowKnowMoreBuyBagFlowModalModal] = useState(false);
  const [mobilePicDetailIndex, setMobilePicDetailIndex] = useState(0);
  const washAmount = R.pathOr(0, ['washAmount'], data);
  const [previewDays, setPreviewDays] = useState(4);
  const [dummy, setDummy] = useState(0);
  const [previewRentAmount, setPreviewRentAmount] = useState(rentAmountCalculate(rentAmountPerDay, 8, false, washAmount));
  // this is for the real rentDays
  const [isDisableDaysSelector, setIsDisableDaysSelector] = useState(false);
  const [rentDays, setRentDays] = useState(0);
  const [rentAmount, setRentAmount] = useState(rentAmountCalculate(rentAmountPerDay, 4, false, washAmount));
  const isBagOwner = R.pathOr('', ['owner', 'id'], data) === memberId;
  const isOhPhireManage = R.pathOr(false, ['isOhPhireManage'], data);
  const ownerChangedAvatar = R.pathOr(null, ['owner', 'avatar', 'uri'], data);
  const commodityType = R.pathOr(1, ['commodityTypeId'], data);
  const isVerified = R.pathOr(false, ['owner', 'isVerified'], data);
  const isBusinessPartner = R.pathOr(false, ['owner', 'isBusinessPartner'], data);
  const isOverdueReturn = R.pathOr(false, ['isOverdueReturn'], data);
  const height = R.pathOr('', ['height'], data);
  const widthSize = R.pathOr('', ['width'], data);
  const depth = R.pathOr('', ['depth'], data);
  const [width] = useWindowSize({ fps: 60 });
  const newLevel = R.pathOr(0, ['newLevel'], data);
  const ownerName = R.pathOr(false, ['owner', 'name'], data);
  const bagStatusId = R.pathOr(0, ['bagStatusId'], data);
  const passbaseStatus = R.pathOr(0, ['passbaseStatus'], memberProfile);

  useEffect(() => {
    setIsDisableDaysSelector(false);
    setRentAmount(rentAmountCalculate(rentAmountPerDay, 4, false, washAmount));
    setPreviewDays(4);
    setPreviewRentAmount(rentAmountCalculate(rentAmountPerDay, 4, false, washAmount));
  }, [rentAmountPerDay]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const detailImgData = [
    R.pathOr(null, ['frontPic', 'uri'], data),
    R.pathOr(null, ['backPic', 'uri'], data),
    R.pathOr(null, ['zipperPic', 'uri'], data),
    R.pathOr(null, ['sidePic', 'uri'], data),
    R.pathOr(null, ['bottomPic', 'uri'], data),
  ];

  const getName = tagCategoryId => R.pipe(
    R.pathOr([], ['tags']),
    R.filter(tag => tag.tagCategoryId === tagCategoryId),
    R.pathOr('', ['0', 'name']),
  )(data);

  const renderNewLevel = () => {
    if (newLevel > 9) {
      return '全新';
    }
    return newLevel ? `${newLevel}成新` : null;
  };

  const calculateBagNumber = () => {
    if (width < 400) {
      return 2;
    }
    if (width < 450) {
      return 2;
    }
    if (width < 800) {
      return 2;
    }
    if (width < 1000) {
      return 3;
    }
    if (width > 1001 && width < 1300) {
      return 4;
    }
    if (width > 1301 && width < 1500) {
      return 5;
    }
    if (width > 1501 && width < 1700) {
      return 5;
    }

    return 6;
  };

  useEffect(() => {
    if (onlineWardrobe.length < calculateBagNumber() + 1) {
      setDummy(calculateBagNumber() - onlineWardrobe.length);
    } else {
      setDummy(0);
    }
  }, [calculateBagNumber]);

  const renderComment = (comment) => {
    const { commentByUser } = comment;
    const resources = R.pathOr([], ['resources'], comment);
    const stars = R.pathOr(0, ['stars'], comment);
    const changedAvatarIcon = R.pathOr(null, ['uri'], commentByUser);
    const userName = R.pathOr('', ['communityName'], commentByUser) || R.pathOr('', ['name'], commentByUser);
    const renderHinderUserName = () => {
      const firstWord = userName[0];
      const lastWord = userName[userName.length - 1];

      return `${firstWord}****${lastWord}`;
    };

    return (
      <React.Fragment key={comment.id}>
        <Default>
          <div key={comment.id} style={styles.commentItemContainer}>
            <div style={styles.avatar}>
              <Avatar
                size={110}
                icon="user"
                src={changedAvatarIcon || Assets.AvatarIcon}
              />
            </div>
            <div style={styles.commentInfoContainer}>
              <div style={styles.commentNameContainer}>
                <div style={styles.commentName}>
                  {renderHinderUserName()}
                </div>
                <div style={styles.commentStar}>
                  <CommentStars stars={stars} />
                </div>
              </div>
              <div style={styles.comment}>
                {comment.content}
              </div>
              {resources.length > 0 && (
                <div style={styles.commentResourceListContainer}>
                  {resources.map(resource => (
                    <img
                      key={resource.id}
                      style={styles.commentImage}
                      src={resource.uri}
                      alt=""
                    />
                  ))}
                </div>
              )}
              <div style={styles.commentDivider} />
            </div>
          </div>
        </Default>
        <Mobile>
          <div key={comment.id} style={styles.commentItemMobileContainer}>
            <div style={styles.commentUserContainer}>
              <div style={styles.avatarMobile}>
                <Avatar
                  size={50}
                  icon="user"
                  src={changedAvatarIcon || Assets.AvatarIcon}
                />
              </div>
              <div style={styles.commentInfoContainer}>
                <div style={styles.commentNameMobileContainer}>
                  <div style={styles.commentName}>
                    {renderHinderUserName()}
                  </div>
                  <div style={styles.commentStar}>
                    <CommentStars stars={stars} />
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.commentMobile}>
              {comment.content}
            </div>
            {resources.length > 0 && (
              <div style={styles.commentResourceListContainerMobile}>
                {resources.map(resource => (
                  <img
                    key={resource.id}
                    style={styles.commentImageMobile}
                    src={resource.uri}
                    alt=""
                  />
                ))}
              </div>
            )}
            <div style={styles.commentDividerMobile} />
          </div>
        </Mobile>
      </React.Fragment>
    );
  };

  const renderAddToCartButton = () => {
    if (!isLogin) {
      return <Link key="cartMember" to={`${Paths.MEMBER}`} style={styles.transaction}>加入願望清單前請先登入</Link>;
    }
    if (isBagOwner) {
      return null;
    }
    if (isInCart) {
      return (
        <button
          style={styles.addToCartContainer}
          key="deleteCart"
          type="button"
          onClick={removeFromCart}
        >
          <img
            src={Assets.hearFull}
            style={styles.cart}
            alt=""
          />
          從願望清單中移除
        </button>
      );
    }
    return (
      <button
        key="addToCart"
        style={styles.addToCartContainer}
        type="button"
        onClick={() => {
          ReactPixel.track('AddToCart', {
            content_ids: `${bagId}`,
            content_type: 'product',
            contents: [{
              id: bagId,
              quantity: '1',
              commodityType: commodityType === 1 || commodityType === '1' ? 'bags' : 'clothes',
              type: R.path(['type', 'name'], data),
              name: R.path(['name'], data),
            }],
            product_catalog_id: '880132272464897',
            currency: 'TWD',
            value: rentAmount,
          });
          addToCart();
        }}
      >
        <img
          src={Assets.heartNotFull}
          style={styles.cart}
          alt=""
        />
        加入願望清單
      </button>
    );
  };

  const renderRentButton = (type) => {
    const onSendRendRequest = (event) => {
      if (isVerify) return window.gtag_report_conversion2();
      return event.preventDefault();
    };

    if (type === 'buy') {
      return null;
    }


    const renderButtonPassbaseState = () => {
      if (isVerify) {
        return (
          <Link
            style={styles.iWantRentRentOrBuy}
            key="transaction"
            to={`${bagOrClothesPath(commodityType)}/${bagId}${Paths.ORDER_CREATE}?type=rent`}
            onClick={(event) => {
              onSendRendRequest(event);
            }}
          >
            {`${isOhPhireManage ? '租借' : '送出租借請求'}`}
          </Link>
        );
      }

      if (passbaseStatus === passbaseStatusId.processing) {
        return (
          <div
            style={styles.iWantRentRentOrBuyDisable}
            key="transaction"
            role="button"
            tabIndex={0}
          >
            系統驗證中
          </div>
        );
      }
      return (
        <div
          style={styles.iWantRentRentOrBuyDisable}
          key="transaction"
          role="button"
          tabIndex={0}
        >
          <Link
            to={Paths.MEMBER}
          >
            前往實名認證
          </Link>
        </div>
      );
    };

    return (
      <div style={type === 'rentBuy' ? styles.transactionBlockContainer : styles.transactionBlockFullContainer}>
        {!isDisableDaysSelector
          ? (
            <div style={styles.iWantRentRentOrBuy}>請選擇租借日期</div>
          )
          : (
            <React.Fragment>
              {renderButtonPassbaseState()}
            </React.Fragment>
          )
      }
        {
          rentDays > 0 ? (
            <div style={styles.amountText}>
              {`$${amountPattern(rentAmount)}租/${rentDays}天`}
            </div>
          ) : (
            <div style={styles.amountText}>
              {`$${amountPattern(rentAmountCalculate(rentAmountPerDay, previewDays, false, washAmount))}租 /${previewDays}天`}
            </div>
          )
        }

      </div>
    );
  };

  const renderTransactionIntentLink = () => {
    if (!isLogin) {
      return (
        <Link
          style={styles.fullWidthButton}
          key="transactionMember"
          to={`${Paths.MEMBER}`}
        >
          請先登入
        </Link>
      );
    }
    if (isBagOwner) {
      return (
        <Link
          style={styles.fullWidthButton}
          key="goToMemberPage"
          to={Paths.ONLINE_PRODUCTS}
        >
          編輯商品
        </Link>
      );
    }
    if (R.pathOr(null, ['bagStatusId'], data) === BagStatus.bagStatusId.inStock
      || R.pathOr(null, ['bagStatusId'], data) === BagStatus.bagStatusId.rented
    ) {
      return (
        <div style={styles.transactionContainer}>
          {R.path(['negotiatorId'], data) === null
          || R.path(['negotiatorId'], data) === memberId
            ? (
              renderRentButton(R.path(['type', 'name'], data))
            ) : (
              <button
                style={styles.iWantRentRentOrBuy}
                type="button"
              >
                此商品正在議價中無法出租
              </button>
            )}

          {
            ((R.path(['type', 'name'], data) === 'buy') || (R.path(['type', 'name'], data) === 'rentBuy'))
            && (
            <div
              style={R.path(['type', 'name'], data) === 'rentBuy'
                ? styles.transactionBlockContainer : styles.transactionBlockFullContainer}
            >
              <Link
                style={styles.iWantRentRentOrBuy}
                key="transaction"
                onClick={() => { onSaveOrderCreateInfo({ fromDate: null, endDate: null }); }}
                to={`${bagOrClothesPath(commodityType)}/${bagId}${Paths.ORDER_CREATE}?type=buy`}
              >
                購買
              </Link>
              <div style={styles.amountText}>
                {`$${amountPattern(R.pathOr(0, ['buyoutAmount'], data))}`}
              </div>
              <div
                style={styles.iWantNegotiation}
                role="button"
                tabIndex={0}
                onClick={() => {
                  setIsShowNegotiationComponent(true);
                }}
                onKeyPress={() => {
                  setIsShowNegotiationComponent(true);
                }}
              >
                我要議價
              </div>
            </div>
            )
          }
        </div>
      );
    }
    return null;
  };

  const renderBuyAmount = R.pathOr('', ['type', 'name'], data) !== 'rent'
    ? <div style={styles.deposit}>{`買斷價格 NT$${amountPattern(R.pathOr(0, ['buyoutAmount'], data))}`}</div>
    : null;

  const renderRentAmount = R.pathOr('', ['type', 'name'], data) !== 'buy'
    ? (
      <React.Fragment>
        <div style={styles.rentBagAmount}>
          { `租金 NT$${amountPattern(previewRentAmount)} / ${previewDays}天`}
        </div>
      </React.Fragment>
    ) : null;

  const renderDescription = (
    <div style={styles.descriptionContainer}>
      <h1 style={styles.sizeGuideTitle}>
        Description
        <span style={styles.sizeSubTitle}>商品敘述</span>
      </h1>
      <div
        style={styles.sizeGuideButton}
      >
        {R.pathOr('', ['description'], data)}
      </div>
    </div>
  );

  const transactionType = () => {
    const type = R.pathOr('', ['type', 'name'], data);
    if (type === 'rent') {
      return '租借';
    }
    if (type === 'buy') {
      return '購買';
    }
    return '租借-購買';
  };

  const getDesignerName = () => {
    const renderTagId = commodityType === Type.commodityTypeId.clothes ? 4 : 1;
    return (
      R.pipe(
        R.pathOr([], ['tags']),
        R.filter(tag => tag.tagCategoryId === renderTagId),
        R.pathOr('', ['0', 'name']),
      )(data)
    );
  };


  const renderBagReleaseDate = () => {
    // buyout inStock did not show the popup
    if (R.pathOr(null, ['bagStatusId'], data) === BagStatus.bagStatusId.sold
      || R.pathOr(null, ['bagStatusId'], data) === BagStatus.bagStatusId.inStock
      || R.pathOr(null, ['bagStatusId'], data) === BagStatus.bagStatusId.terminate) {
      return null;
    }

    return (
      <div style={styles.infoFitContent}>
        <Icon style={styles.infoIcon} type="bell" />
        <button
          type="button"
          style={styles.infoTitle}
          onClick={() => {
            getReleaseDate();
            setIsShowNotifyModal(true);
          }
          }
        >
          預計到貨日期
        </button>
      </div>
    );
  };
  const renderPictureBlock = () => {
    const mobileImgBlock = (path, index) => (
      <div
        key={path}
        style={{ ...styles.rowImgMobile, ...styles.imgRightBorder }}
        role="button"
        tabIndex={0}
        onClick={() => {
          setIsShowGalleryModal(true);
          setMobilePicDetailIndex(index);
        }}
        onKeyPress={() => {
          setIsShowGalleryModal(true);
          setMobilePicDetailIndex(index);
        }}
      >

        <img
          src={path}
          style={styles.mobileImg}
          alt=""
        />
      </div>
    );

    const imgBlock = (path, index) => (
      <img
        key={path + index}
        src={path}
        style={styles.subImg}
        alt=""
        onClick={() => {
          setMobilePicDetailIndex(index);
        }}
        onKeyPress={() => {
          setMobilePicDetailIndex(index);
        }}
        role="presentation"
      />
    );

    return (
      <React.Fragment>
        <Default>
          <div style={styles.subPicContainer}>
            {detailImgData.map((path, index) => imgBlock(path, index))}
          </div>
          <div style={styles.slideContainerBagItem}>
            <Slider
              arrows
              slidesToShow={1}
              slidesToScroll={1}
            >
              {detailImgData.map((path, index) => mobileImgBlock(path, index))}
            </Slider>
          </div>
          <div style={styles.operationContainer}>
            <div
              style={styles.bagDetailButton}
              role="button"
              tabIndex={0}
              onClick={() => {
                setIsShowGalleryModal(true);
              }}
              onKeyPress={() => {
                setIsShowGalleryModal(true);
              }}
            >
              <img
                src={Assets.bagDetailButtonIcon}
                style={styles.bagDetailIcon}
                alt=""
              />
            </div>
            <GalleryComponent
              data={detailImgData}
              isVisible={isShowGalleryModal}
              onModalClose={() => setIsShowGalleryModal(false)}
              mobilePicDetailIndex={mobilePicDetailIndex}
            />
          </div>
        </Default>
        <Mobile>
          <div style={styles.slideMobileContainer}>
            <Slider
              arrows
              dots
              speed={700}
              autoplay
              slidesToShow={1}
              slidesToScroll={1}
            >
              {detailImgData.map((path, index) => mobileImgBlock(path, index))}
            </Slider>
          </div>
          {/* <div style={styles.rowImgContainer}>
            {detailImgData.map((path, index) => {
              if (index === 0) return null;
              return mobileImgBlock(path, index);
            })}
          </div> */}
        </Mobile>
      </React.Fragment>
    );
  };

  const note = (
    <div style={styles.dateNoteContainer}>
      <Default>
        <h1 style={styles.dateTitle}>
          Date
        </h1>
        <div style={styles.dataSubTitle}>
          租借日期
        </div>
      </Default>
      <div>
        *提醒您:
        <br />
        包裹實際送達時段須以物流當日的配送速度為準
        <br />
        建議將租借日期提前一天，以確保當日準時送達
      </div>
    </div>
  );

  const renderWardrobeDummy = () => {
    const dummys = Array.from(Array(dummy).keys());

    return dummys.map(item => (
      <BagItemHomePage
        {...item}
        styles={styles.invisibleBagItem}
      />
    ));
  };

  const renderInfoBlock = () => (
    <React.Fragment>
      <div style={styles.rightUpContainer}>
        <div>
          <div style={styles.designer}>{getDesignerName()}</div>
          <div style={styles.commodityNameContainer}>
            <div style={styles.bagAlias}>
              {R.pathOr('', ['name'], data)}
            </div>
            {commodityType !== Type.commodityTypeId.clothes && (getName(6) === '' || getName(6) === '包包') ? null
              : (
                <React.Fragment>
                  <SizeBLockComponent
                    sizeStandard={R.pathOr('', ['sizeStandard'], data)}
                    sizeNumber={R.pathOr('', ['sizeNumber'], data)}
                  />
                </React.Fragment>
              )}
          </div>
        </div>

        <div style={styles.ownerContainer}>
          <AvatarComponent
            ownerChangedAvatar={ownerChangedAvatar}
            isVerified={isVerified}
            size={60}
            avatarStyle={styles.avatarStyle}
          />
          <div style={styles.ownerInfoTextContainer}>
            <div style={styles.ownerInfo}>Listed By</div>
            <Link style={styles.ownerInfo} to={`${Paths.MEMBER}/${R.pathOr('', ['owner', 'communityName'], data)}`}>
              <div style={styles.user}>{R.pathOr('', ['owner', 'communityName'], data)}</div>
            </Link>
          </div>
          <div style={styles.followContainer}>
            <FollowComponent
              targetId={R.pathOr('', ['owner', 'id'], data)}
              style={styles.followButton}
            />
          </div>
        </div>
      </div>
      <hr />
      <div style={styles.iconContainer}>
        {newLevel === 10 ? (
          <div style={styles.allNewIconContainer}>
            <img
              alt=""
              style={styles.allNewIcon}
              src={Assets.allNew}
            />
          </div>
        )
          : null}
        {
          isOhPhireManage ? (
            <img
              src={Assets.opMangerIcon}
              style={styles.opMangerIcon}
              alt=""
            />
          ) : null
          }
        <div style={styles.type}>
          {transactionType()}
        </div>

      </div>

      {commodityType === Type.commodityTypeId.clothes ? null : (
        <div style={styles.newLevelContainer}>
          <div style={styles.newLevel}>
            {renderNewLevel()}
          </div>
          <Icon type="question-circle" theme="filled" />
          <button
            style={styles.estimateBag}
            type="button"
            onClick={() => setIsShowEstimateBagModal(true)}
          >
            商品評估準則
          </button>
        </div>
      )}
      <div style={styles.priceBlockContainer}>
        <div style={styles.attachmentsContainer}>
          {
             R.pathOr(false, ['isHasDustCover'], data) ? (
               <div style={styles.attachmentItem}>
                 <Icon
                   style={styles.attachmentIcon}
                   type="check-circle"
                   theme="filled"
                 />
                 <div>
                   防塵袋
                 </div>
               </div>
             ) : null
          }
          {
             R.pathOr(false, ['isHasOriginBox'], data) ? (
               <div style={styles.attachmentItem}>
                 <Icon
                   style={styles.attachmentIcon}
                   type="check-circle"
                   theme="filled"
                 />
                 <div>
                   原廠盒
                 </div>
               </div>
             ) : null
          }
          {
             R.pathOr(false, ['isHasWarrantyCard'], data) ? (
               <div style={styles.attachmentItem}>
                 <Icon
                   style={styles.attachmentIcon}
                   type="check-circle"
                   theme="filled"
                 />
                 <div>
                   原廠保卡
                 </div>
               </div>
             ) : null
          }
          {
             R.pathOr(false, ['isHasProve'], data) ? (
               <div style={styles.attachmentItem}>
                 <Icon
                   style={styles.attachmentIcon}
                   type="check-circle"
                   theme="filled"
                 />
                 <div>
                   購買證明
                 </div>
               </div>
             ) : null
          }
        </div>

      </div>

      {
        bagStatusId === BagStatus.bagStatusId.sold ? (
          <div style={styles.soldOutContainer}>
            商品已售出
          </div>
        ) : (
          <React.Fragment>
            {renderRentAmount}
            {
              R.pathOr('', ['type', 'name'], data) !== 'buy' ? (
                <div style={styles.deposit}>{`押金 NT$${amountPattern(R.pathOr(0, ['deposit'], data))}`}</div>
              ) : null
            }
            {renderBuyAmount}
            {
              R.pathOr(0, ['originalPrice'], data) ? (
                <div style={styles.originalPrice}>{`原價 NT$${amountPattern(R.pathOr(0, ['originalPrice'], data))}`}</div>
              ) : null
            }
          </React.Fragment>
        )
      }
      {renderDescription}
      {R.pathOr('', ['type', 'name'], data) !== 'buy'
      && (
        <React.Fragment>
          <BagRentAmountSelectorComponent
            isDisable={isDisableDaysSelector}
            rentAmountPerDay={rentAmountPerDay}
            washAmount={washAmount}
            onSelectDays={(days, finalAmount) => {
              setPreviewDays(days);
              setPreviewRentAmount(finalAmount);
            }}
          />
          <Default>
            {note}
          </Default>
          <Mobile>
            <div style={styles.dateNoteMobileContainer}>
              <h1 style={styles.dateTitle}>
                Date
              </h1>
              <div style={styles.dataSubTitle}>
                租借日期
              </div>
            </div>
          </Mobile>
          <DateRangePickerComponent
            isDisable={R.pathOr('', ['type', 'name'], data) === 'rentBuy'
            && R.pathOr(null, ['bagStatusId'], data) === BagStatus.bagStatusId.sold}
            previewDays={previewDays}
            rentDateList={rentDateList}
            onSelectedDateRange={(fromDate, endDate) => {
              if (!R.isEmpty(fromDate) && !R.isEmpty(endDate)) {
                setIsDisableDaysSelector(true);
                const days = moment(endDate).diff(fromDate, 'days') + 1;
                setRentDays(days);
                setRentAmount(rentAmountCalculate(rentAmountPerDay, days, false, washAmount));
                onSaveOrderCreateInfo({ fromDate, endDate });
              } else {
                setIsDisableDaysSelector(false);
                setRentDays(4);
                setRentAmount(rentAmountCalculate(rentAmountPerDay, 4, false, washAmount));
              }
            }}
            isOhPhireManage={isOhPhireManage}
            isBusinessPartner={isBusinessPartner}
            commodityType={commodityType}
            isOverdueReturn={isOverdueReturn}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );

  const renderRenderTeach = (
    <div style={styles.teachContainer}>
      <div style={styles.teachTitle}>
        租借教學
      </div>
      <div style={styles.divider} />
      <div>1. 送出請求 - 找到喜歡的商品，送出租借請求</div>
      <div>2. 商品寄出 - 租借商品會於日期首日抵達</div>
      <div>3. 歸還商品 - 於租期截止日寄回單品輸入追蹤單號</div>
    </div>
  );

  const renderCommentBlock = () => (
    <React.Fragment>
      <Default>
        <div style={{ ...styles.commentTitleContainer, ...styles.height100 }}>
          <div style={styles.commentTitle}>Comments</div>
          <div style={styles.commentSubTitle}>商品相關評論</div>
        </div>
      </Default>
      <Mobile>
        <div style={styles.commentTitleMobile}>Comments</div>
        <div style={styles.commentSubTitleMobile}>商品相關評論</div>
      </Mobile>
      <div>
        {
          commentList && commentList.length > 0
            ? commentList.map(comment => renderComment(comment))
            : null
        }
      </div>
    </React.Fragment>
  );

  return ((R.isEmpty(data) || R.isNil(data)) ? (
    <div style={styles.loadingContainer}>
      <Spin tip="商品載入中，請稍候..." spinning />
    </div>
  )
    : (
      <ReduxStatusVisualizedComponent
        reduxStatus={[reduxStatus, cartReduxStatus]}
        showModalOnError
        modalFailureContent={reduxError || cartReduxError}
      >

        {/* GTag implant */}
        <Helmet>
          <script>
            {'gtag(\'event\', \'conversion\', {\'send_to\': \'AW-627439533/YJlKCK6vo9IBEK3vl6sC\'})'}
          </script>
          <script>
            {`
              function gtag_report_conversion2(url) {
                var callback = function () {
                  if (typeof(url) != 'undefined') {
                    window.location = url;
                  }
                };
                gtag('event', 'conversion', {
                    'send_to': 'AW-627439533/Z2msCIKwo9IBEK3vl6sC',
                    'transaction_id': '',
                    'event_callback': callback
                });
                return false;
              }
          `}
          </script>
        </Helmet>

        <Helmet>
          <meta property="og:title" content={R.pathOr('', ['name'], data)} />
          <meta property="og:description" content={R.pathOr(R.pathOr('', ['name'], data), ['description'], data)} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={R.pathOr(null, ['frontPic', 'uri'], data)} />
          <meta property="product:brand" content={getDesignerName()} />
          <meta property="product:availability" content={bagStatusId === BagStatus.bagStatusId.sold ? 'out of stock' : 'in stock'} />
          <meta property="product:condition" content={newLevel === 10 ? 'new' : 'used'} />
          <meta property="product:price:amount" content={rentAmount} />
          <meta property="product:price:currency" content="TWD" />
          <meta property="product:retailer_item_id" content={R.pathOr('', ['id'], data)} />
          <meta property="product:item_group_id" content={commodityType === 1 || commodityType === '1' ? 'bags' : 'clothes'} />
        </Helmet>
        <EstimateBagComponent
          isVisible={isShowEstimateBagModal}
          onModalClose={() => setIsShowEstimateBagModal(false)}
        />
        <KnowMoreBuyBagFlowComponent
          isVisible={isShowKnowMoreBuyBagFlowModal}
          onModalClose={() => setIsShowKnowMoreBuyBagFlowModalModal(false)}
        />

        <Default>
          <NotifyMeWhenArrivedComponent
            isVisible={isShowNotifyModal}
            handleNotifyMeWhenArrived={handleNotifyMeWhenArrived}
            onModalClose={() => setIsShowNotifyModal(false)}
            bagName={R.pathOr('', ['name'], data)}
            bagReleaseDate={bagReleaseDate}
          />
        </Default>
        <Mobile>
          <NotifyMeWhenArrivedComponent
            isVisible={isShowNotifyModal}
            handleNotifyMeWhenArrived={handleNotifyMeWhenArrived}
            onModalClose={() => setIsShowNotifyModal(false)}
            bagName={R.pathOr('', ['name'], data)}
            bagReleaseDate={bagReleaseDate}
            mainStyle={styles.NotifyMeWhenArrivedStyle}
          />
        </Mobile>

        <NegotiationComponent
          onModalClose={() => setIsShowNegotiationComponent(false)}
          isVisible={isShowNegotiationComponent}
          bagId={bagId}
          isNegotiationWithAdmin={false}
          bagOwnerId={R.pathOr(0, ['ownerId'], data)}
        />

        <div style={styles.mainContainer}>
          <div style={styles.upperContainer}>
            <Default>
              <div style={styles.leftContainer}>
                <div style={styles.picBlockContainer}>
                  {renderPictureBlock()}
                </div>
                <div style={styles.commentContainer}>
                  {renderCommentBlock()}
                </div>
              </div>

              <div style={styles.rightContainer}>
                {renderInfoBlock()}
                <div style={styles.infoContainer}>
                  {renderBagReleaseDate()}
                </div>
                <div style={styles.rightInfoContainer}>
                  {renderTransactionIntentLink()}
                  {renderAddToCartButton()}
                  {
                    isBusinessPartner ? (
                      <img
                        src={Assets.cloudsetNote}
                        style={styles.saveTry}
                        alt=""
                      />
                    ) : null
                  }
                  { isOhPhireManage && commodityType === Type.commodityTypeId.clothes ? (
                    <img
                      alt=""
                      src={Assets.saveTry}
                      style={styles.saveTry}
                    />
                  ) : null}
                </div>
                {renderRenderTeach}
                <div style={styles.divider} />
                <div style={styles.sizeTitle}>
                  商品尺寸
                </div>
                <div style={styles.sizeText}>
                  {
                    (height || widthSize || depth) ? (`長:${height} 寬:${widthSize} 深:${depth}`) : null
                  }
                </div>
                <div style={styles.divider} />
                <SizeGuideComponent />
                <GuaranteeCollapseComponent />
                <div style={styles.bagNoText}>
                  Product Number #
                  {R.pathOr('', ['bagNo'], data)}
                </div>
              </div>
            </Default>
            <Mobile>
              <div style={styles.mobileContainer}>
                <div style={styles.upContainerMobile}>
                  {renderPictureBlock()}
                </div>
                <div style={styles.midMobileContainer}>
                  {renderInfoBlock()}
                  <div style={styles.noteContainer}>
                    {note}
                  </div>
                  {renderAddToCartButton()}
                  <div style={styles.rightInfoContainer}>
                    {renderTransactionIntentLink()}
                    <div style={styles.infoMobileContainer}>
                      {renderBagReleaseDate()}
                    </div>
                  </div>
                  {renderCommentBlock()}
                  <div style={styles.divider} />
                  {renderRenderTeach}
                  <SizeGuideComponent />
                  <GuaranteeCollapseComponent />
                  <div style={styles.bagNoText}>
                    Product Number #
                    {R.pathOr('', ['bagNo'], data)}
                  </div>
                </div>
                <div style={styles.commentTitleMobileContainer}>
                  <BagOwnerPanelComponent
                    data={data}
                    isVerified={isVerified}
                    mainStyle={styles.bagOwnerPanelStyle}
                  />
                </div>
              </div>
            </Mobile>
          </div>
        </div>
        <Default>
          <div style={styles.slideContainer}>
            <div style={styles.onlineWardrobeTitleContainer}>
              <div style={styles.onlineWardrobeBottomLine} />
              <h1 style={styles.onlineWardrobeTitle}>
                {`${ownerName}'s 線上衣櫥`}
              </h1>
            </div>
            <Slider
              arrows
              autoplay
              speed={800}
              slidesToShow={calculateBagNumber()}
              slidesToScroll={1}
            >
              {(onlineWardrobe || []).map(item => (
                <BagItemHomePage
                  {...item}
                />
              ))}
              {renderWardrobeDummy()}
            </Slider>
          </div>
        </Default>
        <Mobile>
          <div style={styles.onlineWardrobeTitleMobileContainer}>
            <div style={styles.onlineWardrobeBottomMobileLine} />
            <h1 style={styles.onlineWardrobeMobileTitle}>
              {`${ownerName}'s 線上衣櫥`}
            </h1>
          </div>
          <div style={styles.slideMobileContainer}>
            <Slider
              arrows
              autoplay
              speed={800}
              slidesToShow={calculateBagNumber()}
              slidesToScroll={1}
            >
              {(onlineWardrobe || []).map(item => (
                <BagItemHomePage
                  {...item}
                />
              ))}
              {renderWardrobeDummy()}
            </Slider>
          </div>
        </Mobile>
      </ReduxStatusVisualizedComponent>
    )
  );
};

BagDetailPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  cartReduxError: PropTypes.string,
  cartReduxStatus: PropTypes.string,
  addToCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  handleNotifyMeWhenArrived: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
  memberId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  bagId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isLogin: PropTypes.bool,
  isInCart: PropTypes.bool,
  commentList: PropTypes.array,
  rentDateList: PropTypes.array,
  getReleaseDate: PropTypes.func.isRequired,
  bagReleaseDate: PropTypes.string,
  rentAmountPerDay: PropTypes.number,
  onSaveOrderCreateInfo: PropTypes.func.isRequired,
  onPassbaseAuthentication: PropTypes.func.isRequired,
  onlineWardrobe: PropTypes.array,
  isVerify: PropTypes.bool,
  memberEmail: PropTypes.string,
  memberProfile: PropTypes.object.isRequired,
};

BagDetailPage.defaultProps = {
  reduxError: null,
  cartReduxError: null,
  cartReduxStatus: null,
  data: null,
  isLogin: false,
  isInCart: false,
  commentList: [],
  memberId: 0,
  bagId: 0,
  bagReleaseDate: '',
  rentAmountPerDay: 0,
  rentDateList: [],
  onlineWardrobe: [],
  isVerify: false,
  memberEmail: '',
};

export default BagDetailPage;
