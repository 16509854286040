// shipping one times amount
export const shippingCostPerTime = 0;

export const shippingCost = {
  postShipping: 0,
  blackCat: 100,
};

export default {
  shippingCostPerTime,
  shippingCost,
};
