import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import ReduxStatus from '../../../../constants/ReduxStatus';

const styles = {
  mainContainer: {
    width: 'fit-content',
    margin: '100px',
  },
  fieldTitle: {
    margin: 10,
  },
  input: {
    margin: 10,
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid grey',
  },
  button: {
    margin: 10,
    outline: 'none',
    cursor: 'pointer',
    padding: '0px 10px',
    borderRadius: 3,
    backgroundColor: 'white',
    border: '1px solid grey',
  },
};

const BackToLoadingPage = styled.div`
  cursor: pointer;
  &:hover{
    opacity: 0.5;
    text-decoration:underline;
  }
`;

const ForgotPasswordPage = ({
  reduxStatus,
  reduxError,
  handleForgotPassword,
  setIsShowForgotPasswordPage,
}) => {
  const [emailInput, setEmailInput] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    handleForgotPassword({
      email: emailInput,
    });
  };

  return (
    <div style={styles.mainContainer} key="baseContainer">
      {
        reduxStatus === ReduxStatus.STATUS_NORMAL ? (
          <h1>
            暫時登入密碼已經送至您的信箱，請至信箱查看
            <span role="img" aria-labelledby="">&#128522;</span>
          </h1>
        ) : (
          <ReduxStatusVisualizedComponent
            showModalOnError
            reduxStatus={reduxStatus}
            modalFailureContent={reduxError}
          >
            <h1>輸入您的E-mail來拯救您的密碼</h1>
            <form onSubmit={onSubmit}>
              <div>
                <span style={styles.fieldTitle}>您的E-mail:</span>
                <input
                  value={emailInput}
                  onChange={(event) => { setEmailInput(event.target.value); }}
                  style={styles.input}
                  required
                />
              </div>

              <button style={styles.button} type="submit">送出</button>

            </form>
          </ReduxStatusVisualizedComponent>
        )
      }
      <hr />
      <BackToLoadingPage
        onClick={() => setIsShowForgotPasswordPage(false)}
      >
        返回登入頁
      </BackToLoadingPage>

    </div>
  );
};


ForgotPasswordPage.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  handleForgotPassword: PropTypes.func.isRequired,
  setIsShowForgotPasswordPage: PropTypes.func,
};

ForgotPasswordPage.defaultProps = {
  reduxStatus: '',
  reduxError: '',
  setIsShowForgotPasswordPage: null,
};

export default ForgotPasswordPage;
