import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Default from '../../../components/RWDComponent/Default';
import Mobile from '../../../components/RWDComponent/Mobile';
import Assets from '../../../constants/Assets';
import { FontSize } from '../../../constants/FontSize';


const styles = {
  mainContainer: {
    backgroundColor: 'white',
    paddingTop: '50px',
    paddingBottom: '100px',
  },
  igPicContainer: {
    verticalAlign: 'middle',
    width: '20%',
    height: '20%',
    position: 'relative',
    display: 'inline-block',
    textAlign: 'center',
    margin: '30px 0px',
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  igMobilePicContainer: {
    verticalAlign: 'middle',
    width: '33%',
    height: '40%',
    position: 'relative',
    display: 'inline-block',
    margin: '5px 10px 50px 5px',
    backgroundColor: '#FBF2F1',
  },
  titleInstagram: {
    cursor: 'pointer',
    color: 'black',
    width: '100%',
    textAlign: 'center',
    height: 'auto',
    margin: '-20px 0px 10px 0px',
  },
  IgPhotoContainer: {
    margin: '0px auto',
    textAlign: 'center',
    width: '88%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  line: {
    width: '80%',
    border: 0,
    borderTop: '1px grey solid',
  },
};

const InstagramTitleLink = styled.h1`
  cursor: pointer;
  font-size: ${FontSize.title}px;
  &:hover{
    opacity: 0.5;
    text-decoration:underline;
  }
`;

const instagramImgData = [
  { imgUrl: Assets.homePageIgImage01 },
  { imgUrl: Assets.homePageIgImage02 },
  { imgUrl: Assets.homePageIgImage03 },
  { imgUrl: Assets.homePageIgImage04 },
  { imgUrl: Assets.homePageIgImage05 },
];

const instagramImgMobileData = [
  { imgUrl: Assets.homePageIgImage01 },
  { imgUrl: Assets.homePageIgImage02 },
  { imgUrl: Assets.homePageIgImage03 },
];

const RenderImgBlock = ({ imgUrl }) => {
  const content = (
    <a href="https://www.instagram.com/op.taiwan/">
      <img src={imgUrl} alt="" style={styles.img} />
    </a>
  );

  return (
    <React.Fragment key={imgUrl}>
      <Default>
        <div style={styles.igPicContainer}>
          {content}
        </div>
      </Default>
      <Mobile>
        <div style={styles.igMobilePicContainer}>
          {content}
        </div>
      </Mobile>
    </React.Fragment>

  );
};


const InstagramComponent = () => (
  <div style={styles.mainContainer}>
    <InstagramTitleLink>
      <div style={styles.titleInstagram}>
        <div style={styles.title}>#Instagram Feeds</div>
        {/* <hr style={styles.line} /> */}
      </div>
    </InstagramTitleLink>
    <div style={styles.IgPhotoContainer}>
      <Default>
        {instagramImgData.map(RenderImgBlock)}
      </Default>
      <Mobile>
        {instagramImgMobileData.map(RenderImgBlock)}
      </Mobile>

    </div>
  </div>

);

RenderImgBlock.propTypes = {
  imgUrl: PropTypes.string,
};

RenderImgBlock.defaultProps = {
  imgUrl: '',
};

export default InstagramComponent;
