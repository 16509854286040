import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_THEME_LIST'),
  ...createAsyncActionTypesArray('GET_THEME_BAG'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_THEME_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_THEME_BAG_FLOW'),

  // ----- STATUS -----
  'RESET_STATUS',

  { prefix: 'Theme' },
);
