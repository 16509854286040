import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import * as R from 'ramda';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  getClothesListRequest,
  getClothesListSuccess,
  getClothesListFailure,
  getClothesReleaseDateRequest,
  getClothesReleaseDateSuccess,
  getClothesReleaseDateFailure,
  getMyClothesListRequest,
  getMyClothesListSuccess,
  getMyClothesListFailure,
  notifyWhenArrivedRequest,
  notifyWhenArrivedSuccess,
  notifyWhenArrivedFailure,
  resetStatus,

  getCommentListFlowRequest,
  getCommentListSuccess,
  getCommentListFailure,

  getClothesIdByClothesNoFlowRequest,
  getClothesIdByClothesNoSuccess,
  getClothesIdByClothesNoFailure,

} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  selectBagId: null,
  data: [],
  commentList: [],
  releaseDate: '',
  filterCondition: null,
};

export default handleActions({
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: initialState.reduxStatus },
  }),

  [getCommentListFlowRequest]: state => update(state, {
    commentList: { $set: initialState.commentList },
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
  }),

  [combineActions(
    getClothesListRequest,
    notifyWhenArrivedRequest,
    getMyClothesListRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: null },
  }),

  [getClothesIdByClothesNoFlowRequest]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    selectBagId: { $set: null },
  }),

  [getClothesReleaseDateRequest]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    releaseDate: { $set: '' },
  }),

  [combineActions(
    getClothesListFailure,
    notifyWhenArrivedFailure,
    getMyClothesListFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),

  [combineActions(
    getClothesListSuccess,
    getMyClothesListSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data:
      R.pathOr(0, ['filterCondition', 'page'], payload) === 0
        ? { $set: payload.data }
        : { $push: payload.data },
    filterCondition: { $set: payload.filterCondition },
    error: { $set: null },
  }),
  [getClothesReleaseDateSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    releaseDate: { $set: payload },
  }),
  [getCommentListSuccess]: (state, { payload }) => update(state, {
    commentList: { $set: payload },
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
  }),
  [getClothesIdByClothesNoSuccess]: (state, { payload }) => update(state, {
    selectBagId: { $set: payload },
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
  }),

  [getCommentListFailure]: state => update(state, {
    commentList: { $set: initialState.commentList },
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
  }),

  [getClothesIdByClothesNoFailure]: state => update(state, {
    selectBagId: { $set: null },
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
  }),

  [getClothesReleaseDateFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    releaseDate: { $set: '' },
  }),

  [combineActions(
    notifyWhenArrivedSuccess,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
  }),

}, initialState);
