import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import MemberProfileHeader from './MemberProfileHeader';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  isLogInSelector,
} from '../../selector';

const MemberProfileHeaderContainer = ({
  isMe,
  id,
  isLogin,
  memberId,
  reduxStatus,
  reduxError,
  memberProfile,
  onShareOutfit,
  actions: {
    toggleFollowFlowRequest,
  },
}) => {
  const handleOnToggleFollow = () => {
    toggleFollowFlowRequest({ targetId: id });
  };

  return (
    <MemberProfileHeader
      isLogin={isLogin}
      onShareOutfit={onShareOutfit}
      isMe={isMe}
      id={id}
      memberId={memberId}
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      memberProfile={memberProfile}
      onToggleFollow={handleOnToggleFollow}
    />
  );
};

MemberProfileHeaderContainer.propTypes = {
  memberId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  memberProfile: PropTypes.shape({}),
  actions: PropTypes.shape({
    toggleFollowFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  isMe: PropTypes.bool,
  onShareOutfit: PropTypes.func.isRequired,
  isLogin: PropTypes.bool,
};

MemberProfileHeaderContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  memberProfile: null,
  memberId: null,
  id: null,
  isMe: false,
  isLogin: false,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  isLogin: isLogInSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberProfileHeaderContainer);
