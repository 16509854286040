import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  fbLogInRequest,
  fbLogInSuccess,
  fbLogInFailure,
  smsLoginRequest,
  smsLoginSuccess,
  smsLoginFailure,
  sendSmsRequest,
  sendSmsSuccess,
  sendSmsFailure,
  logInRequest,
  logInSuccess,
  logInFailure,
  logOutRequest,
  logOutSuccess,
  logOutFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
  getMemberProfileRequest,
  getMemberProfileSuccess,
  getMemberProfileFailure,
  resetStatus,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  updateMemberInfoRequest,
  updateMemberInfoSuccess,
  updateMemberInfoFailure,
  toggleFollowFlowRequest,
  toggleFollowSuccess,
  toggleFollowFailure,
  getFollowingListFlowRequest,
  getFollowingListSuccess,
  getFollowingListFailure,
  uploadOutfitFlowRequest,
  uploadOutfitSuccess,
  uploadOutfitFailure,
  getMemberOutfitListFlowRequest,
  getMemberOutfitListSuccess,
  getMemberOutfitListFailure,
  getOutfitDetailFlowRequest,
  getOutfitDetailSuccess,
  getOutfitDetailFailure,
  getProtectTreeEndTimeFlowRequest,
  getProtectTreeEndTimeSuccess,
  getProtectTreeEndTimeFailure,
  getReduceDepositListFlowRequest,
  getReduceDepositListSuccess,
  getReduceDepositListFailure,
  getReduceDepositUserListFlowRequest,
  getReduceDepositUserListSuccess,
  getReduceDepositUserListFailure,
  createReduceDepositUserFlowRequest,
  createReduceDepositUserSuccess,
  createReduceDepositUserFailure,
  deleteReduceDepositUserFlowRequest,
  deleteReduceDepositUserSuccess,
  deleteReduceDepositUserFailure,
  updateReduceDepositUserFlowRequest,
  updateReduceDepositUserSuccess,
  updateReduceDepositUserFailure,
  getUserIdByCommunityNameFlowRequest,
  getUserIdByCommunityNameSuccess,
  getUserIdByCommunityNameFailure,

  getNotificationListFlowRequest,
  getNotificationListSuccess,
  getNotificationListFailure,

  getMemberCouponListFlowRequest,
  getMemberCouponListSuccess,
  getMemberCouponListFailure,

  deleteOutfitsFlowRequest,
  deleteOutfitsSuccess,
  deleteOutfitsFailure,

  searchMemberListByKeywordFlowRequest,
  searchMemberListByKeywordSuccess,
  searchMemberListByKeywordFailure,

  getWithdrawOrderNumbersFlowRequest,
  getWithdrawOrderNumbersFlowSuccess,
  getWithdrawOrderNumbersFlowFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: null,
  memberProfile: null,
  outfitDetail: null,
  selectUserId: null,
  searchByKeyword: null,
};

export default handleActions({
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: initialState.reduxStatus },
  }),

  [combineActions(
    fbLogInRequest,
    logInRequest,
    logOutRequest,
    registerRequest,
    changePasswordRequest,
    forgotPasswordRequest,
    updateMemberInfoRequest,
    getMemberProfileRequest,
    smsLoginRequest,
    sendSmsRequest,
    toggleFollowFlowRequest,
    getFollowingListFlowRequest,
    uploadOutfitFlowRequest,
    getMemberOutfitListFlowRequest,
    getOutfitDetailFlowRequest,
    getProtectTreeEndTimeFlowRequest,
    getReduceDepositListFlowRequest,
    getReduceDepositUserListFlowRequest,
    createReduceDepositUserFlowRequest,
    deleteReduceDepositUserFlowRequest,
    updateReduceDepositUserFlowRequest,
    getUserIdByCommunityNameFlowRequest,
    getNotificationListFlowRequest,
    getMemberCouponListFlowRequest,
    deleteOutfitsFlowRequest,
    getWithdrawOrderNumbersFlowRequest,
    searchMemberListByKeywordFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: null },
  }),

  [combineActions(
    fbLogInFailure,
    logInFailure,
    registerFailure,
    changePasswordFailure,
    forgotPasswordFailure,
    updateMemberInfoFailure,
    getMemberProfileFailure,
    smsLoginFailure,
    sendSmsFailure,
    toggleFollowFailure,
    deleteReduceDepositUserFailure,
    updateReduceDepositUserFailure,
    deleteOutfitsFailure,
    getWithdrawOrderNumbersFlowFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),

  [combineActions(
    registerSuccess,
    changePasswordSuccess,
    forgotPasswordSuccess,
    toggleFollowSuccess,
    deleteReduceDepositUserSuccess,
    updateReduceDepositUserSuccess,
    deleteOutfitsSuccess,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    error: { $set: null },
  }),

  [combineActions(
    logInSuccess,
    fbLogInSuccess,
    smsLoginSuccess,
    sendSmsSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
    error: { $set: null },
  }),

  [updateMemberInfoSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { user: { $set: payload } },
    error: { $set: null },
  }),

  [createReduceDepositUserSuccess]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    error: { $set: null },
  }),

  [getFollowingListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { followingList: { $set: payload } },
    error: { $set: null },
  }),


  [searchMemberListByKeywordSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    searchByKeyword: { $set: payload },
    error: { $set: null },
  }),


  [getNotificationListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { notifications: { $set: payload } },
    error: { $set: null },
  }),

  [getMemberCouponListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { coupons: { $set: payload } },
    error: { $set: null },
  }),

  [getProtectTreeEndTimeSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { protectTreeEndTime: { $set: payload } },
    error: { $set: null },
  }),

  [getUserIdByCommunityNameSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    selectUserId: { $set: payload },
    error: { $set: null },
  }),

  [uploadOutfitSuccess]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    error: { $set: null },
  }),

  [getMemberOutfitListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { outfitList: { $set: payload } },
    error: { $set: null },
  }),

  [getReduceDepositListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { reduceDepositList: { $set: payload } },
    error: { $set: null },
  }),

  [getReduceDepositUserListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { reduceDepositUserList: { $set: payload } },
    error: { $set: null },
  }),

  [getOutfitDetailSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    outfitDetail: { $set: payload },
    error: { $set: null },
  }),

  [combineActions(
    logOutSuccess,
    logOutFailure,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: initialState.data },
    error: { $set: null },
  }),

  [getMemberProfileSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    memberProfile: { $set: payload },
    error: { $set: null },
  }),

  [getWithdrawOrderNumbersFlowSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { withdrawOrderNumber: { $set: payload } },
  }),

  [getFollowingListFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { followingList: { $set: null } },
    error: { $set: null },
  }),

  [getReduceDepositUserListFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    data: { followingList: { $set: null } },
    error: { $set: null },
  }),

  [getReduceDepositListFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    data: { reduceDepositUserList: { $set: null } },
    error: { $set: null },
  }),

  [createReduceDepositUserFailure]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    error: { $set: payload },
  }),

  [getProtectTreeEndTimeFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    data: { protectTreeEndTime: { $set: null } },
    error: { $set: null },
  }),

  [uploadOutfitFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    data: { outfitList: { $set: null } },
    error: { $set: null },
  }),

  [getUserIdByCommunityNameFailure]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    selectUserId: { $set: payload },
    error: { $set: null },
  }),

  [getMemberOutfitListFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    data: { outfitList: { $set: null } },
    error: { $set: null },
  }),

  [getNotificationListFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    data: { notifications: { $set: null } },
    error: { $set: null },
  }),

  [getMemberCouponListFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    data: { coupons: { $set: null } },
    error: { $set: null },
  }),

  [searchMemberListByKeywordFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    searchByKeyword: { $set: null },
    error: { $set: null },
  }),

  [getOutfitDetailFailure]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    outfitDetail: { $set: null },
    error: { $set: null },
  }),

}, initialState);
