export const orderStatus = {
  create: '訂單已建立',
  hadPaidDeposit: '已支付押金',
  depositComplete_rentFail: '押金成功/租金尚未支付',
  payFail_returnDepositSuccess: '支付失敗/押金刷退',
  complete: '訂單完成',
  shipped: '已出貨',
  shipSuccess_reviewing: '收貨成功/審核中',
  shipSuccess_returnDeposit: '收貨成功/押金已刷退',
  nursing: '護理中',
  nursingComplete_returnRemainingDeposit: '護理完成/剩餘押金刷退',
  unRepair_tranDeposit: '無法修復/押金轉付',
  shipDelay: '收貨延遲',
  shipFail: '收貨失敗',
  canceledUnconfirmed: '訂單已取消(未確認)',
  canceled: '訂單已取消',
  shipSuccess_nursing: '收貨成功/商品進行護理',
  overdue_notReturn: '逾期未歸還',

  // new Process
  confirming: '訂單確認中',
  confirmed: '訂單成立',
  lenderSent: '出租方寄出',
  renterSent: '租借方歸還',
  lenderReceive: '出借方確認歸還',
  issueReport: '問題回報',
};

export const orderStatusId = {
  create: 1,
  hadPaidDeposit: 2,
  depositComplete_rentFail: 3,
  payFail_returnDepositSuccess: 4,
  complete: 5,
  shipped: 6,
  shipSuccess_reviewing: 7,
  shipSuccess_returnDeposit: 8,
  nursing: 9,
  nursingComplete_returnRemainingDeposit: 10,
  unRepair_tranDeposit: 11,
  shipDelay: 12,
  shipFail: 13,
  canceledUnconfirmed: 14,
  canceled: 15,
  bagBuyout_returnRemainingDeposit: 16,
  shipSuccess_nursing: 17,
  overdue_notReturn: 18,

  // new Process
  confirming: 19,
  confirmed: 20,
  lenderSent: 21,
  renterSent: 22,
  lenderReceive: 23,
  issueReport: 24,
};


export default {
  orderStatus,
  orderStatusId,
};
