import React from 'react';
import AboutPage from './AboutPage';
import Assets from '../../../../constants/Assets';

const data = [
  { imgUrl: Assets.aboutOhMobile01V2 },
  { imgUrl: Assets.aboutOhMobile02V2 },
  { imgUrl: Assets.aboutOhMobile03V2 },
];

const AboutContainer = () => (
  <AboutPage
    data={data}
  />
);


export default AboutContainer;
