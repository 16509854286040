import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popconfirm } from 'antd';
import { Link } from '@reach/router';
import * as R from 'ramda';
import amountPattern from '../../../utils/AmountPattern';
import BagStatus from '../../../constants/BagStatus';
import { rentAmountCalculate } from '../../../utils/rentAmountCalculate';
import bagOrClothesPath from '../../../utils/bagOrClothesPath';

const styles = {
  mainContainer: {
    width: '100%',
  },
  upTableContainer: {
    borderBottom: '1px grey solid',
    borderTop: '1px grey solid',
    padding: '10px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    margin: 0,
  },
  img: {
    width: 100,
    objectFit: 'contain',
    height: 100,
    backgroundColor: 'white',
    minWidth: '90px',
  },
  expand: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    color: 'black',
  },
  upTable: {
    tableLayout: 'fixed',
    width: '70%',
  },
  upTableRow: {
    height: '10px',
    width: '100%',
  },
  upTableTitle: {
    width: '30%',
  },
  upTableContent: {
    paddingLeft: '6px',
    display: 'flex',
    width: '100%',
  },
  functionButton: {
    cursor: 'pointer',
    outline: 'none',
    border: '1px solid #AE4200',
    color: 'black',
    width: 90,
    height: 27,
    fontFamily: 'Microsoft JhengHei Regular',
    fontWeight: 200,
  },
  deleteIcon: {
    textAlign: 'left',
  },
  icon: {
    marginLeft: '90%',
  },
  deleteContainer: {
    position: 'absolute',
    right: '10%',
  },
};

const CartTableMobileComponent = ({
  cart,
  onDeleteCart,
}) => {
  const commodityType = R.pathOr(1, ['bag', 'commodityTypeId'], cart);
  const washAmount = R.pathOr(0, ['bag', 'washAmount'], cart);

  const renderTypeString = (type) => {
    if (type === 'rent') {
      return '僅租借';
    } if (type === 'buy') {
      return '僅購買';
    }
    return '可租借可購買';
  };

  const renderCreateOrderButton = (bagNo, bagStatus) => {
    if (bagStatus.id === BagStatus.bagStatusId.inStock) {
      return (
        <Link
          key="transaction"
          to={`${bagOrClothesPath(commodityType)}/${bagNo}`}
        >
          <button type="button" style={styles.functionButton}>下訂單</button>
        </Link>
      );
    }
    return (<span>{bagStatus.name}</span>);
  };
  return (
    <div style={styles.mainContainer}>
      <div style={styles.upTableContainer}>
        <Link to={`${bagOrClothesPath(commodityType)}/${R.pathOr('', ['bag', 'bagNo'], cart)}`}>
          <img alt="" style={styles.img} src={R.pathOr('', ['bag', 'frontPic', 'uri'], cart)} />
        </Link>
        <div style={styles.deleteContainer}>
          <Popconfirm
            title="是否從願望清單移除?"
            onConfirm={() => onDeleteCart(cart.id)}
            okText="Yes"
            cancelText="No"
          >
            <Icon
              style={styles.icon}
              type="close-circle"
            />
          </Popconfirm>
        </div>
        <table style={styles.upTable}>
          <tbody>
            <tr style={styles.upTableRow}>
              <td style={styles.upTableTitle}>商品編號</td>
              <td style={styles.upTableContent}>
                <Link to={`${bagOrClothesPath(commodityType)}/${R.pathOr('', ['bag', 'bagNo'], cart)}`}>
                  {R.pathOr('0', ['bag', 'bagNo'], cart)}
                </Link>
              </td>
            </tr>
            <tr style={styles.upTableRow}>
              <td style={styles.upTableTitle}>商品名稱</td>
              <td style={styles.upTableContent}>
                <Link to={`${bagOrClothesPath(commodityType)}/${R.pathOr('', ['bag', 'bagNo'], cart)}`}>
                  {R.pathOr('', ['bag', 'name'], cart)}
                </Link>
              </td>
            </tr>
            <tr style={styles.upTableRow}>
              <td style={styles.upTableTitle}>交易型態</td>
              <td style={styles.upTableContent}>
                {renderTypeString(R.pathOr('', ['bag', 'type', 'name'], cart))}
              </td>
            </tr>
            <tr style={styles.upTableRow}>
              <td style={styles.upTableTitle}>租金/押金</td>
              <td style={styles.upTableContent}>
                {`NT$ ${Math.ceil(rentAmountCalculate(R.pathOr(0, ['bag', 'rentAmountPerDay'], cart), 4, false, washAmount) / 4)}
                / NT$ ${R.pathOr('', ['bag', 'deposit'], cart)} `}
              </td>
            </tr>
            <tr style={styles.upTableRow}>
              <td style={styles.upTableTitle}>買斷金額</td>
              <td style={styles.upTableContent}>
                {`NT$ ${amountPattern(R.pathOr('0', ['bag', 'buyoutAmount'], cart))}`}
              </td>
            </tr>
            <tr style={styles.upTableRow}>
              <td style={styles.upTableTitle}>操作</td>
              <td style={styles.upTableContent}>
                {renderCreateOrderButton(R.pathOr('0', ['bag', 'bagNo'], cart), R.pathOr('', ['bag', 'bagStatus'], cart))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

CartTableMobileComponent.propTypes = {
  cart: PropTypes.object,
  onDeleteCart: PropTypes.func.isRequired,
};

CartTableMobileComponent.defaultProps = {
  cart: null,
};

export default CartTableMobileComponent;
