import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../ActionCreator';
import FAQpage from './FAQpage';

const data = [
  {
    typeId: 0,
    questions: '為什麼需要會員實名認證? 一定要通過才能使用平台服務嗎?',
    ans: (
      <div>
        為了創造更安全的共享環境，一定要通過實名認證才能使用平台的服務唷！
        <br />
        實名認證會對會員進行真實身份信息認證，確保帳戶是屬於本人擁有防止身份盜用。
      </div>
    ),
  },
  {
    typeId: 0,
    questions: '會員實名認證需要什麼文件?',
    ans: (
      <div>中華民國國民身分證，中華民國居留證</div>
    ),
  },
  {
    typeId: 0,
    questions: '平台會儲存我的實名認證資料嗎?',
    ans: (
      <div>
        於實名認證程序中提供的所有資料皆不會由平台管理，
        <br />
        而是由第三方驗證單位「passbase」進行資料加密處理，
        <br />
        更不會被出租會員或租借會員任一方獲取您的個人資料。
      </div>
    ),
  },
  {
    typeId: 0,
    questions: '平台提供哪些付款方式?',
    ans: (
      <div>
        目前僅提供 APPLE PAY / VISA / MasterCard / JCB 信用卡線上刷卡付款，買斷商品另提供零卡分期服務。
      </div>
    ),
  },
  {
    typeId: 0,
    questions: '平台服務費如何計算?',
    ans: (
      <div>
        平台每完成一筆租借訂單，會向租借方和出租方各收取20%的服務費用。
      </div>
    ),
  },
  {
    typeId: 0,
    questions: '會員資料或提款帳戶可以更改嗎?',
    ans: (
      <div>
        可以的，登入會員專區即可變更會員資料及提款銀行帳戶等資訊。
      </div>
    ),
  },
  {
    typeId: 0,
    questions: '忘記密碼該怎麼辦??',
    ans: (
      <div>
        請於會員登入頁面中點選【忘記密碼】，並遵照系統指示，重設帳戶密碼即可。
      </div>
    ),
  },
  {
    typeId: 0,
    questions: '我要如何聯繫客服人員?',
    ans: (
      <div>
        歡迎加入OP火租
        <a href="https://line.me/R/ti/p/%40xyf9387x">LINE官方帳號詢問</a>
        ，或使用網頁下方的“聯絡我們”，OP小幫手將會於上班時間盡快回覆您。
      </div>
    ),
  },
  {
    typeId: 1,
    questions: '如何租借?',
    ans: (
      <div>
        1. 註冊會員並通過實名認證
        <br />
        2. 搜尋商品，在商品頁面選擇要租借的日期後，點擊【送出訂單】
        <br />
        3. 填寫收件及發票相關資訊
        <br />
        4. 進入信用卡付款頁面進行付款
        <br />
        5. 付款成功後，等待商品主人確認訂單
        <br />
        6. 商品主人接受訂單後，訂單成立
        <a href="https://www.ohphire.com/howItWorks">
          https://www.ohphire.com/howItWorks
        </a>
      </div>
    ),
  },
  {
    typeId: 1,
    questions: '要怎麼選擇租借日期?',
    ans: (
      <div>
        為了預留足夠的出貨時間給出租方，租借起始日僅能選擇訂單當日日期的後5日開始。
        <br />
        若商品有「OP管理」標示，則最晚可於租借起始日前一天(平日）14:00前下單。
        <br />
        ＊小提醒：包裹實際送達時間以物流公司當日實際配送時間為主，恕不得指定派送時間，建議將租借日期提前一天，避免影響您使用的行程唷！
      </div>
    ),
  },
  {
    typeId: 1,
    questions: '租借期間如何計算?',
    ans: (
      <div>
        租借期間最短4天，最長為16天。包裹一旦送達並經過簽收，即為租期開始計算的第一日；租期最後一天當日則須將包裹以原取件方式寄回歸還。
      </div>
    ),
  },
  {
    typeId: 1,
    questions: '包裹何時會送到? ',
    ans: (
      <div>
        包裹會於您選擇的租期第一日抵達。包裹實際送達時間以物流公司當日實際配送時間為主，建議將租借日期提前一天，避免影響您使用的行程唷！
      </div>
    ),
  },
  {
    typeId: 1,
    questions: '訂單成立後，我可以取消租借訂單嗎?  會收取追加的費用嗎?',
    ans: (
      <div>
        送出訂單後15鐘內未付款系統會自動取消，若已完成付款且出租方已接受訂單，則將無法再取消訂單。
        <br />
        若出租方未接受訂單則會取消，平台將不會收取追加的費用，該筆訂單款項將會由平台完全刷退至租借方。
      </div>
    ),
  },
  {
    typeId: 1,
    questions: '為什麼商品訂單被取消了?',
    ans: (
      <div>
        當您遇到訂單取消，可能有以下幾種情況：
        <li>您在送出訂單的15分鐘內未完成付款 </li>
        <li>出租方未在48小時內按下接受訂單 </li>
        <li>出租方主動按下取消訂單</li>
      </div>
    ),
  },
  {
    typeId: 1,
    questions: '若商品不慎污損怎麼辦?',
    ans: (
      <div>
        平台將會依出租方提供的照片進行評估商品損壞程度，並依損壞程度向租借方請求賠償，
        賠償金額將全額支付給出租方，不收取平台服務費，租借方須同意平台的一切判定結果。
        <br />
        ＊小提醒：為維護雙方權益，建議您在包裹開箱與歸還包裝時全程錄影或拍照記錄，避免後續更多的困擾唷！
      </div>
    ),
  },
  {
    typeId: 1,
    questions: '逾期歸還商品，如何計費?',
    ans: (
      <div>
        平台會以郵戳或寄件單上的日期辨認逾期時間，逾期歸還平台會向您收取延遲金。
        延遲金計費方式：單日租金的1.5倍 x 延遲的天數
      </div>
    ),
  },
  {
    typeId: 1,
    questions: '我已經收到押金退刷通知，信用卡額度為什麼還沒歸還?',
    ans: (
      <div>
        銀行入帳時間可能會因各家銀行作業系統而稍有差異，請於收到通知後5～14個工作日內前往個人帳戶中查看。
      </div>
    ),
  },
  {
    typeId: 1,
    questions: '為什麼我無法送出租借請求?',
    ans: (
      <div>
        為了創造更安全的共享環境，一定要通過實名認證才能使用平台的服務唷！
        <br />
        若還是無法送出租借請求，請檢查收件資料是否填妥或聯繫OP火租
        <a href="https://line.me/R/ti/p/%40xyf9387x">LINE官方帳號</a>
        詢問喔！
      </div>
    ),
  },
  {
    typeId: 1,
    questions: 'OP管理是什麼?',
    ans: (
      <div>
        OP管理是會員委託平台代管的商品，標示OP管理的商品可享有以下服務：
        <li>緊急租借需求的好選擇！最晚可以在租借起始日前一天的14:00(限平日）前下單，皆可享隔天火速到貨服務喔！</li>
        <li>體貼的安心試穿保證！擔心租借衣服會有尺寸不合的問題，服飾類商品在收貨後12小時內皆考聯繫我們申請退貨喔！</li>
      </div>
    ),
  },
  {
    typeId: 1,
    questions: '為什麼我收到的發票金額與當初下單的金額不一樣?',
    ans: (
      <div>
        OP火租為提供會員自由租借之媒合服務平台，並未販售自有商品，因此僅收取訂單金額之20%作為平台服務費，發票上僅顯示該筆服務費之金額。
      </div>
    ),
  },
  {
    typeId: 2,
    questions: '如何出租商品?',
    ans: (
      <div>
        1. 註冊會員並通過實名認證
        <br />
        2. 登入會員後，於首頁右上方點選【立即上傳】
        <br />
        3. 依序上傳商品照片→ 填寫詳細資訊並送出
        <br />
        4. 完成商品資料審核通過且上架後會寄送E-mail通知您
        <br />
        5. 成功出租時，依通知信件指示至平台按下【接受訂單】
        <br />
        6. 為精品包商品需於租借日期前三日寄送至平台審核 / 一般商品則確保商品於租借起始日當天抵達
        <br />
        7. 審核完畢後，由平台於租借起始日前代為出貨
      </div>
    ),
  },
  {
    typeId: 2,
    questions: '商品出租的服務費如何計算?',
    ans: (
      <div>
        平台每完成一筆租借訂單，將酌收訂單金額的20%做為服務費用。
      </div>
    ),
  },
  {
    typeId: 2,
    questions: '租金要怎麼設定?',
    ans: (
      <div>
        建議上傳商品資料前，可先瀏覽並參考平台上相似的品項或品牌來評估符合您預期的商品租金。
        <br />
        ＊小提醒：訂定較為合理的租金，可有效增加出租機率喔！
      </div>
    ),
  },
  {
    typeId: 2,
    questions: '接受訂單後，我可以取消出租訂單嗎？',
    ans: (
      <div>
        若已按下接受訂單，則無法再取消。
        <br />
        ＊小提醒：租借方完成付款後，平台會通知您前往個人頁面的交易紀錄接受訂單，若48小時內未接受，訂單將會自動取消喔！
      </div>
    ),
  },
  {
    typeId: 2,
    questions: '商品出貨前，如何保證我的商品品質?',
    ans: (
      <div>
        建議您在商品出貨前及收到歸還的商品時都使用手機拍照紀錄。手機會記錄當下拍照的時間。
        <br />
        ＊小提醒：為維護雙方權益，建議您在包裹開箱與歸還包裝時全程錄影或拍照記錄，避免後續更多的困擾唷！
      </div>
    ),
  },
  {
    typeId: 2,
    questions: '若我出租的商品回來後出現損壞怎麼辦?',
    ans: (
      <div>
        若您收到商品歸還後發現商品出現損壞情況，請在收到歸還商品後的24小時內向OP客服反應，
        並將商品出貨當時的照片與歸還後出現損壞的照片一併提供給我們，我們會在上班時間盡快為您處理。
        <br />
        ＊小提醒：為維護雙方權益，建議您在包裹開箱與歸還包裝時全程錄影或拍照記錄，避免後續更多的困擾唷！
      </div>
    ),
  },
  {
    typeId: 2,
    questions: '商品成功出租後，我要如何領取獲得的費用?',
    ans: (
      <div>
        當商品成功出租並完整歸還後，請前往【會員專區】中的【交易紀錄】，選取該筆訂單並點選【前往提領】即可領取您的收益唷！
      </div>
    ),
  },
  {
    typeId: 2,
    questions: '我出租的商品逾期未還怎麼辦? ',
    ans: (
      <div>
        若訂單截止日後的三天內還未收到歸還商品，請到【交易紀錄】點選【問題回報】或OP火租
        <a href="https://ne.me/R/ti/p/%40xyf9387x">LINE官方帳號</a>
        直接與我們聯繫，我們會在上班時間盡快為您處理。
      </div>
    ),
  },
  {
    typeId: 2,
    questions: '為什麼我無法接受訂單?',
    ans: (
      <div>
        為了創造更安全的共享環境，一定要通過實名認證才能使用平台的服務唷！
      </div>
    ),
  },
  {
    typeId: 2,
    questions: 'OP管理是什麼?',
    ans: (
      <div>
        OP管理是會員委託平台代管的商品，標示OP管理的商品可享有以下服務：
        <li>平台將統一保存、維護與出貨您的代管商品。您也可以隨時與平台聯繫寄回您的商品，並改為自助管理的方式繼續進行出租喔！</li>
        <li>因平台皆採自助租借的方式，運費皆由出租與租借方自行負擔，代管商品經由平台協助出貨，運費將從出租方實際賺取收益中扣除，金額則依實際出貨時之包裹重量計算。</li>
        <li>若選擇OP管理服務，最短代管天數為30天。</li>
      </div>
    ),
  },
  {
    typeId: 3,
    questions: '哪些商品可以出售/購買?',
    ans: (
      <div>
        平台上有標示「購買 BUY」或「租借-購買 RENT & BUY」的商品皆可購買
      </div>
    ),
  },
  {
    typeId: 3,
    questions: '如何出售商品?',
    ans: (
      <div>
        1. 註冊會員並通過實名認證
        <br />
        2. 登入會員後，於首頁右上方點選【立即上傳】
        <br />
        3. 依序上傳商品照片→ 填寫詳細資訊並送出
        <br />
        4. 完成商品資料審核通過且上架後會寄送E-mail通知您
        <br />
        5. 出售商品若為二手精品包包，訂單成立後須先寄至OP進行鑑定，再由OP統一出貨
      </div>
    ),
  },
  {
    typeId: 3,
    questions: '購買的商品可以退貨嗎?',
    ans: (
      <div>
        除精品包包與全新商品外，其餘二手商品交易均不適用於七天鑑賞期哦！收到貨後七天內，保留貨運簽收單與未剪吊牌
        皆可透過OP官方聯絡管道申請退貨，但需自行負擔來回寄送的運費喔！OP建議如果商品真的不合適可以再將商品上傳至平台尋找更適合的主人
      </div>
    ),
  },
  {
    typeId: 4,
    questions: '配送方式及運費計算',
    ans: (
      <div>
        平台為自助租借型式，架上商品租金皆不包含運費，會員需自行前往寄件並支付運費。
        <br />
        ＊小提醒：
        <br />
        自助管理-為精品包之商品出租方出貨與租借方歸還皆需統一寄至OP平台審核，運費需自行支付，來回平台的運費則由出租方的收益中扣除。
        <br />
        為一般商品則由會員自行寄出，需確保商品於租借起始日抵達
        <br />
        OP管理之商品將交由平台代為出貨，運費將從出租/出售方實際賺取收益中扣除，金額則依實際出貨時之包裹重量計算。
        <br />
        建議您可選擇鄰近、方便寄出的宅配公司或超商店到店服務
        <li>
          郵寄到府
        </li>
        1.
        <b>中華郵政</b>
        -可至全台郵局辦裡寄件服務
        <br />
        2.
        <b>黑貓宅急便</b>
        -可至全台7-ELEVEN、OK超商和有黑貓宅急便包裹代收的商店及黑貓宅急便各地營業據點寄件或是與黑貓宅急便聯絡安排到府收件的服務
        <br />
        3.
        <b>宅配通</b>
        -可至全台全家、萊爾富便利商店和有宅配通包裹代收的商店或是與宅配通聯絡安排到府收件的服務
        <br />
        4.
        <b>順豐速運</b>
        -手機號碼快速註冊，以【我要寄件】功能線上預約收貨員到府收件
        <li>
          超商店到店 (以下提供各超商店到店寄件教學及注意事項)
        </li>
        1. 7-ELEVEN：
        <a href="https://reurl.cc/R10jW9">https://reurl.cc/R10jW9</a>
        <br />
        2. 全家：
        <a href="https://reurl.cc/A8kK0E">https://reurl.cc/A8kK0E </a>
        <br />
        <br />
      </div>
    ),
  },
  {
    typeId: 4,
    questions: '我是出租方，我要怎麼寄出商品?',
    ans: (
      <div>
        您在上傳區頁面可選擇您欲提供的寄送方式。收件資訊可至【會員專區】中的【交易紀錄】點選【我要寄貨】。
        商品成功寄出後，請記得回到【交易紀錄】同樣點選【我要寄貨】回報【出貨資訊】才視為出貨成功喔！
        <br />
        ＊小提醒：詳細寄件教學請參考上方FAQ的“配送方式及運費計算”
        <br />
        ＊若欲更改寄送方式，也可隨時至個人頁面的【出貨資訊】進行更改
      </div>
    ),
  },
  {
    typeId: 4,
    questions: '我是租借方，我要怎麼歸還商品?',
    ans: (
      <div>
        商品於租借期最後一日當天需以原取件方式寄回原出貨地址/超商，平台會以郵戳或寄件單上的日期辨別寄出時間。本平台之收件資訊可至【會員專區】中的【交易紀錄】點選【我要歸還】。
        商品成功寄出後，請記得回到【交易紀錄】同樣點選【我要歸還】回報【歸還資訊】才視為歸還成功喔！
        <br />
        ＊小提醒：詳細寄件教學請參考上方FAQ的“配送方式及運費計算”
      </div>
    ),
  },
  {
    typeId: 4,
    questions: '可以寄送離島地區嗎?',
    ans: (
      <div>
        很抱歉，目前配送區域僅限本島，尚未提供離島地區寄送服務。
      </div>
    ),
  },
];

const FAQContainer = () => (
  <FAQpage
    data={data}
  />
);


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapDispatchToProps,
)(FAQContainer);
