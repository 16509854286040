import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import OutfitDetailComponent from './OutfitDetailComponent';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  outfitDetailSelector,
  isLogInSelector,
} from '../../selector';

const OutfitDetailComponentContainer = ({
  outfitDetail,
  reduxStatus,
  onModalClose,
  isVisible,
  id,
  isLogin,
  actions: {
    getOutfitDetailFlowRequest,
    sendOutfitCommentFlowRequest,
    deleteOutfitsFlowRequest,
    resetStatus,
  },
  memberId,
  isMe,
}) => {
  useEffect(() => {
    if (isVisible) {
      getOutfitDetailFlowRequest({ id });
      resetStatus();
    }
  }, [isVisible]);

  const handleOnSendOutfitComment = (comment) => {
    sendOutfitCommentFlowRequest({ comment, outfitId: id });
  };

  const handleOnDeleteOutfit = (outfitId) => {
    deleteOutfitsFlowRequest({ outfitId, userId: memberId });
    onModalClose();
  };

  return (
    <OutfitDetailComponent
      isLogin={isLogin}
      onSendOutfitComment={handleOnSendOutfitComment}
      onDeleteOutfit={handleOnDeleteOutfit}
      outfitDetail={outfitDetail}
      isVisible={isVisible}
      onModalClose={onModalClose}
      reduxStatus={reduxStatus}
      isMe={isMe}
    />
  );
};

OutfitDetailComponentContainer.propTypes = {
  reduxStatus: PropTypes.string,
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  actions: PropTypes.shape({
    resetStatus: PropTypes.func.isRequired,
    getOutfitDetailFlowRequest: PropTypes.func.isRequired,
    sendOutfitCommentFlowRequest: PropTypes.func.isRequired,
    deleteOutfitsFlowRequest: PropTypes.func.isRequired,
    getMemberProfileFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  outfitDetail: PropTypes.object,
  isLogin: PropTypes.bool,
  memberId: PropTypes.number,
  isMe: PropTypes.bool.isRequired,
};

OutfitDetailComponentContainer.defaultProps = {
  reduxStatus: null,
  isVisible: false,
  outfitDetail: {},
  isLogin: false,
  memberId: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  outfitDetail: outfitDetailSelector(state),
  isLogin: isLogInSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OutfitDetailComponentContainer);
