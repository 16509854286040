import * as R from 'ramda';
import configEnv from './configEnv';

class AppleTapPayService {
  data = {
    supportedNetworks: ['MASTERCARD', 'VISA', 'AMEX'],
    supportedMethods: ['apple_pay'],
    displayItems: [{
      label: '',
      amount: {
        currency: 'TWD',
        value: '0',
      },
    }],
    total: {
      label: '',
      amount: {
        currency: 'TWD',
        value: '0',
      },
    },
    options: {
      requestPayerEmail: false,
      requestPayerName: false,
      requestPayerPhone: false,
      requestShipping: false,
    },
  }

  setOrderAmount(amount) {
    this.data.displayItems[0].amount.value = amount;
    this.data.total.amount.value = amount;
  }

  loadScript = (applePayResultCallback) => {
    const TPDirect = R.path(['TPDirect'], window);
    TPDirect.setupSDK(configEnv.TapPayAppID, configEnv.TapPayAppKey, configEnv.TapPayType);
    TPDirect.paymentRequestApi.setupApplePay({
      merchantIdentifier: configEnv.TapPayApplePayMerchantId,
      countryCode: 'TW',
    });

    if (TPDirect.paymentRequestApi.checkAvailability()) {
      TPDirect.paymentRequestApi.setupPaymentRequest(this.data, (result) => {
        if (result.canMakePaymentWithActiveCard) {
          try {
            document.getElementById('support').textContent = '裝置可以使用 PaymentRequest / Apple Pay';
            document.getElementById('apple-pay').classList.add('buy');
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
          }
        } else {
          try {
            document.getElementById('support').textContent = '裝置支援 PaymentRequest / Apple Pay，但是沒有可以支付的卡片';
            document.getElementById('apple-pay').classList.add('set-up');
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
          }
        }

        if (window.ApplePaySession) {
          document.getElementById('apple-pay').style.display = 'inline-block';
        }
        document.getElementById('apple-pay').addEventListener('click', () => {
          TPDirect.paymentRequestApi.getPrime((primeResult) => {
            applePayResultCallback(primeResult);
          });
        });
      });
    } else {
      // eslint-disable-next-line no-alert
      window.alert('此瀏覽器不支援 Apple pay');
    }
  };
}

export default new AppleTapPayService();
