import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Redirect } from '@reach/router';
import { connect } from 'react-redux';
import Paths from '../../../../routes/Paths';
import ActionCreators from '../../ActionCreator';
import LogoutPage from './LogoutPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  isLogInSelector,
} from '../../selector';

const LogoutPageContainer = ({
  reduxStatus,
  reduxError,
  isLogin,
  actions: {
    resetStatus,
    logOutFlowRequest,
  },
}) => {
  useEffect(() => {
    logOutFlowRequest();
    return resetStatus;
  }, []);

  if (!isLogin) return <Redirect to={Paths.MEMBER} noThrow />;

  return (
    <LogoutPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
    />
  );
};

LogoutPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  isLogin: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    resetStatus: PropTypes.func.isRequired,
    logOutFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
};

LogoutPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  isLogin: isLogInSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutPageContainer);
