import React from 'react';
import Partner from './PartnerPage';


const PartnerContainer = () => (
  <Partner />
);


export default PartnerContainer;
