import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import OrderDetailPage from './OrderDetailPage';
import OrderActionCreators from '../../ActionCreator';
import ReduxStatus from '../../../../constants/ReduxStatus';
import Path from '../../../../routes/Paths';

import {
  orderDetailErrorSelector,
  orderDetailDataStatusSelector,
  orderDetailReduxStatusSelector,
  orderCreateReduxStatusSelector,
  orderCreateErrorSelector,
  sendCommentReduxStatusSelector,
  sendCommentErrorSelector,
} from '../../selector';

const OrderDetailContainer = ({
  orderId,
  orderData = null,
  actions: {
    orderCreateFlowRequest,
    orderDetailFlowRequest,
    resetOrderCreateStatus,
    sendCommentFlowRequest,
  },
  orderDetailError,
  orderCreateError,
  orderDetailReduxStatus,
  orderCreateReduxStatus,
  sendCommentReduxStatus,
  sendCommentError,
}) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [commentResources, setCommentResources] = useState([]);
  const [comment, setComment] = useState('');

  useEffect(() => {
    orderDetailFlowRequest({ orderId });
  }, []);

  useEffect(() => {
    if (sendCommentReduxStatus === ReduxStatus.STATUS_NORMAL) {
      orderDetailFlowRequest({ orderId });
    }
  }, [sendCommentReduxStatus]);

  useEffect(() => {
    if (!R.isEmpty(R.path(['commentId'], orderData))) {
      setComment(R.pathOr('', ['comment', 'content'], orderData));

      if (R.pathOr([], ['comment', 'resources'], orderData).length > 0) {
        const newCommentResources = [...R.pathOr([], ['comment', 'resources'], orderData)];
        setCommentResources(newCommentResources);
      }
    }
  }, [orderData]);

  useEffect(() => {
    if (orderCreateReduxStatus === ReduxStatus.STATUS_NORMAL) {
      resetOrderCreateStatus();
      setShouldRedirect(true);
    }
  }, [orderCreateReduxStatus]);

  const handleOnSubmitCreateBuyOrder = () => {
    const buyOrder = update({}, { $set: orderData });
    orderCreateFlowRequest(buyOrder);
  };

  const handleOnSendComment = () => {
    if (R.isEmpty(comment)) {
      return;
    }

    sendCommentFlowRequest({
      orderId: orderData.id,
      bagId: R.path(['bagId'], orderData),
      comment,
      commentResources,
    });
  };

  const handleOnCommentChanged = (event) => {
    setComment(event.target.value);
  };

  const handleOnAddCommentResource = (result) => {
    const newCommentResources = update(commentResources, { $push: [result] });
    setCommentResources(newCommentResources);
  };

  return (
    shouldRedirect
      ? <Redirect to={Path.ORDER_LIST} noThrow />
      : (
        <OrderDetailPage
          comment={comment}
          orderData={orderData}
          commentResources={commentResources}
          orderDetailError={orderDetailError}
          orderCreateError={orderCreateError}
          sendCommentError={sendCommentError}
          orderDetailReduxStatus={orderDetailReduxStatus}
          orderCreateReduxStatus={orderCreateReduxStatus}
          sendCommentReduxStatus={sendCommentReduxStatus}
          onSubmitCreateBuyOrder={handleOnSubmitCreateBuyOrder}
          onSendComment={handleOnSendComment}
          onAddCommentResource={handleOnAddCommentResource}
          onCommentChanged={handleOnCommentChanged}
        />
      )
  );
};
OrderDetailContainer.propTypes = {
  actions: PropTypes.shape({
    orderDetailFlowRequest: PropTypes.func.isRequired,
    orderCreateFlowRequest: PropTypes.func.isRequired,
    resetOrderCreateStatus: PropTypes.func.isRequired,
    sendCommentFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  orderData: PropTypes.object,
  orderId: PropTypes.string,
  orderDetailReduxStatus: PropTypes.string,
  orderCreateReduxStatus: PropTypes.string,
  orderDetailError: PropTypes.string,
  orderCreateError: PropTypes.object,
  sendCommentReduxStatus: PropTypes.string,
  sendCommentError: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...OrderActionCreators,
  }, dispatch),
});

OrderDetailContainer.defaultProps = {
  orderData: null,
  orderDetailReduxStatus: null,
  orderCreateReduxStatus: null,
  sendCommentReduxStatus: null,
  sendCommentError: null,
  orderDetailError: null,
  orderCreateError: null,
  orderId: '0',
};

const mapStateToProps = state => ({
  orderData: orderDetailDataStatusSelector(state),
  orderDetailReduxStatus: orderDetailReduxStatusSelector(state),
  orderDetailError: orderDetailErrorSelector(state),
  orderCreateReduxStatus: orderCreateReduxStatusSelector(state),
  orderCreateError: orderCreateErrorSelector(state),
  sendCommentReduxStatus: sendCommentReduxStatusSelector(state),
  sendCommentError: sendCommentErrorSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderDetailContainer);
