export const BagLevel = {
  10: 20,
  9: 30,
  8: 40,
  7: 50,
  5: 60,
};

export const BagHotLevel = {
  1: 30,
  2: 40,
  3: 50,
};

export const BagLevelId = {
  new: 10,
  classA: 9,
  classB: 8,
  classC: 7,
  classD: 5,
};

export const BagLevelText = {
  new: '全新(10/10)',
  classA: 'A(9/10)',
  classB: 'B(8/10)',
  classC: 'C(6-7/10)',
  classD: 'D(5/10)',
};

export default {
  BagLevel,
  BagHotLevel,
  BagLevelId,
  BagLevelText,
};
