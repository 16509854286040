import { all } from 'redux-saga/effects';
import homeSaga from '../modules/Home/saga';
import memberSaga from '../modules/Member/saga';
import bagSaga from '../modules/Bag/saga';
import tagSaga from '../modules/Tag/saga';
import orderSaga from '../modules/Order/saga';
import contactSaga from '../modules/Contact/saga';
import transactionSaga from '../modules/Transaction/saga';
import subscribeSaga from '../modules/Subscribe/saga';
import cartSaga from '../modules/Cart/saga';
import themeSaga from '../modules/Theme/saga';
import negotiationSaga from '../modules/Negotiation/saga';
import onlineBagSaga from '../modules/OnlineBag/saga';
import clothesSaga from '../modules/Clothes/saga';
import headerSaga from '../modules/Header/saga';
import specialSelectSaga from '../modules/SpecialSelect/saga';

export default function* rootSaga() {
  yield all([
    ...homeSaga,
    ...memberSaga,
    ...bagSaga,
    ...tagSaga,
    ...orderSaga,
    ...contactSaga,
    ...transactionSaga,
    ...subscribeSaga,
    ...cartSaga,
    ...themeSaga,
    ...negotiationSaga,
    ...onlineBagSaga,
    ...clothesSaga,
    ...headerSaga,
    ...specialSelectSaga,
  ]);
}
