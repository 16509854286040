import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Avatar } from 'antd';
import { Link } from '@reach/router';
import Paths from '../../../../routes/Paths';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import renderTableFormField from '../../../../components/FormTable/renderTableFormField';
import Uploader from '../../../ResourceUploader/components/Uploader';
import ReduxStatus from '../../../../constants/ReduxStatus';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import { FontSize } from '../../../../constants/FontSize';
import Assets from '../../../../constants/Assets';

const styles = {
  reduxStyle: {
    width: '100%',
  },
  mainContainer: {
    width: '100%',
    padding: '50px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundColorWhite: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 75,
  },
  editProfileContainer: {
    fontSize: 21,
    textAlign: 'center',
    padding: '20px 0px',
    width: '100%',
    borderBottom: '1px solid #E6E7E8',
    color: 'black',
    backgroundColor: 'white',
  },
  tableContainer: {
    height: 'fit-content',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  infoContainer: {
    marginTop: 15,
    height: 'fit-content',
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  avatar: {
    fontSize: 100,
  },
  uploader: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 5,
  },
  upperLeftContainer: {
    marginTop: '50px',
    textAlign: 'center',
    width: '40%',
    height: 'fit-content',
  },
  upperLeftMobileContainer: {
    textAlign: 'center',
    width: '30%',
    height: 'fit-content',
  },
  upperRightContainer: {
    width: '70%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  upperRightMobileContainer: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  infoItemContainer: {
    margin: '5px 0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  infoTitle: {
    textAlign: 'left',
    padding: '8px 0px',
    minWidth: 100,
    fontFamily: 'Microsoft JhengHei Regular',
    color: '#070707',
  },
  infoContent: {
    border: '1px solid #D6D6D6',
    textAlign: 'left',
    width: '100%',
    padding: 5,
    height: 37,
    outline: 'none',
  },
  introductionInput: {
    width: '100%',
    border: '1px solid #D6D6D6',
    textAlign: 'left',
    padding: 5,
    height: 106,
    outline: 'none',
  },
  pauseAccount: {
    cursor: 'pointer',
    marginTop: 10,
    fontSize: FontSize.body,
    color: '#AE4200',
    textAlign: 'center',
  },
  pauseAccountMobile: {
    cursor: 'pointer',
    width: '100%',
    marginTop: 10,
    fontSize: FontSize.body,
    color: '#AE4200',
    textAlign: 'left',
  },
  functionContainer: {
    marginTop: 20,
    width: 'fit-content',
    height: 'fit-content',
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  save: {
    width: 162,
    height: 50,
    textAlign: 'center',
    padding: '10px 0px',
    border: '1px solid #AE4200',
    color: '#AE4200',
    backgroundColor: 'white',
    outline: 'none',
    cursor: 'pointer',
  },
  unChangeSave: {
    width: 162,
    height: 50,
    textAlign: 'center',
    padding: '10px 0px',
    backgroundColor: 'white',
    outline: 'none',
    color: '#D6D6D6',
    border: '1px solid #D6D6D6',
  },
  prePage: {
    marginLeft: 10,
    width: 162,
    height: 50,
    textAlign: 'center',
    padding: '10px 0px',
    border: '1px solid #D6D6D6',
    color: '#D6D6D6',
    backgroundColor: 'white',
    cursor: 'pointer',
    outline: 'none',
  },
  memberLevel: {
    textAlign: 'center',
    padding: '10px 0px',
    fontFamily: 'Microsoft JhengHei Regular',
    color: '#070707',
  },
  memberLevelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  changeAvatar: {
    marginTop: 10,
    fontSize: 16,
    width: '100%',
    textAlign: 'center',
    color: '#AE4200',
    cursor: 'pointer',
  },
  avatarContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  avatarTitle: {
    cursor: 'pointer',
    marginTop: 10,
    fontSize: FontSize.body,
    color: '#AE4200',
    textAlign: 'center',
  },
};

const MemberProfileEditPage = ({
  reduxStatus,
  reduxError,
  memberInfo,
  handleUpdateMemberInfo,
  style,
}) => {
  const [modifiedData, setModifiedData] = useState({});
  const isLoading = reduxStatus === ReduxStatus.STATUS_LOADING;

  useEffect(() => {
    setModifiedData({});
  }, [memberInfo]);

  const renderImageChild = (data = {}) => {
    const renderAvatarIcon = R.pathOr(R.pathOr(null, ['avatar', 'uri'], memberInfo), ['modifiedValue', 'uri'], data) || Assets.AvatarIcon;
    return (
      <div style={styles.avatarContainer}>
        <Avatar
          icon="user"
          size={110}
          style={styles.avatar}
          src={isLoading ? null : renderAvatarIcon}
        />
        <div style={styles.uploader}>
          <Uploader
            type="avatar"
            title="變更大頭貼照"
            onFileUploaded={(result) => {
              const {
                handleChange,
              } = data;
              handleChange(result);
            }}
            childComponent={<div style={styles.avatarTitle}>變更大頭貼照</div>}
          />
        </div>
      </div>
    );
  };

  const renderNameChild = (data = {}) => {
    const {
      handleChange,
    } = data;

    return (
      <div style={styles.infoItemContainer}>
        <div style={styles.infoTitle}>帳號</div>
        <input
          onChange={(event) => {
            handleChange(event);
          }}
          style={styles.infoContent}
          value={R.pathOr(R.pathOr('', ['communityName'], memberInfo), ['modifiedValue'], data)}
          type="text"
        />
      </div>
    );
  };

  const renderIntroduction = (data = {}) => {
    const {
      handleChange,
    } = data; return (
      <div style={styles.infoItemContainer}>
        <div style={styles.infoTitle}>個人簡介</div>
        <textarea
          onChange={(event) => {
            handleChange(event);
          }}
          style={styles.introductionInput}
          value={R.pathOr(R.pathOr('', ['introduction'], memberInfo), ['modifiedValue'], data)}
        />
      </div>
    );
  };

  const handleSave = (event) => {
    event.preventDefault();

    if (R.pathOr(null, ['uri'], modifiedData) !== null) {
      const { uri } = modifiedData;

      delete modifiedData.uri;
      handleUpdateMemberInfo({
        avatarId: R.path(['id'], uri),
        ...modifiedData,
      });
    } else {
      handleUpdateMemberInfo(modifiedData);
    }
  };

  const renderField = parameters => renderTableFormField({
    data: memberInfo,
    modifiedData,
    setModifiedData,
    ...parameters,
  });

  const renderInputBlock = (
    <React.Fragment>
      {/* TODO member level in feature */}
      {/* <div style={styles.infoItemContainer}>
        <div style={styles.infoTitle}>會員級別</div>
        <div style={styles.memberLevelContainer}>
          <Icon type="user" />
          <div style={styles.memberLevel}>高級會員</div>
        </div>
      </div> */}
      <div style={styles.infoItemContainer}>
        {renderField({
          key: 'communityName',
          type: 'custom',
          renderChild: (modifiedValue, isModified, handleChange) => renderNameChild({
            modifiedValue,
            isModified,
            handleChange,
            key: 'communityName',
          }),
        })}
      </div>
      <div style={styles.infoItemContainer}>
        {renderField({
          key: 'introduction',
          type: 'custom',
          renderChild: (modifiedValue, isModified, handleChange) => renderIntroduction({
            modifiedValue,
            isModified,
            handleChange,
            key: 'introduction',
          }),
        })}
      </div>
    </React.Fragment>
  );
  return (
    <ReduxStatusVisualizedComponent
      containerStyle={styles.reduxStyle}
      reduxStatus={reduxStatus}
      showModalOnError
      modalFailureTitle="Error"
      modalFailureContent={reduxError}
    >
      <div style={styles.mainContainer} key="baseContainer">
        <div style={styles.backgroundColorWhite}>
          <form onSubmit={handleSave} style={{ ...styles.tableContainer, ...style }}>
            <Default>
              <div style={styles.editProfileContainer}>編輯個人檔案</div>
            </Default>
            <Mobile>
              <div style={styles.upperLeftContainer}>
                {renderField({
                  key: 'uri',
                  type: 'custom',
                  renderChild: (modifiedValue, isModified, handleChange) => renderImageChild({
                    modifiedValue,
                    isModified,
                    handleChange,
                    key: 'uri',
                  }),
                })}
              </div>
            </Mobile>
            <div style={{ ...styles.infoContainer, ...style }}>
              <Default>
                <div style={styles.upperLeftMobileContainer}>
                  {renderField({
                    key: 'uri',
                    type: 'custom',
                    renderChild: (modifiedValue, isModified, handleChange) => renderImageChild({
                      modifiedValue,
                      isModified,
                      handleChange,
                      key: 'uri',
                    }),
                  })}
                </div>
                <div style={styles.upperRightContainer}>
                  {renderInputBlock}
                </div>
              </Default>
              <Mobile>
                <div style={styles.upperRightMobileContainer}>
                  {renderInputBlock}
                </div>
              </Mobile>
            </div>
            <div style={styles.functionContainer}>
              <button
                disabled={R.isEmpty(modifiedData)}
                type="button"
                onClick={event => handleSave(event)}
                style={!R.isEmpty(modifiedData) ? styles.save : styles.unChangeSave}
              >
                儲存
              </button>
              <Link to={`${Paths.MEMBER}/${R.pathOr('', ['communityName'], memberInfo)}`}>
                <button
                  type="submit"
                  style={styles.prePage}
                >
                  上一頁
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </ReduxStatusVisualizedComponent>
  );
};

MemberProfileEditPage.propTypes = {
  memberInfo: PropTypes.shape({}),
  handleUpdateMemberInfo: PropTypes.func.isRequired,
  reduxStatus: PropTypes.string,
  style: PropTypes.object,
  reduxError: PropTypes.string,
};

MemberProfileEditPage.defaultProps = {
  memberInfo: null,
  reduxStatus: null,
  reduxError: null,
  style: {},
};

export default MemberProfileEditPage;
