const bagStatus = {
  pending: '待處理',
  pleasePayTransmitFee: '請繳交運送費',
  payComplete: '繳費完成',
  shipping: '取件中',
  inStock: '有現貨',
  appraisalFail: '鑑定失敗',
  rented: '出租中',
  bagReturn: '商品寄回',
  bagCheck: '商品歸還檢查中',
  bagNursing: '商品護理',
  bagNursingFinished: '商品護理結束',
  terminate: '終止出租',
  sold: '已售出',
  pickup: '完成取件',
  reviewFail: '審核失敗',
};

const bagStatusId = {
  pending: 1,
  pleasePayTransmitFee: 2,
  payComplete: 3,
  shipping: 4,
  inStock: 5,
  appraisalFail: 6,
  rented: 7,
  bagReturn: 8,
  bagCheck: 9,
  bagNursing: 10,
  bagNursingFinished: 11,
  terminate: 12,
  sold: 13,
  pickup: 14,
  reviewFail: 15,
};

module.exports = {
  bagStatus,
  bagStatusId,
};
