import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import HomeBlockComponents from '../HomeBlockComponents';
import InstagramComponent from '../InstagramComponent';
import BagBannerSlideComponent from '../BagBannerSlideComponent/BagBannerSlideComponent';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import HomeHowItWorksComponent from '../HomeHowItWorksComponent';
import RegisterPageContainer from '../../../Member/components/RegisterPage';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import SpecialSelectComponent from '../../../SpecialSelect/components/SpecialSelectComponent';
import Assets from '../../../../constants/Assets';
import Paths from '../../../../routes/Paths';

const styles = {
  mainContainer: {
    margin: '0 20 20 20',
    textAlign: 'center',
    position: 'relative',
  },
  headerPicContainer: {
    marginTop: 0,
    width: '100%',
  },
  headerPic: {
    width: '100%',
    height: 'auto',
  },
  activity: {
    color: 'black',
    fontSize: 30,
    letterSpacing: 5,
  },
  activityContainer: {
    margin: '20px 0px',
  },
  activityLine: {
    width: '0%',
  },
};

const HomePage = ({
  isLogin,
}) => {
  const [isShowResisterModal, setIsShowRegisterPage] = useState(false);

  useEffect(() => {
    setIsShowRegisterPage(!isLogin);
  }, [isLogin]);

  return (
    <div style={styles.mainContainer} key="baseContainer">

      <InfoModalComponent
        containerStyle={{
          zIndex: 999,
          paddingTop: '5%',
        }}
        isVisible={isShowResisterModal}
        onModalClose={() => setIsShowRegisterPage(false)}
        content={(
          <RegisterPageContainer
            setIsShowRegisterPage={setIsShowRegisterPage}
          />
          )}
      />
      <div style={styles.headerPicContainer}>
        <Link to={`${Paths.BAG_LIST}?search={"bagType":[1,3]}&page=1`}>
          <Default>
            <img
              src={Assets.homePageHeaderPic}
              alt=""
              style={styles.headerPic}
            />
          </Default>
          <Mobile>
            <img
              src={Assets.homePageHeaderPicMobile}
              alt=""
              style={styles.headerPic}
            />
          </Mobile>
        </Link>
      </div>

      <div style={styles.activityContainer}>
        <h1 style={styles.activity}>
          <span role="img" aria-label="starts">✨</span>
          Rent Your Outfit, Own the Looks
          <span role="img" aria-label="starts">✨</span>
        </h1>
        <hr style={styles.activityLine} />
      </div>

      <BagBannerSlideComponent />
      <HomeHowItWorksComponent
        setIsShowRegisterPage={setIsShowRegisterPage}
      />
      <HomeBlockComponents />
      <SpecialSelectComponent />
      <InstagramComponent />
    </div>
  );
};

HomePage.propTypes = {
  isLogin: PropTypes.bool,
};

HomePage.defaultProps = {
  isLogin: false,
};

export default HomePage;
