import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import { orderStatus } from '../../../../constants/OrderStatus';
import { Colors } from '../../../../constants/Color';
import amountPattern from '../../../../utils/AmountPattern';

const styles = {
  mainContainer: {
    margin: '10px',
  },
  tapPayIframe: {
    margin: 0,
    outline: 0,
    WebkitAppearance: 'none',
    tapHighlightColor: 'rgba(255,255,255,0)',
    lineHeight: '1.21428571em',
    padding: '.578571em 1em',
    fontSize: '1em',
    background: '#fff',
    border: '1px solid rgba(34,36,38,.15)',
    color: 'rgba(0,0,0,.87)',
    borderRadius: '.28571429rem',
    boxShadow: '0 0 0 0 transparent inset',
    WebkitTransition: 'color .1s ease,border-color .1s ease',
    transition: 'color .1s ease,border-color .1s ease',
    width: '100%',
  },
  confirm: {
    backgroundColor: '#eeeeee',
    width: 'fit-content',
    height: 'fit-content',
    color: '#ff0000',
  },
  confirmButton: {
    position: 'absolute',
    display: 'flex',
    right: '10px',
    top: '400px',
    width: '100px',
    height: '40px',
    padding: '0px 20px',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    color: 'white',
    backgroundColor: Colors.introRightTitle,
    textAlign: 'center',
    alignItems: 'center',
  },
  note: {
    marginBottom: '250px',
    marginTop: '30px',
    paddingTop: '30px',
    borderTop: '1px solid #D1D3D4',
  },
  transactionDetailText: {
    margin: '20px 0',
    fontWeight: 'bold',
  },
};

const ZeroCardTransactionPage = ({
  orderDetailError,
  transactionError,
  orderDetailReduxStatus,
  transactionReduxStatus,
  orderData,
  onSendConfirm,
}) => {
  const renderDepositWithCoupon = () => {
    const couponTypeName = R.path(['userCoupon', 'coupon', 'couponType', 'name'], orderData);
    if (couponTypeName === '押金折扣碼') {
      return `預刷押金: NT$ ${amountPattern(R.pathOr(0, ['deposit'], orderData) - R.pathOr(0, ['couponAmount'], orderData))}`;
    }

    return `預刷押金: NT$ ${amountPattern(R.pathOr(0, ['deposit'], orderData))}`;
  };

  const renderAmountInfo = () => {
    if (R.pathOr('rent', ['type', 'name'], orderData) === 'buy') {
      if (R.pathOr(null, ['fromRentOrderId'], orderData)) {
        return (
          <div style={styles.transactionDetailText}>
            {`支付購買價格 $
             ${R.pathOr(0, ['bagBuyAmountWithDiscount'], orderData)
              - R.pathOr(0, ['depositTransfer'], orderData)
              - R.pathOr(0, ['rentAmountTransfer'], orderData)
              } `}
          </div>
        );
      }

      return (
        <div style={styles.transactionDetailText}>
          {`支付購買價格:$ NT.${R.pathOr('0', ['amount'], orderData)}`}
        </div>
      );
    }
    if (R.path(['orderStatus', 'name'], orderData) === orderStatus.create) {
      return (
        <div style={styles.transactionDetailText}>
          {renderDepositWithCoupon()}
        </div>
      );
    }

    return (
      <div style={styles.transactionDetailText}>
        {`支付租金:$ NT.${R.pathOr('0', ['amount'], orderData)}`}
      </div>
    );
  };

  return (
    <ReduxStatusVisualizedComponent
      showModalOnError
      modalFailureContent={orderDetailError || transactionError || 'error'}
      reduxStatus={[orderDetailReduxStatus, transactionReduxStatus]}
    >
      <div style={styles.mainContainer}>
        {renderAmountInfo()}
        <div className="ui grid centered doubling stackable">
          <div className="six wide column">
            <div className="ui segment">
              <div style={styles.note}>
                ■ 信用卡
                <br />
                接受信用卡一次付款。(您可使用VISA、Master Card、JCB信用卡)
                <br />
                押金僅接受信用卡方式支付
                <br />
                預刷押金將會在商品歸還同時退回
                <br />
                ■ 租後購買:支付價格為扣除押金與租金後之價格
              </div>
              <div
                style={styles.confirmButton}
                onClick={onSendConfirm}
                role="button"
                tabIndex={0}
                onKeyPress={onSendConfirm}
              >
                確認付款
              </div>
            </div>
          </div>
        </div>
      </div>

    </ReduxStatusVisualizedComponent>
  );
};

ZeroCardTransactionPage.propTypes = {
  orderDetailReduxStatus: PropTypes.string,
  orderData: PropTypes.object,
  transactionReduxStatus: PropTypes.string,
  orderDetailError: PropTypes.string,
  transactionError: PropTypes.string,
  onSendConfirm: PropTypes.func.isRequired,
};

ZeroCardTransactionPage.defaultProps = {
  orderDetailReduxStatus: null,
  orderData: null,
  transactionReduxStatus: null,
  orderDetailError: '',
  transactionError: '',
};

export default ZeroCardTransactionPage;
