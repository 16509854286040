import * as R from 'ramda';
import { createSelector } from 'reselect';
import { cartStateSelector } from '../../lib/rootSelector';

export const cartReduxStatusSelector = createSelector(
  cartStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const cartReduxErrorSelector = createSelector(
  cartStateSelector,
  result => R.path(['error', 'message'], result),
);

export const cartListSelector = createSelector(
  cartStateSelector,
  result => R.path(['data'], result),
);
