import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  clothesSizeStandard: {
    margin: '0px 0px 0px 6px',
    fontSize: 16,
  },
  clothesSizeNumber: {
    border: '1px grey solid',
    margin: '0px 2px',
    padding: '0px 4px',
  },
  sizeStandardHeader: {
    fontSize: 12,
  },
  sizeNumberHeader: {
    border: '1px grey solid',
    margin: '0px 2px',
    padding: '0px 4px',
    width: 'fit-content',
    fontSize: 12,
  },
};

const SizeBLockComponent = ({
  sizeStandard,
  sizeNumber,
  header,
  style,
}) => {
  if (!sizeStandard && !sizeNumber) {
    return (
      <div style={{ ...styles.mainContainer, ...style }}>
        <div style={header ? styles.sizeNumberHeader : styles.clothesSizeNumber}>
          OS
        </div>
      </div>
    );
  }

  return (
    <div style={{ ...styles.mainContainer, ...style }}>
      <div style={header ? styles.sizeStandardHeader : styles.clothesSizeStandard}>
        {sizeStandard}
      </div>
      {sizeNumber ? (
        <div style={header ? styles.sizeNumberHeader : styles.clothesSizeNumber}>
          {sizeNumber}
        </div>
      ) : null}
    </div>
  );
};

SizeBLockComponent.propTypes = {
  sizeStandard: PropTypes.string,
  sizeNumber: PropTypes.string,
  header: PropTypes.bool,
  style: PropTypes.object,
};

SizeBLockComponent.defaultProps = {
  sizeStandard: null,
  sizeNumber: null,
  header: false,
  style: {},
};


export default SizeBLockComponent;
