import React from 'react';
import PropTypes from 'prop-types';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import { ohPhireLineQRCode } from '../../../../constants/Assets';

const styles = {
  mainContainer: {
    width: 370,
    height: 320,
    padding: 20,
  },
  title: {
    fontSize: 35,
    color: 'black',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgContainer: {
    width: 190,
    height: 190,
    padding: 10,
    border: '1px solid #eee',
  },
  img: {
    width: 170,
    height: 170,
    objectFit: 'contain',
  },
  help: {
    marginTop: 20,
  },
};

const ProductIssueReportModalComponentContainer = ({
  isVisible,
  onModalClose,
}) => {
  const content = (
    <div style={styles.mainContainer}>
      <div style={styles.title}>問題回報</div>
      <div style={styles.contentContainer}>
        <div style={styles.imgContainer}>
          <img
            alt=""
            src={ohPhireLineQRCode}
            style={styles.img}
          />
        </div>
        <div style={styles.help}>立即掃描，小幫手將馬上為你協助!</div>
      </div>
    </div>
  );


  return (
    <InfoModalComponent
      isVisible={isVisible}
      content={content}
      onModalClose={onModalClose}
      containerStyle={{ paddingTop: 100 }}
    />
  );
};

ProductIssueReportModalComponentContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
};
export default ProductIssueReportModalComponentContainer;
