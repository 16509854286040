// The unit is 4 days
module.exports = {
  getRentBagOwnerEarnAmount: (depositOrigin) => {
    if (depositOrigin > 200000) {
      return Math.ceil(depositOrigin * 0.015 * 0.6);
    }

    if (depositOrigin > 160000) {
      return 2160;
    }

    if (depositOrigin > 130000) {
      return 1740;
    }
    if (depositOrigin > 100000) {
      return 1380;
    }

    if (depositOrigin > 70000) {
      return 1275;
    }

    if (depositOrigin > 50000) {
      return 900;
    }

    if (depositOrigin > 30000) {
      return 600;
    }

    if (depositOrigin === 0) return 0;

    return 225;
  },
};
