import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Redirect } from '@reach/router';
import { connect } from 'react-redux';
import * as R from 'ramda';
import Paths from '../../../../routes/Paths';
import ActionCreators from '../../ActionCreator';
import LoginPage from './LoginPage';
import { writeData, readData } from '../../../../utils/LocalStorageUtils';
import LocalStorageKey from '../../../../constants/LocalStorageKey';
import ForgotPasswordPageContainer from '../ForgotPasswordPage';
import RegisterPageContainer from '../RegisterPage';


import {
  reduxStatusSelector,
  reduxErrorSelector,
  isLogInSelector,
} from '../../selector';

const LoginPageContainer = ({
  reduxStatus,
  reduxError,
  isLogin,
  actions: {
    resetStatus,
    logInFlowRequest,
    fbLogInFlowRequest,
    sendSmsFlowRequest,
    smsLoginFlowRequest,
  },
  setIsShowLoginModal,
}) => {
  const [isShowForgotPasswordPage, setIsShowForgotPasswordPage] = useState(false);
  const [isShowRegisterPage, setIsShowRegisterPage] = useState(false);


  useEffect(() => {
    resetStatus();
    return resetStatus;
  }, []);

  const rememberedEmail = readData(LocalStorageKey.EMAIL);

  const handleLogin = (data) => {
    const { isRememberEmail, email } = data;
    writeData(LocalStorageKey.EMAIL, isRememberEmail && !R.isEmpty(email) ? email : '');
    logInFlowRequest(data);
  };

  const onSendSms = (phone, email) => {
    if (R.isEmpty(phone)) {
      return;
    }

    sendSmsFlowRequest({ phone, email });
  };

  const onSmsLogin = (phone, verificationCode) => {
    if (R.isEmpty(verificationCode)) {
      return;
    }

    smsLoginFlowRequest({ phone, verificationCode });
  };

  const responseFacebook = (response) => {
    fbLogInFlowRequest(response);
  };


  if (isLogin) {
    setIsShowLoginModal(false);
    return <Redirect to={Paths.MEMBER} noThrow />;
  }

  if (isShowForgotPasswordPage) {
    return (
      <ForgotPasswordPageContainer
        setIsShowForgotPasswordPage={setIsShowForgotPasswordPage}
      />
    );
  }
  if (isShowRegisterPage) {
    return (
      <RegisterPageContainer
        setIsShowRegisterPage={setIsShowRegisterPage}
      />
    );
  }

  return (
    <LoginPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      rememberedEmail={rememberedEmail}
      handleLogin={handleLogin}
      responseFacebook={responseFacebook}
      onSendSms={onSendSms}
      onSmsLogin={onSmsLogin}
      setIsShowForgotPasswordPage={setIsShowForgotPasswordPage}
      setIsShowRegisterPage={setIsShowRegisterPage}
    />
  );
};

LoginPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  isLogin: PropTypes.bool.isRequired,
  setIsShowLoginModal: PropTypes.func,
  actions: PropTypes.shape({
    logInFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    fbLogInFlowRequest: PropTypes.func.isRequired,
    sendSmsFlowRequest: PropTypes.func.isRequired,
    smsLoginFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
};

LoginPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  setIsShowLoginModal: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  isLogin: isLogInSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPageContainer);
