import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import API from './API';

import {
  memberIdSelector,
} from '../Member/selector';

const {
  getCartListFlowRequest,
  getCartListFlowSuccess,
  getCartListFlowFailure,
  getCartListRequest,
  getCartListSuccess,
  getCartListFailure,

  deleteCartFlowRequest,
  deleteCartFlowSuccess,
  deleteCartFlowFailure,
  deleteCartRequest,
  deleteCartSuccess,
  deleteCartFailure,

  addCartFlowRequest,
  addCartFlowSuccess,
  addCartFlowFailure,
  addCartRequest,
  addCartSuccess,
  addCartFailure,

} = ActionCreator;

// ---------- TASKS ----------
export function* getCartList() {
  try {
    yield put(getCartListRequest());
    const result = yield call(API.getCartList, {
      where: {
        userId: yield select(memberIdSelector),
      },
      include: [
        {
          relation: 'bag',
          scope: {
            include: [
              {
                relation: 'frontPic',
              },
              {
                relation: 'type',
              },
              {
                relation: 'bagStatus',
              },
            ],
          },
        },
      ],
    });
    yield put(getCartListSuccess(result.data));
  } catch (error) {
    yield put(getCartListFailure(error));
  }
}

export function* deleteCart(cartId) {
  try {
    yield put(deleteCartRequest());
    const result = yield call(API.deleteCart, cartId);
    yield put(deleteCartSuccess());
    return result;
  } catch (error) {
    yield put(deleteCartFailure(error));
    throw error;
  }
}

export function* addCart(bagId) {
  try {
    yield put(addCartRequest());
    const result = yield call(API.addToCart, {
      userId: yield select(memberIdSelector),
      bagId,
    });
    yield put(addCartSuccess());
    return result;
  } catch (error) {
    yield put(addCartFailure(error));
    throw error;
  }
}

// Flow
export function* getCartListFlow() {
  try {
    const result = yield call(getCartList);
    yield put(getCartListFlowSuccess(result));
  } catch (error) {
    yield put(getCartListFlowFailure(error));
  }
}

export function* deleteCartFlow({ payload }) {
  try {
    const result = yield call(deleteCart, payload);
    if (result.status === 200) {
      yield call(getCartList);
    }

    yield put(deleteCartFlowSuccess(result));
  } catch (error) {
    yield put(deleteCartFlowFailure(error));
  }
}

export function* addCartFlow({ payload }) {
  try {
    const result = yield call(addCart, payload);


    if (result.status === 200) {
      yield call(getCartList);
    }

    yield put(addCartFlowSuccess(result));
  } catch (error) {
    yield put(addCartFlowFailure(error));
  }
}

export default [
  takeLatest(getCartListFlowRequest, getCartListFlow),
  takeLatest(deleteCartFlowRequest, deleteCartFlow),
  takeLatest(addCartFlowRequest, addCartFlow),
];
