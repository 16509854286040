import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Paths from '../../../../routes/Paths';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';

const styles = {
  mainContainer: {
    margin: 20,
  },
  themeItem: {
    width: 500,
    margin: '50px auto',
    height: 300,
    position: 'relative',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  text: {
    position: 'absolute',
    left: -20,
    top: -20,
    padding: 10,
    fontSize: '30px',
    color: 'white',
    backgroundColor: 'black',
  },
};

const ThemeListPage = ({
  reduxStatus,
  reduxError,
  data,
}) => (
  <div style={styles.mainContainer} key="baseContainer">
    <Link to={Paths.HOME}>回首頁</Link>
    <hr />
    <h2>主題活動列表</h2>
    <ReduxStatusVisualizedComponent
      reduxStatus={reduxStatus}
      showModalOnError
      modalFailureContent={reduxError}
    >
      <div>
        {(data || []).map((theme) => {
          const {
            name,
            id,
            resource: {
              uri = '',
            } = {},
          } = theme;
          return (
            <Link to={`${Paths.THEME}/${id}`} key={id}>
              <div style={styles.themeItem}>
                <img src={uri} style={styles.image} alt="" />
                <span style={styles.text}>{name}</span>
              </div>
            </Link>
          );
        })}

      </div>
    </ReduxStatusVisualizedComponent>
  </div>
);

ThemeListPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array.isRequired,
};

ThemeListPage.defaultProps = {
  reduxError: null,
};

export default ThemeListPage;
