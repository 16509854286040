import api from '../../lib/configAxios';

export default {
  getBagList: async (filter = { order: 'releaseDate DESC' }) => api.get(
    `Bags?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  notifyWhenArrived: async data => api.put(
    `Users/me/bagArrived/rel/${data.bagId}`,
  ),

  submitBag: async data => api.post(
    'Bags', data,
  ),

  addBagTag: async (data) => {
    const { bagId, tagId } = data;
    return api.put(
      `Bags/${bagId}/tags/rel/${tagId}`,
    );
  },

  getCommentList: async ({ bagId, filter }) => api.get(
    `Bags/${bagId}/comments?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  getBagReleaseDate: async bagId => api.get(
    `Bags/${bagId}/releaseDate`,
  ),

  getBagIdByBagNo: async data => api.post(
    'Bags/getBagIdByBagNo', data,
  ),

  getBagRentDateList: async ({ bagId }) => api.get(
    `Bags/${bagId}/rentDateList`,
  ),

  searchByKeyword: async filter => api.get(
    `Bags?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  getBagNumbers: async filter => api.get(
    `Bags/count?where=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

};
