import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';
import * as R from 'ramda';
import Paths from '../../../../routes/Paths';
import SubmitBagPage from './SubmitBagPage';
import SubmitCommodityPage from './SubmitCommodityPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
} from '../../selector';
import {
  isLogInSelector,
  memberInfoSelector,
} from '../../../Member/selector';
import ActionCreator from '../../ActionCreator';
import MemberActionCreators from '../../../Member/ActionCreator';
import OnlineBagActionCreators from '../../../OnlineBag/ActionCreator';
import { categorizedTagListSelector } from '../../../Tag/selector';
import TagActionCreators from '../../../Tag/ActionCreator';
import * as LocalStorageUtils from '../../../../utils/LocalStorageUtils';
import LocalStorageKey from '../../../../constants/LocalStorageKey';

const SubmitBagContainer = ({
  reduxStatus,
  reduxError,
  isLogin,
  tags,
  actions: {
    getTagFlowRequest,
    resetStatus,
    submitBagFlowRequest,
    updateMemberInfoFlowRequest,
    updateBagInfoFlowRequest,
  },
  memberData,
  isEdit,
}) => {
  const [recipientName, setRecipientName] = useState(R.pathOr('', ['recipientName'], memberData));
  const [recipientPhone, setRecipientPhone] = useState(R.pathOr('', ['recipientPhone'], memberData));
  const [recipientAddress, setRecipientAddress] = useState(R.pathOr('', ['recipientAddress'], memberData));
  const [sevenElevenAddress, setSevenElevenAddress] = useState(R.pathOr('', ['sevenElevenAddress'], memberData));
  const [sevenElevenMarket, setSevenElevenMarket] = useState(R.pathOr('', ['sevenElevenMarket'], memberData));
  const [isPostReceive, setIsPostReceive] = useState(R.pathOr(false, ['isPostReceive'], memberData));
  const [isSevenElevenReceive, setIsSevenElevenReceive] = useState(R.pathOr(false, ['isSevenElevenReceive'], memberData));
  const [selectStore, setSelectStore] = useState(R.pathOr('', ['selectStore'], memberData));
  const [newPageVersion, setNewPageVersion] = useState(true);
  const [isEditSend, setIsEditSend] = useState(false);

  useEffect(() => {
    getTagFlowRequest();
  }, []);

  useEffect(() => {
    resetStatus();
    return resetStatus;
  }, []);

  const onResetStatus = () => {
    resetStatus();
  };

  if (!isLogin) return <Redirect to={Paths.MEMBER} noThrow />;

  const onWriteDraft = (uploadBags, isDraft) => {
    if (!isDraft) {
      const preData = LocalStorageUtils.readData(LocalStorageKey.DRAFT_OF_BAG, []);
      preData.push(...uploadBags);
      LocalStorageUtils.writeData(LocalStorageKey.DRAFT_OF_BAG, preData);
      return;
    }

    LocalStorageUtils.writeData(LocalStorageKey.DRAFT_OF_BAG, uploadBags);
  };

  const draftOfBags = LocalStorageUtils.readData(LocalStorageKey.DRAFT_OF_BAG, []);
  const editData = LocalStorageUtils.readData(LocalStorageKey.EDIT_COMMODITY, []);

  const handleOnMemberValueChanged = (key, event) => {
    switch (key) {
      case 'recipientName': setRecipientName(event.target.value);
        break;
      case 'recipientPhone': setRecipientPhone(event.target.value);
        break;
      case 'recipientAddress': setRecipientAddress(event.target.value);
        break;
      case 'sevenElevenAddress': setSevenElevenAddress(event.target.value);
        break;
      case 'sevenElevenMarket': setSevenElevenMarket(event.target.value);
        break;
      case 'isPostReceive': setIsPostReceive(event.target.value);
        break;
      case 'isSevenElevenReceive': setIsSevenElevenReceive(event.target.value);
        break;
      case 'selectStore': setSelectStore(event.target.value);
        break;
      default:
    }
  };

  const handleOnCommodityChange = (data) => {
    const id = R.pathOr(null, [0, 'id'], data);
    if (!id) return;
    updateBagInfoFlowRequest({
      id,
      ...data[0],
    });

    setIsEditSend(true);
    LocalStorageUtils.writeData(LocalStorageKey.EDIT_COMMODITY, data[0]);
  };

  return (
    newPageVersion ? (
      <SubmitCommodityPage
        resetStatus={onResetStatus}
        draftOfBags={draftOfBags}
        editData={editData}
        isEdit={isEdit}
        tags={tags}
        reduxStatus={reduxStatus}
        reduxError={reduxError}
        submitBag={submitBagFlowRequest}
        updateMemberInfo={updateMemberInfoFlowRequest}
        onWriteDraft={onWriteDraft}
        onMemberValueChanged={handleOnMemberValueChanged}
        recipientName={recipientName}
        recipientPhone={recipientPhone}
        recipientAddress={recipientAddress}
        sevenElevenAddress={sevenElevenAddress}
        sevenElevenMarket={sevenElevenMarket}
        selectStore={selectStore}
        isPostReceive={isPostReceive}
        isSevenElevenReceive={isSevenElevenReceive}
        updateIsPostReceive={setIsPostReceive}
        updateIsSevenElevenReceive={setIsSevenElevenReceive}
        setNewPageVersion={setNewPageVersion}
        onClickCommodityChange={handleOnCommodityChange}
        setIsEditSend={setIsEditSend}
        isEditSend={isEditSend}
      />
    ) : (
      <SubmitBagPage
        resetStatus={onResetStatus}
        draftOfBags={draftOfBags}
        tags={tags}
        reduxStatus={reduxStatus}
        reduxError={reduxError}
        submitBag={submitBagFlowRequest}
        updateMemberInfo={updateMemberInfoFlowRequest}
        onWriteDraft={onWriteDraft}
        onMemberValueChanged={handleOnMemberValueChanged}
        recipientName={recipientName}
        recipientPhone={recipientPhone}
        recipientAddress={recipientAddress}
        sevenElevenAddress={sevenElevenAddress}
        sevenElevenMarket={sevenElevenMarket}
        selectStore={selectStore}
        isPostReceive={isSevenElevenReceive}
        isSevenElevenReceive={isSevenElevenReceive}
        updateIsPostReceive={setIsPostReceive}
        updateIsSevenElevenReceive={setIsSevenElevenReceive}
        setNewPageVersion={setNewPageVersion}
        setIsEditSend={setIsEditSend}
        isEditSend={isEditSend}
      />
    )
  );
};

SubmitBagContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  isLogin: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    resetStatus: PropTypes.func.isRequired,
    submitBagFlowRequest: PropTypes.func.isRequired,
    getTagFlowRequest: PropTypes.func.isRequired,
    updateMemberInfoFlowRequest: PropTypes.func.isRequired,
    updateBagInfoFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  isEdit: PropTypes.bool,
  tags: PropTypes.array,
  memberData: PropTypes.object,
};

SubmitBagContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  isEdit: false,
  tags: [],
  memberData: {},
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  isLogin: isLogInSelector(state),
  tags: categorizedTagListSelector(state),
  memberData: memberInfoSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...TagActionCreators,
    ...MemberActionCreators,
    ...OnlineBagActionCreators,
    ...ActionCreator,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmitBagContainer);
