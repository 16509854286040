import api from '../../lib/configAxios';

export default {
  getBagList: async (filter = { limit: 10, order: 'releaseDate DESC' }) => api.get(
    `Bags?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
  getMaqueeList: async () => api.get(
    '/Marquees',
  ),
};
