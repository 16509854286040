import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import update from 'immutability-helper';
import moment from 'moment';
import * as R from 'ramda';
import QueryString from 'query-string';
import ReduxStatus from '../../../../constants/ReduxStatus';
import { memberIdSelector, memberInfoSelector } from '../../../Member/selector';
import ActionCreators from '../../ActionCreator';
import MemberActionCreators from '../../../Member/ActionCreator';
import OrderCreatePage from './OrderCreatePage';
import Path from '../../../../routes/Paths';
import BagActionCreators from '../../../Bag/ActionCreator';
import { receiptTypeId } from '../../../../constants/ReceiptType';
import { rentAmountCalculate } from '../../../../utils/rentAmountCalculate';
import { shippingCost } from '../../../../constants/ShippingAmount';
import { shippingTypeId } from '../../../../constants/ShippingType';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import * as LocalStorageUtils from '../../../../utils/LocalStorageUtils';
import LocalStorageKey from '../../../../constants/LocalStorageKey';
import {
  orderCreateReduxStatusSelector,
  orderCreateErrorSelector,
  getDonateOrganizationDataSelector,
} from '../../selector';

import {
  bagListSelector,
  getRentDateListSelector,
} from '../../../Bag/selector';

const styles = {
  dialogText: {
    margin: '30px auto',
  },
  tableContainerStyle: {
    width: '100%',
    padding: '0px 20px',
  },
};

const OrderCreateContainer = ({
  orderCreateReduxStatus,
  actions,
  bagId,
  bagInfoData,
  userId,
  donateOrganizations,
  orderCreateReduxError,
  memberData,
  location,
  commodityType,
  rentDateList,
}) => {
  const orderType = R.pathOr(null, ['type'], QueryString.parse(R.pathOr(null, ['search'], location)));
  const orderCreateInfo = JSON.parse(LocalStorageUtils.readData(LocalStorageKey.ORDER_CREATE_INFO));
  const rentStartDate = R.pathOr(null, ['fromDate'], orderCreateInfo);
  const rentEndDate = R.pathOr(null, ['endDate'], orderCreateInfo);
  const rentDays = R.isEmpty(orderCreateInfo) ? 0 : moment(rentEndDate).diff(rentStartDate, 'days') + 1;
  const isShowOrderCreateFailModal = R.isNil(orderType) || (orderType === 'rent' && R.isNil(orderCreateInfo));
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [dialogInfo, setShowDialogInfo] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [recipientName, setRecipientName] = useState(R.pathOr('', ['recipientName'], memberData));
  const [shippingType, setShippingType] = useState(null);
  const [recipientPhone, setRecipientPhone] = useState(R.pathOr('', ['recipientPhone'], memberData));
  const [recipientZipCode, setRecipientZipCode] = useState(R.pathOr('', ['recipientZipCode'], memberData));
  const [recipientAddress, setRecipientAddress] = useState(R.pathOr('', ['recipientAddress'], memberData));
  const [note, setNote] = useState('');
  const [receiptType, setReceiptType] = useState('1');
  const [eReceiptAddress, setEReceiptAddress] = useState('');
  const [phoneBarCode, setPhoneBarCode] = useState('');
  const [taxId, setTaxId] = useState('');
  const [receiptHead, setReceiptHead] = useState('');
  const [taxReceiptAddress, setTaxReceiptAddress] = useState('');
  const [donateOrganizationList, setDonateOrganizationList] = useState([]);
  const [selectDonateOrganization, setSelectDonateOrganization] = useState(null);
  const [bagData, setBagData] = useState(null);
  const [amount, setAmount] = useState(0);
  const [valueDeclarationAmount, setValueDeclarationAmount] = useState(0);
  const [isUpdateMemberInfo, setIsUpdateMemberInfo] = useState(true);
  const [isAutoInputRecipient, setIsAutoInputRecipient] = useState(true);
  const washAmount = R.pathOr(0, ['washAmount'], bagData);
  const rentPrice = R.pathOr(0, ['rentAmountPerDay'], bagData);
  const buyoutPrice = R.pathOr(0, ['buyoutAmount'], bagData);
  const [instalment, setInstalment] = useState(0);
  const [payWayType, setPayWayType] = useState(0); // 0 信用卡 , 1 中租 , 2 Appple
  const [zeroCardInstallment, setZeroCardInstallment] = useState(0);
  const data = bagInfoData;
  const [selectedMemberCoupon, setSelectedMemberCoupon] = useState(null);
  const [sevenElevenAddress, setSevenElevenAddress] = useState(R.pathOr('', ['sevenElevenAddress'], memberData));
  const [sevenElevenMarket, setSevenElevenMarket] = useState(R.pathOr('', ['sevenElevenMarket'], memberData));
  const [inputCouponText, setInputCouponText] = useState('');

  useEffect(() => {
    actions.getDonateOrganizationFlowRequest();
  }, []);

  useEffect(() => {
    if (bagId !== null) {
      actions.getBagRentDateListFlowRequest(bagId);
    }
  }, [bagId]);


  useEffect(() => {
    if (donateOrganizations != null) {
      setDonateOrganizationList(update(donateOrganizationList, { $push: [...donateOrganizations] }));
    }
  }, [donateOrganizations]);

  const calculateAmount = (type, rentAmountPerDay, desiredRentDays, buyoutAmount, washPrice) => {
    if (type !== 'buy') {
      return rentAmountCalculate(rentAmountPerDay, desiredRentDays, false, washPrice);
    }
    return Number(buyoutAmount);
  };

  useEffect(() => {
    if (!bagData || bagData.id !== bagId) {
      const foundBagData = (data || []).find(bag => `${bag.id}` === `${bagId}`);
      if (!R.isNil(foundBagData)) {
        setValueDeclarationAmount(R.pathOr(0, ['deposit'], foundBagData) * 0.016);

        setAmount(calculateAmount(
          orderType,
          foundBagData.rentAmountPerDay,
          rentDays,
          foundBagData.buyoutAmount,
          foundBagData.washAmount,
        ));
      }
      setBagData(foundBagData);
    }
  }, [data]);

  useEffect(() => {
    actions.getBagListFlowRequest({
      where: {
        id: bagId,
      },
      commodityType,
    });

    return actions.resetStatus;
  }, []);

  const handleOnValueChanged = (key, event) => {
    switch (key) {
      case 'recipientName': setRecipientName(event.target.value);
        break;
      case 'recipientPhone': setRecipientPhone(event.target.value);
        break;
      case 'recipientZipCode': setRecipientZipCode(event.target.value);
        break;
      case 'recipientAddress': setRecipientAddress(event.target.value);
        break;
      case 'note': setNote(event.target.value);
        break;
      case 'receiptType': setReceiptType(event.target.value);
        break;
      case 'eReceiptAddress': setEReceiptAddress(event.target.value);
        break;
      case 'phoneBarCode': setPhoneBarCode(event.target.value);
        break;
      case 'donateOrganization': setSelectDonateOrganization(event.target.value);
        break;
      case 'taxId': setTaxId(event.target.value);
        break;
      case 'receiptHead': setReceiptHead(event.target.value);
        break;
      case 'taxReceiptAddress': setTaxReceiptAddress(event.target.value);
        break;
      case 'sevenElevenAddress': setSevenElevenAddress(event.target.value);
        break;
      case 'sevenElevenMarket': setSevenElevenMarket(event.target.value);
        break;
      case 'shippingType': {
        setShippingType(event.target.value);
        const selectValue = event.target.value;
        // Due to default shipping way is post, so select post is 0
        const shippingAmount = selectValue === shippingTypeId.blackCat ? shippingCost.blackCat : 0;

        setAmount(calculateAmount(
          orderType,
          rentPrice,
          rentDays,
          buyoutPrice,
          washAmount,
        ) + shippingAmount);

        break; }
      default:
    }
  };

  const handleOnSubmitOrderCreate = () => {
    const showDialog = () => {
      setIsShowDialog(true);
      setShowDialogInfo('*欄位為必填請確認。');
      // GTag implant
      window.gtag_report_conversion();
    };

    if (!recipientName || !recipientPhone || !shippingType
      || (receiptType === '1' && !eReceiptAddress)
      || (receiptType === '2' && !phoneBarCode)
      || (receiptType === '4' && !taxReceiptAddress)) {
      showDialog();
      return;
    }

    if (shippingType === shippingTypeId.postOffice && !recipientAddress) {
      showDialog();
      return;
    }

    if (shippingType === shippingTypeId.sevenEleven && (!sevenElevenAddress || !sevenElevenMarket)) {
      showDialog();
      return;
    }

    actions.orderCreateFlowRequest({
      orderType,
      amount,
      note,
      recipientName,
      shippingType,
      recipientPhone,
      recipientZipCode,
      recipientAddress,
      rentDays: R.pathOr('', ['type', 'name'], bagData) !== 'buy' ? Number(rentDays) : null,
      rentStartDate: R.pathOr('', ['type', 'name'], bagData) !== 'buy' ? rentStartDate : null,
      eReceiptAddress: receiptType === receiptTypeId.eReceipt ? eReceiptAddress : '',
      phoneBarCode: receiptType === receiptTypeId.eReceiptMobile ? phoneBarCode : '',
      donateOrganizationId: (receiptType === receiptTypeId.donateReceipt) && (selectDonateOrganization !== 0)
        ? selectDonateOrganization : '',
      taxId: receiptType === receiptTypeId.taxIdReceipt ? taxId : '',
      receiptHead: receiptType === receiptTypeId.taxIdReceipt ? receiptHead : '',
      taxReceiptAddress: receiptType === receiptTypeId.taxIdReceipt ? taxReceiptAddress : '',
      userId,
      bagId,
      receiptTypeId: receiptType,
      instalment,
      commodityTypeId: R.pathOr(0, ['commodityTypeId'], bagData),
      userCouponId: R.pathOr(null, ['id'], selectedMemberCoupon),
      sevenElevenAddress,
      sevenElevenMarket,
      zeroCardInstallment,
      payWayType,
    });

    if (isUpdateMemberInfo) {
      actions.updateMemberInfoFlowRequest({
        recipientName,
        recipientPhone,
        recipientZipCode,
        recipientAddress,
      });
    }
  };

  const handleOnDialogConfirm = () => {
    setIsShowDialog(false);
    setShowDialogInfo('');

    if (orderCreateReduxStatus === ReduxStatus.STATUS_NORMAL) {
      actions.resetOrderCreateStatus();
      setShouldRedirect(true);
    }
  };

  const handleOnIsUpdateMemberInfoChanged = (isUpdate) => {
    setIsUpdateMemberInfo(isUpdate);
  };

  const handleOnIsAutoInputRecipientChanged = (isAutoInput) => {
    setIsAutoInputRecipient(isAutoInput);

    if (isAutoInput) {
      setRecipientName(R.pathOr('', ['recipientName'], memberData));
      setRecipientPhone(R.pathOr('', ['recipientPhone'], memberData));
      setRecipientZipCode(R.pathOr('', ['recipientZipCode'], memberData));
      setRecipientAddress(R.pathOr('', ['recipientAddress'], memberData));
      setSevenElevenAddress(R.pathOr('', ['sevenElevenAddress'], memberData));
      setSevenElevenMarket(R.pathOr('', ['sevenElevenMarket'], memberData));
    }
  };

  const handleOnClearSelectedMemberCoupon = () => {
    setSelectedMemberCoupon(null);
    setInputCouponText('');
  };

  useEffect(() => {
    if (orderCreateReduxStatus === ReduxStatus.STATUS_NORMAL) {
      setIsShowDialog(true);
      setShowDialogInfo(
        <div style={styles.dialogText}>
          你的訂單已成立，系統將保留此商品15分鐘
          <br />
          至 我的訂單 完成付款即完成租借
        </div>,
      );
    } else if (orderCreateReduxStatus === ReduxStatus.STATUS_ERROR) {
      setShowDialogInfo(orderCreateReduxError);
    } else if (orderCreateReduxStatus === ReduxStatus.STATUS_INITIAL) {
      setDonateOrganizationList(update(donateOrganizationList, { $push: [{ name: '不指定', id: 0 }] }));
      actions.resetDonateOrganization();
    }
  }, [orderCreateReduxStatus]);

  const handleOnOrderCreateFailConfirm = () => {
    window.history.back();
  };

  const handleOnSelectedMemberCoupon = (memberCoupon) => {
    const couponCode = R.pathOr(null, ['coupon', 'couponCode'], memberCoupon);
    setSelectedMemberCoupon(memberCoupon);

    if (couponCode && couponCode !== inputCouponText) {
      setInputCouponText(couponCode);
    }
  };

  return (
    shouldRedirect
      ? <Redirect to={Path.ORDER_LIST} noThrow />
      : (
        <React.Fragment>
          <Default>
            <OrderCreatePage
              orderCreateReduxStatus={orderCreateReduxStatus}
              isShowDialog={isShowDialog}
              dialogInfo={dialogInfo}
              orderCreateReduxError={orderCreateReduxError}
              recipientName={recipientName}
              recipientPhone={recipientPhone}
              recipientZipCode={recipientZipCode}
              recipientAddress={recipientAddress}
              rentDays={rentDays}
              rentStartDate={rentStartDate}
              rentEndDate={rentEndDate}
              note={note}
              receiptType={receiptType}
              eReceiptAddress={eReceiptAddress}
              phoneBarCode={phoneBarCode}
              donateOrganizationList={donateOrganizationList}
              taxId={taxId}
              receiptHead={receiptHead}
              taxReceiptAddress={taxReceiptAddress}
              bagData={bagData}
              amount={amount}
              valueDeclarationAmount={valueDeclarationAmount}
              onDialogConfirm={handleOnDialogConfirm}
              onValueChanged={handleOnValueChanged}
              onSubmitOrderCreate={handleOnSubmitOrderCreate}
              setEReceiptAddress={setEReceiptAddress}
              isUpdateMemberInfo={isUpdateMemberInfo}
              onUpdateMemberInfo={handleOnIsUpdateMemberInfoChanged}
              isAutoInputRecipient={isAutoInputRecipient}
              onAutoInputRecipientChanged={handleOnIsAutoInputRecipientChanged}
              onInstalmentChanged={setInstalment}
              onPayWayTypeChanged={setPayWayType}
              onZeroCardInstallmentChanged={setZeroCardInstallment}
              isShowOrderCreateFailModal={isShowOrderCreateFailModal}
              onOrderCreateFailConfirm={handleOnOrderCreateFailConfirm}
              onSelectedMemberCoupon={handleOnSelectedMemberCoupon}
              orderType={orderType}
              shippingType={shippingType}
              selectedMemberCoupon={selectedMemberCoupon}
              clearSelectedMemberCoupon={handleOnClearSelectedMemberCoupon}
              sevenElevenMarket={sevenElevenMarket}
              sevenElevenAddress={sevenElevenAddress}
              handleInputCouponText={setInputCouponText}
              inputCouponText={inputCouponText}
              rentDateList={rentDateList}
              instalment={instalment}
              zeroCardInstallment={zeroCardInstallment}
            />
          </Default>
          <Mobile>
            <OrderCreatePage
              orderCreateReduxStatus={orderCreateReduxStatus}
              isShowDialog={isShowDialog}
              dialogInfo={dialogInfo}
              orderCreateReduxError={orderCreateReduxError}
              recipientName={recipientName}
              recipientPhone={recipientPhone}
              recipientZipCode={recipientZipCode}
              recipientAddress={recipientAddress}
              rentDays={rentDays}
              rentStartDate={rentStartDate}
              rentEndDate={rentEndDate}
              note={note}
              receiptType={receiptType}
              eReceiptAddress={eReceiptAddress}
              phoneBarCode={phoneBarCode}
              donateOrganizationList={donateOrganizationList}
              taxId={taxId}
              receiptHead={receiptHead}
              taxReceiptAddress={taxReceiptAddress}
              bagData={bagData}
              amount={amount}
              valueDeclarationAmount={valueDeclarationAmount}
              onDialogConfirm={handleOnDialogConfirm}
              onValueChanged={handleOnValueChanged}
              onSubmitOrderCreate={handleOnSubmitOrderCreate}
              setEReceiptAddress={setEReceiptAddress}
              tableContainerStyle={styles.tableContainerStyle}
              isUpdateMemberInfo={isUpdateMemberInfo}
              onUpdateMemberInfo={handleOnIsUpdateMemberInfoChanged}
              isAutoInputRecipient={isAutoInputRecipient}
              onAutoInputRecipientChanged={handleOnIsAutoInputRecipientChanged}
              onInstalmentChanged={setInstalment}
              onPayWayTypeChanged={setPayWayType}
              isShowOrderCreateFailModal={isShowOrderCreateFailModal}
              onOrderCreateFailConfirm={handleOnOrderCreateFailConfirm}
              onSelectedMemberCoupon={handleOnSelectedMemberCoupon}
              orderType={orderType}
              selectedMemberCoupon={selectedMemberCoupon}
              clearSelectedMemberCoupon={handleOnClearSelectedMemberCoupon}
              shippingType={shippingType}
              sevenElevenMarket={sevenElevenMarket}
              sevenElevenAddress={sevenElevenAddress}
              handleInputCouponText={setInputCouponText}
              inputCouponText={inputCouponText}
              rentDateList={rentDateList}
              instalment={instalment}

            />
          </Mobile>
        </React.Fragment>
      )
  );
};

OrderCreateContainer.propTypes = {
  orderCreateReduxStatus: PropTypes.string,
  orderCreateReduxError: PropTypes.string,
  actions: PropTypes.shape({
    orderCreateFlowRequest: PropTypes.func.isRequired,
    getDonateOrganizationFlowRequest: PropTypes.func.isRequired,
    resetOrderCreateStatus: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    getBagListFlowRequest: PropTypes.func.isRequired,
    resetDonateOrganization: PropTypes.func.isRequired,
    updateMemberInfoFlowRequest: PropTypes.func.isRequired,
    getClothesListFlowRequest: PropTypes.func.isRequired,
    getBagRentDateListFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  userId: PropTypes.number,
  bagId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  donateOrganizations: PropTypes.array,
  bagInfoData: PropTypes.array,
  memberData: PropTypes.object,
  location: PropTypes.object,
  commodityType: PropTypes.number.isRequired,
  rentDateList: PropTypes.array,
};

OrderCreateContainer.defaultProps = {
  orderCreateReduxStatus: null,
  orderCreateReduxError: null,
  userId: '',
  donateOrganizations: [],
  memberData: {},
  location: {},
  bagInfoData: [],
  rentDateList: [],
};

const mapStateToProps = state => ({
  orderCreateReduxStatus: orderCreateReduxStatusSelector(state),
  userId: memberIdSelector(state),
  orderCreateReduxError: orderCreateErrorSelector(state),
  donateOrganizations: getDonateOrganizationDataSelector(state),
  bagInfoData: bagListSelector(state),
  memberData: memberInfoSelector(state),
  rentDateList: getRentDateListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...MemberActionCreators,
    ...BagActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderCreateContainer);
