import React from 'react';
import PropTypes from 'prop-types';
import InfoModalComponent from '../InfoModalComponent';
import Default from '../RWDComponent/Default';
import Mobile from '../RWDComponent/Mobile';
import Assets from '../../constants/Assets';

const styles = {
  mainContainer: {
    width: 'fit-content',
    height: 'auto',
  },
  mainMobileContainer: {
    width: '85vw',
    height: 'auto',
  },
  img: {
    width: '100%',
    height: 'auto',
  },
};

const KnowMoreBuyBagFlowComponent = ({
  isVisible,
  onModalClose,
}) => {
  const mainContent = (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          <img src={Assets.badDetailKnowMoreBuying} style={styles.img} alt="" />
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          <img src={Assets.badDetailKnowMoreBuyingMobile} style={styles.img} alt="" />
        </div>
      </Mobile>
    </React.Fragment>
  );

  return (
    <InfoModalComponent
      isVisible={isVisible}
      content={mainContent}
      onModalClose={onModalClose}
    />
  );
};

KnowMoreBuyBagFlowComponent.propTypes = {
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func,
};

KnowMoreBuyBagFlowComponent.defaultProps = {
  isVisible: false,
  onModalClose: () => {},
};

export default KnowMoreBuyBagFlowComponent;
