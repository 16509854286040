import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  getSpecialSelectListRequest,
  getSpecialSelectListSuccess,
  getSpecialSelectListFailure,

  getSpecialSelectListFlowRequest,
  getSpecialSelectListFlowSuccess,
  getSpecialSelectListFlowFailure,
} = ActionCreator;

// ---------- TASKS ----------
export function* getSpecialSelectList() {
  yield put(getSpecialSelectListRequest());
  try {
    const result = (yield call(API.getSpecialSelectList));
    yield put(getSpecialSelectListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getSpecialSelectListFailure(error));
    throw error;
  }
}

// ---------- FLOWS ----------

export function* getSpecialSelectListFlow({ payload }) {
  try {
    const result = yield call(getSpecialSelectList, payload);
    yield put(getSpecialSelectListFlowSuccess(result));
  } catch (error) {
    yield put(getSpecialSelectListFlowFailure(error));
  }
}

export default [
  takeLatest(getSpecialSelectListFlowRequest, getSpecialSelectListFlow),
];
