import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import ReduxStatusVisualizedComponent from '../../../components/ReduxStatusVisualizedComponent';
import ReduxStatus from '../../../constants/ReduxStatus';
import { Colors } from '../../../constants/Color';

const styles = {
  subscribeContainer: {
    color: Colors.footerSubTitle,
    position: 'relative',
  },
  input: {
    outline: 'none',
    border: 'none',
    width: '100%',
    height: '25px',
    margin: '0 10px 0 0px',
    color: 'black',
    padding: '10px',
  },
  message: {
    textAlign: 'center',
    border: '1px #DDDDDD solid',
    margin: '20px',
  },
  submitButton: {
    border: '0px',
    position: 'absolute',
    top: '0',
    height: '24px',
    right: '0',
    outline: 'none',
    cursor: 'pointer',
  },
  icon: {
    color: 'black',
    fontSize: '20px',
    margin: '0 10px 0 5px',
  },
};
const Subscribe = ({
  reduxStatus,
  reduxError,
  onSendSubscribe,
}) => {
  const [emailInput, setEmailInput] = useState('');


  const onSubmit = (event) => {
    event.preventDefault();
    onSendSubscribe({
      email: emailInput,
    });
    setEmailInput('');
  };

  return (
    <div style={styles.subscribeContainer}>
      <ReduxStatusVisualizedComponent
        showModalOnError
        reduxStatus={reduxStatus}
        modalFailureContent={reduxError}
      >
        { reduxStatus === ReduxStatus.STATUS_NORMAL ? (
          <div style={styles.message}>
            已成功訂閱!
          </div>
        ) : null }

        <form onSubmit={onSubmit}>
          <input
            value={emailInput}
            style={styles.input}
            onChange={(event) => { setEmailInput(event.target.value); }}
            placeholder="首次註冊電子報即贈$30優惠折扣碼 Subscribe us now!"
            type="email"
            required
          />
          <button type="submit" style={styles.submitButton}>
            <Icon type="arrow-right" style={styles.Icon} />
          </button>
        </form>

      </ReduxStatusVisualizedComponent>
    </div>
  );
};

Subscribe.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  onSendSubscribe: PropTypes.func.isRequired,
};

Subscribe.defaultProps = {
  reduxStatus: '',
  reduxError: '',
};

export default Subscribe;
