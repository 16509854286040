import React from 'react';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';
import { Colors } from '../../../constants/Color';
import { orderStatus } from '../../../constants/OrderStatus';

const styles = {
  mainContainer: {
    width: '100%',
    height: 'fit-content',
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  stepDivider: {
    margin: '0px 3px 0px 3px',
    height: 1,
    width: 18,
    backgroundColor: '#927764',
  },
  rightMainContainer: {
    height: 'fit-content',
    width: 'fit-content',
  },
  leftMainContainer: {
    height: 'fit-content',
    width: 'fit-content',
    position: 'relative',
  },
  orderCanceledContainer: {
    position: 'absolute',
    height: 10,
    width: 100,
    top: 30,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wholeDisableContainer: {
    height: 'fit-content',
    width: 'fit-content',
    backgroundColor: 'rgba(255,255,255, 0.7)',
  },
  rightDisableContainer: {
    height: 'fit-content',
    width: 'fit-content',
    backgroundColor: 'rgba(255,255,255, 0.7)',
  },
  rightContainer: {
    height: 'fit-content',
    width: 'fit-content',
    backgroundColor: 'rgba(255,255,255, 0.7)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  stepBarContainer: {
    position: 'relative',
    height: 40,
    width: 20,
    backgroundColor: '#927764',
    margin: '0px 3px',
    borderRadius: 10,
  },
  stepBarDisableContainer: {
    position: 'relative',
    height: 40,
    width: 20,
    backgroundColor: '#CAC2B8',
    margin: '0px 3px',
    borderRadius: 10,
  },
  stepOrderConfirm: {
    position: 'absolute',
    top: 3,
    left: 2,
    width: 16,
    height: 16,
    borderRadius: 50,
    backgroundColor: 'white',
  },
  stepOrderCancel: {
    position: 'absolute',
    bottom: 2,
    left: 2,
    width: 16,
    height: 16,
    borderRadius: 50,
    backgroundColor: 'white',
  },
  middleContainer: {
    display: 'flex',
    height: 'flex-content',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  icon: {
    fontSize: 10,
    marginTop: 4,
    color: '#927764',
    margin: '6px 1px',
  },
  returnComplete: {
    fontSize: 14,
    marginTop: 1,
    color: '#927764',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
  },
  buttonEnable: {
    backgroundColor: Colors.orderBrown,
    color: 'white',
    height: '50px',
    borderRadius: '5px',
    maxWidth: '120px',
    minWidth: '60px',
  },
  buttonDisable: {
    border: 'none',
    color: '#aaa',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'not-allowed',
  },
  orderCancel: {
    fontSize: 14,
    marginTop: 1,
    color: '#927764',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
  },
  backedContainer: {
    position: 'absolute',
    right: 0,
    top: 20,
  },
  commentContainer: {
    position: 'absolute',
    top: -20,
    left: 0,
    width: 400,
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
};

const RenterOrderStatusPanel = ({
  order,
  onProductSend,
}) => {
  const status = R.path(['orderStatus', 'name'], order);
  const rentStartDate = R.path(['rentStartDate'], order);
  const enableStepBar = status === orderStatus.confirmed
    || status === orderStatus.renterSent
    || status === orderStatus.lenderReceive
    || status === orderStatus.lenderSent
    || status === orderStatus.canceled;
  const enableConfirmOrder = status === orderStatus.confirmed
    || status === orderStatus.renterSent
    || status === orderStatus.lenderSent
    || status === orderStatus.lenderReceive;
  //   Note: Hidden
  // const enableCancelOrder = moment(new Date()).isBefore(moment(rentStartDate).add(-7, 'days')) || (status === orderStatus.canceled);
  const showCancelOrderHint = status === orderStatus.canceled;
  const enableReturnBag = (moment(new Date()).isAfter(rentStartDate) && (status === orderStatus.confirmed))
    || (status === '租借方歸還')
    || (status === orderStatus.lenderSent)
    || (status === orderStatus.lenderReceive)
    || (status === orderStatus.overdue_notReturn);

  const showReturnedBag = status === orderStatus.lenderReceive;

  return (
    <div style={styles.mainContainer}>
      <div style={styles.leftMainContainer}>
        訂單確認中
        {showCancelOrderHint && (
        <div style={styles.orderCanceledContainer}>
          <Icon type="check-circle" theme="filled" style={styles.icon} />
          <button type="button" style={styles.orderCancel}>訂單已取消</button>
        </div>
        )}
      </div>
      <div style={styles.stepDivider} />
      <div style={styles.rightMainContainer}>
        <div style={styles.rightContainer}>
          <div style={enableStepBar ? styles.stepBarContainer : styles.stepBarDisableContainer}>
            <div style={status === orderStatus.canceled ? styles.stepOrderCancel : styles.stepOrderConfirm} />
          </div>
          <div style={styles.middleContainer}>
            <button
              type="button"
              style={enableConfirmOrder ? styles.buttonEnable : styles.buttonDisable}
            >
              訂單成立
            </button>
            {/* Note: Hidden */}
            {/* <button
              type="button"
              disabled={!enableCancelOrder || (status === orderStatus.canceled)}
              style={enableCancelOrder ? styles.buttonEnable : styles.buttonDisable}
              onClick={() => onCancelOrder()}
            >
              取消訂單
            </button> */}
          </div>
          <div style={styles.stepDivider} />
          <button
            type="button"
            disabled={!enableReturnBag || showReturnedBag}
            style={enableReturnBag ? styles.buttonEnable : styles.buttonDisable}
            onClick={() => onProductSend()}
          >
            我要歸還
          </button>
        </div>
      </div>
    </div>
  );
};

RenterOrderStatusPanel.propTypes = {
  order: PropTypes.object,
  onProductSend: PropTypes.func.isRequired,
};

RenterOrderStatusPanel.defaultProps = {
  order: {},
};

export default RenterOrderStatusPanel;
