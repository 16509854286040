import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as R from 'ramda';
import { Link } from '@reach/router';
import AmountPattern from '../../../../utils/AmountPattern';
import { bagStatusId } from '../../../../constants/BagStatus';
import { rentAmountCalculate } from '../../../../utils/rentAmountCalculate';
import Paths from '../../../../routes/Paths';
import NegotiationComponent from '../../../Negotiation/components/NegotiationComponent';
import bagOrClothesPath from '../../../../utils/bagOrClothesPath';

const UnavailableText = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 230px;
    height: 320px;
    background-color: #0D0D0D88;
    color: white;
    z-index: 1;
    &:after{
      content: ' ';
      position: absolute;
      left: 30px;
      bottom: 60px;
      width: 150px;
      height: 1px;
      background-color: #F1F2F2;
    }
    & > div:first-child {
      font-weight: 600;
      font-size: 20px;
      text-align: left;
      line-height: 30px;
      margin-top: 230px;
      position: absolute;
      left: 30px;
    }
    & > div:nth-child(2) {
      width: 100%;
      font-size: 15px;
      text-align: right;
      padding: 5px 30px;
      margin-top: 300px;
    }
`;

const styles = {
  mainContainer: {
    position: 'relative',
    width: 230,
    height: 320,
    border: '1px solid #eee',
    boxShadow: '4px 4px 3px rgba(20%,20%,40%,0.1)',
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: 10,
    cursor: 'pointer',
  },
  bagImageContainer: {
    position: 'relative',
    height: 200,
    width: '100%',
  },
  bagImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  bagType: {
    position: 'absolute',
    backgroundColor: '#eee',
    color: 'black',
    fontSize: 12,
    width: 'fit-content',
    height: 'fit-content',
    right: 0,
    top: 5,
    padding: '2px 8px',
  },
  bagBrand: {
    color: 'black',
    fontSize: 13,
    fontFamily: 'Baskerville-Old-Facef',
    marginTop: '20px',
  },
  bagName: {
    color: 'black',
    fontSize: 10,
    fontFamily: 'Microsoft JhengHei Bold',
  },
  rentAmount: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Microsoft JhengHei Bold',
  },
  depositOrigin: {
    color: 'black',
    fontSize: 11,
  },
  optionContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 230,
    height: 320,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  inStockSwitch: {
    position: 'absolute',
    top: 10,
    right: 5,
    border: '1px solid #fff',
    borderRadius: 30,
    backgroundColor: 'transparent',
    margin: '5px 0px',
    height: 'fit-content',
    width: 'fit-content',
    padding: '5px 5px 5px 10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer',
    outline: 'none',
    fontSize: 6,
  },
  hoverInStockSwitch: {
    position: 'absolute',
    top: 10,
    right: 5,
    border: '1px solid #fff',
    borderRadius: 30,
    backgroundColor: 'white',
    margin: '5px 0px',
    height: 'fit-content',
    width: 'fit-content',
    padding: '5px 5px 5px 10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'black',
    cursor: 'pointer',
    outline: 'none',
    fontSize: 6,
  },
  normalButton: {
    border: '1px solid #fff',
    borderRadius: 30,
    backgroundColor: 'transparent',
    margin: '6px 0px',
    height: 'fit-content',
    width: 'fit-content',
    padding: '6px 40px',
    color: 'white',
    cursor: 'pointer',
    outline: 'none',
  },
  hoverButton: {
    border: '1px solid #fff',
    borderRadius: 30,
    margin: '6px 0px',
    height: 'fit-content',
    width: 'fit-content',
    padding: '6px 40px',
    cursor: 'pointer',
    outline: 'none',
    color: 'black',
    backgroundColor: 'white',
  },
  inStock: {
    fontSize: 6,
    marginRight: 3,
  },
};


const MyProductItemComponent = ({
  bagData,
  style,
  inStockSwitch,
  memberInfo,
  onClickEditButton,
  onBagEdit,
}) => {
  const [isOptionVisible, setIsOptionVisible] = useState(false);
  const [isHoverEdit, setIsHoverEdit] = useState(false);
  const [isHoverCheckBag, setIsHoverCheckBag] = useState(false);
  const [isHoverNegotiation, setIsHoverNegotiation] = useState(false);
  const [isHoverInStockSwitch, setIsHoverInStockSwitch] = useState(false);
  const [isHoverReStockSwitch, setIsHoverReStockSwitch] = useState(false);
  const [isShowNegotiationModal, setIsShowNegotiationModal] = useState(false);
  const memberId = R.pathOr('', ['id'], memberInfo);
  const washAmount = R.pathOr(0, ['washAmount'], bagData);
  const bagStatus = R.pathOr(0, ['bagStatusId'], bagData);
  const isTerminate = R.path(['bagStatusId'], bagData) === bagStatusId.terminate;
  const selectedBagId = R.pathOr(0, ['id'], bagData);
  const bagNo = R.pathOr('', ['bagNo'], bagData);
  const commodityType = R.pathOr(1, ['commodityTypeId'], bagData);

  const getIsNegotiationWithAdmin = () => {
    if (selectedBagId === null) {
      return false;
    }
    return R.path(['bagStatusId'], selectedBagId) === bagStatusId.pending;
  };

  const getBrandName = () => {
    let name = '';
    const tags = R.pathOr([], ['tags'], bagData);
    tags.forEach((tag) => {
      if (R.path(['tagCategoryId'], tag) === 1 || R.path(['tagCategoryId'], tag) === 4) {
        const { name: tagName } = tag;
        name = tagName;
      }
    });
    return name || '';
  };

  const getTypeName = () => {
    const typeName = R.path(['type', 'name'], bagData);
    if (typeName === 'rent') {
      return '租借';
    }

    if (typeName === 'buy') {
      return '購買';
    }
    return '租借-購買';
  };

  return (
    <React.Fragment>
      <NegotiationComponent
        isVisible={isShowNegotiationModal}
        onModalClose={() => setIsShowNegotiationModal(false)}
        userId={memberId}
        bagId={R.pathOr(0, ['id'], bagData)}
        bagOwnerId={R.pathOr(0, ['owner', 'id'], bagData)}
        isNegotiationWithAdmin={getIsNegotiationWithAdmin()}
      />
      <div
        onMouseEnter={() => setIsOptionVisible(true)}
        onMouseLeave={() => setIsOptionVisible(false)}
        onTouchStart={() => setIsOptionVisible(true)}
        onTouchMove={() => setIsOptionVisible(true)}
        style={{ ...styles.mainContainer, ...style }}
      >
        {
          bagStatus === bagStatusId.sold ? (
            <UnavailableText>
              <div style={styles.sellOutText}>
                已售出
              </div>
            </UnavailableText>
          ) : null
        }

        <div style={styles.bagImageContainer}>
          <img
            alt=""
            style={styles.bagImage}
            src={R.pathOr(null, ['frontPic', 'uri'], bagData)}
          />
          <div style={styles.bagType}>{getTypeName()}</div>
        </div>
        <div style={styles.bagBrand}>{getBrandName()}</div>
        <div style={styles.bagName}>{R.pathOr('', ['name'], bagData)}</div>
        { R.path(['type', 'name'], bagData) !== 'buy'
        && (
          <div style={styles.rentAmount}>
            {`租金$${AmountPattern(rentAmountCalculate(R.path(['rentAmountPerDay'], bagData), 4, false, washAmount))}/4天`}
          </div>
        )}
        {R.path(['type', 'name'], bagData) !== 'rent'
        && (
          <div style={styles.depositOrigin}>
            {`售價$${AmountPattern(R.pathOr(0, ['buyoutAmount'], bagData))}`}
          </div>
        )
        }
        {R.pathOr(0, ['depositOrigin'], bagData) > 0
          ? (
            <div style={styles.depositOrigin}>
              {`押金$${AmountPattern(R.pathOr(0, ['depositOrigin'], bagData))}`}
            </div>
          )
          : null}
        {isOptionVisible && !isTerminate && (
        <div style={styles.optionContainer}>
          { bagNo ? (
            <Link to={`${bagOrClothesPath(commodityType)}/${bagNo}`}>
              <button
                onMouseEnter={() => setIsHoverCheckBag(true)}
                onMouseLeave={() => setIsHoverCheckBag(false)}
                onTouchStart={() => setIsHoverCheckBag(true)}
                onTouchMove={() => setIsHoverCheckBag(true)}
                onTouchEnd={() => setIsHoverCheckBag(false)}
                type="button"
                style={isHoverCheckBag ? styles.hoverButton : styles.normalButton}
              >
                查看商品
              </button>
            </Link>
          ) : null}

          <Link to={Paths.EDIT_COMMODITY}>
            <button
              onClick={() => onClickEditButton(bagData)}
              onMouseEnter={() => setIsHoverEdit(true)}
              onMouseLeave={() => setIsHoverEdit(false)}
              onTouchStart={() => setIsHoverEdit(true)}
              onTouchMove={() => setIsHoverEdit(true)}
              onTouchEnd={() => setIsHoverEdit(false)}
              type="button"
              style={isHoverEdit ? styles.hoverButton : styles.normalButton}
            >
              編輯商品
            </button>
          </Link>
          <button
            onMouseEnter={() => setIsHoverNegotiation(true)}
            onMouseLeave={() => setIsHoverNegotiation(false)}
            onClick={() => setIsShowNegotiationModal(true)}
            onTouchStart={() => setIsHoverNegotiation(true)}
            onTouchMove={() => setIsHoverNegotiation(true)}
            onTouchEnd={() => setIsHoverNegotiation(false)}
            type="button"
            style={isHoverNegotiation ? styles.hoverButton : styles.normalButton}
          >
            議價紀錄
          </button>
          <button
            disabled={isTerminate}
            onClick={() => inStockSwitch()}
            onMouseEnter={() => setIsHoverInStockSwitch(true)}
            onMouseLeave={() => setIsHoverInStockSwitch(false)}
            type="button"
            style={isHoverInStockSwitch ? styles.hoverButton : styles.normalButton}
          >
            停止上架
          </button>
        </div>
        )}
        {
            isTerminate ? (
              <div style={styles.optionContainer}>
                <button
                  onClick={() => { onBagEdit(R.pathOr('', ['id'], bagData), bagStatusId.pending); }}
                  onMouseEnter={() => setIsHoverReStockSwitch(true)}
                  onMouseLeave={() => setIsHoverReStockSwitch(false)}
                  type="button"
                  style={isHoverReStockSwitch ? styles.hoverButton : styles.normalButton}
                >
                  重新上架
                </button>
              </div>
            ) : null
          }
      </div>
    </React.Fragment>
  );
};

MyProductItemComponent.propTypes = {
  bagData: PropTypes.object,
  style: PropTypes.object,
  inStockSwitch: PropTypes.func,
  onClickEditButton: PropTypes.func,
  onBagEdit: PropTypes.func.isRequired,
  memberInfo: PropTypes.shape({}),
};

MyProductItemComponent.defaultProps = {
  bagData: {},
  style: {},
  inStockSwitch: () => {},
  onClickEditButton: () => {},
  memberInfo: {},
};

export default MyProductItemComponent;
