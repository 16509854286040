import React from 'react';
import Privacy from './PrivacyPage';


const PrivacyContainer = () => (
  <Privacy />
);


export default PrivacyContainer;
