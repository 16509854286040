import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('SEND_CONTACT'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('SEND_CONTACT_FLOW'),

  'RESET_STATUS',
  { prefix: 'CONTACT' },
);
