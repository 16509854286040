import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MyProductItemComponent from './MyProductItemComponent';
import {
  bagEditSelectorReduxStatusSelector,
} from '../../selector';

import {
  memberInfoSelector,
} from '../../../Member/selector';

import ActionCreator from '../../ActionCreator';
import BagActionCreator from '../../../Bag/ActionCreator';

const MyProductComponentContainer = ({
  bagData,
  style,
  inStockSwitch,
  memberInfo,
  onClickEditButton,
  actions: {
    bagEditFlowRequest,
  },
}) => {
  const handelBagEdit = (bagId, modifyBagStatusId) => {
    bagEditFlowRequest({
      bagId, modifyBagStatusId,
    });
  };

  return (
    <MyProductItemComponent
      style={style}
      bagData={bagData}
      onBagEdit={handelBagEdit}
      inStockSwitch={inStockSwitch}
      memberInfo={memberInfo}
      onClickEditButton={onClickEditButton}
    />
  );
};

MyProductComponentContainer.propTypes = {
  bagData: PropTypes.object,
  style: PropTypes.object,
  actions: PropTypes.shape({
    bagEditFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  inStockSwitch: PropTypes.func,
  memberInfo: PropTypes.shape({}),
  onClickEditButton: PropTypes.func,
};

MyProductComponentContainer.defaultProps = {
  bagData: {},
  style: {},
  inStockSwitch: () => {},
  memberInfo: {},
  onClickEditButton: () => {},
};

const mapStateToProps = state => ({
  reduxStatus: bagEditSelectorReduxStatusSelector(state),
  memberInfo: memberInfoSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreator,
    ...BagActionCreator,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyProductComponentContainer);
