import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import * as R from 'ramda';
import Type from '../../../../constants/Type';

const styles = {
  mainContainer: {
    margin: 20,
  },
  edit: {
    textAlign: 'center',
    height: 30,
    width: 90,
    cursor: 'pointer',
    outline: 'none',
    color: 'black',
    fontSize: 18,
    border: '1px solid #AE4200',
  },
  delete: {
    marginLeft: 20,
    textAlign: 'center',
    height: 30,
    width: 90,
    cursor: 'pointer',
    outline: 'none',
    color: '#AFAFAF',
    fontSize: 18,
    border: '1px solid #AFAFAF',
  },
  itemContainer: {
    height: 200,
    borderBottom: '1px solid #D1D3D4',
  },
  name: {
    color: 'black',
    fontFamily: 'Myriad Pro Semibold',
    fontSize: 30,
  },
  functionContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  uploaderImg: {
    height: 150,
    margin: 10,
  },
};

const DraftBagComponent = ({
  onEditDraftBag,
  draftBag,
  onRemoveDraftBag,
}) => {
  const renderType = (typeId) => {
    if (typeId === null) {
      return '';
    }
    if (typeId === Type.typeId.buy) {
      return '出售 Sell';
    }
    if (typeId === Type.typeId.rent) {
      return '出借 Lend';
    }
    return '出借/出售 Both';
  };
  return (
    <tr style={styles.itemContainer}>
      <td>
        <div>
          <img alt="" style={styles.uploaderImg} src={R.pathOr('', ['frontPic', 'uri'], draftBag)} />
        </div>
      </td>
      <td style={styles.name}>
        {R.pathOr('', ['name'], draftBag)}
      </td>
      <td style={styles.name}>
        {`${renderType(draftBag.typeId)}`}
      </td>
      <td>
        <div style={styles.functionContainer}>
          <button
            type="button"
            style={styles.edit}
            onClick={onEditDraftBag}
          >
            編輯
          </button>
          <Popconfirm
            onConfirm={() => onRemoveDraftBag(draftBag.uuid)}
            title="是否從草稿移除?"
            okText="Yes"
            cancelText="No"
          >
            <div style={styles.delete}>刪除</div>
          </Popconfirm>
        </div>
      </td>
    </tr>
  );
};

DraftBagComponent.propTypes = {
  draftBag: PropTypes.object,
  onRemoveDraftBag: PropTypes.func.isRequired,
  onEditDraftBag: PropTypes.func.isRequired,
};

DraftBagComponent.defaultProps = {
  draftBag: {},
};

export default DraftBagComponent;
