import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactPixel from 'react-facebook-pixel';
import { Checkbox } from 'antd';
import { Link } from '@reach/router';
import ReduxStatus from '../../../../constants/ReduxStatus';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import Paths from '../../../../routes/Paths';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import Assets from '../../../../constants/Assets';
import { Colors } from '../../../../constants/Color';

const BackToLoadingPage = styled.div`
  cursor: pointer;
  &:hover{
    opacity: 0.5;
    text-decoration:underline;
  }
`;

const styles = {
  mainContainer: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '60vw',
    textAlign: 'center',
  },
  mainMobileContainer: {
    width: '95vw',
    paddingBottom: '30px',
    position: 'relative',
  },
  signUpSuccess: {
    margin: '100px',
    display: 'block',
  },
  form: {
    width: '70%',
  },
  fieldTitle: {
    margin: 10,
  },
  hint: {
    margin: '5px 0px',
    fontSize: 10,
    color: 'grey',
  },
  title: {
    fontSize: 22,
    marginBottom: 18,
    color: 'black',
    fontWeight: 'bold',
  },
  text: {
    lineHeight: 2.4,
    fontSize: 16,
    marginBottom: 18,
    color: 'black',
  },
  input: {
    fontSize: 12,
    width: '100%',
    margin: '12px 0px',
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid #BCBCBC',
    color: 'black',
  },
  textCenter: {
    textAlign: 'center',
    margin: 20,
    fontSize: 16,
  },
  copyright: {
    textAlign: 'center',
    fontWeight: 100,
    color: 'black',
  },
  button: {
    width: '40%',
    height: 40,
    margin: '20px 0px',
    fontSize: 16,
    color: 'white',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: Colors.introRightTitle,
    borderRadius: 30,
    border: '1px solid #A6A4A4',
  },
  buttonMobile: {
    width: '40%',
    height: 50,
    margin: '20px 0px',
    fontSize: 14,
    color: 'white',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: Colors.introRightTitle,
    borderRadius: 30,
    border: '1px solid #A6A4A4',
  },
  rightContainer: {
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 100,
    paddingBottom: 30,
  },
  rightMobileContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 80,
    paddingBottom: 30,
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imgContainer: {
    width: '45%',
    height: 'auto',
  },
  reduxStyle: {
    width: '100%',
  },
  privacyText: {
    textAlign: 'start',
    fontSize: 12,
  },
  privacyContainer: {
    margin: '20px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
};

const RegisterPage = ({
  reduxStatus,
  reduxError,
  handleRegister,
  setIsShowRegisterPage,
  onLogin,
}) => {
  const [passwordInput, setPasswordInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(false);

  const onSubmit = (event) => {
    ReactPixel.track('CompleteRegistration', {
      content_name: emailInput,
      currency: 'TWD',
    });
    event.preventDefault();
    handleRegister({
      password: passwordInput,
      email: emailInput,
    });
  };

  if (reduxStatus === ReduxStatus.STATUS_NORMAL) {
    onLogin({
      password: passwordInput,
      email: emailInput,
    });
  }
  const content = isMobile => (
    <React.Fragment>
      <div style={styles.imgContainer}>
        { isMobile ? null : (
          <img
            src={Assets.registerImg}
            style={styles.img}
            alt=""
          />
        )}
      </div>
      <div style={isMobile ? styles.rightMobileContainer : styles.rightContainer}>
        <form style={styles.form} onSubmit={onSubmit}>
          <div style={styles.title}>首租享 $150 購物金</div>
          <div style={styles.text}>
            愛時尚又愛地球的人有福了
            <span role="img" aria-label="marks">❗️</span>
            <br />
            享受精品級設計
            無負擔
            <span role="img" aria-label="marks">💖 </span>
            無浪費
            <span role="img" aria-label="tulips">🌷</span>
            <br />
            邀請您加入永續時尚發展的行列
            <span role="img" aria-label="stars"> ✨ </span>
          </div>
          <input
            placeholder="(輸入信箱)"
            type="email"
            style={styles.input}
            value={emailInput}
            onChange={(event) => { setEmailInput(event.target.value); }}
            required
          />
          <input
            placeholder="(密碼必需至少5個字元，包含1個大寫字母)"
            type="password"
            style={styles.input}
            value={passwordInput}
            onChange={(event) => { setPasswordInput(event.target.value); }}
            required
          />
          <div style={styles.privacyContainer}>
            <Checkbox
              onChange={(event) => {
                setIsCheckedPrivacy(event.target.checked);
              }}
            />
            &emsp;
            <div style={styles.privacyText}>
              <Link to={Paths.MEMBER_TERMS}>通過註冊,我接受OP的條款以及隱私政策</Link>
            </div>
          </div>
          <button style={isMobile ? styles.buttonMobile : styles.button} type="submit" disabled={!isCheckedPrivacy}>
            立即註冊
          </button>
        </form>
        <BackToLoadingPage
          onClick={() => setIsShowRegisterPage(false)}
        >
          Log in 已經是會員了?
        </BackToLoadingPage>
        <div style={styles.copyright}>
          &copy; 2023 OP Inc. All rights reserved.
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ReduxStatusVisualizedComponent
        containerStyle={styles.reduxStyle}
        showModalOnError
        reduxStatus={reduxStatus}
        modalFailureContent={reduxError}
      >
        <Default>
          <div style={styles.mainContainer} key="baseContainer">
            {content()}
          </div>
        </Default>
        <Mobile>
          <div style={styles.mainMobileContainer} key="baseContainer">
            {content(true)}
          </div>
        </Mobile>
      </ReduxStatusVisualizedComponent>
    </React.Fragment>
  );
};

RegisterPage.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  handleRegister: PropTypes.func.isRequired,
  setIsShowRegisterPage: PropTypes.func,
  onLogin: PropTypes.func.isRequired,
};

RegisterPage.defaultProps = {
  reduxStatus: '',
  reduxError: '',
  setIsShowRegisterPage: null,
};

export default RegisterPage;
