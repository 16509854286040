import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
  UploadCommodityComponent,
  EmptyUploadCommodityComponent,
} from './UploadCommodityComponent';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import NotifyDraftBagsSavedComponent from './NotifyDraftBagsSavedComponent';
import CompletePublishComponent from './CompletePublishComponent';
import ReduxStatus from '../../../../constants/ReduxStatus';
import { typeId } from '../../../../constants/Type';
import ModalComponent from '../../../../components/ModalComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import { FontSize } from '../../../../constants/FontSize';
import Paths from '../../../../routes/Paths';

const styles = {
  mainContainer: {
    margin: 20,
    marginTop: '40px',
    paddingBottom: '100px',
  },
  tableStyle: {
    margin: '0 auto',
  },
  image: {
    width: 120,
    height: 120,
    objectFit: 'cover',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  uploader: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 5,
  },
  plusContainer: {
    margin: 10,
    width: '100%',
    display: 'flex',
    flexDirection: '',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'transparent',
    height: 'fit-content',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  plusIcon: {
    fontSize: 30,
    color: '#AE4200',
  },
  plusItemHint: {
    marginLeft: 10,
    width: 'fit-content',
    color: '#A7A7A7',
    fontSize: 18,
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: '#DFDFDF',
  },
  uploadHintContainer: {
    margin: '50px 0px',
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadHintMobileContainer: {
    margin: '50px 0px',
    height: 'auto',
    alignItems: 'center',
  },
  uploadHint: {
    width: '60%',
  },
  uploadHintMobile: {
    width: '100%',
  },
  uploadFunctionContainer: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadFunctionMobileContainer: {
    width: '100%',
    marginTop: '20px',
  },
  saveDraft: {
    backgroundColor: 'transparent',
    width: 162,
    height: 54,
    fontFamily: 'Microsoft JhengHei Regular',
    border: '1px solid #AE4200',
    color: '#6F6E6E',
    textAlign: 'center',
    padding: '8px 0px',
    fontSize: 14,
    cursor: 'pointer',
    outline: 'none',
  },
  upload: {
    width: 162,
    height: 54,
    fontFamily: 'Microsoft JhengHei Regular',
    backgroundColor: '#AE4200',
    color: 'white',
    textAlign: 'center',
    padding: '8px 0px',
    fontSize: 14,
    cursor: 'pointer',
    outline: 'none',
  },
  canNotUpload: {
    width: 162,
    height: 54,
    fontFamily: 'Microsoft JhengHei Regular',
    backgroundColor: 'white',
    border: '1px solid #AE4200',
    color: '#AE4200',
    textAlign: 'center',
    padding: '8px 0px',
    fontSize: 14,
    cursor: 'not-allowed',
    outline: 'none',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  tabSelectContainer: {
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    marginBottom: -1,
    borderBottom: '1px solid #AE4200',
    backgroundColor: 'transparent',
  },
  tabDivider: {
    borderBottom: '1px solid #ABABAB',
  },
  tabUnSelectContainer: {
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    marginBottom: -1,
    backgroundColor: 'transparent',
  },
  tabSelect: {
    color: '#AE4200',
    fontSize: 24,
  },
  tabUnSelect: {
    color: '#BABABA',
    fontSize: 24,
  },
  tabHint: {
    marginLeft: 10,
    fontSize: 20,
  },
  tableContainer: {
    marginTop: 200,
    width: '100%',
  },
  draftHeader: {
    height: 50,
    padding: '10px 0px',
    borderTop: '2px solid #939598',
    borderBottom: '1px solid #D1D3D4',
  },
  draftBagImgTr: {
    color: '#58595B',
    width: '25%',
  },
  draftBagNameTr: {
    color: '#58595B',
    width: '25%',
  },
  draftBagTypeTr: {
    color: '#58595B',
    width: '25%',
  },
  draftBagFunctionTr: {
    color: '#58595B',
    width: '25%',
  },
  mobileTabStyle: {
    display: 'block',
    padding: '10px 20px',
    width: '50%',
  },
  mobileTabTitleStyle: {
    fontSize: FontSize.subTitle,
  },
  mobileTabSubTitleStyle: {
    fontSize: FontSize.body,
  },
  mobileContainerStyle: {
    width: '100%',
    maxWidth: 'none',
    padding: '0px',
  },
  mobileMainContainerStyle: {
    flexDirection: 'column',
    padding: '40px 0px 10px 0px',
  },
};

const SubmitCommodityPage = ({
  tags,
  reduxStatus,
  submitBag,
  onWriteDraft,
  draftOfBags,
  updateMemberInfo,
  reduxError,
  resetStatus,
  onMemberValueChanged,
  recipientName,
  recipientPhone,
  recipientAddress,
  sevenElevenAddress,
  sevenElevenMarket,
  selectStore,
  isPostReceive,
  isSevenElevenReceive,
  updateIsPostReceive,
  updateIsSevenElevenReceive,
  editData,
  isEdit,
  onClickCommodityChange,
  setIsEditSend,
  isEditSend,
}) => {
  const [isShowSavedDraft, setIsShowSavedDraft] = useState(false);
  const [isShowCompletePublishComponent, setIsShowCompletePublishComponent] = useState(false);
  const [uploadBags, setUploadBags] = useState(
    isEdit ? [
      { ...editData },
    ] : [
      { ...EmptyUploadCommodityComponent, uuid: uuidv4() },
    ],
  );
  const [isShowBagUploadError, setIsShowBagUploadError] = useState(false);
  const [isUpdateMemberInfo, setIsUpdateMemberInfo] = useState(true);
  const [draftBags, setDraftBags] = useState(draftOfBags);
  const hasUuid = R.pathOr(false, ['uuid'], editData);
  const isDraft = hasUuid && isEdit;
  const [rentType, setRentType] = useState(typeId.rent);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onRemoveUploadBag = (id) => {
    if (uploadBags.length <= 1) return;
    const newUploadItems = R.filter(uploadItem => uploadItem.uuid !== id, uploadBags);
    setUploadBags(newUploadItems);
  };

  const onModifiedData = (uuid, modifiedDataList) => {
    const typeRent = {
      key: 'typeId',
      value: rentType,
    };
    const contractDeadLine = {
      key: 'contractDeadLine',
      value: moment(new Date()).add(6, 'months'),
    };
    const rentMonths = {
      key: 'rentMonths',
      value: 6,
    };

    modifiedDataList.push(typeRent);
    modifiedDataList.push(contractDeadLine);
    modifiedDataList.push(rentMonths);

    const updateBags = update([], { $push: [...uploadBags] });
    updateBags.forEach(
      (updateBag, index) => {
        if (updateBag.uuid === uuid) {
          modifiedDataList.forEach(({ key, value }) => {
            updateBags[index][key] = value;
          });
        }
      },
    );
    setUploadBags(updateBags);
  };

  const onChangeCommodity = () => {
    onClickCommodityChange(uploadBags);
  };

  const checkBagUploadContainerState = () => {
    const bagUploadContainerState = uploadBags.map((bag) => {
      if ((bag.name === null || bag.name === '')
          && (bag.typeId === null)
          && (bag.contractDeadLine === null)
          && (bag.description === null || bag.description === '')
          && (bag.rentMonths === null)
          && (bag.frontPicId === null)
          && (bag.backPicId === null)
          && (bag.sidePicId === null)
          && (bag.bottomPicId === null)
          && (bag.zipperPicId === null)
          && (bag.designerTagId === null || bag.designerTagId === '0')
          && (bag.styleTagId === null || bag.styleTagId === '0')
          && (bag.originalPrice === null || bag.originalPrice === '0')
          && (bag.deposit === null)
          && (bag.buyoutAmount === null)
      ) return 'emptyBagContainer';

      if ((bag.name !== null && bag.name !== '')
      && (bag.typeId !== null)
      && (bag.contractDeadLine !== null)
      && (bag.description !== null)
      && (bag.rentMonths !== null)
      && ((bag.frontPicId !== null)
      || (bag.backPicId !== null)
      || (bag.sidePicId !== null)
      || (bag.bottomPicId !== null)
      || (bag.zipperPicId !== null))
      && (bag.designerTagId !== null && bag.designerTagId !== '0')
      && (bag.styleTagId !== null && bag.styleTagId !== '0')
      && (bag.originalPrice !== null || bag.originalPrice !== 0)
      && (bag.deposit !== null) && (bag.deposit >= 0)
      ) return 'verified';

      return 'unVerifyBagData';
    });
    return bagUploadContainerState;
  };

  const onSubmit = () => {
    const bagContainerState = checkBagUploadContainerState();
    if (bagContainerState.indexOf('unVerifyBagData') !== -1) {
      setIsShowBagUploadError(true);
      return;
    }

    const filterEmptyBagUploadContainer = uploadBags.filter((bag, index) => {
      if (bagContainerState[index] === 'emptyBagContainer') {
        return false;
      }
      return true;
    });

    // if all bags container is empty show error modal
    if (filterEmptyBagUploadContainer.length === 0) {
      setIsShowBagUploadError(true);
      return;
    }

    submitBag(filterEmptyBagUploadContainer);
    if (isUpdateMemberInfo) {
      updateMemberInfo({
        recipientName,
        recipientPhone,
        recipientAddress,
        sevenElevenAddress,
        sevenElevenMarket,
        isPostReceive,
        isSevenElevenReceive,
        selectStore,
      });
    }

    setUploadBags([{ ...EmptyUploadCommodityComponent, uuid: uuidv4() }]);
  };

  const onSaveDraft = () => {
    setIsShowSavedDraft(true);
    onWriteDraft(uploadBags, false);
    setDraftBags(update(draftBags, { $push: [...uploadBags] }));
    setUploadBags([{ ...EmptyUploadCommodityComponent, uuid: uuidv4() }]);
  };

  return (
    <div style={styles.mainContainer} key="baseContainer">

      <NotifyDraftBagsSavedComponent
        onModalClose={() => setIsShowSavedDraft(false)}
        isVisible={isShowSavedDraft}
      />

      <CompletePublishComponent
        onModalClose={() => {
          setIsShowCompletePublishComponent(false);
          resetStatus();
        }}
        isVisible={isShowCompletePublishComponent || (reduxStatus === ReduxStatus.STATUS_NORMAL)}
      />

      <ModalComponent
        isVisible={isEdit && isEditSend}
        onModalClose={() => setIsEditSend(false)}
        onModalConfirmed={() => {
          setIsEditSend(false);
          window.location.href = Paths.ONLINE_PRODUCTS;
        }}
        content="修改完成"
      />

      <ModalComponent
        isVisible={isShowBagUploadError}
        onModalClose={() => setIsShowBagUploadError(false)}
        onModalConfirmed={() => setIsShowBagUploadError(false)}
        content="請確實輸入所有商品資料"
      />

      {/* GTag implant */}
      <Helmet>
        <script>
          {'gtag(\'event\', \'conversion\', {\'send_to\': \'AW-627439533/YJlKCK6vo9IBEK3vl6sC\'})'}
        </script>
        <script>
          {`
          function gtag_report_conversion2(url) {
            var callback = function () {
              if (typeof(url) != 'undefined') {
                window.location = url;
              }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-627439533/Z2msCIKwo9IBEK3vl6sC',
                'transaction_id': '',
                'event_callback': callback
            });
            return false;
          }
          `}
        </script>
      </Helmet>

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        <React.Fragment>
          <Default>
            <UploadCommodityComponent
              onModifiedData={onModifiedData}
              uploadBag={uploadBags[0]}
              onRemoveUploadBag={onRemoveUploadBag}
              tags={tags}
              onMemberValueChanged={onMemberValueChanged}
              recipientName={recipientName}
              recipientPhone={recipientPhone}
              recipientAddress={recipientAddress}
              sevenElevenMarket={sevenElevenMarket}
              sevenElevenAddress={sevenElevenAddress}
              isPostReceive={isPostReceive}
              isSevenElevenReceive={isSevenElevenReceive}
              isUpdateMemberInfo={isUpdateMemberInfo}
              updateIsPostReceive={updateIsPostReceive}
              updateIsSevenElevenReceive={updateIsSevenElevenReceive}
              onIsUpdateMemberInfoChanged={setIsUpdateMemberInfo}
              onSubmit={onSubmit}
              onSaveDraft={onSaveDraft}
              isEdit={isEdit}
              isDraft={isDraft}
              onChangeCommodity={onChangeCommodity}
              selectStore={selectStore}
              onChangeToBuy={setRentType}
              rentType={rentType}
            />
          </Default>
          <Mobile>
            <UploadCommodityComponent
              onModifiedData={onModifiedData}
              uploadBag={uploadBags[0]}
              onRemoveUploadBag={onRemoveUploadBag}
              tags={tags}
              onMemberValueChanged={onMemberValueChanged}
              recipientName={recipientName}
              recipientPhone={recipientPhone}
              recipientAddress={recipientAddress}
              sevenElevenMarket={sevenElevenMarket}
              sevenElevenAddress={sevenElevenAddress}
              isPostReceive={isPostReceive}
              isSevenElevenReceive={isSevenElevenReceive}
              isUpdateMemberInfo={isUpdateMemberInfo}
              updateIsPostReceive={updateIsPostReceive}
              updateIsSevenElevenReceive={updateIsSevenElevenReceive}
              onIsUpdateMemberInfoChanged={setIsUpdateMemberInfo}
              onSubmit={onSubmit}
              onSaveDraft={onSaveDraft}
              isEdit={isEdit}
              isDraft={isDraft}
              onChangeCommodity={onChangeCommodity}
              selectStore={selectStore}
              mobileContainerStyle={styles.mobileContainerStyle}
              mobileMainContainerStyle={styles.mobileMainContainerStyle}
              isMobile
              onChangeToBuy={setRentType}
              rentType={rentType}
            />
          </Mobile>
        </React.Fragment>

      </ReduxStatusVisualizedComponent>
    </div>
  );
};

SubmitCommodityPage.propTypes = {
  resetStatus: PropTypes.func.isRequired,
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  bagInfo: PropTypes.shape({}),
  editData: PropTypes.shape({}),
  tags: PropTypes.array,
  onMemberValueChanged: PropTypes.func,
  recipientName: PropTypes.string,
  recipientPhone: PropTypes.string,
  recipientAddress: PropTypes.string,
  submitBag: PropTypes.func.isRequired,
  updateMemberInfo: PropTypes.func.isRequired,
  draftOfBags: PropTypes.array,
  onWriteDraft: PropTypes.func.isRequired,
  sevenElevenMarket: PropTypes.string,
  sevenElevenAddress: PropTypes.string,
  isPostReceive: PropTypes.bool,
  isSevenElevenReceive: PropTypes.bool,
  updateIsPostReceive: PropTypes.func,
  updateIsSevenElevenReceive: PropTypes.func,
  isEdit: PropTypes.bool,
  onClickCommodityChange: PropTypes.func,
  setIsEditSend: PropTypes.func,
  isEditSend: PropTypes.bool,
  selectStore: PropTypes.string,
};

SubmitCommodityPage.defaultProps = {
  reduxError: null,
  bagInfo: null,
  editData: null,
  tags: [],
  recipientName: '',
  recipientPhone: '',
  recipientAddress: '',
  onMemberValueChanged: () => {},
  draftOfBags: [],
  sevenElevenMarket: '',
  sevenElevenAddress: '',
  selectStore: '',
  isPostReceive: false,
  isSevenElevenReceive: false,
  updateIsPostReceive: () => {},
  updateIsSevenElevenReceive: () => {},
  isEdit: false,
  onClickCommodityChange: () => {},
  setIsEditSend: () => {},
  isEditSend: false,
};

export default SubmitCommodityPage;
