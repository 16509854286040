import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import * as R from 'ramda';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  resetRetrieveBagStatus,
  resetExtendContractBagStatus,
  resetBagEditStatus,

  retrieveBagSuccess,
  retrieveBagFailure,
  retrieveBagFlowRequest,

  extendContractBagSuccess,
  extendContractBagFailure,
  extendContractBagFlowRequest,

  bagEditSuccess,
  bagEditFailure,
  bagEditFlowRequest,

  updateBagInfoRequest,
  updateBagInfoSuccess,
  updateBagInfoFailure,

} = ActionCreator;

const initialState = {
  retrieveBag: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
  },
  extendContractBag: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
  },
  bagEdit: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
  },
};

export default handleActions({

  [resetRetrieveBagStatus]: state => update(state, {
    retrieveBag: {
      reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
      error: { $set: null },
    },
  }),

  [resetExtendContractBagStatus]: state => update(state, {
    extendContractBag: {
      reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
      error: { $set: null },
    },
  }),

  [resetBagEditStatus]: state => update(state, {
    bagEdit: {
      reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
      error: { $set: null },
    },
  }),

  // request
  [retrieveBagFlowRequest]: state => update(state, {
    retrieveBag: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      error: { $set: null },
    },
  }),
  [extendContractBagFlowRequest]: state => update(state, {
    extendContractBag: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      error: { $set: null },
    },
  }),
  [bagEditFlowRequest]: state => update(state, {
    bagEdit: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      error: { $set: null },
    },
  }),
  [
  updateBagInfoRequest
  ]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: initialState.error },
  }),

  // success
  [retrieveBagSuccess]: state => update(state, {
    retrieveBag: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      error: { $set: null },
    },
  }),
  [extendContractBagSuccess]: state => update(state, {
    extendContractBag: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      error: { $set: null },
    },
  }),
  [bagEditSuccess]: state => update(state, {
    bagEdit: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      error: { $set: null },
    },
  }),
  [updateBagInfoSuccess]: (state, { payload }) => {
    const {
      id,
    } = payload;
    return update(state, {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: {
        $set: R.pathOr([], ['data'], state).map((bag) => {
          if (bag.id === id) return payload;
          return bag;
        }),
      },
    });
  },

  // failure
  [retrieveBagFailure]: (state, { payload }) => update(state, {
    retrieveBag: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      error: { $set: payload },
    },
  }),
  [extendContractBagFailure]: (state, { payload }) => update(state, {
    extendContractBag: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      error: { $set: payload },
    },
  }),
  [bagEditFailure]: (state, { payload }) => update(state, {
    bagEdit: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      error: { $set: payload },
    },
  }),

  [updateBagInfoFailure]: (state, { payload }) => update(state, {
    bagEdit: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      error: { $set: payload },
    },
  }),

}, initialState);
