import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Assets from '../../constants/Assets';

const styles = {
  button: {
    width: '100px',
    height: '100px',
  },
  arrowRightContainer: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    right: '0px',
    height: '50px',
    width: '50px',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
    transition: 'transform ease-in 0.1s',
    outline: 'none',
  },
  arrowLeftContainer: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '0px',
    height: '50px',
    width: '50px',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
    transition: 'transform ease-in 0.1s',
    outline: 'none',
  },
};

const Arrow = ({
  direction,
  handleClick,
}) => (
  <div
    role="button"
    onClick={handleClick}
    tabIndex={0}
    onKeyPress={handleClick}
    style={direction === 'right' ? styles.arrowRightContainer : styles.arrowLeftContainer}
  >
    <img
      alt=""
      style={styles.button}
      src={direction === 'right' ? Assets.howItWorksRightArrow : Assets.howItWorksLeftArrow}
    />

  </div>
);

Arrow.propTypes = {
  direction: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

const MemoArrow = memo(Arrow);

export default MemoArrow;
