import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import CancelOrderModalComponent from './CancelOrderModalComponent';
import ActionCreators from '../../ActionCreator';

const CancelOrderModalComponentContainer = ({
  onModalClose,
  isVisible,
  order,
  userId,
  actions: {
    orderUpdateFlowRequest,
  },
}) => {
  const isOwner = R.pathOr(null, ['bag', 'ownerId'], order) === userId;

  const handleOnCancelOrder = () => {
    orderUpdateFlowRequest({
      isFromOrderList: !isOwner,
      orderStatusId: 15,
      orderId: order.id,
    });
  };

  return (
    <CancelOrderModalComponent
      isVisible={isVisible}
      onModalClose={onModalClose}
      onCancelOrder={handleOnCancelOrder}
    />
  );
};


CancelOrderModalComponentContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  order: PropTypes.object,
  userId: PropTypes.number,
  actions: PropTypes.shape({
    orderUpdateFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
};

CancelOrderModalComponentContainer.defaultProps = {
  order: {},
  userId: null,
};
const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancelOrderModalComponentContainer);
