import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';
import { Icon } from 'antd';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import { commodityTypeId } from '../../../../constants/Type';
import { Colors } from '../../../../constants/Color';
import { shippingType, shippingTypeId } from '../../../../constants/ShippingType';

const styles = {
  mainContainer: {
    width: 440,
    height: 620,
    padding: 20,
    position: 'relative',
    zIndex: 9999,
  },
  title: {
    fontSize: 35,
    color: 'black',
  },
  subtitle: {
    fontSize: 16,
  },
  dateConfirmContainer: {
    fontSize: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'fit-content',
    marginTop: 20,
    marginBottom: 30,
  },
  date: {
    color: '#B34F13',
    margin: '0px 5px',
  },
  confirm: {
    marginTop: 90,
    width: 'fit-content',
    height: 'fit-content',
    padding: '3px 20px',
    color: 'black',
    border: '1px solid #CABCB3',
    marginLeft: 200,
    fontWeight: 150,
    backgroundColor: 'transparent',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'fit-content',
    width: '100%',
  },
  itemTitle: {
    fontSize: 15,
    width: '50%',
    height: 'fit-content',
  },
  itemContent: {
    fontSize: 15,
    width: '50%',
    height: 'fit-content',
  },
  supplyPackageNumberContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'fit-content',
    width: '100%',
    marginBottom: 3,
  },
  supplyPackageNumber: {
    fontSize: 15,
    width: 140,
    marginRight: 10,
  },
  packageNumber: {
    backgroundColor: '#eee',
    outline: 'none',
    border: 'none',
    fontSize: 15,
    color: 'black',
  },
  tip: {
    fontSize: 8,
    color: '#CABCB3',
  },
  marginTop50: {
    marginTop: 50,
  },
  addressMarginBottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'fit-content',
    width: '100%',
    marginBottom: 30,
  },
  submitContainer: {
    width: '100%',
    textAlign: ' center',
    marginTop: 20,
  },
  submit: {
    border: '1px solid #927764',
    width: 100,
    height: 30,
    color: '#927764',
    textAlign: 'center',
    alignItems: 'flex-end',
    outline: 'none',
    cursor: 'pointer',
  },
  returnInfoTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  noteText: {
    marginBottom: '20px',
  },
  shippingWayText: {
    fontSize: '14px',
    color: Colors.introRightTitle,
    marginLeft: 20,
  },
  icon: {
    fontSize: 20,
    color: '#927764',
    marginLeft: '5px',
  },
};

const ProductSendModalComponent = ({
  isVisible,
  onModalClose,
  order,
  userId,
  onProductSend,
  addressData,
}) => {
  const isOwner = R.pathOr(null, ['bag', 'ownerId'], order) === userId;
  const isOhPhireManage = R.pathOr(false, ['bag', 'isOhPhireManage'], order);
  const [packageNo, setPackageNo] = useState(isOwner ? order.lenderSentPackageNo : order.renterSentPackageNo);
  const [shippingCompany, setShippingCompany] = useState(isOwner ? order.lenderShippingCompany : order.renterShippingCompany);
  const isBusinessPartner = R.pathOr(false, ['bag', 'owner', 'isBusinessPartner'], order);
  const commodityType = R.pathOr(1, ['commodityTypeId'], order);
  const orderShippingType = R.pathOr(1, ['shippingType'], order);
  const isPostReceive = R.pathOr(false, ['bag', 'owner', 'isPostReceive'], order);
  const isSevenElevenReceive = R.pathOr(false, ['bag', 'owner', 'isSevenElevenReceive'], order);
  const opAddress = addressData[0];
  const companyAddress = addressData[1];

  const onSubmit = () => {
    if ((isOwner && !packageNo && !shippingCompany) || (!isOwner && !packageNo && !shippingCompany)) return;

    if (isOwner) {
      onProductSend({
        isOwner,
        address: R.path(['user', 'recipientAddress'], order),
        packageNo,
        shippingCompany,
      });
    } else {
      onProductSend({
        isOwner,
        address: `${R.path(['bag', 'owner', 'sevenElevenMarket'], order)} ${R.path(['bag', 'owner', 'sevenElevenAddress'], order)}`,
        packageNo,
        shippingCompany,
      });
    }
  };

  const renderReceiveInfo = () => {
    if (isOwner && !isOhPhireManage && commodityType !== commodityTypeId.bag && commodityType) {
      return {
        name: R.path(['recipientName'], order),
        phone: R.path(['recipientPhone'], order),
        recipientAddress: R.path(['recipientAddress'], order),
        storeAddress: `711 ${R.path(['sevenElevenMarket'], order)}`,
      };
    }

    if ((isOhPhireManage || (commodityType === commodityTypeId.bag && !isBusinessPartner) || !commodityType)) {
      return {
        name: R.pathOr('', ['name'], opAddress),
        storeReceiver: R.pathOr('', ['storeReceiver'], opAddress),
        phone: R.pathOr('', ['phone'], opAddress),
        cellPhone: R.pathOr('', ['storeCellPhone'], opAddress),
        recipientAddress: R.pathOr('', ['recipientAddress'], opAddress),
        storeAddress: R.pathOr('', ['storeAddress'], opAddress),
      };
    }

    if (isBusinessPartner) {
      return {
        name: R.pathOr('', ['name'], companyAddress),
        storeReceiver: R.pathOr('', ['storeReceiver'], companyAddress),
        phone: R.pathOr('', ['phone'], companyAddress),
        cellPhone: R.pathOr('', ['storeCellPhone'], companyAddress),
        recipientAddress: R.pathOr('', ['recipientAddress'], companyAddress),
        storeAddress: R.pathOr('', ['storeAddress'], companyAddress),
      };
    }

    return {
      name: R.path(['bag', 'owner', 'recipientName'], order),
      phone: R.path(['bag', 'owner', 'recipientPhone'], order),
      recipientAddress: R.path(['bag', 'owner', 'recipientPhone'], order),
      storeAddress: `711  ${R.path(['bag', 'owner', 'sevenElevenMarket'], order)}`,
    };
  };

  const renderShippingDay = () => {
    const rentStartDate = R.pathOr(null, ['rentStartDate'], order);
    if (rentStartDate) {
      return moment(R.path(['rentStartDate'], order)).subtract(5, 'days').format('YYYY-MM-DD');
    }

    return '盡快';
  };

  const content = (
    <div style={styles.mainContainer}>
      <div style={styles.title}>
        {isOwner ? '我要寄貨' : '我要歸還'}
        {
          isOwner ? (
            <span style={styles.shippingWayText}>
              請使用【
              {shippingType[orderShippingType]}
              】方式寄出
            </span>
          ) : null
        }
      </div>
      {
        isBusinessPartner && isOwner ? ('訂貨出貨統一由OP預約貨運前往取件。') : (
          <React.Fragment>
            <div style={styles.dateConfirmContainer}>
              <div>{isOwner ? '請確保商品會在' : '請確保商品在'}</div>
              <div style={styles.date}>
                {isOwner
                  ? renderShippingDay()
                  : moment(R.path(['rentEndDate'], order)).format('YYYY-MM-DD')}
              </div>
              <div>{isOwner ? '抵達' : '寄出'}</div>
            </div>
            {
              isOwner ? (
                <div style={styles.noteText}>
                  為創造安全的共享環境
                  <br />
                  自助管理精品包需寄至平台鑑定後由代為出貨
                  <br />
                  產生之運費將由收益扣除
                </div>
              ) : null
            }
            <div style={styles.itemContainer}>
              <div style={styles.returnInfoTitle}>郵寄到府</div>
              {
                String(orderShippingType) === shippingTypeId.postOffice || (!isOwner && isPostReceive) ? (
                  <Icon type="check-circle" theme="filled" style={styles.icon} />
                ) : null
              }
            </div>
            <div style={styles.itemContainer}>
              <div style={styles.itemTitle}>收件人</div>
              <div>{renderReceiveInfo().name}</div>
            </div>
            <div style={styles.itemContainer}>
              <div style={styles.itemTitle}>電話</div>
              <div>
                {renderReceiveInfo().phone}
              </div>
            </div>
            <div style={styles.addressMarginBottom}>
              <div style={styles.itemTitle}>地址</div>
              <div>
                {renderReceiveInfo().recipientAddress}
              </div>
            </div>
            <div style={styles.itemContainer}>
              <div style={styles.returnInfoTitle}>超商店到店</div>
              {
                String(orderShippingType) === shippingTypeId.sevenEleven || (!isOwner && isSevenElevenReceive) ? (
                  <Icon type="check-circle" theme="filled" style={styles.icon} />
                ) : null
              }
            </div>
            <div style={styles.itemContainer}>
              <div style={styles.itemTitle}>收件人</div>
              <div>
                {renderReceiveInfo().storeReceiver}
              </div>
            </div>
            <div style={styles.itemContainer}>
              <div style={styles.itemTitle}>電話</div>
              <div>
                {renderReceiveInfo().cellPhone}
              </div>
            </div>
            <div style={styles.addressMarginBottom}>
              <div style={styles.itemTitle}>地址</div>
              <div>
                {renderReceiveInfo().storeAddress}
              </div>
            </div>
            <div style={styles.supplyPackageNumberContainer}>
              <div style={styles.supplyPackageNumber}>請提供物流公司:</div>
              <input
                style={styles.packageNumber}
                defaultValue={isOwner ? order.lenderShippingCompany : order.renterShippingCompany}
                value={shippingCompany}
                onChange={e => setShippingCompany(e.target.value)}
              />
            </div>
            <div style={styles.supplyPackageNumberContainer}>
              <div style={styles.supplyPackageNumber}>包裹查詢號碼:</div>
              <input
                style={styles.packageNumber}
                defaultValue={isOwner ? order.lenderSentPackageNo : order.renterSentPackageNo}
                value={packageNo}
                onChange={e => setPackageNo(e.target.value)}
              />
            </div>
            <div style={styles.tip}>若遇商品運送問題，平台可於短時間內提供協助</div>
            <div style={styles.submitContainer}>
              <button type="button" style={styles.submit} onClick={() => onSubmit()}>確認</button>
            </div>
          </React.Fragment>
        )
      }
    </div>
  );

  return (
    <InfoModalComponent
      isVisible={isVisible}
      content={content}
      onModalClose={onModalClose}
      containerStyle={{ paddingTop: 100 }}
    />
  );
};

ProductSendModalComponent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  order: PropTypes.object,
  userId: PropTypes.number,
  onProductSend: PropTypes.func.isRequired,
  addressData: PropTypes.array.isRequired,
};

ProductSendModalComponent.defaultProps = {
  order: {},
  userId: null,
};
export default ProductSendModalComponent;
