import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import MemberHeader from '../../../Member/components/MemberHeader';
import Paths from '../../../../routes/Paths';
import Assets from '../../../../constants/Assets';
import Button from '../../../../components/Button';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import { Colors } from '../../../../constants/Color';
import { shippingTypeId, shippingType } from '../../../../constants/ShippingType';


const styles = {
  mainContainer: {
    paddingTop: 200,
    margin: '0px auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shippingInfoContainer: {
    width: '85%',
    marginTop: '50px',
    position: 'relative',
    paddingBottom: '50px',
    marginBottom: '200px',
  },
  shippingInfoTitle: {
    fontSize: 20,
    paddingBottom: 10,
    marginBottom: 100,
    color: 'black',
    borderBottom: '2px solid grey',
    textAlign: 'center',
  },
  infoDataBlockContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoDataBlockMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoDataContainer: {
    width: '48%',
    border: '2px grey solid',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '30px 0px',
    height: '300px',
  },
  infoDataMobileContainer: {
    width: '100%',
    border: '2px grey solid',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '30px 0px',
    height: '400px',
    marginBottom: '30px',
  },
  clickContainer: {
    width: '10%',
    textAlign: 'right',
  },
  shippingContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  infoContainer: {
    width: '40%',
  },
  infoMobileContainer: {
    width: '50%',
  },
  title: {
    fontSize: 20,
    color: 'grey',
    marginBottom: '30px',
  },
  subTitle: {
    fontSize: 12,
  },
  inputBlockContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: '8px 0px',
  },
  inputBlockMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: '8px 0px',
  },
  inputText: {
    fontSize: 14,
    color: 'black',
    width: 'fit-content',
    height: '28px',
    textAlign: 'left',
  },
  addressInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 'fit-content',
  },
  input: {
    border: 'none',
    borderBottom: '1px grey solid',
    marginBottom: '5px',
  },
  shippingText: {
    fontSize: 14,
    color: 'black',
    marginTop: '60px',
  },
  shippingIcon: {
    position: 'absolute',
    top: '-32px',
  },
  checkInput: {
    marginTop: '10px',
  },
  unEditButton: {
    position: 'absolute',
    right: '0',
    top: 88,
    color: 'black',
    background: 'white',
    border: `2px solid ${Colors.separateLine}`,
    maxWidth: '160px',
    minWidth: '60px',
    width: '30%',
    height: '45px',
  },
  editButton: {
    position: 'absolute',
    right: '0',
    top: 88,
    color: 'grey',
    background: 'white',
    border: `2px solid ${Colors.separateLine}`,
    maxWidth: '160px',
    minWidth: '60px',
    width: '30%',
    height: '45px',
  },
  submitButton: {
    position: 'absolute',
    right: '0',
    bottom: 0,
    color: 'black',
    background: 'white',
    border: `2px solid ${Colors.introRightTitle}`,
    maxWidth: '160px',
    minWidth: '60px',
    width: '30%',
    height: '45px',
  },
};

const ShippingInfoPage = ({
  reduxStatus,
  reduxError,
  memberData,
  onInputValueChange,
  onSubmitMemberInfoChange,
  recipientName,
  recipientPhone,
  recipientZipCode,
  recipientAddress,
  selectStore,
  sevenElevenMarket,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const isPostReceive = R.pathOr(false, ['user', 'isPostReceive'], memberData);
  const isSevenElevenReceive = R.pathOr(false, ['user', 'isSevenElevenReceive'], memberData);

  const renderContent = isMobile => (
    <React.Fragment>
      <div style={isMobile ? styles.infoDataBlockMobileContainer : styles.infoDataBlockContainer}>

        <div style={isMobile ? styles.infoDataMobileContainer : styles.infoDataContainer}>
          <div style={styles.clickContainer}>
            <input
              type="checkbox"
              defaultChecked={isPostReceive}
              onChange={(event) => { onInputValueChange(event, 'isPostReceive'); }}
              style={styles.checkInput}
            />
          </div>
          <div style={styles.shippingContainer}>
            <img
              src={Assets.memberShippingIcon}
              style={styles.shippingIcon}
              alt=""
            />
            <div style={styles.shippingText}>
              郵寄到府
              <Mobile>
                <br />
              </Mobile>
            </div>
          </div>
          <div style={isMobile ? styles.infoMobileContainer : styles.infoContainer}>
            <h1 style={styles.title}>
              Delivery
              <br />
              <span style={styles.subTitle}>
                郵寄資訊
              </span>
            </h1>
            <div style={isMobile ? styles.inputBlockMobileContainer : styles.inputBlockContainer}>
              <div style={styles.inputText}>
                收件姓名：
              </div>
              <div style={styles.inputBlock}>
                { isEdit ? (
                  <input
                    defaultValue={recipientName}
                    onChange={(event) => { onInputValueChange(event, 'recipientName'); }}
                    style={styles.input}
                  />
                ) : recipientName}
              </div>
            </div>
            <div style={isMobile ? styles.inputBlockMobileContainer : styles.inputBlockContainer}>
              <div style={styles.inputText}>
                收件電話：
              </div>
              <div style={styles.inputBlock}>
                { isEdit ? (
                  <input
                    defaultValue={recipientPhone}
                    onChange={(event) => { onInputValueChange(event, 'recipientPhone'); }}
                    style={styles.input}
                  />
                ) : recipientPhone}
              </div>
            </div>
            <div style={isMobile ? styles.inputBlockMobileContainer : styles.inputBlockContainer}>
              <div style={styles.inputText}>
                收件地址：
              </div>
              <div style={styles.inputBlock}>
                { isEdit ? (
                  <React.Fragment>
                    <div style={styles.addressInputContainer}>
                      <input
                        defaultValue={recipientZipCode}
                        onChange={(event) => { onInputValueChange(event, 'recipientZipCode'); }}
                        style={styles.input}
                      />
                      <input
                        defaultValue={recipientAddress}
                        onChange={(event) => { onInputValueChange(event, 'recipientAddress'); }}
                        style={styles.input}
                      />
                    </div>
                  </React.Fragment>
                )
                  : (
                    <div style={styles.addressInputContainer}>
                      <div>
                        {recipientZipCode}
                      </div>
                      <div>
                        {recipientAddress}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>

        <div style={isMobile ? styles.infoDataMobileContainer : styles.infoDataContainer}>
          <div style={styles.clickContainer}>
            <input
              type="checkbox"
              defaultChecked={isSevenElevenReceive}
              onChange={(event) => { onInputValueChange(event, 'isSevenElevenReceive'); }}
              style={styles.checkInput}
            />
          </div>
          <div style={styles.shippingContainer}>
            <img
              src={Assets.memberShippingStoreIcon}
              style={styles.shippingIcon}
              alt=""
            />
            <div style={styles.shippingText}>
              7-11店到店
            </div>
          </div>
          <div style={isMobile ? styles.infoMobileContainer : styles.infoContainer}>
            <h1 style={styles.title}>
              Pick-Up
              <br />
              <span style={styles.subTitle}>
                超取資訊
              </span>
            </h1>
            <div style={isMobile ? styles.inputBlockMobileContainer : styles.inputBlockContainer}>
              <div style={styles.inputText}>
                取件姓名：
              </div>
              <div style={styles.inputBlock}>
                { isEdit ? (
                  <input
                    defaultValue={recipientName}
                    onChange={(event) => { onInputValueChange(event, 'recipientName'); }}
                    style={styles.input}
                  />
                ) : recipientName}
              </div>
            </div>
            <div style={isMobile ? styles.inputBlockMobileContainer : styles.inputBlockContainer}>
              <div style={styles.inputText}>
                取件電話：
              </div>
              <div style={styles.inputBlock}>
                { isEdit ? (
                  <input
                    defaultValue={recipientPhone}
                    onChange={(event) => { onInputValueChange(event, 'recipientPhone'); }}
                    style={styles.input}
                  />
                ) : recipientPhone}
              </div>
            </div>
            <div style={isMobile ? styles.inputBlockMobileContainer : styles.inputBlockContainer}>
              <div style={styles.inputText}>
                取件超商：
              </div>
              <div style={styles.inputBlock}>
                { isEdit ? (
                  <select
                    defaultValue={selectStore}
                    onChange={(event) => { onInputValueChange(event, 'selectStore'); }}
                    style={styles.inputText}
                  >
                    <option
                      key="0"
                      value="0"
                    />
                    <option
                      key="seven-Eleven"
                      value={shippingTypeId.sevenEleven}
                    >
                      7-11
                    </option>
                  </select>
                ) : shippingType[selectStore]}
              </div>
            </div>
            <div style={isMobile ? styles.inputBlockMobileContainer : styles.inputBlockContainer}>
              <div style={styles.inputText}>
                門市名稱：
              </div>
              <div style={styles.inputBlock}>
                { isEdit ? (
                  <React.Fragment>
                    <div style={styles.addressInputContainer}>
                      <input
                        defaultValue={sevenElevenMarket}
                        onChange={(event) => { onInputValueChange(event, 'sevenElevenMarket'); }}
                        style={styles.input}
                      />
                    </div>
                  </React.Fragment>
                )
                  : (
                    <div style={styles.addressInputContainer}>
                      {sevenElevenMarket}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
    isEdit ? (
      <Button
        text="送出"
        onClick={() => {
          onSubmitMemberInfoChange();
          setIsEdit(false);
        }}
        style={styles.submitButton}
      />
    ) : '出貨資訊'
  }
    </React.Fragment>
  );

  return (
    <ReduxStatusVisualizedComponent
      showModalOnError
      modalFailureContent={reduxError}
      reduxStatus={reduxStatus}
    >

      <div style={styles.mainContainer}>
        <MemberHeader
          reduxStatus
          path={Paths.ShippingInfoPage}
          category="shippingInfo"
        />

        <div style={styles.shippingInfoContainer}>
          <div style={styles.shippingInfoTitle}>
            物流資訊
          </div>

          <Button
            text="編輯"
            onClick={() => { setIsEdit(!isEdit); }}
            style={isEdit ? styles.editButton : styles.unEditButton}
          />
          <Default>
            {renderContent()}
          </Default>
          <Mobile>
            {renderContent(true)}
          </Mobile>
        </div>

      </div>
    </ReduxStatusVisualizedComponent>
  );
};

ShippingInfoPage.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  memberData: PropTypes.object.isRequired,
  onInputValueChange: PropTypes.func.isRequired,
  onSubmitMemberInfoChange: PropTypes.func.isRequired,
  recipientName: PropTypes.string,
  recipientPhone: PropTypes.string,
  recipientZipCode: PropTypes.string,
  recipientAddress: PropTypes.string,
  selectStore: PropTypes.string,
  sevenElevenMarket: PropTypes.string,
};

ShippingInfoPage.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  recipientName: '',
  recipientPhone: '',
  recipientZipCode: '',
  recipientAddress: '',
  selectStore: '0',
  sevenElevenMarket: '',
};

export default ShippingInfoPage;
