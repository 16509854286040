import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  getNegotiationListRequest,
  getNegotiationListSuccess,
  getNegotiationListFailure,
  submitNegotiationRequest,
  submitNegotiationSuccess,
  submitNegotiationFailure,

  negotiationWithOhPhireFlowRequest,
  negotiationWithOhPhireSuccess,
  negotiationWithOhPhireFailure,
  acceptAmountFlowRequest,
  acceptAmountSuccess,
  acceptAmountFailure,

  replyToMessageFlowRequest,
  replyToMessageSuccess,
  replyToMessageFailure,

  resetStatus,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
};

export default handleActions({
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: initialState.reduxStatus },
  }),

  [combineActions(
    getNegotiationListRequest,
    submitNegotiationRequest,
    negotiationWithOhPhireFlowRequest,
    acceptAmountFlowRequest,
    replyToMessageFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: null },
  }),

  [combineActions(
    getNegotiationListFailure,
    submitNegotiationFailure,
    negotiationWithOhPhireFailure,
    acceptAmountFailure,
    replyToMessageFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),

  [getNegotiationListSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
    data: { $set: payload.data },
    error: { $set: null },
  }),

  [combineActions(
    submitNegotiationSuccess,
    negotiationWithOhPhireSuccess,
    acceptAmountSuccess,
    replyToMessageSuccess,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
  }),

}, initialState);
