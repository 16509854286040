import React from 'react';
import PropTypes from 'prop-types';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';

const styles = {
  mainContainer: {
    width: 'fit-content',
    margin: 'auto',
    marginTop: '50px',
  },
};

const LogoutPage = ({
  reduxStatus,
}) => (
  <div style={styles.mainContainer} key="baseContainer">
    <ReduxStatusVisualizedComponent
      reduxStatus={reduxStatus}
    />
  </div>
);


LogoutPage.propTypes = {
  reduxStatus: PropTypes.string,
};

LogoutPage.defaultProps = {
  reduxStatus: '',
};

export default LogoutPage;
