import api from '../../lib/configAxios';

export default {
  getTagList: async () => api.get(
    'Tags',
  ),

  getTagCategoryList: async () => api.get(
    'TagCategories',
  ),
};
