import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Spin,
} from 'antd';
import ModalComponent from './ModalComponent';
import ReduxStatus, { severeLevel } from '../constants/ReduxStatus';

const styles = {
  errorMessage: {
    color: 'red',
    width: '100%',
    textAlign: 'center',
    padding: '20px',
  },
};

const errorIndicator = (
  <div style={styles.errorMessage}>
    <Icon type="exclamation-circle" />
    發生錯誤 請稍後再試
  </div>
);

const ReduxStatusVisualizedComponent = ({
  reduxStatus,
  children,
  containerStyle,
  showModalOnError,
  modalFailureTitle,
  modalFailureContent,
}) => {
  // NOTE: When there're more than on status that are passed from the parent, we should determine
  //       the critical status as our final status to display according to their servereness.
  let determinedStatus = null;
  if (typeof reduxStatus === 'string') {
    determinedStatus = reduxStatus;
  } else if (reduxStatus) {
    reduxStatus.forEach((status) => {
      if ((severeLevel[determinedStatus] || 0) < (severeLevel[status] || -1)) {
        determinedStatus = status;
      }
    });
  }

  // NOTE: [Why do we need double layered buffer?]
  //       Since it's quiet frequent for the parent to pass the old reduxStatus to this component.
  //       For instance, status that was produced and left by the last user's operation.
  //       Thus we use double layered buffer in order to skip out the status that comes at the very first time.
  const [statusBuffer, setStatusBuffer] = useState(determinedStatus);
  const [currentStatus, setCurrentStatus] = useState(ReduxStatus.STATUS_INITIAL);
  useEffect(() => {
    if (statusBuffer !== determinedStatus) {
      setStatusBuffer(determinedStatus);
      setCurrentStatus(determinedStatus);
    }
  }, [determinedStatus]);


  let content = null;
  let showSpin = false;
  // modal data
  let showModal = showModalOnError;
  let modalTitle = null;
  let modalContent = null;

  switch (currentStatus) {
    case ReduxStatus.STATUS_LOADING:
      showModal = false;
      showSpin = true;
      content = children;
      break;
    case ReduxStatus.STATUS_ERROR:
      if (showModal) {
        content = children;
        modalTitle = modalFailureTitle;
        modalContent = modalFailureContent;
      } else {
        content = errorIndicator;
      }
      break;
    case ReduxStatus.STATUS_INITIAL:
      showModal = false;
      content = children;
      break;
    case ReduxStatus.STATUS_NORMAL:
      showModal = false;
      content = children;
      break;
    default:
      content = children;
      break;
  }

  return (
    <div style={containerStyle}>
      <ModalComponent
        isVisible={showModal}
        title={modalTitle}
        content={modalContent}
      />
      <Spin tip="Loading..." spinning={showSpin}>
        {content}
      </Spin>
    </div>
  );
};

ReduxStatusVisualizedComponent.propTypes = {
  reduxStatus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  containerStyle: PropTypes.shape({}),
  showModalOnError: PropTypes.bool,
  modalFailureTitle: PropTypes.string,
  modalFailureContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

ReduxStatusVisualizedComponent.defaultProps = {
  reduxStatus: [],
  children: null,
  containerStyle: {},
  showModalOnError: false,
  modalFailureTitle: null,
  modalFailureContent: null,
};

export default ReduxStatusVisualizedComponent;
