import * as R from 'ramda';
import configEnv from './configEnv';

class TapPayService {
  loadScript = (primeResultCallback) => {
    if (R.path(['TPDirect'], window)) {
      const TPDirect = R.path(['TPDirect'], window);
      TPDirect.setupSDK(configEnv.TapPayAppID, configEnv.TapPayAppKey, configEnv.TapPayType);
      const defaultCardViewStyle = {
        color: 'rgb(0,0,0)',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '300',
        errorColor: 'red',
        placeholderColor: '',
      };
      const config = {
        isUsedCcv: true,
      };
      TPDirect.card.setup('#tappay-iframe', defaultCardViewStyle, config);
      document.querySelector('#submit').addEventListener('click', () => {
        TPDirect.card.getPrime((result) => {
          primeResultCallback(
            result,
          );
        });
      });
    }
  };
}

export default new TapPayService();
