import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm, Button } from 'antd/lib/index';
import * as R from 'ramda';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bagContainer: {
    border: '1px solid #ECECEC',
    position: 'relative',
    margin: 10,
    width: 110,
    height: 110,
  },
  image: {
    position: 'relative',
    height: 90,
    width: 90,
    top: 10,
    left: 10,
    right: 10,
    objectFit: 'cover',
  },
  remove: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#ECECEC',
  },
  bagName: {
    fontSize: 12,
    color: '#010101',
    textAlign: 'center',
  },

};

const ReduceDepositBagItem = ({
  showRemoveButton,
  data,
  onDeleteReduceDepositBag,
}) => (
  <div style={styles.mainContainer}>
    <div style={styles.bagContainer}>
      <img
        alt=""
        style={styles.image}
        src={R.pathOr('', ['frontPic', 'uri'], data)}
      />
      {showRemoveButton
      && (
      <Popconfirm
        title="是否刪除此商品?"
        onConfirm={onDeleteReduceDepositBag}
        okText="Yes"
        cancelText="No"
      >
        <Button icon="close" type="link" style={styles.remove} />
      </Popconfirm>
      )
      }
    </div>
    <div style={styles.bagName}>{R.pathOr('', ['name'], data)}</div>
  </div>
);


ReduceDepositBagItem.propTypes = {
  data: PropTypes.object,
  onDeleteReduceDepositBag: PropTypes.func,
  showRemoveButton: PropTypes.bool,
};

ReduceDepositBagItem.defaultProps = {
  data: {},
  showRemoveButton: true,
  onDeleteReduceDepositBag: () => {},
};

export default ReduceDepositBagItem;
