import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('TRANSACTION'),
  ...createAsyncActionTypesArray('ZERO_CARD_TRANSACTION'),

  // ----- FLOW -----
  ...createAsyncActionTypesArray('TRANSACTION_FLOW'),
  ...createAsyncActionTypesArray('ZERO_CARD_TRANSACTION_FLOW'),

  'RESET_TRANSACTION_STATUS',

  { prefix: 'TRANSACTION' },
);
