import { handleActions, combineActions } from 'redux-actions';
import update from 'immutability-helper';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  getCartListFlowRequest,
  getCartListSuccess,
  getCartListFailure,

  deleteCartFlowRequest,
  deleteCartSuccess,
  deleteCartFailure,

  addCartFlowRequest,
  addCartSuccess,
  addCartFailure,

  resetCartRequest,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
};

export default handleActions({
  // request
  [combineActions(
    getCartListFlowRequest,
    deleteCartFlowRequest,
    addCartFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: null },
  }),

  [resetCartRequest]: state => update(state, {
    data: { $set: [] },
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
    error: { $set: null },
  }),

  // success
  [getCartListSuccess]: (state, { payload }) => update(state, {
    data: { $set: payload },
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    error: { $set: null },
  }),
  [combineActions(
    deleteCartSuccess,
    addCartSuccess,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    error: { $set: null },
  }),


  // failure
  [combineActions(
    getCartListFailure,
    deleteCartFailure,
    addCartFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
}, initialState);
