import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

const Mobile = ({
  children,
  width,
}) => {
  const maxWidth = width || 1100;
  const isMobile = useMediaQuery({ maxWidth });
  return isMobile ? children : null;
};

Mobile.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
};

Mobile.defaultProps = {
  width: null,
};

export default Mobile;
