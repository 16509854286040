import React from 'react';
import PropsType from 'prop-types';
import InfoModalComponent from './InfoModalComponent';
import Assets from '../constants/Assets';
import Default from './RWDComponent/Default';
import Mobile from './RWDComponent/Mobile';

const styles = {
  mainContainer: {
    width: 720,
    height: 420,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  mainMobileContainer: {
    width: '90vw',
    height: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  leftContainer: {
    display: 'flex',
    padding: '40px 40px',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '60%',
    height: '100%',
  },
  rightImgContainer: {
    width: '40%',
    height: '100%',
    objectFit: 'cover',
  },
  title: {
    color: '#6F6F6F',
    fontSize: 40,
    fontFamily: 'Baskerville, serif',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  content: {
    marginLeft: 20,
    fontWeight: 100,
    fontSize: 16,
    color: '#6F6F6F',
  },
  contentMobile: {
    marginLeft: 0,
    fontWeight: 100,
    fontSize: 16,
    color: '#6F6F6F',
  },
  subContent: {
    fontWeight: 200,
    fontSize: 12,
    color: 'black',
  },
  subTitle: {
    fontFamily: 'Microsoft JhengHei Bold',
    fontSize: 14,
    margin: '20px 0px',
    color: 'black',
  },
  divider: {
    margin: '20px 0px',
    backgroundColor: '#F2EFEF',
    height: 1,
    width: '100%',
  },
  picMobile: {
    width: '100%',
    height: '320px',
    objectFit: 'cover',
  },
  mobileTextContainer: {
    padding: '20px 20px 60px 20px',
  },
};

const ProtectTreeComponent = ({
  isVisible,
  onModalClose,
}) => {
  const renderContent = (
    <div style={styles.mainContainer} key="baseContainer">
      <div style={styles.leftContainer}>
        <div style={styles.title}>
          Environmental
        </div>
        <div style={styles.titleContainer}>
          <div style={styles.title}>
            Protection
          </div>
          <div style={styles.content}>啟動時尚的光合作用</div>
        </div>
        <div style={styles.subTitle}>
          每次成功購買 ，將認養一棵大樹並照顧6個月
          <br />
          每次成功租借 ，將認養一棵大樹並照顧1個月
        </div>
        <div style={styles.subContent}>
          每次成功消費Oh!Phire就會認養一棵樹
          <br />
          一起用行動保護地球！
        </div>
        <div style={styles.divider} />
        <div style={styles.subContent}>*倒數表為倒數計時護樹時間</div>
      </div>
      <img style={styles.rightImgContainer} alt="" src={Assets.protectTreeDialog} />
    </div>
  );

  const renderMobileContent = (
    <div style={styles.mainMobileContainer} key="baseContainer">
      <img alt="" style={styles.picMobile} src={Assets.protectTreeDialog} />
      <div style={styles.mobileTextContainer}>
        <div style={styles.title}>
          Environmental
        </div>
        <div style={styles.title}>
          Protection
        </div>
        <div style={styles.contentMobile}>啟動時尚的光合作用</div>
        <div style={styles.subTitle} />
        <div style={styles.subContent}>
          每次成功購買 ，將認養一棵大樹並照顧6個月
          <br />
          每次成功租借 ，將認養一棵大樹並照顧1個月
        </div>
        <div style={styles.divider} />
        <div style={styles.subContent}>*倒數表為倒數計時護樹時間</div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={renderContent}
          containerStyle={{ paddingTop: 150 }}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={renderMobileContent}
          containerStyle={{ paddingTop: '10vh' }}
        />
      </Mobile>
    </React.Fragment>
  );
};
ProtectTreeComponent.propTypes = {
  isVisible: PropsType.bool,
  onModalClose: PropsType.func.isRequired,
};

ProtectTreeComponent.defaultProps = {
  isVisible: false,
};

export default ProtectTreeComponent;
