import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './styles.css';
import envConfig from './lib/configEnv';


ReactDOM.render(<App />, document.getElementById('root'));

// This for implant Google Tag Manager for product environment
if (envConfig.env === 'prod') {
  const gaHeadScript = document.createElement('script');
  const gaBodyScript = document.createElement('noscript');
  const gaIframe = document.createElement('iframe');

  gaHeadScript.innerHTML = (`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-TTG9TGJ');`);

  gaIframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=GTM-TTG9TGJ');
  gaIframe.setAttribute('height', 0);
  gaIframe.setAttribute('width', 0);
  gaIframe.setAttribute('style', 'display:none;visibility:hidden');

  gaBodyScript.appendChild(gaIframe);
  document.head.appendChild(gaHeadScript);
  document.body.appendChild(gaBodyScript);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
