import * as R from 'ramda';
import { createSelector } from 'reselect';
import { orderStateSelector } from '../../lib/rootSelector';


export const orderCreateSelector = createSelector(orderStateSelector,
  result => R.path(['orderCreate'], result));

export const orderUpdateSelector = createSelector(orderStateSelector,
  result => R.path(['orderUpdate'], result));

export const withdrawRequestSelector = createSelector(orderStateSelector,
  result => R.path(['withdrawRequest'], result));

export const orderListSelector = createSelector(orderStateSelector,
  result => R.path(['orderList'], result));

export const orderNumbersSelector = createSelector(orderStateSelector,
  result => R.path(['data'], result));

export const getOrderAddressSelector = createSelector(orderStateSelector,
  result => R.path(['orderAddress'], result));

export const orderDetailSelector = createSelector(orderStateSelector,
  result => R.path(['orderDetail'], result));

export const sendCommentSelector = createSelector(orderStateSelector,
  result => R.path(['sendComment'], result));

export const orderListDataSelector = createSelector(orderListSelector,
  result => R.path(['data'], result));

export const orderListReduxStatusSelector = createSelector(orderListSelector,
  result => R.path(['reduxStatus'], result));

export const orderDetailReduxStatusSelector = createSelector(orderDetailSelector,
  result => R.path(['reduxStatus'], result));

export const orderDetailDataStatusSelector = createSelector(orderDetailSelector,
  result => R.path(['data'], result));

export const donateOrganizationSelector = createSelector(orderStateSelector,
  result => R.pathOr(null, ['donateOrganization'], result));

export const orderCreateReduxStatusSelector = createSelector(
  orderCreateSelector,
  result => R.path(['reduxStatus'], result),
);

export const orderUpdateReduxStatusSelector = createSelector(
  orderUpdateSelector,
  result => R.path(['reduxStatus'], result),
);

export const withdrawRequestReduxStatusSelector = createSelector(
  withdrawRequestSelector,
  result => R.path(['reduxStatus'], result),
);

export const orderCreateDataSelector = createSelector(
  orderCreateSelector,
  result => R.path(['data'], result),
);

export const orderCreateErrorSelector = createSelector(
  orderCreateSelector,
  result => R.path(['error', 'message'], result),
);

export const orderUpdateErrorSelector = createSelector(
  orderUpdateSelector,
  result => R.path(['error', 'message'], result),
);

export const withdrawRequestErrorSelector = createSelector(
  withdrawRequestSelector,
  result => R.path(['error', 'message'], result),
);

export const orderListErrorSelector = createSelector(
  orderListSelector,
  result => R.path(['error', 'message'], result),
);

export const orderDetailErrorSelector = createSelector(
  orderDetailSelector,
  result => R.path(['error', 'message'], result),
);

export const getDonateOrganizationDataSelector = createSelector(
  donateOrganizationSelector,
  result => R.path(['data'], result),
);
export const sendCommentErrorSelector = createSelector(
  sendCommentSelector,
  result => R.path(['error', 'message'], result),
);
export const sendCommentReduxStatusSelector = createSelector(
  sendCommentSelector,
  result => R.path(['reduxStatus'], result),
);
