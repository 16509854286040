export const FontSize = {
  title: 38,
  subTitle: 16,
  body: 13,
  overline: 10,
  aboutUsTitle: 60,
  aboutUsBody: 20,
};


export default {
  FontSize,
};
