import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ThemeListPage from './ThemeListPage';
import ActionCreator from '../../ActionCreator';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  themeListSelector,
} from '../../selector';

const ThemeListPageContainer = ({
  reduxStatus,
  reduxError,
  data,
  actions: {
    resetStatus,
    getThemeListFlowRequest,
  },
}) => {
  useEffect(() => {
    getThemeListFlowRequest();
    return resetStatus;
  }, []);
  return (
    <ThemeListPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
    />
  );
};

ThemeListPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  data: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    getThemeListFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
};

ThemeListPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: themeListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreator,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemeListPageContainer);
