import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  resetTransactionStatus,

  transactionFlowRequest,
  transactionSuccess,
  transactionFailure,

  zeroCardTransactionFlowRequest,
  zeroCardTransactionSuccess,
  zeroCardTransactionFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: null,
};

export default handleActions({
  [resetTransactionStatus]: state => update(state, {
    reduxStatus: { $set: initialState.reduxStatus },
    data: { $set: initialState.data },
    error: { $set: initialState.error },
  }),

  // request
  [transactionFlowRequest]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    data: { $set: initialState.data },
    error: { $set: initialState.error },
  }),
  [zeroCardTransactionFlowRequest]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    data: { $set: initialState.data },
    error: { $set: initialState.error },
  }),

  // success
  [transactionSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
    error: { $set: initialState.error },
  }),
  [zeroCardTransactionSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
    error: { $set: initialState.error },
  }),


  // failure
  [transactionFailure]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    data: { $set: null },
    error: { $set: payload },
  }),
  [zeroCardTransactionFailure]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    data: { $set: null },
    error: { $set: payload },
  }),
}, initialState);
