import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
} from 'antd';

const styles = {
  buttonStyle: {
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    position: 'absolute',
    right: 0,
  },
  icon: {
    fontSize: 24,
  },
};

const MyFavoriteComponent = ({
  onClickRemoveCart,
  setIsInCart,
  onClickAddToCart,
  isInCart,
}) => (
  isInCart ? (
    <button
      type="button"
      onClick={() => {
        onClickRemoveCart();
        setIsInCart(false);
      }}
      style={styles.buttonStyle}
    >
      <Icon
        type="heart"
        theme="filled"
        style={styles.icon}
      />
    </button>
  ) : (
    <button
      type="button"
      onClick={(event) => {
        onClickAddToCart();
        event.stopPropagation();
      }}
      style={styles.buttonStyle}
    >
      <Icon
        type="heart"
        style={styles.icon}
      />
    </button>
  )
);

MyFavoriteComponent.propTypes = {
  onClickRemoveCart: PropTypes.func,
  onClickAddToCart: PropTypes.func,
  setIsInCart: PropTypes.func,
  isInCart: PropTypes.bool,
};

MyFavoriteComponent.defaultProps = {
  isInCart: false,
  onClickRemoveCart: () => {},
  onClickAddToCart: () => {},
  setIsInCart: () => {},
};

export default MyFavoriteComponent;
