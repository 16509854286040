import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ActionCreators from '../../ActionCreator';
import MyRentedSoldOrderListPage from './MyRentedSoldOrderListPage';
import ReduxStatus from '../../../../constants/ReduxStatus';

import {
  orderListDataSelector,
  orderListErrorSelector,
  orderListReduxStatusSelector,
  withdrawRequestReduxStatusSelector,
  withdrawRequestErrorSelector,
  orderUpdateReduxStatusSelector,
} from '../../selector';

import {
  memberIdSelector,
  memberPassbaseVerifySelector,
} from '../../../Member/selector';


const MyRentedSoldOrderListContainer = ({
  actions: {
    withdrawRequestFlowRequest,
    getMyRentedSoldOrderListFlowRequest,
    orderUpdateFlowRequest,
  },
  orderList,
  userId,
  orderListError,
  orderListReduxStatus,
  withdrawRequestError,
  withdrawRequestReduxStatus,
  orderUpdateReduxStatus,
  isVerify,
}) => {
  const [isShowWithdrawRequestSuccess, setIsShowWithdrawRequestSuccessModal] = useState(false);
  useEffect(() => {
    getMyRentedSoldOrderListFlowRequest({ userId });
  }, []);

  useEffect(() => {
    if (withdrawRequestReduxStatus === ReduxStatus.STATUS_NORMAL) {
      setIsShowWithdrawRequestSuccessModal(true);
      getMyRentedSoldOrderListFlowRequest({ userId });
    }
  }, [withdrawRequestReduxStatus]);

  const handleOnSubmitWithdrawRequest = async (data) => {
    const orderId = R.pathOr('', ['id'], data);
    withdrawRequestFlowRequest({
      orderId,
      userId,
    });
  };
  const handleOnModalConfirm = () => {
    if (withdrawRequestReduxStatus === ReduxStatus.STATUS_NORMAL) {
      setIsShowWithdrawRequestSuccessModal(false);
    }
  };

  const handleOnConfirmOrder = (payload) => {
    const { orderId } = payload;
    orderUpdateFlowRequest({
      isFromOrderList: false, orderStatusId: 20, orderId,
    });
  };

  return (
    <MyRentedSoldOrderListPage
      userId={userId}
      orderListError={orderListError}
      orderListReduxStatus={orderListReduxStatus}
      withdrawRequestError={withdrawRequestError}
      withdrawRequestReduxStatus={withdrawRequestReduxStatus}
      orderUpdateReduxStatus={orderUpdateReduxStatus}
      orderList={orderList}
      onSubmitWithdrawRequest={handleOnSubmitWithdrawRequest}
      onModalConfirmed={handleOnModalConfirm}
      isShowWithdrawRequestSuccess={isShowWithdrawRequestSuccess}
      onConfirmOrder={handleOnConfirmOrder}
      isVerify={isVerify}
    />
  );
};
MyRentedSoldOrderListContainer.propTypes = {
  actions: PropTypes.shape({
    getMyRentedSoldOrderListFlowRequest: PropTypes.func.isRequired,
    withdrawRequestFlowRequest: PropTypes.func.isRequired,
    orderUpdateFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  orderList: PropTypes.array,
  userId: PropTypes.number,
  orderListReduxStatus: PropTypes.string,
  orderListError: PropTypes.string,
  withdrawRequestReduxStatus: PropTypes.string,
  withdrawRequestError: PropTypes.string,
  orderUpdateReduxStatus: PropTypes.string,
  isVerify: PropTypes.bool,
};

MyRentedSoldOrderListContainer.defaultProps = {
  orderList: [],
  userId: null,
  orderListReduxStatus: null,
  orderListError: '',
  withdrawRequestReduxStatus: null,
  withdrawRequestError: '',
  orderUpdateReduxStatus: '',
  isVerify: false,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

const mapStateToProps = state => ({
  orderList: orderListDataSelector(state),
  userId: memberIdSelector(state),
  orderListReduxStatus: orderListReduxStatusSelector(state),
  orderListError: orderListErrorSelector(state),
  withdrawRequestReduxStatus: withdrawRequestReduxStatusSelector(state),
  withdrawRequestError: withdrawRequestErrorSelector(state),
  orderUpdateReduxStatus: orderUpdateReduxStatusSelector(state),
  isVerify: memberPassbaseVerifySelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyRentedSoldOrderListContainer);
