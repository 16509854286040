import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';
import * as R from 'ramda';
import { Link } from '@reach/router';
import LazyLoad from 'react-lazyload';
import BagStatus from '../constants/BagStatus';
import Default from './RWDComponent/Default';
import Mobile from './RWDComponent/Mobile';
import { writeData } from '../utils/LocalStorageUtils';
import LocalStorageKey from '../constants/LocalStorageKey';
import { FontSize } from '../constants/FontSize';
import Assets from '../constants/Assets';
import { rentAmountCalculate } from '../utils/rentAmountCalculate';
import amountPattern from '../utils/AmountPattern';
import bagOrClothesPath from '../utils/bagOrClothesPath';
import SizeBLockComponent from './SizeBLockComponent';
import MyFavoriteComponent from './MyFavoriteComponent';

const NextImg = styled.img.attrs({
  src: Assets.bagItemSliderRightArrow,
})`
  margin-right: 20px;
  width: 24px;
  height: 24px;
  z-index: 1;
`;

const PreImg = styled.img.attrs({
  src: Assets.bagItemSliderLeftArrow,
})`
  margin-left: 20px;
  z-index: 1;
  width: 24px;
  height: 24px;
`;

const UnavailableText = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 210px;
    height: 360px;
    background-color: #0D0D0D88;
    color: white;
    &:after{
      content: ' ';
      position: absolute;
      left: 30px;
      bottom: 60px;
      width: 150px;
      height: 1px;
      background-color: #F1F2F2;
    }
    & > div:first-child {
      font-weight: 600;
      font-size: 20px;
      text-align: left;
      line-height: 30px;
      margin-top: 230px;
      position: absolute;
      left: 30px;
    }
    & > div:nth-child(2) {
      width: 100%;
      font-size: 15px;
      text-align: right;
      padding: 5px 30px;
      margin-top: 300px;
    }
`;

const UnavailableTextMobile = styled.div`
    position: absolute;
    top: 60;
    left: 10;
    width: 150px;
    height: 360px;
    background-color: #0D0D0D88;
    color: white;
    &:after{
      content: ' ';
      position: absolute;
      left: 11px;
      bottom: 180px;
      width: 140px;
      height: 1px;
      background-color: #F1F2F2;
    }
    & > div:first-child {
      font-weight: 600;
      font-size: 20px;
      text-align: left;
      line-height: 30px;
      margin-top: 150px;
      position: absolute;
      left: 12px;
    }
    & > div:nth-child(2) {
      width: 100%;
      font-size: 12px;
      text-align: right;
      padding: 5px 30px;
      margin-top: 220px;
    }
`;

const styles = {
  previewImageContainer: {
    position: 'relative',
    width: 210,
    height: 550,
    verticalAlign: 'top',
    overflow: 'visible',
    cursor: 'pointer',
    margin: '5px 38px',
    display: 'inline-block',
    color: 'black',
  },
  previewImageMobileContainer: {
    position: 'relative',
    width: 160,
    height: 390,
    verticalAlign: 'top',
    padding: '0 10px',
    overflow: 'visible',
    cursor: 'pointer',
    margin: '5px 5px',
    display: 'inline-block',
    color: 'black',
  },
  mainContainer: {
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'visible',
    marginTop: 30,
  },
  image: {
    width: '100%',
    height: '360px',
    objectFit: 'contain',
  },
  imageMobile: {
    width: '100%',
    height: '220px',
    objectFit: 'contain',
  },
  tags: {
    textAlign: 'center',
    padding: '5px 0px 5px 0px',
    fontSize: FontSize.subTitle,
  },
  text: {
    fontSize: FontSize.overline,
    textAlign: 'center',
    fontWeight: '400',
  },
  originalPrice: {
    fontSize: FontSize.overline,
    textDecoration: 'line-through',
    textAlign: 'center',
    fontWeight: '400',
  },
  typeContainer: {
    position: 'absolute',
    bottom: '145px',
    left: '5px',
    textAlign: 'start',
    backgroundColor: '#E6E7E8',
    color: '#939292',
    width: 'auto',
    minWidth: '40px',
    padding: '3px 10px',
    fontSize: FontSize.overline,
    borderRadius: '20px',
  },
  typeOPContainer: {
    position: 'absolute',
    bottom: '145px',
    right: '5px',
    textAlign: 'center',
    backgroundColor: '#E6E7E8',
    color: '#939292',
    width: 'auto',
    minWidth: '50px',
    padding: '3px 10px',
    fontSize: FontSize.overline,
    borderRadius: '20px',
  },
  typeMobileContainer: {
    textAlign: 'center',
    backgroundColor: '#E6E7E8',
    color: '#939292',
    width: 'fit-content',
    padding: '3px 10px',
    fontWeight: '400',
    borderRadius: '20px',
    fontSize: 12,
    position: 'absolute',
    left: '-10px',
    display: 'table',
  },
  opMangerIconContainer: {
    width: '100%',
    height: 'auto',
    position: 'relative',
    left: '50%',
    transform: 'translate(-50%, 0)',
    objectFit: 'contain',
    borderTop: '1px #D0D0D0 solid',
    paddingTop: '5px',
    minHeight: '35px',
  },
  opMangerIconMobileContainer: {
    width: '70px',
    height: 'auto',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
    objectFit: 'contain',
  },
  opMangerIcon: {
    width: '90px',
    height: '100%',
  },
  opMangerMobileIcon: {
    width: '60px',
    height: '100%',
  },
  opMangerNoteContainer: {
    position: 'absolute',
    width: '200px',
    height: '60px',
    backgroundColor: 'grey',
    color: 'white',
    bottom: '200px',
    left: '10px',
    padding: '10px 0px',
  },
  opMangerNoteMobileContainer: {
    textAlign: 'center',
    position: 'absolute',
    width: '140px',
    height: '50px',
    backgroundColor: 'grey',
    color: 'white',
    bottom: '45px',
    padding: '10px 0px',
    fontSize: '10px',
  },
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '15px 15px 0 15px',
    borderColor: 'grey transparent transparent transparent',
    position: 'absolute',
    bottom: '190px',
    left: '80px',
  },
  triangleMobile: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '15px 15px 0 15px',
    borderColor: 'grey transparent transparent transparent',
    position: 'absolute',
    bottom: '35px',
    right: '45px',
  },
  tagsAndIconContainer: {
    position: 'relative',
    height: '30px',
  },
  sizeStyle: {
    justifyContent: 'center',
  },
  allNewIconContainer: {
    position: 'absolute',
    left: 0,
    width: '30px',
    height: '30px',
  },
  allNewIcon: {
    width: '100%',
    height: '100%',
  },
  sellOutText: {
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    bottom: '70px',
  },
  sliderContainer: {
    width: '100%',
    zIndex: 0,
  },
};

const tagSortMethod = (a, b) => {
  const tagCategoryIdOfA = R.pathOr(0, ['tagCategoryId'], a);
  const tagCategoryIdOfB = R.pathOr(0, ['tagCategoryId'], b);
  if (tagCategoryIdOfA !== tagCategoryIdOfB) return tagCategoryIdOfA - tagCategoryIdOfB;
  const orderOfA = R.pathOr(0, ['order'], a);
  const orderOfB = R.pathOr(0, ['order'], b);
  return orderOfA - orderOfB;
};

const BagItem = ({
  style,
  isBuyArea,
  setIsShowLoginModal,
  ...restOfBagData
}) => {
  const [isShowOPManageNote, setIsShowOPManageNote] = useState(false);
  const [isShowMultiPreviewImage, setIsShowMultiPreviewImage] = useState(false);
  const id = R.pathOr('', ['id'], restOfBagData);
  const newLevel = R.pathOr(0, ['newLevel'], restOfBagData);
  const bagNo = R.pathOr('', ['bagNo'], restOfBagData);
  const frontPicUrl = R.pathOr(null, ['frontPic', 'uri'], restOfBagData);
  const backPicUrl = R.pathOr(null, ['backPic', 'uri'], restOfBagData);
  const bottomPicUrl = R.pathOr(null, ['bottomPic', 'uri'], restOfBagData);
  const sidePicUrl = R.pathOr(null, ['sidePic', 'uri'], restOfBagData);
  const zipperPicUrl = R.pathOr(null, ['zipperPic', 'uri'], restOfBagData);
  const rentAmountPerDay = R.path(['rentAmountPerDay'], restOfBagData) || null;
  const originalPrice = R.pathOr(0, ['originalPrice'], restOfBagData);
  const washAmount = R.pathOr(0, ['washAmount'], restOfBagData);
  const tags = R.pathOr('', [0, 'name'], R.pathOr([], ['tags'], restOfBagData).sort(tagSortMethod));
  const tagsData = R.pathOr([], ['tags'], restOfBagData);
  const clothesTag = tagsData.find(item => item.tagCategoryId === 4);
  const clothesTagName = R.pathOr('', ['name'], clothesTag);
  // TODO: The rent times just comment in the early time.
  // const rentCount = R.pathOr(0, ['rentCount'], restOfBagData);
  const bagStatusId = R.pathOr(0, ['bagStatusId'], restOfBagData);
  const type = R.pathOr('rent', ['type', 'name'], restOfBagData);
  const commodityType = R.pathOr(1, ['commodityTypeId'], restOfBagData);
  const sizeStandard = R.pathOr(null, ['sizeStandard'], restOfBagData);
  const sizeNumber = R.pathOr(null, ['sizeNumber'], restOfBagData);
  const isOhPhireManage = R.pathOr(false, ['isOhPhireManage'], restOfBagData);


  const renderAmount = () => {
    const renderSellPrice = () => {
      if (bagStatusId === BagStatus.bagStatusId.sold) return '已售出';

      return `售價 NT$ ${amountPattern(R.pathOr(0, ['buyoutAmount'], restOfBagData))}`;
    };
    const renderOriginPrice = () => {
      if (originalPrice > 0) {
        return (
          <div style={styles.originalPrice}>
            {`NT$ ${amountPattern(originalPrice)}`}
          </div>
        );
      }
      return null;
    };

    if (type === 'buy') {
      return (
        <React.Fragment>
          <div>
            {renderSellPrice()}
          </div>
          {renderOriginPrice()}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {
          isBuyArea ? (
            <React.Fragment>
              <div>
                {type !== 'rent' && renderSellPrice()}
              </div>
              {renderOriginPrice()}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                {
                  bagStatusId === BagStatus.bagStatusId.sold ? '已售出' : (
                    `租借 $${Math.ceil(
                      rentAmountCalculate(rentAmountPerDay, 4, false, washAmount),
                    )}`)
                }
              </div>
              {renderOriginPrice()}
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  };

  const bagContentLayout = (
    <div
      onMouseEnter={() => setIsShowMultiPreviewImage(true)}
      onMouseLeave={() => setIsShowMultiPreviewImage(false)}
      onBlur={() => setIsShowMultiPreviewImage(false)}
      style={styles.mainContainer}
    >
      <Default>
        <div
          onMouseEnter={() => setIsShowMultiPreviewImage(true)}
          onMouseOut={() => setIsShowMultiPreviewImage(true)}
          onBlur={() => setIsShowMultiPreviewImage(true)}
          style={styles.sliderContainer}
        >
          <Slider
            arrows={isShowMultiPreviewImage}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            draggable
            nextArrow={<NextImg />}
            prevArrow={<PreImg />}
          >
            <div>
              <LazyLoad height={100} offset={50} once><img style={styles.image} src={frontPicUrl} alt="" /></LazyLoad>
            </div>
            <div>
              <LazyLoad height={100} once><img style={styles.image} src={backPicUrl} alt="" /></LazyLoad>
            </div>
            <div>
              <LazyLoad height={100} once><img style={styles.image} src={sidePicUrl} alt="" /></LazyLoad>
            </div>
            <div>
              <LazyLoad height={100} once><img style={styles.image} src={bottomPicUrl} alt="" /></LazyLoad>
            </div>
            <div>
              <LazyLoad height={100} once><img style={styles.image} src={zipperPicUrl} alt="" /></LazyLoad>
            </div>
          </Slider>
        </div>
      </Default>
      <Mobile>
        <div style={styles.sliderContainer}>
          <Slider
            arrows
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            draggable
            nextArrow={<NextImg />}
            prevArrow={<PreImg />}
          >
            <div><LazyLoad height={100} offset={50} once><img style={styles.imageMobile} src={frontPicUrl} alt="" /></LazyLoad></div>
            <div><LazyLoad height={100} once><img style={styles.imageMobile} src={backPicUrl} alt="" /></LazyLoad></div>
            <div><LazyLoad height={100} once><img style={styles.imageMobile} src={sidePicUrl} alt="" /></LazyLoad></div>
            <div><LazyLoad height={100} once><img style={styles.imageMobile} src={bottomPicUrl} alt="" /></LazyLoad></div>
            <div><LazyLoad height={100} once><img style={styles.imageMobile} src={zipperPicUrl} alt="" /></LazyLoad></div>
          </Slider>
        </div>
      </Mobile>
      {
        isBuyArea ? null : (
          <Default>
            {
            isOhPhireManage && isShowOPManageNote ? (
              <React.Fragment>
                <div style={styles.opMangerNoteContainer}>
                  平日14:00前下單選擇郵寄快遞
                  <br />
                  【隔日火速到貨
                  <span
                    role="img"
                    aria-label="fire"
                  >
                    🔥
                  </span>
                  週日除外
                  】
                </div>
                <div style={styles.triangle} />
              </React.Fragment>
            ) : null
          }
            {
            isOhPhireManage ? (
              <div
                style={styles.opMangerIconContainer}
                onMouseOver={() => { setIsShowOPManageNote(true); }}
                onFocus={() => { setIsShowOPManageNote(true); }}
                onMouseLeave={() => { setIsShowOPManageNote(false); }}
              >
                <img
                  src={Assets.opMangerIcon}
                  style={styles.opMangerIcon}
                  alt=""
                />
              </div>
            ) : (
              <div
                style={styles.opMangerIconContainer}
                onMouseOver={() => { setIsShowOPManageNote(true); }}
                onFocus={() => { setIsShowOPManageNote(true); }}
                onMouseLeave={() => { setIsShowOPManageNote(false); }}
              />
            )
          }
            {/* Note: note show item type */}
            {/* <div style={isOhPhireManage ? styles.typeOPContainer : styles.typeContainer}>
              {transactionType[type]}
            </div> */}
          </Default>
        )
      }


      <h1 style={styles.tags}>{commodityType === 2 ? clothesTagName : tags}</h1>
      {
        isBuyArea ? null : (
          <Mobile>
            <div style={styles.tagsAndIconContainer}>
              {
            isOhPhireManage && isShowOPManageNote ? (
              <React.Fragment>
                <div style={styles.opMangerNoteMobileContainer}>
                  平日14:00前下單選擇郵寄快遞
                  <br />
                  【隔日火速到貨
                  <span
                    role="img"
                    aria-label="fire"
                  >
                    🔥
                  </span>
                  週日除外
                  】
                </div>
                <div style={styles.triangleMobile} />
              </React.Fragment>
            ) : null
          }
              {
            isOhPhireManage ? (
              <div
                style={styles.opMangerIconMobileContainer}
                onMouseOver={() => { setIsShowOPManageNote(true); }}
                onFocus={() => { setIsShowOPManageNote(true); }}
                onMouseLeave={() => { setIsShowOPManageNote(false); }}
                onTouchStart={() => setIsShowOPManageNote(true)}
                onTouchMove={() => setIsShowOPManageNote(true)}
                onTouchEnd={() => setIsShowOPManageNote(false)}
              >
                <img
                  src={Assets.OPManageMobile}
                  style={styles.opMangerMobileIcon}
                  alt=""
                />
              </div>
            ) : null
          }
              {/* Note: note show item type */}
              {/* <div style={styles.typeMobileContainer}>
                {transactionType[type]}
              </div> */}
            </div>
          </Mobile>
        )
      }
      <SizeBLockComponent
        sizeStandard={sizeStandard}
        sizeNumber={sizeNumber}
        header
        style={styles.sizeStyle}
      />
      <div style={styles.text}>
        {renderAmount()}
      </div>
      {bagStatusId === BagStatus.bagStatusId.sold && (
        <React.Fragment>
          <Default>
            <UnavailableText>
              <div style={styles.sellOutText}>
                已售出
              </div>
            </UnavailableText>
          </Default>
          <Mobile>
            <UnavailableTextMobile>
              <div>
                已售出
              </div>
            </UnavailableTextMobile>
          </Mobile>
        </React.Fragment>
      )}
    </div>
  );

  return (
    <React.Fragment key={id}>
      <Default>
        <div style={{ ...style, ...styles.previewImageContainer }}>
          <MyFavoriteComponent
            bagId={id}
            setIsShowLoginModal={setIsShowLoginModal}
          />
          <div style={styles.allNewIconContainer}>
            {newLevel === 10 ? (
              <img
                alt=""
                style={styles.allNewIcon}
                src={Assets.allNewCircle}
              />
            ) : null}
          </div>
          <Link
            to={`${bagOrClothesPath(commodityType)}/${bagNo}`}
            onClick={() => { writeData(LocalStorageKey.SCROLL_POSITION, window.scrollY); }}
          >
            {bagContentLayout}
          </Link>
        </div>
      </Default>
      <Mobile>
        <div style={{ ...style, ...styles.previewImageMobileContainer }}>
          <div style={styles.allNewIconContainer}>
            {newLevel === 10 ? (
              <img
                alt=""
                style={styles.allNewIcon}
                src={Assets.allNewCircle}
              />
            ) : null}
          </div>
          <MyFavoriteComponent
            bagId={id}
            setIsShowLoginModal={setIsShowLoginModal}
          />
          <Link
            to={`${bagOrClothesPath(commodityType)}/${bagNo}`}
            onClick={() => { writeData(LocalStorageKey.SCROLL_POSITION, window.scrollY); }}
          >
            {bagContentLayout}
          </Link>
        </div>
      </Mobile>
    </React.Fragment>
  );
};

BagItem.propTypes = {
  style: PropTypes.shape({}),
  isBuyArea: PropTypes.bool,
  setIsShowLoginModal: PropTypes.func,
};

BagItem.defaultProps = {
  style: {},
  isBuyArea: false,
  setIsShowLoginModal: () => {},
};

export default BagItem;
