import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MyFavoriteComponent from './MyFavoriteComponent';
import ActionCreators from '../../modules/Cart/ActionCreator';

import {
  memberFollowingListSelector,
  memberIdSelector,
  isLogInSelector,
} from '../../modules/Member/selector';

import {
  cartListSelector,
} from '../../modules/Cart/selector';

const MyFavoriteContainer = ({
  actions: {
    addCartFlowRequest,
    deleteCartFlowRequest,
  },
  cartList,
  bagId,
  isLogin,
  setIsShowLoginModal,
}) => {
  const [isInCart, setIsInCart] = useState(false);
  const [cartId, setCartId] = useState('');

  useEffect(() => {
    if (cartList != null && bagId !== null) {
      const getCartId = R.pathOr('', ['id'], R.filter(cart => R.pathOr('', ['bagId'], cart) === Number(bagId), cartList)[0]);
      setCartId(getCartId);
      if (getCartId) {
        setIsInCart(true);
      }
    }
  }, [cartList]);

  const handelAddCart = () => {
    if (!isLogin) {
      return setIsShowLoginModal(true);
    }
    return addCartFlowRequest(bagId);
  };

  return (
    <React.Fragment>
      <MyFavoriteComponent
        onClickAddToCart={() => handelAddCart()}
        onClickRemoveCart={() => deleteCartFlowRequest(cartId)}
        isInCart={isInCart}
        setIsInCart={setIsInCart}
      />
    </React.Fragment>

  );
};

MyFavoriteContainer.propTypes = {
  actions: PropTypes.shape({
    deleteCartFlowRequest: PropTypes.func.isRequired,
    addCartFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  cartList: PropTypes.array,
  bagId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isLogin: PropTypes.bool,
  setIsShowLoginModal: PropTypes.func,
};

MyFavoriteContainer.defaultProps = {
  cartList: [],
  bagId: null,
  isLogin: false,
  setIsShowLoginModal: () => {},
};

const mapStateToProps = state => ({
  followingList: memberFollowingListSelector(state),
  cartList: cartListSelector(state),
  userId: memberIdSelector(state),
  isLogin: isLogInSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyFavoriteContainer);
