import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  resetDonateOrganization,
  resetOrderCreateStatus,

  orderListSuccess,
  orderListFailure,
  orderListFlowRequest,

  getMyRentedSoldOrderListSuccess,
  getMyRentedSoldOrderListFailure,
  getMyRentedSoldOrderListFlowRequest,

  orderDetailSuccess,
  orderDetailFailure,
  orderDetailFlowRequest,

  orderCreateSuccess,
  orderCreateFailure,
  orderCreateFlowRequest,

  getDonateOrganizationFlowRequest,
  getDonateOrganizationSuccess,
  getDonateOrganizationFailure,

  sendCommentFlowRequest,
  sendCommentSuccess,
  sendCommentFailure,

  withdrawRequestSuccess,
  withdrawRequestFailure,
  withdrawRequestFlowRequest,

  orderUpdateFlowRequest,
  orderUpdateSuccess,
  orderUpdateFailure,

  getOrderNumbersFlowRequest,
  getOrderNumbersFlowSuccess,
  getOrderNumbersFlowFailure,

  getAddressFlowRequest,
  getAddressFlowSuccess,
  getAddressFlowFailure,
} = ActionCreator;

const initialState = {
  orderCreate: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
    data: null,
  },
  orderUpdate: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
    data: null,
  },
  orderList: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
    data: null,
  },
  donateOrganization: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
    data: null,
  },
  orderDetail: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
    data: null,
  },
  sendComment: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
  },
  withdrawRequest: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
    data: null,
  },
  orderNumbers: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
    data: null,
  },
  orderAddress: {
    reduxStatus: ReduxStatus.STATUS_INITIAL,
    error: null,
    data: null,
  },
};

export default handleActions({
  [resetOrderCreateStatus]: state => update(state, {
    orderCreate: {
      reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
      error: { $set: null },
      data: { $set: null },
    },
    orderDetail: {
      reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
      error: { $set: null },
      data: { $set: null },
    },
  }),
  [resetDonateOrganization]: state => update(state, {
    donateOrganization: {
      reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
      error: { $set: null },
      data: { $set: null },
    },
  }),

  // request
  [orderCreateFlowRequest]: state => update(state, {
    orderCreate: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      data: { $set: null },
      error: { $set: null },
    },
  }),

  [orderUpdateFlowRequest]: state => update(state, {
    orderUpdate: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      data: { $set: null },
      error: { $set: null },
    },
  }),

  [withdrawRequestFlowRequest]: state => update(state, {
    withdrawRequest: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      data: { $set: null },
      error: { $set: null },
    },
  }),
  [orderListFlowRequest]: state => update(state, {
    orderList: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      data: { $set: null },
      error: { $set: null },
    },
  }),
  [orderDetailFlowRequest]: state => update(state, {
    orderDetail: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      data: { $set: null },
      error: { $set: null },
    },
  }),
  [getDonateOrganizationFlowRequest]: state => update(state, {
    donateOrganization: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      data: { $set: null },
      error: { $set: null },
    },
  }),
  [getMyRentedSoldOrderListFlowRequest]: state => update(state, {
    orderList: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      data: { $set: null },
      error: { $set: null },
    },
  }),
  [sendCommentFlowRequest]: state => update(state, {
    sendComment: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      error: { $set: null },
    },
  }),
  [getOrderNumbersFlowRequest]: state => update(state, {
    orderNumbers: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      error: { $set: null },
    },
  }),
  [getAddressFlowRequest]: state => update(state, {
    orderAddress: {
      reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
      error: { $set: null },
    },
  }),

  // success
  [orderCreateSuccess]: (state, { payload }) => update(state, {
    orderCreate: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: { $set: payload },
      error: { $set: null },
    },
  }),
  [orderUpdateSuccess]: (state, { payload }) => update(state, {
    orderUpdate: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: { $set: payload },
      error: { $set: null },
    },
  }),
  [withdrawRequestSuccess]: (state, { payload }) => update(state, {
    withdrawRequest: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: { $set: payload },
      error: { $set: null },
    },
  }),
  [orderListSuccess]: (state, { payload }) => update(state, {
    orderList: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: { $set: payload },
      error: { $set: null },
    },
  }),
  [orderDetailSuccess]: (state, { payload }) => update(state, {
    orderDetail: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: { $set: payload },
      error: { $set: null },
    },
  }),
  [getDonateOrganizationSuccess]: (state, { payload }) => update(state, {
    donateOrganization: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: { $set: payload },
      error: { $set: null },
    },
  }),
  [getMyRentedSoldOrderListSuccess]: (state, { payload }) => update(state, {
    orderList: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: { $set: payload },
      error: { $set: null },
    },
  }),
  [sendCommentSuccess]: state => update(state, {
    sendComment: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: { $set: null },
      error: { $set: null },
    },
  }),
  [getOrderNumbersFlowSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
    error: { $set: null },
  }),
  [getAddressFlowSuccess]: (state, { payload }) => update(state, {
    orderAddress: {
      reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
      data: { $set: payload },
      error: { $set: null },
    },

  }),

  // failure
  [orderCreateFailure]: (state, { payload }) => update(state, {
    orderCreate: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      data: { $set: null },
      error: { $set: payload },
    },
  }),
  [orderUpdateFailure]: (state, { payload }) => update(state, {
    orderUpdate: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      data: { $set: null },
      error: { $set: payload },
    },
  }),
  [withdrawRequestFailure]: (state, { payload }) => update(state, {
    withdrawRequest: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      data: { $set: null },
      error: { $set: payload },
    },
  }),
  [orderListFailure]: (state, { payload }) => update(state, {
    orderList: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      data: { $set: null },
      error: { $set: payload },
    },
  }),
  [orderDetailFailure]: (state, { payload }) => update(state, {
    orderDetail: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      data: { $set: null },
      error: { $set: payload },
    },
  }),
  [getDonateOrganizationFailure]: (state, { payload }) => update(state, {
    donateOrganization: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      data: { $set: null },
      error: { $set: payload },
    },
  }),
  [getMyRentedSoldOrderListFailure]: (state, { payload }) => update(state, {
    orderList: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      data: { $set: null },
      error: { $set: payload },
    },
  }),
  [sendCommentFailure]: (state, { payload }) => update(state, {
    sendComment: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      error: { $set: payload },
    },
  }),
  [getOrderNumbersFlowFailure]: (state, { payload }) => update(state, {
    orderNumbers: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      error: { $set: payload },
    },
  }),
  [getAddressFlowFailure]: (state, { payload }) => update(state, {
    orderAddress: {
      reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
      error: { $set: payload },
    },
  }),

}, initialState);
