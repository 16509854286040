import React from 'react';
import PropTypes from 'prop-types';
import Assets from '../constants/Assets';

const Logo = ({
  style = {},
  isScrolled,
  isShowCommodityList,
  headerScrolled,
}) => (
  <img
    src={isShowCommodityList[0] || isScrolled || !headerScrolled ? Assets.logo : Assets.whiteLogo}
    alt=""
    style={style}
  />
);

Logo.propTypes = {
  style: PropTypes.object,
  isScrolled: PropTypes.bool,
  isShowCommodityList: PropTypes.array,
  headerScrolled: PropTypes.bool,
};

Logo.defaultProps = {
  style: {},
  isScrolled: false,
  isShowCommodityList: [],
  headerScrolled: false,
};


export default Logo;
