import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ThemeBagPage from './ThemeBagPage';
import ActionCreator from '../../ActionCreator';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  themeBagSelector,
} from '../../selector';

const ThemeBagPageContainer = ({
  reduxStatus,
  reduxError,
  data,
  id,
  actions: {
    resetStatus,
    getThemeBagFlowRequest,
  },
}) => {
  useEffect(() => {
    if (id) getThemeBagFlowRequest(id);
    return resetStatus;
  }, []);
  return (
    <ThemeBagPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      data={data}
    />
  );
};

ThemeBagPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    getThemeBagFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
};

ThemeBagPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  id: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: themeBagSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreator,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemeBagPageContainer);
