import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import * as R from 'ramda';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import Uploader from '../../../ResourceUploader/components/Uploader';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import { Colors } from '../../../../constants/Color';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import { FontSize } from '../../../../constants/FontSize';
import Assets from '../../../../constants/Assets';

const styles = {
  mainContainer: {
    width: '90vw',
    height: 'auto',
    alignItems: 'center',
    paddingBottom: '30px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    borderBottom: '1px solid #F1F2F2',
  },
  prePage: {
    color: '#D6D6D6',
    textAlign: 'center',
    padding: '10px 0px',
    height: '5%',
    width: '15%',
    border: '1px solid #D6D6D6',
    backgroundColor: '#F1F2F2',
    fontSize: 15,
    outline: 'none',
    cursor: 'pointer',
  },
  prePageMobile: {
    color: '#D6D6D6',
    textAlign: 'center',
    padding: '10px 0px',
    height: '50px',
    width: '100px',
    border: '1px solid #D6D6D6',
    backgroundColor: '#F1F2F2',
    fontSize: FontSize.body,
    outline: 'none',
    cursor: 'pointer',
    margin: '0px 10px',
  },
  uploadPhoto: {
    color: 'black',
    fontSize: 25,
  },
  upload: {
    backgroundColor: '#AD4804',
    color: 'white',
    textAlign: 'center',
    padding: '10px 0px',
    fontSize: 15,
    height: '5%',
    width: '15%',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  uploadMobile: {
    backgroundColor: '#AD4804',
    color: 'white',
    textAlign: 'center',
    padding: '10px 0px',
    fontSize: FontSize.body,
    height: '50px',
    width: '100px',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    margin: '0px 10px',
  },
  middleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 20,
  },
  avatar: {
    width: 100,
    height: 100,
  },
  description: {
    marginLeft: '20px',
    outline: 'none',
    border: `1px ${Colors.separateLine} solid`,
    width: '80%',
    height: 100,
    overflowY: 'scroll',
  },
  uploaderContainer: {
    position: 'relative',
    width: 'auto',
    height: 'auto',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadImage: {
    zIndex: 2,
    width: '85%',
    height: '400px',
    objectFit: 'contain',
  },
  uploadImageHint: {
    zIndex: 1,
    textAlign: 'center',
    position: 'absolute',
    top: '20px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    backgroundColor: '#AD4804',
    color: 'white',
    padding: '10px 0px',
    fontSize: 15,
    height: '50px',
    width: '200px',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  reduxStyle: {
    width: '100%',
  },
  hint: {
    textAlign: 'center',
    width: '100%',
    height: 'auto',
    top: 0,
    left: 0,
  },
  mobileButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const UploadOutfitComponent = ({
  avatarUri,
  isVisible,
  reduxStatus,
  onModalClose,
  onUploadOutfit,
}) => {
  const [outfitResource, setOutfitResource] = useState({});
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (isVisible) {
      setOutfitResource({});
      setDescription('');
    }
  }, [isVisible]);

  const upload = () => {
    if (!R.isEmpty(outfitResource)) {
      onUploadOutfit({
        resourceId: R.pathOr(0, ['id'], outfitResource),
        description,
      });
      onModalClose();
    }
  };

  const renderContent = (
    <ReduxStatusVisualizedComponent
      reduxStatus={reduxStatus}
      containerStyle={styles.reduxStyle}
    >
      <div style={styles.mainContainer}>
        <div style={styles.titleContainer}>
          <Default>
            <button style={styles.prePage} onClick={onModalClose} type="button">上一頁</button>
          </Default>
          <div style={styles.uploadPhoto}>新增照片</div>
          <Default>
            <button
              type="button"
              style={styles.upload}
              disabled={R.isEmpty(outfitResource)}
              onClick={() => upload()}
            >
              發佈
            </button>
          </Default>

        </div>
        <div style={styles.middleContainer}>
          <Avatar
            style={styles.avatar}
            size={110}
            icon="user"
            src={avatarUri || Assets.AvatarIcon}
          />
          <textarea
            style={styles.description}
            placeholder="編輯照片敘述"
            value={description}
            onChange={event => setDescription(event.target.value)}
          />
        </div>
        <div style={styles.uploaderContainer}>
          <Uploader
            onFileUploaded={result => setOutfitResource(result)}
            childComponent={(
              <div style={styles.hint}>
                {R.isEmpty(outfitResource) ? <div style={styles.uploadImageHint}>上傳照片</div>
                  : <img style={styles.uploadImage} alt="" src={R.pathOr('', ['uri'], outfitResource)} />}
              </div>
          )}
          />

        </div>
        <Mobile>
          <div style={styles.mobileButtonContainer}>
            <button style={styles.prePageMobile} onClick={onModalClose} type="button">上一頁</button>
            <button
              type="button"
              style={styles.uploadMobile}
              disabled={R.isEmpty(outfitResource)}
              onClick={() => upload()}
            >
              發佈
            </button>
          </div>
        </Mobile>
      </div>
    </ReduxStatusVisualizedComponent>
  );
  return (
    <InfoModalComponent
      isVisible={isVisible}
      onModalClose={onModalClose}
      content={renderContent}
      containerStyle={{ paddingTop: 60 }}
    />
  );
};

UploadOutfitComponent.propTypes = {
  reduxStatus: PropTypes.string,
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  avatarUri: PropTypes.string,
  onUploadOutfit: PropTypes.func.isRequired,
};

UploadOutfitComponent.defaultProps = {
  reduxStatus: null,
  isVisible: false,
  avatarUri: '',
};

export default UploadOutfitComponent;
