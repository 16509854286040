import React from 'react';
import MemberTerms from './MemberTermsPage';


const MemberTermsContainer = () => (
  <MemberTerms />
);


export default MemberTermsContainer;
