import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import OutfitCommentComponent from './OutfitCommentComponent';

const OutfitCommentComponentContainer = ({
  outfitComment,
}) => (
  <OutfitCommentComponent
    outfitComment={outfitComment}
  />
);

OutfitCommentComponentContainer.propTypes = {
  outfitComment: PropTypes.object,
};

OutfitCommentComponentContainer.defaultProps = {
  outfitComment: {},
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapDispatchToProps,
)(OutfitCommentComponentContainer);
