import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as R from 'ramda';
import { Link } from '@reach/router';
import BagStatus from '../constants/BagStatus';
import Default from './RWDComponent/Default';
import Mobile from './RWDComponent/Mobile';
import Assets from '../constants/Assets';
import { FontSize } from '../constants/FontSize';
import { rentAmountCalculate } from '../utils/rentAmountCalculate';
import amountPattern from '../utils/AmountPattern';
import bagOrClothesPath from '../utils/bagOrClothesPath';

const Item = styled.div`
  position: relative;
  width: 200px;
  height: 280px;
  vertical-align: top;
  overflow: hidden;
  cursor: pointer;
  margin: 30px;
  display: inline-block;
  color: black;
  top: 0;
  left: 0;
  &:hover{
    opacity: 0.8
  }
`;

const ItemMobile = styled.div`
  position: relative;
  width: 100px;
  height: 150px;
  vertical-align: top;
  padding: 0px;
  overflow: hidden;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  color: black;
  top: 0;
  left: 0;
  &:hover{
    opacity: 0.8
  }
`;

const UnavailableText = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 280px;
    background-color: #0D0D0D88;
    color: white;
    & > div:first-child {
      font-weight: 600;
      font-size: ${FontSize.body};
      text-align: left;
      line-height: 20px;
      margin-top: 60px;
      position: absolute;
      left: 30px;
    }
    & > div:nth-child(2) {
      width: 100%;
      font-size: 12px;
      text-align: right;
      padding: 5px 30px;
      margin-top: 300px;
    }
`;

const UnavailableTextMobile = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 150px;
    background-color: #0D0D0D88;
    color: white;
    & > div:first-child {
      font-weight: 200;
      width: 40px;
      font-size: 8px;
      -webkit-transform: scale(0.75);
      text-align: center;
      line-height: 22px;
      margin-top: 10px;
      padding: 0px 10px;
      position: absolute;
      left: -15px;
    }
`;

const styles = {
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  imageMobile: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  tags: {
    textAlign: 'left',
    borderTop: '1px solid #DCDDDD',
    padding: '5px 0px',
    fontSize: FontSize.overline,
  },
  text: {
    fontSize: FontSize.body,
    textAlign: 'left',
    fontWeight: '600',
  },
  linkStyle: {
    width: 'auto',
    height: '100%',
    padding: '0px',
  },
  textContainer: {
    width: '150px',
    fontSize: FontSize.overline,
  },
  textMobileContainer: {
    fontSize: '8px',
    width: '130px',
    textAlign: 'left',
  },
  hr: {
    width: '100%',
    height: '1px',
    margin: '5px 0px',
    size: '1px',
    borderBottom: '1px solid  #F1F2F2',
  },
  tagText: {
    fontSize: FontSize.overline,
  },
  tagTextMobile: {
    fontSize: FontSize.overline,
    width: '120px',
  },
  nameText: {
    fontSize: FontSize.body,
    width: '100px',
    height: '70px',
    marginTop: '8px',
  },
  nameTextMobile: {
    fontSize: FontSize.body,
    height: '65px',
  },
  allNewIcon: {
    width: '100%',
    heigh: '100%',
    objectFit: 'contain',
  },
  allNewIconContainer: {
    width: '40px',
    height: '40px',
    margin: '0px 5px',
    marginBottom: '5px',
  },
  allNewIconMobileContainer: {
    width: '20px',
    height: '20px',
    margin: '0px 5px',
    marginBottom: '5px',
  },
};

const tagSortMethod = (a, b) => {
  const tagCategoryIdOfA = R.pathOr(0, ['tagCategoryId'], a);
  const tagCategoryIdOfB = R.pathOr(0, ['tagCategoryId'], b);
  if (tagCategoryIdOfA !== tagCategoryIdOfB) return tagCategoryIdOfA - tagCategoryIdOfB;
  const orderOfA = R.pathOr(0, ['order'], a);
  const orderOfB = R.pathOr(0, ['order'], b);
  return orderOfA - orderOfB;
};

const MyPageBagItem = ({
  style,
  ...restOfBagData
}) => {
  const id = R.pathOr('', ['id'], restOfBagData);
  const bagNo = R.pathOr('', ['bagNo'], restOfBagData);
  const frontPicUrl = R.pathOr(null, ['frontPic', 'uri'], restOfBagData);
  const name = R.pathOr('', ['name'], restOfBagData);
  const rentAmountPerDay = R.path(['rentAmountPerDay'], restOfBagData) || '-';
  const tags = R.pathOr('', [0, 'name'], R.pathOr([], ['tags'], restOfBagData).sort(tagSortMethod));
  const buyoutAmount = R.pathOr(0, ['buyoutAmount'], restOfBagData);
  const washAmount = R.pathOr(0, ['washAmount'], restOfBagData);
  const bagStatusId = R.pathOr(0, ['bagStatusId'], restOfBagData);
  const commodityType = R.pathOr(1, ['commodityTypeId'], restOfBagData);
  const newLevel = R.pathOr(0, ['newLevel'], restOfBagData);

  const bagContentLayout = (
    <React.Fragment>
      <Default>
        <div style={styles.allNewIconContainer}>
          {newLevel === 10 ? (
            <img
              alt=""
              style={styles.allNewIcon}
              src={Assets.allNewCircle}
            />
          ) : null}
        </div>
        <img style={styles.image} src={frontPicUrl} alt="" />
      </Default>
      <Mobile>
        <div style={styles.allNewIconMobileContainer}>
          {newLevel === 10 ? (
            <img
              alt=""
              style={styles.allNewIcon}
              src={Assets.allNewCircle}
            />
          ) : null}
        </div>
        <img style={styles.imageMobile} src={frontPicUrl} alt="" />
      </Mobile>
      {bagStatusId === BagStatus.bagStatusId.rented && (
      <React.Fragment>
        <Default>
          <UnavailableText>
            <div style={styles.textContainer}>
              <div style={styles.tagText}>{tags}</div>
              <div style={styles.nameText}>{name}</div>
              <div style={styles.hr} />
              {`NT$ ${rentAmountPerDay ? amountPattern(Math.ceil(
                rentAmountCalculate(rentAmountPerDay, 4, false, washAmount) / 4,
              )) : '-'} /  Day`}
            </div>
          </UnavailableText>
        </Default>
        <Mobile>
          <UnavailableTextMobile>
            <div style={styles.textMobileContainer}>
              <div style={styles.tagTextMobile}>{tags}</div>
              <div style={styles.nameTextMobile}>{name}</div>
              <div style={styles.hr} />
              {`NT$ ${rentAmountPerDay ? amountPattern(Math.ceil(
                rentAmountCalculate(rentAmountPerDay, 4, false, washAmount) / 4,
              )) : '-'} /Day`}
            </div>
          </UnavailableTextMobile>
        </Mobile>
      </React.Fragment>
      )}
      {bagStatusId === BagStatus.bagStatusId.sold && (
        <React.Fragment>
          <Default>
            <UnavailableText>
              <div style={styles.textContainer}>
                <div style={styles.tagText}>{tags}</div>
                <div style={styles.nameText}>{name}</div>
                已售出
                <div style={styles.hr} />
                {`NT$ ${rentAmountPerDay ? amountPattern(buyoutAmount) : '-'}`}
              </div>
            </UnavailableText>
          </Default>
          <Mobile>
            <UnavailableTextMobile>
              <div style={styles.textMobileContainer}>
                <div style={styles.tagTextMobile}>{tags}</div>
                <div style={styles.nameTextMobile}>{name}</div>
                已售出
                <div style={styles.hr} />
                {`NT$ ${rentAmountPerDay ? amountPattern(buyoutAmount) : '-'}`}
              </div>
            </UnavailableTextMobile>
          </Mobile>
        </React.Fragment>

      )}
    </React.Fragment>
  );

  return (
    <Link
      to={`${bagOrClothesPath(commodityType)}/${bagNo}`}
      key={id}
      style={styles.linkStyle}
    >
      <Default>
        <Item style={style}>
          {bagContentLayout}
        </Item>
      </Default>
      <Mobile>
        <ItemMobile style={style}>
          {bagContentLayout}
        </ItemMobile>
      </Mobile>
    </Link>
  );
};

MyPageBagItem.propTypes = {
  style: PropTypes.shape({}),
};

MyPageBagItem.defaultProps = {
  style: {},
};

export default MyPageBagItem;
