import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'antd';

const styles = {
  mainContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '100%',
    width: '100%',
    position: 'fixed',
    zIndex: 9999,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-Start',
    paddingTop: 200,
    paddingBottom: 200,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: 'auto',
  },
  maskEvent: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    zIndex: 0,
  },
  contentMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  close: {
    fontSize: 18,
    backgroundColor: 'none',
    color: 'white',
    cursor: 'pointer',
  },
  contentContainer: {
    width: 'fit-content',
    height: 'fit-content',
    backgroundColor: 'white',
  },
};

const InfoModalComponent = ({
  isVisible,
  content,
  onModalClose,
  containerStyle,
}) => (isVisible
  ? (
    <div
      style={{ ...styles.mainContainer, ...containerStyle }}
    >
      <div
        style={styles.maskEvent}
        role="button"
        tabIndex={0}
        onClick={() => onModalClose()}
        onKeyPress={() => onModalClose()}
      />
      <div
        style={styles.contentMainContainer}
      >
        <Button
          style={styles.close}
          icon="close"
          type="link"
          onClick={() => onModalClose()}
        >
          Close
        </Button>
        <div style={styles.contentContainer}>{content}</div>
      </div>
    </div>
  ) : null
);

InfoModalComponent.propTypes = {
  isVisible: PropTypes.bool,
  content: PropTypes.node,
  onModalClose: PropTypes.func,
  containerStyle: PropTypes.object,
};

InfoModalComponent.defaultProps = {
  isVisible: false,
  content: null,
  onModalClose: () => {},
  containerStyle: {},
};

export default InfoModalComponent;
