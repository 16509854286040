import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import Default from './RWDComponent/Default';
import Mobile from './RWDComponent/Mobile';
import { Colors } from '../constants/Color';

const ImgButton = styled.div`
  &:hover{
    opacity: 0.5;
  }
`;
const styles = {
  imgButton: {
    backgroundColor: 'white',
    border: `1px ${Colors.separateLine} solid`,
    position: 'relative',
    width: 270,
    height: 270,
    cursor: 'pointer',
    margin: 0,
    outline: 'none',
    color: 'black',
    padding: 0,
  },
  imgButtonMobile: {
    backgroundColor: 'white',
    border: `1px ${Colors.separateLine} solid`,
    position: 'relative',
    width: 100,
    height: 100,
    cursor: 'pointer',
    margin: 0,
    outline: 'none',
    color: 'black',
    padding: 0,
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    objectFit: 'cover',
  },
};


const OutfitItem = ({
  onClickOutfit,
  outfit,
}) => (
  <React.Fragment>
    <Default>
      <ImgButton
        style={styles.imgButton}
        onClick={() => onClickOutfit(R.pathOr(0, ['id'], outfit))}
      >
        <img style={styles.image} src={R.pathOr('', ['resource', 'uri'], outfit)} alt="" />
      </ImgButton>
    </Default>
    <Mobile>
      <ImgButton
        style={styles.imgButtonMobile}
        onClick={() => onClickOutfit(R.pathOr(0, ['id'], outfit))}
      >
        <img style={styles.image} src={R.pathOr('', ['resource', 'uri'], outfit)} alt="" />
      </ImgButton>
    </Mobile>
  </React.Fragment>

);

OutfitItem.propTypes = {
  onClickOutfit: PropTypes.func.isRequired,
  outfit: PropTypes.object,
};

OutfitItem.defaultProps = {
  outfit: {},
};

export default OutfitItem;
