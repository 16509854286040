import React from 'react';
import PropTypes from 'prop-types';


const styles = {
  dotContainer: {
    position: 'absolute',
    top: '45%',
    right: '40px',
  },
  dotContainerHorizontal: {
    position: 'absolute',
    bottom: '5%',
    left: '50%',
    display: 'flex',
    transform: 'translateX(-50%)',
  },
  dot: {
    padding: '8px',
    margin: '20px 0 20px 0',
    height: '9px',
    width: '9px',
    cursor: 'pointer',
    borderRadius: '50%',
    outline: 'none',
    background: 'grey',
    opacity: '0.3',
  },
  dotActive: {
    padding: '8px',
    margin: '20px 0 20px 0',
    height: '10px',
    width: '10px',
    cursor: 'pointer',
    borderRadius: '50%',
    background: 'grey',
    outline: 'none',
  },
  dotHorizontal: {
    padding: '4px',
    margin: '0 10px 0 10px',
    height: '3px',
    width: '3px',
    cursor: 'pointer',
    borderRadius: '50%',
    border: '1px solid black',
    opacity: '0.3',
  },
  dotActiveHorizontal: {
    padding: '4px',
    margin: '0 10px 0 10px',
    height: '4px',
    width: '4px',
    cursor: 'pointer',
    borderRadius: '50%',
    background: 'black',
    border: '1px solid black',
  },
};

const Dot = ({
  active,
  handleClick,
  index,
  slideDirection,
  dotStyle,
}) => (
  slideDirection === 'vertical' ? (
    <div
      role="button"
      tabIndex={0}
      onKeyPress={handleClick}
      style={active
        ? { ...styles.dotActive, ...dotStyle }
        : { ...styles.dot, ...dotStyle }}
      onClick={() => handleClick(index)}
    />
  )
    : (
      <div
        role="button"
        tabIndex={0}
        onKeyPress={handleClick}
        style={active
          ? { ...styles.dotActiveHorizontal, ...dotStyle }
          : { ...styles.dotHorizontal, ...dotStyle }}
        onClick={() => handleClick(index)}
      />
    )
);

const Dots = ({
  slides,
  activeIndex,
  handleClick,
  slideDirection,
  dotStyle,
  dotContainerStyle,
}) => (
  <div style={slideDirection === 'vertical'
    ? { ...styles.dotContainer, ...dotContainerStyle }
    : { ...styles.dotContainerHorizontal, ...dotContainerStyle }}
  >
    {slides.map((slide, index) => (
      <Dot
        key={slide.imgUrl}
        active={activeIndex === index}
        index={index}
        handleClick={handleClick}
        slideDirection={slideDirection}
        dotStyle={dotStyle}
      />
    ))}
  </div>
);

Dot.propTypes = {
  active: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  slideDirection: PropTypes.string,
  dotStyle: PropTypes.object,
};

Dot.defaultProps = {
  slideDirection: null,
  dotStyle: null,
};

Dots.propTypes = {
  slides: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  activeIndex: PropTypes.number.isRequired,
  slideDirection: PropTypes.string,
  dotStyle: PropTypes.object,
  dotContainerStyle: PropTypes.object,
};

Dots.defaultProps = {
  slideDirection: null,
  dotStyle: null,
  dotContainerStyle: null,
};


export default Dots;
