import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import MemberActionCreator from '../Member/ActionCreator';
import API from './API';

const {
  sendSubscribeRequest,
  sendSubscribeSuccess,
  sendSubscribeFailure,

  sendSubscribeFlowRequest,
  sendSubscribeFlowSuccess,
  sendSubscribeFlowFailure,
} = ActionCreator;

const {
  getMemberCouponListFlowRequest,
  getNotificationListFlowRequest,
} = MemberActionCreator;

// ---------- TASKS ----------
export function* sendSubscribe(data) {
  yield put(sendSubscribeRequest(data));
  try {
    const result = (yield call(API.sendSubscribe, data));
    yield put(sendSubscribeSuccess(result.data));
    return result;
  } catch (error) {
    yield put(sendSubscribeFailure(error));
    throw error;
  }
}

// ---------- FLOWS ----------
export function* sendSubscribeFlow({ payload }) {
  try {
    const result = yield call(sendSubscribe, payload);
    yield put(getMemberCouponListFlowRequest());
    yield put(getNotificationListFlowRequest());

    yield put(sendSubscribeFlowSuccess(result));
  } catch (error) {
    yield put(sendSubscribeFlowFailure(error));
  }
}

export default [
  takeLatest(sendSubscribeFlowRequest, sendSubscribeFlow),
];
