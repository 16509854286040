import * as R from 'ramda';
import { createSelector } from 'reselect';
import { memberStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  memberStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  memberStateSelector,
  result => R.path(['error', 'message'], result),
);

export const memberDataSelector = createSelector(
  memberStateSelector,
  result => R.path(['data'], result),
);
