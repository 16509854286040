import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Link } from '@reach/router';
import moment from 'moment';
import { Icon } from 'antd';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import Paths from '../../../../routes/Paths';
import { orderStatus } from '../../../../constants/OrderStatus';
import Uploader from '../../../ResourceUploader/components/Uploader';

const styles = {
  table: {
    width: 800,
    border: '1px solid black',
  },
  image: {
    width: 100,
    height: 100,
    margin: 10,
  },
  uploader: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 5,
  },
  input: {
    margin: 10,
  },
  avatarButton: {
    border: 'none',
    focus: 'outline none',
  },
};

const OrderDetailPage = ({
  orderDetailError,
  orderCreateError,
  orderDetailReduxStatus,
  orderCreateReduxStatus,
  orderData,
  onSubmitCreateBuyOrder,
  onSendComment,
  onAddCommentResource,
  onCommentChanged,
  comment,
  commentResources,
  sendCommentReduxStatus,
  sendCommentError,
}) => {
  const renderRentToBuyButton = () => {
    // orderType === 'rent' &&
    // OrderStatus is complete &&
    // BagType === 'rentBuy' &&
    // isCreateBuyOrder === false &&
    // is in rented period
    if (orderData !== null) {
      if (R.pathOr('', ['bag', 'type', 'name'], orderData) === 'rentBuy'
        && R.pathOr('', ['type', 'name'], orderData) === 'rent'
          && R.pathOr(0, ['orderStatus', 'name'], orderData) === orderStatus.complete
        && moment().isBefore(moment(orderData.rentEndDate))
      && (!R.pathOr(true, ['isCreateBuyOrder'], orderData))) {
        return (
          <React.Fragment>
            <br />
            <button type="submit" onClick={() => { onSubmitCreateBuyOrder(); }}>我要購買!</button>
            <br />
          </React.Fragment>
        );
      }
    }

    return null;
  };

  const renderComment = () => {
    const hasComment = !R.isEmpty(R.pathOr('', ['commentId'], orderData));

    return (
      <div>
        <form>
          <div>你的評論</div>
          <input
            value={comment}
            disabled={hasComment}
            style={styles.input}
            type="text"
            onChange={onCommentChanged}
          />
        </form>
        <div>
          { commentResources.length > 0
            ? (commentResources.map(resource => (
              <img
                key={resource.id}
                style={styles.image}
                src={resource.uri}
                alt=""
              />
            )))
            : null}
        </div>
        {hasComment ? null
          : (
            <div style={styles.uploader}>
              <Uploader
                onFileUploaded={result => onAddCommentResource(result)}
                childComponent={(
                  <button style={styles.avatarButton} type="button">
                    <Icon type="upload" />
                  </button>
                )}
              />
              <button type="button" onClick={onSendComment}>送出評論</button>
            </div>
          )
        }
      </div>
    );
  };

  return (
    <ReduxStatusVisualizedComponent
      showModalOnError
      modalFailureContent={orderDetailError || orderCreateError || sendCommentError}
      reduxStatus={[orderDetailReduxStatus, orderCreateReduxStatus || sendCommentReduxStatus]}
    >
      <Link to={Paths.ORDER_LIST}>回訂單列表</Link>
      <table style={styles.table}>
        <tbody>
          <tr>
            <th>商品細節</th>
            <th>出租天數</th>
            <th>日期</th>
            <th>折扣後租金-押金</th>
            <th>購買價格</th>
          </tr>
          <tr>
            <td>
              <img key="frontPic" style={styles.image} src={R.pathOr('', ['bag', 'frontPic', 'uri'], orderData)} alt="" />
              <p>{R.pathOr('', ['bag', 'name'], orderData)}</p>
              <p>{R.pathOr('', ['bag', 'bagNo'], orderData)}</p>
            </td>
            <td>
              <p>{`${R.pathOr('0', ['rentDays'], orderData)} 天`}</p>
            </td>
            <td>
              {R.pathOr('', ['type', 'name'], orderData) === 'rent'
                ? (
                  <React.Fragment>
                    <p>{`${moment(R.pathOr('', ['rentStartDate'], orderData)).format('YYYY-MM-DD')}`}</p>
                    <p>|</p>
                    <p>{`${moment(R.pathOr('', ['rentEndDate'], orderData)).format('YYYY-MM-DD')}`}</p>
                  </React.Fragment>
                ) : null}
            </td>
            <td>
              {R.pathOr('', ['type', 'name'], orderData) === 'rent'
                ? (
                  <p>
                    {`折扣後租金 : NT$ ${R.pathOr(0, ['bagRentAmountWithDiscount'], orderData)} 元 /
                 ${R.pathOr('', ['rentDays'], orderData)}天`}
                  </p>
                )
                : null}
              <p>{`押金 : NT$ ${R.pathOr(0, ['deposit'], orderData)} 元`}</p>
            </td>
            <td>
              <p>{`購買價格 : NT$ ${R.pathOr(0, ['bag', 'buyoutAmount'], orderData)} 元`}</p>
            </td>
          </tr>
        </tbody>
      </table>
      {R.pathOr(0, ['orderStatus', 'name'], orderData) === orderStatus.complete
        ? renderComment()
        : null}
      {orderData !== null ? (
        <div>
          {renderRentToBuyButton()}
          <h1>訂單資料</h1>
          {R.pathOr('', ['type', 'name'], orderData) === 'rent'
            ? (
              <React.Fragment>
                <p>{`租借天數: ${R.pathOr('', ['rentDays'], orderData)}`}</p>
                <p>{`租借金額: ${R.pathOr('', ['bagRentAmountWithDiscount'], orderData)}`}</p>
              </React.Fragment>
            ) : null}
          <p>{`總金額: NT$ ${R.pathOr('', ['amount'], orderData)}`}</p>
          <p>{`預刷押金: NT$ ${R.pathOr('', ['deposit'], orderData)}`}</p>
          <h1>押金刷退明細</h1>
          <p>{`押金刷退: NT$ ${R.pathOr('', ['depositReturnAmount'], orderData)}`}</p>
          <p>{`押金轉付購買價格: NT$ ${R.pathOr('', ['depositTransfer'], orderData)}`}</p>
          <p>詳細資料:</p>
          <h1>收件人資料</h1>
          <p>{`收件人: ${R.pathOr('', ['recipientName'], orderData)}`}</p>
          <p>{`收件地郵遞區號: ${R.pathOr('', ['recipientZipCode'], orderData)}`}</p>
          <p>{`收件地址: ${R.pathOr('', ['recipientAddress'], orderData)}`}</p>
          <p>{`聯絡電話: ${R.pathOr('', ['recipientPhone'], orderData)}`}</p>
          <h1>捐贈發票</h1>
          {
          R.pathOr(null, ['donateOrganization', 'name'], orderData) !== null
            ? <p>{`捐贈單位: ${R.pathOr('', ['donateOrganization', 'name'], orderData)}`}</p>
            : 'N/A'
          }
        </div>
      ) : null }
    </ReduxStatusVisualizedComponent>
  );
};

OrderDetailPage.propTypes = {
  orderDetailReduxStatus: PropTypes.string,
  orderCreateReduxStatus: PropTypes.string,
  orderData: PropTypes.object,
  orderDetailError: PropTypes.string,
  orderCreateError: PropTypes.string,
  onSubmitCreateBuyOrder: PropTypes.func.isRequired,
  onSendComment: PropTypes.func.isRequired,
  onAddCommentResource: PropTypes.func.isRequired,
  comment: PropTypes.string,
  onCommentChanged: PropTypes.func.isRequired,
  commentResources: PropTypes.array,
  sendCommentReduxStatus: PropTypes.string,
  sendCommentError: PropTypes.string,
};

OrderDetailPage.defaultProps = {
  orderDetailReduxStatus: null,
  orderCreateReduxStatus: null,
  sendCommentReduxStatus: null,
  orderData: null,
  orderDetailError: '',
  orderCreateError: '',
  sendCommentError: '',
  comment: '',
  commentResources: [],
};

export default OrderDetailPage;
