import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import ForgotPasswordPage from './ForgotPasswordPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
} from '../../selector';

const ForgotPasswordPageContainer = ({
  reduxStatus,
  reduxError,
  actions: {
    resetStatus,
    forgotPasswordFlowRequest,
  },
  setIsShowForgotPasswordPage,
}) => {
  useEffect(() => {
    resetStatus();
    return resetStatus;
  }, []);

  const handleForgotPassword = (data) => {
    forgotPasswordFlowRequest(data);
  };

  return (
    <ForgotPasswordPage
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      handleForgotPassword={handleForgotPassword}
      setIsShowForgotPasswordPage={setIsShowForgotPasswordPage}
    />
  );
};

ForgotPasswordPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  actions: PropTypes.shape({
    forgotPasswordFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  setIsShowForgotPasswordPage: PropTypes.func,
};

ForgotPasswordPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  setIsShowForgotPasswordPage: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordPageContainer);
