import React from 'react';
import classNames from 'classnames';
import Prototype from 'prop-types';

import Header from '../../Parts/Header';
import Title from '../../Parts/Title';

import style from './style.module.scss';

const SendInfoConfirm = ({
  sentData,
}) => (
  <div className={style.container}>
    <Header />
    <div className={style.content}>
      <Title>安全傳出您的資料</Title>
      <div className={style.checklist}>
        <div className={style.list}>
          電子郵件地址
          <div className={classNames(style.checkbox, {
            [style.checked]: sentData.start,
          })}
          />
        </div>
        <div className={style.list}>
          臉部識別影片
          <div className={classNames(style.checkbox, {
            [style.checked]: sentData.video,
          })}
          />
        </div>
        <div className={style.list}>
          身分證件
          <div className={classNames(style.checkbox, {
            [style.checked]: sentData.googleResult,
          })}
          />
        </div>
        <div className={style.list}>
          當前IP位置
          <div className={classNames(style.checkbox, {
            [style.checked]: sentData.googleResult,
          })}
          />
        </div>
        <div className={style.list}>
          建立個人資料
          <div className={classNames(style.checkbox, {
            [style.checked]: sentData.created,
          })}
          />
        </div>
      </div>
    </div>

  </div>
);

export default SendInfoConfirm;

SendInfoConfirm.propTypes = {
  sentData: Prototype.shape({
    start: Prototype.bool,
    video: Prototype.bool,
    googleResult: Prototype.bool,
    created: Prototype.bool,
  }).isRequired,
};
