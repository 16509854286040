import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navigate, Location } from '@reach/router';
import DeepEqual from 'fast-deep-equal';
import { commodityTypeId } from '../../constants/Type';
import Paths from '../../routes/Paths';
import BagSearchPanel from './BagSearchPanel';
import * as SearchBagHelper from '../../utils/SearchBagHelper';
import ActionCreators from '../../modules/Bag/ActionCreator';
import {
  filterConditionSelector,
  bagNumberSelector,
} from '../../modules/Bag/selector';
import TagActionCreators from '../../modules/Tag/ActionCreator';
import ClothesActionCreators from '../../modules/Clothes/ActionCreator';
import {
  categorizedTagListSelector,
} from '../../modules/Tag/selector';

const BagSearchPanelContainer = ({
  location,
  filterCondition,
  actions: {
    resetStatus,
    getBagListFlowRequest,
    getTagFlowRequest,
    getClothesListFlowRequest,
    getBagNumbersFlowRequest,
  },
  tags,
  page,
  isHomepage,
  isClothes,
  onSearch,
  isInitial,
  isBuyArea,
  commodityType,
  allNew,
}) => {
  const [loadPage, setLoadPage] = useState(-1);
  const [condition, setCondition] = useState({});
  const [selectedCommodityType, setSelectCommodityType] = useState(commodityType || 0);

  useEffect(() => {
    getBagNumbersFlowRequest({ ...filterCondition, ...{ isClothes } });
  }, [filterCondition]);

  useEffect(() => {
    if (isHomepage) {
      const homePageRentCondition = {
        limit: 20,
        page: 0,
        allCommodity: true,
        bagType: [1, 3],
      };

      const homePageBuyCondition = {
        limit: 20,
        page: 0,
        allCommodity: true,
        bagType: [2, 3],
      };
      getBagListFlowRequest(homePageBuyCondition);
      getClothesListFlowRequest(homePageRentCondition);
      return resetStatus;
    }
    return null;
  }, []);

  const handleCommodityTypeChange = (event) => {
    const newSelectedCommodityType = Number(event.target.value);
    setSelectCommodityType(newSelectedCommodityType);
  };

  useEffect(() => {
    if (!isHomepage) {
      try {
        const {
          page: oldPage,
          ...restOfFilterCondition
        } = filterCondition || {};

        const filterConditionFromSearch = {
          commodityType: selectedCommodityType,
          ...SearchBagHelper.getCurrentFilterConditionFromQuery(location),
        };

        if (!DeepEqual(filterCondition ? restOfFilterCondition : null, filterConditionFromSearch) && isInitial) {
          // NOTE: If the base filter condition has changed, we need to load the data from the beginning.
          //       So we reset the loadPage to 0.
          setLoadPage(0);
          const newSearchCondition = {
            ...filterConditionFromSearch,
            commodityType: selectedCommodityType,
            page: 0,
          };
          setCondition(newSearchCondition);
          getBagListFlowRequest(newSearchCondition);
        } else {
          setCondition({
            ...filterCondition,
          });
        }

        // else if (oldPage !== loadPage && loadPage >= 0) {
        //   const limit = isNextTenPage ? loadMorePages * itemsInPage : itemsInPage;
        //   const newSearchCondition = {
        //     ...restOfFilterCondition,
        //     page: loadPage,
        //     limit,
        //     isNextTenPage,
        //     targetPage: page,
        //   };

        //   setCondition(newSearchCondition);
        //   getBagListFlowRequest(newSearchCondition);
        //   getClothesListFlowRequest(newSearchCondition);
        // }
      } catch (error) {
        setCondition({});
        getBagListFlowRequest();
      }
      return resetStatus;
    }
    return null;
  }, [filterCondition, location, Math.max(loadPage, 0), selectedCommodityType]);

  useEffect(() => {
    setLoadPage(loadPage + 1);
  }, [page]);

  useEffect(() => {
    getTagFlowRequest();
  }, []);

  const handleSearchConditionChange = (newFilterCondition) => {
    const {
      page: newPage,
      ...restOfCondition
    } = newFilterCondition;

    const renderPath = () => {
      if (commodityType === commodityTypeId.clothes) return Paths.CLOTHES_LIST;

      if (commodityType === commodityTypeId.accessories) return Paths.ACCESSORIES_LIST;

      if (commodityType === commodityTypeId.essences) return Paths.ESSENCES_LIST;

      if (isBuyArea) return Paths.BUY_AREA;

      if (isBuyArea && allNew) return Paths.BUY_AREA_ALL_NEW;

      return Paths.BAG_LIST;
    };
    onSearch();
    const queryString = SearchBagHelper.getQueryStringFromFilterCondition(restOfCondition, location);
    navigate(`${renderPath()}${queryString}&page=1`);
  };

  return (
    <BagSearchPanel
      handleSearchConditionChange={handleSearchConditionChange}
      tags={tags}
      condition={condition}
      isHomepage={isHomepage}
      isBuyArea={isBuyArea}
      allNew={allNew}
      isInitial={isInitial}
      commodityType={commodityType}
      isClothes={isClothes}
      onCommodityTypeChange={handleCommodityTypeChange}
      selectedCommodityType={selectedCommodityType}
    />
  );
};

BagSearchPanelContainer.propTypes = {
  filterCondition: PropTypes.shape({}),
  location: PropTypes.shape({}),
  tags: PropTypes.array,
  actions: PropTypes.shape({
    getTagFlowRequest: PropTypes.func.isRequired,
    getBagListFlowRequest: PropTypes.func.isRequired,
    getClothesListFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    getBagNumbersFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  page: PropTypes.number,
  isHomepage: PropTypes.bool,
  isClothes: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  isInitial: PropTypes.bool,
  isBuyArea: PropTypes.bool,
  allNew: PropTypes.bool,
  commodityType: PropTypes.number,
};

BagSearchPanelContainer.defaultProps = {
  filterCondition: null,
  location: {},
  tags: [],
  page: 0,
  isHomepage: false,
  isClothes: false,
  isInitial: true,
  isBuyArea: false,
  commodityType: 0,
  allNew: false,
};

const mapStateToProps = state => ({
  filterCondition: filterConditionSelector(state),
  tags: categorizedTagListSelector(state),
  bagNumber: bagNumberSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...ClothesActionCreators,
    ...TagActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => (
  <Location>
    {({ location }) => (<BagSearchPanelContainer location={location} {...props} />)}
  </Location>
));
