import * as R from 'ramda';
import api from '../../lib/configAxios';

export default {
  orderCreate: async data => api.post(
    'Orders', data,
  ),
  getDonateOrganizations: async () => api.get(
    'DonateOrganizations',
  ),
  orderList: async filter => api.get(
    `Orders?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
  orderDetail: async filter => api.get(
    `Orders?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
  getMyRentedSoldOrderList: async filter => api.get(
    `Orders?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
  sendComment: async data => api.post(
    'Comments', data,
  ),
  linkResourceToComment: ({ commentId, resourceId }) => api.put(
    `Comments/${commentId}/resources/rel/${resourceId}`,
  ),
  updateOrderComment: async data => api.patch(
    `Orders/${R.path(['orderId'], data)}`, { commentId: R.path(['commentId'], data) },
  ),
  withdrawRequest: async data => api.post(
    'Orders/withdrawRequest', data,
  ),

  updateOrderStatus: async ({ orderId, ...restOfData }) => api.post(
    `Orders/${orderId}/orderEdit`, restOfData,
  ),

  getOrderNumbers: async filter => api.get(
    `Orders/count?where=${encodeURIComponent(JSON.stringify(filter))}`,
  ),


  getAddress: async () => api.get(

    'SendAddresses',
  ),
};
