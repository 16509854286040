import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../ActionCreator';
import MemberProfilePage from './MemberProfilePage';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

import {
  reduxStatusSelector,
  reduxErrorSelector,
  memberProfileSelector,
  memberIdSelector,
  getUserIdByCommunityNameSelector,
} from '../../selector';

const styles = {
  mobileMainStyle: {
    width: '95%',
    paddingTop: '50px',
  },
};

const MemberProfilePageContainer = ({
  reduxStatus,
  reduxError,
  memberProfile,
  selectUserId,
  communityName,
  memberId,
  actions: {
    resetStatus,
    getMemberProfileFlowRequest,
    getUserIdByCommunityNameFlowRequest,
  },
}) => {
  useEffect(() => {
    if (communityName) getUserIdByCommunityNameFlowRequest({ communityName });
    return resetStatus;
  }, []);

  useEffect(() => {
    if (communityName) getUserIdByCommunityNameFlowRequest({ communityName });
    return resetStatus;
  }, [communityName]);

  useEffect(() => {
    if (selectUserId !== null) getMemberProfileFlowRequest(selectUserId);
    return resetStatus;
  }, [selectUserId]);

  return (
    <React.Fragment>
      <Default>
        <MemberProfilePage
          isMe={Number(memberId) === Number(selectUserId)}
          id={selectUserId}
          memberId={memberId}
          reduxStatus={reduxStatus}
          reduxError={reduxError}
          memberProfile={memberProfile}
        />
      </Default>
      <Mobile>
        <MemberProfilePage
          isMe={Number(memberId) === Number(selectUserId)}
          id={selectUserId}
          memberId={memberId}
          reduxStatus={reduxStatus}
          reduxError={reduxError}
          memberProfile={memberProfile}
          style={styles.mobileMainStyle}
        />
      </Mobile>
    </React.Fragment>
  );
};

MemberProfilePageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  memberProfile: PropTypes.shape({}),
  actions: PropTypes.shape({
    resetStatus: PropTypes.func.isRequired,
    getMemberProfileFlowRequest: PropTypes.func.isRequired,
    getUserIdByCommunityNameFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  communityName: PropTypes.string,
  selectUserId: PropTypes.number,
  memberId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

MemberProfilePageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  memberProfile: null,
  memberId: null,
  communityName: null,
  selectUserId: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  memberProfile: memberProfileSelector(state),
  memberId: memberIdSelector(state),
  selectUserId: getUserIdByCommunityNameSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberProfilePageContainer);
