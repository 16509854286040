
const { REACT_APP_CONFIG_ENV } = process.env;

const ohPhireEnv = REACT_APP_CONFIG_ENV || 'local';
const ohPhireEnvConfig = {
  appID: '604179463656683',
};

if (/^dev(elopment)?$/i.test(ohPhireEnv)) {
  ohPhireEnvConfig.appID = '604179463656683';
}

if (/^stg(ing)?$/i.test(ohPhireEnv)) {
  ohPhireEnvConfig.appID = '421628318729586';
}

if (/^prod(uction)?$/i.test(ohPhireEnv)) {
  ohPhireEnvConfig.appID = '596657110875691';
}

export default ohPhireEnvConfig;
