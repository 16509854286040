import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReduceDepositAccountModalComponent from './ReduceDepositAccountModalComponent';
import ActionCreators from '../../../../ActionCreator';

import {
  reduceDepositUserListSelector,
} from '../../../../selector';

const ReduceDepositAccountModalComponentContainer = ({
  selectBagList,
  selectUser,
  userList,
  isVisible,
  onModalClose,
  mode,
  onSelectReduceDepositBag,
  actions: {
    getReduceDepositUserListFlowRequest,
    createReduceDepositUserFlowRequest,
    updateReduceDepositUserFlowRequest,
  },
}) => {
  useEffect(() => {
    if (isVisible) {
      getReduceDepositUserListFlowRequest();
    }
  }, [isVisible]);

  const onCreateReduceDepositItem = (createReduceDepositUserData) => {
    createReduceDepositUserFlowRequest(createReduceDepositUserData);
    onModalClose();
  };

  const onUpdateReduceDepositItem = (updateReduceDepositUserData) => {
    updateReduceDepositUserFlowRequest(updateReduceDepositUserData);
    onModalClose();
  };

  return (
    <ReduceDepositAccountModalComponent
      onUpdateReduceDepositItem={onUpdateReduceDepositItem}
      onCreateReduceDepositItem={onCreateReduceDepositItem}
      userList={userList}
      isVisible={isVisible}
      onModalClose={onModalClose}
      mode={mode}
      selectBagList={selectBagList}
      selectUser={selectUser}
      onSelectReduceDepositBag={onSelectReduceDepositBag}
    />
  );
};

ReduceDepositAccountModalComponentContainer.propTypes = {
  isVisible: PropTypes.bool,
  selectUser: PropTypes.object,
  userList: PropTypes.array,
  selectBagList: PropTypes.array,
  onModalClose: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  onSelectReduceDepositBag: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    getReduceDepositUserListFlowRequest: PropTypes.func.isRequired,
    createReduceDepositUserFlowRequest: PropTypes.func.isRequired,
    updateReduceDepositUserFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
};

ReduceDepositAccountModalComponentContainer.defaultProps = {
  userList: [],
  isVisible: false,
  selectBagList: [],
  selectUser: {},
};

const mapStateToProps = state => ({
  userList: reduceDepositUserListSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReduceDepositAccountModalComponentContainer);
