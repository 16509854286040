import * as R from 'ramda';
import { createSelector } from 'reselect';
import { memberStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  memberStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  memberStateSelector,
  result => R.path(['error', 'message'], result),
);

export const memberDataSelector = createSelector(
  memberStateSelector,
  result => R.path(['data'], result),
);

export const memberIdSelector = createSelector(
  memberDataSelector,
  result => R.pathOr(null, ['user', 'id'], result),
);

export const memberPassbaseVerifySelector = createSelector(
  memberDataSelector,
  result => R.pathOr(null, ['user', 'isVerified'], result),
);

export const memberPassbaseStateSelector = createSelector(
  memberDataSelector,
  result => R.pathOr(null, ['user', 'passbaseStatus'], result),
);

export const memberNameSelector = createSelector(
  memberDataSelector,
  result => R.pathOr(null, ['user', 'name'], result),
);

export const memberCommunityNameSelector = createSelector(
  memberDataSelector,
  result => R.pathOr(null, ['user', 'communityName'], result),
);

export const memberAvatarUriSelector = createSelector(
  memberDataSelector,
  result => R.pathOr(null, ['user', 'avatar', 'uri'], result),
);

export const memberEmailSelector = createSelector(
  memberDataSelector,
  result => R.pathOr(null, ['user', 'email'], result),
);

export const isLogInSelector = createSelector(
  memberDataSelector,
  result => !!(R.pathOr(false, ['accessToken'], result) && R.pathOr(false, ['user'], result)),
);

export const memberInfoSelector = createSelector(
  memberDataSelector,
  result => R.pathOr(null, ['user'], result),
);

export const memberSocialLoginSelector = createSelector(
  memberDataSelector,
  result => R.pathOr(null, ['user', 'socialLogin'], result),
);

export const memberProfileSelector = createSelector(
  memberStateSelector,
  result => R.pathOr(null, ['memberProfile'], result),
);


export const getUserIdByCommunityNameSelector = createSelector(
  memberStateSelector,
  result => R.pathOr(null, ['selectUserId'], result),
);


export const memberFollowingListSelector = createSelector(
  memberDataSelector,
  result => R.pathOr([], ['followingList'], result),
);

export const memberNotificationListSelector = createSelector(
  memberDataSelector,
  result => R.pathOr([], ['notifications'], result),
);

export const memberCouponListSelector = createSelector(
  memberDataSelector,
  result => R.pathOr([], ['coupons'], result),
);

export const outfitDetailSelector = createSelector(
  memberStateSelector,
  result => R.pathOr({}, ['outfitDetail'], result),
);

export const searchMemberListByKeywordSelector = createSelector(
  memberStateSelector,
  result => R.pathOr([], ['searchByKeyword'], result),
);

export const memberProtectTreeEndTimeSelector = createSelector(
  memberDataSelector,
  result => R.pathOr('', ['protectTreeEndTime'], result),
);

export const reduceDepositListSelector = createSelector(
  memberDataSelector,
  result => R.pathOr([], ['reduceDepositList'], result),
);

export const reduceDepositUserListSelector = createSelector(
  memberDataSelector,
  result => R.pathOr([], ['reduceDepositUserList'], result),
);

export const withdrawOrderNumberSelector = createSelector(
  memberDataSelector,
  result => R.path(['withdrawOrderNumber'], result),
);
