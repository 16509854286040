/* eslint-disable no-script-url, jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as R from 'ramda';
import AmountPattern from '../../../../utils/AmountPattern';

const styles = {
  tdSmallMargin: {
    width: '10%',
    padding: '10px 0px',
  },
  tdMediumMargin: {
    width: '15%',
    padding: '10px 0px',
  },
  width: {
    width: '100%',
  },
  notDecidedButton: {
    width: 65,
    height: 25,
    border: '1px solid #B34E11',
    outline: 'none',
    textAlign: 'center',
    color: '#BDBDBD',
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  decidedButton: {
    width: 65,
    height: 25,
    backgroundColor: '#B34E11',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    color: 'white',
  },
  replyContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  statusContainer: {
    minWidth: '20%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
};
const NegotiationMessage = ({
  negotiation,
  memberId,
  onAcceptAmount,
  onReplyToMessage,
}) => {
  const [toMessage, setToMessage] = useState('');
  const renderDealResult = R.path(['isDeal'], negotiation) ? '審核通過' : '審核失敗';

  return (
    <tr style={styles.width}>
      <td style={styles.tdSmallMargin}>{moment(R.pathOr('', ['createTime'], negotiation)).format('YYYY/MM/DD')}</td>
      <td style={styles.tdSmallMargin}>
        {R.pathOr('發起議價者', ['fromUser', 'name'], negotiation)}
      </td>
      <td style={styles.tdSmallMargin}>{`NT$ ${AmountPattern(R.pathOr(0, ['amount'], negotiation))}`}</td>
      <td style={styles.tdMediumMargin}>{R.pathOr('', ['fromMessage'], negotiation)}</td>
      <td style={styles.tdSmallMargin}>
        {R.pathOr('回覆議價者', ['toUser', 'name'], negotiation)}
      </td>
      <td style={styles.tdMediumMargin}>
        {memberId !== R.path(['fromUserId'], negotiation)
          ? (
            <div style={styles.replyContainer}>
              <input
                defaultValue={R.pathOr('', ['toMessage'], negotiation)}
                onChange={event => setToMessage(event.target.value)}
              />
              <button
                style={styles.decidedButton}
                type="button"
                onClick={() => onReplyToMessage({ negotiationId: negotiation.id, toMessage })}
              >
                回覆
              </button>
            </div>
          )
          : <div>{R.pathOr('', ['toMessage'], negotiation)}</div>
        }
      </td>
      <td style={styles.tdMediumMargin}>
        { R.path(['isDeal'], negotiation) === null
          ? (
            <div style={styles.statusContainer}>
              <button
                onClick={() => onAcceptAmount({ id: negotiation.id, isAccept: false })}
                disabled={Number(memberId) === R.path(['fromUserId'], negotiation)}
                type="button"
                style={R.pathOr('', ['isAccept'], negotiation) === false ? styles.decidedButton : styles.notDecidedButton}
              >
                不接受
              </button>
              <button
                onClick={() => onAcceptAmount({ id: negotiation.id, isAccept: true })}
                disabled={Number(memberId) === R.path(['fromUserId'], negotiation)}
                type="button"
                style={R.pathOr(false, ['isAccept'], negotiation) === true ? styles.decidedButton : styles.notDecidedButton}
              >
                接受
              </button>
            </div>
          )
          : renderDealResult}
      </td>
      <td style={styles.tdMediumMargin}>{R.pathOr('', ['adminMessage'], negotiation)}</td>
    </tr>
  );
};

NegotiationMessage.propTypes = {
  negotiation: PropTypes.object,
  memberId: PropTypes.number,
  onAcceptAmount: PropTypes.func.isRequired,
  onReplyToMessage: PropTypes.func.isRequired,
};

NegotiationMessage.defaultProps = {
  memberId: 0,
  negotiation: {},
};

export default NegotiationMessage;
