import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const CircleNumber = ({
  children,
}) => (
  <div className={styles.container}>
    {children}
  </div>
);

export default CircleNumber;

CircleNumber.propTypes = {
  children: PropTypes.node.isRequired,
};
