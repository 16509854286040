import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';
import BagStatus from '../../constants/BagStatus';
import {
  memberIdSelector,
} from '../Member/selector';
import BagSortOrder from '../../constants/BagSortOrder';
import { commodityTypeId } from '../../constants/Type';

const {
  getClothesListRequest,
  getClothesListSuccess,
  getClothesListFailure,
  getMyClothesListRequest,
  getMyClothesListSuccess,
  getMyClothesListFailure,
  notifyWhenArrivedRequest,
  notifyWhenArrivedSuccess,
  notifyWhenArrivedFailure,

  getClothesListFlowRequest,
  getClothesListFlowSuccess,
  getClothesListFlowFailure,
  getMyClothesListFlowRequest,
  getMyClothesListFlowSuccess,
  getMyClothesListFlowFailure,
  notifyWhenArrivedFlowRequest,
  notifyWhenArrivedFlowSuccess,
  notifyWhenArrivedFlowFailure,

  getCommentListFlowRequest,
  getCommentListFlowSuccess,
  getCommentListFlowFailure,
  getCommentListRequest,
  getCommentListSuccess,
  getCommentListFailure,

  getClothesReleaseDateFlowRequest,
  getClothesReleaseDateFlowSuccess,
  getClothesReleaseDateFlowFailure,
  getClothesReleaseDateRequest,
  getClothesReleaseDateSuccess,
  getClothesReleaseDateFailure,

  getClothesIdByClothesNoFlowRequest,
  getClothesIdByClothesNoFlowSuccess,
  getClothesIdByClothesNoFlowFailure,
  getClothesIdByClothesNoRequest,
  getClothesIdByClothesNoSuccess,
  getClothesIdByClothesNoFailure,


} = ActionCreator;

// ---------- NORMAL FUNCTIONS ----------
export const buildFilterClause = (filterCondition = {}) => {
  try {
    const {
      keyword,
      tags,
      bagStatus,
      sortOrder = 0,
      bagType,
      getClothes,
      ownerId,
      limit,
      isClothes,
      allCommodity,
      isOhPhireManage,
      size,
      commodityType,
    } = filterCondition;

    const where = filterCondition.where || {};
    // only for bag, but bag is including null
    if (getClothes) {
      Object.assign(where, {
        and: [
          { ownerId },
          {
            or: [
              { commodityTypeId: null },
              { commodityTypeId: 1 },
              { commodityTypeId: 2 },
              { commodityTypeId: 3 },
            ],
          },
        ],
      });
    } else if (isClothes) {
      Object.assign(where, {
        commodityTypeId: 2,
      });
    } else if (commodityType && commodityType !== commodityTypeId.bag) {
      Object.assign(where, {
        commodityTypeId: commodityType,
      });
    } else if (commodityType === 0) {
      Object.assign(where, {
        and: [
          {
            or: [
              { commodityTypeId: null },
              { commodityTypeId: 1 },
              { commodityTypeId: 2 },
              { commodityTypeId: 3 },
            ],
          },
        ],
      });
    } else {
      Object.assign(where, {
        and: [
          {
            or: [
              { commodityTypeId: null },
              { commodityTypeId: 1 },
            ],
          },
        ],
      });
    }

    if (keyword) {
      Object.assign(where, {
        name: {
          like: `%${keyword}%`,
        },
      });
    }

    if (tags) {
      Object.assign(where, {
        tags,
      });
    }

    if (bagStatus) {
      Object.assign(where, {
        bagStatusId: bagStatus,
      });
    } else {
      Object.assign(where, {
        bagStatusId: {
          inq: [
            BagStatus.bagStatusId.inStock,
            BagStatus.bagStatusId.sold,
            BagStatus.bagStatusId.rented,
          ],
        },
      });
    }

    if (bagType) {
      if (typeof bagType === 'string' || typeof bagType === 'number') {
        Object.assign(where, {
          typeId: bagType,
        });
      } else {
        Object.assign(where, {
          typeId: {
            inq: Object.keys(bagType).map(key => bagType[key]),
          },
        });
      }
    }

    if (isOhPhireManage) {
      Object.assign(where, {
        isOhPhireManage,
      });
    }

    if (size) {
      Object.assign(where, {
        sizeNumber: String(size),
      });
    }


    if (allCommodity) {
      let commodityTypeArray = [
        { commodityTypeId: null },
        { commodityTypeId: 1 },
        { commodityTypeId: 2 },
      ];

      if (commodityType === commodityTypeId.clothes) {
        commodityTypeArray = [
          { commodityTypeId: 2 },
        ];
      }

      if (commodityType === commodityTypeId.bag) {
        commodityTypeArray = [
          { commodityTypeId: null },
          { commodityTypeId: 1 },
        ];
      }

      Object.assign(where, {
        and: [
          {
            bagStatusId: {
              inq: [
                BagStatus.bagStatusId.inStock,
              ],
            },
          },
          {
            or: commodityTypeArray,
          },
        ],
      });
    }

    const order = R.pathOr(
      R.pathOr('', [0, 'order'], BagSortOrder),
      [sortOrder, 'order'],
      BagSortOrder,
    );

    const assignedFilterCondition = {
      limit,
      // skip: basePageLimit * targetPage,
      order,
      include: [
        {
          relation: 'bagStatus',
          scope: { fields: 'name' },
        },
        {
          relation: 'frontPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'backPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'sidePic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'bottomPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'zipperPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'reduceDepositUsers',
          scope: { fields: 'id' },
        },
        {
          relation: 'owner',
          scope: {
            fields: [
              'name', 'avatarId', 'communityName', 'isPostReceive',
              'isSevenElevenReceive', 'selectStore', 'isVerified', 'isBusinessPartner'],
            include: [
              {
                relation: 'avatar',
                scope: { fields: 'uri' },
              },
              {
                relation: 'follow',
              },
            ],
          },
        },
        'type',
        'tags',
        'commodityType',
      ],
      ...(R.isEmpty(where) ? {} : { where }),
    };

    return assignedFilterCondition;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {};
  }
};

// ---------- TASKS ----------
export function* getClothesReleaseDate(bagId) {
  yield put(getClothesReleaseDateRequest());
  try {
    const result = (yield call(API.getClothesReleaseDate, bagId));
    yield put(getClothesReleaseDateSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getClothesReleaseDateFailure(error));
    throw error;
  }
}

export function* getClothesIdByClothesNo(bagNo) {
  yield put(getClothesIdByClothesNoRequest());
  try {
    const result = (yield call(API.getClothesIdByClothesNo, { bagNo }));
    const { bagId } = result.data;
    yield put(getClothesIdByClothesNoSuccess(bagId));
    return result;
  } catch (error) {
    yield put(getClothesIdByClothesNoFailure(error));
    throw error;
  }
}

export function* getClothesList(filterCondition = {}) {
  const assignedFilterCondition = buildFilterClause(filterCondition);
  yield put(getClothesListRequest(assignedFilterCondition));
  try {
    const result = (yield call(API.getClothesList, assignedFilterCondition));
    yield put(getClothesListSuccess({
      data: result.data,
      filterCondition,
    }));
    return result;
  } catch (error) {
    yield put(getClothesListFailure(error));
    throw error;
  }
}

export function* getCommentList(bagId) {
  yield put(getCommentListRequest());
  try {
    const result = yield call(API.getCommentList,
      {
        bagId,
        filter: {
          include: [
            {
              relation: 'resources',
              scope: { fields: 'uri' },
            },
            {
              relation: 'commentByUser',
              scope: { fields: ['name', 'communityName'] },
            },
          ],
        },
      });

    yield put(getCommentListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getCommentListFailure(error));
    throw error;
  }
}

export function* getMyClothesList(userId, typeId, currentPage) {
  const filterCondition = {
    where: {
      ownerId: userId,
    },
    bagStatus: {
      inq: Object.keys(BagStatus.bagStatusId).map(bagStatus => BagStatus.bagStatusId[bagStatus]),
    },
    bagType: typeId,
    page: currentPage,
  };

  const assignedFilterCondition = buildFilterClause(filterCondition);

  yield put(getMyClothesListRequest(assignedFilterCondition));
  try {
    const result = (yield call(API.getClothesList, assignedFilterCondition));
    yield put(getMyClothesListSuccess({
      data: result.data,
      filterCondition,
    }));
    return result;
  } catch (error) {
    yield put(getMyClothesListFailure(error));
    throw error;
  }
}

export function* notifyWhenArrived(data) {
  try {
    yield put(notifyWhenArrivedRequest());
    const result = (yield call(API.notifyWhenArrived, data));
    yield put(notifyWhenArrivedSuccess(result.data));
    return result;
  } catch (error) {
    yield put(notifyWhenArrivedFailure(error));
    throw error;
  }
}

// ---------- FLOWS ----------

export function* getClothesListFlow({ payload }) {
  try {
    const result = yield call(getClothesList, payload);
    yield put(getClothesListFlowSuccess(result));
  } catch (error) {
    yield put(getClothesListFlowFailure(error));
  }
}

export function* getMyClothesListFlow({ payload }) {
  try {
    const { typeId, currentPage } = payload;
    const memberId = yield select(memberIdSelector);
    const result = yield call(getMyClothesList, memberId, typeId, currentPage);
    yield put(getMyClothesListFlowSuccess(result));
  } catch (error) {
    yield put(getMyClothesListFlowFailure(error));
  }
}

export function* getCommentListFlow({ payload }) {
  try {
    const result = yield call(getCommentList, payload);
    yield put(getCommentListFlowSuccess(result));
  } catch (error) {
    yield put(getCommentListFlowFailure(error));
  }
}

export function* getClothesReleaseDateFlow({ payload }) {
  try {
    const result = yield call(getClothesReleaseDate, payload);
    yield put(getClothesReleaseDateFlowSuccess(result));
  } catch (error) {
    yield put(getClothesReleaseDateFlowFailure(error));
  }
}

export function* getClothesIdByClothesNoFlow({ payload }) {
  try {
    const result = yield call(getClothesIdByClothesNo, payload);
    yield put(getClothesIdByClothesNoFlowSuccess(result));
  } catch (error) {
    yield put(getClothesIdByClothesNoFlowFailure(error));
  }
}

export function* notifyWhenArrivedFlow({ payload }) {
  try {
    const result = yield call(notifyWhenArrived, payload);
    yield put(notifyWhenArrivedFlowSuccess(result));
  } catch (error) {
    yield put(notifyWhenArrivedFlowFailure(error));
  }
}

export default [
  takeLatest(getClothesListFlowRequest, getClothesListFlow),
  takeLatest(getMyClothesListFlowRequest, getMyClothesListFlow),
  takeLatest(notifyWhenArrivedFlowRequest, notifyWhenArrivedFlow),
  takeLatest(getCommentListFlowRequest, getCommentListFlow),
  takeLatest(getClothesReleaseDateFlowRequest, getClothesReleaseDateFlow),
  takeLatest(getClothesIdByClothesNoFlowRequest, getClothesIdByClothesNoFlow),
  // takeLatest(getBagRentDateListFlowRequest, getBagRentDateListFlow),
];
