import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import * as R from 'ramda';
import Default from '../../RWDComponent/Default';
import Mobile from '../../RWDComponent/Mobile';
import { commodityTypeId } from '../../../constants/Type';

const styles = {
  mainContainer: {
    textAlign: 'center',
    fontSize: 14,
    width: 40,
    height: 40,
    padding: '10px 0px',
    margin: '0px 10px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
  },
  selectedDate: {
    textAlign: 'center',
    fontSize: 12,
    width: 40,
    height: 40,
    padding: '10px 0px',
    color: 'white',
    backgroundColor: '#75331B',
    borderRadius: 60,
    margin: '0px 10px',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  },
  disableDate: {
    textAlign: 'center',
    fontSize: 12,
    width: 60,
    height: 40,
    padding: '10px 0px',
    color: '#CDCDCD',
    cursor: 'not-allowed',
    border: 'none',
    backgroundColor: 'transparent',
  },
  periodDate: {
    textAlign: 'center',
    fontSize: 12,
    width: 60,
    height: 40,
    padding: '10px 0px',
    color: 'black',
    backgroundColor: '#F5F5F5',
    cursor: 'not-allowed',
    border: 'none',
    outline: 'none',
  },

  mainMobileContainer: {
    textAlign: 'center',
    fontSize: 14,
    width: '14.2%',
    height: 30,
    margin: '5px 0px',
    padding: '5px 0px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
  },
  selectedMobileDate: {
    textAlign: 'center',
    fontSize: 14,
    width: 30,
    height: 30,
    padding: '5px 0px',
    color: 'white',
    backgroundColor: '#75331B',
    borderRadius: 60,
    margin: '5px 10px',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  },
  disableMobileDate: {
    textAlign: 'center',
    fontSize: 14,
    width: '14.2%',
    height: 30,
    margin: '5px 0px',
    padding: '5px 0px',
    color: '#CDCDCD',
    cursor: 'not-allowed',
    border: 'none',
    backgroundColor: 'transparent',
  },
  periodMobileDate: {
    textAlign: 'center',
    fontSize: 14,
    width: 50,
    height: 30,
    padding: '5px 0px',
    color: 'black',
    backgroundColor: '#F5F5F5',
    cursor: 'not-allowed',
    border: 'none',
    outline: 'none',
    margin: '5px 0px',
  },
};

const DateComponent = ({
  isDisable,
  previewDays,
  fromDate,
  endDate,
  date,
  onSelected,
  rentDateList,
  isOhPhireManage,
  commodityType,
}) => {
  const [fourDaysLater, stFourDaysLater] = useState('');
  const [eightDaysLater, stEightDaysLater] = useState('');
  const [twelveDaysLater, stTwelveDaysLater] = useState('');
  // Note: OP管理可以隔天到貨
  const restrictionStartDate = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
  const restrictionEndDate = moment(new Date()).add(88, 'days').format('YYYY-MM-DD');

  // Note: 不是OP管理的限制選取時間
  const bagManageLimitDays = moment(new Date()).add(7, 'days').format('YYYY-MM-DD');
  const selfManageLimitDays = moment(new Date()).add(3, 'days').format('YYYY-MM-DD');

  const renderLimitDate = () => {
    if (commodityType === commodityTypeId.bag || !commodityType) return bagManageLimitDays;

    return selfManageLimitDays;
  };

  useEffect(() => {
    stFourDaysLater(R.isEmpty(fromDate) ? '' : moment(fromDate).add(3, 'days').format('YYYY-MM-DD'));
    stEightDaysLater(R.isEmpty(fromDate) ? '' : moment(fromDate).add(7, 'days').format('YYYY-MM-DD'));
    stTwelveDaysLater(R.isEmpty(fromDate) ? '' : moment(fromDate).add(11, 'days').format('YYYY-MM-DD'));
  }, [fromDate]);

  const isEndDateExceedRestrictionEndDate = moment(date).isAfter(moment(restrictionEndDate).add(-previewDays + 1, 'days'));

  // 例如 包包已被預約 9/15 ~ 9/23 共八天, 因為緩衝期為4天 + 若選擇4天(previewDays)的租期 共八天
  // 則必須 9/7 ~ 9/14 都沒有辦法選擇
  const isInRentDatePeriod = () => {
    let inRentDatePeriod = false;
    if (R.isEmpty(rentDateList)) {
      return false;
    }
    rentDateList.forEach((rentDatePair) => {
      const inAvailableRentStarDate = moment(rentDatePair.rentStartDate)
        .add(-4 - previewDays, 'days')
        .format('YYYY-MM-DD');

      const inAvailableRentEndDate = moment(rentDatePair.rentEndDate)
        .add(4, 'days')
        .format('YYYY-MM-DD');

      if (moment(date).isAfter(inAvailableRentStarDate) && moment(date).isBefore(inAvailableRentEndDate)) {
        inRentDatePeriod = true;
        return false;
      }
      return true;
    });
    return inRentDatePeriod;
  };

  const getStyle = (layout) => {
    if (isDisable) {
      return layout === 'default' ? styles.disableDate : styles.disableMobileDate;
    }

    if (!R.isEmpty(fromDate)
      && !R.isEmpty(endDate)
      && moment(date).isAfter(fromDate)
      && moment(date).isBefore(endDate)) {
      return layout === 'default' ? styles.periodDate : styles.periodMobileDate;
    }

    if (moment(date).isoWeekday() === 7
      || moment(date).isBefore(restrictionStartDate)
      || moment(date).isAfter(restrictionEndDate)
      // Note: Holiday Lock
      // || moment(date).isBetween('2021-04-01', '2021-04-06')
      // || moment(date).isBetween('2021-04-29', '2021-05-03')
      // || moment(date).isBetween('2021-06-11', '2021-06-15')
      // || moment(date).isBetween('2021-09-17', '2021-09-22')
      // || moment(date).isBetween('2021-10-08', '2021-10-12')
      // || moment(date).isBetween('2021-12-30', '2022-01-03')
    ) {
      return layout === 'default' ? styles.disableDate : styles.disableMobileDate;
    }

    if (date === fromDate || date === endDate) {
      return layout === 'default' ? styles.selectedDate : styles.selectedMobileDate;
    }

    if (!R.isEmpty(fromDate)
      && (date !== fourDaysLater)
      && (date !== eightDaysLater)
      && (date !== twelveDaysLater)) {
      return layout === 'default' ? styles.disableDate : styles.disableMobileDate;
    }

    if ((previewDays === 12 && moment(date).isoWeekday() === 3)
      || isEndDateExceedRestrictionEndDate
      || isInRentDatePeriod()) {
      return layout === 'default' ? styles.disableDate : styles.disableMobileDate;
    }

    if (!isOhPhireManage && moment(date).isBefore(renderLimitDate())) {
      return layout === 'default' ? styles.disableDate : styles.disableMobileDate;
    }

    return layout === 'default' ? styles.mainContainer : styles.mainMobileContainer;
  };


  const getIsDisable = () => isDisable || isInRentDatePeriod()
  || moment(date).isoWeekday() === 7
    || (
      !R.isEmpty(fromDate)
        && (date !== fourDaysLater)
        && (date !== eightDaysLater)
        && (date !== twelveDaysLater)
    ) || (
    !R.isEmpty(fromDate)
      && !R.isEmpty(endDate)
      && moment(date).isAfter(fromDate)
      && moment(date).isBefore(endDate)
  ) || (
    moment(date).isBefore(restrictionStartDate)
    || moment(date).isAfter(restrictionEndDate)
  )
  // Note: Holiday Lock
  // || (
  //   moment(date).isBetween('2021-04-01', '2021-04-06')
  // ) || (
  //   moment(date).isBetween('2021-04-29', '2021-05-03')
  // ) || (
  //   moment(date).isBetween('2021-06-11', '2021-06-15')
  // ) || (
  //   moment(date).isBetween('2021-09-17', '2021-09-22')
  // ) || (
  //   moment(date).isBetween('2021-10-08', '2021-10-12')
  // ) || (
  //   moment(date).isBetween('2021-12-30', '2022-01-03')
  // )
  || ((previewDays === 12 && moment(date).isoWeekday() === 3)
  ) || isEndDateExceedRestrictionEndDate;

  return (
    <React.Fragment>
      <Default>
        <button
          type="button"
          style={getStyle('default')}
          onClick={() => onSelected(date)}
          disabled={getIsDisable() || (!R.isEmpty(fromDate) && !R.isEmpty(endDate))}
        >
          {moment(date).format('DD')}
        </button>
      </Default>
      <Mobile>
        <button
          type="button"
          style={getStyle('mobile')}
          onClick={() => onSelected(date)}
          disabled={getIsDisable() || (!R.isEmpty(fromDate) && !R.isEmpty(endDate))}
        >
          {moment(date).format('DD')}
        </button>
      </Mobile>
    </React.Fragment>
  );
};

DateComponent.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  fromDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onSelected: PropTypes.func.isRequired,
  previewDays: PropTypes.number.isRequired,
  rentDateList: PropTypes.array,
  isDisable: PropTypes.bool,
  isOhPhireManage: PropTypes.bool,
  commodityType: PropTypes.number,
};

DateComponent.defaultProps = {
  rentDateList: [],
  isDisable: false,
  isOhPhireManage: false,
  commodityType: 1,
};

export default DateComponent;
