import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import NegotiationMessage from './NegotiationMessage';
import Paths from '../../../../routes/Paths';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import ModalComponent from '../../../../components/ModalComponent';

const styles = {
  mainContainer: {
    width: 1300,
    height: 520,
    padding: 40,
    position: 'relative',
    zIndex: 9999,
  },
  mainModalStyle: {
    zIndex: 2,
  },
  mainMobileContainer: {
    width: '95vw',
    height: 'auto',
    padding: 40,
    position: 'relative',
    zIndex: 9999,
  },
  title: {
    fontSize: 20,
    color: 'black',
    fontFamily: 'Microsoft JhengHei Regular',
    textAlign: 'left',
    padding: 10,
    borderBottom: '2px solid #939598',
    width: '100%',
  },
  negotiationButton: {
    backgroundColor: '#F8F8F8',
    outline: 'none',
    cursor: 'pointer',
    marginTop: 10,
    textAlign: 'center',
    padding: '20px 0px',
    width: '100%',
    height: 70,
    fontSize: 16,
    border: '#CEC8C6 1px solid',
  },
  width10percent: {
    width: '10%',
  },
  width15percent: {
    width: '15%',
  },
  table: {
    width: '100%',
    borderBottom: '1px solid #D1D3D4',
  },
  headerContainer: {
    padding: '5px 0px',
    borderBottom: '1px solid #D1D3D4',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  negotiationContainer: {
    overflowY: 'scroll',
    height: 200,
    width: '100%',
    borderBottom: '1px solid #D1D3D4',
  },
  hint: {
    padding: '10px',
    fontSize: 12,
    color: '#221815',
  },
  functionContainer: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  updateAmount: {
    fontSize: 18,
    width: 'fit-content',
    marginRight: 20,
  },
  input: {
    fontSize: 16,
    width: '10%',
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid #BCBCBC',
  },
  width70percent: {
    width: '65%',
    marginRight: 15,
  },
  sendMessage: {
    fontSize: 16,
    width: 160,
    height: 50,
    border: '1px solid #AE4200',
    textAlign: 'center',
    backgroundColor: 'white',
    fontWeight: 100,
    cursor: 'pointer',
    outline: 'none',
  },
};

const NegotiationComponent = ({
  reduxError,
  data,
  isLogin,
  memberId,
  onReplyToMessage,
  sendFromMessage,
  bagOwnerId,
  isVisible,
  onModalClose,
  isNegotiationWithAdmin,
  onAcceptAmount,
  onReplySentHintClose,
  isShowReplySentHint,
}) => {
  const [amount, setAmount] = useState('');
  const [message, setMessage] = useState('');

  const renderContent = isLogin ? (
    <React.Fragment>
      <div style={styles.title}>議價歷史紀錄</div>
      <div style={styles.headerContainer}>
        <div style={styles.width10percent}>日期</div>
        <div style={styles.width10percent}>議價方</div>
        <div style={styles.width10percent}>議價金額</div>
        <div style={styles.width15percent}>議價方訊息</div>
        <div style={styles.width10percent}>回覆方</div>
        <div style={styles.width15percent}>回覆方訊息</div>
        <div style={styles.width15percent}>狀態</div>
        <div style={styles.width15percent}>Oh!Phire訊息</div>
      </div>
      <div style={styles.negotiationContainer}>
        <table style={styles.table}>
          <tbody>
            {data.map(item => (
              <NegotiationMessage
                onReplyToMessage={onReplyToMessage}
                onAcceptAmount={onAcceptAmount}
                key={item.id}
                negotiation={item}
                memberId={memberId}
                isNegotiationWithAdmin={isNegotiationWithAdmin}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div style={styles.hint}>議價功能單一帳號僅限三次議價為限</div>
      { (isNegotiationWithAdmin || (Number(memberId) !== Number(bagOwnerId)))
      && (
        <div>
          <div style={styles.functionContainer}>
            <div style={styles.updateAmount}>更新價格 NT$ </div>
            <input
              type="number"
              placeholder=""
              value={amount}
              onChange={(event) => {
                setAmount(Number(event.target.value) <= 1 ? 1 : event.target.value);
              }}
              style={styles.input}
            />
          </div>
          <div style={styles.functionContainer}>
            <div style={styles.updateAmount}>訊息 </div>
            <input
              type="text"
              placeholder=""
              value={message}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
              style={{ ...styles.input, ...styles.width70percent }}
            />
            <button
              type="button"
              style={styles.sendMessage}
              onClick={() => {
                sendFromMessage({ id: memberId }, message, amount);
                setMessage('');
                setAmount('');
              }}
            >
              { reduxError || '提出審核'}
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  ) : (
    <Link
      style={styles.negotiationButton}
      key="loginForNegotiation"
      to={`${Paths.MEMBER}`}
    >
      請先登入以參與議價
    </Link>
  );

  return (
    <React.Fragment>
      <ModalComponent
        key="modal"
        isVisible={isShowReplySentHint}
        onModalClose={onReplySentHintClose}
        onModalConfirmed={onReplySentHintClose}
        content="回覆成功"
      />
      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainContainer}>
              {renderContent}
            </div>
          )}
          containerStyle={styles.mainModalStyle}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainMobileContainer}>
              {renderContent}
            </div>
          )}
          containerStyle={styles.mainModalStyle}
        />
      </Mobile>
    </React.Fragment>

  );
};

NegotiationComponent.propTypes = {
  onAcceptAmount: PropTypes.func.isRequired,
  onReplyToMessage: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  reduxError: PropTypes.string,
  data: PropTypes.array.isRequired,
  isLogin: PropTypes.bool,
  onReplySentHintClose: PropTypes.func.isRequired,
  memberId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  sendFromMessage: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  isNegotiationWithAdmin: PropTypes.bool,
  bagOwnerId: PropTypes.number,
  isShowReplySentHint: PropTypes.bool,
};

NegotiationComponent.defaultProps = {
  reduxError: null,
  isVisible: false,
  isNegotiationWithAdmin: false,
  isLogin: false,
  memberId: null,
  bagOwnerId: 0,
  isShowReplySentHint: false,
};

export default NegotiationComponent;
