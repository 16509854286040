import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import Mobile from '../../../../components/RWDComponent/Mobile';
import Default from '../../../../components/RWDComponent/Default';

const styles = {
  mainContainer: {
    padding: 20,
    width: 350,
    height: 450,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    zIndex: 999,
  },
  mainMobileContainer: {
    padding: 20,
    width: 350,
    height: 450,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  image: {
    height: 300,
    width: '100%',
    objectFit: 'contain',
    border: 'none',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 30,
    width: '100%',
    marginLeft: 60,
    marginTop: 10,
  },
  title: {
    fontSize: 16,
    color: 'black',
    marginRight: 30,
  },
  content: {
    fontSize: 16,
    color: 'black',
  },
  confirm: {
    border: '1px solid #D39F89',
    color: '#D39F89',
    marginTop: 10,
    backgroundColor: 'transparent',
    fontSize: 14,
    padding: '5px 10px',
    cursor: 'pointer',
    outline: 'none',
  },
};

const ProductTerminateComponent = ({
  bagData,
  isVisible,
  onModalClose,
  onProductTerminate,
}) => {
  const content = (
    <React.Fragment>
      <img
        style={styles.image}
        alt=""
        src={R.pathOr(null, ['frontPic', 'uri'], bagData)}
      />
      <div style={styles.infoContainer}>
        <div style={styles.title}>商品編號</div>
        <div style={styles.content}>{R.pathOr('N/A', ['bagNo'], bagData)}</div>
      </div>
      <div style={styles.infoContainer}>
        <div style={styles.title}>商品名稱</div>
        <div style={styles.content}>{R.pathOr('N/A', ['name'], bagData)}</div>
      </div>
      <button
        type="button"
        onClick={() => {
          onModalClose();
          onProductTerminate({ bagId: bagData.id, modifyBagStatusId: 12 });
        }}
        style={styles.confirm}
      >
        確定停止上架
      </button>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainContainer}>
              {content}
            </div>
          )}
          containerStyle={{ paddingTop: 300 }}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainContainer}>
              {content}
            </div>
          )}
          containerStyle={{ paddingTop: 100 }}
        />
      </Mobile>
    </React.Fragment>
  );
};

ProductTerminateComponent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  bagData: PropTypes.object,
  onModalClose: PropTypes.func.isRequired,
  onProductTerminate: PropTypes.func.isRequired,
};

ProductTerminateComponent.defaultProps = {
  bagData: {},
};

export default ProductTerminateComponent;
