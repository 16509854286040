import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Assets from '../../../../constants/Assets';
import SlideComponent from '../../../../components/SlideComponent/SlideComponent';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import LoginPageContainer from '../../../Member/components/LoginPage';
import Paths from '../../../../routes/Paths';
import { FontSize } from '../../../../constants/FontSize';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    textAlign: 'center',
    color: 'black',
  },
  title: {
    fontSize: FontSize.title,
  },
  buyRentContainer: {
    width: '100vw',
    height: 'auto',
    marginLeft: 'calc(50% - 50vw)',
    transform: 'translate(0, 0)',
  },
  img: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  bannerSliderContainer: {
    height: '100%',
    width: '100%',
    whiteSpace: 'normal',
    display: 'inline-block',
    position: 'relative',
    left: '0',
    margin: '0px 0px',
  },
  bannerImg: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  contentContainer: {
    width: '100vw',
    height: 'auto',
    position: 'relative',
  },
  rentOrLentSwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  switchRentButton: {
    width: '30%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    left: '19%',
    height: '8.1vw',
    top: '70px',
  },
  switchLendButton: {
    width: '30%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    right: '17%',
    height: '8.1vw',
    top: '70px',
  },
  switchRentMobileButton: {
    width: '50%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    left: 0,
    top: '6%',
    height: '13vw',
    opacity: 0,
  },
  switchLendMobileButton: {
    width: '50%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: '6%',
    height: '13vw',
    opacity: 0,
  },
  rentSingUpButton: {
    width: '7.6%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    left: '27%',
    top: '27.5%',
    height: '2.4vw',
    opacity: 0,
  },
  rentShareButton: {
    width: '16%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    left: '29%',
    bottom: '18%',
    height: '4.3vw',
    backgroundColor: 'transparent',
    opacity: 0,
  },
  lendSingUpButton: {
    width: '8%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    right: '38%',
    top: '26.5%',
    height: '2.4vw',
    opacity: 0,
  },
  lendShareButton: {
    width: '17%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    right: '29%',
    bottom: '17%',
    height: '4.4vw',
    opacity: 0,
  },
  rentSingUpMobileButton: {
    width: '25%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    left: '23%',
    top: '25%',
    height: '6vw',
    opacity: 0,
  },
  rentShareMobileButton: {
    width: '50%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    left: '25%',
    bottom: '8%',
    height: '13vw',
    opacity: 0,
  },
  lendSingUpMobileButton: {
    width: '24%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    left: '24%',
    top: '43%',
    height: '6vw',
    opacity: 0,
  },
  lendShareMobileButton: {
    width: '50%',
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    left: '25%',
    bottom: '7.2%',
    height: '13.3vw',
    opacity: 0,
  },
};

const HowItWorksPage = ({
  bannerData,
  bannerSlideDirection,
}) => {
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);
  const [isRent, setIsRent] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const rentContent = (
    <div>
      <Default>
        <img
          style={styles.bannerImg}
          src={Assets.howItWorksRentContent}
          alt=""
        />
      </Default>
      <Mobile>
        <img
          style={styles.bannerImg}
          src={Assets.howItWorksRentContentMobile}
          alt=""
        />
      </Mobile>
    </div>
  );

  const LentContent = (
    <div>
      <Default>
        <img
          style={styles.bannerImg}
          src={Assets.howItWorksLendContent}
          alt=""
        />
      </Default>
      <Mobile>
        <img
          style={styles.bannerImg}
          src={Assets.howItWorksLendContentMobile}
          alt=""
        />
      </Mobile>
    </div>
  );

  return (
    <div style={styles.mainContainer}>

      <InfoModalComponent
        containerStyle={{ zIndex: 1 }}
        isVisible={isShowLoginModal}
        onModalClose={() => setIsShowLoginModal(false)}
        content={(
          <LoginPageContainer
            setIsShowLoginModal={setIsShowLoginModal}
          />
          )}
      />
      <div style={styles.buyRentContainer}>
        <SlideComponent
          data={bannerData}
          arrow
          slideDirection={bannerSlideDirection}
          layout={bannerData.map(data => (
            <div style={styles.bannerSliderContainer} key={data.imgUrl}>
              <Link to={Paths.SUBMIT_BAG}>
                <img
                  style={styles.bannerImg}
                  src={data.imgUrl}
                  alt=""
                />
              </Link>
            </div>
          ))}
        />
      </div>

      <div style={styles.contentContainer}>
        <Default>
          <div
            style={styles.switchRentButton}
            role="button"
            tabIndex={0}
            onKeyPress={() => setIsRent(true)}
            onClick={() => setIsRent(true)}
          />
          <div
            style={styles.switchLendButton}
            role="button"
            tabIndex={0}
            onKeyPress={() => setIsRent(false)}
            onClick={() => setIsRent(false)}
          />
          <div
            style={isRent ? styles.rentSingUpButton : styles.lendSingUpButton}
            role="button"
            tabIndex={0}
            onKeyPress={() => setIsShowLoginModal(true)}
            onClick={() => setIsShowLoginModal(true)}
          />

          <Link to={Paths.SUBMIT_BAG}>
            <div
              type="button"
              role="button"
              tabIndex={0}
              style={isRent ? styles.rentShareButton : styles.lendShareButton}
            />
          </Link>
        </Default>
        <Mobile>
          <div
            style={styles.switchRentMobileButton}
            role="button"
            tabIndex={0}
            onKeyPress={() => setIsRent(true)}
            onClick={() => setIsRent(true)}
          />
          <div
            style={styles.switchLendMobileButton}
            role="button"
            tabIndex={0}
            onKeyPress={() => setIsRent(false)}
            onClick={() => setIsRent(false)}
          />
          <div
            style={isRent ? styles.rentSingUpMobileButton : styles.lendSingUpMobileButton}
            role="button"
            tabIndex={0}
            onKeyPress={() => setIsShowLoginModal(true)}
            onClick={() => setIsShowLoginModal(true)}
          />

          <Link to={Paths.SUBMIT_BAG}>
            <div
              type="button"
              role="button"
              tabIndex={0}
              style={isRent ? styles.rentShareMobileButton : styles.lendShareMobileButton}
            />
          </Link>
        </Mobile>

        {isRent ? rentContent : LentContent}

      </div>


    </div>
  );
};
HowItWorksPage.propTypes = {
  bannerData: PropTypes.array.isRequired,
  bannerSlideDirection: PropTypes.string,
};

HowItWorksPage.defaultProps = {
  bannerSlideDirection: '',
};

export default HowItWorksPage;
