import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  sendContactRequest,
  sendContactSuccess,
  sendContactFailure,
  resetStatus,
} = ActionCreator;


const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: null,
};

export default handleActions({
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: initialState.reduxStatus },
  }),

  [sendContactRequest]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: null },
  }),

  [sendContactFailure]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),

  [sendContactSuccess]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data: { $set: payload },
    error: { $set: null },
  }),

}, initialState);
