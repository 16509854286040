import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';

import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import MemberActionCreator from '../Member/ActionCreator';
import API from './API';
import { memberIdSelector } from '../Member/selector';
import { nonBlockedSagaAll } from '../../utils/nonBlockedPromiseAll';

const { getNotificationListFlowRequest } = MemberActionCreator;

const {
  orderCreateFlowRequest,
  orderCreateFlowSuccess,
  orderCreateFlowFailure,
  orderCreateRequest,
  orderCreateSuccess,
  orderCreateFailure,

  orderListFlowRequest,
  orderListFlowSuccess,
  orderListFlowFailure,
  orderListRequest,
  orderListSuccess,
  orderListFailure,

  getMyRentedSoldOrderListFlowRequest,
  getMyRentedSoldOrderListFlowSuccess,
  getMyRentedSoldOrderListFlowFailure,
  getMyRentedSoldOrderListRequest,
  getMyRentedSoldOrderListSuccess,
  getMyRentedSoldOrderListFailure,

  orderDetailFlowRequest,
  orderDetailFlowSuccess,
  orderDetailFlowFailure,
  orderDetailRequest,
  orderDetailSuccess,
  orderDetailFailure,

  getDonateOrganizationFlowRequest,
  getDonateOrganizationFlowSuccess,
  getDonateOrganizationFlowFailure,
  getDonateOrganizationRequest,
  getDonateOrganizationSuccess,
  getDonateOrganizationFailure,

  sendCommentFlowRequest,
  sendCommentFlowSuccess,
  sendCommentFlowFailure,
  sendCommentRequest,
  sendCommentSuccess,
  sendCommentFailure,

  withdrawRequestFlowRequest,
  withdrawRequestFlowSuccess,
  withdrawRequestFlowFailure,
  withdrawRequestRequest,
  withdrawRequestSuccess,
  withdrawRequestFailure,

  orderUpdateFlowRequest,
  orderUpdateFlowSuccess,
  orderUpdateFlowFailure,
  orderUpdateRequest,
  orderUpdateSuccess,
  orderUpdateFailure,

  getOrderNumbersRequest,
  getOrderNumbersSuccess,
  getOrderNumbersFailure,
  getOrderNumbersFlowRequest,
  getOrderNumbersFlowSuccess,
  getOrderNumbersFlowFailure,


  getAddressRequest,
  getAddressSuccess,
  getAddressFailure,
  getAddressFlowRequest,
  getAddressFlowSuccess,
  getAddressFlowFailure,
} = ActionCreator;

export function* orderCreate(payload) {
  yield put(orderCreateRequest());
  try {
    const result = yield call(API.orderCreate, payload);
    yield put(orderCreateSuccess(result.data));
    return result;
  } catch (error) {
    yield put(orderCreateFailure(error));
    throw error;
  }
}

export function* orderList(userId) {
  yield put(orderListRequest());
  try {
    const result = yield call(API.orderList, {
      where: {
        and: [
          { userId },
          {
            or: [{ isHiddenThisOrder: null }, { isHiddenThisOrder: false }],
          },
        ],
      },
      order: 'createDate desc',
      include: [
        {
          relation: 'comment',
          scope: {
            include: [
              {
                relation: 'resources',
              },
            ],
          },
        },
        {
          relation: 'userCoupon',
          scope: {
            fields: ['useDate', 'endDate', 'couponId'],
            include: [
              {
                relation: 'coupon',
                scope: {
                  fields: [
                    'percent',
                    'minus',
                    'isActive',
                    'endDate',
                    'couponCode',
                    'couponTypeId',
                  ],
                  include: [{ relation: 'couponType' }],
                },
              },
            ],
          },
        },
        {
          relation: 'bag',
          scope: {
            include: [
              {
                relation: 'frontPic',
              },
              {
                relation: 'type',
              },
              {
                relation: 'owner',
                scope: {
                  fields: [
                    'name',
                    'avatarId',
                    'communityName',
                    'sevenElevenMarket',
                    'sevenElevenAddress',
                    'recipientName',
                    'recipientPhone',
                    'recipientZipCode',
                    'recipientAddress',
                    'selectStore',
                    'isBusinessPartner',
                    'isSevenElevenReceive',
                    'isPostReceive',
                  ],
                  include: [
                    {
                      relation: 'avatar',
                      scope: { fields: 'uri' },
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          relation: 'user',
        },
      ],
    });
    yield put(orderListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(orderListFailure(error));
    throw error;
  }
}

export function* getMyRentedSoldOrderList(userId, otherCondition) {
  yield put(getMyRentedSoldOrderListRequest());
  try {
    const result = yield call(API.getMyRentedSoldOrderList, {
      where: {
        and: [
          { bagOwnerId: userId },
          {
            orderStatusId: {
              nin: [14],
            },
          },
          { ...otherCondition },
        ],
      },
      order: 'createDate desc',
      include: [
        {
          relation: 'userCoupon',
          scope: {
            fields: ['useDate', 'endDate', 'couponId'],
            include: [
              {
                relation: 'coupon',
                scope: {
                  fields: [
                    'percent',
                    'minus',
                    'isActive',
                    'endDate',
                    'couponCode',
                    'couponTypeId',
                  ],
                  include: [{ relation: 'couponType' }],
                },
              },
            ],
          },
        },
        {
          relation: 'bag',
          scope: {
            fields: [
              'name',
              'bagNo',
              'ownerId',
              'frontPicId',
              'isOhPhireManage',
            ],
            include: [
              {
                relation: 'frontPic',
                scope: { fields: 'uri' },
              },
              {
                relation: 'owner',
                scope: {
                  fields: [
                    'name',
                    'avatarId',
                    'communityName',
                    'sevenElevenMarket',
                    'sevenElevenAddress',
                    'recipientName',
                    'recipientPhone',
                    'recipientZipCode',
                    'recipientAddress',
                    'selectStore',
                    'sevenElevenMarket',
                  ],
                  include: [
                    {
                      relation: 'avatar',
                      scope: { fields: 'uri' },
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          relation: 'user',
          scope: {
            fields: [
              'name',
              'avatarId',
              'communityName',
              'sevenElevenMarket',
              'sevenElevenAddress',
              'recipientName',
              'recipientPhone',
              'recipientZipCode',
              'selectStore',
              'sevenElevenMarket',
              'recipientAddress',
              'isBusinessPartner',
              'isSevenElevenReceive',
              'isPostReceive',
            ],
            include: [
              {
                relation: 'avatar',
                scope: { fields: 'uri' },
              },
            ],
          },
        },
      ],
    });
    yield put(getMyRentedSoldOrderListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getMyRentedSoldOrderListFailure(error));
    throw error;
  }
}

export function* orderDetail(orderId) {
  const filterCondition = {
    where: {
      id: orderId,
    },
    include: [
      {
        relation: 'orderStatus',
      },
      {
        relation: 'type',
      },
      {
        relation: 'bag',
        scope: {
          include: [
            {
              relation: 'frontPic',
            },
            {
              relation: 'type',
            },
          ],
        },
      },
      {
        relation: 'donateOrganization',
      },
      {
        relation: 'comment',
        scope: {
          include: [
            {
              relation: 'resources',
              scope: { fields: 'uri' },
            },
          ],
        },
      },
      {
        relation: 'userCoupon',
        scope: {
          fields: ['useDate', 'endDate', 'couponId'],
          include: [
            {
              relation: 'coupon',
              scope: {
                fields: [
                  'percent',
                  'minus',
                  'isActive',
                  'endDate',
                  'couponCode',
                  'couponTypeId',
                ],
                include: [{ relation: 'couponType' }],
              },
            },
          ],
        },
      },
    ],
  };

  yield put(orderDetailRequest());
  try {
    const result = yield call(API.orderDetail, filterCondition);
    yield put(orderDetailSuccess(result.data[0]));
    return result;
  } catch (error) {
    yield put(orderDetailFailure(error));
    throw error;
  }
}

export function* sendComment(data) {
  const {
    comment, commentResources = [], bagId, orderId, stars,
  } = data;

  yield put(sendCommentRequest());
  try {
    // 1: post Comment
    const postCommentResult = yield call(API.sendComment, {
      content: comment,
      commentByUserId: yield select(memberIdSelector),
      stars,
      bagId,
      isCommentWithPhoto: commentResources.length > 0,
    });

    // 2. update Order 'commentId' field
    const commentId = R.pathOr(null, ['data', 'id'], postCommentResult);
    yield call(API.updateOrderComment, { orderId, commentId });

    // 3: post link Resource to Command
    yield call(
      nonBlockedSagaAll,
      commentResources.map(resource => call(API.linkResourceToComment, {
        commentId,
        resourceId: resource.id,
      })),
    );

    yield put(sendCommentSuccess(postCommentResult.data));
    return postCommentResult;
  } catch (error) {
    yield put(sendCommentFailure(error));
    throw error;
  }
}

export function* getDonateOrganization(payload) {
  yield put(getDonateOrganizationRequest());
  try {
    const result = yield call(API.getDonateOrganizations, payload);
    yield put(getDonateOrganizationSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getDonateOrganizationFailure(error));
    throw error;
  }
}

export function* withdrawRequest(payload) {
  yield put(withdrawRequestRequest());
  try {
    const { selectOrderList, userId } = payload || {};
    const result = yield call(API.withdrawRequest, { selectOrderList, userId });
    yield put(withdrawRequestSuccess());
    return result;
  } catch (error) {
    yield put(withdrawRequestFailure(error));
    throw error;
  }
}

export function* OrderUpdate(payload) {
  yield put(orderUpdateRequest());
  try {
    const result = yield call(API.updateOrderStatus, payload);
    yield put(orderUpdateSuccess());
    return result;
  } catch (error) {
    yield put(orderUpdateFailure(error));
    throw error;
  }
}

function* getOrderNumbers(payload) {
  try {
    yield put(getOrderNumbersRequest());
    const result = yield call(API.getOrderNumbers, payload);
    yield put(getOrderNumbersSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getOrderNumbersFailure(error));
    throw error;
  }
}


export function* getAddress(payload) {
  try {
    yield put(getAddressRequest());
    const result = yield call(API.getAddress, payload);
    yield put(getAddressSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getAddressFailure(error));
    throw error;
  }
}

export function* withdrawRequestFlow({ payload }) {
  try {
    const result = yield call(withdrawRequest, payload);
    if (result.status === 200) {
      yield put(withdrawRequestFlowSuccess());
      return;
    }
    yield put(withdrawRequestFlowFailure());
  } catch (error) {
    yield put(withdrawRequestFlowFailure());
  }
}

export function* orderCreateFlow({ payload }) {
  try {
    const result = yield call(orderCreate, payload);
    if (result.status === 200) {
      yield put(orderCreateFlowSuccess());
      return;
    }

    yield put(orderCreateFlowFailure());
  } catch (error) {
    yield put(orderCreateFlowFailure());
  }
}

export function* orderListFlow({ payload }) {
  try {
    const { userId } = payload;

    const result = yield call(orderList, userId);
    if (result.status === 200) {
      yield put(orderListFlowSuccess());
      return;
    }

    yield put(orderListFlowFailure());
  } catch (error) {
    yield put(orderListFlowFailure());
  }
}

export function* getMyRentedSoldOrderListFlow({ payload }) {
  try {
    const { userId, filleter } = payload;
    const result = yield call(getMyRentedSoldOrderList, userId, filleter);
    if (result.status === 200) {
      yield put(getMyRentedSoldOrderListFlowSuccess());
      return;
    }
    yield put(getMyRentedSoldOrderListFlowFailure());
  } catch (error) {
    yield put(getMyRentedSoldOrderListFlowFailure());
  }
}

export function* orderDetailFlow({ payload }) {
  try {
    const { orderId } = payload;
    const result = yield call(orderDetail, orderId);
    if (result.status === 200) {
      yield put(orderDetailFlowSuccess());
    }
  } catch (error) {
    yield put(orderDetailFlowFailure());
  }
}

export function* getDonateOrganizationFlow({ payload }) {
  try {
    const result = yield call(getDonateOrganization, payload);
    if (result.status === 200) {
      yield put(getDonateOrganizationFlowSuccess());
      return;
    }

    yield put(getDonateOrganizationFlowFailure());
  } catch (error) {
    yield put(getDonateOrganizationFlowFailure());
  }
}

export function* sendCommentFlow({ payload }) {
  try {
    const result = yield call(sendComment, payload);
    const userId = yield select(memberIdSelector);
    yield call(orderList, userId);
    yield put(getNotificationListFlowRequest());
    if (result.status === 200) {
      yield put(sendCommentFlowSuccess());
      return;
    }

    yield put(sendCommentFlowFailure());
  } catch (error) {
    yield put(sendCommentFlowFailure());
  }
}

export function* orderUpdateFlow({ payload }) {
  try {
    const { isFromOrderList } = payload;
    const result = yield call(OrderUpdate, payload);
    const userId = yield select(memberIdSelector);
    if (result.status === 200) {
      yield put(orderUpdateFlowSuccess());
      if (isFromOrderList) {
        yield put(orderListFlowRequest({ userId }));
      } else {
        yield put(getMyRentedSoldOrderListFlowRequest({ userId }));
      }
      return;
    }

    yield put(orderUpdateFlowFailure());
  } catch (error) {
    yield put(orderUpdateFlowFailure());
  }
}

export function* getOrderNumbersFlow({ payload }) {
  try {
    const result = yield call(getOrderNumbers, payload);
    yield put(getOrderNumbersFlowSuccess(result.data.count));
  } catch (error) {
    yield put(getOrderNumbersFlowFailure(error));
  }
}


export function* getAddressFlow({ payload }) {
  try {
    const result = yield call(getAddress, payload);
    yield put(getAddressFlowSuccess(result.data));
  } catch (error) {
    yield put(getAddressFlowFailure(error));
  }
}

export default [
  takeLatest(withdrawRequestFlowRequest, withdrawRequestFlow),
  takeLatest(orderCreateFlowRequest, orderCreateFlow),
  takeLatest(orderListFlowRequest, orderListFlow),
  takeLatest(orderDetailFlowRequest, orderDetailFlow),
  takeLatest(getDonateOrganizationFlowRequest, getDonateOrganizationFlow),
  takeLatest(getMyRentedSoldOrderListFlowRequest, getMyRentedSoldOrderListFlow),
  takeLatest(sendCommentFlowRequest, sendCommentFlow),
  takeLatest(orderUpdateFlowRequest, orderUpdateFlow),
  takeLatest(getOrderNumbersFlowRequest, getOrderNumbersFlow),
  takeLatest(getAddressFlowRequest, getAddressFlow),
];
