import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';
import * as R from 'ramda';
import QueryString from 'query-string';
import OnlineBagPage from './OnlineBagPage';
import Type from '../../../../constants/Type';
import * as LocalStorageUtils from '../../../../utils/LocalStorageUtils';
import LocalStorageKey from '../../../../constants/LocalStorageKey';

import {
  reduxStatusSelector,
  reduxErrorSelector,
  bagListSelector,
} from '../../../Bag/selector';

import {
  isLogInSelector,
  memberInfoSelector,
} from '../../../Member/selector';

import ActionCreator from '../../../Bag/ActionCreator';
import Paths from '../../../../routes/Paths';


const OnlineBagPageContainer = ({
  location,
  reduxStatus,
  reduxError,
  isLogin,
  data,
  actions: {
    resetStatus,
    getMyBagListFlowRequest,
  },
}) => {
  const [defaultOpenNegotiationBagId, setDefaultOpenNegotiationBagId] = useState(
    JSON.parse((R.pathOr(null, ['bagId'], QueryString.parse(R.pathOr(null, ['search'], location))))),
  );
  const [selectOpManger, setSelectOpManger] = useState(1);
  const draftOfBags = LocalStorageUtils.readData(LocalStorageKey.DRAFT_OF_BAG, []);

  useEffect(() => {
    setDefaultOpenNegotiationBagId(JSON.parse((R.pathOr(null, ['bagId'], QueryString.parse(R.pathOr(null, ['search'], location))))));
  }, [location]);


  useEffect(() => {
    getMyBagListFlowRequest({
      typeId: [
        Type.typeId.rent,
        Type.typeId.buy,
        Type.typeId.rentBuy,
      ],
      currentPage: 0,
      limit: 999999,
      isOhPhireManage: selectOpManger === 1,
    });
    return resetStatus;
  }, [selectOpManger]);

  return isLogin ? (
    <OnlineBagPage
      defaultOpenNegotiationBagId={defaultOpenNegotiationBagId}
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      isLogin={isLogin}
      data={selectOpManger === 3 ? draftOfBags : data}
      draftOfBags={draftOfBags}
      onClickOpMangerTag={setSelectOpManger}
      selectOpManger={selectOpManger}
    />
  ) : (
    <Redirect to={Paths.MEMBER} noThrow />
  );
};

OnlineBagPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  data: PropTypes.array.isRequired,
  isLogin: PropTypes.bool.isRequired,
  location: PropTypes.object,
  actions: PropTypes.shape({
    resetStatus: PropTypes.func.isRequired,
    getMyBagListFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
};

OnlineBagPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  location: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  isLogin: isLogInSelector(state),
  data: bagListSelector(state),
  userData: memberInfoSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreator,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnlineBagPageContainer);
