import React from 'react';
import Assets from '../../../../constants/Assets';
import { Colors } from '../../../../constants/Color';

const styles = {
  mainContainer: {
    margin: '0 20px 120px 20px',
    textAlign: 'center',
  },
  pic: {
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    maxHeight: '520px',
    objectFit: 'cover',
    position: 'relative',
  },
  ourPartner: {
    position: 'absolute',
    color: 'white',
    fontSize: '60px',
    letterSpacing: '2px',
    bottom: '60px',
    right: '120px',
  },
  partnerConatiner: {
    width: 'auto',
    height: 'auto',
    margin: '0px auto',
  },
  partners: {
    display: 'inline-block',
    margin: '60px 90px 0 90px',
    height: '100%',
    width: '300px',
    verticalAlign: 'top',
  },
  logoEntrupy: {
    width: '155px',
    margin: '50px 0 22px 0',
  },
  logoPrimas: {
    width: '149px',
    height: '119px',
  },
  logoBlackCat: {
    width: '91px',
    height: '91px',
    margin: '24px 0 6px 0',
  },
  partnersTitle: {
    width: 'auto',
    height: '22px',
    fontSize: '24px',
    letterSpacing: '0.1px',
    margin: '44px 0 0 0',
  },
  partnersText: {
    fontSize: '18px',
    letterSpacing: '0.1px',
    margin: '30px 0 0 0',
    lineHeight: '33px',
  },
  partnerEntrupy: {
    margin: '104px 0 0 0',
    width: '300px',
    height: '330px',
    objectFit: 'cover',
  },
  partnerPrimas: {
    margin: '42px 0 0 0',
    width: '300px',
    height: '330px',
    objectFit: 'cover',
    borderBottom: `3.6px solid ${Colors.introRightTitle}`,
  },
  partnerBlackCat: {
    margin: '132px 0 0 0',
    width: '300px',
    height: '330px',
    objectFit: 'cover',
  },
  button: {
    margin: '80px 0 0 0',
    width: '206px',
    height: '58px',
    borderRadius: '0',
    border: `1px solid ${Colors.introRightTitle}`,
    backgroundColor: 'white',
  },
};

const goBack = () => {
  window.history.back();
};

const Partner = () => (

  <div style={styles.mainContainer}>

    <div style={styles.pic}>
      <img
        src={Assets.partnerTop}
        alt=""
        style={styles.pic}
      />
      <div style={styles.ourPartner}> Our Partner</div>
    </div>

    <div style={styles.partnerConatiner}>

      <div style={styles.partners}>
        <div>
          <img
            src={Assets.logoEntrupy}
            alt=""
            style={styles.logoEntrupy}
          />
        </div>
        <div style={styles.partnersTitle}>
          Entrupy鑑定，絕對真品
        </div>
        <div style={styles.partnersText}>
          提供最專業的商品鑑定，擁有市場唯一的AI鑑定技術，強調以高達99.1%的高質量數據演算法，精準區分正品及膺品，確保所有商品品質。

        </div>
        <img
          src={Assets.partnerEntrupy}
          alt=""
          style={styles.partnerEntrupy}
        />

      </div>
      <div style={styles.partners}>
        <img
          src={Assets.logoPrimas}
          alt=""
          style={styles.logoPrimas}
        />
        <div>
          <div style={styles.partnersTitle}>
            PRIMA’S護理，安心託付
          </div>
          <div style={styles.partnersText}>
            關於包包保養、清潔，Oh!Phire皆交由擁有獨家技術工法與標準作業流程的「皮瑪斯工坊」，無論各式頂級布料或皮件的清洗、染整及保養，皆具備頂級的手工技術水準，是最安心的選擇。
          </div>
          <img
            src={Assets.partnerPrimas}
            alt=""
            style={styles.partnerPrimas}
          />
        </div>

      </div>
      <div style={styles.partners}>
        <img
          src={Assets.logoBlackCat}
          alt=""
          style={styles.logoBlackCat}
        />
        <div>
          <div style={styles.partnersTitle}>
            配送到府・到府收件
          </div>
          <div style={styles.partnersText}>
            專人到府收、送件，黑貓宅急便以高效率和高品質服務穩居台灣之冠，是運送精品的不二人選！
          </div>
          <img
            src={Assets.partnerBlackCat}
            alt=""
            style={styles.partnerBlackCat}
          />
        </div>
      </div>
    </div>
    <button type="button" onClick={goBack} style={styles.button}>回上一頁</button>
  </div>
);


export default Partner;
