import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('RETRIEVE_BAG'),
  ...createAsyncActionTypesArray('EXTEND_CONTRACT_BAG'),
  ...createAsyncActionTypesArray('BAG_EDIT'),
  ...createAsyncActionTypesArray('UPDATE_BAG_INFO'),

  // ----- FLOW -----
  ...createAsyncActionTypesArray('RETRIEVE_BAG_FLOW'),
  ...createAsyncActionTypesArray('EXTEND_CONTRACT_BAG_FLOW'),
  ...createAsyncActionTypesArray('BAG_EDIT_FLOW'),
  ...createAsyncActionTypesArray('UPDATE_BAG_INFO_FLOW'),
  'RESET_RETRIEVE_BAG_STATUS',
  'RESET_EXTEND_CONTRACT_BAG_STATUS',
  'RESET_BAG_EDIT_STATUS',
  { prefix: 'ONLINE_PRODUCTS' },
);
