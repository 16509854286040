import React from 'react';

import {
  identifySuccess,
} from '../../../../constants/Assets';

import Header from '../../Parts/Header';
import Title from '../../Parts/Title';

import style from './styles.module.scss';

const SendSuccess = () => (
  <div className={style.container}>
    <Header />
    <div className={style.content}>
      <img src={identifySuccess} alt="identifySuccess" />
      <div className={style.textContent}>
        <Title>大功告成，系統進入審核階段</Title>
        <div>需等候8-24小時，驗證成功後您將收到郵件通知</div>
      </div>
    </div>
  </div>
);

export default SendSuccess;
