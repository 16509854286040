import { combineReducers } from 'redux';
import home from '../modules/Home/reducer';
import member from '../modules/Member/reducer';
import bag from '../modules/Bag/reducer';
import tag from '../modules/Tag/reducer';
import order from '../modules/Order/reducer';
import contact from '../modules/Contact/reducer';
import transaction from '../modules/Transaction/reducer';
import subscribe from '../modules/Subscribe/reducer';
import cart from '../modules/Cart/reducer';
import theme from '../modules/Theme/reducer';
import negotiation from '../modules/Negotiation/reducer';
import onlineBag from '../modules/OnlineBag/reducer';
import clothes from '../modules/Clothes/reducer';
import headerCommodity from '../modules/Header/reducer';
import specialSelect from '../modules/SpecialSelect/reducer';

const appReducer = combineReducers({
  home,
  member,
  bag,
  tag,
  order,
  contact,
  transaction,
  subscribe,
  cart,
  theme,
  negotiation,
  onlineBag,
  clothes,
  headerCommodity,
  specialSelect,
});

// NOTE: reset reducers after sign out.
const rootReducer = (state, action) => (appReducer(state, action));

export default rootReducer;
