import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Link } from '@reach/router';
import { commodityTypeId } from '../../../constants/Type';
import Assets from '../../../constants/Assets';
import { rentAmountCalculate } from '../../../utils/rentAmountCalculate';
import bagOrClothesPath from '../../../utils/bagOrClothesPath';
import amountPattern from '../../../utils/AmountPattern';
import SizeBLockComponent from '../../../components/SizeBLockComponent';
import MyFavoriteComponent from '../../../components/MyFavoriteComponent';

const styles = {
  commodityContainer: {
    width: '10vw',
    maxWidth: '100%',
    height: '16vw',
    marginTop: '5px',
    marginBottom: '50px',
    fontsize: '11px',
    position: 'relative',
    textAlign: 'center',
  },
  commodityImg: {
    width: '100%',
    margin: '-15px auto',
    height: '100%',
    objectFit: 'contain',
  },
  tagTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  tag: {
    margin: 0,
    marginTop: 2,
    width: '100%',
    fontSize: '14px',
  },
  typeBlock: {
    border: '1px grey solid',
    width: 'fit-content',
    padding: '0px 3px',
    textAlign: 'center',
    fontSize: '10px',
    color: 'grey',
  },
  price: {
    fontSize: '10px',
    fontFamily: 'Myriad-Variable-Concept',
    color: 'black',
    width: 'fix-content',
  },
  commodityText: {
    fontSize: '10px',
    textDecoration: 'line-through',
  },
  opMangerIconContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0px',
  },
  opMangerIcon: {
    width: '90px',
    height: 'auto',
    objectFit: 'contain',
  },
  nullOpMangerIconContainer: {
    height: '20px',
  },
  allNewIconContainer: {
    position: 'absolute',
    left: 0,
    width: '30px',
    height: '30px',
  },
  allNewIcon: {
    width: '100%',
    height: '100%',
  },
};
const tagSortMethod = (a, b) => {
  const tagCategoryIdOfA = R.pathOr(0, ['tagCategoryId'], a);
  const tagCategoryIdOfB = R.pathOr(0, ['tagCategoryId'], b);
  if (tagCategoryIdOfA !== tagCategoryIdOfB) return tagCategoryIdOfA - tagCategoryIdOfB;
  const orderOfA = R.pathOr(0, ['order'], a);
  const orderOfB = R.pathOr(0, ['order'], b);
  return orderOfA - orderOfB;
};

const CommodityItem = ({
  style,
  isBuyArea,
  ...restOfBagData
}) => {
  const id = R.pathOr('', ['id'], restOfBagData);
  const bagNo = R.pathOr('', ['bagNo'], restOfBagData);
  const frontPicUrl = R.pathOr(null, ['frontPic', 'uri'], restOfBagData);
  const tag = R.pathOr('', [0, 'name'], R.pathOr([], ['tags'], restOfBagData).sort(tagSortMethod));
  const rentAmountPerDay = R.path(['rentAmountPerDay'], restOfBagData) || null;
  const commodityType = R.pathOr(1, ['commodityTypeId'], restOfBagData);
  const sizeStandard = R.pathOr(null, ['sizeStandard'], restOfBagData);
  const sizeNumber = R.pathOr('', ['sizeNumber'], restOfBagData);
  const originalPrice = R.pathOr(0, ['originalPrice'], restOfBagData);
  const washAmount = R.pathOr(0, ['washAmount'], restOfBagData);
  const tagsData = R.pathOr([], ['tags'], restOfBagData);
  const clothesTag = tagsData.find(item => item.tagCategoryId === 4);
  const clothesTagName = R.pathOr('', ['name'], clothesTag);
  const isOhPhireManage = R.pathOr(false, ['isOhPhireManage'], restOfBagData);
  const newLevel = R.pathOr(0, ['newLevel'], restOfBagData);
  // const type = R.pathOr('rent', ['type', 'name'], restOfBagData);

  return (
    <div style={styles.commodityContainer} key={id}>
      <div style={styles.allNewIconContainer}>
        {newLevel === 10 ? (
          <img
            alt=""
            style={styles.allNewIcon}
            src={Assets.allNewCircle}
          />
        ) : null}
      </div>
      <MyFavoriteComponent
        bagId={id}
      />
      <Link to={`${bagOrClothesPath(commodityType)}/${bagNo}`}>
        <img
          alt=""
          src={frontPicUrl}
          style={styles.commodityImg}
        />
        {isOhPhireManage ? (
          <div
            style={styles.opMangerIconContainer}
          >
            <img
              src={Assets.opMangerIcon}
              style={styles.opMangerIcon}
              alt=""
            />
          </div>
        ) : (
          <div
            style={styles.nullOpMangerIconContainer}
          />
        )}
        <div style={styles.tagTypeContainer}>
          <h1 style={styles.tag}>
            {commodityType === commodityTypeId.clothes ? clothesTagName : tag}
          </h1>
          <SizeBLockComponent
            sizeStandard={sizeStandard}
            sizeNumber={sizeNumber}
            header
          />
        </div>
        {
          isBuyArea ? (
            <div style={styles.price}>
              {`購買 $${amountPattern(R.pathOr(0, ['buyoutAmount'], restOfBagData))}`}
            </div>
          ) : (
            <div style={styles.price}>
              {`租借 $${rentAmountPerDay ? amountPattern(Math.ceil(
                rentAmountCalculate(rentAmountPerDay, 4, false, washAmount),
              )) : '-'}`}
            </div>
          )
        }
        <div style={styles.commodityText}>
          {`$${amountPattern(originalPrice)}`}
        </div>
      </Link>
    </div>
  );
};

CommodityItem.propTypes = {
  style: PropTypes.shape({}),
  isBuyArea: PropTypes.bool,
};

CommodityItem.defaultProps = {
  style: {},
  isBuyArea: false,
};

export default CommodityItem;
