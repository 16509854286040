import Path from '../routes/Paths';

export const notificationTypeRedirectPath = {
  100: Path.MEMBER,
  101: Path.BAG_DETAIL,
  102: Path.ONLINE_PRODUCTS,
  103: Path.ONLINE_PRODUCTS,
  104: Path.MEMBER,
  105: Path.MY_RENTED_SOLD_HISTORY,
  106: Path.BAG_DETAIL,
  107: Path.ONLINE_PRODUCTS,

  200: Path.ORDER_LIST,
  201: Path.ORDER_LIST,
  202: Path.ORDER_LIST,
  203: Path.ORDER_LIST,
  204: Path.ORDER_LIST,
  205: Path.ORDER_LIST,
  206: Path.ORDER_LIST,
  207: Path.ORDER_LIST,
  208: Path.ORDER_LIST,
  209: Path.ORDER_LIST,
  210: Path.ORDER_LIST,
  211: Path.ORDER_LIST,
  212: Path.ORDER_LIST,
  213: Path.ORDER_LIST,

  300: Path.MY_RENTED_SOLD_HISTORY,
  301: Path.MY_RENTED_SOLD_HISTORY,
  302: Path.MY_RENTED_SOLD_HISTORY,
  303: Path.ONLINE_PRODUCTS,
  304: Path.ONLINE_PRODUCTS,
  305: Path.ONLINE_PRODUCTS,
  306: Path.MY_RENTED_SOLD_HISTORY,
  307: Path.MY_RENTED_SOLD_HISTORY,
  308: Path.ONLINE_PRODUCTS,
  309: Path.ONLINE_PRODUCTS,
  310: Path.MY_RENTED_SOLD_HISTORY,
  311: Path.MY_RENTED_SOLD_HISTORY,
  312: Path.MY_RENTED_SOLD_HISTORY,
  313: Path.ORDER_LIST,
  314: Path.MY_RENTED_SOLD_HISTORY,
  315: Path.ORDER_LIST,

  400: Path.MY_RENTED_SOLD_HISTORY,
  401: Path.ONLINE_PRODUCTS,
  402: Path.MY_RENTED_SOLD_HISTORY,

  500: Path.MEMBER,
  501: Path.MEMBER,
};


export default {
  notificationTypeRedirectPath,
};
