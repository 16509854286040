import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProductReturnConfirmModalComponent from './ProductReturnConfirmModalComponent';
import ActionCreators from '../../ActionCreator';

const ProductReturnConfirmModalContainer = ({
  onModalClose,
  isVisible,
  order,
  actions: {
    orderUpdateFlowRequest,
  },
}) => {
  const handleOnLenderReceive = () => {
    orderUpdateFlowRequest({
      isFromOrderList: false,
      orderStatusId: 23,
      orderId: order.id,
    });
  };

  return (
    <ProductReturnConfirmModalComponent
      isVisible={isVisible}
      onModalClose={onModalClose}
      onLenderReceive={handleOnLenderReceive}
    />
  );
};


ProductReturnConfirmModalContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  order: PropTypes.object,
  actions: PropTypes.shape({
    orderUpdateFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
};

ProductReturnConfirmModalContainer.defaultProps = {
  order: {},
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});


const mapStateToProps = () => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductReturnConfirmModalContainer);
