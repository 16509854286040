import React, { useState } from 'react';
import { Popconfirm } from 'antd/lib/index';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ReduceDepositBagItem from './ReduceDepositBagItem';
import Default from '../../../../../components/RWDComponent/Default';
import Mobile from '../../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    width: '10%',
    borderBottom: '1px solid #D1D3D4',
  },
  checkbox: {
    width: 20,
    height: 20,
  },
  bagContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  edit: {
    width: 67,
    height: 27,
    color: '#AE4200',
    border: '1px solid #AE4200',
    fontSize: 14,
    textAlign: 'center',
    padding: '2px 0px',
    outline: 'none',
  },
  remove: {
    marginTop: 10,
    width: 67,
    height: 27,
    fontSize: 14,
    color: '#B9B9B9',
    textAlign: 'center',
    padding: '2px 0px',
    border: '1px solid #AE4200',
    outline: 'none',
  },
};

const ReduceDepositItemComponent = ({
  data,
  onDeleteReduceDepositBag,
  onDeleteReduceDepositUser,
  onEditReduceDepositBag,
}) => {
  const [isEditable, setIsEditable] = useState(false);

  const renderEditButton = (
    isEditable
      && (
      <div style={styles.optionContainer}>
        <button
          type="button"
          style={styles.edit}
          onClick={() => onEditReduceDepositBag()}
        >
          編輯
        </button>
        <Popconfirm
          title="是否刪除此免押金帳號?"
          onConfirm={() => onDeleteReduceDepositUser()}
          okText="Yes"
          cancelText="No"
        >
          <button
            type="button"
            style={styles.remove}
          >
            移除
          </button>
        </Popconfirm>
      </div>
      )
  );
  return (
    <tr style={styles.mainContainer}>
      <td>
        <input
          style={styles.checkbox}
          defaultChecked={isEditable}
          type="checkbox"
          onChange={(event) => {
            setIsEditable(event.target.checked);
          }}
        />
      </td>
      <td>
        <div>
          {R.pathOr('', ['user', 'name'], data)}
          <Mobile>
            {renderEditButton}
          </Mobile>
        </div>
      </td>
      <td>
        <div style={styles.bagContainer}>
          {R.pathOr([], ['bags'], data).map(item => (
            <ReduceDepositBagItem
              showRemoveButton={isEditable}
              onDeleteReduceDepositBag={() => onDeleteReduceDepositBag({ userId: data.user.id, bagId: item.id })}
              key={`${data.user.id}${item.id}`}
              data={item}
            />
          ))}
        </div>
      </td>
      <Default>
        <td>
          {renderEditButton}
        </td>
      </Default>

    </tr>
  );
};

ReduceDepositItemComponent.propTypes = {
  data: PropTypes.object,
  onDeleteReduceDepositBag: PropTypes.func.isRequired,
  onDeleteReduceDepositUser: PropTypes.func.isRequired,
  onEditReduceDepositBag: PropTypes.func.isRequired,
};

ReduceDepositItemComponent.defaultProps = {
  data: {},
};

export default ReduceDepositItemComponent;
