import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import OrderBagCommentComponent from './OrderBagCommentComponent';
import OrderActionCreators from '../../ActionCreator';

import {
  sendCommentReduxStatusSelector,
  sendCommentErrorSelector,
} from '../../selector';

const OrderBagComponentContainer = ({
  orderData = null,
  actions: {
    sendCommentFlowRequest,
  },
  sendCommentReduxStatus,
  sendCommentError,
}) => {
  const [commentResources, setCommentResources] = useState([]);
  const [comment, setComment] = useState(R.pathOr('', ['comment', 'content'], orderData));
  const [stars, setSelectStars] = useState(R.pathOr(0, ['comment', 'stars'], orderData));
  useEffect(() => {
    if (!R.isEmpty(R.path(['commentId'], orderData))) {
      setComment(R.pathOr('', ['comment', 'content'], orderData));

      if (R.pathOr([], ['comment', 'resources'], orderData).length > 0) {
        const newCommentResources = [...R.pathOr([], ['comment', 'resources'], orderData)];
        setCommentResources(newCommentResources);
      }
    }
  }, [orderData]);

  const handleOnSendComment = () => {
    if (R.isEmpty(comment)) {
      return;
    }

    sendCommentFlowRequest({
      orderId: orderData.id,
      bagId: R.path(['bagId'], orderData),
      comment,
      stars,
      commentResources,
    });
  };

  const handleOnCommentChanged = (event) => {
    setComment(event.target.value);
  };

  const handleOnAddCommentResource = (result) => {
    const newCommentResources = update(commentResources, { $push: [result] });
    setCommentResources(newCommentResources);
  };

  const handleOnDeleteCommentResource = (deleteIndex) => {
    const newCommentResources = update([], { $push: commentResources });
    const filterCommentResources = [];
    newCommentResources.map((item, index) => {
      if (index.toString() !== deleteIndex.toString()) {
        filterCommentResources.push(item);
      }
      return false;
    });
    setCommentResources(filterCommentResources);
  };

  return (
    <OrderBagCommentComponent
      commentResources={commentResources}
      comment={comment}
      orderData={orderData}
      sendCommentError={sendCommentError}
      sendCommentReduxStatus={sendCommentReduxStatus}
      onSendComment={handleOnSendComment}
      onCommentChanged={handleOnCommentChanged}
      onStarChanged={setSelectStars}
      stars={stars}
      onAddCommentResource={handleOnAddCommentResource}
      onDeleteCommentResource={handleOnDeleteCommentResource}
    />
  );
};
OrderBagComponentContainer.propTypes = {
  actions: PropTypes.shape({
    sendCommentFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  orderData: PropTypes.object,
  sendCommentReduxStatus: PropTypes.string,
  sendCommentError: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...OrderActionCreators,
  }, dispatch),
});

OrderBagComponentContainer.defaultProps = {
  orderData: null,
  sendCommentReduxStatus: null,
  sendCommentError: null,
};

const mapStateToProps = state => ({
  sendCommentReduxStatus: sendCommentReduxStatusSelector(state),
  sendCommentError: sendCommentErrorSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderBagComponentContainer);
