import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '../constants/Color';
import Default from './RWDComponent/Default';
import Mobile from './RWDComponent/Mobile';

const styles = {
  title: {
    width: '780px',
    color: '#000000',
    fontSize: '40px',
    fontWeight: 'bold',
    letterSpacing: '3.33px',
    lineHeight: '28px',
  },
  titleMobile: {
    width: '780px',
    color: '#000000',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '3.33px',
  },
  subtitle: {
    width: '100%',
    color: '#000000',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '3.33px',
    lineHeight: '28px',
    textAlign: 'left',
    margin: '20px 0 35px 0',
  },
  subtitleMobile: {
    width: '100%',
    color: '#000000',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '3.33px',
    lineHeight: '28px',
    textAlign: 'left',
    marginBottom: '20px',
    marginTop: '-20px',
  },
  subContainer: {
    width: '50%',
    display: 'inline-block',
    paddingLeft: '100px',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
  },
  rightTitle: {
    position: 'relative',
    width: '100%',
    color: Colors.introRightTitle,
    height: '40px',
    fontSize: '48px',
    letterSpacing: '6px',
    lineHeight: '28px',
    textAlign: 'left',
    marginBottom: '45px',
    right: '20px',
    paddingLeft: '20px',
    // zIndex: '1',
    backgroundColor: 'white',
  },
  rightTitleMobile: {
    position: 'relative',
    width: '100%',
    color: Colors.introRightTitle,
    fontSize: '32px',
    letterSpacing: '3px',
    lineHeight: '28px',
    textAlign: 'left',
    right: '20px',
    paddingLeft: '20px',
    // zIndex: '1',
    backgroundColor: 'white',
    height: '35px',
    marginTop: '50px',
  },
  leftTitle: {
    width: 'auto',
    display: 'inline-block',
  },
  dash: {
    display: 'inline-block',
    marginLeft: '20px',
    width: '26vw',
    borderBottom: '1px solid black',
    zIndex: '2',
  },
  dashMobile: {
    display: 'inline-block',
    marginLeft: '20px',
    width: '30vw',
    borderBottom: '1px solid black',
    zIndex: '2',
  },
};

const InfoTitle = ({
  leftTitle,
  leftSubtitle,
  rightTitle,
  rightSubtitle,
  content,
  contentStyle,
}) => (
  <div>
    <Default width={901}>
      <div style={styles.subContainer}>
        <div style={styles.title}>
          <h1 style={styles.leftTitle}>
            {leftTitle}
          </h1>
          <div style={styles.dash} />
        </div>
        <div style={styles.subtitle}>
          {leftSubtitle}
        </div>
      </div>
      <div style={styles.subContainer}>
        <h1 style={styles.rightTitle}>
          {rightTitle}
        </h1>
        <div style={styles.subtitle}>{rightSubtitle}</div>
        <div style={contentStyle}>
          {content}
        </div>
      </div>
    </Default>
    <Mobile width={900}>
      <div style={styles.titleMobile}>
        <h1 style={styles.leftTitle}>
          {leftTitle}
        </h1>
        <div style={styles.dashMobile} />
        <div style={styles.subtitleMobile}>{leftSubtitle}</div>
        <h1 style={styles.rightTitleMobile}>
          {rightTitle}
        </h1>
        <div style={styles.subtitleMobile}>{rightSubtitle}</div>
      </div>
      {content}
    </Mobile>
  </div>
);

InfoTitle.propTypes = {
  leftTitle: PropTypes.string.isRequired,
  leftSubtitle: PropTypes.string.isRequired,
  rightTitle: PropTypes.string.isRequired,
  rightSubtitle: PropTypes.string,
  content: PropTypes.any,
  contentStyle: PropTypes.object,
};

InfoTitle.defaultProps = {
  rightSubtitle: null,
  content: null,
  contentStyle: null,
};

export default InfoTitle;
