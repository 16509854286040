import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Avatar } from 'antd/lib/index';
import * as R from 'ramda';
import InfoModalComponent from '../../../../../../components/InfoModalComponent';
import ReduceDepositBagItem from '../ReduceDepositBagItem';
import Default from '../../../../../../components/RWDComponent/Default';
import Mobile from '../../../../../../components/RWDComponent/Mobile';
import Assets from '../../../../../../constants/Assets';

const styles = {
  mainContainer: {
    padding: 30,
    width: 800,
    height: 550,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    zIndex: 9999,
  },
  mainMobileContainer: {
    padding: 30,
    width: '95vw',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    zIndex: 9999,
  },
  titleMainContainer: {
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  editTitle: {
    fontSize: 21,
    fontFamily: 'Microsoft JhengHei Regular',
    color: 'black',
    width: '30%',
    weight: 100,
  },
  editSubTitle: {
    fontSize: 12,
    marginLeft: 10,
    fontFamily: 'Microsoft JhengHei Regular',
    color: 'black',
    width: '70%',
    weight: 100,
  },
  editItemContainer: {
    height: 400,
    width: '100%',
    overflowY: 'scroll',
  },
  table: {
    marginTop: 20,
    width: '100%',
  },
  tableHeader: {
    borderTop: '2px solid #939598',
    borderBottom: '1px solid #D1D3D4',
  },
  account: {
    padding: '10px 0px',
    width: '30%',
    color: '#58595B',
  },
  bag: {
    color: '#58595B',
    padding: '10px 0px',
    width: '70%',
  },
  select: {
    width: '80%',
    height: 30,
    marginTop: 15,
    outline: 'none',
  },
  selectionContainer: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  memberContainer: {
    height: 100,
    marginTop: 30,
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  memberName: {
    marginLeft: 15,
    fontSize: 18,
    fontFamily: 'Microsoft JhengHei Regular',
  },
  bagContainer: {
    height: 300,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  itemContainer: {
    paddingBottom: 30,
    borderBottom: '1px solid #D1D3D4',
  },
  plus: {
    width: 100,
    height: 100,
    padding: 40,
    fontSize: 30,
    color: '#939598',
    outline: 'none',
    cursor: 'pointer',
  },
  function: {
    width: 'fit-content',
    height: 50,
    marginTop: 30,
    fontSize: 20,
    padding: '10px 50px',
    border: '1px solid #AE4200',
    backgroundColor: 'white',
    color: '#939598',
    fontFamily: 'Microsoft JhengHei Regular',
    outline: 'none',
    cursor: 'pointer',
  },
};

const ReduceDepositAccountModalComponent = ({
  selectBagList,
  selectUser,
  userList,
  onModalClose,
  isVisible,
  mode,
  onSelectReduceDepositBag,
  onCreateReduceDepositItem,
  onUpdateReduceDepositItem,
}) => {
  const [selectUserList, setSelectUserList] = useState([]);
  const [selectAddReduceDepositUser, setSelectAddReduceDepositUser] = useState(selectUser);
  const [bagList, setBagList] = useState(selectBagList);

  useEffect(() => {
    setBagList(selectBagList);
  }, [selectBagList]);

  useEffect(() => {
    setSelectAddReduceDepositUser(selectUser);
  }, [selectUser]);

  useEffect(() => {
    setSelectUserList(userList);
  }, [userList]);

  const onEditReduceDeposit = () => {
    if (mode === 'add') {
      onCreateReduceDepositItem({ user: selectAddReduceDepositUser, bags: bagList });
    } else {
      onUpdateReduceDepositItem({ user: selectAddReduceDepositUser, bags: bagList });
    }
    setSelectAddReduceDepositUser(null);
    setBagList([]);
  };

  const verify = () => {
    if (mode === 'add') {
      return !R.isEmpty(selectAddReduceDepositUser) && !R.isNil(bagList) && !R.isEmpty(bagList);
    }
    return !R.isNil(bagList) && !R.isEmpty(bagList);
  };

  const content = (
    <React.Fragment>
      <div style={styles.titleMainContainer}>
        <div style={styles.titleContainer}>
          <div style={styles.editTitle}>{mode === 'add' ? '新增免押金帳號' : '免押金帳號'}</div>
          <div style={styles.editSubTitle}>
            {mode === 'edit' && '當包主將帳號加入免押金清單的同時，該帳號即可享有租該包款預付20%押金的優惠'}
          </div>
        </div>
      </div>
      <table style={styles.table}>
        <tbody>
          <tr style={styles.tableHeader}>
            <th style={styles.account}>帳號</th>
            <th style={styles.bag}>包款限制</th>
          </tr>
          <tr style={styles.itemContainer}>
            <td>
              {mode === 'add'
                ? (
                  <div style={styles.selectionContainer}>
                    <div style={styles.memberContainer}>
                      { !R.isEmpty(selectAddReduceDepositUser)
                      && (
                      <Avatar
                        size="large"
                        icon="user"
                        src={R.pathOr('', ['avatar', 'uri'], selectAddReduceDepositUser) || Assets.AvatarIcon}
                      />
                      )}
                      <div style={styles.memberName}>{R.pathOr('', ['name'], selectAddReduceDepositUser)}</div>
                    </div>
                    <select
                      style={styles.select}
                      onChange={(event) => {
                        setSelectAddReduceDepositUser(R.filter(user => user.id === Number(event.target.value), selectUserList)[0]);
                      }}
                    >
                      <option key={0} value={0} />
                      { selectUserList.map(user => (
                        <option
                          key={user.id}
                          value={user.id}
                        >
                          {user.name}
                        </option>
                      ))
                      }
                    </select>
                  </div>
                )
                : (
                  <div style={styles.memberContainer}>
                    <Avatar size="large" icon="user" src={R.pathOr('', ['avatar', 'uri'], selectAddReduceDepositUser)} />
                    <div style={styles.memberName}>{R.pathOr('', ['name'], selectAddReduceDepositUser)}</div>
                  </div>
                )}
            </td>
            <td style={styles.bagContainer}>
              {
                bagList.map(bag => (
                  <ReduceDepositBagItem
                    showRemoveButton={false}
                    key={bag.id}
                    data={bag}
                  />
                ))
              }
              <Button
                icon="plus-circle"
                type="link"
                style={styles.plus}
                onClick={onSelectReduceDepositBag}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        disabled={!verify()}
        type="button"
        style={styles.function}
        onClick={() => onEditReduceDeposit()}
      >
        {mode === 'add' ? '新增清單' : '儲存'}
      </button>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainContainer}>
              {content}
            </div>
        )}
          containerStyle={{ paddingTop: 30 }}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainMobileContainer}>
              {content}
            </div>
        )}
          containerStyle={{ paddingTop: 30 }}
        />
      </Mobile>

    </React.Fragment>
  );
};


ReduceDepositAccountModalComponent.propTypes = {
  isVisible: PropTypes.bool,
  selectUser: PropTypes.object,
  onModalClose: PropTypes.func.isRequired,
  onSelectReduceDepositBag: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  userList: PropTypes.array,
  selectBagList: PropTypes.array,
  onCreateReduceDepositItem: PropTypes.func.isRequired,
  onUpdateReduceDepositItem: PropTypes.func.isRequired,
};

ReduceDepositAccountModalComponent.defaultProps = {
  selectUser: {},
  userList: [],
  selectBagList: [],
  isVisible: false,
};

export default ReduceDepositAccountModalComponent;
