import React from 'react';
import PropTypes from 'prop-types';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import { UploadCommodityComponent } from './UploadCommodityComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    padding: 30,
    width: 1500,
    height: 900,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  mainMobileContainer: {
    padding: 20,
    width: '90vw',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  upload: {
    marginTop: 30,
    width: 162,
    minHeight: 54,
    fontFamily: 'Microsoft JhengHei Regular',
    backgroundColor: '#AE4200',
    color: 'white',
    textAlign: 'center',
    padding: '8px 0px',
    fontSize: 14,
    cursor: 'pointer',
    outline: 'none',
  },
  canNotUpload: {
    marginTop: 30,
    width: 162,
    minHeight: 54,
    fontFamily: 'Microsoft JhengHei Regular',
    backgroundColor: 'white',
    border: '1px solid #AE4200',
    color: '#AE4200',
    textAlign: 'center',
    padding: '8px 0px',
    fontSize: 14,
    cursor: 'not-allowed',
    outline: 'none',
  },
  uploadBagMobileStyle: {
    display: 'block',
    padding: '20px',
  },
  uploadOptionItemMobileContainer: {
    width: '100%',
    margin: '5px 0px',
  },
  uploadItemMobileContainer: {
    display: 'block',
  },
  uploadItemTitleMobileContainer: {
    width: '100%',
  },
};

const SubmitMonoBagComponent = ({
  tags,
  selectDraftBag,
  isVisible,
  submitDraftBag,
  onModalClose,
  onModifiedDraftData,
}) => {
  const renderChild = (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          <UploadCommodityComponent
            isMonoUploadBag
            tags={tags}
            uploadBag={selectDraftBag}
            onRemoveUploadBag={() => {}}
            index={0}
            onModifiedData={onModifiedDraftData}
          />
          <button
            type="button"
            style={styles.upload}
            onClick={submitDraftBag}
          >
            審核發佈
          </button>
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          <UploadCommodityComponent
            isMonoUploadBag
            tags={tags}
            uploadBag={selectDraftBag}
            onRemoveUploadBag={() => {}}
            index={0}
            onModifiedData={onModifiedDraftData}
            containerStyle={styles.uploadBagMobileStyle}
            optionItemMobileContainerStyle={styles.uploadOptionItemMobileContainer}
            itemMobileContainerStyle={styles.uploadItemMobileContainer}
            itemTitleMobileContainerStyle={styles.uploadItemTitleMobileContainer}
          />
          <button
            type="button"
            style={styles.upload}
            onClick={submitDraftBag}
          >
            審核發佈
          </button>
        </div>
      </Mobile>
    </React.Fragment>
  );

  return (
    <InfoModalComponent
      isVisible={isVisible}
      onModalClose={onModalClose}
      content={renderChild}
      containerStyle={{ paddingTop: 60, zIndex: 2 }}
    />
  );
};

SubmitMonoBagComponent.propTypes = {
  tags: PropTypes.array.isRequired,
  isVisible: PropTypes.bool,
  selectDraftBag: PropTypes.object,
  onModalClose: PropTypes.func.isRequired,
  onModifiedDraftData: PropTypes.func.isRequired,
  submitDraftBag: PropTypes.func.isRequired,
};

SubmitMonoBagComponent.defaultProps = {
  isVisible: false,
  selectDraftBag: {},
};

export default SubmitMonoBagComponent;
