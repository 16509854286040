import React from 'react';
import PropTypes from 'prop-types';
import InfoModalComponent from '../InfoModalComponent';
import Default from '../RWDComponent/Default';
import Mobile from '../RWDComponent/Mobile';

const styles = {
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: '',
    borderBottom: '#F1F1DD 1px solid',
    padding: 10,
  },
  itemMobile: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: '',
    borderBottom: '#F1F1DD 1px solid',
    padding: 10,
  },
  mainContainer: {
    width: 'fit-content',
    height: 500,
    padding: '40px 50px',
  },
  mainMobileContainer: {
    width: '85vw',
    height: 'auto',
    padding: '40px 20px',
  },
  itemTitle: {
    width: 100,
    fontSize: 15,
    color: 'black',
  },
  itemTitleMobile: {
    fontSize: 15,
    color: 'black',
  },
  itemSubTitle: {
    width: 100,
    fontSize: 13,
    marginLeft: 10,
    color: 'black',
  },
  itemSubTitleMobile: {
    fontSize: 13,
    marginLeft: 10,
    color: 'black',
  },
  itemIntro: {
    marginLeft: 150,
    fontWeight: 100,
    color: 'black',
    fontSize: 12,
  },
  itemIntroMobile: {
    fontWeight: 100,
    color: 'black',
    fontSize: 12,
  },
  titleContainer: {
    height: 70,
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'baseline',
    alignItems: 'flex-end',
  },
  title: {
    fontFamily: 'Baskerville, serif',
    fontSize: 50,
    color: '#6F6F6F',
  },
  titleMobile: {
    fontFamily: 'Baskerville, serif',
    fontSize: 30,
    color: '#6F6F6F',
  },
  subTitle: {
    marginLeft: 20,
    paddingBottom: 20,
    fontWeight: 100,
    color: 'black',
  },
  subTitleMobile: {
    paddingBottom: 20,
    fontWeight: 100,
    color: 'black',
  },
  ps1: {
    marginTop: 20,
    fontWeight: 100,
    color: 'black',
  },
  ps2: {
    fontWeight: 100,
    color: 'black',
  },
  mobileTitleContainer: {
    display: 'flex',
  },
};

const EstimateBagComponent = ({
  isVisible,
  onModalClose,
}) => {
  const content = [
    {
      id: 1, title: '全新', subTitle: '10/10', intro: '全新未使用過的商品',
    },
    {
      id: 2, title: 'A', subTitle: '9/10', intro: '無明顯使用痕跡及磨損，接近新品狀態',
    },
    {
      id: 3, title: 'B', subTitle: '8/10', intro: '有少許輕微使用痕跡及磨損，但不易察覺',
    },
    {
      id: 4, title: 'C', subTitle: '6-7/10', intro: '有些為無法修復的使用痕跡，但不影響使用',
    },
    {
      id: 5, title: 'D', subTitle: '5/10', intro: '有部分較明顯的使用痕跡或瑕疵，但性價比最高',
    },
  ];

  const renderEstimateBagItem = item => (
    <div key={item.id}>
      <Default>
        <div style={styles.item}>
          <div style={styles.itemTitle}>{item.title}</div>
          <div style={styles.itemSubTitle}>{item.subTitle}</div>
          <div style={styles.itemIntro}>{item.intro}</div>
        </div>
      </Default>
      <Mobile>
        <div style={styles.itemMobile}>
          <div style={styles.mobileTitleContainer}>
            <div style={styles.itemTitleMobile}>{item.title}</div>
            <div style={styles.itemSubTitleMobile}>{item.subTitle}</div>
          </div>
          <div style={styles.itemIntroMobile}>{item.intro}</div>
        </div>
      </Mobile>

    </div>
  );

  const mainContent = (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          <div style={styles.titleContainer}>
            <div style={styles.title}>Condition Guide</div>
            <div style={styles.subTitle}>商品狀態說明</div>
          </div>
          {content.map(item => (renderEstimateBagItem(item)))}
          <div style={styles.ps1}>*商品狀態係依平台最終審核為準</div>
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          <div style={styles.titleMobile}>Condition Guide</div>
          <div style={styles.subTitleMobile}>商品狀態說明</div>
          {content.map(item => (renderEstimateBagItem(item)))}
          <div style={styles.ps1}>*商品狀態係依平台最終審核為準</div>
        </div>
      </Mobile>
    </React.Fragment>
  );

  return (
    <InfoModalComponent
      isVisible={isVisible}
      content={mainContent}
      onModalClose={onModalClose}
    />
  );
};

EstimateBagComponent.propTypes = {
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func,
};

EstimateBagComponent.defaultProps = {
  isVisible: false,
  onModalClose: () => {},
};

export default EstimateBagComponent;
