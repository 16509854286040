export const receiptTypeId = {
  eReceipt: '1',
  eReceiptMobile: '2',
  donateReceipt: '3',
  taxIdReceipt: '4',
};


export default {
  receiptTypeId,
};
