export const homeStateSelector = state => (state.home);
export const memberStateSelector = state => (state.member);
export const bagStateSelector = state => (state.bag);
export const tagStateSelector = state => (state.tag);
export const orderStateSelector = state => (state.order);
export const contactStateSelector = state => (state.contact);
export const transactionStateSelector = state => (state.transaction);
export const subscribeStateSelector = state => (state.subscribe);
export const cartStateSelector = state => (state.cart);
export const themeStateSelector = state => (state.theme);
export const negotiationStateSelector = state => (state.negotiation);
export const onlineBagStateSelector = state => (state.onlineBag);
export const clothesStateSelector = state => (state.clothes);
export const headerCommodityStateSelector = state => (state.headerCommodity);
export const specialSelectStateSelector = state => (state.specialSelect);
export default {};
