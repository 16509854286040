import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Paths from '../../../../routes/Paths';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import BagItem from '../../../../components/BagItem';

const styles = {
  mainContainer: {
    margin: 20,
  },
  bagListContainer: {
    width: '100%',
    textAlign: 'center',
    maxWidth: 1500,
    margin: '0 auto',
  },
  invisibleBagItem: {
    height: 1,
    opacity: 0,
  },
};

const ThemeBagPage = ({
  reduxStatus,
  reduxError,
  data,
}) => (
  <div style={styles.mainContainer} key="baseContainer">
    <Link to={Paths.HOME}>回首頁</Link>
    <br />
    <Link to={Paths.THEME}>回主題活動列表</Link>
    <hr />
    <ReduxStatusVisualizedComponent
      reduxStatus={reduxStatus}
      showModalOnError
      modalFailureContent={reduxError}
    >
      <div style={styles.bagListContainer}>
        {(data || []).map(BagItem)}
        {/*
           NOTE: We add four invisible items to the end of the list in order
           to keep the items in the last row being arranged from left.
           The maximum number of items of each row is 5 (since we've limited the
           maximum with of container to be 1500), thus we only need 4 dummy
           items in the last row.
           */}
        <BagItem style={styles.invisibleBagItem} key="invisibleBagItem1" />
        <BagItem style={styles.invisibleBagItem} key="invisibleBagItem2" />
        <BagItem style={styles.invisibleBagItem} key="invisibleBagItem3" />
        <BagItem style={styles.invisibleBagItem} key="invisibleBagItem4" />


      </div>
    </ReduxStatusVisualizedComponent>
  </div>
);

ThemeBagPage.propTypes = {
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  data: PropTypes.array.isRequired,
};

ThemeBagPage.defaultProps = {
  reduxError: null,
};

export default ThemeBagPage;
