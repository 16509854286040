const transactionType = {
  rent: '租借',
  buy: '購買',
  rentBuy: '租借/購買',
};

const typeId = {
  rent: 1,
  buy: 2,
  rentBuy: 3,
};

const commodityTypeName = {
  bag: '包包',
  clothes: '服裝',
  accessories: '配件',
  essences: '香精',
};

const commodityTypeId = {
  bag: 1,
  clothes: 2,
  accessories: 3,
  essences: 4,
};

const tagTypeId = {
  brand: 1,
  bagStyle: 2,
  color: 3,
  clothesBrand: 4,
  clotheStyle: 5,
  accessoriesType: 6,
  occasion: 7,
  price: 8,
};

const couponCodeTypeId = {
  depositPrice: 1,
  sellPrice: 2,
  rentPrice: 3,
};

module.exports = {
  transactionType,
  typeId,
  commodityTypeId,
  commodityTypeName,
  tagTypeId,
  couponCodeTypeId,
};
