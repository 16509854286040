import {
  call,
  put,
  takeLatest,
  fork,
  take,
  select,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import CartActionCreator from '../Cart/ActionCreator';
import SubscribeActionCreator from '../Subscribe/ActionCreator';
import API from './API';
import configAxios from '../../lib/configAxios';
import {
  removeData,
  writeData,
  readData,
} from '../../utils/LocalStorageUtils';
import LocalStorageKey from '../../constants/LocalStorageKey';
import { buildFilterClause } from '../Bag/saga';
import {
  memberIdSelector,
  memberEmailSelector,
  reduceDepositListSelector,
} from './selector';
import { nonBlockedSagaAll } from '../../utils/nonBlockedPromiseAll';

const {
  getCartListFlowRequest,
  resetCartRequest,
} = CartActionCreator;

const { sendSubscribeFlowRequest } = SubscribeActionCreator;

const {
  fbLogInRequest,
  fbLogInSuccess,
  fbLogInFailure,
  smsLoginRequest,
  smsLoginSuccess,
  smsLoginFailure,
  sendSmsRequest,
  sendSmsSuccess,
  sendSmsFailure,

  logInRequest,
  logInSuccess,
  logInFailure,
  logOutRequest,
  logOutSuccess,
  logOutFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  updateMemberInfoRequest,
  updateMemberInfoSuccess,
  updateMemberInfoFailure,
  getMemberProfileRequest,
  getMemberProfileSuccess,
  getMemberProfileFailure,
  smsLoginFlowRequest,
  smsLoginFlowSuccess,
  smsLoginFlowFailure,
  sendSmsFlowRequest,
  sendSmsFlowSuccess,
  sendSmsFlowFailure,
  fbLogInFlowRequest,
  fbLogInFlowSuccess,
  fbLogInFlowFailure,
  logInFlowRequest,
  logInFlowSuccess,
  logInFlowFailure,
  logOutFlowRequest,
  logOutFlowSuccess,
  logOutFlowFailure,
  registerFlowRequest,
  registerFlowSuccess,
  registerFlowFailure,
  changePasswordFlowRequest,
  changePasswordFlowSuccess,
  changePasswordFlowFailure,
  forgotPasswordFlowRequest,
  forgotPasswordFlowSuccess,
  forgotPasswordFlowFailure,
  updateMemberInfoFlowRequest,
  updateMemberInfoFlowSuccess,
  updateMemberInfoFlowFailure,
  getMemberProfileFlowRequest,
  getMemberProfileFlowSuccess,
  getMemberProfileFlowFailure,
  toggleFollowFlowRequest,
  toggleFollowFlowSuccess,
  toggleFollowFlowFailure,
  toggleFollowRequest,
  toggleFollowSuccess,
  toggleFollowFailure,
  getFollowingListFlowRequest,
  getFollowingListFlowSuccess,
  getFollowingListFlowFailure,
  getFollowingListRequest,
  getFollowingListSuccess,
  getFollowingListFailure,
  uploadOutfitFlowRequest,
  uploadOutfitFlowSuccess,
  uploadOutfitFlowFailure,
  uploadOutfitRequest,
  uploadOutfitSuccess,
  uploadOutfitFailure,
  getMemberOutfitListFlowRequest,
  getMemberOutfitListFlowSuccess,
  getMemberOutfitListFlowFailure,
  getMemberOutfitListRequest,
  getMemberOutfitListSuccess,
  getMemberOutfitListFailure,
  getOutfitDetailFlowRequest,
  getOutfitDetailFlowSuccess,
  getOutfitDetailFlowFailure,
  getOutfitDetailRequest,
  getOutfitDetailSuccess,
  getOutfitDetailFailure,
  sendOutfitCommentFlowRequest,
  sendOutfitCommentFlowSuccess,
  sendOutfitCommentFlowFailure,
  sendOutfitCommentRequest,
  sendOutfitCommentSuccess,
  sendOutfitCommentFailure,
  getProtectTreeEndTimeFlowRequest,
  getProtectTreeEndTimeFlowSuccess,
  getProtectTreeEndTimeFlowFailure,
  getProtectTreeEndTimeRequest,
  getProtectTreeEndTimeSuccess,
  getProtectTreeEndTimeFailure,

  getReduceDepositListFlowRequest,
  getReduceDepositListFlowSuccess,
  getReduceDepositListFlowFailure,
  getReduceDepositListRequest,
  getReduceDepositListSuccess,
  getReduceDepositListFailure,

  deleteReduceDepositBagFlowRequest,
  deleteReduceDepositBagFlowSuccess,
  deleteReduceDepositBagFlowFailure,
  deleteReduceDepositBagRequest,
  deleteReduceDepositBagSuccess,
  deleteReduceDepositBagFailure,

  getReduceDepositUserListFlowRequest,
  getReduceDepositUserListFlowSuccess,
  getReduceDepositUserListFlowFailure,
  getReduceDepositUserListRequest,
  getReduceDepositUserListSuccess,
  getReduceDepositUserListFailure,

  createReduceDepositUserFlowRequest,
  createReduceDepositUserFlowSuccess,
  createReduceDepositUserFlowFailure,
  createReduceDepositUserRequest,
  createReduceDepositUserSuccess,
  createReduceDepositUserFailure,

  updateReduceDepositUserFlowRequest,
  updateReduceDepositUserFlowSuccess,
  updateReduceDepositUserFlowFailure,
  updateReduceDepositUserRequest,
  updateReduceDepositUserSuccess,
  updateReduceDepositUserFailure,

  deleteReduceDepositUserFlowRequest,
  deleteReduceDepositUserFlowSuccess,
  deleteReduceDepositUserFlowFailure,
  deleteReduceDepositUserRequest,
  deleteReduceDepositUserSuccess,
  deleteReduceDepositUserFailure,

  deleteOutfitsFlowRequest,
  deleteOutfitsFlowSuccess,
  deleteOutfitsFlowFailure,
  deleteOutfitsRequest,
  deleteOutfitsSuccess,
  deleteOutfitsFailure,

  getUserIdByCommunityNameFlowRequest,
  getUserIdByCommunityNameFlowSuccess,
  getUserIdByCommunityNameFlowFailure,
  getUserIdByCommunityNameRequest,
  getUserIdByCommunityNameSuccess,
  getUserIdByCommunityNameFailure,

  hadReadNotificationFlowRequest,
  hadReadNotificationFlowSuccess,
  hadReadNotificationFlowFailure,
  hadReadNotificationRequest,
  hadReadNotificationSuccess,
  hadReadNotificationFailure,

  allReadNotificationFlowRequest,
  allReadNotificationFlowSuccess,
  allReadNotificationFlowFailure,
  allReadNotificationRequest,
  allReadNotificationSuccess,
  allReadNotificationFailure,

  getNotificationListFlowRequest,
  getNotificationListFlowSuccess,
  getNotificationListFlowFailure,
  getNotificationListRequest,
  getNotificationListSuccess,
  getNotificationListFailure,


  getMemberCouponListFlowRequest,
  getMemberCouponListFlowSuccess,
  getMemberCouponListFlowFailure,
  getMemberCouponListRequest,
  getMemberCouponListSuccess,
  getMemberCouponListFailure,

  searchMemberListByKeywordFlowRequest,
  searchMemberListByKeywordFlowSuccess,
  searchMemberListByKeywordFlowFailure,
  searchMemberListByKeywordRequest,
  searchMemberListByKeywordSuccess,
  searchMemberListByKeywordFailure,

  passbaseNewAuthenticationFlowRequest,
  passbaseNewAuthenticationFlowSuccess,
  passbaseNewAuthenticationFlowFailure,
  passbaseNewAuthenticationRequest,
  passbaseNewAuthenticationSuccess,
  passbaseNewAuthenticationFailure,

  getWithdrawOrderNumbersRequest,
  getWithdrawOrderNumbersSuccess,
  getWithdrawOrderNumbersFailure,
  getWithdrawOrderNumbersFlowRequest,
  getWithdrawOrderNumbersFlowSuccess,
  getWithdrawOrderNumbersFlowFailure,
} = ActionCreator;

// ---------- TASKS ----------
export function* logIn(data) {
  yield put(logInRequest(data));
  try {
    const result = (yield call(API.login, data));
    yield put(logInSuccess(result.data));
    return result;
  } catch (error) {
    yield put(logInFailure(error));
    throw error;
  }
}

export function* fbLogIn(data) {
  yield put(fbLogInRequest(data));
  try {
    const result = (yield call(API.fbLogin, data));
    yield put(fbLogInSuccess(result.data));
    return result;
  } catch (error) {
    yield put(fbLogInFailure(error));
    throw error;
  }
}

export function* smsLogIn(data) {
  yield put(smsLoginRequest(data));
  try {
    const result = (yield call(API.smsLogin, data));
    yield put(smsLoginSuccess(result.data));
    return result;
  } catch (error) {
    yield put(smsLoginFailure(error));
    throw error;
  }
}

export function* sendSms(data) {
  yield put(sendSmsRequest(data));
  try {
    const result = (yield call(API.sendSms, data));
    yield put(sendSmsSuccess(result.data));
    return result;
  } catch (error) {
    yield put(sendSmsFailure(error));
    throw error;
  }
}

export function* logOut() {
  yield put(logOutRequest());
  try {
    const result = (yield call(API.logout));
    yield put(logOutSuccess(result.data));
    return result;
  } catch (error) {
    yield put(logOutFailure(error));
    throw error;
  }
}

export function* register(data) {
  yield put(registerRequest());
  try {
    const result = (yield call(API.register, data));
    yield put(registerSuccess(result.data));
    return result;
  } catch (error) {
    yield put(registerFailure(error));
    throw error;
  }
}

export function* changePassword(data) {
  yield put(changePasswordRequest());
  try {
    const result = (yield call(API.changePassword, data));
    yield put(changePasswordSuccess(result.data));
    return result;
  } catch (error) {
    yield put(changePasswordFailure(error));
    throw error;
  }
}

export function* forgotPassword(data) {
  yield put(forgotPasswordRequest());
  try {
    const result = (yield call(API.forgotPassword, data));
    yield put(forgotPasswordSuccess(result.data));
    return result;
  } catch (error) {
    yield put(forgotPasswordFailure(error));
    throw error;
  }
}

export function* updateMemberInfo(data) {
  yield put(updateMemberInfoRequest());
  try {
    const result = (yield call(API.updateMemberInfo, data));
    yield put(updateMemberInfoSuccess(result.data));

    const { isSubscribe } = data;

    const memberEmail = yield select(memberEmailSelector);

    if (isSubscribe) {
      yield put(sendSubscribeFlowRequest({ email: memberEmail }));
    }

    return result;
  } catch (error) {
    yield put(updateMemberInfoFailure(error));
    throw error;
  }
}

export function* toggleFollow(data) {
  yield put(toggleFollowRequest());
  try {
    const result = (yield call(API.toggleFollow, data));
    yield put(toggleFollowSuccess(result.data));
    return result;
  } catch (error) {
    yield put(toggleFollowFailure(error));
    throw error;
  }
}

export function* getFollowingList() {
  yield put(getFollowingListRequest());

  const userId = yield select(memberIdSelector);

  try {
    const result = (yield call(API.getFollowingList, { where: { userId } }));
    yield put(getFollowingListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getFollowingListFailure(error));
    throw error;
  }
}

export function* uploadOutfit(payload) {
  yield put(uploadOutfitRequest());

  const userId = yield select(memberIdSelector);

  try {
    const result = yield call(API.uploadOutfit, { userId, ...payload });
    yield put(uploadOutfitSuccess(result.data));
    return result;
  } catch (error) {
    yield put(uploadOutfitFailure(error));
    throw error;
  }
}

export function* hadReadNotification(payload) {
  yield put(hadReadNotificationRequest());
  try {
    const result = yield call(API.hadReadNotification, payload);
    yield put(hadReadNotificationSuccess(result.data));
    return result;
  } catch (error) {
    yield put(hadReadNotificationFailure(error));
    throw error;
  }
}


export function* passbaseNewAuthentication(payload) {
  yield put(passbaseNewAuthenticationRequest());
  try {
    const result = yield call(API.passbaseAuthentication, payload);
    yield put(passbaseNewAuthenticationSuccess(result.data));
    return result;
  } catch (error) {
    yield put(passbaseNewAuthenticationFailure(error));
    throw error;
  }
}

export function* allReadNotification() {
  yield put(allReadNotificationRequest());
  try {
    const result = yield call(API.allReadNotification);
    yield put(allReadNotificationSuccess(result.data));
    return result;
  } catch (error) {
    yield put(allReadNotificationFailure(error));
    throw error;
  }
}

export function* sendOutfitComment(payload) {
  yield put(sendOutfitCommentRequest());

  const userId = yield select(memberIdSelector);

  try {
    const result = yield call(API.sendOutfitComment, { userId, ...payload });
    yield put(sendOutfitCommentSuccess());
    return result;
  } catch (error) {
    yield put(sendOutfitCommentFailure(error));
    throw error;
  }
}

export function* getProtectTreeEndTime() {
  yield put(getProtectTreeEndTimeRequest());

  try {
    const result = yield call(API.getProtectTreeEndTime);
    yield put(getProtectTreeEndTimeSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getProtectTreeEndTimeFailure(error));
    throw error;
  }
}

export function* getReduceDepositUserList() {
  yield put(getReduceDepositUserListRequest());
  const userId = yield select(memberIdSelector);
  try {
    const result = yield call(API.getReduceDepositUserList, {
      where: {
        id: { neq: userId },
      },
      fields: ['name', 'id', 'avatarId', 'communityName'],
      include: [
        {
          relation: 'avatar',
          scope: { fields: 'uri' },
        },
      ],
    });
    yield put(getReduceDepositUserListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getReduceDepositUserListFailure(error));
    throw error;
  }
}

export function* createReduceDepositUser(payload) {
  yield put(createReduceDepositUserRequest());
  try {
    const userId = yield select(memberIdSelector);
    const user = R.pathOr({}, ['user'], payload);
    const bags = R.pathOr([], ['bags'], payload);
    yield call(nonBlockedSagaAll, bags.map(bag => call(API.createReduceDepositUser,
      {
        ownerId: userId,
        userId: user.id,
        bagId: bag.id,
      })));

    yield put(createReduceDepositUserSuccess());
  } catch (error) {
    yield put(createReduceDepositUserFailure(error));
    throw error;
  }
}

export function* deleteReduceDepositBag(payload) {
  yield put(deleteReduceDepositBagRequest());
  try {
    const { bagId, userId } = payload;
    const originReduceDeposit = yield select(reduceDepositListSelector);
    const selectReduceDeposit = R.filter(item => item.userId === userId && item.bagId === bagId,
      originReduceDeposit);

    const result = yield call(API.deleteReduceDepositBag, selectReduceDeposit[0].id);
    yield put(deleteReduceDepositBagSuccess());
    return result;
  } catch (error) {
    yield put(deleteReduceDepositBagFailure(error));
    throw error;
  }
}

export function* updateReduceDepositUser(payload) {
  const originReduceDeposit = yield select(reduceDepositListSelector);
  const getReduceDepositId = (userId, bagId) => {
    const target = R.filter(reduceDeposit => (
      reduceDeposit.userId === userId && reduceDeposit.bagId === bagId),
    originReduceDeposit);
    return target[0].id;
  };

  yield put(updateReduceDepositUserRequest());
  try {
    const { bags, user } = payload;
    const updateBagIds = bags.map(bag => bag.id);
    const selectReduceDeposit = R.filter(item => item.userId === user.id, originReduceDeposit);
    const originBagIds = selectReduceDeposit.map(reduceDeposit => reduceDeposit.bagId);
    const newReduceDepositBagIds = R.difference(updateBagIds, originBagIds);
    const deleteReduceDepositBagIds = R.difference(originBagIds, updateBagIds);
    const memberId = yield select(memberIdSelector);

    // add
    yield call(nonBlockedSagaAll,
      newReduceDepositBagIds.map(bagId => call(API.createReduceDepositUser, {
        ownerId: memberId,
        userId: user.id,
        bagId,
      })));

    // remove by reduceDepositId
    yield call(nonBlockedSagaAll,
      deleteReduceDepositBagIds.map(bagId => call(API.deleteReduceDepositBag,
        getReduceDepositId(user.id, bagId))));

    yield put(updateReduceDepositUserSuccess());
  } catch (error) {
    yield put(updateReduceDepositUserFailure(error));
    throw error;
  }
}

export function* deleteReduceDepositUser(payload) {
  yield put(deleteReduceDepositUserRequest());
  try {
    const { userId } = payload;
    const originReduceDeposit = yield select(reduceDepositListSelector);
    const selectReduceDeposit = R.filter(item => item.userId === userId, originReduceDeposit);

    yield call(nonBlockedSagaAll,
      selectReduceDeposit.map(item => call(API.deleteReduceDepositUser, item.id)));

    yield put(deleteReduceDepositUserSuccess());
  } catch (error) {
    yield put(deleteReduceDepositUserFailure(error));
    throw error;
  }
}

export function* deleteOutfits(payload) {
  yield put(deleteOutfitsRequest());
  try {
    const { outfitId } = payload;
    const result = (yield call(API.deletedOutfit, outfitId));
    yield put(deleteOutfitsSuccess(result.data));
    return result;
  } catch (error) {
    yield put(deleteOutfitsFailure(error));
    throw error;
  }
}

export function* getUserIdByCommunityName(payload) {
  yield put(getUserIdByCommunityNameRequest());
  try {
    const { communityName } = payload;
    const result = yield call(API.getUserIdByCommunityName, { communityName });
    const { userId } = result.data;
    yield put(getUserIdByCommunityNameSuccess(userId));
  } catch (error) {
    yield put(getUserIdByCommunityNameFailure(error));
    throw error;
  }
}


export function* getReduceDepositList() {
  yield put(getReduceDepositListRequest());

  try {
    const memberId = yield select(memberIdSelector);

    const result = yield call(API.getReduceDepositList, {
      where: { ownerId: memberId },
      include: [
        {
          relation: 'user',
          scope: {
            fields: ['name', 'avatarId', 'communityName'],
            include: [
              {
                relation: 'avatar',
                scope: { fields: 'uri' },
              },
            ],
          },
        },
        {
          relation: 'bag',
          scope: {
            fields: ['frontPicId', 'typeId', 'name', 'ownerId'],
            include: [
              {
                relation: 'frontPic',
              },
              {
                relation: 'type',
              },
              {
                relation: 'owner',
                scope: {
                  fields: ['name', 'avatarId', 'communityName'],
                },
              },
            ],
          },
        },
      ],
    });

    yield put(getReduceDepositListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getReduceDepositListFailure(error));
    throw error;
  }
}


export function* getOutfitDetail(payload) {
  yield put(getOutfitDetailRequest());
  const { id } = payload;
  try {
    const result = (yield call(API.outfitDetail, {
      where: { id },
      include: [
        {
          relation: 'user',
          scope: {
            fields: ['name', 'avatarId', 'communityName'],
            include: [
              {
                relation: 'avatar',
                scope: { fields: 'uri' },
              },
            ],
          },
        },
        {
          relation: 'resource',
          scope: { fields: 'uri' },
        },
        {
          relation: 'outfitComment',
          scope: {
            order: 'createTime ASC',
            include: [
              {
                relation: 'user',
                scope: {
                  fields: ['name', 'avatarId', 'communityName'],
                  include: [
                    {
                      relation: 'avatar',
                      scope: { fields: 'uri' },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    }));
    yield put(getOutfitDetailSuccess(R.mergeAll(result.data)));
    return result;
  } catch (error) {
    yield put(getOutfitDetailFailure(error));
    throw error;
  }
}

export function* getNotificationList() {
  yield put(getNotificationListRequest());

  try {
    const result = yield call(API.getNotificationList);
    yield put(getNotificationListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getNotificationListFailure(error));
    throw error;
  }
}


export function* getMemberCouponList() {
  yield put(getMemberCouponListRequest());

  try {
    const result = yield call(API.getMemberCouponList);
    yield put(getMemberCouponListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getMemberCouponListFailure(error));
    throw error;
  }
}
export function* searchMemberListByKeyword(keyword) {
  yield put(searchMemberListByKeywordRequest());

  try {
    const result = yield call(API.searchByKeyword, keyword);
    yield put(searchMemberListByKeywordSuccess(result.data));
    return result;
  } catch (error) {
    yield put(searchMemberListByKeywordFailure(error));

    throw error;
  }
}

export function* getMemberOutfitList() {
  yield put(getMemberOutfitListRequest());

  const userId = yield select(memberIdSelector);

  try {
    const result = yield call(API.getMemberOutfitList, {
      where: { userId },
      include: [
        {
          relation: 'user',
          scope: { fields: ['name', 'communityName'] },
        },
        {
          relation: 'resource',
        },
      ],
    });
    yield put(getMemberOutfitListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getMemberOutfitListFailure(error));
    throw error;
  }
}

export function* getMemberProfile(id) {
  yield put(getMemberProfileRequest());
  try {
    const {
      limit,
      skip,
      ...bagCondition
    } = buildFilterClause({
      where: {
        ownerId: id,
      },
      getClothes: true,
    });
    const result = (yield call(API.getMemberProfile, {
      id,
      bagCondition,
    }));
    yield put(getMemberProfileSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getMemberProfileFailure(error));
    throw error;
  }
}

function* getWithdrawOrderNumbers(userId) {
  try {
    yield put(getWithdrawOrderNumbersRequest());
    const result = yield call(API.getWithdrawOrders, {
      where: {
        userId,
      },
    });
    yield put(getWithdrawOrderNumbersSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getWithdrawOrderNumbersFailure(error));
    throw (error);
  }
}

// ---------- FLOWS ----------

export function* getNotificationListFlow() {
  try {
    const result = yield call(getNotificationList);
    yield put(getNotificationListFlowSuccess(result));
  } catch (error) {
    yield put(getNotificationListFlowFailure(error));
  }
}

export function* autoLoginFlow() {
  try {
    const authMemberData = readData(LocalStorageKey.AUTH_MEMBER, null);
    if (authMemberData) {
      const accessToken = R.pathOr(null, ['accessToken', 'id'], authMemberData);
      configAxios.setToken(accessToken);
      yield put(logInSuccess(authMemberData));
      yield put(getCartListFlowRequest());
      yield call(getNotificationListFlow);
    }
    yield put(logInFlowSuccess(authMemberData));
  } catch (error) {
    yield put(logInFlowFailure(error));
  }
}

export function* logInFlow({ payload }) {
  try {
    const result = yield call(logIn, payload);

    const accessToken = R.pathOr(null, ['data', 'accessToken', 'id'], result);
    configAxios.setToken(accessToken);
    writeData(LocalStorageKey.AUTH_MEMBER, result.data);
    yield put(getCartListFlowRequest());
    yield put(logInFlowSuccess(result));
  } catch (error) {
    yield put(logInFlowFailure(error));
  }
}

export function* fbLogInFlow({ payload }) {
  try {
    const result = yield call(fbLogIn, payload);
    const accessToken = R.pathOr(null, ['data', 'accessToken', 'id'], result);
    configAxios.setToken(accessToken);
    writeData(LocalStorageKey.AUTH_MEMBER, result.data);
    yield put(getCartListFlowRequest());
    yield put(fbLogInFlowSuccess(result));
  } catch (error) {
    yield put(fbLogInFlowFailure(error));
  }
}

export function* smsLoginFlow({ payload }) {
  try {
    const result = yield call(smsLogIn, payload);
    const accessToken = R.pathOr(null, ['data', 'accessToken', 'id'], result);
    configAxios.setToken(accessToken);
    writeData(LocalStorageKey.AUTH_MEMBER, result.data);
    yield put(getCartListFlowRequest());
    yield put(smsLoginFlowSuccess(result));
  } catch (error) {
    yield put(smsLoginFlowFailure(error));
  }
}

export function* sendSmsFlow({ payload }) {
  try {
    const result = yield call(sendSms, payload);
    yield put(sendSmsFlowSuccess(result));
  } catch (error) {
    yield put(sendSmsFlowFailure(error));
  }
}

export function* logOutFlow({ payload }) {
  try {
    const result = yield call(logOut, payload);
    yield put(logOutFlowSuccess(result));
  } catch (error) {
    yield put(logOutFlowFailure(error));
  }
  configAxios.setToken(null);
  yield put(resetCartRequest());
  removeData(LocalStorageKey.AUTH_MEMBER);
}

export function* changePasswordFlow({ payload }) {
  try {
    const result = yield call(changePassword, payload);
    yield put(changePasswordFlowSuccess(result));
  } catch (error) {
    yield put(changePasswordFlowFailure(error));
  }
}

export function* forgotPasswordFlow({ payload }) {
  try {
    const result = yield call(forgotPassword, payload);
    yield put(forgotPasswordFlowSuccess(result));
  } catch (error) {
    yield put(forgotPasswordFlowFailure(error));
  }
}

export function* updateMemberInfoFlow({ payload }) {
  try {
    const result = yield call(updateMemberInfo, payload);
    const memberData = readData(LocalStorageKey.AUTH_MEMBER) || {};
    memberData.user = result.data;
    writeData(LocalStorageKey.AUTH_MEMBER, memberData);
    yield put(updateMemberInfoFlowSuccess(result));
  } catch (error) {
    yield put(updateMemberInfoFlowFailure(error));
  }
}

export function* registerFlow({ payload }) {
  try {
    const result = yield call(register, payload);
    yield put(registerFlowSuccess(result));
  } catch (error) {
    yield put(registerFlowFailure(error));
  }
}

export function* getMemberProfileFlow({ payload }) {
  try {
    const result = yield call(getMemberProfile, payload);
    yield put(getMemberProfileFlowSuccess(result));
  } catch (error) {
    yield put(getMemberProfileFlowFailure(error));
  }
}

export function* getUserIdByCommunityNameFlow({ payload }) {
  try {
    const result = yield call(getUserIdByCommunityName, payload);
    yield put(getUserIdByCommunityNameFlowSuccess(result));
  } catch (error) {
    yield put(getUserIdByCommunityNameFlowFailure(error));
  }
}


export function* toggleFollowFlow({ payload }) {
  try {
    const { targetId } = payload;
    const result = yield call(toggleFollow, payload);
    yield put(toggleFollowFlowSuccess(result));
    if (result.status === 200) {
      yield put(getMemberProfileFlowRequest(targetId));
      yield put(getFollowingListFlowRequest());
    }
  } catch (error) {
    yield put(toggleFollowFlowFailure(error));
  }
}

export function* getFollowingListFlow() {
  try {
    const result = yield call(getFollowingList);
    yield put(getFollowingListFlowSuccess(result));
  } catch (error) {
    yield put(getFollowingListFlowFailure(error));
  }
}

export function* getMemberOutfitListFlow() {
  try {
    const result = yield call(getMemberOutfitList);
    yield put(getMemberOutfitListFlowSuccess(result));
  } catch (error) {
    yield put(getMemberOutfitListFlowFailure(error));
  }
}


export function* getMemberCouponListFlow() {
  try {
    const result = yield call(getMemberCouponList);
    yield put(getMemberCouponListFlowSuccess(result));
  } catch (error) {
    yield put(getMemberCouponListFlowFailure(error));
  }
}

export function* searchMemberListByKeywordFlow({ payload }) {
  try {
    const result = yield call(searchMemberListByKeyword, payload);
    yield put(searchMemberListByKeywordFlowSuccess(result));
  } catch (error) {
    yield put(searchMemberListByKeywordFlowFailure(error));
  }
}

export function* getOutfitDetailFlow({ payload }) {
  try {
    const result = yield call(getOutfitDetail, payload);
    yield put(getOutfitDetailFlowSuccess(result));
  } catch (error) {
    yield put(getOutfitDetailFlowFailure(error));
  }
}

export function* sendOutfitCommentFlow({ payload }) {
  try {
    const result = yield call(sendOutfitComment, payload);
    yield put(sendOutfitCommentFlowSuccess(result));
    if (result.status === 200) {
      const { outfitId } = payload;
      yield put(getOutfitDetailFlowRequest({ id: outfitId }));
    }
  } catch (error) {
    yield put(sendOutfitCommentFlowFailure(error));
  }
}

export function* getProtectTreeEndTimeFlow() {
  try {
    const result = yield call(getProtectTreeEndTime);
    yield put(getProtectTreeEndTimeFlowSuccess(result));
  } catch (error) {
    yield put(getProtectTreeEndTimeFlowFailure(error));
  }
}

export function* getReduceDepositListFlow() {
  try {
    const result = yield call(getReduceDepositList);
    yield put(getReduceDepositListFlowSuccess(result));
  } catch (error) {
    yield put(getReduceDepositListFlowFailure(error));
  }
}

export function* deleteReduceDepositBagFlow({ payload }) {
  try {
    const result = yield call(deleteReduceDepositBag, payload);
    if (result.status === 200) {
      yield put(getReduceDepositListFlowRequest());
    }
    yield put(deleteReduceDepositBagFlowSuccess(result));
  } catch (error) {
    yield put(deleteReduceDepositBagFlowFailure(error));
  }
}

export function* getReduceDepositUserListFlow() {
  try {
    const result = yield call(getReduceDepositUserList);
    yield put(getReduceDepositUserListFlowSuccess(result));
  } catch (error) {
    yield put(getReduceDepositUserListFlowFailure(error));
  }
}

export function* createReduceDepositUserFlow({ payload }) {
  try {
    yield call(createReduceDepositUser, payload);
    yield put(createReduceDepositUserFlowSuccess());

    yield put(getReduceDepositListFlowRequest());
  } catch (error) {
    // even occur error, still need to reload
    yield put(getReduceDepositListFlowRequest());
    yield put(createReduceDepositUserFlowFailure(error));
  }
}

export function* updateReduceDepositUserFlow({ payload }) {
  try {
    yield call(updateReduceDepositUser, payload);
    yield put(updateReduceDepositUserFlowSuccess());

    yield put(getReduceDepositListFlowRequest());
  } catch (error) {
    // even occur error, still need to reload
    yield put(getReduceDepositListFlowRequest());
    yield put(updateReduceDepositUserFlowFailure(error));
  }
}


export function* deleteReduceDepositUserFlow({ payload }) {
  try {
    yield call(deleteReduceDepositUser, payload);
    yield put(deleteReduceDepositUserFlowSuccess());

    yield put(getReduceDepositListFlowRequest());
  } catch (error) {
    // even occur error, still need to reload
    yield put(getReduceDepositListFlowRequest());
    yield put(deleteReduceDepositUserFlowFailure(error));
  }
}

export function* deleteOutfitsFlow({ payload }) {
  try {
    const { userId } = payload;
    const result = yield call(deleteOutfits, payload);
    yield put(deleteOutfitsFlowSuccess(result));
    if (result.status === 200) {
      yield put(getMemberProfileFlowRequest(userId));
    }
  } catch (error) {
    yield put(deleteOutfitsFlowFailure(error));
  }
}

export function* uploadOutfitFlow({ payload }) {
  try {
    const result = yield call(uploadOutfit, payload);
    yield put(uploadOutfitFlowSuccess(result));

    // if upload success, need to update member.data.outfitList & member,memberProfile
    if (result.status === 200) {
      yield put(getMemberOutfitListFlowRequest());
      const userId = yield select(memberIdSelector);
      yield put(getMemberProfileFlowRequest(userId));
    }
  } catch (error) {
    yield put(uploadOutfitFlowFailure(error));
  }
}

export function* hadReadNotificationFlow({ payload }) {
  try {
    const result = yield call(hadReadNotification, payload);
    yield put(hadReadNotificationFlowSuccess(result));

    // if upload success, need to update member.data.outfitList & member,memberProfile
    if (result.status === 200) {
      yield put(getNotificationListFlowRequest());
    }
  } catch (error) {
    yield put(hadReadNotificationFlowFailure(error));
  }
}

export function* allReadNotificationFlow() {
  try {
    const result = yield call(allReadNotification);
    yield put(allReadNotificationFlowSuccess(result));


    if (result.status === 200) {
      yield put(getNotificationListFlowRequest());
    }
  } catch (error) {
    yield put(allReadNotificationFlowFailure(error));
  }
}


export function* passbaseNewAuthenticationFlow({ payload }) {
  try {
    const result = yield call(passbaseNewAuthentication, payload);
    yield put(passbaseNewAuthenticationFlowSuccess(result));
  } catch (error) {
    yield put(passbaseNewAuthenticationFlowFailure(error));
  }
}

export function* authenticationErrorController() {
  // NOTE: Register a listener for authentication error (for example, token expired)
  //       and auto sign-out when this kind of error take place.
  const authenticationErrorChannel = eventChannel((emit) => {
    configAxios.setAuthenticationErrorHandler(() => emit(''));
    return () => {};
  });
  yield take(authenticationErrorChannel);
  yield put(logOutFlowRequest());
}

export function* getWithdrawOrderNumbersFlow() {
  try {
    const userId = yield select(memberIdSelector);
    const result = yield call(getWithdrawOrderNumbers, userId);
    yield put(getWithdrawOrderNumbersFlowSuccess(result.data));
  } catch (error) {
    yield put(getWithdrawOrderNumbersFlowFailure(error));
  }
}

export default [
  takeLatest(logInFlowRequest, logInFlow),
  takeLatest(fbLogInFlowRequest, fbLogInFlow),
  takeLatest(sendSmsFlowRequest, sendSmsFlow),
  takeLatest(smsLoginFlowRequest, smsLoginFlow),
  takeLatest(logOutFlowRequest, logOutFlow),
  takeLatest(registerFlowRequest, registerFlow),
  takeLatest(changePasswordFlowRequest, changePasswordFlow),
  takeLatest(forgotPasswordFlowRequest, forgotPasswordFlow),
  takeLatest(updateMemberInfoFlowRequest, updateMemberInfoFlow),
  takeLatest(getMemberProfileFlowRequest, getMemberProfileFlow),
  takeLatest(toggleFollowFlowRequest, toggleFollowFlow),
  takeLatest(getFollowingListFlowRequest, getFollowingListFlow),
  takeLatest(uploadOutfitFlowRequest, uploadOutfitFlow),
  takeLatest(getMemberOutfitListFlowRequest, getMemberOutfitListFlow),
  takeLatest(getOutfitDetailFlowRequest, getOutfitDetailFlow),
  takeLatest(sendOutfitCommentFlowRequest, sendOutfitCommentFlow),
  takeLatest(getProtectTreeEndTimeFlowRequest, getProtectTreeEndTimeFlow),
  takeLatest(getReduceDepositListFlowRequest, getReduceDepositListFlow),
  takeLatest(deleteReduceDepositBagFlowRequest, deleteReduceDepositBagFlow),
  takeLatest(getReduceDepositUserListFlowRequest, getReduceDepositUserListFlow),
  takeLatest(createReduceDepositUserFlowRequest, createReduceDepositUserFlow),
  takeLatest(deleteReduceDepositUserFlowRequest, deleteReduceDepositUserFlow),
  takeLatest(updateReduceDepositUserFlowRequest, updateReduceDepositUserFlow),
  takeLatest(getUserIdByCommunityNameFlowRequest, getUserIdByCommunityNameFlow),
  takeLatest(hadReadNotificationFlowRequest, hadReadNotificationFlow),
  takeLatest(allReadNotificationFlowRequest, allReadNotificationFlow),
  takeLatest(getNotificationListFlowRequest, getNotificationListFlow),
  takeLatest(passbaseNewAuthenticationFlowRequest, passbaseNewAuthenticationFlow),
  takeLatest(getMemberCouponListFlowRequest, getMemberCouponListFlow),
  takeLatest(searchMemberListByKeywordFlowRequest, searchMemberListByKeywordFlow),
  takeLatest(deleteOutfitsFlowRequest, deleteOutfitsFlow),
  fork(autoLoginFlow),
  fork(authenticationErrorController),
  takeLatest(getWithdrawOrderNumbersFlowRequest, getWithdrawOrderNumbersFlow),
];
