import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Icon, Popover, Button,
} from 'antd';
import { Link } from '@reach/router';
import styled from 'styled-components';
import ReactPixel from 'react-facebook-pixel';
import * as R from 'ramda';
import Paths from '../../../../routes/Paths';
import { FontSize } from '../../../../constants/FontSize';
import bagOrClothesPath from '../../../../utils/bagOrClothesPath';
import { commodityTypeId } from '../../../../constants/Type';
import { rentAmountCalculate } from '../../../../utils/rentAmountCalculate';

const styles = {
  logoContainer: {
    height: '100%',
    color: 'black',
    position: 'absolute',
  },
  logo: {
    height: '100%',
    padding: 15,
  },
  spacer: {
    flexGrow: 1,
  },
  headerMember: {
    margin: 10,
  },
  linkGroup: {
    margin: 10,
  },
  link: {
    fontSize: 16,
    color: 'black',
    margin: '15px 0',
    display: 'block',
  },
  searchIcon: {
    fontSize: '20px',
    width: 20,
    height: 40,
    textAlign: 'center',
    lineHeight: '40px',
    color: 'black',
  },
  searchInputBoxContainer: {
    display: 'inline-block',
    width: '200px',
  },
  searchInputBox: {
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid grey',
    width: '100%',
  },
  mobileMenuContainer: {
    backgroundColor: 'white',
    height: 500,
  },
  bottom: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: '40px',
    backgroundColor: 'yellow',
  },
  mobileLogo: {
    width: '20px',
    height: '20px',
  },
  mainContainer: {
    borderTop: '1px solid grey',
    padding: '10px 30px 50px 30px',
    position: 'absolute',
    width: '100vw',
    backgroundColor: 'white',
    top: '48px',
    height: '80vh',
    zIndex: 3,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  title: {
    margin: '20px 0',
    fontSize: '16px',
  },
  textContainer: {
    position: 'relative',
    marginTop: '30px',
  },
  textContent: {
    textAlign: 'center',
    height: '60px',
    marginTop: '10px0',
    position: 'relative',
    color: 'black',
    fontSize: '14px',
  },
  memberText: {
    position: 'absolute',
    left: '0%',
    color: 'black',
    fontSize: '16px',
  },
  mobileMemberLink: {
    color: 'black',
    textDecoration: 'underline',
  },
  mobileTrademarkText: {
    color: 'grey',
    textAlign: 'left',
    position: 'relative',
    fontSize: '10px',
    fontFamily: 'Myriad-Variable-Concept',
  },
  searchResultListContainer: {
    width: 200,
    height: 'fit-content',
    maxHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflowY: 'scroll',
  },
  searchResultItem: {
    width: '100%',
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  searchResultImage: {
    width: 35,
    height: 35,
    padding: 2.5,
    fontSize: 20,
    objectFit: 'cover',
  },
  searchResultName: {
    marginLeft: 10,
    color: 'black',
    fontSize: 14,
    width: 'fit-content',
    height: 'fit-content',
  },
  searchResult: {
    width: '100%',
    height: '100',
    outline: 'none',
    cursor: 'point',
    border: 'none',
    backgroundColor: 'transparent',
  },
  titleContainer: {
    height: 'auto',
    width: '100vw',
    fontSize: FontSize.body,
    display: 'flex',
    margin: '0 auto',
    marginTop: '5px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'black',
  },
  expand: {
    color: 'black',
  },
  subscribeContainer: {
    position: 'relative',
    marginTop: '5px',
  },
  deepExpand: {
    marginBottom: '20px',
  },
  linkStyle: {
    fontSize: '8px',
    margin: '12px 0px',
  },
  linkText: {
    color: 'black',
  },
  commodityLink: {
    outline: 'none',
  },
};

const SearchBox = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.3s;
  cursor: pointer;
  &:hover{
    width: 90%;
  }
  &:focus-within{
    width: 90%;
  }
`;

const CommodityLinkStyle = styled.div`
  fontSize: 14px;
  cursor: pointer;
  color: black;
  &:hover{
    color: #8B4513;
    text-decoration: underline;
  }
`;

const HeaderMobileComponent = ({
  isLogin,
  setIsShowMobileMenu,
  userList,
  bagList,
  onBagSearchByKeyword,
  onMemberSearchByKeyword,
  tags,
}) => {
  const [keyword, setKeyword] = useState('');
  const [isBagsExpand, setIsBagsExpandExpand] = useState(false);
  const [isClothesExpand, setIsClothesExpandExpand] = useState(false);
  const [isAccessoriesExpand, setIsAccessoriesExpandExpand] = useState(false);
  const [isEssencesExpand, setIsEssencesExpand] = useState(false);
  const [isCompanyExpand, setIsCompanyExpandExpand] = useState(false);


  const getCommodityType = (isUser, type) => {
    if (isUser) return 'user';
    if (type === commodityTypeId.bag) {
      return 'bags';
    }
    if (type === commodityTypeId.accessories) {
      return 'accessories';
    }
    return 'clothes';
  };

  const triggerSearchPixelAction = (isUser, item) => {
    const washAmount = R.pathOr(0, ['washAmount'], item);
    const rentAmountPerDay = R.pathOr(0, ['rentAmountPerDay'], item);
    ReactPixel.track('Search', {
      content_category: getCommodityType(isUser, item.commodityTypeId),
      content_ids: [R.pathOr(null, ['id'], item)],
      contents: [{
        id: R.pathOr(null, ['id'], item),
        quantity: '1',
        name: isUser ? R.pathOr('', ['communityName'], item) : R.pathOr('', ['name'], item),
      }],
      currency: 'TWD',
      search_string: keyword,
      value: rentAmountCalculate(rentAmountPerDay, 4, false, washAmount),
    });
  };

  const renderTitleBlock = () => (
    <React.Fragment>
      <Link
        to={`${Paths.BUY_AREA}?search={"bagType":[2],"allCommodity":true,"allNew":true}&page=1`}
        role="button"
        onClick={() => { setIsShowMobileMenu(false); }}
        tabIndex={0}
        onKeyPress={() => { setIsShowMobileMenu(false); }}
      >
        <div style={styles.titleContainer}>
          <h1 style={styles.title}>
            全新商品
          </h1>
        </div>
      </Link>
      <Link
        to={`${Paths.BUY_AREA}?search={"bagType":[2,3], "allCommodity": true}&page=1`}
        role="button"
        onClick={() => { setIsShowMobileMenu(false); }}
        tabIndex={0}
        onKeyPress={() => { setIsShowMobileMenu(false); }}
      >
        <div style={styles.titleContainer}>
          <h1 style={styles.title}>
            探索二手
          </h1>
        </div>
      </Link>
      <div style={styles.titleContainer}>
        <h1 style={styles.title}>
          租包包
        </h1>
        <Button
          icon={isClothesExpand ? 'up' : 'down'}
          onClick={() => setIsBagsExpandExpand(!isBagsExpand)}
          type="link"
          style={styles.expand}
        />
      </div>
      {isBagsExpand ? (
        <React.Fragment>
          {
             tags.map(({
               id: tagCategoryId,
               tags: tagItems,
             }) => {
               if (tagCategoryId !== 2) return null;
               return tagItems.map(({
                 name: tagName,
                 id: tagId,
               }) => (
                 <Link to={`${Paths.BAG_LIST}?search={"bagType":[1,3] , "tags":[[${tagId}]]}&page=1`} key={tagId}>
                   <div
                     style={styles.commodityLink}
                     role="button"
                     onClick={() => { setIsShowMobileMenu(false); }}
                     tabIndex={0}
                     onKeyPress={() => { setIsShowMobileMenu(false); }}
                   >
                     <CommodityLinkStyle>
                       {tagName}
                     </CommodityLinkStyle>
                   </div>
                 </Link>
               ));
             })
           }
          <div
            style={styles.commodityLink}
            role="button"
            onClick={() => { setIsShowMobileMenu(false); }}
            tabIndex={0}
            onKeyPress={() => { setIsShowMobileMenu(false); }}
          >
            <Link to={`${Paths.BAG_LIST}?search={"bagType":[1,3]}&page=1`}>
              <CommodityLinkStyle>
                瀏覽全部
              </CommodityLinkStyle>
            </Link>
          </div>
        </React.Fragment>
      ) : null}
      <div style={styles.titleContainer}>
        <h1 style={styles.title}>
          租服裝
        </h1>
        <Button
          icon={isClothesExpand ? 'up' : 'down'}
          onClick={() => setIsClothesExpandExpand(!isClothesExpand)}
          type="link"
          style={styles.expand}
        />
      </div>
      {isClothesExpand ? (
        <React.Fragment>
          {
             tags.map(({
               id: tagCategoryId,
               tags: tagItems,
             }) => {
               if (tagCategoryId !== 5) return null;
               return tagItems.map(({
                 name: tagName,
                 id: tagId,
               }) => (
                 <Link to={`${Paths.CLOTHES_LIST}?search={"bagType":[1,3] , "tags":[[${tagId}]]}&page=1`} key={tagId}>
                   <div
                     style={styles.commodityLink}
                     role="button"
                     onClick={() => { setIsShowMobileMenu(false); }}
                     tabIndex={0}
                     onKeyPress={() => { setIsShowMobileMenu(false); }}
                   >
                     <CommodityLinkStyle>
                       {tagName}
                     </CommodityLinkStyle>
                   </div>
                 </Link>
               ));
             })
           }
          <div
            style={styles.commodityLink}
            role="button"
            onClick={() => { setIsShowMobileMenu(false); }}
            tabIndex={0}
            onKeyPress={() => { setIsShowMobileMenu(false); }}
          >
            <Link to={`${Paths.CLOTHES_LIST}?search={"bagType":[1,3]}&page=1`}>
              <CommodityLinkStyle>
                瀏覽全部
              </CommodityLinkStyle>
            </Link>
          </div>
        </React.Fragment>
      ) : null}
      <div style={styles.titleContainer}>
        <h1 style={styles.title}>
          租配件
        </h1>
        <Button
          icon={isAccessoriesExpand ? 'up' : 'down'}
          onClick={() => setIsAccessoriesExpandExpand(!isAccessoriesExpand)}
          type="link"
          style={styles.expand}
        />
      </div>
      {isAccessoriesExpand ? (
        <React.Fragment>
          {
             tags.map(({
               id: tagCategoryId,
               tags: tagItems,
             }) => {
               if (tagCategoryId !== 6) return null;
               return tagItems.map(({
                 name: tagName,
                 id: tagId,
               }) => (
                 <Link to={`${Paths.ACCESSORIES_LIST}?search={"bagType":[1,3],"tags":[[${tagId}]]}&page=1`} key={tagId}>
                   <div
                     style={styles.commodityLink}
                     role="button"
                     onClick={() => { setIsShowMobileMenu(false); }}
                     tabIndex={0}
                     onKeyPress={() => { setIsShowMobileMenu(false); }}
                   >
                     <CommodityLinkStyle>
                       {tagName}
                     </CommodityLinkStyle>
                   </div>
                 </Link>
               ));
             })
           }
          <div
            style={styles.commodityLink}
            role="button"
            onClick={() => { setIsShowMobileMenu(false); }}
            tabIndex={0}
            onKeyPress={() => { setIsShowMobileMenu(false); }}
          >
            <Link to={`${Paths.ACCESSORIES_LIST}?search={"bagType":[1,3]}&page=1`}>
              <CommodityLinkStyle>
                瀏覽全部
              </CommodityLinkStyle>
            </Link>
          </div>
        </React.Fragment>
      ) : null}
      <div style={styles.titleContainer}>
        <h1 style={styles.title}>
          清潔保養
        </h1>
        <Button
          icon={isEssencesExpand ? 'up' : 'down'}
          onClick={() => setIsEssencesExpand(!isEssencesExpand)}
          type="link"
          style={styles.expand}
        />
      </div>
      {isEssencesExpand ? (
        <React.Fragment>
          {
             tags.map(({
               id: tagCategoryId,
               tags: tagItems,
             }) => {
               if (tagCategoryId !== 9) return null;
               return tagItems.map(({
                 name: tagName,
                 id: tagId,
               }) => (
                 <Link to={`${Paths.ESSENCES_LIST}?search={"bagType":[2],"tags":[[${tagId}]]}&page=1`} key={tagId}>
                   <div
                     style={styles.commodityLink}
                     role="button"
                     onClick={() => { setIsShowMobileMenu(false); }}
                     tabIndex={0}
                     onKeyPress={() => { setIsShowMobileMenu(false); }}
                   >
                     <CommodityLinkStyle>
                       {tagName}
                     </CommodityLinkStyle>
                   </div>
                 </Link>
               ));
             })
           }
          <div
            style={styles.commodityLink}
            role="button"
            onClick={() => { setIsShowMobileMenu(false); }}
            tabIndex={0}
            onKeyPress={() => { setIsShowMobileMenu(false); }}
          >
            <Link to={`${Paths.ESSENCES_LIST}?search={"bagType":[2]}&page=1`}>
              <CommodityLinkStyle>
                瀏覽全部
              </CommodityLinkStyle>
            </Link>
          </div>
        </React.Fragment>
      ) : null}
      <Link
        to={Paths.HOW_IT_WORKS}
        role="button"
        onClick={() => { setIsShowMobileMenu(false); }}
        tabIndex={0}
        onKeyPress={() => { setIsShowMobileMenu(false); }}
      >
        <div style={styles.titleContainer}>
          <h1 style={styles.title}>
            如何運作
          </h1>
        </div>
      </Link>
      <div style={styles.titleContainer}>
        <h1 style={styles.title}>
          關於我們
        </h1>
        <Button
          icon={isCompanyExpand ? 'up' : 'down'}
          onClick={() => setIsCompanyExpandExpand(!isCompanyExpand)}
          type="link"
          style={styles.expand}
        />
      </div>
      {isCompanyExpand ? (
        <React.Fragment>
          <Link to={Paths.ABOUT}>
            <div style={styles.commodityLink}>
              <CommodityLinkStyle>
                關於OP
              </CommodityLinkStyle>
            </div>
          </Link>
          <Link to={Paths.HOW_IT_WORKS}>
            <div style={styles.commodityLink}>
              <CommodityLinkStyle>
                如何運作
              </CommodityLinkStyle>
            </div>
          </Link>
          <Link to={Paths.FAQ}>
            <div style={styles.commodityLink}>
              <CommodityLinkStyle>
                常見問與答
              </CommodityLinkStyle>
            </div>
          </Link>
          <div style={styles.commodityLink}>
            <CommodityLinkStyle>
              <a
                href="https://drive.google.com/file/d/1a5qAedAj4Es4lhYiG2EyNZo3Op-PdNKD/view?usp=sharing"
                target="_blank: "
                style={styles.linkText}
              >
                上傳簡易教學操作
              </a>
            </CommodityLinkStyle>
          </div>
          <div style={styles.commodityLink}>
            <Link to={`${Paths.BAG_LIST}?search={"bagType":[1,3]}&page=1`}>
              <CommodityLinkStyle>
                瀏覽商品
              </CommodityLinkStyle>
            </Link>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );

  const renderMemberSearchResult = user => (
    <React.Fragment
      key={`user ${user.id}`}
    >
      <button
        type="button"
        style={styles.searchResult}
        onClick={() => setKeyword('')}
      >
        <Link
          to={`${Paths.MEMBER}/${R.pathOr('', ['communityName'], user)}`}
          onClick={() => triggerSearchPixelAction(true, user)}
        >
          <div style={styles.searchResultItem}>
            <Avatar
              style={styles.searchResultImage}
              src={R.path(['avatar', 'uri'], user)}
              icon="user"
              size={35}
            />
            <div style={styles.searchResultName}>{R.path(['communityName'], user)}</div>
          </div>
        </Link>
      </button>
    </React.Fragment>
  );

  const renderBagSearchResult = (bag) => {
    const commodityType = R.pathOr(1, ['commodityTypeId'], bag);
    return (
      <React.Fragment
        key={`bag ${bag.id}`}
      >
        <button
          type="button"
          style={styles.searchResult}
          onClick={() => {
            setKeyword('');
          }}
        >
          <Link
            to={`${bagOrClothesPath(commodityType)}/${R.pathOr('', ['bagNo'], bag)}`}
            onClick={() => triggerSearchPixelAction(false, bag)}
          >
            <div style={styles.searchResultItem}>
              <img
                alt=""
                style={styles.searchResultImage}
                src={R.path(['frontPic', 'uri'], bag)}
              />
              <div style={styles.searchResultName}>{R.path(['name'], bag)}</div>
            </div>
          </Link>
        </button>
      </React.Fragment>
    );
  };

  const renderSearchResult = () => (
    <div style={styles.searchResultListContainer}>
      {bagList.map(bag => renderBagSearchResult(bag))}
      {userList.map(user => renderMemberSearchResult(user))}
    </div>
  );

  return (
    <div
      style={styles.mainContainer}
      onMouseLeave={() => { setIsShowMobileMenu(false); }}
    >
      <Popover
        arrowPointAtCenter
        visible={(keyword.length > 0) && ((bagList.length > 0) || (userList.length > 0))}
        content={renderSearchResult()}
        overlayClassName=".ant-popover-title"
        trigger="click"
        placement="bottom"
        onVisibleChange={(e) => {
          if (!e) {
            setKeyword('');
          }
        }}
      >
        <SearchBox>
          <Icon type="search" style={styles.searchIcon} />
          <form style={styles.searchInputBoxContainer}>
            <input
              onChange={(event) => {
                if (event.target.value.length > 0) {
                  setKeyword(event.target.value);
                  onBagSearchByKeyword({ keyword: event.target.value });
                  onMemberSearchByKeyword({ keyword: event.target.value });
                } else {
                  setKeyword('');
                }
              }}
              style={styles.searchInputBox}
              value={keyword}
            />
            <button type="submit" hidden />
          </form>
        </SearchBox>
      </Popover>
      {renderTitleBlock()}
      <hr />
      <Link
        style={styles.link}
        to={Paths.SUBMIT_BAG}
        onClick={() => { setIsShowMobileMenu(false); }}
      >
        <h1 style={styles.title}>立即上傳</h1>
      </Link>

      <br />

      <div style={styles.link}>
        <hr />
      </div>

      <div style={styles.textContainer}>
        {isLogin ? (
          <div style={styles.textContent}>
            <div style={styles.memberText}>
              <Link
                to={Paths.LOGOUT}
                style={styles.mobileMemberLink}
                onClick={() => { setIsShowMobileMenu(false); }}
              >
                Log Out 登出
              </Link>
            </div>
          </div>
        ) : null}

        <div style={styles.mobileTrademarkText}>
          © 2023 OP Inc. All rights reserved
          <br />
        </div>
      </div>
    </div>
  );
};


HeaderMobileComponent.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  setIsShowMobileMenu: PropTypes.func,
  userList: PropTypes.array,
  bagList: PropTypes.array,
  onMemberSearchByKeyword: PropTypes.func.isRequired,
  onBagSearchByKeyword: PropTypes.func.isRequired,
  tags: PropTypes.array,
};

HeaderMobileComponent.defaultProps = {
  setIsShowMobileMenu: () => {},
  userList: [],
  bagList: [],
  tags: [],
};


export default HeaderMobileComponent;
