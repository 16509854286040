import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import API from './API';

const {
  sendContactRequest,
  sendContactSuccess,
  sendContactFailure,

  sendContactFlowRequest,
  sendContactFlowSuccess,
  sendContactFlowFailure,
} = ActionCreator;

// ---------- TASKS ----------
export function* sendContact(data) {
  yield put(sendContactRequest(data));
  try {
    const result = (yield call(API.contact, data));
    yield put(sendContactSuccess(result.data));
    return result;
  } catch (error) {
    yield put(sendContactFailure(error));
    throw error;
  }
}

// ---------- FLOWS ----------
export function* sendContactFlow({ payload }) {
  try {
    const result = yield call(sendContact, payload);
    yield put(sendContactFlowSuccess(result));
  } catch (error) {
    yield put(sendContactFlowFailure(error));
  }
}

export default [
  takeLatest(sendContactFlowRequest, sendContactFlow),
];
