import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProductTerminateComponent from './ProductTerminateComponent';
import {
  bagEditSelectorReduxStatusSelector,
} from '../../selector';

import ActionCreator from '../../ActionCreator';
import BagActionCreator from '../../../Bag/ActionCreator';
import ReduxStatus from '../../../../constants/ReduxStatus';
import Type from '../../../../constants/Type';

const ProductTerminateComponentContainer = ({
  reduxStatus,
  bagData,
  isVisible,
  onModalClose,
  actions: {
    bagEditFlowRequest,
    getMyBagListFlowRequest,
  },
}) => {
  useEffect(() => {
    if (reduxStatus === ReduxStatus.STATUS_NORMAL) {
      getMyBagListFlowRequest({
        typeId: [
          Type.typeId.rent,
          Type.typeId.buy,
          Type.typeId.rentBuy,
        ],
        currentPage: 0,
        limit: 999999,
      });
    }
  }, [reduxStatus]);

  return (
    <ProductTerminateComponent
      bagData={bagData}
      isVisible={isVisible}
      onModalClose={onModalClose}
      onProductTerminate={bagEditFlowRequest}
    />
  );
};

ProductTerminateComponentContainer.propTypes = {
  bagData: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    bagEditFlowRequest: PropTypes.func.isRequired,
    getMyBagListFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  reduxStatus: PropTypes.string,
};

ProductTerminateComponentContainer.defaultProps = {
  bagData: {},
  reduxStatus: null,
};

const mapStateToProps = state => ({
  reduxStatus: bagEditSelectorReduxStatusSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreator,
    ...BagActionCreator,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductTerminateComponentContainer);
