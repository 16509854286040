import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NegotiationComponent from './NegotiationComponent';
import ActionCreator from '../../ActionCreator';
import ReduxStatus from '../../../../constants/ReduxStatus';

import {
  reduxStatusSelector,
  reduxErrorSelector,
  negotiationListSelector,
} from '../../selector';
import {
  isLogInSelector,
  memberIdSelector,
} from '../../../Member/selector';

const NegotiationContainer = ({
  reduxStatus,
  reduxError,
  bagId,
  data,
  isLogin,
  memberId,
  bagOwnerId,
  actions: {
    replyToMessageFlowRequest,
    submitNegotiationFlowRequest,
    getNegotiationListFlowRequest,
    negotiationWithOhPhireFlowRequest,
    acceptAmountFlowRequest,
    resetStatus,
  },
  isVisible,
  onModalClose,
  isNegotiationWithAdmin,
}) => {
  const [isShowReplySentHint, setIsShowReplySentHint] = useState(false);

  useEffect(() => {
    if (bagId && isLogin) {
      getNegotiationListFlowRequest(bagId);
    }
    return resetStatus;
  }, []);

  useEffect(() => {
    if (reduxStatus === ReduxStatus.STATUS_NORMAL) {
      resetStatus();
      getNegotiationListFlowRequest(bagId);
    }
  }, [reduxStatus]);

  useEffect(() => {
    if (isVisible && bagId && isLogin) {
      getNegotiationListFlowRequest(bagId);
    }
  }, [isVisible]);


  const sendFromMessage = (user, message, amount) => {
    if (!amount || Number(amount) === 0) {
      return;
    }

    if (isNegotiationWithAdmin) {
      negotiationWithOhPhireFlowRequest({
        bagId,
        fromMessage: message,
        amount,
      });
    } else {
      submitNegotiationFlowRequest({
        bagId,
        fromMessage: message,
        amount,
      });
    }
  };

  const handleOnAcceptAmount = (acceptAmountData) => {
    acceptAmountFlowRequest({
      ...acceptAmountData,
      bagId,
    });
  };

  const onReplyToMessage = (replyToMessage) => {
    replyToMessageFlowRequest({
      bagId,
      ...replyToMessage,
    });
    setIsShowReplySentHint(true);
  };

  return (
    <NegotiationComponent
      onReplyToMessage={onReplyToMessage}
      bagOwnerId={bagOwnerId}
      reduxError={reduxError}
      bagId={bagId}
      data={data}
      isLogin={isLogin}
      memberId={memberId}
      isVisible={isVisible}
      onModalClose={onModalClose}
      sendFromMessage={sendFromMessage}
      isNegotiationWithAdmin={isNegotiationWithAdmin}
      onAcceptAmount={handleOnAcceptAmount}
      isShowReplySentHint={isShowReplySentHint}
      onReplySentHintClose={() => setIsShowReplySentHint(false)}
    />
  );
};

NegotiationContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  bagId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  data: PropTypes.array,
  isLogin: PropTypes.bool,
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  memberId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  actions: PropTypes.shape({
    replyToMessageFlowRequest: PropTypes.func.isRequired,
    submitNegotiationFlowRequest: PropTypes.func.isRequired,
    getNegotiationListFlowRequest: PropTypes.func.isRequired,
    negotiationWithOhPhireFlowRequest: PropTypes.func.isRequired,
    acceptAmountFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  isNegotiationWithAdmin: PropTypes.bool,
  bagOwnerId: PropTypes.number,
};

NegotiationContainer.defaultProps = {
  data: [],
  isVisible: false,
  reduxStatus: null,
  reduxError: null,
  isLogin: false,
  bagId: null,
  memberId: null,
  bagOwnerId: 0,
  isNegotiationWithAdmin: false,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  data: negotiationListSelector(state),
  isLogin: isLogInSelector(state),
  memberId: memberIdSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreator,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NegotiationContainer);
