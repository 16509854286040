/* eslint-disable no-console */
import envConfig from '../../../lib/configEnv';

const identificationAPI = async (url, formData, errorMessage) => {
  try {
    const response = await fetch(`${envConfig.API_BASE_URL}${url}`, {
      method: 'POST',
      body: formData,
    });
    if (response.ok) {
      return response.json();
    }
    console.log('Upload failed:', response.status, response.statusText);
    return 'fail';
  } catch (error) {
    console.log(errorMessage, error);
    return 'fail';
  }
};

export default identificationAPI;
