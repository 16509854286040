import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';
import ActionCreators from '../../ActionCreator';
import MemberProfileEditPage from './MemberProfileEditPage';
import {
  reduxStatusSelector,
  reduxErrorSelector,
  isLogInSelector,
  memberInfoSelector,
} from '../../selector';
import Paths from '../../../../routes/Paths';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

const styles = {
  mobileFormStyle: {
    width: '100%',
  },
};

const MemberProfileEditContainer = ({
  isLogin,
  id,
  reduxStatus,
  reduxError,
  memberInfo,
  actions: {
    updateMemberInfoFlowRequest,
  },
}) => (
  isLogin
    ? (
      <React.Fragment>
        <Default>
          <MemberProfileEditPage
            id={id}
            reduxStatus={reduxStatus}
            reduxError={reduxError}
            memberInfo={memberInfo}
            handleUpdateMemberInfo={updateMemberInfoFlowRequest}
          />
        </Default>
        <Mobile>
          <MemberProfileEditPage
            id={id}
            reduxStatus={reduxStatus}
            reduxError={reduxError}
            memberInfo={memberInfo}
            handleUpdateMemberInfo={updateMemberInfoFlowRequest}
            style={styles.mobileFormStyle}
          />
        </Mobile>
      </React.Fragment>

    )
    : <Redirect to={Paths.LOGIN} noThrow />
);

MemberProfileEditContainer.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  memberInfo: PropTypes.shape({}),
  actions: PropTypes.shape({
    resetStatus: PropTypes.func.isRequired,
    getMemberProfileFlowRequest: PropTypes.func.isRequired,
    updateMemberInfoFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  isLogin: PropTypes.bool,
};

MemberProfileEditContainer.defaultProps = {
  memberInfo: null,
  id: null,
  isLogin: false,
  reduxStatus: null,
  reduxError: null,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  memberInfo: memberInfoSelector(state),
  isLogin: isLogInSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberProfileEditContainer);
