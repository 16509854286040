import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';
import QueryString from 'query-string';
import ActionCreators from '../../ActionCreator';
import OrderActionCreators from '../../../Order/ActionCreator';
import MemberPage from './MemberPage';
import { typeId } from '../../../../constants/Type';

import {
  reduxStatusSelector,
  reduxErrorSelector,
  isLogInSelector,
  memberNameSelector,
  memberInfoSelector,
  reduceDepositListSelector,
} from '../../selector';

import {
  orderListDataSelector,
} from '../../../Order/selector';

import {
  bagListSelector,
} from '../../../Bag/selector';

import BagActionCreators from '../../../Bag/ActionCreator';

const MemberPageContainer = ({
  location,
  reduxStatus,
  reduxError,
  isLogin,
  memberName,
  memberInfo,
  reduceDepositList,
  myBagList,
  actions: {
    resetStatus,
    changePasswordFlowRequest,
    updateMemberInfoFlowRequest,
    getReduceDepositListFlowRequest,
    getMyBagListFlowRequest,
    getMyRentedSoldOrderListFlowRequest,
  },
  withdrawOrderList,
}) => {
  const [isDefaultShowMemberCoupon, setIsDefaultShowMemberCoupon] = useState(
    JSON.parse((R.pathOr(null, ['show'], QueryString.parse(R.pathOr(null, ['search'], location))))),
  );
  const memberId = R.pathOr(0, ['id'], memberInfo);

  const filleterNuSentWithdrawCondition = {
    isAbleSentWithdrawRequest: true,
    isRequestWithdraw: false,
  };
  useEffect(() => {
    setIsDefaultShowMemberCoupon(JSON.parse((R.pathOr(null, ['show'], QueryString.parse(R.pathOr(null, ['search'], location))))));
  }, [location]);

  useEffect(() => {
    if (isLogin) {
      getMyBagListFlowRequest({ typeId: typeId.rent });
      getReduceDepositListFlowRequest();
      getMyRentedSoldOrderListFlowRequest({
        userId: memberId,
        filleter: filleterNuSentWithdrawCondition,
      });
    }
  }, []);
  const withdrawNumber = withdrawOrderList ? withdrawOrderList.length : 0;
  return (
    <MemberPage
      myBagList={myBagList}
      reduceDepositList={reduceDepositList}
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      isLogin={isLogin}
      memberName={memberName}
      memberInfo={memberInfo}
      handleChangePassword={changePasswordFlowRequest}
      handleUpdateMemberInfo={updateMemberInfoFlowRequest}
      isDefaultShowMemberCoupon={isDefaultShowMemberCoupon}
      resetStatus={resetStatus}
      withdrawNumber={withdrawNumber}
    />
  );
};

MemberPageContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  isLogin: PropTypes.bool.isRequired,
  memberInfo: PropTypes.shape({}),
  memberName: PropTypes.string,
  reduceDepositList: PropTypes.array,
  myBagList: PropTypes.array,
  location: PropTypes.object,
  actions: PropTypes.shape({
    changePasswordFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    getReduceDepositListFlowRequest: PropTypes.func.isRequired,
    updateMemberInfoFlowRequest: PropTypes.func.isRequired,
    getMyBagListFlowRequest: PropTypes.func.isRequired,
    getMyRentedSoldOrderListFlowRequest: PropTypes.func.isRequired,
  }).isRequired,
  withdrawOrderList: PropTypes.array,
};

MemberPageContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  memberName: '',
  memberInfo: {},
  location: {},
  reduceDepositList: [],
  myBagList: [],
  withdrawOrderList: [],
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  isLogin: isLogInSelector(state),
  memberName: memberNameSelector(state),
  memberInfo: memberInfoSelector(state),
  myBagList: bagListSelector(state),
  reduceDepositList: reduceDepositListSelector(state),
  withdrawOrderList: orderListDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...BagActionCreators,
    ...OrderActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberPageContainer);
