import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Title = ({
  children,
}) => (
  <div className={styles.title}>
    {children}
  </div>
);


export default Title;

Title.propTypes = {
  children: PropTypes.node.isRequired,
};
