/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import configStore from './lib/configStore';
import RootRouter from './routes/RootRouter';

export const store = configStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        {/* GATag */}
        <Helmet>
          <script asyncsrc="https://www.googletagmanager.com/gtag/js?id=AW-627439533" />
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-627439533');
          `}
          </script>
        </Helmet>
        <RootRouter />
      </Provider>
    );
  }
}

export default App;
