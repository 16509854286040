import React, {
  useRef, useState, useCallback, useEffect,
} from 'react';
import classNames from 'classnames';
import Prototype from 'prop-types';

import Header from '../../Parts/Header';
import Title from '../../Parts/Title';
import Button from '../../Parts/Button';

import style from './styles.module.scss';

const PhotoCapture = ({
  title,
  fileKey,
  onClickStop,
}) => {
  const videoRef = useRef();
  const canvasRef = useRef();
  const [photoTaken, setPhotoTaken] = useState(false);
  const [isOpenCamera, setIsOpenCamera] = useState(false);
  const constraints = {
    video: {
      facingMode: 'environment',
    },
    audio: false,
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;

      setIsOpenCamera(true);
    } catch (error) {
      throw ('Error starting camera:', error);
    }
  };

  const takePhoto = useCallback(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    setPhotoTaken(true);

    const sourceRatio = video.videoWidth / video.videoHeight;
    const canvasRatio = canvas.width / canvas.height;

    let sourceWidth; let
      sourceHeight;

    if (sourceRatio > canvasRatio) {
      sourceHeight = video.videoHeight;
      sourceWidth = sourceHeight * canvasRatio;
    } else {
      sourceWidth = video.videoWidth;
      sourceHeight = sourceWidth / canvasRatio;
    }

    const sourceX = (video.videoWidth - sourceWidth) / 2;
    const sourceY = (video.videoHeight - sourceHeight) / 2;

    ctx.drawImage(video, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, canvas.width, canvas.height);

    const tracks = video.srcObject.getTracks();
    tracks.forEach(track => track.stop());
    video.srcObject = null;

    const formData = new FormData();
    canvas.toBlob(async (blob) => {
      formData.append('file', blob, 'captured-photo.jpg');

      const uploadDate = {
        [fileKey]: formData,
      };

      onClickStop(uploadDate);
    }, 'image/jpeg');
  }, []);

  const uploadPhoto = () => {
    // Reset the photoTaken state so the video is visible again
    setPhotoTaken(false);
    // Clear the canvas
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    // Restart the camera
    startCamera();
  };


  useEffect(() => {
    startCamera();
  }, []);

  return (
    <div className={style.container}>
      <Header />
      <div className={style.content}>
        <Title>{title}</Title>
        <div className={style.picContainer}>
          <canvas
            ref={canvasRef}
            className={classNames(style.picContainer, { [style.canvasHidden]: !photoTaken })}
          />
          {!photoTaken ? (
            <>
              <video className={style.picContainer} ref={videoRef} autoPlay muted playsInline />
            </>
          ) : (
            null
          )}
        </div>
        {
            isOpenCamera && !photoTaken ? (<Button type="button" onClick={takePhoto}>拍照</Button>) : null
            }
        {
            photoTaken && <Button type="button" onClick={uploadPhoto}>再拍一次</Button>
          }
      </div>
    </div>
  );
};

export default PhotoCapture;

PhotoCapture.propTypes = {
  title: Prototype.string,
  fileKey: Prototype.string.isRequired,
  onClickStop: Prototype.func.isRequired,
};

PhotoCapture.defaultProps = {
  title: '',
};
