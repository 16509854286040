import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  Icon,
  Popconfirm,
} from 'antd';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import OutfitCommentComponent from '../OutfitCommentComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    width: 1200,
    height: 700,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  mainMobileContainer: {
    width: '95vw',
    height: 'auto',
    minHeight: '600px',
    display: 'block',
  },
  mobileContainer: {
    width: '100%',
  },
  leftContainer: {
    width: '60%',
  },
  rightContainer: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  outfitImg: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  commentContainer: {
    height: '70%',
    width: '100%',
    overflowY: 'auto',
  },
  divider: {
    borderBottom: '1px solid #F1F2F2',
  },
  loginContainer: {
    width: '100%',
    height: 60,
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftCommentContainer: {
    width: '100%',
    height: 60,
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  uploadComment: {
    marginRight: 15,
    padding: '0px 10px',
    width: '60%',
    height: '100',
    border: 'none',
    outline: 'none',
  },
  sendComment: {
    backgroundColor: 'black',
    color: 'white',
    height: 30,
    width: '30%',
    outline: 'none',
    borderRadius: 20,
  },
  pleaseLogin: {
    backgroundColor: 'black',
    color: 'white',
    height: 30,
    padding: '4px 20px',
    width: 'fit-content',
    outline: 'none',
    borderRadius: 20,
  },
  deleteIcon: {
    fontSize: '30px',
  },
  deleteContainer: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
};

const OutfitDetailComponent = ({
  outfitDetail,
  isVisible,
  reduxStatus,
  onModalClose,
  onSendOutfitComment,
  onDeleteOutfit,
  isLogin,
  isMe,
}) => {
  const outfitComments = R.pathOr([], ['outfitComment'], outfitDetail);
  const [uploadComment, setUploadComment] = useState('');
  const outfitId = R.pathOr([], ['id'], outfitDetail);

  const uploaderInfo = {
    userId: R.pathOr(0, ['userId'], outfitDetail),
    user: {
      name: R.pathOr('', ['user', 'communityName'], outfitDetail),
      avatar: {
        uri: R.pathOr('', ['user', 'avatar', 'uri'], outfitDetail),
      },
    },
    comment: R.pathOr('', ['description'], outfitDetail),
    createTime: R.pathOr('', ['createTime'], outfitDetail),
  };

  const send = () => {
    if (!R.isEmpty(uploadComment)) {
      onSendOutfitComment(uploadComment);
      setUploadComment('');
    }
  };

  const renderContent = (mainStyle, contentStyle) => (
    <ReduxStatusVisualizedComponent
      reduxStatus={reduxStatus}
      containerStyle={styles.reduxStyle}
    >
      <div style={{ ...styles.mainContainer, ...mainStyle }}>
        <div style={{ ...styles.leftContainer, ...contentStyle }}>
          <img
            style={styles.outfitImg}
            alt=""
            src={R.pathOr('', ['resource', 'uri'], outfitDetail)}
          />
        </div>
        <div style={{ ...styles.rightContainer, ...contentStyle }}>
          {isMe ? (
            <div style={styles.deleteContainer}>
              <Popconfirm
                title="是否移除貼文?"
                onConfirm={() => onDeleteOutfit(outfitId)}
                okText="Yes"
                cancelText="No"
              >
                <Icon
                  style={styles.deleteIcon}
                  type="close-circle"
                />
              </Popconfirm>
            </div>
          ) : null}

          <OutfitCommentComponent
            outfitComment={uploaderInfo}
          />
          <div style={styles.divider} />
          <div style={styles.commentContainer}>
            {outfitComments.map(outfitCommit => (
              <OutfitCommentComponent
                key={outfitCommit.id}
                outfitComment={outfitCommit}
              />
            ))}
          </div>
          <div style={styles.divider} />

          {isLogin ? (
            <div style={styles.leftCommentContainer}>
              <textarea
                placeholder="我要留言..."
                style={styles.uploadComment}
                value={uploadComment}
                onChange={event => setUploadComment(event.target.value)}
              />

              <button
                type="button"
                style={styles.sendComment}
                onClick={() => send()}
              >
                送出
              </button>
            </div>
          ) : (
            <div style={styles.loginContainer}>
              <div
                style={styles.pleaseLogin}
              >
                留言前請先登入
              </div>
            </div>
          )}
        </div>
      </div>
    </ReduxStatusVisualizedComponent>
  );
  return (
    <React.Fragment>
      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={renderContent()}
          containerStyle={{ paddingTop: 50, zIndex: 2 }}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={renderContent(styles.mainMobileContainer, styles.mobileContainer)}
          containerStyle={{ paddingTop: 50, zIndex: 2 }}
        />
      </Mobile>
    </React.Fragment>
  );
};

OutfitDetailComponent.propTypes = {
  reduxStatus: PropTypes.string,
  isVisible: PropTypes.bool,
  isLogin: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  outfitDetail: PropTypes.object,
  onSendOutfitComment: PropTypes.func.isRequired,
  onDeleteOutfit: PropTypes.func.isRequired,
  isMe: PropTypes.bool.isRequired,
};

OutfitDetailComponent.defaultProps = {
  reduxStatus: null,
  isVisible: false,
  outfitDetail: {},
  isLogin: false,
};

export default OutfitDetailComponent;
