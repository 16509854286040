import React, { useState, useEffect } from 'react';
import {
  Icon,
  Popover,
  Avatar,
} from 'antd';
import { Link } from '@reach/router';
import ReactPixel from 'react-facebook-pixel';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import Paths from '../../../../routes/Paths';
import Logo from '../../../../components/Logo';
import HeaderMemberContainer from '../../../Member/components/HeaderMemberComponent';
import InfoModalComponent from '../../../../components/InfoModalComponent';
import bagOrClothesPath from '../../../../utils/bagOrClothesPath';
import LoginPageContainer from '../../../Member/components/LoginPage';
import HeaderMemberMobileContainer from './HeaderMobileComponent';
import { FontSize } from '../../../../constants/FontSize';
import { commodityTypeId } from '../../../../constants/Type';
import HeaderBarComponent from '../../../../components/HeaderBarComponent/HeaderBarComponent';
import CommodityItem from '../CommodityItem';
import Assets from '../../../../constants/Assets';
import { rentAmountCalculate } from '../../../../utils/rentAmountCalculate';

const styles = {
  logoContainer: {
    height: '100%',
    color: 'black',
    position: 'absolute',
    left: '20px',
  },
  logo: {
    height: '90px',
    padding: 15,
  },
  headerContainer: {
    display: 'flex',
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 100px',
    position: 'fixed',
    margin: '0 auto',
    marginTop: '30px',
    height: '130',
    fontSize: FontSize.body,
    zIndex: 2,
  },
  headerContainerTransparent: {
    display: 'flex',
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 100px',
    position: 'fixed',
    margin: '0 auto',
    marginTop: '30px',
    height: '130',
    fontSize: FontSize.body,
    zIndex: 2,
    color: 'white',
  },
  spacer: {
    flexGrow: 1,
  },
  headerMember: {
    margin: 10,
  },
  headerMemberMobile: {
    margin: 10,
    position: 'relative',
  },
  linkGroup: {
    margin: '10px 10px 10px 50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    margin: '0px 10px',
    fontSize: '14px',
    fontFamily: 'Microsoft JhengHei Regular',
  },
  searchIcon: {
    fontSize: '16px',
    width: 20,
    height: 40,
    textAlign: 'center',
    lineHeight: '40px',
  },
  searchInputBoxContainer: {
    display: 'inline-block',
    width: 100,
  },
  searchInputBox: {
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid grey',
    width: 100,
    backgroundColor: 'white',
  },
  searchInputBoxScrolled: {
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid black',
    width: 100,
    backgroundColor: 'white',
  },
  bottom: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: '40px',
    backgroundColor: 'yellow',
    zIndex: 9999,
  },
  mobileLogo: {
    width: '50px',
    height: '50px',
    objectFit: 'contain',
  },
  headerMobileContainer: {
    height: '50px',
    borderBottom: '1px grey solid',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'fixed',
    width: '100vw',
    marginTop: '30px',
    backgroundColor: 'white',
    zIndex: '2',
  },
  logoMobileContainer: {
    height: '100%',
    color: 'black',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  mobileButton: {
    marginLeft: '15px',
    width: '40px',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    background: 'white',
  },
  line: {
    border: '1.2px grey solid',
    backgroundColor: 'grey',
    margin: '4px 0',
  },
  lineContainerOnClick: {
    transform: 'rotate(90deg)',
  },
  searchResultListContainer: {
    width: '300px',
    height: 'fit-content',
    maxHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflowY: 'scroll',
  },
  searchResultItem: {
    width: '100%',
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  searchResultImage: {
    width: 35,
    height: 35,
    padding: 2.5,
    fontSize: 20,
    objectFit: 'cover',
  },
  searchResultName: {
    marginLeft: 10,
    color: 'black',
    fontSize: 14,
    width: 'fit-content',
    height: 'fit-content',
  },
  searchResult: {
    width: '100%',
    height: '100',
    outline: 'none',
    cursor: 'point',
    border: 'none',
    backgroundColor: 'white',
  },
  stackContainer: {
    position: 'fixed',
    display: 'flex',
    backgroundColor: 'white',
    height: 'auto',
    width: '100%',
    top: '110px',
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0px 0px 0px 0px',
  },
  commodityLeftContainer: {
    marginTop: '0px',
    width: '30%',
    height: '100%',
    paddingRight: '70px',
  },
  commodityRightContainer: {
    marginTop: '0px',
    marginBottom: '20px',
    width: '50%',
    height: 'auto',
    position: 'relative',
  },
  commodityListContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: 'auto',
    marginBottom: '40px',
  },
  commodityListAboutContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    height: 'auto',
    marginBottom: '40px',
  },
  commodityTitle: {
    width: '100%',
    fontSize: 17,
    fontFamily: 'Amiri',
  },
  commodityLink: {
    margin: '10px 0px',
    color: 'black',
    fontSize: 14,
    fontFamily: 'myriad pro semibold',
    outline: 'none',
  },
  commodityContainer: {
    width: '15vw',
    maxWidth: '240px',
    height: '100%',
    marginTop: '30px',
  },
  commodityImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  divedLine: {
    backgroundColor: '#6a3906',
    border: 0,
    height: '1px',
  },
  linkStyle: {
    color: 'black',
  },
  aboutText: {
    color: 'black',
    textAlign: 'center',
  },
  essencesImg: {
    width: '100%',
  },
  essencesTitle: {
    fontSize: '20px',
    color: 'black',
    textAlign: 'center',
    width: '90%',
    margin: 'auto',
    marginBottom: '10px',
  },
  essencesContainer: {
    width: '32%',
  },
};

const SearchBox = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.3s;
  cursor: pointer;
  color: black;
  &:hover{
    width: 120px;
  }
  &:focus-within{
    width: 120px;
  }
`;

const LinkStyle = styled.div`
  margin: 0px 10px;
  fontSize: ${FontSize.body};
  fontFamily: Baskerville-Old-Facef;
  cursor: pointer;
  color: black;
  &:hover{
    color: #8B4513;
  }
`;

const CommodityLinkStyle = styled.div`
  fontSize: 14px;
  cursor: pointer;
  color: black;
  &:hover{
    color: #8B4513;
    text-decoration: underline;
  }
`;

const HeaderComponent = ({
  userList,
  bagList,
  commodityList,
  onBagSearchByKeyword,
  onMemberSearchByKeyword,
  isShowCommodityList,
  setIsShowCommodityList,
  memberName,
  isLogin,
  headerScrolled,
  tags,
  maqueeText,
}) => {
  const [searchString, setSearchString] = useState(null);
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const buyAreaId = 99;
  const buyAreaAllNewId = 98;

  useEffect(() => {
    if (!searchString) return;
    setSearchString(null);
  }, [searchString]);

  useEffect(() => {
    const onScroll = (event) => {
      if (event.target.documentElement.scrollTop > 720) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [isScrolled]);

  const getCommodityType = (isUser, type) => {
    if (isUser) return 'user';
    if (type === commodityTypeId.bag) {
      return 'bags';
    }
    if (type === commodityTypeId.accessories) {
      return 'accessories';
    }
    return 'clothes';
  };

  const triggerSearchPixelAction = (isUser, item) => {
    const washAmount = R.pathOr(0, ['washAmount'], item);
    const rentAmountPerDay = R.pathOr(0, ['rentAmountPerDay'], item);
    ReactPixel.track('Search', {
      content_category: getCommodityType(isUser, isShowCommodityList[1]),
      content_ids: [R.pathOr(null, ['id'], item)],
      contents: [{
        id: R.pathOr(null, ['id'], item),
        quantity: '1',
        name: isUser ? R.pathOr('', ['communityName'], item) : R.pathOr('', ['name'], item),
      }],
      currency: 'TWD',
      search_string: keyword,
      value: rentAmountCalculate(rentAmountPerDay, 4, false, washAmount),
    });
  };

  const renderMemberSearchResult = user => (
    <React.Fragment
      key={`user ${user.id}`}
    >
      <button
        key={user.id}
        type="button"
        style={styles.searchResult}
        onClick={() => {
          setKeyword('');
        }}
      >
        <Link
          to={`${Paths.MEMBER}/${R.pathOr('', ['communityName'], user)}`}
          onClick={() => triggerSearchPixelAction(true, user)}
        >
          <div style={styles.searchResultItem}>
            <Avatar
              style={styles.searchResultImage}
              src={R.path(['avatar', 'uri'], user)}
              icon="user"
              size={35}
            />
            <div style={styles.searchResultName}>{R.path(['communityName'], user)}</div>
          </div>
        </Link>
      </button>
    </React.Fragment>
  );

  const renderBagSearchResult = (bag) => {
    const commodityType = R.pathOr(1, ['commodityTypeId'], bag);
    return (
      <React.Fragment
        key={`bag ${bag.id}`}
      >
        <button
          type="button"
          style={styles.searchResult}
          onClick={() => {
            setKeyword('');
          }}
        >
          <Link
            to={`${bagOrClothesPath(commodityType)}/${R.pathOr('', ['bagNo'], bag)}`}
            onClick={() => triggerSearchPixelAction(false, bag)}
          >
            <div style={styles.searchResultItem}>
              <img
                alt=""
                style={styles.searchResultImage}
                src={R.path(['frontPic', 'uri'], bag)}
              />
              <div style={styles.searchResultName}>{R.path(['name'], bag)}</div>
            </div>
          </Link>
        </button>
      </React.Fragment>
    );
  };

  const renderSearchResult = () => (
    <div style={styles.searchResultListContainer}>
      {bagList.map(bag => renderBagSearchResult(bag))}
      {userList.map(user => renderMemberSearchResult(user))}
    </div>
  );


  const renderStackBlock = () => {
    const bagOrClothesListPath = () => {
      if (isShowCommodityList[1] === commodityTypeId.clothes) return Paths.CLOTHES_LIST;

      if (isShowCommodityList[1] === commodityTypeId.accessories) return Paths.ACCESSORIES_LIST;

      if (isShowCommodityList[1] === commodityTypeId.essences) return Paths.ESSENCES_LIST;

      if (isShowCommodityList[1] === buyAreaId) return Paths.BUY_AREA;

      if (isShowCommodityList[1] === buyAreaAllNewId) return Paths.BUY_AREA_ALL_NEW;

      return Paths.BAG_LIST;
    };

    const stackContent = (type) => {
      if (type === commodityTypeId.bag) {
        return (
          <React.Fragment>
            {
              tags.map(({
                id: tagCategoryId,
                tags: tagItems,
              }) => {
                if (tagCategoryId !== 2) return null;
                return tagItems.map(({
                  name: tagName,
                  id: tagId,
                }) => (
                  <Link to={`${bagOrClothesListPath()}?search={"bagType":[1,3] , "tags":[[${tagId}]]}&page=1`} key={tagId}>
                    <div
                      style={styles.commodityLink}
                      role="button"
                      onClick={() => { setIsShowCommodityList([false, '']); }}
                      tabIndex={0}
                      onKeyPress={() => { setIsShowCommodityList([false, '']); }}
                    >
                      <CommodityLinkStyle>
                        {tagName}
                      </CommodityLinkStyle>
                    </div>
                  </Link>
                ));
              })
            }
            <div style={styles.commodityLink}>
              {/* Note: tag should be '包包', in the product admin  */}
              <Link to={`${bagOrClothesListPath()}?search={"bagType":[1,3]}&page=1`}>
                <CommodityLinkStyle>
                  瀏覽全部
                </CommodityLinkStyle>
              </Link>
            </div>
          </React.Fragment>
        );
      }

      if (type === commodityTypeId.accessories) {
        return (
          <React.Fragment>
            {
              tags.map(({
                id: tagCategoryId,
                tags: tagItems,
              }) => {
                if (tagCategoryId !== 6) return null;
                return tagItems.map(({
                  name: tagName,
                  id: tagId,
                }) => (
                  <Link to={`${bagOrClothesListPath()}?search={"bagType":[1,3] , "tags":[[${tagId}]]}&page=1`} key={tagId}>
                    <div
                      style={styles.commodityLink}
                      role="button"
                      onClick={() => { setIsShowCommodityList([false, '']); }}
                      tabIndex={0}
                      onKeyPress={() => { setIsShowCommodityList([false, '']); }}
                    >
                      <CommodityLinkStyle>
                        {tagName}
                      </CommodityLinkStyle>
                    </div>
                  </Link>
                ));
              })
            }
            <div style={styles.commodityLink}>
              <Link to={`${bagOrClothesListPath()}?search={"bagType":[1,3]}&page=1`}>
                <CommodityLinkStyle>
                  瀏覽全部
                </CommodityLinkStyle>
              </Link>
            </div>
          </React.Fragment>
        );
      }

      if (type === commodityTypeId.clothes) {
        return (
          <React.Fragment>
            {
              tags.map(({
                id: tagCategoryId,
                tags: tagItems,
              }) => {
                if (tagCategoryId !== 5) return null;
                return tagItems.map(({
                  name: tagName,
                  id: tagId,
                }) => (
                  <Link to={`${bagOrClothesListPath()}?search={"tags":[[${tagId}]] , "bagType":[1,3] }&page=1`} key={tagId}>
                    <div
                      style={styles.commodityLink}
                      role="button"
                      onClick={() => { setIsShowCommodityList([false, '']); }}
                      tabIndex={0}
                      onKeyPress={() => { setIsShowCommodityList([false, '']); }}
                    >
                      <CommodityLinkStyle>
                        {tagName}
                      </CommodityLinkStyle>
                    </div>
                  </Link>
                ));
              })
            }
            <div
              style={styles.commodityLink}
              role="button"
              onClick={() => { setIsShowCommodityList([false, '']); }}
              tabIndex={0}
              onKeyPress={() => { setIsShowCommodityList([false, '']); }}
            >
              <Link to={`${bagOrClothesListPath()}?search={"bagType":[1,3]}&page=1`}>
                <CommodityLinkStyle>
                  瀏覽全部
                </CommodityLinkStyle>
              </Link>
            </div>
          </React.Fragment>
        );
      }

      if (type === commodityTypeId.essences) {
        return (
          <React.Fragment>
            {
              tags.map(({
                id: tagCategoryId,
                tags: tagItems,
              }) => {
                if (tagCategoryId !== 9) return null;
                return tagItems.map(({
                  name: tagName,
                  id: tagId,
                }) => (
                  <Link to={`${bagOrClothesListPath()}?search={"tags":[[${tagId}]] , "bagType":[2] }&page=1`} key={tagId}>
                    <div
                      style={styles.commodityLink}
                      role="button"
                      onClick={() => { setIsShowCommodityList([false, '']); }}
                      tabIndex={0}
                      onKeyPress={() => { setIsShowCommodityList([false, '']); }}
                    >
                      <CommodityLinkStyle>
                        {tagName}
                      </CommodityLinkStyle>
                    </div>
                  </Link>
                ));
              })
            }
            <div
              style={styles.commodityLink}
              role="button"
              onClick={() => { setIsShowCommodityList([false, '']); }}
              tabIndex={0}
              onKeyPress={() => { setIsShowCommodityList([false, '']); }}
            >
              <Link to={`${bagOrClothesListPath()}?search={"bagType":[2]}&page=1`}>
                <CommodityLinkStyle>
                  瀏覽全部
                </CommodityLinkStyle>
              </Link>
            </div>
          </React.Fragment>
        );
      }

      const isAllNewPath = type === buyAreaAllNewId ? Paths.BUY_AREA_ALL_NEW : Paths.BUY_AREA;
      const isAllNew = type === buyAreaAllNewId ? (',"allNew":true') : '';
      const buyAreaParameter = type === buyAreaAllNewId ? '"allCommodity":true,"bagType":[2]' : '"allCommodity":true,"bagType":[2,3]';

      return (
        <React.Fragment>
          <Link
            to={`${isAllNewPath}?search={"buyCommodityType":${commodityTypeId.bag},${buyAreaParameter}${isAllNew}}&page=1`}
          >
            <div
              style={styles.commodityLink}
              role="button"
              onClick={() => { setIsShowCommodityList([false, '']); }}
              tabIndex={0}
              onKeyPress={() => { setIsShowCommodityList([false, '']); }}
            >
              <CommodityLinkStyle>
                手袋
              </CommodityLinkStyle>
            </div>
          </Link>
          <Link
            to={
              `${isAllNewPath}?search={"buyCommodityType":${commodityTypeId.clothes},${buyAreaParameter}${isAllNew}}&page=1`
            }
          >
            <div
              style={styles.commodityLink}
              role="button"
              onClick={() => { setIsShowCommodityList([false, '']); }}
              tabIndex={0}
              onKeyPress={() => { setIsShowCommodityList([false, '']); }}
            >
              <CommodityLinkStyle>
                服裝
              </CommodityLinkStyle>
            </div>
          </Link>
          <Link to={`${isAllNewPath}?search={"buyCommodityType":${commodityTypeId.accessories},${buyAreaParameter}${isAllNew}}&page=1`}>
            <div
              style={styles.commodityLink}
              role="button"
              onClick={() => { setIsShowCommodityList([false, '']); }}
              tabIndex={0}
              onKeyPress={() => { setIsShowCommodityList([false, '']); }}
            >
              <CommodityLinkStyle>
                飾品
              </CommodityLinkStyle>
            </div>
          </Link>
          <Link to={`${isAllNewPath}?search={${buyAreaParameter},"tags":[[362]]${isAllNew}}&page=1`}>
            <div
              style={styles.commodityLink}
              role="button"
              onClick={() => { setIsShowCommodityList([false, '']); }}
              tabIndex={0}
              onKeyPress={() => { setIsShowCommodityList([false, '']); }}
            >
              <CommodityLinkStyle>
                鞋履
              </CommodityLinkStyle>
            </div>
          </Link>
          <div
            style={styles.commodityLink}
            role="button"
            onClick={() => { setIsShowCommodityList([false, '']); }}
            tabIndex={0}
            onKeyPress={() => { setIsShowCommodityList([false, '']); }}
          >
            <Link to={`${isAllNewPath}?search={${buyAreaParameter}${isAllNew}}&page=1`}>
              <CommodityLinkStyle>
                瀏覽全部
              </CommodityLinkStyle>
            </Link>
          </div>
        </React.Fragment>
      );
    };

    const renderTypeText = (type) => {
      if (type === commodityTypeId.bag) {
        return '租借手袋';
      }
      if (type === commodityTypeId.accessories) {
        return '租借配飾';
      }
      if (type === commodityTypeId.clothes) {
        return '租借服裝';
      }
      if (type === commodityTypeId.essences) {
        return '精選品牌';
      }
      if (type === buyAreaAllNewId) {
        return '全新商品';
      }
      return '探索二手';
    };

    if (isShowCommodityList[1] === commodityTypeId.bag
      || isShowCommodityList[1] === commodityTypeId.clothes
      || isShowCommodityList[1] === commodityTypeId.accessories
      || isShowCommodityList[1] === buyAreaId
      || isShowCommodityList[1] === buyAreaAllNewId) {
      return (
        <React.Fragment>
          <div style={styles.commodityLeftContainer}>
            <h1 style={styles.commodityTitle}>
              {renderTypeText(isShowCommodityList[1])}
            </h1>
            <hr style={styles.divedLine} />
            {stackContent(isShowCommodityList[1])}
          </div>
          <div style={styles.commodityRightContainer}>
            <h1 style={styles.commodityTitle}>
              NEW IN
            </h1>
            <hr style={styles.divedLine} />
            <div style={styles.commodityListContainer}>
              {(commodityList || []).map(item => (
                <CommodityItem
                  isBuyArea={isShowCommodityList[1] === buyAreaId || isShowCommodityList[1] === buyAreaAllNewId}
                  {...item}
                />
              ))}
            </div>
          </div>
        </React.Fragment>
      );
    }

    if (isShowCommodityList[1] === commodityTypeId.essences) {
      return (
        <React.Fragment>
          <div style={styles.commodityLeftContainer}>
            <h1 style={styles.commodityTitle}>
              {renderTypeText(isShowCommodityList[1])}
            </h1>
            <hr style={styles.divedLine} />
            {stackContent(isShowCommodityList[1])}
          </div>
          <div style={styles.commodityRightContainer}>
            <h1 style={styles.commodityTitle}>
              皮革護理專區
            </h1>
            <hr style={styles.divedLine} />
            <div style={styles.commodityListContainer}>
              <div style={styles.essencesContainer}>
                {/* <div style={styles.essencesTitle}>居家香氛</div> */}
                <Link to={`${bagOrClothesListPath()}?search={ "tags":[[396]]}&page=1`}>
                  <img
                    alt=""
                    src={Assets.essencesHeader01}
                    style={styles.essencesImg}
                  />
                </Link>
              </div>
              <div style={styles.essencesContainer}>
                {/* <div style={styles.essencesTitle}>沐浴系列</div> */}
                <Link to={`${bagOrClothesListPath()}?search={ "tags":[[397]]}&page=1`}>
                  <img
                    alt=""
                    src={Assets.essencesHeader02}
                    style={styles.essencesImg}
                  />
                </Link>
              </div>
              <div style={styles.essencesContainer}>
                {/* <div style={styles.essencesTitle}>髮絲保養</div> */}
                <Link to={`${bagOrClothesListPath()}?search={ "tags":[[398]]}&page=1`}>
                  <img
                    alt=""
                    src={Assets.essencesHeader03}
                    style={styles.essencesImg}
                  />
                </Link>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div style={styles.commodityLeftContainer}>
          <h1 style={styles.commodityTitle}>
            關於我們
          </h1>
          <hr style={styles.divedLine} />
          <div style={styles.commodityLink}>
            <Link to={Paths.ABOUT}>
              <CommodityLinkStyle>
                關於OP
              </CommodityLinkStyle>
            </Link>
          </div>
          <div style={styles.commodityLink}>
            <Link to={Paths.HOW_IT_WORKS}>
              <CommodityLinkStyle>
                如何運作
              </CommodityLinkStyle>
            </Link>
          </div>
          <div style={styles.commodityLink}>
            <Link to={Paths.FAQ}>
              <CommodityLinkStyle>
                常見問與答
              </CommodityLinkStyle>
            </Link>
          </div>
          <div style={styles.commodityLink}>
            <CommodityLinkStyle>
              <a
                href="https://drive.google.com/file/d/1a5qAedAj4Es4lhYiG2EyNZo3Op-PdNKD/view?usp=sharing"
                target="_blank: "
                style={styles.linkStyle}
              >
                上傳簡易教學操作
              </a>
            </CommodityLinkStyle>
          </div>
        </div>
        <div style={styles.commodityRightContainer}>
          <h1 style={styles.commodityTitle}>
            ABOUT OP
          </h1>
          <hr style={styles.divedLine} />
          <div style={styles.commodityListAboutContainer}>
            <Link to={Paths.ABOUT}>
              <div style={styles.commodityContainer}>
                <img
                  alt=""
                  src={Assets.headerAbout01}
                  style={styles.commodityImg}
                />
                <div style={styles.aboutText}>
                  關於我們
                </div>
              </div>
            </Link>
            <Link to={Paths.HOW_IT_WORKS}>
              <div style={styles.commodityContainer}>
                <img
                  alt=""
                  src={Assets.headerAbout02}
                  style={styles.commodityImg}
                />
                <div style={styles.aboutText}>
                  如何運作
                </div>
              </div>
            </Link>
            <Link to={Paths.FAQ}>
              <div style={styles.commodityContainer}>
                <img
                  alt=""
                  src={Assets.headerAbout03}
                  style={styles.commodityImg}
                />
                <div style={styles.aboutText}>
                  常見問與答
                </div>
              </div>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  };
  return [
    <HeaderBarComponent
      key="headerMessage"
      maqueeText={maqueeText}
    />,
    <React.Fragment key="headerContainer">
      <Default>
        <div style={isShowCommodityList[0] || isScrolled || !headerScrolled ? styles.headerContainer : styles.headerContainerTransparent}>
          <Link to={Paths.HOME} style={styles.logoContainer}>
            <Logo
              style={styles.logo}
              isScrolled={isScrolled}
              isShowCommodityList={isShowCommodityList}
              headerScrolled={headerScrolled}
            />
          </Link>
          <div style={styles.linkGroup}>
            <div
              style={styles.link}
              onMouseOver={() => { setIsShowCommodityList([true, buyAreaAllNewId]); }}
              onFocus={() => { setIsShowCommodityList([true, buyAreaAllNewId]); }}
            >
              <LinkStyle>
                全新商品
              </LinkStyle>
            </div>
            <div
              style={styles.link}
              onMouseOver={() => { setIsShowCommodityList([true, buyAreaId]); }}
              onFocus={() => { setIsShowCommodityList([true, buyAreaId]); }}
            >
              <LinkStyle>
                探索二手
              </LinkStyle>
            </div>
            <div
              style={styles.link}
              onMouseOver={() => { setIsShowCommodityList([true, commodityTypeId.bag]); }}
              onFocus={() => { setIsShowCommodityList([true, commodityTypeId.bag]); }}
            >
              <LinkStyle>
                租包包
              </LinkStyle>
            </div>
            <div
              style={styles.link}
              onMouseOver={() => { setIsShowCommodityList([true, commodityTypeId.clothes]); }}
              onFocus={() => { setIsShowCommodityList([true, commodityTypeId.clothes]); }}
            >
              <LinkStyle>
                租服裝
              </LinkStyle>
            </div>
            <div
              style={styles.link}
              onMouseOver={() => { setIsShowCommodityList([true, commodityTypeId.accessories]); }}
              onFocus={() => { setIsShowCommodityList([true, commodityTypeId.accessories]); }}
            >
              <LinkStyle>
                租配件
              </LinkStyle>
            </div>
            <div
              style={styles.link}
              onMouseOver={() => { setIsShowCommodityList([true, commodityTypeId.essences]); }}
              onFocus={() => { setIsShowCommodityList([true, commodityTypeId.essences]); }}
            >
              <LinkStyle>
                清潔保養
              </LinkStyle>
            </div>
            <Link
              to={Paths.HOW_IT_WORKS}
            >
              <div
                style={styles.link}
              >
                <LinkStyle>
                  如何運作
                </LinkStyle>
              </div>
            </Link>
            <div
              style={styles.link}
              onMouseOver={() => { setIsShowCommodityList([true, 'about']); }}
              onFocus={() => { setIsShowCommodityList([true, 'about']); }}
            >
              <LinkStyle>
                關於我們
              </LinkStyle>
            </div>
            <Popover
              arrowPointAtCenter
              visible={(keyword.length > 0) && ((bagList.length > 0) || (userList.length > 0))}
              content={renderSearchResult()}
              overlayClassName=".ant-popover-title"
              trigger="click"
              placement="bottom"
              onVisibleChange={(e) => {
                if (!e) {
                  setKeyword('');
                }
              }}
            >
              <SearchBox>
                <Icon type="search" style={styles.searchIcon} />
                <form style={styles.searchInputBoxContainer}>
                  <input
                    onChange={(event) => {
                      if (event.target.value.length > 0) {
                        setKeyword(event.target.value);
                        onBagSearchByKeyword({ keyword: event.target.value });
                        onMemberSearchByKeyword({ keyword: event.target.value });
                      } else {
                        setKeyword('');
                      }
                    }}
                    style={isShowCommodityList[0] || isScrolled || !headerScrolled ? styles.searchInputBox : styles.searchInputBoxScrolled}
                    value={keyword}
                  />
                  <button type="submit" hidden />
                </form>
              </SearchBox>
            </Popover>
          </div>
          <div style={styles.spacer} />
          <HeaderMemberContainer
            style={styles.headerMember}
            isScrolled={isScrolled}
            isShowCommodityList={isShowCommodityList}
            headerScrolled={headerScrolled}
          />

          <InfoModalComponent
            containerStyle={{ zIndex: 1 }}
            isVisible={isShowLoginModal}
            onModalClose={() => setIsShowLoginModal(false)}
            content={(
              <LoginPageContainer
                setIsShowLoginModal={setIsShowLoginModal}
              />
          )}
          />
        </div>
        <div
          style={styles.stackContainer}
          onMouseLeave={() => { setIsShowCommodityList([false, '']); }}
        >
          {isShowCommodityList[0] ? (
            renderStackBlock()
          ) : null}
        </div>
      </Default>
      <Mobile>
        <div style={styles.headerMobileContainer}>
          <button
            type="button"
            onClick={() => { setIsShowMobileMenu(!isShowMobileMenu); }}
            style={styles.mobileButton}
          >
            <div style={isShowMobileMenu ? styles.lineContainerOnClick : null}>
              <div style={styles.line} />
              <div style={styles.line} />
              <div style={styles.line} />
            </div>

          </button>
          <Link
            to={Paths.HOME}
            style={styles.logoMobileContainer}
            onClick={() => { setIsShowMobileMenu(false); }}
          >
            <Logo style={styles.mobileLogo} />
          </Link>
          <HeaderMemberContainer
            style={styles.headerMember}
            setIsShowMobileMenu={setIsShowMobileMenu}
          />
          { isShowMobileMenu ? (
            <HeaderMemberMobileContainer
              isLogin={isLogin}
              memberName={memberName}
              setIsShowMobileMenu={value => setIsShowMobileMenu(value)}
              userList={userList}
              bagList={bagList}
              onBagSearchByKeyword={onBagSearchByKeyword}
              onMemberSearchByKeyword={onMemberSearchByKeyword}
              tags={tags}
              setIsShowCommodityList={setIsShowCommodityList}
            />
          ) : null }
        </div>
      </Mobile>
    </React.Fragment>,
  ];
};


HeaderComponent.propTypes = {
  userList: PropTypes.array,
  bagList: PropTypes.array,
  onMemberSearchByKeyword: PropTypes.func.isRequired,
  onBagSearchByKeyword: PropTypes.func.isRequired,
  isShowCommodityList: PropTypes.array,
};

HeaderComponent.defaultProps = {
  userList: [],
  bagList: [],
  isShowCommodityList: [],
};

export default HeaderComponent;
