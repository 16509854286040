import React from 'react';
import PropTypes from 'prop-types';
import { FontSize } from '../../constants/FontSize';
import Default from '../RWDComponent/Default';
import Mobile from '../RWDComponent/Mobile';

const styles = {
  mainContainer: {
    backgroundColor: 'black',
    color: 'white',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'center',
    fontSize: FontSize.overline,
    width: '100%',
    position: 'fixed',
    zIndex: 3,
  },
  mainMobileContainer: {
    backgroundColor: 'black',
    color: 'white',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'center',
    fontSize: FontSize.overline,
    width: '100%',
    position: 'fixed',
    zIndex: 3,
  },
};

const HeaderBarComponent = ({
  maqueeText,
}) => {
  const content = (
    <div>
      {maqueeText}
    </div>
  );
  return (
    <React.Fragment>
      <Default>
        <div
          style={styles.mainContainer}
          data-testid="main-container"
        >
          {content}
        </div>
      </Default>
      <Mobile>
        <div
          style={styles.mainMobileContainer}
          data-testid="main-mobile-container"
        >
          {content}
        </div>
      </Mobile>
    </React.Fragment>
  );
};

HeaderBarComponent.propTypes = {
  maqueeText: PropTypes.string.isRequired,
};

HeaderBarComponent.defaultProps = {
};

export default HeaderBarComponent;
