import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ActionCreators from '../../ActionCreator';
import TransactionActionCreators from '../../../Transaction/ActionCreator';
import OrderListPage from './OrderListPage';
import ReduxStatus from '../../../../constants/ReduxStatus';


import {
  orderListDataSelector,
  orderListErrorSelector,
  orderListReduxStatusSelector,
  orderCreateReduxStatusSelector,
  orderCreateErrorSelector,
  orderUpdateReduxStatusSelector,
} from '../../selector';


import {
  transactionReduxStatusSelector,
  transactionErrorSelector,
} from '../../../Transaction/selector';

import { memberIdSelector } from '../../../Member/selector';


const OrderListContainer = ({
  actions,
  orderList,
  userId,
  orderListError,
  orderListReduxStatus,
  actions: {
    orderCreateFlowRequest,
    resetTransactionStatus,
    resetOrderCreateStatus,
  },
  orderCreateError,
  orderCreateReduxStatus,
  transactionReduxStatus,
  orderUpdateReduxStatus,
}) => {
  const [transactionConfirmModalContent, setTransactionConfirmModalContent] = useState('');
  const [transactionModalId, setTransactionModalId] = useState(0);
  const [isShowTransactionConfirmModal, setIsShowTransactionConfirmModal] = useState(false);
  const [isEndTransaction, setIsEndTransaction] = useState(false);
  const [isShowEndConfirmModal, setIsShowEndConfirmModal] = useState(false);

  useEffect(() => {
    actions.orderListFlowRequest({ userId });
  }, []);

  useEffect(() => {
    if (transactionReduxStatus === ReduxStatus.STATUS_NORMAL) {
      setTransactionModalId(0);

      if (isEndTransaction) {
        setIsShowEndConfirmModal(true);
      } else {
        setTransactionConfirmModalContent('押金預刷成功，請至我的訂單中進行租金付款！');
        setIsShowTransactionConfirmModal(true);
      }
      actions.orderListFlowRequest({ userId });
    }
  }, [transactionReduxStatus]);

  useEffect(() => {
    if (orderCreateReduxStatus === ReduxStatus.STATUS_NORMAL) {
      resetOrderCreateStatus();
      actions.orderListFlowRequest({ userId });
    }
  }, [orderCreateReduxStatus]);

  const handleOnModalConfirm = () => {
    if (transactionReduxStatus === ReduxStatus.STATUS_NORMAL) {
      resetTransactionStatus();
      setIsShowTransactionConfirmModal(false);
      setIsShowEndConfirmModal(false);
      resetTransactionStatus();
    }
  };

  const handleOnSubmitCreateBuyOrder = async (order, isRentToBuy) => {
    const orderData = { ...order, isRentToBuy };
    const buyOrder = update({}, { $set: orderData });
    orderCreateFlowRequest(buyOrder);
  };

  return (
    <OrderListPage
      orderListError={orderListError}
      orderListReduxStatus={orderListReduxStatus}
      orderList={orderList}
      onSubmitCreateBuyOrder={handleOnSubmitCreateBuyOrder}
      orderCreateError={orderCreateError}
      orderCreateReduxStatus={orderCreateReduxStatus}
      setTransactionModalId={setTransactionModalId}
      transactionModalId={transactionModalId}
      isShowTransactionConfirmModal={isShowTransactionConfirmModal}
      onModalConfirmed={handleOnModalConfirm}
      transactionConfirmModalContent={transactionConfirmModalContent}
      setIsShowEndConfirmModal={setIsShowEndConfirmModal}
      setIsShowTransactionConfirmModal={setIsShowTransactionConfirmModal}
      isShowEndConfirmModal={isShowEndConfirmModal}
      setIsEndTransaction={setIsEndTransaction}
      orderUpdateReduxStatus={orderUpdateReduxStatus}
    />
  );
};

OrderListContainer.propTypes = {
  actions: PropTypes.shape({
    orderListFlowRequest: PropTypes.func.isRequired,
    orderCreateFlowRequest: PropTypes.func.isRequired,
    resetTransactionStatus: PropTypes.func.isRequired,
    resetOrderCreateStatus: PropTypes.func.isRequired,
  }).isRequired,
  orderList: PropTypes.array,
  userId: PropTypes.number,
  orderListReduxStatus: PropTypes.string,
  orderListError: PropTypes.string,
  orderCreateReduxStatus: PropTypes.string,
  orderCreateError: PropTypes.object,
  transactionReduxStatus: PropTypes.string,
  orderUpdateReduxStatus: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...TransactionActionCreators,
  }, dispatch),

});

OrderListContainer.defaultProps = {
  orderList: [],
  userId: null,
  orderListReduxStatus: null,
  orderListError: '',
  orderCreateReduxStatus: null,
  orderCreateError: null,
  transactionReduxStatus: null,
  orderUpdateReduxStatus: '',
};

const mapStateToProps = state => ({
  orderList: orderListDataSelector(state),
  userId: memberIdSelector(state),
  orderListReduxStatus: orderListReduxStatusSelector(state),
  orderListError: orderListErrorSelector(state),
  orderCreateReduxStatus: orderCreateReduxStatusSelector(state),
  orderCreateError: orderCreateErrorSelector(state),
  transactionReduxStatus: transactionReduxStatusSelector(state),
  transactionError: transactionErrorSelector(state),
  orderUpdateReduxStatus: orderUpdateReduxStatusSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderListContainer);
