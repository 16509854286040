export default {
  HOME: '/',
  MEMBER: '/member',
  EDIT: '/edit', // for edit member page use
  LOGOUT: '/logout',
  BAG_LIST: '/bagList',
  MY_BAG_LIST: '/myBagList',
  SUBMIT_BAG: '/submitBag',
  BAG_DETAIL: '/bag',
  FAQ: '/faq',
  TRANSACTION: '/transaction',
  ABOUT: '/about',
  MEMBER_TERMS: '/memberTerms',
  PRIVACY: '/privacy',
  ORDER_CREATE: '/orderCreate',
  CONTACT: '/contact',
  ORDER_LIST: '/orderList',
  ORDER_DETAIL: '/order',
  MY_RENTED_SOLD_HISTORY: '/myRentedSoldHistory',
  CART: '/cart',
  THEME: '/theme',
  PARTNER: '/partner',
  ONLINE_PRODUCTS: '/onlineProducts',
  SELL: '/sell',
  LEND: '/lend',
  LEND_SELL: '/lendSell',
  LEND_RECORD: '/lendRecord', // 租給別人包
  RENT_RECORD: '/rentRecord', // 租包
  BUY_RECORD: '/buyRecord',
  SELL_RECORD: '/sellRecord',
  HOW_IT_WORKS: '/howItWorks',
  HOW_IT_WORKS_PROVIDE: '/howItWorksProvide',
  CLOTHES_DETAIL: '/clothes',
  CLOTHES_LIST: '/clothesList',
  EDIT_COMMODITY: '/editCommodity',
  SHIPPING_INFO: '/shippingInfo',
  BUY_AREA: '/buy_area',
  BUY_AREA_ALL_NEW: '/buy_area_all_new',
  ACCESSORIES_LIST: '/accessoriesList',
  ACCESSORIES_DETAIL: '/accessories',
  ESSENCES_DETAIL: '/essences',
  ESSENCES_LIST: '/essencesList',
};
