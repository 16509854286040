import React from 'react';
import PropTypes from 'prop-types';
import Triangle from './triangle';

import Header from '../../Parts/Header';
import CircleNumber from '../../Parts/CircleNumber';

import style from './styles.module.scss';

const LandingPage = ({
  onClickAgree,
}) => (
  <div className={style.container}>
    <Header />
    <div className={style.content}>
      <div>
        <div className={style.title}>
          準備開始您的驗證
          <div className={style.triangle}>
            <Triangle />
          </div>
        </div>
        <div className={style.stepsContainer}>
          <div className={style.steps}>
            <CircleNumber>
              1
            </CircleNumber>
            勾選同意使用條款與條件
          </div>
          <div className={style.steps}>
            <CircleNumber>
              2
            </CircleNumber>
            請確認目前環境為光線充足之處
          </div>
          <div className={style.steps}>
            <CircleNumber>
              3
            </CircleNumber>
            請準備本人 身分證
          </div>
        </div>
      </div>

      <div className={style.termsContent}>
        <div>
          <input
            type="checkbox"
            onChange={onClickAgree}
          />
        </div>
        <div className={style.terms}>
          點擊【繼續】表示您同意Ohphire使用條款並接受我們的隱私政策。
          您還確定您指示驗證系統與Ohphire共享您提供的訊息。
          您的個人信息也將受到Ohphire的隱私權政策的約束。
        </div>
      </div>
    </div>
  </div>
);

export default LandingPage;

LandingPage.propTypes = {
  onClickAgree: PropTypes.func.isRequired,
};
