import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '../constants/Color';


const styles = {
  baseButton: {
    width: '200px',
    height: '60px',
    padding: '0px 10px',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    color: 'white',
    backgroundColor: Colors.introRightTitle,
  },
};

const Button = ({
  text,
  onClick,
  style,
  disabled,
}) => (
  <button
    type="button"
    style={{
      ...styles.baseButton,
      ...style,
    }}
    disabled={disabled}
    onClick={onClick}
  >
    {text}
  </button>
);

Button.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  onClick: null,
  style: null,
  disabled: false,
};

export default Button;
