import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_SPECIAL_SELECT_LIST'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_SPECIAL_SELECT_LIST_FLOW'),

  // ----- STATUS -----
  'RESET_STATUS',

  { prefix: 'SpecialSelect' },
);
