import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Icon } from 'antd';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';
import SubmitMonoBagComponent from './SubmitMonoBagComponent';
import DraftBagComponent from './DraftBagComponent';
import {
  UploadBagComponent,
  EmptyUploadBagComponent,
} from './UploadBagComponent';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import NotifyDraftBagsSavedComponent from './NotifyDraftBagsSavedComponent';
import CompletePublishComponent from './CompletePublishComponent';
import ReduxStatus from '../../../../constants/ReduxStatus';
import { typeId } from '../../../../constants/Type';
import ModalComponent from '../../../../components/ModalComponent';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import { FontSize } from '../../../../constants/FontSize';

const styles = {
  mainContainer: {
    margin: 20,
    marginTop: '100px',
  },
  tableStyle: {
    margin: '0 auto',
  },
  image: {
    width: 120,
    height: 120,
    objectFit: 'cover',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  uploader: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 5,
  },
  plusContainer: {
    margin: 10,
    width: '100%',
    display: 'flex',
    flexDirection: '',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'transparent',
    height: 'fit-content',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  plusIcon: {
    fontSize: 30,
    color: '#AE4200',
  },
  plusItemHint: {
    marginLeft: 10,
    width: 'fit-content',
    color: '#A7A7A7',
    fontSize: 18,
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: '#DFDFDF',
  },
  uploadHintContainer: {
    margin: '50px 0px',
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadHintMobileContainer: {
    margin: '50px 0px',
    height: 'auto',
    alignItems: 'center',
  },
  uploadHint: {
    width: '60%',
  },
  uploadHintMobile: {
    width: '100%',
  },
  uploadFunctionContainer: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadFunctionMobileContainer: {
    width: '100%',
    marginTop: '20px',
  },
  saveDraft: {
    backgroundColor: 'transparent',
    width: 162,
    height: 54,
    fontFamily: 'Microsoft JhengHei Regular',
    border: '1px solid #AE4200',
    color: '#6F6E6E',
    textAlign: 'center',
    padding: '8px 0px',
    fontSize: 14,
    cursor: 'pointer',
    outline: 'none',
  },
  upload: {
    width: 162,
    height: 54,
    fontFamily: 'Microsoft JhengHei Regular',
    backgroundColor: '#AE4200',
    color: 'white',
    textAlign: 'center',
    padding: '8px 0px',
    fontSize: 14,
    cursor: 'pointer',
    outline: 'none',
  },
  canNotUpload: {
    width: 162,
    height: 54,
    fontFamily: 'Microsoft JhengHei Regular',
    backgroundColor: 'white',
    border: '1px solid #AE4200',
    color: '#AE4200',
    textAlign: 'center',
    padding: '8px 0px',
    fontSize: 14,
    cursor: 'not-allowed',
    outline: 'none',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  tabSelectContainer: {
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    marginBottom: -1,
    borderBottom: '1px solid #AE4200',
    backgroundColor: 'transparent',
  },
  tabDivider: {
    borderBottom: '1px solid #ABABAB',
  },
  tabUnSelectContainer: {
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    marginBottom: -1,
    backgroundColor: 'transparent',
  },
  tabSelect: {

    color: '#AE4200',
    fontSize: 24,
  },
  tabUnSelect: {
    color: '#BABABA',
    fontSize: 24,
  },
  tabHint: {
    marginLeft: 10,
    fontSize: 20,
  },
  tableContainer: {
    marginTop: 200,
    width: '100%',
  },
  draftHeader: {
    height: 50,
    padding: '10px 0px',
    borderTop: '2px solid #939598',
    borderBottom: '1px solid #D1D3D4',
  },
  draftBagImgTr: {
    color: '#58595B',
    width: '25%',
  },
  draftBagNameTr: {
    color: '#58595B',
    width: '25%',
  },
  draftBagTypeTr: {
    color: '#58595B',
    width: '25%',
  },
  draftBagFunctionTr: {
    color: '#58595B',
    width: '25%',
  },
  mobileTabStyle: {
    display: 'block',
    padding: '10px 20px',
    width: '50%',
  },
  mobileTabTitleStyle: {
    fontSize: FontSize.subTitle,
  },
  mobileTabSubTitleStyle: {
    fontSize: FontSize.body,
  },
  uploadBagMobileStyle: {
    display: 'block',
    padding: '20px',
  },
  uploadOptionItemMobileContainer: {
    width: '100%',
    margin: '5px 0px',
  },
  uploadItemMobileContainer: {
    display: 'block',
  },
  uploadItemTitleMobileContainer: {
    width: '100%',
  },
};

const SubmitBagPage = ({
  draftOfBags,
  tags,
  reduxStatus,
  reduxError,
  submitBag,
  onWriteDraft,
  resetStatus,
  onMemberValueChanged,
  recipientName,
  recipientPhone,
  recipientAddress,
  updateMemberInfo,
  setNewPageVersion,
}) => {
  const [selectDraftBag, setSelectDraftBag] = useState(null);
  const [selectTab, setSelectTab] = useState('uploadMultiBag');
  const [isShowSavedDraft, setIsShowSavedDraft] = useState(false);
  const [isShowCompletePublishComponent, setIsShowCompletePublishComponent] = useState(false);
  const [isShowMonoUploadBagComponent, setIsShowMonoUploadBagComponent] = useState(false);
  const [uploadBags, setUploadBags] = useState([
    { ...EmptyUploadBagComponent, uuid: uuidv4() },
  ]);
  const [draftBags, setDraftBags] = useState(draftOfBags);
  const [isShowBagUploadError, setIsShowBagUploadError] = useState(false);
  const [isUpdateMemberInfo, setIsUpdateMemberInfo] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onRemoveDraftBag = (uuid) => {
    const newDraftBag = R.filter(draftOfBag => draftOfBag.uuid !== uuid, draftBags);
    setDraftBags(newDraftBag);
    onWriteDraft(newDraftBag, true);
  };

  const onRemoveUploadBag = (id) => {
    if (uploadBags.length <= 1) return;
    const newUploadItems = R.filter(uploadItem => uploadItem.uuid !== id, uploadBags);
    setUploadBags(newUploadItems);
  };

  const onSaveDraft = () => {
    setIsShowSavedDraft(true);
    onWriteDraft(uploadBags, false);
    setDraftBags(update(draftBags, { $push: [...uploadBags] }));
    setUploadBags([{ ...EmptyUploadBagComponent, uuid: uuidv4() }]);
  };

  const onModifiedData = (uuid, modifiedDataList) => {
    const updateBags = update([], { $push: [...uploadBags] });
    updateBags.forEach(
      (updateBag, index) => {
        if (updateBag.uuid === uuid) {
          modifiedDataList.forEach(({ key, value }) => {
            updateBags[index][key] = value;
          });
        }
      },
    );
    setUploadBags(updateBags);
  };

  const onModifiedDraftData = (uuid, modifiedDataList) => {
    const updateDraftBags = update([], { $push: [...draftBags] });
    updateDraftBags.forEach(
      (updateDraftBag, index) => {
        if (updateDraftBag.uuid === uuid) {
          modifiedDataList.forEach(({ key, value }) => {
            updateDraftBags[index][key] = value;
          });
        }
      },
    );
    setDraftBags(updateDraftBags);
  };
  const checkBagUploadContainerState = () => {
    const bagUploadContainerState = uploadBags.map((bag) => {
      if ((bag.name === null || bag.name === '')
          && (bag.typeId === null)
          && (bag.contractDeadLine === null)
          && (bag.description === null || bag.description === '')
          && (bag.rentMonths === null)
          && (bag.frontPicId === null)
          && (bag.backPicId === null)
          && (bag.sidePicId === null)
          && (bag.bottomPicId === null)
          && (bag.zipperPicId === null)
          && (bag.newLevel === null || bag.newLevel === '0')
          && (bag.hotLevel === null || bag.hotLevel === '0')
          && (bag.designerTagId === null || bag.designerTagId === '0')
          && (bag.styleTagId === null || bag.styleTagId === '0')
          && (bag.originalPrice === null || bag.originalPrice === '0')
          && (bag.depositOrigin === null)
          && (bag.buyoutAmount === null)
          && (bag.depositOrigin === null)
      ) return 'emptyBagContainer';

      if ((bag.name !== null && bag.name !== '')
      && (bag.typeId !== null)
      && (bag.contractDeadLine !== null)
      && (bag.description !== null)
      && (bag.rentMonths !== null)
      && ((bag.frontPicId !== null)
      || (bag.backPicId !== null)
      || (bag.sidePicId !== null)
      || (bag.bottomPicId !== null)
      || (bag.zipperPicId !== null))
      && (bag.newLevel !== null && bag.newLevel !== '0')
      && (bag.hotLevel !== null && bag.hotLevel !== '0')
      && (bag.designerTagId !== null && bag.designerTagId !== '0')
      && (bag.styleTagId !== null && bag.styleTagId !== '0')
      && (bag.originalPrice !== null || bag.originalPrice !== 0)
      && (((bag.typeId === typeId.rent) && (bag.depositOrigin !== null) && (bag.depositOrigin >= 0))
        || ((bag.typeId === typeId.buy) && (bag.buyoutAmount !== null) && (bag.buyoutAmount >= 0))
        || ((bag.typeId === typeId.rentBuy) && (bag.buyoutAmount !== null) && (bag.buyoutAmount >= 0)
        && (bag.depositOrigin !== null) && (bag.depositOrigin >= 0)))
      ) return 'verified';

      return 'unVerifyBagData';
    });
    return bagUploadContainerState;
  };

  const onSubmit = () => {
    const bagContainerState = checkBagUploadContainerState();
    if (bagContainerState.indexOf('unVerifyBagData') !== -1) {
      setIsShowBagUploadError(true);
      return;
    }

    const filterEmptyBagUploadContainer = uploadBags.filter((bag, index) => {
      if (bagContainerState[index] === 'emptyBagContainer') {
        return false;
      }
      return true;
    });

    // if all bags container is empty show error modal
    if (filterEmptyBagUploadContainer.length === 0) {
      setIsShowBagUploadError(true);
      return;
    }

    submitBag(filterEmptyBagUploadContainer);
    if (isUpdateMemberInfo) {
      updateMemberInfo({
        recipientName,
        recipientPhone,
        recipientAddress,
      });
    }

    setUploadBags([{ ...EmptyUploadBagComponent, uuid: uuidv4() }]);
  };

  const onCheckDraftFields = () => {
    if (!selectDraftBag) {
      return false;
    }

    if ((selectDraftBag.name !== null && selectDraftBag.name !== '')
        && (selectDraftBag.typeId !== null)
        && (selectDraftBag.contractDeadLine !== null)
        && (selectDraftBag.description !== null)
        && (selectDraftBag.rentMonths !== null)
        && ((selectDraftBag.frontPicId !== null)
        || (selectDraftBag.backPicId !== null)
        || (selectDraftBag.sidePicId !== null)
        || (selectDraftBag.bottomPicId !== null)
        || (selectDraftBag.zipperPicId !== null))
        && (selectDraftBag.newLevel !== null && selectDraftBag.newLevel !== '0')
        && (selectDraftBag.hotLevel !== null && selectDraftBag.hotLevel !== '0')
        && (selectDraftBag.designerTagId !== null && selectDraftBag.designerTagId !== '0')
        && (selectDraftBag.styleTagId !== null && selectDraftBag.styleTagId !== '0')
        && (selectDraftBag.originalPrice !== null || selectDraftBag.originalPrice !== 0)
        && (((selectDraftBag.typeId === typeId.rent) && (selectDraftBag.depositOrigin !== null) && (selectDraftBag.depositOrigin >= 0))
          || ((selectDraftBag.typeId === typeId.buy) && (selectDraftBag.buyoutAmount !== null) && (selectDraftBag.buyoutAmount >= 0))
          || ((selectDraftBag.typeId === typeId.rentBuy) && (selectDraftBag.buyoutAmount !== null) && (selectDraftBag.buyoutAmount >= 0)
          && (selectDraftBag.depositOrigin !== null) && (selectDraftBag.depositOrigin >= 0)))
    ) return 'verified';

    return 'unVerifyBagData';
  };

  const submitDraftBag = () => {
    if (onCheckDraftFields() !== 'verified') {
      setIsShowBagUploadError(true);
      return;
    }

    setIsShowMonoUploadBagComponent(false);
    setSelectDraftBag(null);
    submitBag([selectDraftBag]);

    const newDraftBag = R.filter(draftOfBag => draftOfBag.uuid !== selectDraftBag.uuid, draftBags);
    setDraftBags(newDraftBag);
  };

  const renderTopTab = (
    tab,
    title,
    subtitle,
    mobileButtonStyle,
    mobileTitleStyle,
    mobileSubTitleStyle,
  ) => (
    <button
      key={title}
      onClick={() => setSelectTab(tab)}
      type="button"
      style={selectTab === tab
        ? { ...styles.tabSelectContainer, ...mobileButtonStyle }
        : { ...styles.tabUnSelectContainer, ...mobileButtonStyle }}
    >
      <div style={selectTab === tab
        ? { ...styles.tabSelect, ...mobileTitleStyle }
        : { ...styles.tabUnSelect, ...mobileTitleStyle }}
      >
        {title}
      </div>
      <div style={selectTab === tab
        ? { ...styles.tabSelect, ...styles.tabHint, ...mobileSubTitleStyle }
        : { ...styles.tabUnSelect, ...styles.tabHint, ...mobileSubTitleStyle }}
      >
        {subtitle}
      </div>
    </button>
  );

  const TopTabData = [
    {
      tab: 'uploadMultiBag',
      title: '商品上傳',
      subtitle: 'Upload New Item',
    },
    {
      tab: 'editDraft',
      title: '編輯草稿',
      subtitle: 'Edit Draft',
    },
  ];

  const uploadBottomText = (buttonContainerStyle) => {
    const text = (
      <React.Fragment>
        審核通過後會寄送E-mail通知
        <br />
        並安排物流人員前往取件
        <br />
        商品抵達中心後會植入奈米防偽晶片並進行上架！
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Default>
          <div style={styles.uploadHint}>
            {text}
          </div>
        </Default>
        <Mobile>
          <div style={styles.uploadHintMobile}>
            {text}
          </div>
        </Mobile>
        <div style={{ ...styles.uploadFunctionContainer, ...buttonContainerStyle }}>
          <button type="button" style={styles.saveDraft} onClick={() => onSaveDraft()}>儲存草稿</button>
          <button
            type="button"
            style={styles.upload}
            onClick={() => {
              onSubmit();
              // GTag implant
              window.gtag_report_conversion2();
            }}
          >
            審核發佈
          </button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div style={styles.mainContainer} key="baseContainer">

      <NotifyDraftBagsSavedComponent
        onModalClose={() => setIsShowSavedDraft(false)}
        isVisible={isShowSavedDraft}
      />

      <SubmitMonoBagComponent
        submitDraftBag={submitDraftBag}
        tags={tags}
        onModifiedDraftData={onModifiedDraftData}
        selectDraftBag={selectDraftBag}
        onModalClose={() => setIsShowMonoUploadBagComponent(false)}
        isVisible={isShowMonoUploadBagComponent && (reduxStatus !== ReduxStatus.STATUS_NORMAL)}
      />

      <CompletePublishComponent
        onModalClose={() => {
          setIsShowCompletePublishComponent(false);
          resetStatus();
        }}
        isVisible={isShowCompletePublishComponent || (reduxStatus === ReduxStatus.STATUS_NORMAL)}
      />

      <ModalComponent
        isVisible={isShowBagUploadError}
        onModalClose={() => setIsShowBagUploadError(false)}
        onModalConfirmed={() => setIsShowBagUploadError(false)}
        content="請確實輸入所有包包資料"
      />

      {/* GTag implant */}
      <Helmet>
        <script>
          {'gtag(\'event\', \'conversion\', {\'send_to\': \'AW-627439533/YJlKCK6vo9IBEK3vl6sC\'})'}
        </script>
        <script>
          {`
          function gtag_report_conversion2(url) {
            var callback = function () {
              if (typeof(url) != 'undefined') {
                window.location = url;
              }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-627439533/Z2msCIKwo9IBEK3vl6sC',
                'transaction_id': '',
                'event_callback': callback
            });
            return false;
          }
          `}
        </script>
      </Helmet>

      <ReduxStatusVisualizedComponent
        reduxStatus={reduxStatus}
        showModalOnError
        modalFailureContent={reduxError}
      >
        <div style={styles.tabContainer}>
          <button
            onClick={() => setNewPageVersion(false)}
            type="button"
          >
            Old version
          </button>
          <button
            onClick={() => setNewPageVersion(true)}
            type="button"
          >
            new version
          </button>
          <Default>
            {TopTabData.map((item) => {
              const {
                tab,
                title,
                subtitle,
              } = item;
              return renderTopTab(tab, title, subtitle);
            })}
          </Default>
          <Mobile>
            {TopTabData.map((item) => {
              const {
                tab,
                title,
                subtitle,
              } = item;
              return renderTopTab(
                tab,
                title,
                subtitle,
                styles.mobileTabStyle,
                styles.mobileTabTitleStyle,
                styles.mobileTabSubTitleStyle,
              );
            })}
          </Mobile>
        </div>

        <div style={styles.tabDivider} />
        {selectTab === 'uploadMultiBag'
          ? (
            <React.Fragment>
              {uploadBags.map((uploadBag, index) => (
                <React.Fragment key={uploadBag.uuid}>
                  <Default>
                    <UploadBagComponent
                      onModifiedData={onModifiedData}
                      uploadBag={uploadBag}
                      onRemoveUploadBag={onRemoveUploadBag}
                      index={index}
                      tags={tags}
                      onMemberValueChanged={onMemberValueChanged}
                      recipientName={recipientName}
                      recipientPhone={recipientPhone}
                      recipientAddress={recipientAddress}
                      isUpdateMemberInfo={isUpdateMemberInfo}
                      onIsUpdateMemberInfoChanged={setIsUpdateMemberInfo}
                    />
                  </Default>
                  <Mobile>
                    <UploadBagComponent
                      onModifiedData={onModifiedData}
                      uploadBag={uploadBag}
                      onRemoveUploadBag={onRemoveUploadBag}
                      index={index}
                      tags={tags}
                      containerStyle={styles.uploadBagMobileStyle}
                      optionItemMobileContainerStyle={styles.uploadOptionItemMobileContainer}
                      itemMobileContainerStyle={styles.uploadItemMobileContainer}
                      itemTitleMobileContainerStyle={styles.uploadItemTitleMobileContainer}
                      onMemberValueChanged={onMemberValueChanged}
                      recipientName={recipientName}
                      recipientPhone={recipientPhone}
                      recipientAddress={recipientAddress}
                    />
                  </Mobile>
                </React.Fragment>
              ))}
              <button
                type="button"
                style={styles.plusContainer}
                onClick={() => {
                  const uploadItem = { ...EmptyUploadBagComponent, uuid: uuidv4() };
                  const addItems = update(uploadBags, { $push: [uploadItem] });
                  setUploadBags(addItems);
                }}
              >
                <Icon style={styles.plusIcon} type="plus-circle" />
                <div style={styles.plusItemHint}>增加項目</div>
              </button>

              <div style={styles.divider} />
              <Default>
                <div style={styles.uploadHintContainer}>
                  {uploadBottomText()}
                </div>
              </Default>
              <Mobile>
                <div style={styles.uploadHintMobileContainer}>
                  {uploadBottomText(styles.uploadFunctionMobileContainer)}
                </div>
              </Mobile>
            </React.Fragment>
          )
          : (
            <table style={styles.tableContainer}>
              <tbody>
                <tr style={styles.draftHeader}>
                  <th style={styles.draftBagImgTr}>產品圖片</th>
                  <th style={styles.draftBagNameTr}>產品名稱</th>
                  <th style={styles.draftBagTypeTr}>提供方式</th>
                  <th style={styles.draftBagFunctionTr}>操作</th>
                </tr>
                {
                draftBags.map(draftBag => (
                  <DraftBagComponent
                    onEditDraftBag={() => {
                      setSelectDraftBag(draftBag);
                      setIsShowMonoUploadBagComponent(true);
                    }}
                    key={draftBag.uuid}
                    onRemoveDraftBag={onRemoveDraftBag}
                    draftBag={draftBag}
                  />
                ))
              }
              </tbody>
            </table>
          )
        }
      </ReduxStatusVisualizedComponent>
    </div>
  );
};

SubmitBagPage.propTypes = {
  submitBag: PropTypes.func.isRequired,
  resetStatus: PropTypes.func.isRequired,
  onWriteDraft: PropTypes.func.isRequired,
  reduxStatus: PropTypes.string.isRequired,
  reduxError: PropTypes.string,
  bagInfo: PropTypes.shape({}),
  tags: PropTypes.array,
  draftOfBags: PropTypes.array,
  onMemberValueChanged: PropTypes.func,
  recipientName: PropTypes.string,
  recipientPhone: PropTypes.string,
  recipientAddress: PropTypes.string,
  updateMemberInfo: PropTypes.func.isRequired,
  setNewPageVersion: PropTypes.func,
};

SubmitBagPage.defaultProps = {
  reduxError: null,
  bagInfo: null,
  tags: [],
  draftOfBags: [],
  recipientName: '',
  recipientPhone: '',
  recipientAddress: '',
  onMemberValueChanged: () => {},
  setNewPageVersion: () => {},
};

export default SubmitBagPage;
