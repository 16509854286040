module.exports = [
  {
    name: '精選推薦',
    order: ['defaultOrder desc', 'createTime desc'],
  }, {
    name: '最新上架',
    order: 'createTime desc',
  }, {
    name: '租金由低至高',
    order: 'rentAmountPerDay asc',
  }, {
    name: '租金由高至低',
    order: 'rentAmountPerDay desc',
  }, {
    name: '購買價格由低至高',
    order: 'buyoutAmount asc',
  }, {
    name: '購買價格由高至低',
    order: 'buyoutAmount desc',
  }, {
    name: '上架優先',
    order: 'createTime asc',
  },
];
