import React from 'react';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: 40,
    padding: '10px 0px',
  },
  dayOfWeek: {
    fontSize: 13,
    color: 'black',
    flex: 1,
  },
};

const DayOfWeekComponent = () => (
  <div style={styles.mainContainer}>
    <div style={styles.dayOfWeek}>Su</div>
    <div style={styles.dayOfWeek}>Mo</div>
    <div style={styles.dayOfWeek}>Tu</div>
    <div style={styles.dayOfWeek}>We</div>
    <div style={styles.dayOfWeek}>Th</div>
    <div style={styles.dayOfWeek}>Fr</div>
    <div style={styles.dayOfWeek}>Sa</div>
  </div>
);


export default DayOfWeekComponent;
