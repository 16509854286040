import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import {
  couponItemEnable,
  couponItemDisable,
} from '../../../../constants/Assets';

const styles = {
  mainContainer: {
    border: 'none',
    width: '100%',
    height: '100px',
    margin: '5px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 0,
    outline: 'none',
  },
  mainDisableContainer: {
    border: 'none',
    width: '100%',
    height: '100px',
    margin: '5px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'not-allowed',
    padding: 0,
    outline: 'none',
  },
  leftCouponWave: {
    width: '5%',
    height: '100px',
    objectFit: 'fill',
  },
  leftContainer: {
    width: '25%',
    height: '100px',
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftDisableContainer: {
    width: '25%',
    height: '100px',
    backgroundColor: '#B7B6B6',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  couponTitle: {
    fontSize: 16,
    color: 'white',
    marginLeft: -20,
  },
  couponMobileTitle: {
    fontSize: 12,
    color: 'white',
    marginLeft: -10,
  },
  rightContainer: {
    borderTop: '1px solid #aaa',
    borderBottom: '1px solid #aaa',
    borderRight: '1px solid #aaa',
    height: '100px',
    width: '70%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  couponContent: {
    fontSize: 16,
    color: 'black',
  },
  couponDisableContent: {
    fontSize: 16,
    color: '#B7B6B6',
  },
  couponContentContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  couponMobileContentContainer: {
    width: '90%',
  },
};

const CouponItemComponent = ({
  memberCoupon,
  orderType,
  isReadMode,
  onSelectedMemberCoupon,
}) => {
  const couponType = R.path(['coupon', 'couponType', 'name'], memberCoupon);
  const isHidden = R.pathOr(false, ['coupon', 'isHidden'], memberCoupon);

  const renderAmountCoupon = () => {
    if (R.path(['coupon', 'percent'], memberCoupon) !== 0) {
      return `${R.path(['coupon', 'percent'], memberCoupon) / 10} 折 折扣券`;
    }

    return `$ ${R.path(['coupon', 'minus'], memberCoupon)}元 折扣券`;
  };


  const isAvailable = () => {
    if (isReadMode) {
      return true;
    }

    if (!R.isNil(R.path(['useDate'], memberCoupon)) && !R.path(['isReusable'], memberCoupon)) {
      return false;
    }

    if (moment().isAfter(R.path(['endDate'], memberCoupon))) {
      return false;
    }

    if (orderType === 'rent' && (couponType === '售價折扣碼')) {
      return false;
    }

    if (orderType === 'buy' && couponType !== '售價折扣碼') {
      return false;
    }

    return true;
  };

  return (
    <React.Fragment>
      {isHidden ? null : (
        <React.Fragment>
          <Default>
            <button
              type="button"
              disabled={!isAvailable()}
              onClick={() => {
                onSelectedMemberCoupon(memberCoupon);
              }}
              style={isAvailable() ? styles.mainContainer : styles.mainDisableContainer}
            >
              <img alt="" style={styles.leftCouponWave} src={isAvailable() ? couponItemEnable : couponItemDisable} />
              <div style={isAvailable() ? styles.leftContainer : styles.leftDisableContainer}>
                <div style={styles.couponTitle}>{renderAmountCoupon()}</div>
                <div style={styles.couponTitle}>{R.path(['coupon', 'couponName'], memberCoupon)}</div>
              </div>
              <div style={styles.rightContainer}>
                <div style={styles.couponContentContainer}>
                  <div style={isAvailable() ? styles.couponContent : styles.couponDisableContent}>折扣碼</div>
                  <div style={isAvailable() ? styles.couponContent : styles.couponDisableContent}>
                    {R.pathOr('', ['coupon', 'couponCode'], memberCoupon)}
                  </div>
                </div>
                <div style={styles.couponContentContainer}>
                  <div style={isAvailable() ? styles.couponContent : styles.couponDisableContent}>有效日期</div>
                  <div style={isAvailable() ? styles.couponContent : styles.couponDisableContent}>
                    {R.path(['endDate'], memberCoupon) === null
                      ? '無使用期限'
                      : moment(R.path(['endDate'], memberCoupon)).format('YYYY-MM-DD')
              }
                  </div>
                </div>
              </div>
            </button>
          </Default>
          <Mobile>
            <button
              type="button"
              disabled={!isAvailable()}
              onClick={() => {
                onSelectedMemberCoupon(memberCoupon);
              }}
              style={isAvailable() ? styles.mainContainer : styles.mainDisableContainer}
            >
              <img alt="" style={styles.leftCouponWave} src={isAvailable() ? couponItemEnable : couponItemDisable} />
              <div style={isAvailable() ? styles.leftContainer : styles.leftDisableContainer}>
                <div style={styles.couponMobileTitle}>{renderAmountCoupon()}</div>
                <div style={styles.couponMobileTitle}>{R.path(['coupon', 'couponName'], memberCoupon)}</div>
              </div>
              <div style={styles.rightContainer}>
                <div style={{ ...styles.couponContentContainer, ...styles.couponMobileContentContainer }}>
                  <div style={isAvailable() ? styles.couponContent : styles.couponDisableContent}>折扣碼</div>
                  <div style={isAvailable() ? styles.couponContent : styles.couponDisableContent}>
                    {R.pathOr('', ['coupon', 'couponCode'], memberCoupon)}
                  </div>
                </div>
                <div style={{ ...styles.couponContentContainer, ...styles.couponMobileContentContainer }}>
                  <div style={isAvailable() ? styles.couponContent : styles.couponDisableContent}>有效日期</div>
                  <div style={isAvailable() ? styles.couponContent : styles.couponDisableContent}>
                    {R.path(['endDate'], memberCoupon) === null
                      ? '無使用期限'
                      : moment(R.path(['endDate'], memberCoupon)).format('YYYY-MM-DD')
                  }
                  </div>
                </div>
              </div>
            </button>
          </Mobile>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

CouponItemComponent.propTypes = {
  memberCoupon: PropTypes.object,
  onSelectedMemberCoupon: PropTypes.func.isRequired,
  orderType: PropTypes.string,
  isReadMode: PropTypes.bool,
};

CouponItemComponent.defaultProps = {
  memberCoupon: null,
  isReadMode: false,
  orderType: 'rent',
};

export default CouponItemComponent;
