import React, { useEffect } from 'react';
import { Link } from '@reach/router';
import Assets from '../../../../constants/Assets';
import { Colors } from '../../../../constants/Color';
import InfoTitle from '../../../../components/InfoTitle';
import Paths from '../../../../routes/Paths';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    margin: '160px 20px 120px 20px',
  },
  mobileContainer: {
    margin: '40px 25px 120px 25px',
  },
  termsContainer: {
    lineHeight: '26px',
    paddingRight: '140px',
    letterSpacing: '1px',
    whiteSpace: 'normal',
  },
  mainTerms: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '30px',
  },
  terms: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '40px',
  },
  inlineTerms: {
    fontSize: '14px',
    fontWeight: 'normal',
    marginBottom: '40px',
  },
  items: {
    fontSize: '14px',
    fontWeight: 'normal',
    marginLeft: '10px',
  },
  lists: {
    fontSize: '14px',
    fontWeight: 'normal',
    marginLeft: '20px',
  },
  subtitle: {
    width: '100%',
    color: '#000000',
    fontSize: '20px',
    letterSpacing: '3.33px',
    lineHeight: '28px',
    textAlign: 'left',
    margin: '10px 0 10px 0',
  },
  pic: {
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    marginTop: '80px',
    marginBottom: '60px',
    height: '600px',
    objectFit: 'cover',
  },
  listContainer: {
    margin: '30px 0 30px 0',
  },
  leftTermsTitle: {
    width: '35%',
    display: 'inline-block',
    paddingLeft: '100px',
    paddingRight: '124px',
    margin: '60px 0 60px 0',
  },
  rightTermsContent: {
    width: '65%',
    display: 'inline-block',
    verticalAlign: 'top',
    paddingRight: '140px',
    lineHeight: '28px',
    margin: '60px 0 20px 0',
  },
  leftTermsNumber: {
    color: Colors.introRightTitle,
    fontSize: '20px',
    letterSpacing: '2px',
  },
  hr: {
    margin: '0 140px  0  100px',
    border: `0.5px solid ${Colors.separateLine}`,
  },
  hrMobile: {
    margin: '0 10px',
    border: `0.5px solid ${Colors.separateLine}`,
  },
  picMobile: {
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    marginTop: '80px',
    marginBottom: '60px',
    height: '400px',
    objectFit: 'cover',
  },
  contentMobileContainer: {
    marginTop: '20px',
  },
  titleMobileContainer: {
    marginTop: '40px',
  },
};


const termsIntro = `「OP 永續時尚共享平台」網站為哇美股份有限公司(以下稱「本網站」、「本公司」、「我們」)所建置及提供之服務，
本公司之服務係提供出租/出售方會員(下稱出租/出售方)與租借/購買方會員(下稱租借/購買方)(統稱為”會員””您”)之間在平台進行商品租借及買賣的場所和機會；
會員須自行定期追蹤平台訂單狀態，並依平台網站流程指示出貨或歸還商品(以下稱「本服務」、「本平台」)，為了保障您的使用權益，所有使用或造訪本服務的使用者
（包括未註冊成為會員以及註冊會員之使用者）（以下簡稱「使用者」、「會員」 或「您」），都應詳細閱讀本服務條款。本服務條款構成您與本公司之約定
（以下簡稱「本服務條款」），一旦您開始使用本服務、或完成註冊時，即視為您已閱讀、暸解、並同意接受本服務條款之所有內容。`;

const privacyLink = (
  <Link to={Paths.PRIVACY}>隱私權政策</Link>
);

// const FAQLink = (
//   <Link to={Paths.FAQ}>Q&A</Link>
// );

const termsData = [
  {
    title: '認知與接受條款',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          (一) 我們有權隨時修訂本服務條款之內容。若我們修訂本服務條款，會透過我們的
          網站「OP 永續時尚共享平台」公告，告知您修訂後的條款內容；您了解並同意我們無需針
          對本服務條款之修訂，對您另行各別通知，您應定期瀏覽本服務網站，查看本
          服務條款是否有內容修訂或更新。
        </div>
        <div style={styles.terms}>
          (二) 修訂或更新後之本服務條款內容，除另有說明者外，自公告時起生效。自生效
          日起，如會員繼續使用本服務，即視為會員已閱讀、瞭解、並同意修訂後的條
          款所有內容。如會員不同意修改後之內容，會員則無權再使用本服務，並立即
          停止繼續使用本服務，構成終止合約之效果；我們與您之間的權利義務應自終
          止合約時結算。您了解並同意，對於您無法同意修訂或更新後之本服務條款內
          容，而與我們終止合約時，我們並不因此而對會員負任何賠償或補償之責任。
        </div>
      </React.Fragment>
    ),
  },
  {
    title: '會員及註冊',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          (一) 使用本服務之會員原則上應以滿20歲之成年人為限。若您未滿7歲，應由您的法
          定代理人為您註冊本服務，並由法定代理人全程代替您使用本服務。若您已滿
          七歲但未滿20歲，應由法定代理人閱讀、瞭解並同意本服務條款之所有約定內
          容及其修改後之內容，於您取得法定代理人之同意後，始得開始使用或繼續使
          用本服務；如會員開始使用或繼續使用本服務，視為其帳號係由法定代理人所
          註冊或法定代理人已閱讀、暸解、並同意本服務條款之所有內容及其修改後之
          內容。
        </div>
        <div style={styles.terms}>
          (二)會員申請註冊本服務時，需按本公司要求選擇填入會員資料以及提供註冊流程
          中所要求之相關文件或資料。會員欲使用本服務之所有功能者，並應依本服務
          當時所訂之方式完成會員認證。
        </div>
        <div style={styles.terms}>
          (三)會員應擔保其所提供的所有資料，均為正確且即時的資料，且不得以第三人之
          名義註冊為會員；如會員所提供的資料事後有變更，會員應即時更新其資料。
          如會員未即時提供、更新資料、未按指定方式提供資料、所提供之資料不正確
          或與事實不符， 本公司有權不經事先通知，逕行暫停或終止會員的帳號，並拒
          絕會員使用本服務之全部或部份功能。
        </div>
        <div style={styles.terms}>
          (四)您了解並同意，於會員註冊時您需進行您所提交之會員申請資料之個人資料實
          名認證，該驗證由Passbase個人資料認證程序進行；請注意，所有會員均需進
          行Passbase個人資料認證程序始得使用本服務。當您未通過或未使用前述驗證
          時，則無法使用本網站服務。
        </div>
        <div style={styles.terms}>
          (五)您同意，會員就使用本服務所取得的帳號和密碼，僅供會員本人使用，該帳號
          不可轉讓、贈與或繼承。會員決定不再使用該帳號時，應向本公司申請註銷該
          帳號。會員對於自身於本服務取得之帳號、密碼必須負妥善保管和保密的義務
          ，且不得以有償、無償或任何方式透露或提供予任何第三人登錄或使用；您了
          解並同意，任何經由輸入您之帳號及密碼所發生的交易、活動，均視為您的行
          為，經由該組帳號和密碼所為行為之所有法律效果，應由您自負全部責任。
        </div>
        <div style={styles.terms}>
          (六)會員如果發現或懷疑其帳號或密碼被第三人冒用或不當使用，應立即以書面通
          知我們，以便及時採取適當的因應措施；但上述因應措施不得解釋為本公司因
          此而明示或默示對會員負有任何形式之賠償或補償義務。若係因為會員之保管
          疏失或未遵守規定使用帳號密碼， 致您的帳號或密碼遭他人不當使用所生之任
          何損害，本公司不負任何法律責任。
        </div>
        <div style={styles.terms}>
          (七)為了保障會員權益，提醒您，若有於其他非本人持有之設備使用本服務後，務
          必登出帳號，假如會員與他人共用電腦或任何裝置，務必要關閉瀏覽器或本服
          務，以防止他人讀取您的個人資料。
        </div>
        <div style={styles.terms}>
          (八)本公司得限制同一自然人所得申請註冊之會員帳號數量；對於註冊成為會員之
          申請，本公司保留是否同意其註冊之權利。
        </div>
        <div style={styles.terms}>
          (九)本公司可藉由簡訊、電子郵件、電話等提供會員關於本公司所提供服務之相關
          資訊。
        </div>
        <div style={styles.terms}>
          (十)不論係因何種原因由本公司註銷會員帳號或是停止提供服務時，本公司就您帳
          號的所有相關資訊均不負任何備份之義務。
        </div>
        <div style={styles.terms}>
          (十一)本服務保留隨時暫停或終止您在本服務帳戶以及您使用本服務的權利，倘有以下任一情形者，本公司得不予同意加入會員：
          <div style={styles.items}>
            1.以他人的名義申請。
            <br />
            2.申請服務時，登錄之資料內容不實。
            <br />
            3.電子郵件位址不明確。
            <br />
            4.違反本服務條款、或本公司其他政策、聲明或規則被停權者申請加入。
            <br />
            5.其他不符合本服務之申請條件。
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: '本服務內容',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          本網站為提供會員精品名牌服飾/包款/飾品共享之媒合服務平台，由會員提供商品於本網站上租賃，使其他會員得透過本網站租借、
          買賣(當您因使用本網站特定服務而具有為出租/出售者之身分時，以下稱「出租/出售方會員」；當您的身分為租借/購買之會員，
          以下稱「租借/購買方會員」；兩者合稱「會員」或「您」)。
        </div>
        <div style={styles.terms}>
          (一) 基本說明
          <div style={styles.items}>
            (1) 您於本網站申請成為會員或使用本服務時，我們將依照本服務提供所須
            知特定目的要求您提供包括：真實姓名、出生年月日、聯絡電話、通訊
            地址、 金融機構代碼及帳戶號碼、信用卡卡號等個人真實資料，詳參本
            網站隱私權政策。
            {privacyLink}
            。
          </div>
          <div style={styles.items}>
            (2) 付款方式：透過平台以線上刷卡方式交易 / 中租0卡分期
          </div>
          <div style={styles.items}>
            （3）運送方式：自助管理商品者，為精品包之商品會員須先將商品提前寄至平台鑑定後，由平台代為出貨，可郵寄到府／超商店到店。
            一般商品非精品包則由會員自行寄送至租借方收件地址。
          </div>
          <div style={styles.items}>
            （4）運費計算方式：平台採自助式出租/出售與租借/購買，運費皆自行負擔，OP管理商品則由平台代為出貨，
            出貨運費將從出租/出售方實際賺取收益中扣除，運費金額則依實際出貨時之包裹重量計算。
          </div>
          <div style={styles.items}>
            （5）鑑定服務：所有為精品包商品於出租/出售前皆須寄至OP進行專業鑑定，通過鑑定後再經由OP出貨。
          </div>
          <div style={styles.items}>
            （6）七天鑑賞期：OP 永續時尚共享平台提供會員精品名牌服飾/包款/飾品共享之媒合服務平台，除購買精品包包與全新商品外，交易商品屬於個人與個人之間的交易均沒有七天鑑賞期，不提供退換貨。
          </div>
          <div style={styles.items}>
            （7）退換貨說明：購買僅二手精品包包與全新商品享退貨服務，購買方在簽收商品後七天內，吊牌未剪且有合理退貨事由，皆可透過OP官方聯絡管道申請退貨，
            並經OP判定符合退貨條件後方可進入退貨程序，且購買方須同意支付退貨過程中所產生之運費成本。另外，標示為OP管理之商品，平台提供「安心試穿」服務，租借服飾若有尺寸不合之問題，到貨12小時內可申請退貨。
          </div>
          <div style={styles.items}>
            （8）OP管理商品：將欲出租/出售之商品交由OP代管，統一進行維護、清潔與出貨之服務，若選擇該項服務，商品需至少交由OP代管30天以上。商品若明確標示為「OP管理」將享有當天15:30前下單隔日到貨之服務，
            另若租借服飾遇尺寸不符可於到貨12小時內申請退貨。OP管理商品成功出租/出售後，則由平台代為出貨，出貨運費將從出租/出售方實際賺取收益中扣除。
          </div>
        </div>
        <div style={styles.terms}>
          （二）出租/出售方會員可於平台進行（以下稱「出租/出售方」）
          <div style={styles.items}>
            1.  上傳商品-出租/出售方將商品上傳，應將商品之照片依照本網站之規定格式上傳，並就各包款之包括：價值金額、品牌、型號款式、購買年份、
            顏色及其他本網站要求填寫之資料一併上傳於本平台，供本平台審核；前述審核內容包括但不限於：出租/出售方上傳包款資訊之正確性、包款價值、
            贗品檢驗等；經本平台審核通過後，包款始得上架出租/出售。出租/出售方應自行擔保前述上傳本平台之相關資訊無侵害任何第三人之任何權利，
            以及該等資訊之正確性、即時性與完整性。經平台審核通過後商品即在平台進行上架。
          </div>
          <div style={styles.items}>
            2. 接受/取消訂單-當租借/購買方完成商品訂單線上付款後，出租/出售方會收到網站系統通知，並可選擇是否接受訂單。若租借/購買方完成付款後的48小時內出租/出售方未接受或取消訂單，
            系統會自動取消該筆訂單並由平台進行款項刷退予租借方。請留意，若未通過Passbase實名認證，將無法接受訂單。
          </div>
          <div style={styles.items}>
            3. 訂單成立/取消-出租方確認訂單後，即無法取消該筆訂單，屆時該筆訂單款項將會由平台完全刷退至租借方。
          </div>
          <div style={styles.items}>
            4. 訂單出貨-當出租/出售方接受為精品包商品之訂單後，必須確保商品將最晚會在租借起始日前3天先抵達OP火租進行審核，寄送方式可至交易紀錄中點選「我要寄貨」查看；運費負擔上，
            出租方需自行支付來回平台與OP代為出貨時之運費，共3趟。若為OP管理商品，則由平台統一出貨，運費負擔共1趟。當出租/出售方接受為一般商品之訂單後，必須確保商品將於租借起始日當天抵達。
          </div>
          <div style={styles.items}>
            5. 出貨商品品質擔保-出租/出售方應擔保其商品之真實且同意商品在出租/出售前，需先寄至OP進行鑑定並接受鑑定結果，
            若未通過鑑定OP有權取消訂單並退件商品，同時建議出租/出售方於包裝商品時使用手機進行錄影或拍攝照片存證，以避免日後糾紛。
          </div>
          <div style={styles.items}>
            6.  租借訂單收取歸還商品-訂單截止日後，出租方收取歸還商品、並確認商品無任何問題後，須四天內於平台內按下 [確認歸還]。
            四天內若未從出租方收到任何回報即代表商品歸還成功，系統將自動跳轉為確認歸還；若未收到歸還商品，須於四天內盡快與平台回報問題。
          </div>
          <div style={styles.items}>
            7.  租借訂單商品逾期歸還-考慮到商品逾期歸還會造成出租方後續訂單的延遲，若租借方未在訂單截止日當天寄出歸還，
            平台會向租借方收取延遲金（單日租金x1.5倍x延遲天數），延遲金扣除平台服務費後平台將轉付給出租方。
          </div>
          <div style={styles.items}>
            8. 租借訂單商品逾期未歸還-若出租方的商品超過租借截止日七天未收到歸還，且也未與租借方取得聯絡，平台會向租借方要求支付商品全額，
            並在收到租借方賠償金額後將商品全額轉付給出租方（出租方將收到：商品原價並扣除服務費後計算之總額）。
          </div>
          <div style={styles.items}>
            9.  出貨商品逾期未領取遭退回(超商店到店)-出租方出貨之商品抵達指定超
            商後若超過七日未由租借方取貨，商品將會被退回原出貨門市(須持證件
            才可取回商品)，平台對租借方支付之款項將不予退還，並會將該筆訂單
            款項支付給出租方；而出租方須在收到通知後七日內前往原出貨超商取
            回，若出租方未於期限內取回，包裹將會被送至各家超商的物流中心，
            需自行與超商物流公司聯繫，若超過十日未與超商物流中心聯繫取回，
            則包裹將會進行銷毀，後續所生問題由出租方自行負責，本公司不負任
            何相關賠償責任。
          </div>
          <div style={styles.items}>
            10. 租借訂單歸還商品出現毀損-建議出租方在商品出貨前/收貨後使用手機拍照紀錄(手機會記錄拍照日期及時間)。若出租方收到歸還商品後發現商品出現損壞情況，
            出租方需將商品出貨當時的照片與歸還後出現損壞的照片一併提供給平台進行判定，平台將會依出租方提供的照片進行評估商品損壞程度，
            並依損壞程度向租借方請求賠償，賠償金額將全額支付給出租方，不收取平台服務費，出租方須同意平台的一切判定結果。
          </div>
          <div style={styles.items}>
            11. 提款申請-出租商品完成訂單後，出租方可於平台提領款項，平台須在收到出租方提出申請後才進行撥款程序，
            約5個工作天。出售商品將在出貨日起算9天後，於提領款項清單中開放提領。
          </div>
          <div style={styles.items}>
            12.  關於押金(僅適用租借訂單)-出租方於上傳商品時皆可自訂押金金額，但押金金額不得超過商品價值。若商品須支付押金，會於付款頁面與租金一併對租借方進行扣款。
            商品歸還並經出租方確認商品無誤後，平台將於五個工作天內進行租金扣款並刷退押金予租借方。
          </div>
          <div style={styles.items}>
            13. 平台服務費-平台將就每筆完成訂單對出租/出售方酌收服務費用，服務費用之計算方式為：租借訂單-雙方(出租方和租借方)各收取20%=服務費用；
            購買訂單-出售方收取20%=服務費用。平台將於結算第11條之提領款項時，先扣取平台服務費，
            並將剩餘款項撥付予出租方。出租/出售方需接受平台提供的限時優惠以促進消費。當租借/購買方使用優惠折扣碼時，以優惠後的總金額再計算服務費。
          </div>
        </div>
        <div style={styles.terms}>
          （三） 租借/購買方會員可於平台進行（以下稱「租借/購買方」）
          <div style={styles.items}>
            1. 租借/購買商品-租借起始日僅能選擇訂單當日日期的後五日開始(因平台須預留出租/出售方確認訂單、OP平台鑑定、出貨與物流配送時間)。
            若標示為OP代管之商品，則享當日15:30前下單隔日到貨。請留意，若未通過Passbase實名認證，將無法送出租借請求。
          </div>
          <div style={styles.items}>
            2.  進行支付/訂單成立-租借/購買方在選定商品需求後，即可進行線上付款。完成付款後，出租/出售方會收到系統通知並選擇是否接受訂單。
            若出租/出售方未於租借/購買方完成訂單的48小時內接受或取消訂單，系統會自動取消該筆訂單並由平台進行款項刷退。
          </div>
          <div style={styles.items}>
            3.  訂單成立/取消-租借方建立訂單後，需於15鐘內支付租金與押金才算訂單完成，逾15分鐘未付款系統將自動取消該筆訂單。
            出租方確認訂單後，即無法取消該筆訂單，屆時該筆訂單款項將會由平台完全刷退至租借方。
          </div>
          <div style={styles.items}>
            4.  商品逾期未領取(超商店到店)-指定超商店到店配送若商品到店逾期七日未取，超商會將商品退至原出貨超商，
            租借方已支付款項將不進行刷退。
          </div>
          <div style={styles.items}>
            5.  租借訂單商品歸還-須於訂單截止日當天寄出歸還，歸還日會以郵戳或寄件單日期計算。
            寄出完成後租借方應透過本平台向出租方提供如貨運公司名稱及追蹤號碼等細節。
          </div>
          <div style={styles.items}>
            6. 租借訂單歸還商品品質擔保-為免日後糾紛，本公司建議租借方於包裝歸還商品時進行錄影或拍攝照片存證，以證明商品在歸還時的狀態良好。
          </div>
          <div style={styles.items}>
            7.  租借訂單商品逾期歸還-為保障後續租借者權益，若商品未於訂單截止日當天寄出歸還本公司將酌收延遲金(遲延金計算方式：單日租金x1.5倍x延遲天數之總額)。
          </div>
          <div style={styles.items}>
            8.  租借訂單逾期未還-若租借方在訂單截止日後超過七天未歸還商品，租借方了解其舉已造成出租方之損害，租借方並同意逾期未還的損害賠償總額為扣除租借時所支付之租金與押金後的商品原價
            ，平台將以從租借方信用卡扣除該筆價款之方式收費，若有不足租借方則需另補價額。
          </div>
          <div style={styles.items}>
            9. 租借訂單歸還商品出現毀損-建議租借方應於商品收貨後/歸還前使用手機拍照紀錄(手機會記錄拍照日期及時間)並寄回平台審核，若平台收到歸還商品後發現損壞情況，
            平台將要求雙方提供商品出貨與歸還時的照片，根據照片評估損壞程度並向租借方請求賠償，賠償金額將全額支付給出租方，不收取平台服務費，租借方須同意平台的一切判定結果。
          </div>
          <div style={styles.items}>
            10. 關於押金(僅適用租借訂單)-出租方於上傳商品時皆可自訂押金金額，若商品須支付押金，會於付款頁面與租金及服務費一併進行扣款。商品歸還時，出租方確認商品無誤後平台將於五個工作天內進行租金扣款並刷退押金予租借方。
          </div>
          <div style={styles.items}>
            11.  平台服務費-平台將就每筆完成租借訂單對租借方酌收服務費用，服務費用之計算方式為：雙方(出租方和租借方)各收取20%=服務費用=發票金額，該筆費用已含在下單時的訂單金額中，將不會另外進行收費。
          </div>
          <div style={styles.items}>
            12.  退貨服務-購買僅針對二手精品包包與全新商品購買提供退貨服務，退貨需於七天鑑賞期內提出申請，提供貨運簽收單與未剪吊牌之商品照片，滿足初步審核條件後，方可將商品寄回OP進行判定。且需同意不論退貨申請成功或失敗，皆需支付過程中所產生之運費成本。
            另外，標示為OP管理之商品，平台提供「安心試穿」服務，租借服飾若有尺寸不合之問題，到貨12小時內可申請退貨。
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: '一般條款',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          （一） 會員於本服務中，與他會員所進行之互動，均由雙方自行負責；就會員間發生
          之權利義務關係，包括但不限於產生消費糾紛之情形，應由其雙方自行相互協
          調、解決爭議，本公司不負任何明示或默示之承諾或擔保。
        </div>
        <div style={styles.terms}>
          （二） 本服務保留隨時變更、限制服務內容以及停止任何服務的權利，若原有服務被中斷、終止或不可再行使用，本服務保留提供替代服務的權利。
        </div>
        <div style={styles.terms}>
          （三） 在使用本服務時，若會員未遵從本服務條款或網站說明中之操作提示、規則，則本公司有權拒絕為您繼續提供相關服務，且本公司不承擔損害賠償責任。
        </div>
        <div style={styles.terms}>
          （四） 您同意本服務保留隨時進行暫時性或永久性的服務內容修改或中斷，而無需另行通知，且本服務不負擔相關責任。
        </div>
        <div style={styles.terms}>
          （五） 若因其他會員過失導致您的任何損失均與本公司無關，本公司不負擔相關責任。
        </div>
        <div style={styles.terms}>
          （六） 會員同意本公司得自行判斷因任何理由，包含但不限於一定期間未使用、法院或政府機關命令、本服務無法繼續或服務內容實質變更、無法預期之技術或安全因素或問題、
          您所為詐欺或違法行為、未依約支付費用，或其他本公司認為會員已經違反本服務條款的明文規定及精神，而終止或限制會員使用帳號（或其任何部分）或本服務之使用，
          並將本服務內任何會員內任何會員內容加以移除並刪除。會員同意本公司亦得在自行之考量，於通知或未通知之情形下，隨時終止或限制會員使用本服務或其任何部分。會員承認並同意前開終止或限制，
          本公司得立即關閉、刪除或限制存取會員的帳號及會員帳號中全部或部分相關資料及檔案，及停止本服務全部或部分之使用。此外，會員同意若本服務之使用被終止或限制時，本公司對會員或任何第三人均不承擔責任。
        </div>
        <div style={styles.terms}>
          （七） 使用本服務時，若會員未遵從本服務條款或網站說明中之操作提示、規則，則本公司有權拒絕為您繼續提供相關服務，且本公司不承擔損害賠償責任。
        </div>
        <div style={styles.terms}>
          （八） 若因會員過失導致的任何損失均與本公司無關，該過失包括但不限於：不按照提示操作、遺忘或洩漏密碼、密碼被他人破解、您使用的電腦或手機被他人侵入等。
        </div>
        <div style={styles.terms}>
          （九） 會員應擔保其所提供的所有資料，均為正確且即時的資料，且不得以第三人之名義註冊為會員；如會員所提供的資料事後有變更，會員應即時更新其資料。如會員未即時提供、更新資料、
          未按指定方式提供資料、所提供之資料不正確或與事實不符，本公司有權不經事先通知，逕行暫停或終止會員的帳號，並拒絕會員使用本服務之全部或部分功能。
        </div>
        <div style={styles.terms}>
          （十） 您承諾絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守中華民國相關法律及一切使用網際網路之國際慣例。會員若是中華民國以外之會員，
          並同意遵守所屬國家或地域之法令。會員同意並保證不得利用本服務從事侵害他人權益或違法之行為，也不得將本服務用於任何非法目的（包括用於禁止或限制交易物品的交易）
          ，也不以任何非法方式使用本服務，於交易或使用本服務時並應適當說明所發佈之交易條件及相關資訊，不得有誇大、不實、或引人錯誤或誤信之情形。
        </div>
        <div style={styles.terms}>
          （十一） 您不得利用本服務從事侵害他人合法權益之行為，否則本公司有權拒絕提供本服務，且您應承擔所有相關法律責任，因此導致本公司、本公司僱員或其他會員受損者，您應承擔賠償責任。包括但不限於：
          <div style={styles.lists}>
            1. 上載、張貼、公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案或言行舉止於本服務上；
          </div>
          <div style={styles.lists}>
            2. 侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利；
          </div>
          <div style={styles.lists}>
            3. 違反依法律或契約所應負之保密義務；
          </div>
          <div style={styles.lists}>
            4. 冒用他人名義使用本服務；
          </div>
          <div style={styles.lists}>
            5. 上載、張貼、傳輸或散佈任何含有電腦病毒或任何對電腦軟、硬體產生中斷、破壞或限制功能之程式碼之資料；
          </div>
          <div style={styles.lists}>
            6. 從事不法交易行為或張貼虛假不實、引人犯罪之訊息；
          </div>
          <div style={styles.lists}>
            7. 提供賭博資訊或以任何方式引誘他人參與賭博；
          </div>
          <div style={styles.lists}>
            8. 偽造訊息來源或以任何方式干擾傳輸來源之認定；
          </div>
          <div style={styles.lists}>
            9. 干擾或中斷本服務或伺服器或連結本服務之網路，或不遵守連結至本服務之相關需求、程序、政策或規則等；
          </div>
          <div style={styles.lists}>
            10. 從事任何可能含有電腦病毒或是可能侵害本服務系統、資料之行為。
          </div>
          <div style={styles.lists}>
            11. 追蹤他人或其他干擾他人或為前述目前蒐集或儲存他人之個人資訊；
          </div>
          <div style={styles.lists}>
            12. 其他本公司有正當理由認為不適當之行為。
          </div>
        </div>
        <div style={styles.terms}>
          （十二） 會員違反前條規定者，在必要時，您同意本公司無需事先通知會員即可終止提供本服務，並暫停、關閉或刪除會員帳號中所有相關資料及檔案。
        </div>
        <div style={styles.terms}>
          （十三） 如您需要註銷帳號，經本公司審核同意註銷該帳號後，即表明本公司與您之間的條款已解除，但您仍應對您使用本服務期間的行為承擔可能的違約或損害賠償責任，同時本公司仍可保有您的相關資訊。
        </div>
        <div style={styles.terms}>
          （十四） 您明白知悉且了解本服務及本服務所包含之軟體，可能含有使數位資料得以被保護之安全元件，使用該等資料必須依照本公司或提供該內容予本服務之內容提供者所定之使用規範。
        </div>
        <div style={styles.terms}>
          （十五） 您不得試圖破壞或規避任何內含於本服務之使用規範。任何未經合法授權之重製、發行、散布或公開展示本服務所提供之資料之全部或一部，均被嚴格禁止。
        </div>
      </React.Fragment>
    ),
  },
  {
    title: '免責條款',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          （一） 實際的租借關係存在於出租方與租借方間，Oh!Phire不是契約的當事方，會員
          了解並同意，透過本服務所達成之任何交易，均由會員負全責，本公司對其交
          易過程及商品本身，均不負任何擔保及連帶賠償責任；如有任何瑕疵給付之情
          形，均與本公司無關。出租方了解並同意，租借方會員於共享交易中所為之不
          當使用等有害出租方權益及商品本身之行為，均由租借方會員負全責，本公司
          不負任何擔保及連帶賠償責任。
        </div>
        <div style={styles.terms}>
          （二） 會員在本服務上所刊載之資訊、文字、照片、圖形、信息或其他資料，本公司
          對於其內容之真實性、合法性、即時性、完整性等，不負任何明示或默示之承
          諾或擔保，均由提交內容提供者自負責任。
        </div>
        <div style={styles.terms}>
          （三） 您了解並同意我們使用第三方金流/貨運系統為您服務。您瞭解並同意，本服務
          之收費係透過系統平台業者合作之金流機構/ 代收費營運商託收等付款方式與
          第三方貨運業者配送與收貨，您通過此類付款方式付費/使用貨運服務可能存在
          一定之風險，包括但不限於 ：不法份子利用您的帳戶或信用卡等支付工具進行
          違法活動 、運送及收貨過程之時程延誤，該等風險均會給您造成相應之損害，
          此損害將由您自行負擔向侵權方追究侵權與賠償之責任。
        </div>
        <div style={styles.terms}>
          （四） 若我們無法通過您選擇之支付方式收費，無論係因為任何原因，我們有權暫停
          或終止對您的服務，並依據您所提交之會員資料對您進行刑事或民事責任之追
          溯或請求。
        </div>
        <div style={styles.terms}>
          （五）  除本條款另有約定外，本公司就本服務不負任何明示或默示之擔保責任，且不
          保證本服務之穩定、安全、無誤、及不中斷。若會員無法使用本服務時，除係
          基於本公司之故意或重大過失所導致者外，本公司不負任何賠償或補償責任。
        </div>
        <div style={styles.terms}>
          （六） 除本條款另有約定外，會員因使用或無法使用本服務而產生之任何間接、特
          殊、附帶、衍生或經濟性損害，不負賠償責任。
        </div>
        <div style={styles.terms}>
          （七）會員同意使用本服務係基於會員之個人意願，並同意自負任何風險，包括但不
          限於因透過本服務媒合所進行之任何交易糾紛（惟本公司將協助解決交易糾紛
          所生之爭議，參照本契約出租方條款第12條及租借方條款第12條”歸還商品發生
          毀損”），或自本服務中獲得之資料導致會員之行動終端設備損壞，或是發生任
          何資料流失等結果。
        </div>
        <div style={styles.terms}>
          （八）  會員理解其承擔貨物運輸所固有的全部風險(倘由出租方寄件出貨時由出租方承
          擔，由租借方寄件歸還商品時則係租借方承擔)，如果在運輸期間商品損壞、丟
          失、或交付失敗，則會員同意本公司對由此引起之損害、費用或成本概不負責
          ，會員將與貨物運輸提供商討論此問題。
        </div>
      </React.Fragment>
    ),
  },
  {
    title: '系統維護及服務中斷',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          （一） 如發生下列情形之一時，我們有權可以臨時、暫時停止、中斷提供本服務，所有會員不得因此請求賠償或補償：
          <div style={styles.items}>
            1. 電腦軟硬體設備與電子通信設備進行必要之保養及施工、突發性之電腦軟硬體設備與電子通信設備故障；
          </div>
          <div style={styles.items}>
            2. 對本服務相關軟硬體設備進行搬遷、更換、升級、保養或維修時；
          </div>
          <div style={styles.items}>
            3. 會員有任何違反法令、本約定條款或本服務相關約定之情形；
          </div>
          <div style={styles.items}>
            4. 因第三人之行為、非本公司所得以控制之事項、或其他不可歸責於本公司之事由所致之服務暫停、中斷或無法正常運作；
          </div>
          <div style={styles.items}>
            5. 天災或其他不可抗力所致之服務暫停、中斷或無法正常運作；
          </div>
          <div style={styles.items}>
            6. 因本公司自身業務考量，包括但不限於商業合併、結束營業等。
          </div>
        </div>
        <div style={styles.terms}>
          （二） 本服務停止、中斷或發生故障等現象時，可能造成會員使用上的不便、資料喪失、錯誤、遭人篡改或其他經濟上損失等情形。會員於使用本服務時宜定期自行採取防護措施與資料備份，
          並隨時留意本公司相關公告訊息。本公司對於會員因使用（或無法使用）本服務而造成的損害概不負任何賠償責任。
        </div>
      </React.Fragment>
    ),
  },
  {
    title: '帳戶安全',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          （一） 任一會員就使用本服務所取得的帳號和密碼，僅供會員本人使用，該帳號不可
          轉讓、贈與或繼承。會員決定不再使用該帳號時，應向本公司申請註銷該帳
          號。會員必須負妥善保管跟保密的義務， 且不得以有償或無償之方式透露或提
          供予任何第三人登錄或使用，任何經由輸入該組號及密碼所為的所有行為，應
          由註冊該帳號之會員自負責任。
        </div>
        <div style={styles.terms}>
          （二） ) 會員如果發現或懷疑其帳號或密碼被三人冒用、未經您授權而進行付費服務之
          訂閱或不當使用，應立即通知我們以便本公司及時採取適當的因應措施 ；但上
          述因應措施不得解釋為本公司因此而明示或默示對會員負有任何形式之賠償或
          補償義務。 若係因為會員之保管疏失或未遵守本條定使用帳號密碼，致您的帳
          號或密碼遭他人不當使用所生之任何損害，本公司不負任何法律責任。
        </div>
        <div style={styles.terms}>
          （三） 為了保障會員權益，提醒您，若有於其他非本人持有之設備使用本服務後，務
          必登出帳號，假如會員與他人共用電腦或任何裝置，務必要關閉瀏覽器或本服
          務，以防止他人讀取您的個人資料。
        </div>
      </React.Fragment>
    ),
  },
  {
    title: '智慧財產權的保護',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          （一） 本服務所使用之軟體或程式、網上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、程式架構、App或網站畫面的安排、網站架構、頁面設計、資訊內容、提交內容等，均由本公司或其他權利人依法擁有其智慧財產權，
          包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。
          任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，必須依法取得本公司或其他權利人的事前書面同意。
          尊重智慧財產權是您應盡的義務，如有違反，會員應對本公司負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
        </div>
        <div style={styles.terms}>
          （二）尊重智慧財產權是您應盡的義務，如有違反，會員應對本公司負損害賠償責任
          （包括但不限於訴訟費用及律師費用等）。
        </div>
        <div style={styles.terms}>
          （三） 對於您提供到本服務之提交/上傳之內容，本公司並不聲明擁有所有權。然而，您在張貼、上傳、輸入、提供或提交提交內容時，即表示永久且不可撤回的授予了本公司及本公司之關係企業，
          得基於行銷及商業目的，使用您提交內容的權利，權利包含但不限於對於您的提交內容進行複製、散布、傳輸、公開展示、公開演出、重製、編輯、翻譯、重置形式，以及公開與您的名字相互連結。
        </div>
        <div style={styles.terms}>
          （四） 使用您在此所提交內容對您並無產生任何報償。本公司亦沒有張貼或使用提交內容的義務，且可以自行隨時移除任何提交內容。本公司對您所提交內容不負任何責任。
        </div>
        <div style={styles.terms}>
          （五） 您擔保提供到本服務之提交/上傳之內容，均為合法且無侵害第三人包括智慧財產權等任何權利；倘本公司經第三人檢舉/申訴而發現您所提交/上傳之內容為侵權內容或無權授權之情況時，本公司得於不另行通知您的情況下，
          將您提交/上傳之內容予以下架，並於必要時暫停您對於本服務使用之權限。
        </div>
        <div style={styles.terms}>
          （六） 您在張貼、上傳、輸入、提供或提交內容時，您保證並表示您擁有所有提交內容的權利，包括但不限於所有您能夠提供、張貼、上傳、輸入或提交提交內容的所需權利，否則若因此遭第三方主張侵害其權利者，本公司除得隨時移除提交內容外，
          如因此導致本公司受損者，您應就本公司所受之損害負賠償責任（包括但不限於訴訟費用及律師費用等），您同意不予異議。
        </div>
        <div style={styles.terms}>
          （七） 若提交內容遭任何第三人主張侵權，本服務有權立即將該提交內容移除。
        </div>
        <div style={styles.terms}>
          （八） 若您認為您的智慧財產權受到侵害，請您提供以下資訊予本公司：
          <div style={styles.items}>
            1. 您的正確姓名資料與聯絡方式，並同意將您舉報的資料提供給被檢舉人。
          </div>
          <div style={styles.items}>
            2. 能證明您為智慧財產權人之證明。
          </div>
          <div style={styles.items}>
            3. 您所主張受侵害之智慧財產權之敘述，以及受侵害之證明資料。
          </div>
          <div style={styles.items}>
            4. 請注意，您的檢舉需依照本服務準據法下之相關法令為之，若未依前開說明敘明檢舉/申訴原因及法律基礎，本公司得要求您另行提出補充說明，或不為任何處置。
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: '個人資料之保護',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          （一）當會員註冊或使用本服務時，本公司會請您提供相關的個人資料或其他資料；
          本公司所蒐集的所有個人資料或其他資料，將依個人資料保護法及相關法令之
          規定，只就提供本服務及提升本服務使用體驗所必要之特定目的下進行個人資
          料之蒐集、處理及利用，不會任意對其他第三者揭露； 本網站不會將其做為超
          出蒐集之特定目的以外的用途，亦不會任意對其他第三者揭露；關於更詳盡的
          說明請參閱本服務的隱私權政策。
          {privacyLink}
          。
        </div>
        <div style={styles.terms}>
          （二）本服務對於所有註冊會員進行Passbase個人資料認證程序，請注意，所有會員
          均需進行Passbase個人資料認證程序始得使用本服務。您了解並同意，當您進
          行Passbase會員身分認證時，您即同意本平台與Passbase認證服務對於您個人資
          料（包括：Email、身分證或護照、臉部辨識短片等）之蒐集、處理及利用；您
          了解前述Passbase認證服務對於您個人資料蒐集、處理及利用之特定目的僅限
          於驗證您註冊之個人資料是否正確，除為提供本服務或協助解決因本服務而生
          之消費糾紛等目的外，本服務承諾不會將透過Passbase認證服務蒐集到您的個
          人資料進行其他利用。惟您了解並同意，本公司對於Passbase認證服務蒐集、
          處理及利用您個人資料之所有方式、程序及資料儲存等認證服務之安全性均不
          負擔保責任，您於註冊會員選擇使用Passbase認證服務時應自行注意個人資料
          傳輸至網路之相關風險，並詳閱Passbase之使用者條款等規範及運作方式。
          {privacyLink}
          。
        </div>
      </React.Fragment>
    ),
  },
  {
    title: '拒絕或終止使用',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          （一）會員同意本公司得自行判斷因任何理由，包含但不限於一定期間未使用、法院
          或政府機關命令、本服務無法繼續或服務內容實質變更、無法預期之技術或安
          全因素或問題、您所為詐欺或違法行為、未依約支付費用，或其他本公司認為
          會員已經違反本服務條款的明文規定及精神等事由，而終止或限制會員使用帳
          號（或其任何部分）或本服務之使用，並將本服務內任何關於您或由您提供之
          會員內容加以移除並刪除。倘您因前述事由，造成本公司或第三人因此受到損
          害，應負賠償責任（包括但不限於訴訟費用及律師費用等）。
        </div>
        <div style={styles.terms}>
          （二） 會員同意本公司亦得在自行之考量，於通知或未通知之情形下，隨時終止或限
          制會員使用本服務或其任何部分。會員承認並同意前開終止或限制，本公司得
          立即關閉、刪除或限制存取會員的帳號及會員帳號中全部或部分相關資料及檔
          案，及停止本服務全部或部分之使用。此外，會員同意若本服務之使用被終止
          或限制時，本公司對會員或任何第三人均不承擔責任。
        </div>
      </React.Fragment>
    ),
  },
  {
    title: '準據法、管轄法院及其他',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          （一）造訪或使用本網站或是寄送電子郵件到本公司構成電子通訊。您同意接受電子
          通訊，您也同意我們透過電子通訊或網頁所提供給您的所有協議、通知、揭露
          以及其他形式的溝通訊息（下稱「訊息內容」），您均應受到前開訊息內容之
          拘束。
        </div>
        <div style={styles.terms}>
          （二） 如果本服務條款的任何部分（包括但不限於上述的免責保證以及有限賠償責
          任），依相關法律規定被判定為無效或無法執行時，就該無效或無法執行的部
          分，將被本服務條款其他有效可執行且與原規範意旨最相符的條款所取代或準
          用其他仍屬有效之條款，以處理或規範該被認定無效或無法執行原規範事項；
          前述被判定為無效或無法執行之條款或內容，不影響本服務條款的其餘條款之
          有效性。
        </div>
        <div style={styles.terms}>
          （三）本服務條款、隱私權政策及其相關使用規範、辦法、處理原則、政策、及相關服務說明等，均以中華民國法律為準據法。
        </div>
        <div style={styles.terms}>
          （四）會員與本公司因本服務、本服務條款、隱私權政策或其相關使用規範、辦法、處理原則、政策、及相關服務說明所生之爭議，如因此而訴訟，以台灣台中地方法院為第一審合意管轄法院。
        </div>
        <div style={styles.terms}>
          （五）本使用者說明各條標題，僅為查閱而設，不影響有關條款之解釋、說明及瞭解。
        </div>
      </React.Fragment>
    ),
  },
  {
    title: '條款之變更',
    content: (
      <React.Fragment>
        <div style={styles.terms}>
          本平台得隨時透過在本網站上公布修訂版服務條款的方式修改本服務條款之內容，
          並同時以電子郵件、簡訊等方式通知您。未進行前述公告及通知之變更無效。當您在
          修訂版公布後繼續使用本網站，即視為您接受此修訂版條款。
        </div>

      </React.Fragment>
    ),
  },
];
const MemberTerms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          <InfoTitle
            leftTitle="CUSTOMER"
            leftSubtitle="顧客"
            rightTitle="Member Terms"
            rightSubtitle="會員條款"
            contentStyle={styles.termsContainer}
            content={termsIntro}
          />

          <img
            src={Assets.memberTerms}
            alt=""
            style={styles.pic}
          />

          {termsData.map((item, index) => (
            <div key={item.title}>
              <div style={styles.leftTermsTitle}>
                <div style={styles.subtitle}>{item.title}</div>
                <div style={styles.leftTermsNumber}>{String(index + 1).padStart(2, '0')}</div>
              </div>
              <div style={styles.rightTermsContent}>
                {item.content}
              </div>
              <hr style={styles.hr} />
            </div>
          ))}
        </div>
      </Default>
      <Mobile>
        <div style={styles.mobileContainer}>
          <InfoTitle
            leftTitle="CUSTOMER"
            leftSubtitle="顧客"
            rightTitle="Member Terms"
            rightSubtitle="會員條款"
            contentStyle={styles.termsContainer}
            content={termsIntro}
          />

          <img
            src={Assets.memberTerms}
            alt=""
            style={styles.picMobile}
          />
          {termsData.map((item, index) => (
            <div key={item.title}>
              <div style={styles.titleMobileContainer}>
                <div style={styles.subtitle}>{item.title}</div>
                <div style={styles.leftTermsNumber}>{String(index + 1).padStart(2, '0')}</div>
              </div>
              <div style={styles.contentMobileContainer}>
                {item.content}
              </div>
              <hr style={styles.hrMobile} />
            </div>
          ))}
        </div>
      </Mobile>
    </React.Fragment>
  );
};


export default MemberTerms;
