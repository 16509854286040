import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  Icon,
} from 'antd';
import imageCompression from 'browser-image-compression';
import API from '../API';

const Uploader = ({
  onFileUploaded,
  childComponent,
  disabled,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const beginUpload = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    setIsUploading(true);
    const compressFile = await imageCompression(file, options);
    const formData = new FormData();
    formData.append('file', compressFile);
    const result = await API.uploadFile(formData);
    setIsUploading(false);
    if (result.status === 200) {
      onFileUploaded(result.data);
    }
    return false;
  };

  return (
    <Upload
      accept="image/*"
      fileList={[]}
      beforeUpload={(file) => {
        beginUpload(file);
        return false;
      }}
      disabled={isUploading || disabled}
    >
      {isUploading ? <Icon type="loading" /> : childComponent }
    </Upload>
  );
};

Uploader.propTypes = {
  onFileUploaded: PropTypes.func.isRequired,
  childComponent: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

Uploader.defaultProps = {
  disabled: false,
};

export default Uploader;
