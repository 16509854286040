import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProductIssueReportModalComponent from './ProductIssueReportModalComponent';

const ProductIssueReportModalComponentContainer = ({
  onModalClose,
  isVisible,
}) => (
  <ProductIssueReportModalComponent
    isVisible={isVisible}
    onModalClose={onModalClose}
  />
);


ProductIssueReportModalComponentContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
  }, dispatch),
});

export default connect(
  mapDispatchToProps,
)(ProductIssueReportModalComponentContainer);
