import React from 'react';
import ProvidePage from './ProvidePage';
import Assets from '../../../../constants/Assets';

const data = [
  { imgUrl: Assets.provideSell },
  { imgUrl: Assets.provideLending },
  { imgUrl: Assets.provideLendingSell },
];

const ProvidePageContainer = () => (
  <ProvidePage data={data} />
);


export default ProvidePageContainer;
