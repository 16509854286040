import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as R from 'ramda';
import { Link } from '@reach/router';
import Assets from '../constants/Assets';
import Default from './RWDComponent/Default';
import Mobile from './RWDComponent/Mobile';
import { FontSize } from '../constants/FontSize';
import { rentAmountCalculate } from '../utils/rentAmountCalculate';
import { typeId } from '../constants/Type';
import amountPattern from '../utils/AmountPattern';
import bagOrClothesPath from '../utils/bagOrClothesPath';

const Item = styled.div`
  position: relative;
  width: 230px;
  height: 420px;
  vertical-align: top;
  overflow: hidden;
  cursor: pointer;
  margin: 60px 20px;
  display: inline-block;
  background-color: white;
  color: black;
  text-align: center;
  &:hover{
    opacity: 0.8
  }
`;

const ItemMobile = styled.div`
  position: relative;
  width: 140px;
  height: 380px;
  vertical-align: top;
  padding: 0 10px;
  overflow: hidden;
  cursor: pointer;
  margin: 10px 10px;
  display: inline-block;
  fontFamily: Baskerville-Old-Facef;
  background-color: white;
  color: black;
  &:hover{
    opacity: 0.8
  }
`;

// ToDo: Hidden this bag rent out not info
// const UnavailableText = styled.div`
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 160px;
//     height: 360px;
//     background-color: #0D0D0D88;
//     color: white;
//     &:after{
//       content: ' ';
//       position: absolute;
//       left: 20px;
//       bottom: 60px;
//       width: 120px;
//       height: 1px;
//       background-color: #F1F2F2;
//     }
//     & > div:first-child {
//       font-weight: 600;
//       font-size: 20px;
//       text-align: left;
//       line-height: 30px;
//       margin-top: 230px;
//       position: absolute;
//       left: 30px;
//     }
//     & > div:nth-child(2) {
//       width: 100%;
//       font-size: 15px;
//       text-align: right;
//       padding: 5px 30px;
//       margin-top: 300px;
//     }
// `;

// const UnavailableTextMobile = styled.div`
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 180px;
//     height: 400px;
//     background-color: #0D0D0D88;
//     color: white;
//     &:after{
//       content: ' ';
//       position: absolute;
//       left: 11px;
//       bottom: 180px;
//       width: 140px;
//       height: 1px;
//       background-color: #F1F2F2;
//     }
//     & > div:first-child {
//       font-weight: 600;
//       font-size: 20px;
//       text-align: left;
//       line-height: 30px;
//       margin-top: 150px;
//       position: absolute;
//       left: 12px;
//     }
//     & > div:nth-child(2) {
//       width: 100%;
//       font-size: 12px;
//       text-align: right;
//       padding: 5px 30px;
//       margin-top: 220px;
//     }
// `;

const styles = {
  image: {
    width: '100%',
    height: '260px',
    objectFit: 'contain',
  },
  imageMobile: {
    width: '100%',
    height: '220px',
    objectFit: 'contain',
  },
  tags: {
    textAlign: 'center',
    padding: '5px 20px',
    fontSize: FontSize.subTitle,
    fontFamily: 'Baskerville-Old-Facef',
  },
  text: {
    fontSize: FontSize.overline,
    textAlign: 'center',
    fontWeight: '400',
    padding: '0px 20px',
  },
  originalPrice: {
    textDecoration: 'line-through',
    textAlign: 'center',
    color: 'grey',
  },
  typeContainer: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    textAlign: 'center',
    backgroundColor: '#E6E7E8',
    color: '#939292',
    width: 'auto',
    minWidth: '40px',
    padding: '3px 10px',
    fontSize: FontSize.overline,
  },
  opMangerIconContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  nullOpMangerIconContainer: {
    width: '100%',
    height: '22px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  opMangerIcon: {
    width: '90px',
    height: 'auto',
    objectFit: 'contain',
  },
  allNewIcon: {
    width: '100%',
    heigh: '100%',
    objectFit: 'contain',
  },
  allNewIconContainer: {
    width: '40px',
    height: '40px',
    margin: '0px 5px',
    marginBottom: '5px',
    position: 'absolute',
    left: '20px',
  },
  allNewIconMobileContainer: {
    width: '30px',
    height: '30px',
    margin: '0px 5px',
    marginBottom: '5px',
    position: 'absolute',
    left: '0px',
  },
};

const tagSortMethod = (a, b) => {
  const tagCategoryIdOfA = R.pathOr(0, ['tagCategoryId'], a);
  const tagCategoryIdOfB = R.pathOr(0, ['tagCategoryId'], b);
  if (tagCategoryIdOfA !== tagCategoryIdOfB) return tagCategoryIdOfA - tagCategoryIdOfB;
  const orderOfA = R.pathOr(0, ['order'], a);
  const orderOfB = R.pathOr(0, ['order'], b);
  return orderOfA - orderOfB;
};

const BagItemHomePage = ({
  style,
  isClothes,
  ...restOfBagData
}) => {
  const id = R.pathOr('', ['id'], restOfBagData);
  const bagNo = R.pathOr('', ['bagNo'], restOfBagData);
  const frontPicUrl = R.pathOr(null, ['frontPic', 'uri'], restOfBagData);
  const rentAmountPerDay = R.path(['rentAmountPerDay'], restOfBagData) || null;
  const originalPrice = R.pathOr(0, ['originalPrice'], restOfBagData);
  const tags = R.pathOr('', [0, 'name'], R.pathOr([], ['tags'], restOfBagData).sort(tagSortMethod));
  const washAmount = R.pathOr(0, ['washAmount'], restOfBagData);
  const tagsData = R.pathOr([], ['tags'], restOfBagData);
  const clothesTag = tagsData.find(item => item.tagCategoryId === 4);
  const clothesTagName = R.pathOr('', ['name'], clothesTag);
  // TODO: The rent times just comment in the early time.
  // const rentCount = R.pathOr(0, ['rentCount'], restOfBagData);
  const commodityType = R.pathOr(1, ['commodityTypeId'], restOfBagData);
  const isOhPhireManage = R.pathOr(false, ['isOhPhireManage'], restOfBagData);
  const newLevel = R.pathOr(0, ['newLevel'], restOfBagData);
  const type = R.pathOr(1, ['typeId'], restOfBagData);
  // eslint-disable-next-line no-console

  const renderAmount = () => {
    const renderOriginPrice = () => {
      if (originalPrice > 0) {
        return (
          <div style={styles.originalPrice}>
            {`NT$ ${amountPattern(originalPrice)}`}
          </div>
        );
      }
      return null;
    };

    if (type === typeId.buy) {
      return (
        <React.Fragment>
          {renderOriginPrice()}
          {R.pathOr(0, ['buyoutAmount'], restOfBagData) ? (
            <div>
              {`購買 NT$ ${amountPattern(R.pathOr(0, ['buyoutAmount'], restOfBagData))}`}
            </div>
          ) : null}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div>
          {`租借 $${rentAmountPerDay ? Math.ceil(
            rentAmountCalculate(rentAmountPerDay, 4, false, washAmount),
          ) : '-'}`}
        </div>
        {renderOriginPrice()}
      </React.Fragment>
    );
  };

  const bagContentLayout = (
    <React.Fragment>
      { frontPicUrl ? (
        <React.Fragment>
          <Default>
            <img style={styles.image} src={frontPicUrl} alt="" />
          </Default>
          <Mobile>
            <img style={styles.imageMobile} src={frontPicUrl} alt="" />
          </Mobile>
        </React.Fragment>
      ) : null}
      {isOhPhireManage ? (
        <div
          style={styles.opMangerIconContainer}
        >
          <img
            src={Assets.opMangerIcon}
            style={styles.opMangerIcon}
            alt=""
          />
        </div>
      ) : (
        <div
          style={styles.nullOpMangerIconContainer}
        />
      )}
      <div style={styles.tags}>{commodityType === 2 ? clothesTagName : tags}</div>
      <div style={styles.text}>
        {renderAmount()}
      </div>
    </React.Fragment>
  );

  return (
    bagNo ? (
      <Link to={`${bagOrClothesPath(commodityType)}/${bagNo}`} key={id}>
        <Default>
          <Item style={style}>
            <div style={styles.allNewIconContainer}>
              {newLevel === 10 ? (
                <img
                  alt=""
                  style={styles.allNewIcon}
                  src={Assets.allNewCircle}
                />
              ) : null}
            </div>
            {bagContentLayout}
          </Item>
        </Default>
        <Mobile>
          <ItemMobile style={style}>
            <div style={styles.allNewIconMobileContainer}>
              {newLevel === 10 ? (
                <img
                  alt=""
                  style={styles.allNewIcon}
                  src={Assets.allNewCircle}
                />
              ) : null}
            </div>
            {bagContentLayout}
          </ItemMobile>
        </Mobile>
      </Link>
    ) : (
      <React.Fragment>
        <Default>
          <Item style={style}>
            <div style={styles.allNewIconContainer}>
              {newLevel === 10 ? (
                <img
                  alt=""
                  style={styles.allNewIcon}
                  src={Assets.allNewCircle}
                />
              ) : null}
            </div>
            {bagContentLayout}
          </Item>
        </Default>
        <Mobile>
          <ItemMobile style={style}>
            <div style={styles.allNewIconMobileContainer}>
              {newLevel === 10 ? (
                <img
                  alt=""
                  style={styles.allNewIcon}
                  src={Assets.allNewCircle}
                />
              ) : null}
            </div>
            {bagContentLayout}
          </ItemMobile>
        </Mobile>
      </React.Fragment>
    )
  );
};

BagItemHomePage.propTypes = {
  style: PropTypes.shape({}),
  isClothes: PropTypes.bool,
};

BagItemHomePage.defaultProps = {
  style: {},
  isClothes: false,
};

export default BagItemHomePage;
