import * as R from 'ramda';
import { createSelector } from 'reselect';
import { themeStateSelector } from '../../lib/rootSelector';

export const reduxStatusSelector = createSelector(
  themeStateSelector,
  result => R.path(['reduxStatus'], result),
);

export const reduxErrorSelector = createSelector(
  themeStateSelector,
  result => R.path(['error', 'message'], result),
);

export const themeListSelector = createSelector(
  themeStateSelector,
  result => R.path(['data'], result),
);

export const themeBagSelector = createSelector(
  themeStateSelector,
  result => R.path(['themeBags'], result),
);
