import api from '../../lib/configAxios';

export default {
  getThemeList: async () => api.get(
    `Themes?filter=${encodeURIComponent(JSON.stringify({
      include: 'resource',
      order: 'order asc',
      where: {
        isActive: true,
      },
    }))}`,
  ),

  getThemeBag: async ({
    id, filter,
  }) => api.get(
    `Themes/${id}/bags?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),
};
