import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import * as R from 'ramda';
import moment from 'moment';
import FollowComponent from '../../../../components/FollowComponent';
import { Colors } from '../../../../constants/Color';
import Assets from '../../../../constants/Assets';

const styles = {
  mainContainer: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 20,
    borderBottom: `1px ${Colors.separateLine} solid`,
  },
  leftContainer: {
    width: '10%',
  },
  rightContainer: {
    width: '90%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 20,
  },
  avatar: {
    width: 50,
    height: 50,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'fit-content',
  },
  name: {
    minWidth: 100,
    marginRight: 20,
    fontSize: 18,
    fontFamily: 'Baskerville, serif',
  },
  comment: {
    wordBreak: 'break-all',
    marginTop: 20,
    width: '100%',
    height: 'fit-content',
    maxHeight: 60,
    overflowY: 'hidden',
  },
  time: {
    marginTop: 20,
    fontSize: 13,
  },
};

const OutfitCommentComponent = ({
  outfitComment,
}) => (
  <div style={styles.mainContainer}>
    <div style={styles.leftContainer}>
      <Avatar
        icon="user"
        size={50}
        style={styles.avatar}
        alt=""
        src={R.pathOr(null, ['user', 'avatar', 'uri'], outfitComment) || Assets.AvatarIcon}
      />
    </div>
    <div style={styles.rightContainer}>
      <div style={styles.nameContainer}>
        <div style={styles.name}>{R.pathOr('', ['user', 'communityName'], outfitComment)}</div>
        <FollowComponent
          targetId={R.pathOr(0, ['userId'], outfitComment)}
        />
      </div>
      <div style={styles.comment}>{R.pathOr('', ['comment'], outfitComment)}</div>
      <div style={styles.time}>
        {`On ${moment(R.pathOr('', ['createTime'], outfitComment))
          .format('MM/DD/YYYY')} at 
          ${moment(R.pathOr('', ['createTime'], outfitComment))
          .format('HH:MM')}`}
      </div>
    </div>
  </div>
);


OutfitCommentComponent.propTypes = {
  outfitComment: PropTypes.object,
};

OutfitCommentComponent.defaultProps = {
  outfitComment: {},
};

export default OutfitCommentComponent;
