import React from 'react';
import PropTypes from 'prop-types';
import InfoModalComponent from '../../../../../components/InfoModalComponent';
import Default from '../../../../../components/RWDComponent/Default';
import Mobile from '../../../../../components/RWDComponent/Mobile';
import { Colors } from '../../../../../constants/Color';
import Button from '../../../../../components/Button';
import Assets from '../../../../../constants/Assets';

const styles = {
  mainContainer: {
    padding: 30,
    width: 700,
    height: 540,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
  },
  mainMobileContainer: {
    padding: 30,
    width: '95vw',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
  },
  titleMainContainer: {
    width: '100%',
    marginTop: '50px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editTitle: {
    fontSize: 21,
    color: 'black',
    weight: 100,
  },
  dividedLine: {
    margin: '20px',
    color: 'grey',
  },
  withdrawOrderText: {
    fontSize: '18px',
    margin: '20px 0',
  },
  withdrawNote: {
    fontSize: '12px',
    margin: '15px',
    lineHeight: '30px',
  },
  numberText: {
    color: Colors.introRightTitle,
    fontSize: '22px',
    fontWeight: 700,
    margin: '0px 10px',
  },
  confirmButton: {
    marginTop: '20px',
    width: '160px',
    height: '50px',
    backgroundColor: 'white',
    border: `1px solid ${Colors.introRightTitle}`,
    color: 'grey',
  },
  icon: {
    marginBottom: '20px',
    width: '80px',
  },
};

const WithdrawSendSuccussComponent = ({
  isVisible,
  onModalClose,
  requestOrderNumber,
  withdrawAmount,
}) => {
  const content = (
    <React.Fragment>
      <div style={styles.titleMainContainer}>
        <div style={styles.titleContainer}>
          <div style={styles.editTitle}>
            <img alt="" style={styles.icon} src={Assets.withdrawSuccessIcon} />
            <div>
              租金提領完成
            </div>
          </div>
        </div>
        <hr style={styles.dividedLine} />
        <div style={styles.withdrawOrderText}>
          你已成功提領
          <span style={styles.numberText}>{requestOrderNumber}</span>
          筆訂單
        </div>
        <div style={styles.withdrawOrderText}>
          金額共 NT.
          <span style={styles.numberText}>{withdrawAmount}</span>
        </div>
        <div style={styles.withdrawNote}>
          款項將於5個工作日入帳(列假日除外)
          <br />
          地球因你的共享變得更美麗了!
        </div>
      </div>
      <Button
        text="我知道了"
        style={styles.confirmButton}
        onClick={onModalClose}
      />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainContainer}>
              {content}
            </div>
          )}
          containerStyle={{ paddingTop: 30 }}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainMobileContainer}>
              {content}
            </div>
          )}
          containerStyle={{ paddingTop: 30 }}
        />
      </Mobile>
    </React.Fragment>
  );
};

WithdrawSendSuccussComponent.propTypes = {
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  requestOrderNumber: PropTypes.number,
  withdrawAmount: PropTypes.number,
};

WithdrawSendSuccussComponent.defaultProps = {
  isVisible: false,
  requestOrderNumber: 0,
  withdrawAmount: 0,
};

export default WithdrawSendSuccussComponent;
