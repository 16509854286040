import api from '../../lib/configAxios';

export default {
  orderTransaction: async data => api.post(
    'Orders/transaction', data,
  ),

  ZerCardReserveEc: async data => api.post(
    'Orders/ZeroCard/reserveEc', data,
  ),
};
