import React, { useState } from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { receiptTypeId } from '../../../constants/ReceiptType';
import { shippingTypeId } from '../../../constants/ShippingType';
import 'react-day-picker/lib/style.css';
import Default from '../../../components/RWDComponent/Default';
import Mobile from '../../../components/RWDComponent/Mobile';
import { FontSize } from '../../../constants/FontSize';
import Assets from '../../../constants/Assets';

const styles = {
  mainContainer: {
    width: '100%',
  },
  fieldTableContainer: {
    width: '100%',
    tableLayout: 'fixed',
  },
  img: {
    width: '100%',
    objectFit: 'contain',
    height: 160,
    backgroundColor: 'white',
  },
  functionButton: {
    cursor: 'pointer',
    outline: 'none',
    border: '1px solid #AE4200',
    color: 'black',
    width: 80,
    height: 27,
    fontWeight: 200,
  },
  fieldItem: {
    width: '10%',
    height: '70px',
  },
  autoInputRecipient: {
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 70,
  },
  autoInputRecipientCheckbox: {
    width: 15,
    height: 15,
  },
  autoInputRecipientCheckboxText: {
    marginLeft: 10,
  },
  inputBox: {
    marginBottom: '20px',
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid black',
    width: '200px',
    height: '30px',
    padding: '5px 0',
    fontSize: FontSize.body,
  },
  inputBoxReadOnly: {
    marginBottom: '20px',
    outline: 'none',
    border: 'none',
    borderBottom: '1px solid black',
    width: '200px',
    fontSize: FontSize.body,
    backgroundColor: '#DDDDDD',
    opacity: 0.8,
    cursor: 'default',
  },
  instalmentContainer: {
    width: '100%',
    border: '1px solid grey',
    borderRadius: '10px',
    marginLeft: '5px',
    padding: '15px',
  },
  installNumber: {
    margin: '0px 10px 0px 3px',
  },
  instalmentNumberContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
    flexWrap: 'wrap',
  },
  creditIcon: {
    objectFit: 'cover',
    width: '40px',
    margin: '0px 2px',
  },
  zeroCardIcon: {
    objectFit: 'cover',
    width: '25px',
    margin: '0px 2px',
  },
  creditContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '5px 0px',
    width: '400px',
  },
  creditIconContainer: {
    marginRight: '0px',
  },
  creditText: {
    width: '120px',
  },
  couponContainer: {
    marginTop: 20,
  },
  couponItemContainer: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inputCoupon: {
    border: '2px solid #bbb',
    width: '70%',
    height: 30,
    fontSize: 15,
    marginLeft: 20,
    padding: '0px 5px',
    color: '#974107',
    outline: 'none',
  },
  inputCouponDisable: {
    border: '2px solid #bbb',
    width: '70%',
    height: 30,
    fontSize: 15,
    marginLeft: 20,
    padding: '0px 5px',
    color: '#974107',
    outline: 'none',
    cursor: 'not-allowed',
  },
  inputCouponMobile: {
    border: '2px solid #bbb',
    width: '40%',
    marginLeft: 5,
    height: 30,
    fontSize: 13,
    padding: '0px 5px',
    color: '#974107',
    outline: 'none',
  },
  inputCouponMobileDisable: {
    border: '2px solid #bbb',
    width: '40%',
    marginLeft: 5,
    height: 30,
    fontSize: 13,
    padding: '0px 5px',
    color: '#974107',
    outline: 'none',
    cursor: 'not-allowed',
  },
  myCoupon: {
    marginTop: 40,
    backgroundColor: 'transparent',
    height: 30,
    width: 'fit-content',
    border: '1px solid #974107',
    cursor: 'pointer',
    color: '#974107',
    textAlign: 'center',
    paddingTop: 2,
    outline: 'none',
  },
  clearCoupon: {
    backgroundColor: '#974107',
    height: 30,
    width: 60,
    textAlign: 'center',
    paddingTop: 2,
    color: 'white',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    marginLeft: 10,
  },
  myCouponMobile: {
    marginLeft: 5,
    backgroundColor: 'transparent',
    height: 30,
    width: '30%',
    border: '1px solid #974107',
    cursor: 'pointer',
    color: '#974107',
    textAlign: 'center',
    paddingTop: 2,
    outline: 'none',
  },
  couponText: {
    width: 'fit-content',
  },
};

const PlaceOrderTableComponent = ({
  orderType,
  bagData,
  onValueChanged,
  recipientName,
  recipientPhone,
  recipientZipCode,
  recipientAddress,
  rentStartDate,
  rentEndDate,
  note,
  receiptType,
  shippingType,
  phoneBarCode,
  taxId,
  receiptHead,
  taxReceiptAddress,
  donateOrganizationList,
  eReceiptAddress,
  setEReceiptAddress,
  isAutoInputRecipient,
  onAutoInputRecipientChanged,
  isUpdateMemberInfo,
  onUpdateMemberInfo,
  onInstalmentChanged,
  onPayWayTypeChanged,
  onClickMyCoupon,
  instalmentTextStyle,
  clearSelectedMemberCoupon,
  sevenElevenMarket,
  sevenElevenAddress,
  onInputCouponChange,
  inputCouponText,
  amount,
  rentDateList,
  onZeroCardInstallmentChanged,
}) => {
  const [isSameAsRecipient, setIsSameAsRecipient] = useState(false);
  const [isShowSelectInstalment, setIsShowSelectInstalment] = useState(false);
  const [isShowZeroCardInstalment, setIsShowZeroCardInstalment] = useState(false);
  const isPostReceive = R.pathOr(false, ['owner', 'isPostReceive'], bagData);
  const selectStore = R.pathOr(null, ['owner', 'selectStore'], bagData);
  const [orderSelectStore, setOrderSelectStore] = useState(null);
  const isOhPhireManage = R.pathOr(false, ['isOhPhireManage'], bagData);

  const renderAddressAsRecipient = () => {
    setEReceiptAddress(recipientAddress);
    return recipientAddress;
  };

  const is711Able = () => {
    const nowTwoDateLater = moment(new Date()).add(2, 'days').format('YYYY-MM-DD');
    const isBeforeTwoDate = moment(rentStartDate).isSameOrBefore(nowTwoDateLater);
    let isLimit711BuyEndDate = false;

    if (isLimit711BuyEndDate) {
      return isLimit711BuyEndDate;
    }

    // Note: 🔒四天後面三天不能選7-11(加上本來訂單結束後四天)
    rentDateList.forEach((item) => {
      const revisedRentEndDateLimit = moment(item.rentEndDate).add(7, 'days').format('YYYY-MM-DD');
      if (moment(revisedRentEndDateLimit).isSameOrAfter(rentStartDate)) {
        isLimit711BuyEndDate = true;
      }
    });

    if (isOhPhireManage && isBeforeTwoDate) {
      return true;
    } if (isOhPhireManage) {
      return false;
    }
    return !(selectStore === shippingTypeId.sevenEleven);
  };

  const renderCouponMobileAmount = () => (
    <React.Fragment>
      <td style={styles.couponItemContainer}>
        <div>折扣碼</div>
        {
          amount > 600 ? (
            <input
              type="text"
              style={styles.inputCouponMobile}
              placeholder="請輸入優惠代碼"
              value={inputCouponText}
              onChange={(event) => { onInputCouponChange(event.target.value); }}
            />
          ) : (
            <input
              type="text"
              style={styles.inputCouponMobileDisable}
              placeholder="請輸入優惠代碼"
              value={inputCouponText}
              onChange={(event) => { onInputCouponChange(event.target.value); }}
              disabled
            />
          )
        }

      </td>
      <td style={styles.couponItemContainer}>
        <button
          type="button"
          style={styles.myCouponMobile}
          onClick={() => onClickMyCoupon()}
        >
          我的優惠券
        </button>
        <button
          type="button"
          style={styles.clearCoupon}
          onClick={() => clearSelectedMemberCoupon()}
        >
          清除
        </button>
      </td>
    </React.Fragment>
  );

  const renderCouponComponent = () => (
    <React.Fragment>
      <td style={styles.couponItemContainer}>
        <div style={styles.couponText}>折扣碼</div>
        {
          amount > 600 ? (
            <input
              type="text"
              style={styles.inputCoupon}
              placeholder="請輸入優惠代碼"
              value={inputCouponText}
              onChange={(event) => { onInputCouponChange(event.target.value); }}
            />
          ) : (
            <input
              type="text"
              style={styles.inputCouponDisable}
              placeholder="請輸入優惠代碼"
              value={inputCouponText}
              onChange={(event) => { onInputCouponChange(event.target.value); }}
              disabled
            />
          )
        }

      </td>
      <td>
        <button
          type="button"
          style={styles.myCoupon}
          onClick={() => onClickMyCoupon()}
        >
          我的優惠券
        </button>
        <button
          type="button"
          style={styles.clearCoupon}
          onClick={() => clearSelectedMemberCoupon()}
        >
          清除
        </button>
      </td>
      <td />
    </React.Fragment>
  );

  const renderInvoiceType = () => {
    switch (receiptType) {
      case receiptTypeId.eReceiptMobile:
        return (
          <tr key="phoneBarCode">
            <td style={styles.fieldItem}>
              <div style={styles.fieldTitle}>
                手機載具條碼:
              </div>
              <input
                style={styles.inputBox}
                value={phoneBarCode}
                onChange={(event) => { onValueChanged('phoneBarCode', event); }}
              />
            </td>
          </tr>
        );

      case receiptTypeId.donateReceipt:
        return (
          <tr key="donateOrganizationList">
            <td style={styles.fieldItem}>
              <div style={styles.fieldTitle}>
                捐贈單位:
              </div>
              <select onChange={(event) => { onValueChanged('donateOrganization', event); }}>
                {
                  donateOrganizationList.map(donateOrganization => (
                    <option key={donateOrganization.id} value={donateOrganization.id}>{donateOrganization.name}</option>))
                }
              </select>
            </td>
          </tr>
        );

      case receiptTypeId.taxIdReceipt:
        return (
          <React.Fragment>
            <tr key="taxId">
              <td style={styles.fieldItem}>
                <div style={styles.fieldTitle}>
                  統一編號:
                </div>
                <input
                  style={styles.inputBox}
                  value={taxId}
                  onChange={(event) => { onValueChanged('taxId', event); }}
                />
              </td>
            </tr>
            <tr key="receiptHead">
              <td style={styles.fieldItem}>
                <div style={styles.fieldTitle}>
                  發票抬頭:
                </div>
                <input
                  style={styles.inputBox}
                  value={receiptHead}
                  onChange={(event) => { onValueChanged('receiptHead', event); }}
                />
              </td>
            </tr>
            <tr key="taxReceiptAddress">
              <td style={styles.fieldItem}>
                <div style={styles.fieldTitle}>
                  統編發票寄送地址:
                </div>
                <input
                  style={styles.inputBox}
                  value={taxReceiptAddress}
                  onChange={(event) => { onValueChanged('taxReceiptAddress', event); }}
                />
              </td>
            </tr>
          </React.Fragment>
        );

      default:
        return (
          <tr key="eReceiptAddress">
            <td style={styles.fieldItem}>
              <div style={styles.fieldTitle}>
                中獎發票寄送地址:
              </div>
              <input
                style={isSameAsRecipient ? styles.inputBoxReadOnly : styles.inputBox}
                readOnly={isSameAsRecipient}
                value={isSameAsRecipient ? renderAddressAsRecipient() : eReceiptAddress}
                onChange={(event) => { onValueChanged('eReceiptAddress', event); }}
              />
              <div style={styles.fieldTitle}>
                <input
                  type="checkbox"
                  value={isSameAsRecipient}
                  onClick={() => { setIsSameAsRecipient(!isSameAsRecipient); }}
                />
                同訂購人地址
              </div>

            </td>
          </tr>
        );
    }
  };
  const renderInputBlock = (title, value, valueKey, inputStyle) => (
    <td style={styles.fieldItem}>
      <div style={styles.fieldTitle}>
        {title}
      </div>
      <input
        style={{ ...styles.inputBox, ...inputStyle }}
        value={value}
        onChange={(event) => { onValueChanged(valueKey, event); }}
      />
    </td>
  );

  const renderInputMyRecipientInfo = () => (
    <td style={styles.autoInputRecipient}>
      <input
        type="checkbox"
        style={styles.autoInputRecipientCheckbox}
        defaultChecked={isAutoInputRecipient}
        onChange={event => onAutoInputRecipientChanged(event.target.checked)}
      />
      <div style={styles.autoInputRecipientCheckboxText}>加入我的收件資料</div>
    </td>
  );

  const renderUpdateRecipientToMemberInfo = () => (
    <td style={styles.autoInputRecipient}>
      <input
        type="checkbox"
        style={styles.autoInputRecipientCheckbox}
        defaultChecked={isUpdateMemberInfo}
        onChange={event => onUpdateMemberInfo(event.target.checked)}
      />
      <div style={styles.autoInputRecipientCheckboxText}>收件資料更新至會員資料</div>
    </td>
  );

  const renderSelectShippingType = () => {
    const postReceiveIsAble = () => {
      if (!selectStore && !isPostReceive) return false;

      if (isOhPhireManage) return false;

      if (isPostReceive) return false;

      return true;
    };

    return (
      <td style={styles.autoInputRecipient}>
        <div style={styles.fieldTitle}>
          運送方式:
        </div>
        <select
          onChange={(event) => {
            onValueChanged('shippingType', event);
            setOrderSelectStore(event.target.value);
          }}
          defaultValue={shippingType}
        >
          <option
            value={null}
          />
          <option
            disabled={postReceiveIsAble()}
            value={shippingTypeId.postOffice}
          >
            郵寄到府 收件/歸還
          </option>
          <option
            disabled={is711Able()}
            value={shippingTypeId.sevenEleven}
          >
            711 店到店
          </option>
        </select>
      </td>
    );
  };

  const renderInputDate = (title, value) => (
    <td style={styles.fieldItem}>
      <div style={styles.fieldTitle}>
        {title}
      </div>
      <div style={styles.inputBox}>{value}</div>
    </td>
  );
  const renderRecipeBlock = (
    <td style={styles.fieldItem}>
      <div style={styles.fieldTitle}>
        發票類型(Address):
      </div>
      <select onChange={(event) => { onValueChanged('receiptType', event); }} defaultValue={receiptType}>
        <option value={receiptTypeId.eReceipt}>電子發票</option>
        <option value={receiptTypeId.eReceiptMobile}>電子發票(手機載具)</option>
        <option value={receiptTypeId.donateReceipt}>捐贈發票</option>
        <option value={receiptTypeId.taxIdReceipt}>統編(公司戶)發票</option>
      </select>
    </td>
  );

  const checkAbleInstalment = () => {
    const buyoutAmount = R.pathOr(0, ['buyoutAmount'], bagData);
    if (buyoutAmount > 25000) {
      return false;
    }
    return true;
  };

  return (
    <table style={styles.fieldTableContainer} key={R.path(['id'], bagData)}>
      <tbody>
        <Default>
          <tr key="addMeRecipientInfo">
            {renderInputMyRecipientInfo()}
          </tr>
          <tr key="name">
            {renderInputBlock('姓名:', recipientName, 'recipientName')}
            {renderInputBlock('手機:', recipientPhone, 'recipientPhone')}
          </tr>
          {
            orderSelectStore === shippingTypeId.postOffice || !selectStore ? (
              <React.Fragment>
                <tr key="zip_code_address">
                  {renderInputBlock('郵遞區號:', recipientZipCode, 'recipientZipCode')}
                </tr>
                <tr key="address">
                  {renderInputBlock('地址:', recipientAddress, 'recipientAddress', styles.inputBoxAddress)}
                </tr>
              </React.Fragment>
            )
              : (
                <React.Fragment>
                  <tr key="sevenElevenMarket">
                    {renderInputBlock('超商門市', sevenElevenMarket, 'sevenElevenMarket')}
                  </tr>
                  <tr key="sevenElevenAddress">
                    {renderInputBlock('超商地址:', sevenElevenAddress, 'sevenElevenAddress')}
                  </tr>
                </React.Fragment>
              )
          }
          <tr>
            {renderUpdateRecipientToMemberInfo()}
          </tr>
          <tr>
            {renderSelectShippingType()}
          </tr>
          <tr key="rentStartDate_rentDays">
            {orderType !== 'buy'
              ? (
                <React.Fragment>
                  {renderInputDate('收貨日期:', rentStartDate)}
                  {renderInputDate('歸還日期:', rentEndDate)}
                </React.Fragment>
              ) : null}
          </tr>
        </Default>
        <Mobile>
          <tr key="addMeRecipientInfo">
            {renderInputMyRecipientInfo()}
          </tr>
          <tr key="name">
            {renderInputBlock('姓名:', recipientName, 'recipientName')}
          </tr>
          <tr key="phone">
            {renderInputBlock('手機號碼:', recipientPhone, 'recipientPhone')}
          </tr>
          {
            orderSelectStore === shippingTypeId.postOffice ? (
              <React.Fragment>
                <tr key="zip_code">
                  {renderInputBlock('郵遞區號:', recipientZipCode, 'recipientZipCode')}
                </tr>
                <tr key="address">
                  {renderInputBlock('地址:', recipientAddress, 'recipientAddress', styles.inputBoxAddressMobile)}
                </tr>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <tr key="sevenElevenMarket">
                  {renderInputBlock('超商門市:', sevenElevenMarket, 'sevenElevenMarket')}
                </tr>
                <tr key="sevenElevenAddress">
                  {renderInputBlock('超商地址:', sevenElevenAddress, 'sevenElevenAddress', styles.inputBoxAddressMobile)}
                </tr>
              </React.Fragment>
            )
          }
          <tr>
            {renderUpdateRecipientToMemberInfo()}
          </tr>
          <tr>
            {renderSelectShippingType()}
          </tr>
          {orderType !== 'buy'
            ? (
              <React.Fragment>
                <tr key="rentStartDate">
                  {renderInputDate('收貨日期:', rentStartDate)}
                </tr>
                <tr key="rentEndDate">
                  {renderInputDate('歸還日期:', rentEndDate)}
                </tr>
              </React.Fragment>
            ) : null}
        </Mobile>
        <tr key="note">
          {renderInputBlock('備註:', note, 'note')}
        </tr>
        <tr key="receiptType">
          {renderRecipeBlock}
        </tr>
        {renderInvoiceType()}
        <tr>
          <td>
            <form onChange={((event) => { onValueChanged('insurance', event); })}>
              <input type="radio" name="transmit" value="false" defaultChecked />
              【租借方】支付單趟(歸還)運費
            </form>
          </td>
        </tr>
        <tr>
          <td>
            付款方式:
          </td>
        </tr>
        <tr>
          <td>
            <div style={styles.creditContainer}>
              <input
                type="radio"
                name="isInstallment"
                onClick={() => {
                  setIsShowSelectInstalment(false);
                  setIsShowZeroCardInstalment(false);
                  onInstalmentChanged(0);
                  onZeroCardInstallmentChanged(0);
                  onPayWayTypeChanged(2);
                }}
              />
              <div style={styles.creditText}>
                Apple Pay
              </div>
              <div style={styles.creditIconContainer}>
                <img style={styles.creditIcon} src={Assets.applePayIcon} alt="" />
              </div>
            </div>
            <div style={styles.creditContainer}>
              <input
                type="radio"
                name="isInstallment"
                onClick={() => {
                  onZeroCardInstallmentChanged(0);
                  onInstalmentChanged(0);
                  setIsShowZeroCardInstalment(false);
                  setIsShowSelectInstalment(false);
                  onPayWayTypeChanged(0);
                }}
                defaultChecked
              />
              <div style={styles.creditText}>
                信用卡一次付清
              </div>
              <div style={styles.creditIconContainer}>
                <img style={styles.creditIcon} src={Assets.orderCredit01} alt="" />
                <img style={styles.creditIcon} src={Assets.orderCredit02} alt="" />
                <img style={styles.creditIcon} src={Assets.orderCredit03} alt="" />
              </div>
            </div>
            {orderType === 'buy' ? (
              <React.Fragment>
                <div style={styles.creditContainer}>
                  <input
                    type="radio"
                    name="isInstallment"
                    onClick={() => {
                      setIsShowSelectInstalment(true);
                      setIsShowZeroCardInstalment(false);
                      onInstalmentChanged(0);
                      onZeroCardInstallmentChanged(0);
                      onPayWayTypeChanged(0);
                    }}
                  />
                  <div style={styles.creditText}>
                    信用卡分期
                  </div>
                  <div style={styles.creditIconContainer}>
                    <img style={styles.creditIcon} src={Assets.orderCredit01} alt="" />
                    <img style={styles.creditIcon} src={Assets.orderCredit02} alt="" />
                    <img style={styles.creditIcon} src={Assets.orderCredit03} alt="" />
                  </div>
                </div>
                {(isShowSelectInstalment && !isShowZeroCardInstalment) ? (
                  <div style={{ ...styles.instalmentContainer, ...instalmentTextStyle }}>
                    <div style={styles.instalmentNumberContainer}>
                      <input
                        type="radio"
                        name="instalment"
                        onClick={() => {
                          onInstalmentChanged(3);
                          onZeroCardInstallmentChanged(0);
                        }}
                      />
                      <div style={styles.installNumber}>
                        3期
                      </div>
                      <input
                        type="radio"
                        name="instalment"
                        disabled={checkAbleInstalment()}
                        onClick={() => {
                          onInstalmentChanged(6);
                          onZeroCardInstallmentChanged(0);
                        }}
                      />
                      <div style={styles.installNumber}>
                        6期
                      </div>
                    </div>
                    <div style={{ whiteSpace: 'pre-line' }}>
                      {[
                        '提供刷分期：3期、6期',
                        '3期－1% 6期－1.5%',
                        '$25000以上可分6期',
                        '',
                        '適用銀行：',
                        '台新銀行、玉山銀行',
                      ].join('\n')}
                    </div>
                  </div>
                ) : null}
                <div style={styles.creditContainer}>
                  <input
                    type="radio"
                    name="isInstallment"
                    onClick={() => {
                      onZeroCardInstallmentChanged(0);
                      onInstalmentChanged(0);
                      setIsShowZeroCardInstalment(false);
                      setIsShowSelectInstalment(false);
                      onPayWayTypeChanged(1);
                    }}
                  />
                  <div style={styles.creditText}>
                    中租零卡分期
                  </div>
                  <div style={styles.creditIconContainer}>
                    <img style={styles.zeroCardIcon} src={Assets.zeroCardIcon} alt="" />
                  </div>
                </div>
                {/* zero card need over $1000 */}
                {(isShowZeroCardInstalment && R.pathOr(0, ['buyoutAmount'], bagData) >= 1000) ? (
                  <div style={{ ...styles.instalmentContainer, ...instalmentTextStyle }}>
                    <div style={styles.instalmentNumberContainer}>
                      <input
                        type="radio"
                        name="zeroCardInstallment"
                        onClick={() => onZeroCardInstallmentChanged(3)}
                      />
                      <div style={styles.installNumber}>
                        3期
                      </div>
                      <input
                        type="radio"
                        name="zeroCardInstallment"
                        onClick={() => {
                          onInstalmentChanged(0);
                          onZeroCardInstallmentChanged(6);
                        }}
                      />
                      <div style={styles.installNumber}>
                        6期
                      </div>
                      <input
                        type="radio"
                        name="zeroCardInstallment"
                        onClick={() => {
                          onInstalmentChanged(0);
                          onZeroCardInstallmentChanged(9);
                        }}
                      />
                      <div style={styles.installNumber}>
                        9期
                      </div>
                      <input
                        type="radio"
                        name="zeroCardInstallment"
                        onClick={() => {
                          onInstalmentChanged(0);
                          onZeroCardInstallmentChanged(12);
                        }}
                      />
                      <div style={styles.installNumber}>
                        12期
                      </div>
                      <input
                        type="radio"
                        name="zeroCardInstallment"
                        onClick={() => {
                          onInstalmentChanged(0);
                          onZeroCardInstallmentChanged(18);
                        }}
                      />
                      <div style={styles.installNumber}>
                        18期
                      </div>
                      <input
                        type="radio"
                        name="zeroCardInstallment"
                        onClick={() => {
                          onInstalmentChanged(0);
                          onZeroCardInstallmentChanged(24);
                        }}
                      />
                      <div style={styles.installNumber}>
                        24期
                      </div>
                    </div>
                    <div style={{ whiteSpace: 'pre-line' }}>
                      {[
                        '提供刷分期：3期、6期、9期、12期、18期、24期',
                        '3期－3%',
                        '6期－3.5%',
                        '9期－4.5%',
                        '12期－5.5%',
                        '18期－8%',
                        '24期－10%',
                        ' 須先申請為中租0卡分期會員',
                      ].join('\n')}
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ) : null}
          </td>
        </tr>

        <Default>
          <tr>
            {renderCouponComponent()}
          </tr>
        </Default>
        <Mobile>
          <tr>
            {renderCouponMobileAmount()}
          </tr>
        </Mobile>
      </tbody>
    </table>
  );
};


PlaceOrderTableComponent.propTypes = {
  bagData: PropTypes.object,
  recipientName: PropTypes.string,
  recipientPhone: PropTypes.string,
  recipientZipCode: PropTypes.string,
  recipientAddress: PropTypes.string,
  rentStartDate: PropTypes.string,
  rentEndDate: PropTypes.string,
  note: PropTypes.string,
  receiptType: PropTypes.string,
  phoneBarCode: PropTypes.string,
  taxId: PropTypes.string,
  receiptHead: PropTypes.string,
  taxReceiptAddress: PropTypes.string,
  eReceiptAddress: PropTypes.string,
  onValueChanged: PropTypes.func.isRequired,
  donateOrganizationList: PropTypes.array,
  setEReceiptAddress: PropTypes.func.isRequired,
  isAutoInputRecipient: PropTypes.bool,
  onUpdateMemberInfo: PropTypes.func.isRequired,
  onAutoInputRecipientChanged: PropTypes.func.isRequired,
  onClickMyCoupon: PropTypes.func.isRequired,
  clearSelectedMemberCoupon: PropTypes.func.isRequired,
  isUpdateMemberInfo: PropTypes.bool,
  onInstalmentChanged: PropTypes.func,
  instalmentTextStyle: PropTypes.object,
  orderType: PropTypes.string.isRequired,
  shippingType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  sevenElevenMarket: PropTypes.string,
  sevenElevenAddress: PropTypes.string,
  onInputCouponChange: PropTypes.func.isRequired,
  onPayWayTypeChanged: PropTypes.func,
  onZeroCardInstallmentChanged: PropTypes.func,
  inputCouponText: PropTypes.string,
  amount: PropTypes.number,
  rentDateList: PropTypes.number,
};

PlaceOrderTableComponent.defaultProps = {
  bagData: null,
  rentStartDate: new Date(),
  rentEndDate: new Date(),
  recipientName: '',
  recipientPhone: '',
  recipientAddress: '',
  recipientZipCode: '',
  phoneBarCode: '',
  taxId: '',
  receiptHead: '',
  taxReceiptAddress: '',
  eReceiptAddress: '',
  note: '',
  receiptType: '1',
  shippingType: null,
  donateOrganizationList: [],
  isAutoInputRecipient: false,
  isUpdateMemberInfo: false,
  onInstalmentChanged: () => {},
  onPayWayTypeChanged: () => {},
  onZeroCardInstallmentChanged: () => {},
  instalmentTextStyle: {},
  sevenElevenAddress: '',
  sevenElevenMarket: '',
  inputCouponText: '',
  amount: 0,
  rentDateList: [],
};

export default PlaceOrderTableComponent;
