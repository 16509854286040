import React, { useEffect } from 'react';
import InfoTitle from '../../../../components/InfoTitle';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    margin: '160px 20px',
  },
  mainMobileContainer: {
    margin: '40px 25px 120px 25px',
  },
  termsContainer: {
    lineHeight: '30px',
    paddingRight: '140px',
    letterSpacing: '3.33px',
    whiteSpace: 'normal',
  },
  termsMobileContainer: {
    lineHeight: '30px',
    letterSpacing: '3.33px',
    whiteSpace: 'normal',
  },
  subTerms: {
    fontSize: '14px',
  },
  items: {
    fontSize: '14px',
    fontWeight: 'normal',
    marginLeft: '20px',
  },
  subtitle: {
    width: '100%',
    color: '#000000',
    fontSize: '20px',
    letterSpacing: '3.33px',
    lineHeight: '28px',
    textAlign: 'left',
    margin: '10px 0 10px 0',
  },
  pic: {
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    marginTop: '80px',
    maxHeight: '30vw',
    objectFit: 'cover',
  },
  listContainer: {
    margin: '30px 0 30px 0',
  },
};

const terms = (
  <React.Fragment>
    <div style={styles.subTerms}>
      <div style={styles.listContainer}>
        一、本條所使用相關名詞定義，均引用中華民國現行「個人資料保護法」。且會員根據該法享有相關個人資料保護。
      </div>
      <div style={styles.listContainer}>
        二、隱私權政策適用於會員使用本網站服務時所提供的個人資料。會員經由本網站所提供的連結點選進入其他網站時，會員在該網站中不適用本網站的隱私權保護政策。
      </div>
      <div style={styles.listContainer}>
        三、本網站採行適當的安全措施保護會員的個人資料及交易紀錄，並盡力以合理之技術及程序，保障所有個人資料之安全。但仍不能排除其他第三人利用各種方式違法侵入、截取、傳輸會員個人資料或交易紀錄的可能性。
      </div>
      <div style={styles.listContainer}>
        四、會員同意，「Oh! Phire」得蒐集、處理、利用會員於本網站所填寫個人資料及交易紀錄，茲明確同意本網站、「Oh! Phire」、經「Oh! Phire」授權的人員、
        為完成租賃服務所委外的廠商、合作夥伴或人員等，可以使用會員個人資料及交易紀錄的範圍如下：
        <div style={styles.items}>
          (一)向會員通知、展示、行銷、聯絡、促成、履行與本網站所提供的商品租賃服務所需有關事項。
          <br />
          (二)作為本網站判斷是否同意授與會員資格，或對已取得會員資格者暫停或終止服務的依據。
          <br />
          (三)處理會員資格者暫停或終止服務後相關事宜。
        </div>
      </div>
      <div style={styles.listContainer}>
        五、若依法令，或為協助公務機關執行法定職務或非公務機關履行法定義務必要範圍內，或經主管機關或司法機關要求者，「Oh! Phire」應依法提供會員於本網站所填寫的個人資料或交易紀錄，且不需於提供前通知會員。
      </div>
      <div style={styles.listContainer}>
        六、會員於本網站的會員帳號無法變更。會員帳號以外的其他會員資料若需變更，會員應自行登入本網站「會員中心」進行變更。會員並得自行登入本網站「會員中心」列印所填寫的會員資料。
      </div>
      <div style={styles.listContainer}>
        七、會員應妥善管理自己於本網站的帳號、密碼。若因會員本身因素導致本網站的帳號、密碼有遺失或遭他人盜用情形，或會員自行將本網站的帳號、密碼交由他人使用者，會員均應自負其責。
      </div>
      <div style={styles.listContainer}>
        八、若會員懷疑或發現於本網站的帳號、密碼有遺失或遭他人盜用情形，或所授權使用的他人有使用不當的情形，會員應即時通知本網站客服人員處理，以避免損害擴大。
      </div>
      <div style={styles.listContainer}>
        九、為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導至網站某些功能無法正常執行 。
      </div>
      <div style={styles.listContainer}>
        十、若會員在本網站的行為已違反本網站的服務條款，或可能損害或妨礙本網站權益，或會員的行為已導致任何人遭受損害，只要我們相信揭露會員的個人資料是為了辨識、聯絡或採取法律行動所必要者，
        於必要時本公司亦得提供會員於本網站所填寫的個人資料或交易紀錄，且不需於提供前通知會員。
      </div>
    </div>
  </React.Fragment>
);

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          <InfoTitle
            leftTitle="CUSTOMER"
            leftSubtitle="顧客"
            rightTitle="Privacy protection"
            rightSubtitle="會員資料及隱私權的保護"
            content={(
              <div style={styles.termsContainer}>
                {terms}
              </div>
            )}
          />
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          <InfoTitle
            leftTitle="CUSTOMER"
            leftSubtitle="顧客"
            rightTitle="Privacy protection"
            rightSubtitle="會員資料及隱私權的保護"
            content={(
              <div style={styles.termsMobileContainer}>
                {terms}
              </div>
            )}
          />
        </div>
      </Mobile>
    </React.Fragment>
  );
};


export default Privacy;
