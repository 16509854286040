import React from 'react';
import PropTypes from 'prop-types';
import Assets from '../constants/Assets';

const styles = {
  star: {
    width: 20,
    height: 20,
  },
};
const CommentStars = ({
  stars,
}) => {
  const renderStarArray = [...Array(stars).fill(true), ...Array(5 - stars).fill(false)];

  return (
    <div>
      {
         renderStarArray.map((star, index) => {
           const key = `${star} ${index}`;
           if (star) {
             return (
               <img
                 key={key}
                 src={Assets.commentStartActive}
                 alt=""
                 style={styles.star}
               />
             );
           }
           return (
             <img
               key={key}
               src={Assets.commentStartDisActive}
               alt=""
               style={styles.star}
             />
           );
         })
      }
    </div>
  );
};

CommentStars.propTypes = {
  stars: PropTypes.number,
};

CommentStars.defaultProps = {
  stars: 0,
};


export default CommentStars;
