import React from 'react';
import { Router } from '@reach/router';
import Paths from './Paths';
import { commodityTypeId } from '../constants/Type';
import GeneralLayout from '../components/layouts/GeneralLayout';
import HomeContainer from '../modules/Home/components/HomePage';
import MemberContainer from '../modules/Member/components/MemberPage';
import MemberProfileContainer from '../modules/Member/components/MemberProfilePage';
import LogoutContainer from '../modules/Member/components/LogoutPage';
import BagListContainer from '../modules/Bag/components/BagListPage';
import MyBagListContainer from '../modules/Bag/components/MyBagListPage';
import SubmitBagContainer from '../modules/Bag/components/SubmitBagPage';
import BagDetailContainer from '../modules/Bag/components/BagDetailPage';
import FAQContainer from '../modules/FAQ/components/FAQ';
import OrderCreateContainer from '../modules/Order/components/OrderCreatePage';
import TransactionContainer from '../modules/Transaction/components/TransactionPage';
import AboutContainer from '../modules/About/components/AboutPage';
import MemberTermsContainer from '../modules/Terms/components/MemberTermsPage';
import PrivacyContainer from '../modules/Terms/components/PrivacyPage';
import ContactContainer from '../modules/Contact/components/ContactPage';
import OrderListContainer from '../modules/Order/components/OrderListPage';
import OrderDetailContainer from '../modules/Order/components/OrderDetailPage';
import MyRentedSoldOrderListContainer from '../modules/Order/components/MyRentedSoldOrderListPage';
import CartListContainer from '../modules/Cart/components/CartListPage';
import ThemeListContainer from '../modules/Theme/components/ThemeListPage';
import ThemeBagContainer from '../modules/Theme/components/ThemeBagPage';
import PartnerContainer from '../modules/Partners/components/PartnersPage';
import OnlineBagContainer from '../modules/OnlineBag/components/OnlineBagPage';
import HowItWorksContainer from '../modules/HowItWork/components/HowItWorksPage';
import HowItWorksProvideContainer from '../modules/HowItWork/components/ProvidePage';
import MemberProfileEditContainer from '../modules/Member/components/MemberProfileEditPage';
import ShippingInfoContainer from '../modules/ShippingInfo/components/ShippingInfoPage';

const {
  HOME,
  MEMBER,
  LOGOUT,
  BAG_LIST,
  MY_BAG_LIST,
  SUBMIT_BAG,
  BAG_DETAIL,
  FAQ,
  TRANSACTION,
  ORDER_CREATE,
  ABOUT,
  MEMBER_TERMS,
  PRIVACY,
  CONTACT,
  ORDER_LIST,
  ORDER_DETAIL,
  MY_RENTED_SOLD_HISTORY,
  CART,
  THEME,
  PARTNER,
  ONLINE_PRODUCTS,
  HOW_IT_WORKS,
  HOW_IT_WORKS_PROVIDE,
  EDIT,
  CLOTHES_LIST,
  CLOTHES_DETAIL,
  EDIT_COMMODITY,
  SHIPPING_INFO,
  BUY_AREA,
  ACCESSORIES_LIST,
  ACCESSORIES_DETAIL,
  ESSENCES_DETAIL,
  ESSENCES_LIST,
  BUY_AREA_ALL_NEW,
} = Paths;

const RouterList = () => (
  <Router>

    <GeneralLayout
      default
      path={HOME}
      Child={HomeContainer}
      headerScrolled
    />

    <GeneralLayout
      path={`${MEMBER}/:communityName`}
      Child={MemberProfileContainer}
    />

    <GeneralLayout
      path={`${MEMBER}/:id${EDIT}`}
      Child={MemberProfileEditContainer}
      fullWidthContent
    />

    <GeneralLayout
      path={MEMBER}
      Child={MemberContainer}
      fullWidthContent
      headerScrolled={false}
    />

    <GeneralLayout
      path={LOGOUT}
      Child={LogoutContainer}
    />

    <GeneralLayout
      path={BAG_LIST}
      Child={BagListContainer}
      commodityType={commodityTypeId.bag}
    />

    <GeneralLayout
      path={BUY_AREA}
      Child={BagListContainer}
      isBuyArea
    />

    <GeneralLayout
      path={BUY_AREA_ALL_NEW}
      Child={BagListContainer}
      isBuyArea
      allNew
    />

    <GeneralLayout
      path={THEME}
      Child={ThemeListContainer}
    />

    <GeneralLayout
      path={`${THEME}/:id`}
      Child={ThemeBagContainer}
    />

    <GeneralLayout
      path={MY_BAG_LIST}
      Child={MyBagListContainer}
    />


    <GeneralLayout
      path={SUBMIT_BAG}
      Child={SubmitBagContainer}
    />
    <GeneralLayout
      path={EDIT_COMMODITY}
      Child={SubmitBagContainer}
      isEdit
    />

    <GeneralLayout
      path={`${BAG_DETAIL}/:bagNo`}
      Child={BagDetailContainer}
      fullWidthContent
      commodityType={commodityTypeId.bag}
    />

    <GeneralLayout
      path={`${CLOTHES_DETAIL}/:bagNo`}
      Child={BagDetailContainer}
      fullWidthContent
      commodityType={commodityTypeId.clothes}
    />

    <GeneralLayout
      path={`${ACCESSORIES_DETAIL}/:bagNo`}
      Child={BagDetailContainer}
      fullWidthContent
      commodityType={commodityTypeId.accessories}
    />

    <GeneralLayout
      path={`${ESSENCES_DETAIL}/:bagNo`}
      Child={BagDetailContainer}
      fullWidthContent
      commodityType={commodityTypeId.essences}
    />

    <GeneralLayout
      path={FAQ}
      Child={FAQContainer}
    />

    <GeneralLayout
      path={`${BAG_DETAIL}/:bagId${ORDER_CREATE}`}
      Child={OrderCreateContainer}
      commodityType={commodityTypeId.bag}
    />

    <GeneralLayout
      path={`${CLOTHES_DETAIL}/:bagId${ORDER_CREATE}`}
      Child={OrderCreateContainer}
      commodityType={commodityTypeId.clothes}
    />

    <GeneralLayout
      path={`${ACCESSORIES_DETAIL}/:bagId${ORDER_CREATE}`}
      Child={OrderCreateContainer}
      commodityType={commodityTypeId.accessories}
    />

    <GeneralLayout
      path={`${ESSENCES_DETAIL}/:bagId${ORDER_CREATE}`}
      Child={OrderCreateContainer}
      commodityType={commodityTypeId.essences}
    />

    <GeneralLayout
      path={ABOUT}
      Child={AboutContainer}
    />

    <GeneralLayout
      path={MEMBER_TERMS}
      Child={MemberTermsContainer}
    />

    <GeneralLayout
      path={ORDER_LIST}
      Child={OrderListContainer}
      fullWidthContent
    />

    <GeneralLayout
      path={`${ORDER_DETAIL}/:orderId${TRANSACTION}`}
      Child={TransactionContainer}
    />

    <GeneralLayout
      path={`${ORDER_DETAIL}/:orderId`}
      Child={OrderDetailContainer}
    />

    <GeneralLayout
      path={MY_RENTED_SOLD_HISTORY}
      Child={MyRentedSoldOrderListContainer}
    />

    <GeneralLayout
      path={CART}
      Child={CartListContainer}
    />

    <GeneralLayout
      path={PRIVACY}
      Child={PrivacyContainer}
    />

    <GeneralLayout
      path={CONTACT}
      Child={ContactContainer}
    />

    <GeneralLayout
      path={PARTNER}
      Child={PartnerContainer}
    />

    <GeneralLayout
      path={ONLINE_PRODUCTS}
      Child={OnlineBagContainer}
      fullWidthContent
    />

    <GeneralLayout
      path={HOW_IT_WORKS}
      Child={HowItWorksContainer}
    />

    <GeneralLayout
      path={HOW_IT_WORKS_PROVIDE}
      Child={HowItWorksProvideContainer}
    />

    <GeneralLayout
      path={CLOTHES_LIST}
      Child={BagListContainer}
      commodityType={commodityTypeId.clothes}
    />

    <GeneralLayout
      path={ACCESSORIES_LIST}
      Child={BagListContainer}
      commodityType={commodityTypeId.accessories}
    />

    <GeneralLayout
      path={ESSENCES_LIST}
      Child={BagListContainer}
      commodityType={commodityTypeId.essences}
    />

    <GeneralLayout
      path={SHIPPING_INFO}
      Child={ShippingInfoContainer}
      fullWidthContent
    />
  </Router>
);

export default RouterList;
