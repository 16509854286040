import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import Assets from '../constants/Assets';


const styles = {
  passbaseVerifiedIconContainer: {
    width: '26px',
    height: '26px',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  passbaseVerifiedIcon: {
    width: '100%',
    height: '100%',
  },
  avatarContainer: {
    position: 'relative',
  },
  avatar: {
    marginLeft: '25px',
  },
};

const AvatarComponent = ({
  size,
  ownerChangedAvatar,
  isVerified,
  avatarStyle,
  passbaseIconStyle,
  avatarContainerStyle,
}) => (
  <div style={{ ...styles.avatarContainer, ...avatarContainerStyle }}>
    <Avatar
      icon="user"
      size={size || 110}
      style={{ ...styles.avatar, ...avatarStyle }}
      src={ownerChangedAvatar || Assets.AvatarIcon}
    />
    {isVerified ? (
      <div style={{ ...styles.passbaseVerifiedIconContainer, ...passbaseIconStyle }}>
        <img
          src={Assets.passbaseVerifiedIcon01}
          style={styles.passbaseVerifiedIcon}
          alt=""
        />
      </div>
    ) : null}
  </div>
);

AvatarComponent.propTypes = {
  size: PropTypes.number,
  ownerChangedAvatar: PropTypes.string,
  isVerified: PropTypes.bool,
  avatarStyle: PropTypes.object,
  passbaseIconStyle: PropTypes.object,
  avatarContainerStyle: PropTypes.object,
};

AvatarComponent.defaultProps = {
  size: 110,
  ownerChangedAvatar: null,
  isVerified: false,
  avatarStyle: {},
  passbaseIconStyle: {},
  avatarContainerStyle: {},
};

export default AvatarComponent;
