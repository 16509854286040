import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import * as R from 'ramda';
import ActionCreator from './ActionCreator';
import API from './API';
import BagStatus from '../../constants/BagStatus';
import {
  memberIdSelector,
} from '../Member/selector';
import BagSortOrder from '../../constants/BagSortOrder';
import { commodityTypeId } from '../../constants/Type';
import { nonBlockedSagaAll } from '../../utils/nonBlockedPromiseAll';
import * as LocalStorageUtils from '../../utils/LocalStorageUtils';
import LocalStorageKey from '../../constants/LocalStorageKey';

const {
  getBagListRequest,
  getBagListSuccess,
  getBagListFailure,
  getMyBagListRequest,
  getMyBagListSuccess,
  getMyBagListFailure,
  notifyWhenArrivedRequest,
  notifyWhenArrivedSuccess,
  notifyWhenArrivedFailure,
  submitBagRequest,
  submitBagSuccess,
  submitBagFailure,

  getBagListFlowRequest,
  getBagListFlowSuccess,
  getBagListFlowFailure,
  getMyBagListFlowRequest,
  getMyBagListFlowSuccess,
  getMyBagListFlowFailure,
  notifyWhenArrivedFlowRequest,
  notifyWhenArrivedFlowSuccess,
  notifyWhenArrivedFlowFailure,
  submitBagFlowRequest,
  submitBagFlowSuccess,
  submitBagFlowFailure,

  getCommentListFlowRequest,
  getCommentListFlowSuccess,
  getCommentListFlowFailure,
  getCommentListRequest,
  getCommentListSuccess,
  getCommentListFailure,

  getBagReleaseDateFlowRequest,
  getBagReleaseDateFlowSuccess,
  getBagReleaseDateFlowFailure,
  getBagReleaseDateRequest,
  getBagReleaseDateSuccess,
  getBagReleaseDateFailure,

  searchBagListByKeywordFlowRequest,
  searchBagListByKeywordFlowSuccess,
  searchBagListByKeywordFlowFailure,
  searchBagListByKeywordRequest,
  searchBagListByKeywordSuccess,
  searchBagListByKeywordFailure,

  getBagIdByBagNoFlowRequest,
  getBagIdByBagNoFlowSuccess,
  getBagIdByBagNoFlowFailure,
  getBagIdByBagNoRequest,
  getBagIdByBagNoSuccess,
  getBagIdByBagNoFailure,

  getBagRentDateListFlowRequest,
  getBagRentDateListFlowSuccess,
  getBagRentDateListFlowFailure,
  getBagRentDateListRequest,
  getBagRentDateListSuccess,
  getBagRentDateListFailure,

  getBagNumbersRequest,
  getBagNumbersSuccess,
  getBagNumbersFailure,
  getBagNumbersFlowRequest,
  getBagNumbersFlowSuccess,
  getBagNumbersFlowFailure,

  getOnlineWardrobeRequest,
  getOnlineWardrobeSuccess,
  getOnlineWardrobeFailure,
  getOnlineWardrobeFlowRequest,
  getOnlineWardrobeFlowSuccess,
  getOnlineWardrobeFlowFailure,
} = ActionCreator;

// ---------- NORMAL FUNCTIONS ----------
export const buildFilterClause = (filterCondition = {}) => {
  try {
    const {
      keyword,
      tags,
      bagStatus,
      sortOrder = 0,
      bagType,
      getClothes,
      ownerId,
      limit,
      isClothes,
      allCommodity,
      isOhPhireManage,
      size,
      commodityType,
      getMyOnlineProduct,
      buyCommodityType,
      allNew,
    } = filterCondition;

    const where = filterCondition.where || {};
    // only for bag, but bag is including null
    if (getClothes) {
      Object.assign(where, {
        and: [
          { ownerId },
          {
            or: [
              { commodityTypeId: null },
              { commodityTypeId: 1 },
              { commodityTypeId: 2 },
              { commodityTypeId: 3 },
            ],
          },
        ],
      });
    } else if (isClothes) {
      Object.assign(where, {
        commodityTypeId: 2,
      });
    } else if (commodityType && commodityType !== commodityTypeId.bag) {
      Object.assign(where, {
        commodityTypeId: commodityType,
      });
    } else if (commodityType === 0) {
      Object.assign(where, {
        and: [
          {
            or: [
              { commodityTypeId: null },
              { commodityTypeId: 1 },
              { commodityTypeId: 2 },
              { commodityTypeId: 3 },
            ],
          },
        ],
      });
    } else if (getMyOnlineProduct && isOhPhireManage) {
      Object.assign(where, {
        and: [
          { ownerId },
          {
            or: [
              { commodityTypeId: null },
              { commodityTypeId: 1 },
              { commodityTypeId: 2 },
              { commodityTypeId: 3 },
            ],
          },
          { isOhPhireManage },
        ],
      });
    } else if (getMyOnlineProduct) {
      Object.assign(where, {
        and: [
          { ownerId },
          {
            or: [
              { commodityTypeId: null },
              { commodityTypeId: 1 },
              { commodityTypeId: 2 },
              { commodityTypeId: 3 },
            ],
          },
          {
            or: [
              { isOhPhireManage: false },
              { isOhPhireManage: null },
            ],
          },
        ],
      });
    } else {
      Object.assign(where, {
        and: [
          {
            or: [
              { commodityTypeId: null },
              { commodityTypeId: 1 },
            ],
          },
        ],
      });
    }

    if (keyword) {
      Object.assign(where, {
        name: {
          like: `%${keyword}%`,
        },
      });
    }

    if (tags) {
      Object.assign(where, {
        tags,
      });
    }

    if (bagStatus) {
      Object.assign(where, {
        bagStatusId: bagStatus,
      });
    } else {
      Object.assign(where, {
        bagStatusId: {
          inq: [
            BagStatus.bagStatusId.inStock,
            BagStatus.bagStatusId.sold,
            BagStatus.bagStatusId.rented,
          ],
        },
      });
    }

    if (bagType) {
      if (typeof bagType === 'string' || typeof bagType === 'number') {
        Object.assign(where, {
          typeId: bagType,
        });
      } else {
        Object.assign(where, {
          typeId: {
            inq: Object.keys(bagType).map(key => bagType[key]),
          },
        });
      }
    }

    if (isOhPhireManage) {
      Object.assign(where, {
        isOhPhireManage,
      });
    }

    if (size) {
      Object.assign(where, {
        sizeNumber: String(size),
      });
    }


    if (allCommodity) {
      let commodityTypeArray = [
        { commodityTypeId: null },
        { commodityTypeId: 1 },
        { commodityTypeId: 2 },
        { commodityTypeId: 3 },
      ];

      let newLevel = {
        newLevel: { nin: [10] },
      };

      if (commodityType === commodityTypeId.clothes || buyCommodityType === commodityTypeId.clothes) {
        commodityTypeArray = [
          { commodityTypeId: 2 },
        ];
      }

      if (commodityType === commodityTypeId.accessories || buyCommodityType === commodityTypeId.accessories) {
        commodityTypeArray = [
          { commodityTypeId: 3 },
        ];
      }

      if (commodityType === commodityTypeId.bag || buyCommodityType === commodityTypeId.bag) {
        commodityTypeArray = [
          { commodityTypeId: null },
          { commodityTypeId: 1 },
        ];
      }

      if (allNew) {
        newLevel = { newLevel: 10 };
      }

      Object.assign(where, {
        and: [
          {
            bagStatusId: {
              inq: [
                BagStatus.bagStatusId.inStock,
                BagStatus.bagStatusId.sold,
                BagStatus.bagStatusId.rented,
              ],
            },
          },
          newLevel,
          {
            or: commodityTypeArray,
          },
        ],
      });
    }

    const order = R.pathOr(
      R.pathOr('', [0, 'order'], BagSortOrder),
      [sortOrder, 'order'],
      BagSortOrder,
    );

    const assignedFilterCondition = {
      limit,
      // skip: basePageLimit * targetPage,
      order,
      include: [
        {
          relation: 'bagStatus',
          scope: { fields: 'name' },
        },
        {
          relation: 'frontPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'backPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'sidePic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'bottomPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'zipperPic',
          scope: { fields: 'uri' },
        },
        {
          relation: 'reduceDepositUsers',
          scope: { fields: 'id' },
        },
        {
          relation: 'owner',
          scope: {
            fields: [
              'name', 'avatarId', 'communityName', 'isPostReceive',
              'isSevenElevenReceive', 'selectStore', 'isVerified', 'isBusinessPartner'],
            include: [
              {
                relation: 'avatar',
                scope: { fields: 'uri' },
              },
              {
                relation: 'follow',
              },
            ],
          },
        },
        'type',
        'tags',
        'commodityType',
      ],
      ...(R.isEmpty(where) ? {} : { where }),
    };

    return assignedFilterCondition;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {};
  }
};

// ---------- TASKS ----------
export function* getBagReleaseDate(bagId) {
  yield put(getBagReleaseDateRequest());
  try {
    const result = (yield call(API.getBagReleaseDate, bagId));
    yield put(getBagReleaseDateSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getBagReleaseDateFailure(error));
    throw error;
  }
}

export function* getBagIdByBagNo(bagNo) {
  yield put(getBagIdByBagNoRequest());
  try {
    const result = (yield call(API.getBagIdByBagNo, { bagNo }));
    const { bagId } = result.data;
    yield put(getBagIdByBagNoSuccess(bagId));
    return result;
  } catch (error) {
    yield put(getBagIdByBagNoFailure(error));
    throw error;
  }
}

export function* getBagRentDateList(bagId) {
  yield put(getBagRentDateListRequest());
  try {
    const result = yield call(API.getBagRentDateList, { bagId });
    yield put(getBagRentDateListSuccess(result.data));
  } catch (error) {
    yield put(getBagRentDateListFailure(error));
    throw error;
  }
}

export function* getBagList(filterCondition = {}) {
  const assignedFilterCondition = buildFilterClause(filterCondition);
  yield put(getBagListRequest(assignedFilterCondition));
  try {
    const result = (yield call(API.getBagList, assignedFilterCondition));
    yield put(getBagListSuccess({
      data: result.data,
      filterCondition,
    }));
    return result;
  } catch (error) {
    yield put(getBagListFailure(error));
    throw error;
  }
}

export function* getCommentList(bagId) {
  yield put(getCommentListRequest());
  try {
    const result = yield call(API.getCommentList,
      {
        bagId,
        filter: {
          include: [
            {
              relation: 'resources',
              scope: { fields: 'uri' },
            },
            {
              relation: 'commentByUser',
              scope: { fields: ['name', 'communityName'] },
            },
          ],
        },
      });

    yield put(getCommentListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getCommentListFailure(error));
    throw error;
  }
}

export function* getMyBagList(userId, typeId, currentPage, limit = null, isOhPhireManage) {
  const filterCondition = {
    bagStatus: {
      inq: Object.keys(BagStatus.bagStatusId).map(bagStatus => BagStatus.bagStatusId[bagStatus]),
    },
    bagType: typeId,
    page: currentPage,
    getMyOnlineProduct: true,
    ownerId: userId,
    limit,
    isOhPhireManage,
    sortOrder: 1,
  };

  const assignedFilterCondition = buildFilterClause(filterCondition);
  yield put(getMyBagListRequest(assignedFilterCondition));
  try {
    const result = (yield call(API.getBagList, assignedFilterCondition));
    yield put(getMyBagListSuccess({
      data: result.data,
      filterCondition,
    }));
    return result;
  } catch (error) {
    yield put(getMyBagListFailure(error));
    throw error;
  }
}

export function* getOnlineWardrobe(userId) {
  const filterCondition = {
    bagStatus: {
      inq: [BagStatus.bagStatusId.inStock],
    },
    ownerId: userId,
    getClothes: true,
  };

  const assignedFilterCondition = buildFilterClause(filterCondition);
  yield put(getOnlineWardrobeRequest(assignedFilterCondition));
  try {
    const result = (yield call(API.getBagList, assignedFilterCondition));
    yield put(getOnlineWardrobeSuccess({
      data: result.data,
      filterCondition,
    }));
    return result;
  } catch (error) {
    yield put(getOnlineWardrobeFailure(error));
    throw error;
  }
}

export function* notifyWhenArrived(data) {
  try {
    yield put(notifyWhenArrivedRequest());
    const result = (yield call(API.notifyWhenArrived, data));
    yield put(notifyWhenArrivedSuccess(result.data));
    return result;
  } catch (error) {
    yield put(notifyWhenArrivedFailure(error));
    throw error;
  }
}

export function* submitBag(data) {
  yield put(submitBagRequest());
  try {
    Object.assign(data, {
      bagStatusId: BagStatus.bagStatusId.pending,
    });

    let result = null;
    if (!R.isEmpty(data)) {
      result = yield call(API.submitBag, data);

      // TODO: temporarily comment using originalPrice instead.
      if (result.status === 200) {
        const tagIds = [data.designerTagId, data.styleTagId, data.colorTagId];
        yield call(nonBlockedSagaAll,
          tagIds.map(tagId => call(API.addBagTag, {
            bagId: result.data.id,
            tagId,
          })));
      }
    }
    yield put(submitBagSuccess());
    return result;
  } catch (error) {
    yield put(submitBagFailure(error));
    throw error;
  }
}

export function* searchBagListByKeyword(payload) {
  yield put(searchBagListByKeywordRequest());
  const { keyword } = payload;
  try {
    const result = yield call(API.searchByKeyword,
      {
        where: {
          name: {
            like: `%${keyword}%`,
          },
          bagStatusId: {
            inq: [
              BagStatus.bagStatusId.inStock,
              BagStatus.bagStatusId.sold,
              BagStatus.bagStatusId.rented,
            ],
          },
        },
        include: [
          {
            relation: 'frontPic',
            scope: { fields: 'uri' },
          },
        ],
      });
    yield put(searchBagListByKeywordSuccess(result.data));
    return result;
  } catch (error) {
    yield put(searchBagListByKeywordFailure(error));
    throw error;
  }
}

function* getBagNumbers(payload) {
  const assignedFilterCondition = buildFilterClause(payload).where;
  try {
    yield put(getBagNumbersRequest());
    const result = yield call(API.getBagNumbers, assignedFilterCondition);
    yield put(getBagNumbersSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getBagNumbersFailure(error));
    throw (error);
  }
}

// ---------- FLOWS ----------

export function* getBagListFlow({ payload }) {
  try {
    const result = yield call(getBagList, payload);
    yield put(getBagListFlowSuccess(result));
  } catch (error) {
    yield put(getBagListFlowFailure(error));
  }
}

export function* getMyBagListFlow({ payload }) {
  try {
    const {
      typeId, currentPage, limit = null, isOhPhireManage,
    } = payload;
    const memberId = yield select(memberIdSelector);
    const result = yield call(getMyBagList, memberId, typeId, currentPage, limit, isOhPhireManage);
    yield put(getMyBagListFlowSuccess(result));
  } catch (error) {
    yield put(getMyBagListFlowFailure(error));
  }
}

export function* getOnlineWardrobeFlow({ payload }) {
  try {
    const { ownerId } = payload;
    const result = yield call(getOnlineWardrobe, ownerId);
    yield put(getOnlineWardrobeFlowSuccess(result));
  } catch (error) {
    yield put(getOnlineWardrobeFlowFailure(error));
  }
}

export function* getCommentListFlow({ payload }) {
  try {
    const result = yield call(getCommentList, payload);
    yield put(getCommentListFlowSuccess(result));
  } catch (error) {
    yield put(getCommentListFlowFailure(error));
  }
}

export function* getBagReleaseDateFlow({ payload }) {
  try {
    const result = yield call(getBagReleaseDate, payload);
    yield put(getBagReleaseDateFlowSuccess(result));
  } catch (error) {
    yield put(getBagReleaseDateFlowFailure(error));
  }
}

export function* getBagIdByBagNoFlow({ payload }) {
  try {
    const result = yield call(getBagIdByBagNo, payload);
    yield put(getBagIdByBagNoFlowSuccess(result));
  } catch (error) {
    yield put(getBagIdByBagNoFlowFailure(error));
  }
}

export function* getBagRentDateListFlow({ payload }) {
  try {
    const result = yield call(getBagRentDateList, payload);
    yield put(getBagRentDateListFlowSuccess(result));
  } catch (error) {
    yield put(getBagRentDateListFlowFailure(error));
  }
}

export function* notifyWhenArrivedFlow({ payload }) {
  try {
    const result = yield call(notifyWhenArrived, payload);
    yield put(notifyWhenArrivedFlowSuccess(result));
  } catch (error) {
    yield put(notifyWhenArrivedFlowFailure(error));
  }
}

export function* searchBagListByKeywordFlow({ payload }) {
  try {
    const result = yield call(searchBagListByKeyword, payload);
    yield put(searchBagListByKeywordFlowSuccess(result));
  } catch (error) {
    yield put(searchBagListByKeywordFlowFailure(error));
  }
}

export function* submitBagFlow({ payload }) {
  try {
    const memberId = yield select(memberIdSelector);

    // upload bag
    const results = yield call(nonBlockedSagaAll,
      payload.map(uploadBags => call(submitBag, {
        ...uploadBags,
        ownerId: memberId,
      })));
    const successDraftBagUUIDs = [];

    results.forEach((result) => {
      if (result.status === 200) {
        successDraftBagUUIDs.push(R.pathOr(null, ['data', 'uuid'], result));
      }
    });

    // delete the local storage
    const allDraftBags = LocalStorageUtils.readData(LocalStorageKey.DRAFT_OF_BAG, []);
    let restOfDraftBags = [];
    if (!R.isNil(allDraftBags) && !R.isEmpty(allDraftBags)) {
      restOfDraftBags = R.filter(draftBag => !R.includes(draftBag.uuid, successDraftBagUUIDs), allDraftBags);
    }

    if (restOfDraftBags !== null) {
      LocalStorageUtils.writeData(LocalStorageKey.DRAFT_OF_BAG,
        R.isEmpty(restOfDraftBags) || R.isNil(restOfDraftBags) ? [] : restOfDraftBags);
    }
    yield put(submitBagFlowSuccess());
  } catch (error) {
    yield put(submitBagFlowFailure(error));
  }
}

export function* getBagNumbersFlow({ payload }) {
  try {
    const result = yield call(getBagNumbers, payload);
    yield put(getBagNumbersFlowSuccess(result.data.count));
  } catch (error) {
    yield put(getBagNumbersFlowFailure(error));
  }
}

export default [
  takeLatest(getBagListFlowRequest, getBagListFlow),
  takeLatest(getMyBagListFlowRequest, getMyBagListFlow),
  takeLatest(notifyWhenArrivedFlowRequest, notifyWhenArrivedFlow),
  takeLatest(submitBagFlowRequest, submitBagFlow),
  takeLatest(getCommentListFlowRequest, getCommentListFlow),
  takeLatest(getBagReleaseDateFlowRequest, getBagReleaseDateFlow),
  takeLatest(getBagIdByBagNoFlowRequest, getBagIdByBagNoFlow),
  takeLatest(searchBagListByKeywordFlowRequest, searchBagListByKeywordFlow),
  takeLatest(getBagRentDateListFlowRequest, getBagRentDateListFlow),
  takeLatest(getBagNumbersFlowRequest, getBagNumbersFlow),
  takeLatest(getOnlineWardrobeFlowRequest, getOnlineWardrobeFlow),
];
