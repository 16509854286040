import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Assets from '../../../../constants/Assets';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';

const styles = {
  mainContainer: {
    margin: 0,
    textAlign: 'center',
    height: 'auto',
    minHeight: '500px',
    position: 'relative',
  },
  img: {
    objectFit: 'cover',
    height: 'auto',
    width: '100vw',
    right: '0',
    top: '0',
    marginLeft: 'calc(50% - 50vw)',
    minHeight: '600px',
  },
  brandContainer: {
    width: '100%',
    height: 'auto',
    position: 'relative',
  },
  mobilePicStyle: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
};

const AboutPage = ({
  data,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={styles.mainContainer}>
      <Default>
        <div style={styles.brandContainer}>
          <img alt="" src={Assets.aboutOh01V2} style={styles.img} />
        </div>
        <div style={styles.brandContainer}>
          <img alt="" src={Assets.aboutOh02V2} style={styles.img} />
        </div>
        <div style={styles.brandContainer}>
          <img alt="" src={Assets.aboutOh03V2} style={styles.img} />
        </div>
      </Default>
      <Mobile>
        {data.map(item => (
          <img
            key={item.imgUrl}
            alt=""
            src={item.imgUrl}
            style={styles.mobilePicStyle}
          />
        ))}
      </Mobile>
    </div>
  );
};

AboutPage.propTypes = {
  data: PropTypes.array.isRequired,
};
export default AboutPage;
