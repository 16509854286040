import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Arrow from './Arrow';
import Dots from './Dots';


const styles = {
  mainContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
    margin: '0 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

const SlideContent = styled.div`
  transform: translateX(${props => props.translate * (-100)}%);
  transition: transform ease-out ${props => props.transition}s;
  height: 100%;
  width: auto;
`;

const SlideComponent = ({
  data,
  layout,
  arrow,
  dot,
  slideDirection,
  dotStyle,
  dotContainerStyle,
}) => {
  const transition = 0.5;
  const [activeIndex, setActiveIndex] = useState(0);
  const [translate, setTranslate] = useState(0);

  const nextSlide = () => {
    const next = activeIndex + 1;

    if (activeIndex === data.length - 1) {
      return (
        setActiveIndex(0),
        setTranslate(0)
      );
    }
    return (
      setActiveIndex(next),
      setTranslate(next)
    );
  };


  const prevSlide = () => {
    const prev = activeIndex - 1;

    if (activeIndex === 0) {
      return (
        setActiveIndex(data.length - 1),
        setTranslate(data.length - 1)
      );
    }

    return (
      setActiveIndex(prev),
      setTranslate(prev)
    );
  };

  const clickDot = (index) => {
    setActiveIndex(index);
    setTranslate(index);
  };

  return (
    <div style={styles.mainContainer}>

      <SlideContent
        translate={translate}
        transition={transition}
      >
        {layout}
      </SlideContent>

      {
        arrow ? (
          <div>
            <Arrow direction="left" handleClick={prevSlide} />
            <Arrow direction="right" handleClick={nextSlide} />
          </div>
        ) : null
      }

      {
        dot ? (
          <Dots
            slides={data}
            activeIndex={activeIndex}
            handleClick={clickDot}
            slideDirection={slideDirection}
            dotStyle={dotStyle}
            dotContainerStyle={dotContainerStyle}
          />
        ) : null
      }
    </div>
  );
};

SlideComponent.propTypes = {
  data: PropTypes.array.isRequired,
  layout: PropTypes.array.isRequired,
  arrow: PropTypes.bool,
  dot: PropTypes.bool,
  slideDirection: PropTypes.string,
  dotStyle: PropTypes.object,
  dotContainerStyle: PropTypes.object,
};

SlideComponent.defaultProps = {
  arrow: false,
  dot: false,
  slideDirection: null,
  dotStyle: null,
  dotContainerStyle: null,
};
export default SlideComponent;
