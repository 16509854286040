import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';
import MemberHeader from './MemberHeader';
import ActionCreators from '../../ActionCreator';
import OrderActionCreators from '../../../Order/ActionCreator';
import BagStatus from '../../../../constants/BagStatus';
import { orderStatusId } from '../../../../constants/OrderStatus';

import {
  memberNameSelector,
  reduxStatusSelector,
  reduxErrorSelector,
  memberIdSelector,
  memberAvatarUriSelector,
  memberProfileSelector,
  memberEmailSelector,
} from '../../selector';

import {
  orderListErrorSelector,
  orderListReduxStatusSelector,
  orderNumbersSelector,
} from '../../../Order/selector';


const MemberHeaderContainer = ({
  reduxStatus,
  reduxError,
  memberName,
  path,
  category,
  memberAvatarUri,
  memberId,
  actions: {
    getMemberProfileFlowRequest,
    resetStatus,
    getOrderNumbersFlowRequest,
    passbaseNewAuthenticationFlowRequest,
  },
  memberProfile,
  memberEmail,
  orderNumbers,
}) => {
  useEffect(() => {
    if (memberId) {
      getMemberProfileFlowRequest(memberId);
      getOrderNumbersFlowRequest(
        {
          typeId: 1,
          bagOwnerId: memberId,
          orderStatusId: {
            nin: [orderStatusId.canceled, orderStatusId.canceledUnconfirmed, orderStatusId.confirming, orderStatusId.create],
          },
        },
      );
    }
    return resetStatus;
  }, [getMemberProfileFlowRequest, memberId, resetStatus]);

  const bags = R.pathOr([], ['bags'], memberProfile);
  const earnAmount = R.pathOr(0, ['earnAmount'], memberProfile);
  const communityName = R.pathOr(0, ['communityName'], memberProfile);
  const bagNumber = bags.length;

  const dealtBags = bags.reduce((accumulator, currentBag) => {
    const status = R.pathOr([], ['bagStatus', 'name'], currentBag);
    if (status === BagStatus.bagStatus.sold) {
      return accumulator + 1;
    }
    return accumulator;
  }, 0);

  const rentedOrders = bags.reduce((accumulator, currentBag) => {
    const rentCount = R.pathOr(0, ['rentCount'], currentBag);
    if (rentCount) {
      return accumulator + rentCount;
    }
    return accumulator;
  }, 0);

  const handleOnPassbaseAuthentication = (authKey) => {
    passbaseNewAuthenticationFlowRequest({ authenticationKey: authKey });
  };

  return (
    <MemberHeader
      reduxStatus={reduxStatus}
      reduxError={reduxError}
      memberName={memberName}
      communityName={communityName}
      path={path}
      category={category}
      memberAvatarUri={memberAvatarUri}
      memberId={memberId}
      bagNumber={bagNumber}
      dealtBags={dealtBags}
      rentedOrders={rentedOrders}
      earnAmount={earnAmount}
      memberEmail={memberEmail}
      memberProfile={memberProfile}
      onPassbaseAuthentication={handleOnPassbaseAuthentication}
      orderNumbers={orderNumbers}
    />
  );
};

MemberHeaderContainer.propTypes = {
  reduxStatus: PropTypes.string,
  reduxError: PropTypes.string,
  memberName: PropTypes.string,
  memberAvatarUri: PropTypes.string,
  memberId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  path: PropTypes.string,
  category: PropTypes.string,
  memberProfile: PropTypes.object,
  memberEmail: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    getMemberProfileFlowRequest: PropTypes.func.isRequired,
    passbaseNewAuthenticationFlowRequest: PropTypes.func.isRequired,
    getOrderNumbersFlowRequest: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
  }).isRequired,
  orderNumbers: PropTypes.number,
};

MemberHeaderContainer.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  memberAvatarUri: null,
  memberName: '',
  memberId: 0,
  path: '',
  category: 'personalInfo',
  memberProfile: {},
  orderNumbers: 0,
};

const mapStateToProps = state => ({
  reduxStatus: reduxStatusSelector(state),
  reduxError: reduxErrorSelector(state),
  memberName: memberNameSelector(state),
  memberId: memberIdSelector(state),
  memberAvatarUri: memberAvatarUriSelector(state),
  memberProfile: memberProfileSelector(state),
  memberEmail: memberEmailSelector(state),
  orderListReduxStatus: orderListReduxStatusSelector(state),
  orderListError: orderListErrorSelector(state),
  orderNumbers: orderNumbersSelector(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...ActionCreators,
    ...OrderActionCreators,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberHeaderContainer);
