import { createActions } from 'redux-actions';
import { createAsyncActionTypesArray } from '../../utils/createAsyncActionTypes';

export default createActions(
  // ----- TASKS -----
  ...createAsyncActionTypesArray('GET_HEADER_COMMODITY_LIST'),
  ...createAsyncActionTypesArray('RESET_HEADER_COMMODITY'),
  ...createAsyncActionTypesArray('GET_MAQUEE_LIST'),

  // ----- FLOWS -----
  ...createAsyncActionTypesArray('GET_HEADER_COMMODITY_LIST_FLOW'),
  ...createAsyncActionTypesArray('GET_MAQUEE_LIST_FLOW'),

  // ----- STATUS -----
  'RESET_STATUS',
  { prefix: 'HEADER_COMMODITY' },
);
