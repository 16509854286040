// The unit is 4 days
module.exports = {
  getInstallmentRate: (installment) => {
    if (installment === 3) {
      return 0.03;
    }
    if (installment === 6) {
      return 0.035;
    }
    if (installment === 9) {
      return 0.045;
    }
    if (installment === 12) {
      return 0.055;
    }
    if (installment === 18) {
      return 0.08;
    }

    return 0.1;
  },
};
