import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import ActionCreator from './ActionCreator';
import API from './API';
import { buildFilterClause } from '../Bag/saga';

const {
  getThemeListRequest,
  getThemeListSuccess,
  getThemeListFailure,
  getThemeBagRequest,
  getThemeBagSuccess,
  getThemeBagFailure,

  getThemeListFlowRequest,
  getThemeListFlowSuccess,
  getThemeListFlowFailure,
  getThemeBagFlowRequest,
  getThemeBagFlowSuccess,
  getThemeBagFlowFailure,
} = ActionCreator;

// ---------- TASKS ----------
export function* getThemeList() {
  yield put(getThemeListRequest());
  try {
    const result = (yield call(API.getThemeList));
    yield put(getThemeListSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getThemeListFailure(error));
    throw error;
  }
}

export function* getThemeBag(id) {
  yield put(getThemeBagRequest());
  try {
    const {
      limit,
      skip,
      ...filterCondition
    } = buildFilterClause();
    const result = (yield call(API.getThemeBag, {
      id,
      filter: filterCondition,
    }));
    yield put(getThemeBagSuccess(result.data));
    return result;
  } catch (error) {
    yield put(getThemeBagFailure(error));
    throw error;
  }
}

// ---------- FLOWS ----------

export function* getThemeListFlow({ payload }) {
  try {
    const result = yield call(getThemeList, payload);
    yield put(getThemeListFlowSuccess(result));
  } catch (error) {
    yield put(getThemeListFlowFailure(error));
  }
}

export function* getThemeBagFlow({ payload }) {
  try {
    const result = yield call(getThemeBag, payload);
    yield put(getThemeBagFlowSuccess(result));
  } catch (error) {
    yield put(getThemeBagFlowFailure(error));
  }
}

export default [
  takeLatest(getThemeListFlowRequest, getThemeListFlow),
  takeLatest(getThemeBagFlowRequest, getThemeBagFlow),
];
