import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

const styles = {
  mainContainer: {
    height: 'auto',
    width: '100%',
    display: 'inline-block',
    whiteSpace: 'normal',
    position: 'relative',
    textAlign: 'center',
    overflow: 'hidden',
  },
  pic: {
    objectFit: 'cover',
    height: 'auto',
    width: '100vw',
    right: '0',
    top: '0',
    marginLeft: 'calc(50% - 50vw)',
  },
  eventContainer: {
    minWidth: '200px',
    maxWidth: '600px',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
  },
  title: {
    fontSize: '60px',
  },
  name: {
    fontSize: '18px',
    margin: '10px 0 40px 0',
  },
  linkStyle: {
    opacity: 1,
  },
};

const BagBannerSlide = ({
  imgUrl,
  style,
  path,
  mobileImgStyle,
}) => (
  <div style={{ ...styles.mainContainer, ...style }}>
    {
      path ? (
        <Link to={path} style={styles.linkStyle}>
          <img alt="" src={imgUrl} style={{ ...styles.pic, ...mobileImgStyle }} />
        </Link>
      )
        : <img alt="" src={imgUrl} style={{ ...styles.pic, ...mobileImgStyle }} />
    }
  </div>
);


BagBannerSlide.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  style: PropTypes.object,
  path: PropTypes.string,
  mobileImgStyle: PropTypes.object,
};

BagBannerSlide.defaultProps = {
  style: null,
  path: null,
  mobileImgStyle: {},
};


const MemoBagBannerSlide = memo(BagBannerSlide);

export default MemoBagBannerSlide;
