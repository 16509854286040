import { handleActions, combineActions } from 'redux-actions';
import * as R from 'ramda';
import update from 'immutability-helper';
import ReduxStatus from '../../constants/ReduxStatus';
import ActionCreator from './ActionCreator';

const {
  getHeaderCommodityListFlowRequest,
  getHeaderCommodityListSuccess,
  getHeaderCommodityListFailure,

  resetHeaderCommodityRequest,
  resetStatus,
  getMaqueeListFlowRequest,
  getMaqueeListFlowSuccess,
  getMaqueeListFlowFailure,
} = ActionCreator;

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: [],
  mequee: [],
};

export default handleActions({
  // request
  [combineActions(
    getHeaderCommodityListFlowRequest,
    getMaqueeListFlowRequest,
  )]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_LOADING },
    error: { $set: null },
  }),

  [resetHeaderCommodityRequest]: state => update(state, {
    data: { $set: [] },
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
    error: { $set: null },
  }),

  // success
  [combineActions(
    getHeaderCommodityListSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    data:
      R.pathOr(0, ['filterCondition', 'page'], payload) === 0
        ? { $set: payload.data }
        : { $push: payload.data },
    filterCondition: { $set: payload.filterCondition },
    error: { $set: null },
  }),
  [combineActions(
    getMaqueeListFlowSuccess,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_NORMAL },
    mequee: { $set: payload },
  }),

  // failure
  [combineActions(
    getHeaderCommodityListFailure,
    getMaqueeListFlowFailure,
  )]: (state, { payload }) => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_ERROR },
    error: { $set: payload },
  }),
  [resetStatus]: state => update(state, {
    reduxStatus: { $set: ReduxStatus.STATUS_INITIAL },
  }),
}, initialState);
