import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Assets from '../../constants/Assets';

import styles from './style.module.scss';

const Modal = ({
  isOpen, onClose, children, contentClassName,
}) => {
  const backgroundRef = useRef(null);
  const [closing, setClosing] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles['no-scroll']);
    } else {
      document.body.classList.remove(styles['no-scroll']);
    }

    return () => {
      document.body.classList.remove(styles['no-scroll']);
    };
  }, [isOpen]);

  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      setClosing(false);
      onClose();
    }, 300);
  };

  const handleClick = (e) => {
    if (e.target === backgroundRef.current) {
      handleClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      ref={backgroundRef}
      className={`${styles['modal-overlay']} ${closing ? styles.closing : ''}`}
      onClick={handleClick}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      <div
        className={
          classnames(styles['modal-content'], contentClassName,
            [
              { closing: styles.closing },
            ])}
      >
        <button
          type="button"
          onClick={handleClose}
          aria-label="Close Modal"
        >
          <img alt="" className={styles.closeButton} src={Assets.closeIcon} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  contentClassName: PropTypes.string,
};

Modal.defaultProps = {
  contentClassName: '',
};
