import { handleActions } from 'redux-actions';
import ReduxStatus from '../../constants/ReduxStatus';

const initialState = {
  reduxStatus: ReduxStatus.STATUS_INITIAL,
  error: null,
  data: {},
};

export default handleActions({
}, initialState);
