import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';
import { Link } from '@reach/router';
import { Avatar, Icon, Button } from 'antd';
import CancelOrderModalComponent from './CancelOrderModalComponent';
import ProductSendModalComponent from './ProductSendModalComponent';
import ProductReturnConfirmComponent from './ProductReturnConfirmModalComponent';
import ProductIssueReportComponent from './ProductIssueReportModalComponent';
import amountPattern from '../../../utils/AmountPattern';
import DefaultButton from '../../../components/Button';
import { orderStatus, orderStatusId } from '../../../constants/OrderStatus';
import { Colors } from '../../../constants/Color';
import Default from '../../../components/RWDComponent/Default';
import Mobile from '../../../components/RWDComponent/Mobile';
import OrderDetailMobileComponent from './OrderDetailMobileComponent';
import OrderBagCommentComponent from './OrderBagCommentComponent';
import Paths from '../../../routes/Paths';
import InfoModalComponent from '../../../components/InfoModalComponent';
import calculateOrderNo from '../../../utils/calculateOrderNo';
import RenderOrderStatusPanel from './RenterOrderStatusPanel';
import LenderOrderStatusPanel from './LenderOrderStatusPanel';
import { shippingCost } from '../../../constants/ShippingAmount';
import Assets from '../../../constants/Assets';
import { commodityTypeId } from '../../../constants/Type';
import bagOrClothesPath from '../../../utils/bagOrClothesPath';

const styles = {
  bottomContainer: {
    backgroundColor: '#F2F2F2',
    padding: '20px 30px',
    margin: '15px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  narrowColumn: {
    width: '5%',
    textAlign: 'center',
  },
  buyColumn: {
    width: '10%',
    textAlign: 'center',
  },
  nanoColumn: {
    width: '1%',
    textAlign: 'center',
  },
  orderStatusColumn: {
    width: '17%',
    textAlign: 'center',
  },
  alignCenterContainer: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerNormalColumn: {
    padding: 10,
    width: '15%',
    borderRight: '1px solid #BABABA',
    borderLeft: '1px solid #BABABA',
  },
  innerTimeIntervalColumn: {
    padding: 10,
    width: '90%',
    borderRight: '1px solid #BABABA',
    borderLeft: '1px solid #BABABA',
    textAlign: 'left',
  },
  outerNormalColumn: {
    padding: 10,
    width: '12%',
    textAlign: 'center',
  },
  expand: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    color: 'black',
  },
  img: {
    width: 'auto',
    objectFit: 'contain',
    height: 150,
    backgroundColor: 'white',
    minWidth: '100px',
  },
  mainColor: {
    color: '#AE4200',
    fontSize: 16,
  },
  subColor: {
    color: 'black',
    fontSize: 16,
  },
  tableBottomBorder: {
    borderBottom: '1px solid #BABABA',
  },
  tableLeftBorder: {
    borderLeft: '1px solid #BABABA',
  },
  mainTable: {
    tableLayout: 'fixed',
    borderBottom: '1px solid #BABABA',
    width: '100%',
    minWidth: '900px',
    margin: '20px 0px',
  },
  mainInnerTable: {
    tableLayout: 'fixed',
    borderBottom: '1px solid #BABABA',
    width: '100%',
    minWidth: '900px',
  },
  innerTopTable: {
    marginTop: '20px',
    borderTop: '1px solid #BABABA',
    textAlign: 'center',
  },
  innerBottomTable: {
    marginBottom: '20px',
    borderTop: '1px solid #BABABA',
    textAlign: 'center',
  },
  thead: {
    height: '30px',
    width: '100px',
  },
  button: {
    backgroundColor: Colors.orderBrown,
    color: 'white',
    height: '50px',
    borderRadius: '5px',
    maxWidth: '120px',
    minWidth: '60px',
  },
  buttonDisable: {
    backgroundColor: Colors.orderBrown,
    color: 'white',
    maxWidth: '120px',
    minWidth: '60px',
    height: '50px',
    borderRadius: '5px',
    cursor: 'not-allowed',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  withdrawButton: {
    backgroundColor: 'white',
    color: '#888888',
    border: `2px ${Colors.introRightTitle} solid`,
    maxWidth: '100px',
    minWidth: '60px',
    height: '50px',
  },
  withdrawButtonDisable: {
    backgroundColor: 'white',
    color: '#888888',
    border: '2px grey solid',
    maxWidth: '100px',
    minWidth: '60px',
    height: '50px',
    cursor: 'not-allowed',
  },
  alignCenterTd: {
    textAlign: 'center',
  },
  orderRole: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  avatarMarginRight: {
    marginRight: 5,
  },
  icon: {
    fontSize: 20,
    color: '#927764',
  },
  shippingInfoContainer: {
    textAlign: 'left',
  },
  shippingInfoOrderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-Start',
    alignItems: 'flex-Start',
  },
  shippingInfoMobileContainer: {
    textAlign: 'left',
    width: '80%',
    margin: '0px auto',
  },
  shippingTitle: {
    color: 'white',
    backgroundColor: '#927764',
    borderRadius: 10,
    padding: '4px 3px',
    width: '70px',
    height: '26px',
    textAlign: 'center',
    margin: '3px 0px',
  },
  buyIconText: {
    color: 'white',
    backgroundColor: '#927764',
    borderRadius: 15,
    padding: '0px 3px',
    width: '70px',
    textAlign: 'center',
    margin: '3px 0px',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0%)',
  },
  buyIconTextMobile: {
    color: 'white',
    backgroundColor: '#927764',
    borderRadius: 15,
    padding: '0px 3px',
    width: '70px',
    textAlign: 'center',
    margin: '3px 0px',
  },
  userCouponContainer: {
    margin: '5px 0px',
    height: 'fit-content',
    padding: 10,
    width: '100%',
    backgroundColor: '#eee',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  opReplyContainer: {
    margin: '5px 0px',
    height: 'fit-content',
    padding: 10,
    width: '100%',
    backgroundColor: '#eee',
    textAlign: 'left',
  },
  delayInfo: {
    color: 'red',
    fontSize: '14px',
    textAlign: 'left',
  },
  delayText: {
    color: 'red',
    fontSize: '12px',
    textAlign: 'left',
  },
  buyIconContainer: {
    textAlign: 'center',
    position: 'relative',
    height: '30px',
  },
  infoBlock: {
    width: '50%',
    height: '120px',
  },
  infoBlockOrderList: {
    width: 'fit-content',
    height: 'auto',
  },
  rentToBuyContainer: {
    backgroundColor: '#EDE6DA',
    width: '80%',
    height: '160px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginBottom: '20px',
  },
  rentToBuyMobileContainer: {
    backgroundColor: '#EDE6DA',
    width: '50%',
    height: '160px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginBottom: '20px',
  },
  buyText: {
    color: 'black',
  },
  dateText: {
    textAlign: 'center',
  },
  dateTextMobile: {
    textAlign: 'left',
  },
  opIcon: {
    width: '120px',
  },
  sendDateContainer: {
    color: Colors.introRightTitle,
  },
  sendText: {
    color: 'black',
  },
};

const OrderDetailTableComponent = ({
  userId,
  order,
  renderOperate,
  renderRentToBuyButton,
  isRentedSoldHistory,
  onSubmitWithdrawRequest,
  onConfirmOrder,
  index,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const [isShowCommentModal, setIsShowCommentModal] = useState(false);
  const [isShowCancelOrderModal, setIsShowCancelOrderModal] = useState(false);
  const [isShowProductSendModal, setIsShowProductSendModal] = useState(false);
  const [isShowProductReturnConfirmModal, setIsShowProductReturnConfirmModal] = useState(false);
  const [isShowProductIssueReportModal, setIsShowProductIssueReportModal] = useState(false);
  const isComment = R.pathOr(false, ['commentId'], order);
  const commentStars = R.pathOr(0, ['comment', 'stars'], order);
  const historyReply = R.pathOr('', ['historyReply'], order);
  const delayDays = R.pathOr(0, ['delayDays'], order);
  const delayDeduct = R.pathOr(0, ['delayDeduct'], order);
  const delayDeductToLender = R.pathOr(0, ['delayDeductToLender'], order);
  const couponCode = R.pathOr(
    null,
    ['userCoupon', 'coupon', 'couponCode'],
    order,
  );

  const couponAmount = R.pathOr(0, ['couponAmount'], order);
  const commodityType = R.pathOr(1, ['commodityTypeId'], order);

  const renderRentTimeOrBuyTableContent = (isMobile) => {
    if (
      isRentedSoldHistory
      && R.pathOr('rent', ['type', 'name'], order) === 'buy'
    ) {
      return R.pathOr('', ['user', 'name'], order);
    }
    if (
      !isRentedSoldHistory
      && R.pathOr('rent', ['type', 'name'], order) === 'buy'
    ) {
      return (
        <div style={styles.buyIconContainer}>
          <div style={styles.buyIconTextMobile}>購買</div>
        </div>
      );
    }
    return isMobile ? (
      <div style={styles.dateTextMobile}>
        {`${R.pathOr(0, ['rentDays'], order)}天`}
        <br />
        {`${moment(R.pathOr('', ['rentStartDate'], order)).format(
          'YYYY/MM/DD',
        )}-
          ${moment(R.pathOr('', ['rentEndDate'], order)).format('YYYY/MM/DD')}`}
      </div>
    ) : (
      <div style={styles.dateText}>
        {`${R.pathOr(0, ['rentDays'], order)} 天`}
        <br />
        <br />
        {`${moment(R.pathOr('', ['rentStartDate'], order)).format(
          'YYYY/MM/DD',
        )}`}
        <br />
        {`${moment(R.pathOr('', ['rentEndDate'], order)).format('YYYY/MM/DD')}`}
      </div>
    );
  };

  const renderCommentButton = () => {
    const status = R.pathOr('', ['orderStatus', 'name'], order);

    const checkIsAbleComment = () => {
      if (status === orderStatus.lenderReceive) {
        return true;
      }
      if (status === orderStatus.shipSuccess_returnDeposit) {
        return true;
      }
      if (status === orderStatus.lenderSent) {
        return true;
      }
      if (status === orderStatus.renterSent) {
        return true;
      }
      if (status === orderStatus.confirmed) {
        return true;
      }
      return false;
    };
    return (
      <DefaultButton
        text="我要評論"
        style={checkIsAbleComment() ? styles.button : styles.buttonDisable}
        onClick={() => {
          setIsShowCommentModal(true);
        }}
        disabled={!checkIsAbleComment()}
      />
    );
  };

  const renderOrderStatus = () => {
    const inComingOrderStatusId = R.pathOr('', ['orderStatusId'], order);
    if (
      inComingOrderStatusId === orderStatusId.confirming
      || inComingOrderStatusId === orderStatusId.confirmed
      || inComingOrderStatusId === orderStatusId.lenderSent
      || inComingOrderStatusId === orderStatusId.renterSent
      || inComingOrderStatusId === orderStatusId.lenderReceive
      || inComingOrderStatusId === orderStatusId.shipSuccess_returnDeposit
    ) {
      return <Icon type="check-circle" theme="filled" style={styles.icon} />;
    }
    return R.pathOr('', ['orderStatus', 'name'], order);
  };

  const renderDepositWithCoupon = () => {
    const couponTypeName = R.path(
      ['userCoupon', 'coupon', 'couponType', 'name'],
      order,
    );
    if (couponTypeName === '押金折扣碼') {
      return `NT $${amountPattern(
        R.pathOr(0, ['deposit'], order) - R.pathOr(0, ['couponAmount'], order),
      )}`;
    }

    return `NT $${amountPattern(R.pathOr(0, ['deposit'], order))}`;
  };

  const renderRenterToBuyContent = isMobile => (renderRentToBuyButton(order) ? (
    <React.Fragment>
      {moment(new Date())
        < moment(R.pathOr('', ['rentEndDate'], order)).add(2, 'day') ? (
          <div
            style={
              isMobile
                ? styles.rentToBuyMobileContainer
                : styles.rentToBuyContainer
            }
          >
            {renderRentToBuyButton(order)}
            <div style={styles.buyText}>
              {`購買價格 NT$${amountPattern(
                R.pathOr(0, ['bagBuyAmount'], order)
                  - R.pathOr(0, ['amount'], order)
                  - R.pathOr(0, ['deposit'], order),
              )}`}
            </div>
          </div>
        ) : null}
    </React.Fragment>
  ) : null);

  const renderDelayInfo = (
    <React.Fragment>
      {delayDays > 0 ? (
        <div style={styles.userCouponContainer}>
          <div style={styles.delayInfo}>
            {`延遲天數: ${delayDays}`}
            <br />
            {isRentedSoldHistory
              ? `提撥延遲金額: $${delayDeductToLender}`
              : `酌收延遲金額: $${delayDeduct}`}
          </div>
          <div style={styles.delayText}>
            {isRentedSoldHistory
              ? '*為保障您的租借權益，延遲歸還平台將提撥延遲補償費用（平均日租金*延遲天數*1.5倍)'
              : '*為保障其他會員租借權益，延遲歸還將酌收費用（平均日租金*延遲天數*1.5倍)'}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );

  const renderReply = (
    <React.Fragment>
      {!isRentedSoldHistory && historyReply ? (
        <div style={styles.opReplyContainer}>{`客服回覆: ${historyReply}`}</div>
      ) : null}
    </React.Fragment>
  );

  const shippingInfo = (isOrderList) => {
    const content = (
      <React.Fragment>
        <div style={isOrderList ? styles.infoBlock : styles.infoBlockOrderList}>
          <div style={styles.shippingTitle}>出貨資訊</div>
          {isOrderList ? <br /> : null}
          {`物流公司： ${R.pathOr('', ['lenderShippingCompany'], order)}`}
          <br />
          {isOrderList ? <br /> : null}
          {`查詢號碼： ${R.pathOr('', ['lenderSentPackageNo'], order)}`}
          <br />
        </div>
        <div style={isOrderList ? styles.infoBlock : styles.infoBlockOrderList}>
          <div style={styles.shippingTitle}>歸還資訊</div>
          {isOrderList ? <br /> : null}
          {`物流公司： ${R.pathOr('', ['renterShippingCompany'], order)}`}
          <br />
          {isOrderList ? <br /> : null}
          {`查詢號碼： ${R.pathOr('', ['renterSentPackageNo'], order)}`}
          <br />
        </div>
      </React.Fragment>
    );

    if (isOrderList) {
      return <div style={styles.shippingInfoOrderContainer}>{content}</div>;
    }

    return (
      <React.Fragment>
        <Default>
          <td style={styles.narrowColumn}>
            <div style={styles.shippingInfoContainer}>{content}</div>
          </td>
        </Default>
        <Mobile>
          <div style={styles.shippingInfoMobileContainer}>{content}</div>
        </Mobile>
      </React.Fragment>
    );
  };

  const renderShippingDate = () => {
    const isOhPhireManage = R.pathOr(false, ['bag', 'isOhPhireManage'], order);

    if (isOhPhireManage) {
      return <img src={Assets.opMangerIcon} style={styles.opIcon} alt="" />;
    }

    if (commodityType === commodityTypeId.bag && !isOhPhireManage) {
      return (
        <div style={styles.sendDateContainer}>
          {`${moment(R.pathOr('', ['rentStartDate'], order))
            .subtract(3, 'days')
            .format('YYYY/MM/DD')} `}
          <span style={styles.sendText}>前寄出</span>
        </div>
      );
    }

    return (
      <div style={styles.sendDateContainer}>
        {`${moment(R.pathOr('', ['rentStartDate'], order))
          .subtract(5, 'days')
          .format('YYYY/MM/DD')} `}
        <span style={styles.sendText}>前寄出</span>
      </div>
    );
  };
  return (
    <React.Fragment>
      <InfoModalComponent
        isVisible={isShowCommentModal}
        onModalClose={() => setIsShowCommentModal(false)}
        content={<OrderBagCommentComponent orderData={order} />}
        containerStyle={{ paddingTop: '10vh' }}
      />

      <CancelOrderModalComponent
        userId={userId}
        order={order}
        isVisible={isShowCancelOrderModal}
        onModalClose={() => setIsShowCancelOrderModal(false)}
      />

      <ProductSendModalComponent
        userId={userId}
        order={order}
        isVisible={isShowProductSendModal}
        onModalClose={() => setIsShowProductSendModal(false)}
      />

      <ProductReturnConfirmComponent
        order={order}
        userId={userId}
        isVisible={isShowProductReturnConfirmModal}
        onModalClose={() => setIsShowProductReturnConfirmModal(false)}
      />

      <ProductIssueReportComponent
        userId={userId}
        isVisible={isShowProductIssueReportModal}
        onModalClose={() => setIsShowProductIssueReportModal(false)}
      />

      <Default>
        {isRentedSoldHistory ? (
          <table style={styles.mainTable}>
            <thead style={styles.thead}>
              <tr>
                <td style={styles.narrowColumn}>
                  {calculateOrderNo(R.pathOr('', ['id'], order))}
                </td>
                <td style={styles.narrowColumn}>
                  <div style={styles.alignCenterContainer}>
                    <div style={styles.imgContainer}>
                      <Link
                        to={`${bagOrClothesPath(commodityType)}/${R.pathOr(
                          null,
                          ['bag', 'bagNo'],
                          order,
                        )}`}
                      >
                        <img
                          alt=""
                          style={styles.img}
                          src={R.pathOr(
                            null,
                            ['bag', 'frontPic', 'uri'],
                            order,
                          )}
                        />
                      </Link>
                    </div>
                  </div>
                </td>
                <td style={styles.narrowColumn}>{renderOrderStatus()}</td>
                <td style={styles.narrowColumn}>
                  {R.pathOr('rent', ['type', 'name'], order) === 'buy' ? (
                    <div style={styles.buyIconContainer}>
                      <div style={styles.buyIconText}>購買</div>
                    </div>
                  ) : (
                    <div style={styles.buyIconContainer}>
                      <div style={styles.buyIconText}>租借</div>
                    </div>
                  )}
                </td>
                <td style={styles.orderStatusColumn}>
                  <div style={styles.alignCenterContainer}>
                    <LenderOrderStatusPanel
                      order={order}
                      onConfirmOrder={() => onConfirmOrder()}
                      onCancelOrder={() => setIsShowCancelOrderModal(true)}
                      onProductSend={() => setIsShowProductSendModal(true)}
                      onProductReturnConfirm={() => setIsShowProductReturnConfirmModal(true)
                      }
                      onProductIssueReport={() => setIsShowProductIssueReportModal(true)
                      }
                    />
                  </div>
                </td>
                <td style={styles.buyColumn}>{renderShippingDate()}</td>
                <td style={styles.nanoColumn}>
                  <Button
                    icon={isExpand ? 'up' : 'down'}
                    onClick={() => setIsExpand(!isExpand)}
                    type="link"
                    style={styles.expand}
                  />
                </td>
              </tr>
            </thead>
          </table>
        ) : (
          <table style={styles.mainTable}>
            <thead style={styles.thead}>
              <tr>
                <td style={styles.narrowColumn}>
                  <div style={styles.alignCenterContainer}>
                    <div style={styles.imgContainer}>
                      <Link
                        to={`${Paths.BAG_DETAIL}/${R.pathOr(
                          null,
                          ['bag', 'bagNo'],
                          order,
                        )}`}
                      >
                        <img
                          alt=""
                          style={styles.img}
                          src={R.pathOr(
                            null,
                            ['bag', 'frontPic', 'uri'],
                            order,
                          )}
                        />
                      </Link>
                    </div>
                  </div>
                </td>
                <td style={styles.narrowColumn}>
                  {renderOperate(
                    R.pathOr('0', ['id'], order),
                    R.pathOr('', ['orderStatus', 'name'], order),
                    R.pathOr('rent', ['type', 'name'], order),
                    R.pathOr(0, ['zeroCardInstallment'], order),
                    R.pathOr(null, ['zeroCardStatus'], order),
                    R.pathOr(null, ['payWayType'], order),
                    index,
                  )}
                </td>
                <td style={styles.narrowColumn}>
                  {R.pathOr('rent', ['type', 'name'], order) === 'buy' ? (
                    <div style={styles.buyIconContainer}>
                      <div style={styles.buyIconText}>購買</div>
                    </div>
                  ) : (
                    <div style={styles.buyIconContainer}>
                      <div style={styles.buyIconText}>租借</div>
                    </div>
                  )}
                </td>
                <td style={styles.orderStatusColumn}>
                  <div style={styles.alignCenterContainer}>
                    <RenderOrderStatusPanel
                      order={order}
                      onCancelOrder={() => setIsShowCancelOrderModal(true)}
                      onProductSend={() => setIsShowProductSendModal(true)}
                      renderCommentButton={renderCommentButton}
                    />
                  </div>
                </td>
                <td style={styles.narrowColumn}>{renderCommentButton()}</td>
                <td style={styles.buyColumn}>{renderRenterToBuyContent()}</td>
                <td style={styles.nanoColumn}>
                  <Button
                    icon={isExpand ? 'up' : 'down'}
                    onClick={() => setIsExpand(!isExpand)}
                    type="link"
                    style={styles.expand}
                  />
                </td>
              </tr>
            </thead>
          </table>
        )}

        {isExpand ? (
          <React.Fragment>
            <table
              style={{ ...styles.mainInnerTable, ...styles.innerTopTable }}
            >
              <thead>
                <tr style={styles.tableBottomBorder}>
                  <th style={styles.innerNormalColumn}>訂單日期</th>
                  <th style={styles.innerNormalColumn}>訂單編號</th>
                  <th style={styles.innerNormalColumn}>商品名稱</th>
                  <th style={styles.innerNormalColumn}>
                    {isRentedSoldHistory
                    && R.pathOr('rent', ['type', 'name'], order) === 'buy'
                      ? '買主'
                      : '租借時間'}
                  </th>
                  <th style={styles.innerNormalColumn}>折扣代碼</th>
                  <th style={styles.innerNormalColumn}>
                    {R.pathOr('rent', ['type', 'name'], order) === 'buy'
                      ? '金額'
                      : '押金/租金'}
                  </th>
                  {isRentedSoldHistory ? (
                    <React.Fragment>
                      <th style={styles.innerNormalColumn}>實際賺取</th>
                    </React.Fragment>
                  ) : null}
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style={styles.innerNormalColumn}>
                    {moment(R.pathOr('', ['createDate'], order)).format(
                      'YYYY/MM/DD',
                    )}
                  </td>
                  <td style={styles.innerNormalColumn}>
                    {calculateOrderNo(R.pathOr('', ['id'], order))}
                  </td>
                  <td style={styles.innerNormalColumn}>
                    {R.pathOr('', ['bag', 'name'], order)}
                  </td>
                  <td style={styles.innerNormalColumn}>
                    {renderRentTimeOrBuyTableContent()}
                  </td>
                  <td style={styles.innerNormalColumn}>
                    {couponCode ? `${couponCode} (NT$${couponAmount})` : null}
                  </td>
                  <td style={styles.innerNormalColumn}>
                    {R.pathOr('rent', ['type', 'name'], order) === 'buy' ? (
                      <React.Fragment>
                        {`金額  NT$ ${
                          R.pathOr('', ['amount'], order)
                          - shippingCost.blackCat
                        }`}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {`押金  ${renderDepositWithCoupon()} `}
                        <br />
                        <br />
                        {`租金 NT$ ${R.pathOr('', ['amount'], order)}`}
                      </React.Fragment>
                    )}
                  </td>
                  {isRentedSoldHistory ? (
                    <React.Fragment>
                      <td style={styles.innerNormalColumn}>
                        {`NT$ ${R.pathOr('-', ['ownerEarnedAmount'], order)}`}
                      </td>
                    </React.Fragment>
                  ) : null}
                </tr>
              </tbody>
            </table>

            <table
              style={{ ...styles.mainInnerTable, ...styles.innerBottomTable }}
            >
              <thead>
                <tr style={styles.tableBottomBorder}>
                  <th style={styles.innerNormalColumn}>
                    {isRentedSoldHistory ? '訂單會員' : '商品會員'}
                  </th>
                  <th style={styles.innerTimeIntervalColumn}>物流資訊</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={styles.innerNormalColumn}>
                    <div style={styles.orderRole}>
                      {isRentedSoldHistory ? (
                        <React.Fragment>
                          <Avatar
                            style={styles.avatarMarginRight}
                            size="default"
                            icon="user"
                            src={`${R.pathOr(
                              '',
                              ['user', 'avatar', 'uri'],
                              order,
                            )}`}
                          />
                          {`${R.pathOr('', ['user', 'name'], order)}`}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Avatar
                            style={styles.avatarMarginRight}
                            size="default"
                            icon="user"
                            src={`${R.pathOr(
                              '',
                              ['bag', 'owner', 'avatar', 'uri'],
                              order,
                            )}`}
                          />
                          {`${R.pathOr(
                            '',
                            ['bag', 'owner', 'communityName'],
                            order,
                          )}`}
                        </React.Fragment>
                      )}
                    </div>
                  </td>
                  <td style={styles.innerTimeIntervalColumn}>
                    {shippingInfo(true)}
                  </td>
                </tr>
              </tbody>
            </table>

            {renderDelayInfo}
            {renderReply}
          </React.Fragment>
        ) : null}
      </Default>
      <Mobile>
        <OrderDetailMobileComponent
          order={order}
          renderOperate={renderOperate}
          renderRentTimeOrBuyTableContent={renderRentTimeOrBuyTableContent}
          isRentedSoldHistory={isRentedSoldHistory}
          onSubmitWithdrawRequest={onSubmitWithdrawRequest}
          renderCommentButton={renderCommentButton}
          commentStars={commentStars}
          isComment={isComment}
          index={index}
          setIsShowProductSendModal={setIsShowProductSendModal}
          setIsShowCancelOrderModal={setIsShowCancelOrderModal}
          onConfirmOrder={() => onConfirmOrder()}
          setIsShowProductReturnConfirmModal={
            setIsShowProductReturnConfirmModal
          }
          setIsShowProductIssueReportModal={setIsShowProductIssueReportModal}
          shippingInfo={shippingInfo}
          couponCode={couponCode}
          couponAmount={couponAmount}
          renderDelayInfo={renderDelayInfo}
          renderReply={renderReply}
          renderRenterToBuyContent={renderRenterToBuyContent}
          renderShippingDate={renderShippingDate}
        />
      </Mobile>
    </React.Fragment>
  );
};

OrderDetailTableComponent.propTypes = {
  order: PropTypes.object,
  renderOperate: PropTypes.func,
  renderRentToBuyButton: PropTypes.func,
  isRentedSoldHistory: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onSubmitWithdrawRequest: PropTypes.func,
  onConfirmOrder: PropTypes.func,
  userId: PropTypes.number,
};

OrderDetailTableComponent.defaultProps = {
  order: {},
  renderOperate: () => {},
  renderRentToBuyButton: () => {},
  onSubmitWithdrawRequest: () => {},
  onConfirmOrder: () => {},
  userId: null,
  isRentedSoldHistory: false,
};

export default OrderDetailTableComponent;
