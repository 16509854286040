import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd/lib/index';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import InfoModalComponent from '../InfoModalComponent';
import Default from '../RWDComponent/Default';
import Mobile from '../RWDComponent/Mobile';

const styles = {
  mainContainer: {
    width: '50vw',
    height: '100%',
    padding: '40px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainMobileContainer: {
    width: '90vw',
    height: 'auto',
    padding: '30px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgContainer: {
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    alignItems: 'center',
  },
  img: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  upperContainer: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    overflow: 'hidden',
    alignItems: 'center',
  },
  icon: {
    fontSize: 80,
  },
  leftRightButton: {
    fontSize: 30,
    width: '10%',
    border: 'none',
    outline: 'none',
    color: 'black',
  },
  zoomContainer: {
    margin: '30px 0',
    width: '50%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  plusMinusButton: {
    fontSize: 40,
    border: 'none',
    outline: 'none',
    color: '#D5D5D5',
    width: 50,
    height: 50,
  },
  zoomProgress: {
    backgroundColor: '#E5E5E5',
    width: '100%',
    height: 3,
  },
  zoomProgressContainer: {
    width: '100%',
    position: 'relative',
    margin: '0px 20px',
  },
  zoomCurrentProgress: {
    position: 'absolute',
    height: 30,
    marginTop: -17,
    width: 8,
    zIndex: 9999,
    backgroundColor: 'white',
    border: '#E5E5E5 2px solid',
    cursor: 'pointer',
  },
  modalContainerStyle: {
    paddingTop: '15vh',
  },
};

const GalleryComponent = ({
  isVisible,
  onModalClose,
  data,
  mobilePicDetailIndex,
}) => {
  const [currentIndex, setCurrentIndex] = useState(mobilePicDetailIndex);

  useEffect(() => {
    setCurrentIndex(mobilePicDetailIndex);
  }, [mobilePicDetailIndex]);

  const next = () => {
    setCurrentIndex((currentIndex + 1) % data.length);
  };
  const pre = () => {
    setCurrentIndex((currentIndex - 1 + data.length) % data.length);
  };

  const renderPhoto = () => (
    <div style={styles.imgContainer}>
      <TransformWrapper
        defaultPositionX={200}
        defaultPositionY={100}
        wheel={{
          wheelEnabled: false,
        }}
      >
        {({
          zoomIn, zoomOut,
        }) => (
          <React.Fragment>
            <TransformComponent>
              <img src={data[currentIndex]} style={styles.img} alt="" />
            </TransformComponent>

            <div style={styles.zoomContainer}>
              <Button icon="minus" onClick={zoomOut} style={styles.plusMinusButton} />
              <div style={styles.zoomProgressContainer} />
              <Button icon="plus" onClick={zoomIn} style={styles.plusMinusButton} />
            </div>
          </React.Fragment>
        )}
      </TransformWrapper>
    </div>
  );

  const mainContent = (
    <React.Fragment>
      <Default>
        <div style={styles.mainContainer}>
          <div style={styles.upperContainer}>
            <Button icon="left" onClick={() => pre()} style={styles.leftRightButton} type="link" />
            {renderPhoto()}
            <Button icon="right" onClick={() => next()} style={styles.leftRightButton} type="link" />
          </div>
        </div>
      </Default>
      <Mobile>
        <div style={styles.mainMobileContainer}>
          <div style={styles.upperContainer}>
            <Button icon="left" onClick={() => pre()} style={styles.leftRightButton} type="link" />
            {renderPhoto()}
            <Button icon="right" onClick={() => next()} style={styles.leftRightButton} type="link" />
          </div>
        </div>
      </Mobile>
    </React.Fragment>

  );

  const resetCurrentIndex = () => {
    setCurrentIndex(0);
    onModalClose();
  };

  return (
    <React.Fragment>
      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          content={mainContent}
          onModalClose={() => resetCurrentIndex()}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          content={mainContent}
          onModalClose={() => resetCurrentIndex()}
          containerStyle={styles.modalContainerStyle}
        />
      </Mobile>
    </React.Fragment>
  );
};

GalleryComponent.propTypes = {
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func,
  data: PropTypes.array,
  mobilePicDetailIndex: PropTypes.number,
};

GalleryComponent.defaultProps = {
  isVisible: false,
  onModalClose: () => {},
  data: [],
  mobilePicDetailIndex: 0,
};

export default GalleryComponent;
