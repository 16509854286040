import api from '../../lib/configAxios';

export default {
  login: async data => api.post(
    'Users/login', data,
  ),

  fbLogin: async data => api.post(
    'Users/fbLogin', data,
  ),

  sendSms: async data => api.post(
    'Users/sendSMS', data,
  ),

  smsLogin: async data => api.post(
    'Users/smsLogin', data,
  ),

  logout: async () => api.post(
    'Users/logout',
  ),

  register: async data => api.post(
    'Users/register', data,
  ),

  changePassword: async data => api.post(
    'Users/changePassword', data,
  ),

  forgotPassword: async data => api.post(
    'Users/forgotPassword', data,
  ),

  updateMemberInfo: async data => api.patch(
    'Users/me', data,
  ),

  getMemberProfile: async data => api.post(
    'Users/getProfile', data,
  ),

  toggleFollow: async data => api.post(
    'Follows/toggle', data,
  ),

  getFollowingList: async filter => api.get(
    `Follows/?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  uploadOutfit: async data => api.post(
    'Outfits', data,
  ),

  getMemberOutfitList: async filter => api.get(
    `Outfits/?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  outfitDetail: async filter => api.get(
    `Outfits/?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  sendOutfitComment: async data => api.post(
    'OutfitComments', data,
  ),

  getProtectTreeEndTime: async () => api.get(
    'Users/protectTreeEndTime',
  ),

  getReduceDepositList: async filter => api.get(
    `ReduceDeposits/?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  deleteReduceDepositBag: async id => api.delete(
    `ReduceDeposits/${id}`,
  ),

  getReduceDepositUserList: async filter => api.get(
    `Users/?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  createReduceDepositUser: async data => api.post(
    'ReduceDeposits', data,
  ),

  deleteReduceDepositUser: async id => api.delete(
    `ReduceDeposits/${id}`,
  ),

  getUserIdByCommunityName: async data => api.post(
    'Users/getUserIdByCommunityName', data,
  ),

  hadReadNotification: async data => api.post(
    'notifications/hadRead', data,
  ),

  allReadNotification: async () => api.post(
    'notifications/allRead',
  ),

  getNotificationList: async () => api.get(
    'Users/getNotifications',
  ),

  getMemberCouponList: async () => api.get(
    'Users/getCoupons',
  ),

  deletedOutfit: async id => api.delete(
    `Outfits/${id}`,
  ),

  getWithdrawOrders: async filter => api.get(
    `Withdraws/?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  ),

  searchByKeyword: async data => api.post(
    'Users/searchByKeyword', data,
  ),

  passbaseAuthentication: async data => api.post(
    'Users/passbaseAuthentication', data,
  ),

};
