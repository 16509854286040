import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Assets from '../../../constants/Assets';
import Default from '../../../components/RWDComponent/Default';
import Mobile from '../../../components/RWDComponent/Mobile';
import { Colors } from '../../../constants/Color';

const styles = {
  mainContainer: {
    margin: 0,
    backgroundColor: 'white',
    height: 'auto',
    paddingTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainMobileContainer: {
    margin: 0,
    backgroundColor: 'white',
    height: 'auto',
    paddingTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activity: {
    color: 'black',
    fontSize: 30,
    letterSpacing: 6,
  },
  activityContainer: {
    margin: '20px 0px',
    width: '100%',
  },
  activityLine: {
    width: '0%',
  },
  buttonContainer: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonRenter: {
    width: '40%',
    height: '40px',
    backgroundColor: Colors.orderBrown,
    color: 'white',
    fontSize: '20px',
    padding: '5px 0px',
    fontWeight: '300',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
  },
  buttonLender: {
    width: '40%',
    height: '40px',
    backgroundColor: '#F7F0E7',
    color: 'black',
    fontSize: '20px',
    padding: '5px 0px',
    fontWeight: '300',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
  },
  buttonRenterMobile: {
    width: '50%',
    height: '40px',
    backgroundColor: Colors.orderBrown,
    color: 'white',
    fontSize: '12px',
    padding: '10px 0px',
    fontWeight: '400',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
  },
  buttonLenderMobile: {
    width: '50%',
    height: '40px',
    backgroundColor: '#F7F0E7',
    color: 'black',
    fontSize: '12px',
    padding: '10px 0px',
    fontWeight: '400',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
  },
  buttonPic: {
    width: '100%',
    height: '100%',
  },
  stepContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '50px 0px',
  },
  stepMobileContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '2px 0px',
  },
  stepPic: {
    width: '24.5%',
    height: 'auto',
  },
  stepBlockContainer: {
    width: '24.5%',
    height: '260px',
    backgroundColor: '#F7F0E7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepBlockMobileContainer: {
    width: '49.5%',
    height: '260px',
    backgroundColor: '#F7F0E7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepPicMobile: {
    width: '49.5%',
    height: 'auto',
  },
  signUpButton: {
    width: '80%',
    backgroundColor: Colors.orderBrown,
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    height: '35px',
    marginBottom: '40px',
    fontWeight: '400',
  },
  stepMobileHigherContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '50px 0px',
    fontWeight: '400',
  },
  icon: {
    with: '70px',
    height: '80px',
    objectFit: 'contain',
  },
  list: {
    fontSize: '30px',
    margin: 0,
  },
  title: {
    color: 'black',
    fontWeight: '400',
    fontSize: '16px',
    marginTop: '10px',
  },
  subTitle: {
    color: 'black',
    fontWeight: '300',
    width: '300px',
    marginTop: '15px',
    fontSize: '12px',
  },
};

const RenderStepBlock = item => (
  <div style={item.isMobile ? styles.stepBlockMobileContainer : styles.stepBlockContainer}>
    <h1 style={styles.list}>
      {item.list}
    </h1>
    <img
      src={item.icon}
      style={styles.icon}
      alt=""
    />
    <div style={styles.title}>
      {item.title}
    </div>
    <Default>
      <div style={styles.subTitle}>
        {item.subTitle}
      </div>
    </Default>
    <Mobile>
      <div style={styles.subTitle}>
        {item.subTitleMobile1}
        <br />
        {item.subTitleMobile2}
      </div>
    </Mobile>
  </div>
);

const HomeHowItWorksComponent = ({
  setIsShowRegisterPage,
}) => {
  const [isShowSellStep, setIsShowSellStep] = useState(false);

  const stepData = [
    {
      list: '01',
      icon: Assets.homePageIcon01,
      title: '實名認證開通會員',
      subTitle: '嚴格審核會員資料，安全共享環境',
      subTitleMobile1: '嚴格審核會員資料',
      subTitleMobile2: '安全共享環境',
    },
    {
      list: '02',
      icon: Assets.homePageIcon03,
      title: '挑選心儀單品',
      subTitle: '選擇租借日期，支付租金',
      subTitleMobile1: '選擇租借日期',
      subTitleMobile2: '支付租金',
    },
    {
      list: '03',
      icon: Assets.homePageIcon08,
      title: '火速到貨',
      subTitle: '訂購商品會在租期首日送達',
      subTitleMobile1: '訂購商品會在租期首日送達',
      subTitleMobile2: null,
    },
    {
      list: '04',
      icon: Assets.homePageIcon04,
      title: '歸還 & 再次租借',
      subTitle: '輕鬆租借精品，讓擁有更自由',
      subTitleMobile1: '輕鬆租借精品',
      subTitleMobile2: '讓擁有更自由',
    },
  ];

  const stepSellData = [
    {
      list: '01',
      icon: Assets.homePageIcon01,
      title: '實名認證開通會員',
      subTitle: '嚴格審核會員資料，安全共享環境',
      subTitleMobile1: '嚴格審核會員資料',
      subTitleMobile2: '安全共享環境',
    },
    {
      list: '02',
      icon: Assets.homePageIcon05,
      title: '上傳商品自由訂價',
      subTitle: '手機拍照即可輕鬆上傳，自訂理想價格',
      subTitleMobile1: '手機拍照即可輕鬆上傳',
      subTitleMobile2: '自訂理想價格',
    },
    {
      list: '03',
      icon: Assets.homePageIcon08,
      title: '確認訂單',
      subTitle: '商品成功出租，前往【確認訂單】',
      subTitleMobile1: '商品成功出租',
      subTitleMobile2: '前往【確認訂單】',
    },
    {
      list: '04',
      icon: Assets.homePageIcon06,
      title: '賺取收益提領',
      subTitle: '輕鬆賺取可觀被動收入',
      subTitleMobile1: '輕鬆賺取可觀被動收入',
      subTitleMobile2: null,
    },
  ];

  const data = isShowSellStep ? stepSellData : stepData;

  return (
    <div style={styles.mainContainer}>

      <div style={styles.activityContainer}>
        <h1 style={styles.activity}>
          平台操作說明
        </h1>
        <hr style={styles.activityLine} />
      </div>

      <div style={styles.buttonContainer}>
        <Default>
          <React.Fragment>
            <button
              style={isShowSellStep ? styles.buttonLender : styles.buttonRenter}
              onClick={() => { setIsShowSellStep(false); }}
              type="button"
            >
              如何租借 / 購買 ？
            </button>
            <button
              style={isShowSellStep ? styles.buttonRenter : styles.buttonLender}
              onClick={() => { setIsShowSellStep(true); }}
              type="button"
            >
              如何出租 / 出售 ？
            </button>
          </React.Fragment>
        </Default>
        <Mobile>
          <React.Fragment>
            <button
              style={isShowSellStep ? styles.buttonLenderMobile : styles.buttonRenterMobile}
              onClick={() => { setIsShowSellStep(false); }}
              type="button"
            >
              如何租借 / 購買 ？
            </button>
            <button
              style={isShowSellStep ? styles.buttonRenterMobile : styles.buttonLenderMobile}
              onClick={() => { setIsShowSellStep(true); }}
              type="button"
            >
              如何出租 / 出售 ？
            </button>
          </React.Fragment>
        </Mobile>

      </div>

      <Default>
        <div style={styles.stepContainer}>
          {data.map(RenderStepBlock)}
        </div>
      </Default>
      <Mobile>
        <div style={styles.stepMobileHigherContainer}>
          <div style={styles.stepMobileContainer}>
            {RenderStepBlock({ ...data[0], ...{ isMobile: true } })}
            {RenderStepBlock({ ...data[1], ...{ isMobile: true } })}
          </div>
          <div style={styles.stepMobileContainer}>
            {RenderStepBlock({ ...data[2], ...{ isMobile: true } })}
            {RenderStepBlock({ ...data[3], ...{ isMobile: true } })}
          </div>
        </div>
      </Mobile>

      <button
        style={styles.signUpButton}
        onClick={() => setIsShowRegisterPage(true)}
        type="button"
      >
        立即註冊 / 認證
      </button>
    </div>
  );
};


HomeHowItWorksComponent.propTypes = {
  setIsShowRegisterPage: PropTypes.func.isRequired,
};


export default HomeHowItWorksComponent;
