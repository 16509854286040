import * as R from 'ramda';
import { createSelector } from 'reselect';
import { onlineBagStateSelector } from '../../lib/rootSelector';

export const retrieveBagSelector = createSelector(onlineBagStateSelector,
  result => R.path(['retrieveBag'], result));

export const extendContractBagSelector = createSelector(onlineBagStateSelector,
  result => R.path(['extendContractBag'], result));

export const bagEditSelector = createSelector(onlineBagStateSelector,
  result => R.path(['bagEdit'], result));

export const retrieveBagReduxStatusSelector = createSelector(
  retrieveBagSelector,
  result => R.path(['reduxStatus'], result),
);

export const extendContractBagReduxStatusSelector = createSelector(
  extendContractBagSelector,
  result => R.path(['reduxStatus'], result),
);

export const bagEditSelectorReduxStatusSelector = createSelector(
  bagEditSelector,
  result => R.path(['reduxStatus'], result),
);
