import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import InfoModalComponent from '../InfoModalComponent';

const styles = {
  mainContainer: {
    width: 750,
    height: 230,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0px',
  },
  title: {
    fontSize: 20,
    color: 'black',
  },
  button: {
    border: '#994207 1px solid',
    borderRadius: 0,
    width: 170,
    height: 50,
    marginTop: 20,
    color: '#949598',
    fontSize: 16,
  },
};

const NotifyMeWhenArrivedComponent = ({
  handleNotifyMeWhenArrived,
  isVisible,
  onModalClose,
  bagName,
  bagReleaseDate,
  mainStyle,
}) => {
  const [showSuccess, setShowSuccess] = useState(false);

  const renderContent = (
    <div style={{ ...styles.mainContainer, ...mainStyle }}>
      <div style={styles.title}>{`${bagName}預計 ${bagReleaseDate} 返回架上`}</div>
      <Button
        icon="bell"
        onClick={() => {
          setShowSuccess(true);
          handleNotifyMeWhenArrived();
        }}
        style={styles.button}
      >
        {showSuccess ? '已完成登錄！' : '到貨通知我'}
      </Button>
    </div>
  );

  return (
    <InfoModalComponent
      isVisible={isVisible}
      onModalClose={onModalClose}
      content={renderContent}
    />
  );
};

NotifyMeWhenArrivedComponent.propTypes = {
  handleNotifyMeWhenArrived: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  bagName: PropTypes.string,
  bagReleaseDate: PropTypes.string,
  mainStyle: PropTypes.object,
};

NotifyMeWhenArrivedComponent.defaultProps = {
  bagName: '',
  bagReleaseDate: '',
  mainStyle: {},
};

export default NotifyMeWhenArrivedComponent;
