import Type from '../constants/Type';
import Paths from '../routes/Paths';

const bagOrClothesPath = (commodityTypeId) => {
  if (commodityTypeId === Type.commodityTypeId.clothes) return Paths.CLOTHES_DETAIL;

  if (commodityTypeId === Type.commodityTypeId.accessories) return Paths.ACCESSORIES_DETAIL;

  if (commodityTypeId === Type.commodityTypeId.essences) return Paths.ESSENCES_DETAIL;

  return Paths.BAG_DETAIL;
};

export default bagOrClothesPath;
