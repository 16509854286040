import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
} from 'antd';
import Button from './Button';

const styles = {
  baseStyle: {
    width: '100px',
    height: '40px',
  },
};

const ModalComponent = ({
  isVisible,
  title,
  content,
  onModalClose,
  onModalConfirmed,
  maskClosable,
  buttonText,
  buttonStyle,
  isShowFooter,
  width,
  closeIcon,
}) => {
  const [isModalShow, setIsModalShow] = useState(isVisible);
  useEffect(() => {
    setIsModalShow(isVisible);
  }, [isVisible]);
  const handleCloseModal = () => {
    setIsModalShow(false);
    if (onModalClose) onModalClose();
  };
  const handleConfirmModal = () => {
    if (onModalConfirmed) onModalConfirmed();
    else handleCloseModal();
  };

  const button = [
    <Button
      key="Accept"
      onClick={handleConfirmModal}
      text={buttonText}
      style={{
        ...styles.baseStyle,
        ...buttonStyle,
      }}
    />,
  ];

  return (
    <Modal
      visible={isModalShow}
      title={title}
      onCancel={handleCloseModal}
      closeIcon={closeIcon}
      maskClosable={maskClosable}
      footer={isShowFooter && button}
      width={width}
    >
      {content}
    </Modal>
  );
};

ModalComponent.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.node,
  onModalClose: PropTypes.func,
  onModalConfirmed: PropTypes.func,
  maskClosable: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonStyle: PropTypes.object,
  isShowFooter: PropTypes.bool,
  width: PropTypes.number,
  closeIcon: PropTypes.object,
};

ModalComponent.defaultProps = {
  isVisible: false,
  title: null,
  content: null,
  onModalClose: null,
  onModalConfirmed: null,
  maskClosable: true,
  buttonText: '確認',
  buttonStyle: {},
  isShowFooter: true,
  width: 520,
  closeIcon: null,
};

export default ModalComponent;
