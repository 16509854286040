import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { navigate, Location } from '@reach/router';
import Paths from '../routes/Paths';
import Default from './RWDComponent/Default';
import Mobile from './RWDComponent/Mobile';
import { readData, writeData } from '../utils/LocalStorageUtils';
import { itemsInPage, loadMorePages } from '../constants/PageSetting';
import LocalStorageKey from '../constants/LocalStorageKey';
import * as SearchBagHelper from '../utils/SearchBagHelper';
import { commodityTypeId } from '../constants/Type';

const styles = {
  loadMoreContainer: {
    position: 'absolute',
    right: '8%',
    top: '195px',
  },
  loadMoreMobileContainer: {
    position: 'absolute',
    right: '8%',
    top: '280px',
  },
  pageButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  loadMoreButton: {
    width: 'fit-content',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: 12,
    margin: '0px 2px',
    padding: '0px 4px',
  },
  numberButton: {
    width: 'fit-content',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: 12,
    margin: '0px 1px',
  },
  numberButtonActive: {
    width: 'fit-content',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: 14,
    color: 'black',
    margin: '0px 1px',
    fontWeight: '800',
  },
};


const PageButton = ({
  currentPage,
  data,
  showPage,
  setShowPageData,
  isSearch,
  setIsSearch,
  setShowPage,
  setCurrentPage,
  setIsNextTenPage,
  setIsInitial,
  bagNumber,
  location,
  isBuyArea,
  commodityType,
}) => {
  const maxPages = Math.floor(bagNumber / itemsInPage) + 1;
  const queryString = R.pathOr('', ['search'], SearchBagHelper.getPath(location));
  const [isGoTop, setIsGoTop] = useState(false);

  const renderPath = () => {
    if (isBuyArea) return Paths.BUY_AREA;

    if (commodityType === commodityTypeId.clothes) return Paths.CLOTHES_LIST;

    if (commodityType === commodityTypeId.accessories) return Paths.ACCESSORIES_LIST;

    if (commodityType === commodityTypeId.essences) return Paths.ESSENCES_LIST;

    return Paths.BAG_LIST;
  };

  useEffect(() => {
    const thisPageData = data.filter((value, index) => (index >= showPage * itemsInPage && index < (showPage + 1) * itemsInPage));
    setShowPageData(thisPageData);
    if (isSearch) {
      setIsSearch(false);
      setShowPage(0);
      setCurrentPage(0);
    }
  }, [showPage, data, isSearch]);

  useEffect(() => {
    const scrollY = readData(LocalStorageKey.SCROLL_POSITION, 0);

    if (scrollY !== 0) {
      window.scrollTo(0, scrollY);
      writeData(LocalStorageKey.SCROLL_POSITION, 0);
    }

    if (scrollY === 0) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (isGoTop) {
      window.scrollTo(0, 0);
      setIsGoTop(false);
    }
  }, [isGoTop]);

  const renderPageCounter = (maxPageButton) => {
    const pages = [...Array(maxPages).keys()];

    const pageButton = pages.map((pageNumber) => {
      const renderPageButton = (
        <button
          key={pageNumber}
          style={showPage === pageNumber ? styles.numberButtonActive : styles.numberButton}
          onClick={() => {
            setShowPage(pageNumber);
            setIsGoTop(true);
            navigate(`${renderPath()}?search=${queryString}&page=${pageNumber + 1}`);
          }}
          type="button"
        >
          {String(pageNumber + 1)}
        </button>
      );

      if (showPage - maxPageButton < pageNumber && pageNumber < showPage + maxPageButton) {
        return renderPageButton;
      }

      return null;
    });

    return pageButton;
  };

  const onClickNextPage = () => {
    if (currentPage !== showPage) {
      setShowPage(showPage + 1);
    } else {
      setCurrentPage(currentPage + 1);
      setShowPage(currentPage + 1);
      setIsNextTenPage(false);
      setIsInitial(false);
    }
  };

  const onClickPrevPage = () => {
    setShowPage(showPage - 1);
  };

  const onClickPrevTenPage = () => {
    setShowPage(showPage - loadMorePages);
  };

  return (
    <div style={styles.pageButtonContainer}>
      { loadMorePages - showPage > 0 ? null : (
        <button
          style={styles.loadMoreButton}
          onClick={
            () => {
              onClickPrevTenPage();
              navigate(`${renderPath()}?search=${queryString}&page=${showPage - loadMorePages}`);
              setIsGoTop(true);
            }
          }
          type="button"
        >
          {'<<'}
        </button>
      )}
      {showPage === 0 ? null : (
        <button
          style={styles.loadMoreButton}
          onClick={() => {
            onClickPrevPage();
            navigate(`${renderPath()}?search=${queryString}&page=${showPage}`);
            setIsGoTop(true);
          }}
          type="button"
        >
          {'<'}
        </button>
      )}
      <Default>
        { renderPageCounter(6)}
      </Default>
      <Mobile>
        { renderPageCounter(4)}
      </Mobile>

      { showPage + 1 >= maxPages ? null : (
        <button
          style={styles.loadMoreButton}
          onClick={() => {
            onClickNextPage();
            setIsGoTop(true);
            navigate(`${renderPath()}?search=${queryString}&page=${showPage + 2}`);
          }}
          type="button"
        >
          {'>'}
        </button>
      )}

      {currentPage + loadMorePages + 1 >= maxPages ? null : (
        <button
          style={styles.loadMoreButton}
          disabled={currentPage + 2 >= maxPages}
          onClick={
            () => {
              setCurrentPage(currentPage + loadMorePages);
              setShowPage(currentPage + loadMorePages);
              setIsNextTenPage(true);
              setIsInitial(false);
              navigate(`${renderPath()}?search=${queryString}&page=${showPage + loadMorePages}`);
              setIsGoTop(true);
            }
          }
          type="button"
        >
          {'>>'}
        </button>
      )}

    </div>
  );
};

PageButton.propTypes = {
  currentPage: PropTypes.number,
  data: PropTypes.array,
  showPage: PropTypes.number,
  setShowPageData: PropTypes.func.isRequired,
  isSearch: PropTypes.bool,
  setIsSearch: PropTypes.func.isRequired,
  setShowPage: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setIsNextTenPage: PropTypes.func.isRequired,
  setIsInitial: PropTypes.func.isRequired,
  bagNumber: PropTypes.number,
  commodityType: PropTypes.number,
  location: PropTypes.shape({}),
  isBuyArea: PropTypes.bool,
};

PageButton.defaultProps = {
  currentPage: 0,
  data: [],
  showPage: 0,
  isSearch: false,
  bagNumber: 0,
  commodityType: 1,
  location: {},
  isBuyArea: false,
};


export default
(props => (
  <Location>
    {({ location }) => (<PageButton location={location} {...props} />)}
  </Location>
));
