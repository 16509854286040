import React from 'react';
import Slider from 'react-slick';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import * as R from 'ramda';
import { useWindowSize } from '@react-hook/window-size/throttled';
import Assets from '../../../../constants/Assets';
import Default from '../../../../components/RWDComponent/Default';
import Mobile from '../../../../components/RWDComponent/Mobile';
import { FontSize } from '../../../../constants/FontSize';
import FollowComponent from '../../../../components/FollowComponent';
import ReduxStatusVisualizedComponent from '../../../../components/ReduxStatusVisualizedComponent';
import Paths from '../../../../routes/Paths';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const styles = {
  mainContainer: {
    margin: 0,
    backgroundColor: 'white',
    height: 'auto',
    paddingTop: '30px',
  },
  membershipContainer: {
    width: '100%',
    height: 'auto',
    textAlign: 'left',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
  },
  membershipMobileContainer: {
    width: '100%',
    height: 'auto',
    position: 'relative',
  },
  membershipPicContainer: {
    verticalAlign: 'top',
    minWidth: '400px',
    margin: '0 auto',
  },
  membershipPic: {
    width: '100%',
    height: '660px',
    objectFit: 'cover',
  },
  membershipTextContainer: {
    width: '90%',
    height: 'auto',
    overflow: 'hidden',
    color: 'black',
    margin: '0 auto',
    display: 'flex',
    flexWrap: 'wrap',
  },
  sliderContainer: {
    width: '94%',
    height: 'auto',
    left: '3%',
    position: 'relative',
    padding: '50px 0px',
  },
  sliderMobileContainer: {
    width: '80%',
    height: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'relative',
    padding: '90px 0px',
  },
  memberItemBlock: {
    width: '15vw',
    minWidth: '150px',
    height: 'calc(15vw + 100px)',
    margin: '35px auto',
    fontSize: FontSize.body,
    position: 'relative',
    textAlign: 'center',
  },
  memberItemBlockMobile: {
    width: '50vw',
    minWidth: '150px',
    height: 'auto',
    fontSize: FontSize.body,
    position: 'relative',
    textAlign: 'center',
  },
  titleContainer: {
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    marginBottom: '50px',
    position: 'relative',
  },
  memberSubTitle: {
    fontSize: FontSize.body,
    margin: '20px 0 17px 0',
  },
  aboutImg: {
    width: '100%',
    height: 'auto',
  },
  membershipIcon: {
    width: '29px',
    height: '29px',
  },
  memberItemTitle: {
    marginTop: '15px',
    fontSize: '26px',
    color: 'white',
  },
  verticalLine: {
    width: 0,
    border: '1px solid grey',
    height: 'auto',
  },
  memberItemMobileContainer: {
    width: '50%',
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
  },
  memberMobileSubTitle: {
    fontSize: FontSize.body,
    margin: '20px 30px',
  },
  leftLine: {
    width: '32%',
    position: 'absolute',
    top: '35%',
    left: '10%',
    transform: 'translate(-10%, -35%)',
    border: 0,
    borderTop: '1px grey solid',
  },
  rightLine: {
    width: '32%',
    position: 'absolute',
    top: '35%',
    right: '10%',
    transform: 'translate(10%, -35%)',
    border: 0,
    borderTop: '1px grey solid',
  },
  textContainer: {
    position: 'absolute',
    top: '10px',
    left: '20px',
    color: 'white',
  },
  readMore: {
    color: 'white',
    marginTop: '60px',
  },
  specialContainer: {
    padding: '20px 20px 0px 20px',
  },
  specialMobileContainer: {
    width: '80%',
    bottom: '50px',
    left: '0px',
    position: 'relative',
  },
  avatar: {
    fontSize: '13vw',
    width: '15vw',
    height: '15vw',
    marginBottom: '30px',
  },
  avatarMobile: {
    fontSize: '35vw',
    width: '35vw',
    height: '35vw',
    marginBottom: '20px',
  },
  specialTitle: {
    fontSize: '30px',
    letterSpacing: '5px',
  },
  specialSubTitle: {
    fontSize: '24px',
    letterSpacing: '1.5px',
    color: 'black',
  },
};

const renderTextBlock = (item, isMobile) => {
  const id = R.pathOr(null, ['id'], item);
  const icon = R.pathOr(null, ['avatar', 'uri'], item);
  const communityName = R.pathOr(null, ['communityName'], item);
  return (
    <React.Fragment key={communityName}>
      <div style={isMobile ? styles.memberItemBlockMobile : styles.memberItemBlock}>
        <Link to={`${Paths.MEMBER}/${communityName}`}>
          <Avatar
            icon="user"
            size={110}
            style={isMobile ? styles.avatarMobile : styles.avatar}
            src={icon || Assets.AvatarIcon}
          />
          <h1>{communityName}</h1>
        </Link>
        <FollowComponent
          targetId={id}
        />
      </div>
    </React.Fragment>
  );
};

const SpecialSelectComponent = ({
  data,
  reduxStatus,
  reduxError,
}) => {
  const [width] = useWindowSize({ fps: 60 });
  const calculateNumbers = () => {
    if (width < 400) {
      return 1;
    }
    if (width < 700) {
      return 2;
    }
    if (width < 900) {
      return 3;
    }

    return 5;
  };

  return (
    <ReduxStatusVisualizedComponent
      reduxStatus={reduxStatus}
      showModalOnError
      modalFailureContent={reduxError}
    >
      <div style={styles.mainContainer}>
        <div style={styles.specialContainer}>
          <h1 style={styles.specialTitle}>OP精選線上衣櫥</h1>
          <hr />
          <div style={styles.specialSubTitle}> </div>
        </div>
        <Default>
          <div style={styles.membershipContainer}>
            <div style={styles.membershipTextContainer}>
              <div style={styles.sliderContainer}>
                <Slider
                  arrows
                  speed={1000}
                  autoplay
                  slidesToShow={calculateNumbers()}
                  slidesToScroll={1}
                >
                  {(data || []).map(item => renderTextBlock(item))}
                </Slider>
              </div>
            </div>
          </div>
        </Default>
        <Mobile>
          <div style={styles.membershipMobileContainer}>
            <div style={styles.sliderMobileContainer}>
              <Slider
                arrows
                speed={700}
                autoplay
                slidesToShow={calculateNumbers()}
                slidesToScroll={1}
              >
                {(data || []).map(item => renderTextBlock(item, true))}
              </Slider>
            </div>
          </div>
        </Mobile>
      </div>
    </ReduxStatusVisualizedComponent>
  );
};

SpecialSelectComponent.propTypes = {
  data: PropTypes.array,
  reduxError: PropTypes.string,
  reduxStatus: PropTypes.string,
};

SpecialSelectComponent.defaultProps = {
  reduxStatus: null,
  reduxError: null,
  data: [],
};

export default SpecialSelectComponent;
