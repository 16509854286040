module.exports = [
  {
    name: '尺寸',
    value: 0,
  },
  {
    name: 'XXS',
    value: 'XXS',
  }, {
    name: 'XS',
    value: 'XS',
  }, {
    name: 'S',
    value: 'S',
  }, {
    name: 'M',
    value: 'M',
  }, {
    name: 'L',
    value: 'L',
  }, {
    name: 'XL',
    value: 'XL',
  },
];
