import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import InfoModalComponent from '../../../../../components/InfoModalComponent';
import Default from '../../../../../components/RWDComponent/Default';
import Mobile from '../../../../../components/RWDComponent/Mobile';
import { Colors } from '../../../../../constants/Color';
import { transactionType } from '../../../../../constants/Type';
import WithdrawSendSuccussComponent from './WithdrawSendSuccussComponent';

const styles = {
  mainContainer: {
    padding: 30,
    width: 700,
    height: 550,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    zIndex: 9999,
  },
  mainMobileContainer: {
    padding: 30,
    width: '95vw',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    zIndex: 9999,
  },
  titleMainContainer: {
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  editTitle: {
    fontSize: 21,
    color: 'black',
    width: '60%',
    weight: 100,
  },
  selectTab: {
    fontSize: 12,
    color: Colors.introRightTitle,
    margin: '20px 40px 0px 0px',
    weight: 100,
    paddingBottom: '10px',
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    borderBottom: `1px ${Colors.introRightTitle} solid`,
    cursor: 'pointer',
  },
  selectTabDisActive: {
    fontSize: 12,
    color: 'black',
    margin: '20px 40px 0px 0px',
    weight: 100,
    paddingBottom: '10px',
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    borderBottom: '1px black solid',
    cursor: 'pointer',
  },
  editSubTitle: {
    paddingRight: 10,
    textAlign: 'right',
    marginLeft: 10,
    fontFamily: 'Microsoft JhengHei Regular',
    color: 'black',
    width: '20%',
    weight: 100,
  },
  editItemContainer: {
    height: 400,
    width: '100%',
    overflowY: 'scroll',
  },
  table: {
    marginTop: 20,
    width: '100%',
  },
  contentTable: {
    width: '100%',
  },
  tableScrollAble: {
    width: '100%',
    height: 300,
    overflowY: 'scroll',
    borderBottom: '1px solid #D1D3D4',
  },
  tableHeader: {
    borderTop: '2px solid #939598',
    borderBottom: '1px solid #D1D3D4',
  },
  imgTitleHeader: {
    width: '30%',
    color: '#58595B',
  },
  orderTypeHeader: {
    color: '#58595B',
    width: '25%',
  },
  amountHeader: {
    color: '#58595B',
    width: '25%',
  },
  selectHeader: {
    width: '10%',
    height: 30,
  },
  itemContainer: {
    width: '100%',
  },
  imgTitle: {
    width: '30%',
  },
  orderType: {
    color: '#58595B',
    width: '25%',
  },
  amount: {
    color: '#58595B',
    width: '23%',
  },
  select: {
    width: '10%',
  },
  selectionContainer: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  memberContainer: {
    marginTop: 30,
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  memberName: {
    marginLeft: 15,
    fontSize: 18,
    fontFamily: 'Microsoft JhengHei Regular',
  },
  checkbox: {
    width: 20,
    height: 20,
  },
  bagContainer: {
    border: '1px solid #ECECEC',
    position: 'relative',
    margin: 10,
    width: 100,
    height: 100,
  },
  function: {
    width: 'fit-content',
    height: 50,
    marginTop: 30,
    fontSize: 20,
    padding: '10px 50px',
    border: '1px solid #AE4200',
    backgroundColor: 'white',
    color: '#939598',
    outline: 'none',
    cursor: 'pointer',
  },
  functionDisable: {
    width: 'fit-content',
    height: 50,
    marginTop: 30,
    fontSize: 20,
    padding: '10px 50px',
    border: '1px solid grey',
    backgroundColor: 'white',
    color: '#939598',
    outline: 'none',
    cursor: 'not-allowed',
  },
  optionValue: {
    width: '20%',
    height: 30,
    backgroundColor: 'transparent',
    border: '1px solid #E6E7E8',
    color: 'black',
    outline: 'none',
    borderRadius: 0,
    padding: 10,
    WebkitAppearance: 'none',
    cursor: 'pointer',
  },
};

const WithdrawMoneyComponent = ({
  isVisible,
  onModalClose,
  data,
  onClickSendWithdrawRequest,
  onClickGetUnSendWithdrawRequestList,
  onClickGetSendedWithdrawRequestList,
}) => {
  const [currentSelectOrderIdList, setCurrentSelectOrderIdList] = useState([]);
  const [requestOrderNumber, setRequestOrderNumber] = useState(0);
  const [isShowSuccussModal, setIsShowSuccussModal] = useState(false);
  const [requestAmount, setRequestAmount] = useState(0);
  const [orderList, setOrderList] = useState([]);
  const [selectTag, setSelectTag] = useState(0);
  const orderIdList = orderList.map(order => order.id);

  const calculateWithdrawAmount = (selectOrder) => {
    if (!data || !selectOrder) {
      return 0;
    }
    const amount = data.reduce((accumulator, currentOrder) => {
      if (selectOrder.indexOf(currentOrder.id) >= 0) {
        return accumulator + currentOrder.ownerEarnedAmount;
      }
      return accumulator;
    }, 0);

    return setRequestAmount(amount);
  };

  const onClickWithdrawRequest = () => {
    setOrderList([]);
    setRequestOrderNumber(currentSelectOrderIdList.length);
    calculateWithdrawAmount(currentSelectOrderIdList);
    onClickSendWithdrawRequest(currentSelectOrderIdList);
    setCurrentSelectOrderIdList([]);
    setIsShowSuccussModal(true);
  };

  const content = (
    <React.Fragment>
      <div style={styles.titleMainContainer}>
        <div style={styles.titleContainer}>
          <div style={styles.editTitle}>提領款項清單</div>
        </div>
      </div>
      <div style={styles.titleMainContainer}>
        <div style={styles.titleContainer}>
          <button
            style={selectTag === 0 ? styles.selectTab : styles.selectTabDisActive}
            type="button"
            onClick={() => {
              setSelectTag(0);
              setCurrentSelectOrderIdList([]);
              onClickGetUnSendWithdrawRequestList();
            }}
          >
            未提領款項清單
          </button>
          <button
            style={selectTag === 1 ? styles.selectTab : styles.selectTabDisActive}
            type="button"
            onClick={() => {
              setSelectTag(1);
              setCurrentSelectOrderIdList([]);
              onClickGetSendedWithdrawRequestList();
            }}
          >
            已提領款項清單
          </button>
        </div>
      </div>
      <table style={styles.table}>
        <tbody>
          <tr style={styles.tableHeader}>
            {selectTag === 0 ? (
              <th style={styles.selectHeader}>選取</th>
            ) : null}
            <th style={styles.imgTitleHeader}>商品圖片</th>
            <th style={styles.orderTypeHeader}>交易類別</th>
            <th style={styles.amountHeader}>可領取金額</th>
          </tr>
        </tbody>
      </table>
      <div style={styles.tableScrollAble}>
        <table style={styles.contentTable}>
          <tbody>
            {data ? data.map(order => (
              <tr key={order.id}>
                {selectTag === 0 ? (
                  <td style={styles.select}>
                    <input
                      style={styles.checkbox}
                      type="checkbox"
                      value={order.id}
                      key={order.id}
                      defaultChecked={R.includes(order.id, orderIdList)}
                      onChange={(event) => {
                        if (event.target.checked && !R.includes(Number(event.target.value), currentSelectOrderIdList)) {
                          setCurrentSelectOrderIdList(R.concat(currentSelectOrderIdList, [Number(event.target.value)]));
                        } else {
                          setCurrentSelectOrderIdList(R.filter(item => item !== Number(event.target.value), currentSelectOrderIdList));
                        }
                      }}
                    />
                  </td>
                ) : null}
                <td style={styles.imgTitle}>
                  <img alt="" src={R.pathOr('', ['bag', 'frontPic', 'uri'], order)} style={styles.bagContainer} />
                  <div>{R.pathOr('', ['bag', 'name'], order)}</div>
                </td>
                <td style={styles.orderType}>{transactionType[R.pathOr('', ['type', 'name'], order)]}</td>
                <td style={styles.amount}>{R.pathOr(0, ['ownerEarnedAmount'], order)}</td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </div>
      {
        selectTag === 0 ? (
          <button
            type="button"
            style={currentSelectOrderIdList.length === 0 ? styles.functionDisable : styles.function}
            disabled={currentSelectOrderIdList.length === 0}
            onClick={() => {
              onClickWithdrawRequest();
              // GTag implant
              window.gtag_report_conversion();
            }}
          >
            確認提領
          </button>
        ) : null
      }
    </React.Fragment>
  );

  return (
    <React.Fragment>

      {/* GTag implant */}
      <Helmet>
        <script>
          {`
          function gtag_report_conversion(url) {
            var callback = function () {
              if (typeof(url) != 'undefined') {
                window.location = url;
              }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-627439533/ux8jCLC9sdIBEK3vl6sC',
                'event_callback': callback
            });
            return false;
          }          
          `}
        </script>
      </Helmet>

      <WithdrawSendSuccussComponent
        isVisible={isShowSuccussModal}
        onModalClose={() => { setIsShowSuccussModal(false); }}
        requestOrderNumber={requestOrderNumber}
        withdrawAmount={requestAmount}
      />

      <Default>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainContainer}>
              {content}
            </div>
          )}
          containerStyle={{ paddingTop: 30 }}
        />
      </Default>
      <Mobile>
        <InfoModalComponent
          isVisible={isVisible}
          onModalClose={onModalClose}
          content={(
            <div style={styles.mainMobileContainer}>
              {content}
            </div>
          )}
          containerStyle={{ paddingTop: 30 }}
        />
      </Mobile>
    </React.Fragment>
  );
};

WithdrawMoneyComponent.propTypes = {
  data: PropTypes.array,
  isVisible: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onClickSendWithdrawRequest: PropTypes.func.isRequired,
  onClickGetUnSendWithdrawRequestList: PropTypes.func.isRequired,
  onClickGetSendedWithdrawRequestList: PropTypes.func.isRequired,
};

WithdrawMoneyComponent.defaultProps = {
  data: [],
  isVisible: false,
};

export default WithdrawMoneyComponent;
