import React, { useState } from 'react';
import Assets from '../../constants/Assets';
import { Colors } from '../../constants/Color';
import Default from '../RWDComponent/Default';
import Mobile from '../RWDComponent/Mobile';
import InfoModalComponent from '../InfoModalComponent';

const styles = {
  mainContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%',
  },
  divider: {
    height: 2,
    width: '100%',
    backgroundColor: '#E1E1E1',
    margin: '0px 0px',
  },
  sizeGuideContainer: {
    width: '70vw',
    position: 'relative',
    zIndex: 999,
  },
  sizeGuideImg: {
    width: '100%',
    height: 'auto',
  },
  sizeGuideButton: {
    cursor: 'pointer',
    textAlign: 'left',
    marginBottom: '10px',
    color: Colors.orderBrown,
    textDecoration: 'underline',
  },
  sizeGuideTitle: {
    fontSize: '16px',
    color: 'black',
    marginRight: '5px',
    textAlign: 'left',
  },
  sizeSubTitle: {
    color: Colors.introRightTitle,
    fontSize: '14px',
    marginLeft: '4px',
  },
};

const SizeGuideComponent = () => {
  const [isShowSizeGuide, setIsShowSizeGuide] = useState(false);

  const sizeGuideContent = (
    <div style={styles.sizeGuideContainer}>
      <Default>
        <img
          alt=""
          src={Assets.sizeGuide}
          style={styles.sizeGuideImg}
        />
      </Default>
      <Mobile>
        <img
          alt=""
          src={Assets.sizeGuideMobile}
          style={styles.sizeGuideImg}
        />
      </Mobile>
    </div>
  );
  return (
    <div style={styles.mainContainer}>

      <InfoModalComponent
        isVisible={isShowSizeGuide}
        onModalClose={() => setIsShowSizeGuide(false)}
        content={sizeGuideContent}
        containerStyle={{ paddingTop: '10vh' }}
      />

      <div style={styles.sizeGuideTitle}>
        尺寸指南
      </div>
      <div
        onClick={() => setIsShowSizeGuide(true)}
        onKeyPress={() => setIsShowSizeGuide(true)}
        role="button"
        tabIndex="0"
        style={styles.sizeGuideButton}
      >
        挑選適合你的尺寸&gt;&gt;&gt;&gt;
      </div>
    </div>
  );
};

export default SizeGuideComponent;
